;(function() {
  'use strict'

  Controller.$inject = ["glUtils", "featureService", "accountService", "subscriberService", "userService"];
  angular.module('app.core').component('surveyQuota', {
    controller: Controller,
    templateUrl: 'survey-quota.html',
    bindings: {
      onSelect: '&',
    },
  })

  /* @ngInject */
  function Controller(
    glUtils,
    featureService,
    accountService,
    subscriberService,
    userService
  ) {
    var ctrl = this

    ctrl.$onInit = onInit

    ctrl.upgrade = upgrade

    function onInit() {
      ctrl.unlimited = featureService.hasUnlimitedSurveys()

      if (!ctrl.unlimited) {
        ctrl.canCreate = featureService.canCreateSurvey()
        ctrl.remainingText = makeRemainingText(
          featureService.getRemainingSurveys()
        )
        ctrl.isAdmin = subscriberService.isAdmin(userService.getUser().id)
      }
    }

    function makeRemainingText(remaining) {
      return (
        remaining +
        ' more ' +
        glUtils.numberSwitch(remaining, 'Survey', 'Surveys')
      )
    }

    function upgrade(event) {
      ctrl.onSelect({ $event: event })
      if (!event.defaultPrevented) {
        accountService.promptUpgrade({ context: 'more-surveys' })
      }
    }
  }
})()
