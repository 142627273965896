;(function() {
  'use strict'

  questionTemplatesConfig.$inject = ["$stateProvider"];
  angular.module('app.question-templates', []).config(questionTemplatesConfig)

  /* @ngInject */
  function questionTemplatesConfig($stateProvider) {
    $stateProvider.state('question-templates', {
      url: '/question-templates?pages&filters',
      params: {
        pages: { dynamic: true },
        filters: { dynamic: true },
      },
      parent: 'portal',
      templateUrl: 'question-templates.html',
      controller: 'QuestionTemplatesController',
      controllerAs: '$ctrl',
      title: 'Question Templates',
    })
  }
})()
