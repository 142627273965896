;(function() {
  'use strict'

  angular
    .module('core.filters')
    .filter('interactionsByText', interactionsByText)

  /* @ngInject */
  function interactionsByText() {
    /**
     * @name interactionsByWord
     * @description filter interactions by text
     * @param {[]} input array of interaction objects
     * @param {String} text text to filter interactions by
     * @returns {[]} array of arrays of interactions containing the text in its content or user comments
     */
    return function(input, text) {
      if (!input || !text) {
        return input || []
      }

      var result = []
      text = text && text.toLowerCase()

      _.forEach(input, function(group) {
        var groupResult = []

        _.forEach(group, function(interaction) {
          // check comments
          if (angular.isDefined(interaction.comments) && inComments()) {
            groupResult.push(interaction)
          }

          function inComments() {
            return _.find(interaction.comments, function(comment) {
              return _.includes(
                comment.comment && comment.comment.toLowerCase(),
                text
              )
            })
          }

          // check survey answers
          if (angular.isDefined(interaction.answers) && inAnswers()) {
            groupResult.push(interaction)
          }

          function inAnswers() {
            return _.find(interaction.answers, function(answer) {
              return _.includes(
                answer.textAnswer && answer.textAnswer.toLowerCase(),
                text
              )
            })
          }
        })

        if (groupResult.length > 0) {
          result.push(groupResult)
        }
      })
      return result
    }
  }
})()
