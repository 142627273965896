;(function() {
  'use strict'

  Controller.$inject = ["glUtils", "Logic", "LogicCondition", "LogicAction", "Question"];
  angular.module('app.core').component('logicEditor', {
    controller: Controller,
    templateUrl: 'logic-editor.html',
    bindings: {
      survey: '<',
      view: '<',
      isForbidden: '<',
      onChange: '&',
      onDone: '&',
      onCancel: '&',
    },
  })

  /* @ngInject */
  function Controller(glUtils, Logic, LogicCondition, LogicAction, Question) {
    var ctrl = this
    var choiceOptions = {}
    var statementOptions = {}
    var displayedTargetOptions = {}
    var loopVariableOptions = {} /* viewId: Options */

    ctrl.$onInit = onInit
    ctrl.getQuestionOptions = getQuestionOptions
    ctrl.isDisplayLogic = isDisplayLogic
    ctrl.isActionLogic = isActionLogic

    ctrl.addLogic = addLogic
    ctrl.moveLogic = moveLogic
    ctrl.duplicateLogic = duplicateLogic
    ctrl.removeLogic = removeLogic
    ctrl.addQuickTags = addQuickTags

    ctrl.getTitle = getTitle

    ctrl.getLogic = getLogic
    ctrl.isCursorNav = isCursorNav
    ctrl.getCursorInfo = getCursorInfo
    ctrl.setCursor = setCursor
    ctrl.isCursorPrev = isCursorPrev
    ctrl.isCursorNext = isCursorNext
    ctrl.cursorFirst = cursorFirst
    ctrl.cursorPrev = cursorPrev
    ctrl.cursorNext = cursorNext
    ctrl.cursorLast = cursorLast

    ctrl.getConditionChoicesPlaceholder = getConditionChoicesPlaceholder
    ctrl.getChoiceOptions = getChoiceOptions
    ctrl.getStatementOptions = getStatementOptions
    ctrl.getDisplayedTargetOptions = getDisplayedTargetOptions
    ctrl.getLoopVariableOptions = getLoopVariableOptions
    ctrl.clearQuestion = clearQuestion
    ctrl.update = update
    ctrl.updateLeastFill = updateLeastFill
    ctrl.getConditionRowClass = getConditionRowClass
    ctrl.getActionRowClass = getActionRowClass

    ctrl.onVariableKeyDown = onVariableKeyDown
    ctrl.onVariablePaste = onVariablePaste

    ctrl.jumpsAndTargetUsesDisplayLogic = jumpsAndTargetUsesDisplayLogic

    function onInit() {
      ctrl.cursor = 9999
      ctrl.set = 20
      if (ctrl.view.isQuestion()) {
        var question = ctrl.view.value
        ctrl.title = question.getTitleLabel({ number: true })
        ctrl.logic = question.logic
        ctrl.viewType = 'question'
        ctrl.supportsDisplayTarget = question.isType([
          Question.Types.CHOICE,
          Question.Types.SCALE,
          Question.Types.RANK,
          Question.Types.MATRIX,
          Question.Types.CONSTANT_SUM,
          Question.Types.SCORE,
          Question.Types.HIDDEN_VARIABLES,
        ])

        // prepare target options
        if (question.isHiddenVariables()) {
          ctrl.targetOptions = [
            { value: Logic.Targets.CHOICES, label: 'Variables' },
          ]
        } else {
          ctrl.targetOptions = [
            { value: Logic.Targets.QUESTION, label: 'Question' },
            { value: Logic.Targets.CHOICES, label: 'Choices' },
          ]
          if (question.isUsedAsMatrix()) {
            ctrl.targetOptions.push({
              value: Logic.Targets.STATEMENTS,
              label: 'Statements',
            })
          }
        }

        ctrl.targetChoicesOptions = _.map(question.getAllChoices(), function(
          choice
        ) {
          return { value: choice.id, label: choice.value || choice.label }
        })
        ctrl.targetStatementsOptions = _.map(
          question.getAllStatements(),
          function(statement) {
            return {
              value: statement.id,
              label: statement.value || statement.label,
            }
          }
        )
        ctrl.targetChoicesPlaceholder = question.isHiddenVariables()
          ? 'variables'
          : 'choices'

        ctrl.supportsQuickTags = question.isType([
          Question.Types.CHOICE,
          Question.Types.SCALE,
          Question.Types.HIDDEN_VARIABLES,
        ])
      }
      if (ctrl.view.isSection()) {
        var section = ctrl.view.value
        ctrl.title = section.getTitleLabel()
        ctrl.logic = section.logic
        ctrl.viewType = 'section'
      }
      ctrl.conditionTypeOptions = [
        { value: LogicCondition.Types.SELECTED, label: 'respondent selected' },
        {
          value: LogicCondition.Types.ANSWER_COUNT,
          label: "respondent's answer count",
        },
        {
          value: LogicCondition.Types.ANSWERED,
          label: 'respondent answered question',
        },
        {
          value: LogicCondition.Types.DIDNT_ANSWER,
          label: "respondent didn't answer question",
        },
        {
          value: LogicCondition.Types.IS_TAGGED,
          label: 'respondent has tags',
        },
        {
          value: LogicCondition.Types.IS_PRIOR_TAGGED,
          label: 'respondent has prior tags',
        },
        {
          value: LogicCondition.Types.LIST,
          label: 'list',
        },
        {
          value: LogicCondition.Types.DISPLAYED,
          label: 'respondent displayed',
        },
      ]
      // IS_INSIDE_LOOP type is only available if logic is owned by view in loop
      var viewGroup = ctrl.view.value.getViewGroup()
      if (viewGroup && viewGroup.loop) {
        ctrl.conditionTypeOptions.push({
          value: LogicCondition.Types.IS_INSIDE_LOOP,
          label: 'respondent is inside loop',
        })
      }
      ctrl.actionTypeOptions = [
        { value: LogicAction.Types.SUBMIT, label: 'submit the survey' },
        {
          value: LogicAction.Types.EXIT,
          label: 'exit the survey',
        },
        {
          value: LogicAction.Types.JUMP_TO_QUESTION,
          label: 'jump to a question',
        },
        {
          value: LogicAction.Types.JUMP_TO_SECTION,
          label: 'jump to a section',
        },
        // DEPRECATED
        // {
        //   value: LogicAction.Types.JUMP_TO_FOLLOWUP,
        //   label: 'jump to followup question',
        // },
        // DEPRECATED
        // {
        //   value: LogicAction.Types.JUMP_TO_PROFILING,
        //   label: 'jump to profiling questions',
        // },
        {
          value: LogicAction.Types.TAG,
          label: 'add tag',
        },
        {
          value: LogicAction.Types.UNTAG,
          label: 'remove tags',
        },
        {
          value: LogicAction.Types.ADD_LEAST_FILLED_TAGS,
          label: 'add least filled tags',
        },
        {
          value: LogicAction.Types.RETAIN_LEAST_FILLED_TAGS,
          label: 'retain least filled tags',
        },
        {
          value: LogicAction.Types.HIDE_VIEW_GROUPS,
          label: 'hide groups',
        },
        {
          value: LogicAction.Types.ADD_TO_LIST,
          label: 'add to list',
        },
        {
          value: LogicAction.Types.SET_VARIABLE,
          label: 'set variable',
        },
      ]
      ctrl.allQuestionOptions = ctrl.survey
        .getQuestions()
        .map(function(question) {
          return {
            label: question.getTitleLabel({ number: true }),
            value: question.id,
            type: question.type,
          }
        })
      ctrl.allQuestionOptionsSansHiddenVariables = ctrl.allQuestionOptions.filter(
        function(q) {
          return q.type !== Question.Types.HIDDEN_VARIABLES
        }
      )
      ctrl.allSelectionQuestionOptions = ctrl.allQuestionOptions.filter(
        function(q) {
          // exclude IMAGE, SCAN, PLACES_NEAR_ME and HIDDEN_VARIABLES types question
          return !_.includes(
            [
              Question.Types.SCAN,
              Question.Types.PLACES_NEAR_ME,
              Question.Types.IMAGE,
              Question.Types.HIDDEN_VARIABLES,
            ],
            q.type
          )
        }
      )
      ctrl.allAnsweredAmountQuestionOptions = ctrl.allQuestionOptions.filter(
        function(q) {
          return _.includes(
            [
              Question.Types.CHOICE,
              Question.Types.RANK,
              Question.Types.MATRIX,
              Question.Types.SINGLE_TEXT,
              Question.Types.CONSTANT_SUM,
            ],
            q.type
          )
        }
      )
      ctrl.allDisplayedQuestionOptions = ctrl.allQuestionOptions.filter(
        function(q) {
          // supported question types are identical to ctrl.supportsDisplayTarget
          return _.includes(
            [
              Question.Types.CHOICE,
              Question.Types.SCALE,
              Question.Types.RANK,
              Question.Types.MATRIX,
              Question.Types.CONSTANT_SUM,
              Question.Types.SCORE,
              Question.Types.HIDDEN_VARIABLES,
            ],
            q.type
          )
        }
      )
      ctrl.allSectionOptions = ctrl.survey.getSections().map(function(section) {
        return {
          label: section.getTitleLabel(),
          value: section.id,
        }
      })
      ctrl.selectionQuantifierOptions = [
        {
          value: LogicCondition.SelectionQuantifiers.ANY,
          label: 'any of',
        },
        {
          value: LogicCondition.SelectionQuantifiers.ALL,
          label: 'all of',
        },
        {
          value: LogicCondition.SelectionQuantifiers.NONE,
          label: 'none of',
        },
        {
          value: LogicCondition.SelectionQuantifiers.EXACTLY,
          label: 'exactly',
        },
      ]
      ctrl.choiceQuantifierOptions = [
        {
          value: LogicCondition.ChoiceQuantifiers.ANY,
          label: 'any of',
        },
        {
          value: LogicCondition.ChoiceQuantifiers.ALL,
          label: 'all of',
        },
      ]
      ctrl.textQuantifierOptions = [
        {
          value: LogicCondition.TextQuantifiers.ANY,
          label: 'any of',
        },
        {
          value: LogicCondition.TextQuantifiers.ALL,
          label: 'all of',
        },
        {
          value: LogicCondition.TextQuantifiers.NONE,
          label: 'none of',
        },
      ]
      ctrl.numericQuantifierOptions = [
        {
          value: LogicCondition.NumericQuantifiers.LESS_THAN,
          label: 'less than',
        },
        {
          value: LogicCondition.NumericQuantifiers.GREATER_THAN,
          label: 'greater than',
        },
        {
          value: LogicCondition.NumericQuantifiers.EQUALS,
          label: 'equal to',
        },
        {
          value: LogicCondition.NumericQuantifiers.ANY,
          label: 'any of',
        },
        {
          value: LogicCondition.NumericQuantifiers.NONE,
          label: 'none of',
        },
      ]
      ctrl.listQuantifierOptions = [
        {
          value: LogicCondition.ListQuantifiers.ANY,
          label: 'any of',
        },
        {
          value: LogicCondition.ListQuantifiers.ALL,
          label: 'all of',
        },
        {
          value: LogicCondition.ListQuantifiers.NONE,
          label: 'none of',
        },
        {
          value: LogicCondition.ListQuantifiers.LESS_THAN,
          label: 'less than',
        },
        {
          value: LogicCondition.ListQuantifiers.GREATER_THAN,
          label: 'greater than',
        },
        {
          value: LogicCondition.ListQuantifiers.EQUALS,
          label: 'equal to',
        },
      ]
      ctrl.displayedQuantifierOptions = [
        {
          value: LogicCondition.DisplayedQuantifiers.ANY,
          label: 'any of',
        },
        {
          value: LogicCondition.DisplayedQuantifiers.ALL,
          label: 'all of',
        },
        {
          value: LogicCondition.DisplayedQuantifiers.NONE,
          label: 'none of',
        },
      ]
      ctrl.insideLoopQuantifierOptions = [
        {
          value: LogicCondition.InsideLoopQuantifiers.ANY,
          label: 'any of',
        },
        {
          value: LogicCondition.InsideLoopQuantifiers.NONE,
          label: 'none of',
        },
      ]
      ctrl.rankOptions = [
        { label: 'Rank 1', value: 0 },
        { label: 'Rank 2', value: 1 },
        { label: 'Rank 3', value: 2 },
        { label: 'Rank 4', value: 3 },
        { label: 'Rank 5', value: 4 },
        { label: 'Rank 6', value: 5 },
        { label: 'Rank 7', value: 6 },
        { label: 'Rank 8', value: 7 },
        { label: 'Rank 9', value: 8 },
        { label: 'Rank 10', value: 9 },
      ]
      populateTagOptions()
      populateListOptions()
      populateViewGroupOptions()
    }

    function getQuestionOptions(condition) {
      switch (condition.type) {
        case condition.Types.SELECTED:
          return ctrl.allSelectionQuestionOptions
        case condition.Types.ANSWER_COUNT:
          return ctrl.allAnsweredAmountQuestionOptions
        case condition.Types.DISPLAYED:
          return ctrl.allDisplayedQuestionOptions
        default:
          return ctrl.allQuestionOptionsSansHiddenVariables
      }
    }

    function isDisplayLogic() {
      return _.some(ctrl.logic, function(logic) {
        return logic.type === logic.Types.DISPLAY
      })
    }

    function isActionLogic() {
      return _.some(ctrl.logic, function(logic) {
        return logic.type === logic.Types.ACTION
      })
    }

    function addLogic(type) {
      var logic = new Logic(ctrl.survey.logicHelpers)
      logic.type = type
      if (type === Logic.Types.DISPLAY) {
        if (ctrl.viewType === 'question') {
          var question = ctrl.view.value
          logic.target = question.isHiddenVariables()
            ? Logic.Targets.CHOICES // hidden variables display logic can only target choices
            : Logic.Targets.QUESTION
        }
        if (ctrl.viewType === 'section') {
          logic.target = Logic.Targets.SECTION
        }
      }
      logic.setup()
      ctrl.logic.push(logic)
      return logic
    }

    function addQuickTags() {
      var question = ctrl.view.value
      var isHiddenVariables = question.type === Question.Types.HIDDEN_VARIABLES
      if (isHiddenVariables) {
        getChoiceOptions(question.id).forEach(function(choice) {
          var logic = addLogic(Logic.Types.ACTION)
          var condition = logic.conditions[0]
          condition.type = LogicCondition.Types.DISPLAYED
          condition.questionId = question.id
          condition.displayedTarget = 'CHOICES'
          condition.choiceIds.push(choice.value)
          condition.displayedQuantifier =
            LogicCondition.DisplayedQuantifiers.ANY
          var action = logic.actions[0]
          action.type = LogicAction.Types.TAG
          action.tag = choice.label
        })
      } else {
        getChoiceOptions(question.id).forEach(function(choice) {
          var logic = addLogic(Logic.Types.ACTION)
          var condition = logic.conditions[0]
          condition.type = LogicCondition.Types.SELECTED
          condition.questionId = question.id
          condition.choiceIds.push(choice.value)
          condition.selectionQuantifier =
            LogicCondition.SelectionQuantifiers.ANY
          var action = logic.actions[0]
          action.type = LogicAction.Types.TAG
          action.tag = choice.label
        })
      }
    }

    function moveLogic(id, amount) {
      var fromIndex = _.findIndex(ctrl.logic, function(logic) {
        return logic.id === id
      })
      if (fromIndex === -1) return
      var toIndex = fromIndex + amount
      ctrl.logic.splice(toIndex, 0, ctrl.logic.splice(fromIndex, 1)[0])
    }

    function duplicateLogic(id) {
      var logic = _.find(ctrl.logic, { id: id })
      var index = _.findIndex(ctrl.logic, logic)
      var ids = {
        /* [oldId]: newId */
      }
      function getId(oldId, getOnly) {
        // if there is no id don't re-assign
        if (!oldId) return oldId
        // safeguard for rebinding old references
        if (!ids[oldId] && getOnly) {
          return oldId
        }
        // generate one if it hasn't been generated yet
        if (!ids[oldId]) {
          ids[oldId] = glUtils.uuid()
        }
        return ids[oldId]
      }
      ctrl.logic.splice(index, 0, logic.clone().refresh(getId))
    }

    function removeLogic(id) {
      _.remove(ctrl.logic, function(logic) {
        return logic.id === id
      })
    }

    function getConditionChoicesPlaceholder(questionId) {
      var question = ctrl.survey.getQuestion(questionId)
      return question.isHiddenVariables() ? 'variables' : 'choices'
    }

    function getChoiceOptions(questionId) {
      if (!choiceOptions[questionId]) {
        var question = ctrl.survey.getQuestion(questionId)
        if (!question) return []
        choiceOptions[questionId] = _.map(
          question.getVisibleChoices(),
          function(choice) {
            return {
              value: choice.id,
              label: choice.value || choice.label,
            }
          }
        )
      }
      return choiceOptions[questionId]
    }

    function getStatementOptions(questionId) {
      if (!statementOptions[questionId]) {
        var question = ctrl.survey.getQuestion(questionId)
        if (!question) return []
        statementOptions[questionId] = _.map(
          question.getVisibleStatements(),
          function(statement) {
            return {
              value: statement.id,
              label: statement.value || statement.label,
            }
          }
        )
      }
      return statementOptions[questionId]
    }

    function getDisplayedTargetOptions(questionId) {
      if (!displayedTargetOptions[questionId]) {
        var question = ctrl.survey.getQuestion(questionId)
        if (!question) return []
        var targetLabel = question.isHiddenVariables() ? 'Variables' : 'Choices'
        displayedTargetOptions[questionId] = [
          {
            value: LogicCondition.DisplayedTargets.CHOICES,
            label: targetLabel,
          },
        ]
        if (question.isUsedAsMatrix()) {
          displayedTargetOptions[questionId].push({
            value: LogicCondition.DisplayedTargets.STATEMENTS,
            label: 'Statements',
          })
        }
      }
      return displayedTargetOptions[questionId]
    }

    function getLoopVariableOptions(viewId) {
      if (!loopVariableOptions[viewId]) {
        var viewGroup = ctrl.survey.getViewGroupByViewId(viewId)
        loopVariableOptions[viewId] = viewGroup.getLoopVariableOptions(
          ctrl.survey
        )
      }
      return loopVariableOptions[viewId]
    }

    function clearQuestion(condition) {
      // clear selected question if not supported on selected condition type
      if (
        !_.find(getQuestionOptions(condition), { value: condition.questionId })
      ) {
        condition.questionId = null
      }
    }

    function update() {
      ctrl.onChange()
      populateTagOptions()
      populateListOptions()
    }

    function populateTagOptions() {
      ctrl.allTagOptions = ctrl.survey.getTags().map(function(tag) {
        return { label: tag, value: tag }
      })
    }

    function populateListOptions() {
      ctrl.listOptions = []
      ctrl.listItemOptionsByList = {}
      var lists = ctrl.survey.getLists()
      _.each(lists, function(items, list) {
        ctrl.listOptions.push({ label: list, value: list })
        ctrl.listItemOptionsByList[list] = items.map(function(item) {
          return { label: item, value: item }
        })
      })
    }

    function populateViewGroupOptions() {
      ctrl.viewGroupOptions = []
      _.each(ctrl.survey.viewGroups, function(viewGroup) {
        ctrl.viewGroupOptions.push({
          label: viewGroup.name,
          value: viewGroup.id,
        })
      })
    }

    function updateLeastFill(leastFill) {
      leastFill.populateAmountOptions()
      leastFill.cleanUp()
    }

    function getConditionRowClass(condition) {
      if (!condition.type) {
        return '-four'
      }
      if (
        _.includes(
          [LogicCondition.Types.IS_TAGGED, LogicCondition.Types.IS_INSIDE_LOOP],
          condition.type
        )
      ) {
        return '-three'
      }
      if (
        condition.usesStatements() ||
        condition.usesRanks() ||
        condition.usesDisplayedTarget()
      ) {
        return condition.usesLoopKey() ? '-six' : '-five'
      }
      return condition.usesLoopKey() ? '-five' : '-four'
    }

    function getActionRowClass(action) {
      if (
        action.usesList() ||
        action.usesVariables() ||
        action.usesLeastFill() ||
        action.usesLoopKey()
      )
        return '-three'
      return '-two'
    }

    function jumpsAndTargetUsesDisplayLogic(action) {
      if (
        action.type !== action.Types.JUMP_TO_QUESTION &&
        action.type !== action.Types.JUMP_TO_SECTION
      ) {
        return
      }
      if (action.type === action.Types.JUMP_TO_QUESTION && !action.questionId) {
        return
      }
      if (action.type === action.Types.JUMP_TO_SECTION && !action.sectionId) {
        return
      }

      if (action.usesLoopKey()) {
        var loopVariable = action.getLoopVariable()
        if (!loopVariable) return

        // check if selected loop variable is borrowed
        if (loopVariable.isBorrowed) {
          return true
        }
      }

      // check if target uses display logic
      var view = ctrl.survey.getView(action.getViewId())
      if (!view || !view.value.logic) return
      return _.some(view.value.logic, function(logic) {
        return logic.type === logic.Types.DISPLAY
      })
    }

    function getLogic() {
      if (ctrl.logic.length <= ctrl.set) {
        return ctrl.logic
      }
      // when on last page cursor is set to 9999 to maintain last
      // page while adding logic. so we need to clamp it to get the proper slice amount
      var cursor = ctrl.cursor
      if (cursor === 9999) {
        cursor = ctrl.logic.length - ctrl.set
      }
      return ctrl.logic.slice(cursor, cursor + ctrl.set)
    }

    function isCursorNav() {
      return ctrl.logic.length > ctrl.set
    }

    function getCursorInfo() {
      var total = ctrl.logic.length
      var from = ctrl.cursor
      var to = ctrl.cursor + ctrl.set
      if (ctrl.cursor === 9999) {
        from = ctrl.logic.length - ctrl.set
        to = ctrl.logic.length
      }
      return ['Showing ', from, '-', to, ' of ', total].join('')
    }

    function setCursor(value) {
      ctrl.cursor = Math.max(value, 0)
    }

    function isCursorPrev() {
      return ctrl.logic.length > ctrl.set && ctrl.cursor > 0
    }

    function isCursorNext() {
      return ctrl.cursor + ctrl.set < ctrl.logic.length
    }

    function cursorFirst() {
      if (!isCursorPrev()) return
      ctrl.cursor = 0
    }

    function cursorPrev() {
      if (!isCursorPrev()) return
      if (ctrl.cursor === 9999) {
        ctrl.cursor = ctrl.logic.length - ctrl.set - ctrl.set
      } else {
        ctrl.cursor -= ctrl.set
      }
      if (ctrl.cursor < 0) ctrl.cursor = 0
    }

    function cursorNext() {
      if (!isCursorNext()) return
      ctrl.cursor += ctrl.set
      // don't show partial pages, clamp to end on last page
      if (!isCursorNext()) {
        ctrl.cursor = 9999
      }
    }

    function cursorLast() {
      if (!isCursorNext()) return
      ctrl.cursor = 9999
    }

    function getTitle(logic) {
      if (logic.isAction()) {
        return 'If...'
      }
      return ctrl.supportsDisplayTarget ? 'Display...' : 'Display if...'
    }

    var VARIABLE_REGEX = /^[a-zA-Z0-9_\- ]+$/

    function onVariableKeyDown(event) {
      switch (event.keyCode) {
        case 8: // Backspace
        case 9: // Tab
        case 13: // Enter
        case 37: // Left
        case 38: // Up
        case 39: // Right
        case 40: // Down
          break
        default:
          if (!VARIABLE_REGEX.test(event.key)) {
            event.preventDefault()
            return false
          }
          break
      }
    }
    function onVariablePaste(event) {
      var text = event.clipboardData.getData('text/plain')
      if (!VARIABLE_REGEX.test(text)) {
        event.preventDefault()
      }
    }
  }
})()
