;(function() {
  'use strict'

  Controller.$inject = ["StateFactory", "clipboardService", "reportLinkService"];
  angular.module('glow.reporting').component('reportLinkList', {
    controller: Controller,
    templateUrl: 'report-link-list.html',
    bindings: {
      surveyId: '<',
      onClose: '&',
    },
  })

  /* @ngInject */
  function Controller(StateFactory, clipboardService, reportLinkService) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.create = create
    ctrl.copy = clipboardService.copy
    ctrl.edit = edit
    ctrl.destroy = destroy

    function onInit() {
      ctrl.links = []
      ctrl.state = new StateFactory(['loading', 'ready', 'none', 'error'])
      ctrl.state.loading()
      reportLinkService
        .getBySurvey(ctrl.surveyId)
        .then(function(links) {
          hidePrivateAndExpired(links)
          ctrl.links = links
          ctrl.links.length ? ctrl.state.ready() : ctrl.state.none()
        })
        .catch(function(error) {
          console.error(error)
          ctrl.state.error()
        })
    }

    function create() {
      var link = reportLinkService.spawn()
      link.surveyId = ctrl.surveyId
      reportLinkService.showEditDialog(link).then(function() {
        ctrl.links.push(link)
        if (!ctrl.state.isReady()) {
          ctrl.state.ready()
        }
      })
    }

    function edit(link) {
      reportLinkService.showEditDialog(link)
    }

    function destroy(link) {
      var idx = ctrl.links.indexOf(link)
      _.remove(ctrl.links, link)
      reportLinkService.destroy(link).catch(function() {
        ctrl.links.splice(idx, 0, link)
      })
    }

    function hidePrivateAndExpired(links) {
      _.remove(links, function(link) {
        return link.forResource ? true : link.isExpired()
      })
    }
  }
})()
