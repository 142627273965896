;(function() {
  'use strict'

  Controller.$inject = ["$http", "$state", "$q", "glAnalytics", "glAuthService", "configService", "userService", "subscriberPromotionService", "countryService", "industryService", "Money"];
  angular.module('app.signup').controller('Signup', Controller)

  /* @ngInject */
  function Controller(
    $http,
    $state,
    $q,
    glAnalytics,
    glAuthService,
    configService,
    userService,
    subscriberPromotionService,
    countryService,
    industryService,
    Money
  ) {
    var ctrl = this
    var promoInfo = [] /* [currencyCode]: { credit, matchedSpend, total } */

    ctrl.$onInit = onInit
    ctrl.onSignupCompleted = onSignupCompleted
    ctrl.loginDialog = loginDialog
    ctrl.getPromoAmountLabel = getPromoAmountLabel
    ctrl.getPromoDetailsLabel = getPromoDetailsLabel

    function onInit() {
      ctrl.isLoading = true
      parseParams()
      attemptLogin()
        .then(getLocation)
        .then(checkSteadfast)
        .then(loadPromo)
        .finally(function() {
          makeOptions()
          ctrl.isLoading = false
        })
    }

    function parseParams() {
      ctrl.contentGroupIds = $state.params.contentGroupIds
        ? $state.params.contentGroupIds.split(',')
        : []
      ctrl.country = $state.params.country
        ? countryService.getById($state.params.country)
        : undefined
      ctrl.industry = $state.params.industry
        ? industryService.getById($state.params.industry)
        : undefined
      ctrl.bannerImageUrl = $state.params.bannerImageUrl
      ctrl.hideOverview = $state.params.hideOverview === 'true'
      ctrl.hideCompany = true // Default to hidden
      ctrl.referrerCode = $state.params.referrerCode
      ctrl.isSteadfast = $state.params.promo === 'steadfast'
      ctrl.promo =
        $state.params.promo === 'steadfast' ? null : $state.params.promo
      ctrl.preferredExternalPanelId = $state.params.ppp
      ctrl.allowAddIntegratedPanel = $state.params.ip === 'true'
      ctrl.allowAddExternalPanel = $state.params.ep !== 'false'
      ctrl.enableExternalPanelFlag = $state.params.ep === 'true'
      ctrl.utm_source = $state.params.utm_source
      ctrl.utm_medium = $state.params.utm_medium
      ctrl.utm_campaign = $state.params.utm_campaign
      ctrl.utm_term = $state.params.utm_term
      ctrl.utm_content = $state.params.utm_content

      // params from consume-invite redirect
      ctrl.organisationName = $state.params.organisationName
      var redirectedFrom = $state.transition.options().custom.redirectedFrom
      ctrl.isInvited = redirectedFrom === 'consume-invite'
    }

    function makeOptions() {
      // These fields are primarily for registrationContext but some fields are also
      // for the subscriber, so it is named "options" to differentiate.
      ctrl.options = {
        referrerCode: ctrl.referrerCode,
        utm_source: ctrl.utm_source,
        utm_medium: ctrl.utm_medium,
        utm_campaign: ctrl.utm_campaign,
        utm_term: ctrl.utm_term,
        utm_content: ctrl.utm_content,
        contentGroupIds: ctrl.contentGroupIds,
        isSteadfast: ctrl.isSteadfast,
        promotion: ctrl.promotion,
        country: ctrl.country,
        industry: ctrl.industry,
        hideCompany: ctrl.hideCompany,
        preferredExternalPanelId: ctrl.preferredExternalPanelId,
        allowAddIntegratedPanel: ctrl.allowAddIntegratedPanel,
        allowAddExternalPanel: ctrl.allowAddExternalPanel,
        enableExternalPanelFlag: ctrl.enableExternalPanelFlag,
      }
    }

    function attemptLogin() {
      return $q(function(resolve, reject) {
        if (userService.hasCredentials()) {
          track('signup-auto-login')
          goToPortal()
          reject()
        } else {
          resolve()
        }
      })
    }

    function checkSteadfast() {
      if (ctrl.isSteadfast) {
        ctrl.contentGroupIds = configService.isProduction()
          ? ['5e5b3c76-287f-40ce-be3b-d88663652b50']
          : ['b752e7ed-1af8-4e58-9353-1f4fb9255fe0']
        ctrl.bannerImageUrl = 'img/logo-steadfast.png'
        ctrl.country = countryService.getById(
          'e388d689-d159-4cf9-979a-c058419af047'
        )
        ctrl.industry = industryService.getById(
          '86fc0da5-b3c4-42b4-b791-1b39b2863dfb'
        )
        ctrl.hideOverview = true
        ctrl.hideCompany = false
      }
      return $q.resolve()
    }

    function loadPromo() {
      if (!ctrl.promo) {
        return $q.resolve()
      }
      return subscriberPromotionService
        .getByCode(ctrl.promo)
        .then(function(promotion) {
          if (promotion.isActive) {
            ctrl.promotion = promotion
            glAnalytics.track(
              'subscriber-promotion',
              'offered-at-signup',
              promotion.code
            )
          } else {
            ctrl.promotionInactive = true
          }
        })
        .catch(function() {
          ctrl.promoNotFound = true
        })
    }

    function getLocation() {
      if (ctrl.country) return $q.resolve() // it was passed in via param
      return $http({
        method: 'GET',
        url: configService.getNodeUrl('/geolocation'),
      })
        .then(function(resp) {
          var countryCode = resp.data.country_code
          ctrl.country = countryService.getByCC(countryCode)
        })
        .catch(function() {
          ctrl.country = countryService.getByCC('AU')
          return $q.resolve()
        })
    }

    function onSignupCompleted(promoApplied) {
      ctrl.promoApplied = promoApplied
      track('signup-completed')
      if (ctrl.promotion) {
        glAnalytics.track(
          'subscriber-promotion',
          'offer-accepted-with-signup',
          ctrl.promotion.code
        )
        glAnalytics.set('promo', ctrl.promotion.code)
      }
      goToPortal()
    }

    function goToPortal() {
      if (ctrl.isInvited) {
        return userService.redirectToRequestedUrl()
      }
      if (ctrl.promoApplied) {
        subscriberPromotionService.setApplied(ctrl.promotion)
      }
      $state.go('dashboard', {
        didSignup: true,
      })
    }

    function loginDialog() {
      userService
        .loginDialog({
          allowedRoles: [
            glAuthService.Roles.SUBSCRIBER,
            glAuthService.Roles.SUBSCRIBER_SUPPORT,
          ],
        })
        .then(function() {
          track('signup-manual-login-success')
          goToPortal()
        })
        .catch(function(reason) {
          if (reason === 'passwordReset') {
            $state.go('passwordReset')
            return
          }
          track('signup-manual-login-error')
        })
    }

    function track(action) {
      glAnalytics.track('signup', action, ctrl.contentGroupIds.join(','))
    }

    function getPromoInfo(currencyCode) {
      if (promoInfo[currencyCode]) return promoInfo[currencyCode]
      var credit = ctrl.promotion.credit.byCurrency(currencyCode)
      var matchedSpend = ctrl.promotion.matchedSpend.byCurrency(currencyCode)
      var n = 0
      if (credit) n += credit.amount
      if (matchedSpend) n += matchedSpend.amount
      var total = new Money()
      total.currency = currencyCode
      total.setAmount(n)
      promoInfo[currencyCode] = {
        credit: credit,
        matchedSpend: matchedSpend,
        total: total,
      }
      return promoInfo[currencyCode]
    }

    function getPromoAmountLabel() {
      if (!ctrl.currency) return ''
      var info = getPromoInfo(ctrl.currency.code)

      /**
       * HACK
       * ----
       * Enterprise is a special promotion that doesn't require any credit or matched-spend.
       * It can if it wants to, but if it has none we just hide this label.
       */
      if (!info.total.amount && ctrl.promotion.code === 'enterprise') {
        return null
      }

      return info.total.format({ decimal: false, currency: true }) + ' Credit'
    }

    function getPromoDetailsLabel() {
      if (!ctrl.currency) return ''
      var info = getPromoInfo(ctrl.currency.code)
      if (info.credit.amount && !info.matchedSpend.amount) {
        return 'Applied immediately'
      }
      if (!info.credit.amount && info.matchedSpend.amount) {
        return 'We match what you spend'
      }
      if (info.credit.amount && info.matchedSpend.amount) {
        return [
          info.credit.format({ decimal: false }),
          ' immediately + ',
          info.matchedSpend.format({ decimal: false }),
          ' matched spend',
        ].join('')
      }
      return ''
    }
  }
})()
