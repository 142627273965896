;(function() {
  'use strict'

  feedbackInteractionController.$inject = ["$scope", "interactionsService", "userService", "glDialog"];
  angular
    .module('core.interaction')
    .controller('FeedbackInteraction', feedbackInteractionController)

  /* @ngInject */
  function feedbackInteractionController(
    $scope,
    interactionsService,
    userService,
    glDialog
  ) {
    // valid mood hash - as per UP 1.0 update
    var moods = {
      '1': { class: 'gi gi-mood-6', label: 'Very Angry' },
      '2': { class: 'gi gi-mood-5', label: 'Angry' },
      '3': { class: 'gi gi-mood-4', label: 'Meh' },
      '4': { class: 'gi gi-mood-3', label: 'Neutral' },
      '5': { class: 'gi gi-mood-2', label: 'Happy' },
      '6': { class: 'gi gi-mood-1', label: 'Very Happy' },
    }

    $scope.getDisplayName = getDisplayName
    $scope.getEmail = getEmail
    $scope.interactionIcon = interactionIcon
    $scope.interactionMoodIcon = interactionMoodIcon
    $scope.interactionMoodLabel = interactionMoodLabel
    $scope.actionInteraction = actionInteraction
    $scope.commentCircleText = commentCircleText
    $scope.commentCreatedAt = commentCreatedAt
    $scope.flagFeedback = flagFeedback
    $scope.flagComment = flagComment
    $scope.sendCoupon = sendCoupon

    activate()

    /**
     * Initialise
     */
    function activate() {
      getInteraction()
        .then(function(interaction) {
          $scope.interaction = interaction
          actionInteraction($scope.interaction)
          $scope.state = 'ready'
        })
        .catch(function() {
          $scope.state = 'error'
        })
    }

    /**
     * @name getDisplayName
     * @description get name to display
     * @return {String} the display name
     */
    function getDisplayName() {
      if ($scope.interaction.requestedBy) {
        return (
          $scope.interaction.requestedBy.fullname ||
          $scope.interaction.username ||
          'A user'
        )
      } else {
        // Older interactions don't have requestedBy
        return $scope.interaction.username || 'A user'
      }
    }

    /**
     * @name getEmail
     * @description get the requestor's email
     * @return {String} the email
     */
    function getEmail() {
      return $scope.interaction.requestedBy.email
    }

    /**
     * @name getInteraction
     * @description get interaction by ID
     * @return {Promise}
     */
    function getInteraction() {
      return interactionsService.get($scope.interaction.id)
    }

    /**
     * @name interactionIcon
     * @description get interaction icon class(s)
     * @param  {Object} interaction interaction to generate icon class(s) for
     * @return {String}             icon class(s)
     */
    function interactionIcon(interaction) {
      var iconTypes = {
        feedback: 'gi-chat blue',
        idea: 'gi-idea blue',
        complaint: 'gi-complaint blue',
        praise: 'gi-praise blue',
        suggestion: 'gi-suggestion blue',
        request: 'gi-request blue',
        question: 'gi-question blue',
        'survey-response': 'gi-survey green',
      }

      return iconTypes[interaction.type] || 'feedback'
    }

    /**
     * @name interactionMoodIcon
     * @description get interaction mood icon class - null if cannot be match to a known mood
     * @param  {Number} mood mood enumeration
     * @return {String}             mood class
     */
    function interactionMoodIcon(mood) {
      return moods[mood] ? moods[mood].class : null
    }

    /**
     * @name interactionMoodLabel
     * @description get interaction mood label - null if cannot be matched to a known mood
     * @param  {Number} mood mood enumeration
     * @return {String}             mood label
     */
    function interactionMoodLabel(mood) {
      return moods[mood] ? moods[mood].label : null
    }

    /**
     * @name actionInteraction
     * @description set interaction as actioned
     * @param  {Object} interaction interaction to action
     */
    function actionInteraction(interaction) {
      if (!interaction.actionedBy && $scope.doAction) {
        interactionsService.markAsActioned(interaction)
        interaction.actionedBy = userService.getUser().id
      }
    }

    /**
     * @name  commentCircleText
     * @description get first two letters of commentors username to use in comment icon
     * @param  {Object} comment comment to generate comment icon text for
     * @return {String}         two letter string for use in icon
     */
    function commentCircleText(comment) {
      if (comment.creator && !comment.creator.hack) {
        return (
          comment.creator.name[0].toUpperCase() +
          comment.creator.name[1].toLowerCase()
        )
      } else {
        // Fix for old interactions that do not have a creator key on comments
        // TOOD remove once migration complete
        comment.creator = {
          hack: true,
        }
        switch (comment.type) {
          case 'u':
            comment.creator.name = 'Glow user'
            return 'U'
          case 's':
            comment.creator.name = 'me'
            return 'S'
          case 'c':
            comment.creator.name = 'Glow user'
            return 'C'
          case 'a':
            comment.creator.name = 'Admin user'
            return 'A'
        }
      }
    }

    /**
     * @name commentCreatedAt
     * @description get comment created at date - account for old interactions with date object
     * @param  {Object} comment comment object
     * @return {String}         comment created at date
     */
    function commentCreatedAt(comment) {
      return _.isObject(comment.createdAt)
        ? comment.createdAt.date
        : comment.createdAt
    }

    /**
     * @name flagFeedback
     * @description flag some feedback
     * @param  {Object} $event  click event
     * @param  {Object} interaction inetraction to flag
     */
    function flagFeedback($event, interaction) {
      if (
        interaction.userFlagged === true ||
        interaction.userFlagged === false
      ) {
        // still waiting on previous flag request to complete
        return
      }
      if (interaction.userFlagged) {
        doFlagFeedback()
      } else {
        glDialog
          .confirm('', 'Flag this feedback as inappropriate?', {
            targetEvent: $event,
          })
          .then(doFlagFeedback)
      }

      function doFlagFeedback() {
        if (!interaction.userFlagged) {
          // flag
          interaction.userFlagged = true

          interactionsService.flagInteraction(interaction.id).then(
            function(edgeId) {
              interaction.userFlagged = edgeId
            },
            function() {
              delete interaction.userFlagged
            }
          )
        } else {
          // unflag
          var edgeId = _.clone(interaction.userFlagged)
          interaction.userFlagged = false

          interactionsService.unflagInteraction(edgeId).then(function() {
            delete interaction.userFlagged
          })
        }
      }
    }

    /**
     * @name flagComment
     * @description flag a comment
     * @param  {Object} $event  click event
     * @param  {Object} comment comment to flag
     */
    function flagComment($event, comment) {
      if (comment.userFlagged === true || comment.userFlagged === false) {
        // still waiting on previous flag request to complete
        return
      }
      if (comment.userFlagged) {
        doFlagComment()
      } else {
        glDialog
          .confirm('', 'Flag this comment as inappropriate?', {
            targetEvent: $event,
          })
          .then(doFlagComment)
      }

      function doFlagComment() {
        if (!comment.userFlagged) {
          // flag
          comment.userFlagged = true

          interactionsService.flagComment(comment.id).then(
            function(edgeId) {
              comment.userFlagged = edgeId
            },
            function() {
              delete comment.userFlagged
            }
          )
        } else {
          // unflag
          var edgeId = _.clone(comment.userFlagged)
          comment.userFlagged = false

          interactionsService.unflagComment(edgeId).then(function() {
            delete comment.userFlagged
          })
        }
      }
    }

    /**
     * @name  sendCoupon
     * @description open send coupon dialog
     * @param  {Object} $event      event object
     * @param  {Object} interaction interaction to get information of OP to send coupon
     */
    function sendCoupon($event, interaction, coupons) {
      $event.stopPropagation()
      glDialog.show({
        controller: 'SendCouponDialogController',
        targetEvent: $event,
        templateUrl: 'send-coupon-dialog.tpl.html',
        locals: {
          userId: interaction.phoneUserId,
          coupons: coupons,
        },
      })
    }
  }
})()
