;(function() {
  'use strict'

  Config.$inject = ["$stateProvider"];
  angular.module('app.export', []).config(Config)

  /* @ngInject */
  function Config($stateProvider) {
    $stateProvider.state('export', {
      url: '/export/:exportId',
      templateUrl: 'export.html',
      controller: 'ExportController',
      controllerAs: '$ctrl',
      title: 'Export',
      public: true,
    })
  }
})()
