;(function() {
  'use strict'

  Service.$inject = ["$q", "$rootScope", "glAnalytics", "accountSetupDialog", "projectService", "subscriberService", "userService", "hostingCreditService"];
  angular.module('app.core').service('incentivesService', Service)

  /* @ngInject */
  function Service(
    $q,
    $rootScope,
    glAnalytics,
    accountSetupDialog,
    projectService,
    subscriberService,
    userService,
    hostingCreditService
  ) {
    var self = this
    var cache = {
      value: [],
      zero: false, // we dont know if there are zero until after a refresh
      loading: false,
    }

    var Types = {
      COMPLETE_PROFILE: 'COMPLETE_PROFILE',
      CREATE_PROJECT: 'CREATE_PROJECT',
    }

    var Units = {
      COMPLETE_PROFILE: 100,
      CREATE_PROJECT: 100,
    }

    $rootScope.$on(
      accountSetupDialog.Events.COMPLETED,
      claim.bind(self, Types.COMPLETE_PROFILE)
    )

    $rootScope.$on(
      projectService.Events.CREATED,
      claim.bind(self, Types.CREATE_PROJECT)
    )

    self.Types = Types
    self.get = get
    self.getInfoMessage = getInfoMessage
    self.execute = execute

    refresh() // initalize on injected

    function get() {
      refresh()
      return cache
    }

    function refresh() {
      if (!userService.isLoggedIn()) {
        _.remove(cache.value)
        cache.zero = true
        return $q.resolve()
      }
      cache.loading = true
      return $q(function(resolve, reject) {
        var subscriberId = subscriberService.getSubscriber().id
        hostingCreditService
          .listClaimedIncentives(subscriberId)
          .then(function(claimedIncentives) {
            populate(claimedIncentives)
            cache.loading = false
            cache.zero = cache.value.length === 0
            resolve()
          })
          .catch(function() {
            cache.loading = false
            reject()
          })
      })
    }

    function populate(claimedIncentives) {
      var isSSR = userService.isSSR()
      var isAdmin = subscriberService.isAdmin(userService.getUser().id)

      _.remove(cache.value)
      _.chain(getIncentiveInfo())
        .filter(function(incentive) {
          var isAvailable = incentive.requireAdminOrSSR
            ? isSSR || isAdmin
            : true
          var isUnclaimed = !_.find(claimedIncentives, {
            action: incentive.type,
          })
          return isAvailable && isUnclaimed
        })
        .orderBy('order')
        .each(function(incentive) {
          cache.value.push(incentive)
        })
        .value()
    }

    function canClaim(type) {
      return !!_.find(cache.value, { type: type })
    }

    function claim(type) {
      if (!userService.isLoggedIn() || !canClaim(type)) {
        return
      }

      var subscriber = subscriberService.getSubscriber()
      var data = {
        action: type,
        units: Units[type],
      }
      hostingCreditService
        .claimIncentive(subscriber, data)
        .then(refresh)
        .catch(function(err) {
          // claim failed. do nothing
          console.error('claim incentive failed', err)
        })
    }

    function getIncentiveInfo() {
      return [
        {
          type: Types.COMPLETE_PROFILE,
          units: Units.COMPLETE_PROFILE,
          order: 0,
          icon: 'person',
          title: 'Tailored Content',
          message:
            'Get tailored content based on your country and industry and earn <b>' +
            Units.COMPLETE_PROFILE +
            ' credits</b>!',
          context: 'complete-profile',
          action: 'Complete Profile',
          onClick: accountSetupDialog.show,
          requireAdminOrSSR: true,
        },
        {
          type: Types.CREATE_PROJECT,
          units: Units.CREATE_PROJECT,
          order: 1,
          icon: 'project',
          title: 'Create Project',
          message:
            'Create project and earn <b>' +
            Units.CREATE_PROJECT +
            ' credits</b>!',
          context: 'create-project',
          action: 'Create Project',
          onClick: projectService.promptCreateAndView,
          requireAdminOrSSR: false,
        },
      ]
    }

    function getInfoMessage(type) {
      if (!canClaim(type)) {
        return null
      }
      return _.find(getIncentiveInfo(), { type: type }).message
    }

    function execute(incentive) {
      glAnalytics.track('incentive', 'clicked', incentive.context)
      incentive.onClick && incentive.onClick()
    }
  }
})()
