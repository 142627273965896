;(function() {
  'use strict'

  Service.$inject = ["$q", "glUtils", "DOCXService", "subscriberService", "imagesService", "videoService", "projectService", "countryService", "numericService", "cloudinaryService", "questionEditor", "glToast"];
  angular.module('app.survey-edit').factory('surveyDOCXExporter', Service)

  /* @ngInject */
  function Service(
    $q,
    glUtils,
    DOCXService,
    subscriberService,
    imagesService,
    videoService,
    projectService,
    countryService,
    numericService,
    cloudinaryService,
    questionEditor,
    glToast
  ) {
    var GLOW_LOGO_PATH = '/img/glow-logo.png'
    var cachedImage = {} /* [imageUrl]: Image */

    return {
      export: exportDOCX,
    }

    function exportDOCX(survey) {
      survey.isExportingDOCX = true
      var subscriber = subscriberService.getSubscriber()
      $q.all([
        loadProject(survey.projectId),
        loadImages(subscriber, survey),
      ]).then(function(results) {
        var project = results[0]
        var doc = createDoc(subscriber, project, survey)
        var fileName = 'survey-document.docx'
        DOCXService.generate(doc, fileName).then(function() {
          if (results[1]) {
            glToast.show(
              'Failed to download images. Please check your connection and try again.'
            )
          }
          survey.isExportingDOCX = false
        })
      })
    }

    function loadProject(projectId) {
      return projectService.get(projectId)
    }

    function getImageUrls(subscriber, survey) {
      var imageUrls = []
      // glow logo
      imageUrls.push(GLOW_LOGO_PATH)
      // subscriber logo
      imageUrls.push(subscriber.portalTheme.logoImageUrl)
      // intro image
      imageUrls.push(survey.imageUrl)
      // view images
      _.each(survey.views, function(view) {
        imageUrls.push(view.value.imageUrl)
        if (view.isQuestion()) {
          // statement images
          if (view.value.statementImagesEnabled) {
            _.each(view.value.getLocalStatements(true), function(statement) {
              imageUrls.push(statement.imageUrl)
            })
          }
          // choice images
          if (view.value.choiceImagesEnabled) {
            _.each(view.value.getLocalChoices(true), function(choice) {
              imageUrls.push(choice.imageUrl)
            })
          }
        }
      })
      return imageUrls
    }

    function loadImages(subscriber, survey) {
      return $q(function(resolve) {
        var imageUrls = getImageUrls(subscriber, survey)
        var chunks = _.chunk(imageUrls, 5) // load 5 images at a time
        var isSomeImagesFailedToLoad = false
        function next() {
          var chunk = chunks.shift()
          var promises = _.map(chunk, function(imageUrl) {
            return loadImage(imageUrl)
          })
          $q.all(promises)
            .then(check)
            .catch(function() {
              // if there's an image failed to load, mark it and continue load remaining images
              isSomeImagesFailedToLoad = true
              check()
            })
        }
        function check() {
          if (chunks.length) {
            next()
          } else {
            resolve(isSomeImagesFailedToLoad)
          }
        }
        next()
      })
    }

    function loadImage(imageUrl) {
      return $q(function(resolve, reject) {
        if (!imageUrl || cachedImage[imageUrl]) {
          return resolve()
        }

        // limit the image size to maximum width/height of 400px
        // inspired from cloudinaryService.resize() with additional c_limit param
        var resizedUrl = cloudinaryService.isCloudinaryUrl(imageUrl)
          ? imageUrl.replace('upload/', 'upload/w_400,h_400,c_limit/')
          : imageUrl

        imagesService
          .fetchBase64DataFromUrl(resizedUrl)
          .then(function(base64Data) {
            var image = new Image()
            image.src = base64Data
            image.onload = function() {
              cachedImage[imageUrl] = image
              resolve()
            }
          })
          .catch(reject)
      })
    }

    function createDoc(subscriber, project, survey) {
      var children = []
      // subscriber logo
      if (subscriber.portalTheme.logoImageUrl) {
        children.push(
          createParagraph({
            alignment: DOCXService.AlignmentType.CENTER,
            children: [
              createImage(subscriber.portalTheme.logoImageUrl, 300, 300),
            ],
          })
        )
      }
      // subscriber name
      children.push(
        createParagraph({
          alignment: DOCXService.AlignmentType.CENTER,
          heading: DOCXService.HeadingLevel.HEADING_1,
          text: subscriber.name,
        })
      )
      children.push(createLineBreak())
      // powered by glow
      children.push(
        createParagraph({
          alignment: DOCXService.AlignmentType.CENTER,
          heading: DOCXService.HeadingLevel.HEADING_5,
          children: [
            createText('Powered by '),
            createImage(GLOW_LOGO_PATH, 40, 40),
          ],
        })
      )
      children.push(createLineBreak())
      children.push(createLineBreak())
      // project name
      children.push(
        createParagraph({
          alignment: DOCXService.AlignmentType.CENTER,
          heading: DOCXService.HeadingLevel.HEADING_1,
          text: 'Project: ' + (project ? project.name : ''),
        })
      )
      children.push(createLineBreak())
      children.push(createLineBreak())
      children.push(createLineBreak())
      // survey image
      if (survey.imageUrl) {
        children.push(
          createParagraph({
            alignment: DOCXService.AlignmentType.CENTER,
            children: [createImage(survey.imageUrl, 400, 400)],
          })
        )
        children.push(createLineBreak())
      }
      // survey title
      children.push(
        createParagraph({
          alignment: DOCXService.AlignmentType.CENTER,
          heading: DOCXService.HeadingLevel.HEADING_2,
          text: 'Survey: ' + (survey.title || 'Untitled Survey'),
        })
      )
      // last updated (date now)
      children.push(
        createParagraph({
          alignment: DOCXService.AlignmentType.CENTER,
          heading: DOCXService.HeadingLevel.HEADING_5,
          text: 'Last updated: ' + moment().format('HH:mm, Do MMMM YYYY'),
        })
      )
      children.push(createLineBreak())
      // legend
      var shouldShowLegend = _.some(survey.views, function(view) {
        return (
          view.isQuestion() &&
          (view.value.choices.length || view.value.statements.length)
        )
      })
      if (shouldShowLegend) {
        children.push(createLineBreak())
        children.push(createLineBreak())
        children.push(createLegendTable())
      }
      // view groups
      if (survey.viewGroups.length) {
        children.push(createLineBreak())
        children.push(createLineBreak())
        children.push(createViewGroupsTable(survey))
      }
      // shuffle groups
      if (survey.shuffleGroups.length) {
        children.push(createLineBreak())
        children.push(createLineBreak())
        children.push(createShuffleGroupsTable(survey))
      }
      // intro text & url
      if (survey.showIntro && (survey.introText || survey.introTextUrl)) {
        children.push(createLineBreak())
        children.push(createLineBreak())
        children.push(createIntroTable(survey))
      }
      // views table
      _.each(createViewsTable(survey), function(viewTable) {
        children.push(createLineBreak())
        children.push(createLineBreak())
        children.push(viewTable)
      })
      // outro
      children.push(createLineBreak())
      children.push(createLineBreak())
      children.push(createOutroTable(survey))

      return createDocument({ sections: [{ children: children }] })
    }

    function createLegendTable() {
      var totalColumns = 3
      var rows = [
        createRow({
          children: [
            createCell({
              children: [
                createParagraph({ text: 'Legend', style: 'rowTitle' }),
              ],
              shading: { fill: 'ccffff' },
              columnSpan: totalColumns,
            }),
          ],
        }),
      ]
      var legends = [
        {
          symbol: 'A',
          label: 'Anchor',
          description:
            'If you have shuffle OR rotate enabled, a choice that is anchored will remain in the same position.',
        },
        {
          symbol: 'TC',
          label: 'Text Capture',
          description:
            'If a respondent selects this choice they will be able to enter some text to elaborate on their answer.',
        },
        {
          symbol: 'I',
          label: 'Independent',
          description:
            'This will display the choice outside of the sliding scale. It is useful for "N/A" or "Don\'t Know" choices.',
        },
        {
          symbol: 'E',
          label: 'Exclusive',
          description:
            'If a respondent selects this they will not be able to select any other choices.',
        },
      ]
      _.each(legends, function(legend) {
        rows.push(
          createRow({
            children: [
              createCell({
                children: [
                  createParagraph({ text: legend.symbol, style: 'bold' }),
                ],
                width: { size: DOCXService.convertPxtoPt(50) },
              }),
              createCell({
                children: [createParagraph(legend.label)],
                width: { size: DOCXService.convertPxtoPt(110) },
              }),
              createCell({
                children: [createParagraph(legend.description)],
              }),
            ],
          })
        )
      })
      return createTable({ rows: rows })
    }

    function createViewGroupsTable(survey) {
      var rows = [
        createRow({
          children: [
            createCell({
              children: [
                createParagraph({ text: 'Groups', style: 'rowTitle' }),
              ],
              width: { size: 25, type: DOCXService.WidthType.PERCENTAGE },
              shading: { fill: 'deeaf6' },
            }),
            createCell({
              children: [createParagraph({ text: 'Views', style: 'rowTitle' })],
              width: { size: 25, type: DOCXService.WidthType.PERCENTAGE },
              shading: { fill: 'deeaf6' },
            }),
            createCell({
              children: [
                createParagraph({ text: 'Sampling', style: 'rowTitle' }),
              ],
              width: { size: 25, type: DOCXService.WidthType.PERCENTAGE },
              shading: { fill: 'deeaf6' },
            }),
            createCell({
              children: [
                createParagraph({ text: 'Shuffle', style: 'rowTitle' }),
              ],
              width: { size: 25, type: DOCXService.WidthType.PERCENTAGE },
              shading: { fill: 'deeaf6' },
            }),
          ],
        }),
      ]
      _.each(survey.viewGroups, function(group) {
        rows.push(
          createRow({
            children: [
              createCell({
                children: [createParagraph(group.name)],
              }),
              createCell({
                children: [
                  createParagraph(getViewsNumberById(survey, group.viewIds)),
                ],
              }),
              createCell({
                children: [
                  createParagraph(
                    _.isNumber(group.sample) ? group.sample + '' : ''
                  ),
                ],
              }),
              createCell({
                children: [
                  createParagraph(getViewsNumberById(survey, group.shuffleIds)),
                ],
              }),
            ],
          })
        )
      })
      return createTable({ rows: rows })
    }

    function createShuffleGroupsTable(survey) {
      var rows = [
        createRow({
          children: [
            createCell({
              children: [
                createParagraph({ text: 'Shuffle Groups', style: 'rowTitle' }),
              ],
              width: { size: 50, type: DOCXService.WidthType.PERCENTAGE },
              shading: { fill: 'e2efd9' },
            }),
            createCell({
              children: [
                createParagraph({ text: 'Sampling', style: 'rowTitle' }),
              ],
              width: { size: 50, type: DOCXService.WidthType.PERCENTAGE },
              shading: { fill: 'e2efd9' },
            }),
          ],
        }),
      ]
      _.each(survey.shuffleGroups, function(group) {
        rows.push(
          createRow({
            children: [
              createCell({
                children: [
                  createParagraph(
                    getGroupsNameById(survey, group.viewGroupIds)
                  ),
                ],
              }),
              createCell({
                children: [
                  createParagraph(
                    _.isNumber(group.sample) ? group.sample + '' : ''
                  ),
                ],
              }),
            ],
          })
        )
      })
      return createTable({ rows: rows })
    }

    function createIntroTable(survey) {
      var rows = [
        createRow({
          children: [
            createCell({
              children: [createParagraph({ text: 'Intro', style: 'rowTitle' })],
              columnSpan: 2,
              shading: { fill: 'fff2cc' },
            }),
          ],
        }),
        createRow({
          children: [
            createCell({
              children: [createParagraph({ text: 'Text: ', style: 'bold' })],
              width: { size: DOCXService.convertPxtoPt(110) },
            }),
            createCell({
              children: [createParagraph(survey.introText || '')],
            }),
          ],
        }),
        createRow({
          children: [
            createCell({
              children: [
                createParagraph({ text: 'Text URL: ', style: 'bold' }),
              ],
            }),
            createCell({
              children: [createParagraph(survey.introTextUrl || '')],
            }),
          ],
        }),
      ]
      return createTable({ rows: rows })
    }

    function createOutroTable(survey) {
      var rows = [
        createRow({
          children: [
            createCell({
              children: [createParagraph({ text: 'Outro', style: 'rowTitle' })],
              columnSpan: 2,
              shading: { fill: 'fbe4d5' },
            }),
          ],
        }),
        createRow({
          children: [
            createCell({
              children: [
                createParagraph({
                  text: 'Survey Completed Message: ',
                  style: 'bold',
                }),
              ],
              width: { size: DOCXService.convertPxtoPt(220) },
            }),
            createCell({
              children: [createParagraph(survey.outroText)],
            }),
          ],
        }),
        createRow({
          children: [
            createCell({
              children: [
                createParagraph({ text: 'Done URL: ', style: 'bold' }),
              ],
            }),
            createCell({
              children: [createParagraph(survey.submitRoute || '')],
            }),
          ],
        }),
      ]
      return createTable({ rows: rows })
    }

    function createViewsTable(survey) {
      var tables = []
      _.each(survey.views, function(view) {
        if (view.isSection()) {
          tables.push(createSectionTable(view.value))
        }
        if (view.isQuestion()) {
          tables.push(createQuestionTable(view.value))
        }
      })
      return tables
    }

    function createSectionTable(section) {
      var rows = []
      var totalColumns = 2

      // header
      var headerRow = createRow({
        children: [
          createCell({
            children: [createParagraph({ text: 'Section', style: 'viewType' })],
            columnSpan: totalColumns,
          }),
        ],
      })
      rows.push(headerRow)

      // hidden section
      if (section.isHidden) {
        var hiddenRow = createHiddenRow(totalColumns)
        rows.push(hiddenRow)
      }

      // section display logic
      var displayLogic = _.filter(section.logic, {
        type: 'DISPLAY',
        target: 'SECTION',
      })
      if (displayLogic.length) {
        var displayLogicRow = createLogicRow(displayLogic, null, totalColumns)
        rows.push(displayLogicRow)
      }

      // title
      var titleRow = createRow({
        children: [
          createCell({
            children: [
              createParagraph({
                text: 'S' + section.getNumber() + '.',
                style: 'viewTitle',
              }),
            ],
            width: { size: DOCXService.convertPxtoPt(110) },
            shading: { fill: '7f7f7f' },
          }),
          createCell({
            children: [
              createParagraph({
                text: section.getTitleLabel({ number: false }),
                style: 'viewTitle',
              }),
            ],
            shading: { fill: '7f7f7f' },
          }),
        ],
      })
      rows.push(titleRow)

      // media && media label
      var details = []
      if (section.mediaType) {
        var mediaCellChildren = []
        if (section.mediaType === section.MediaTypes.IMAGE) {
          mediaCellChildren.push(
            createParagraph({
              alignment: DOCXService.AlignmentType.CENTER,
              children: [createImage(section.imageUrl, 300, 300)],
            })
          )
        }
        var url, text
        switch (section.mediaType) {
          case section.MediaTypes.IMAGE:
            url = section.imageUrl
            text = 'Image URL'
            if (section.delay) {
              details.push({
                label: 'Delay Timer',
                value: section.delay + ' seconds',
              })
            }
            if (section.hide) {
              details.push({
                label: 'Hide Timer',
                value: section.hide + ' seconds',
              })
            }
            break
          case section.MediaTypes.VIDEO:
            url = section.youTubeVideoUrl
            text = 'Video (YouTube)'
            break
          case section.MediaTypes.VIDEO_UPLOAD:
            url = videoService.getPreviewUrl(section.video.id)
            text = 'Video (Upload)'
            if (section.video.restrictions.scrub) {
              details.push({
                label: '',
                value: 'Restricted Controls',
              })
            }
            if (section.video.restrictions.complete) {
              details.push({
                label: '',
                value: 'Completion Required',
              })
            }
            break
          case section.MediaTypes.AUDIO:
            url = section.audioUrl
            text = 'Audio (MP3)'
            break
        }
        mediaCellChildren.push(
          createParagraph({
            alignment:
              section.mediaType === section.MediaTypes.IMAGE
                ? DOCXService.AlignmentType.CENTER
                : DOCXService.AlignmentType.LEFT,
            children: [createHyperLink(text, url)],
          })
        )
        var mediaRow = createRow({
          children: [
            createCell({
              children: [createParagraph({ text: 'Media:', style: 'bold' })],
            }),
            createCell({
              children: mediaCellChildren,
            }),
          ],
        })
        rows.push(mediaRow)

        if (section.mediaLabel) {
          var mediaLabelRow = createRow({
            children: [
              createCell({
                children: [
                  createParagraph({ text: 'Media Label:', style: 'bold' }),
                ],
              }),
              createCell({
                children: [createParagraph(section.mediaLabel)],
              }),
            ],
          })
          rows.push(mediaLabelRow)
        }
      }
      if (section.reference) {
        details.push({ label: 'Reference', value: section.reference })
      }

      // details
      if (details.length) {
        var detailsRow = createDetailsRow('Details', details, totalColumns)
        rows.push(detailsRow)
      }

      // action logic
      var actionLogic = _.filter(section.logic, { type: 'ACTION' })
      if (actionLogic.length) {
        var actionLogicRow = createLogicRow(actionLogic, null, totalColumns)
        rows.push(actionLogicRow)
      }

      return createTable({ rows: rows })
    }

    function createQuestionTable(question) {
      var rows = []
      var totalColumns = 3
      var hasImageColumn =
        question.choiceImagesEnabled || question.statementImagesEnabled
      if (hasImageColumn) {
        totalColumns++
      }

      // header
      var headerRow = createRow({
        children: [
          createCell({
            children: [
              createParagraph({
                text: 'Question (' + question.label + ')',
                style: 'viewType',
              }),
            ],
            columnSpan: totalColumns,
          }),
        ],
      })
      rows.push(headerRow)

      // hidden question
      if (question.isHidden) {
        var hiddenRow = createHiddenRow(totalColumns)
        rows.push(hiddenRow)
      }

      // question display logic
      var displayLogic = _.filter(question.logic, {
        type: 'DISPLAY',
        target: 'QUESTION',
      })
      if (displayLogic.length) {
        var displayLogicRow = createLogicRow(
          displayLogic,
          question,
          totalColumns
        )
        rows.push(displayLogicRow)
      }

      // title
      var titleRow = createRow({
        children: [
          createCell({
            children: [
              createParagraph({
                text: 'Q' + question.getNumber() + '.',
                style: 'viewTitle',
              }),
            ],
            width: { size: DOCXService.convertPxtoPt(110) },
            shading: { fill: '404040' },
          }),
          createCell({
            children: [
              createParagraph({
                text: question.getTitleLabel(),
                style: 'viewTitle',
              }),
            ],
            columnSpan: totalColumns - 1,
            shading: { fill: '404040' },
          }),
        ],
      })
      rows.push(titleRow)

      // description
      if (question.description) {
        var descriptionRow = createRow({
          children: [
            createCell({
              children: [
                createParagraph({ text: 'Description:', style: 'bold' }),
              ],
            }),
            createCell({
              children: [createParagraph(question.description)],
              columnSpan: totalColumns - 1,
            }),
          ],
        })
        rows.push(descriptionRow)
      }

      // image & image label
      if (question.imageUrl) {
        var imageRow = createRow({
          children: [
            createCell({
              children: [createParagraph({ text: 'Image:', style: 'bold' })],
            }),
            createCell({
              children: [
                createParagraph({
                  alignment: DOCXService.AlignmentType.CENTER,
                  children: [createImage(question.imageUrl, 300, 300)],
                }),
                createParagraph({
                  alignment: DOCXService.AlignmentType.CENTER,
                  children: [createHyperLink('Image URL', question.imageUrl)],
                }),
              ],
              columnSpan: totalColumns - 1,
            }),
          ],
        })
        rows.push(imageRow)

        if (question.imageLabel) {
          var imageLabelRow = createRow({
            children: [
              createCell({
                children: [
                  createParagraph({ text: 'Image Label:', style: 'bold' }),
                ],
              }),
              createCell({
                children: [createParagraph(question.imageLabel)],
                columnSpan: totalColumns - 1,
              }),
            ],
          })
          rows.push(imageLabelRow)
        }
      }

      // score type
      if (question.type === question.Types.SCORE) {
        var ScoreTypes = {
          nts: 'Net Trust Score',
          srs: 'Social Responsibility Score',
          custom: 'Custom',
        }
        var scoreTypeRow = createRow({
          children: [
            createCell({
              children: [createParagraph({ text: 'Type:', style: 'bold' })],
            }),
            createCell({
              children: [createParagraph(ScoreTypes[question.scoreType])],
              columnSpan: totalColumns - 1,
            }),
          ],
        })
        rows.push(scoreTypeRow)
      }

      // statements
      if (question.isUsedAsMatrix()) {
        var statementHeaderRow = createChoiceHeaderRow(true, totalColumns)
        rows.push(statementHeaderRow)

        var statementsDetails = getChoicesDetails(question, true)
        if (statementsDetails.length) {
          var statementsConfigRow = createDetailsRow(
            'Config',
            statementsDetails,
            totalColumns
          )
          rows.push(statementsConfigRow)
        }

        var statementsDisplayLogic = _.filter(question.logic, {
          type: 'DISPLAY',
          target: 'STATEMENTS',
        })
        if (statementsDisplayLogic.length) {
          var statementsDisplayLogicRow = createLogicRow(
            statementsDisplayLogic,
            question,
            totalColumns
          )
          rows.push(statementsDisplayLogicRow)
        }

        var allStatements = question.getAllStatements(true)
        _.each(allStatements, function(statement) {
          var statementNumber = getChoiceNumber(allStatements, statement.id)
          var statementRow = createChoiceRow(
            statement,
            statementNumber,
            hasImageColumn,
            question.statementImagesEnabled
          )
          rows.push(statementRow)
        })
      }

      // choices
      var isChoiceType = question.isType([
        question.Types.CHOICE,
        question.Types.SCALE,
        question.Types.RANK,
        question.Types.MATRIX,
        question.Types.CONSTANT_SUM,
        question.Types.SCORE,
        question.Types.NUMERIC,
        question.Types.NPS,
        question.Types.MOOD,
        question.Types.RATING,
        question.Types.HIDDEN_VARIABLES,
      ])
      if (isChoiceType) {
        var choiceHeaderRow = createChoiceHeaderRow(false, totalColumns)
        rows.push(choiceHeaderRow)

        var choicesDetails = getChoicesDetails(question, false)
        if (choicesDetails.length) {
          var choicesConfigRow = createDetailsRow(
            'Config',
            choicesDetails,
            totalColumns
          )
          rows.push(choicesConfigRow)
        }

        var choicesDisplayLogic = _.filter(question.logic, {
          type: 'DISPLAY',
          target: 'CHOICES',
        })
        if (choicesDisplayLogic.length) {
          var choicesDisplayLogicRow = createLogicRow(
            choicesDisplayLogic,
            question,
            totalColumns
          )
          rows.push(choicesDisplayLogicRow)
        }

        var allChoices
        if (question.type === question.Types.NUMERIC) {
          var numericValues = numericService.calculateValues(
            question.numericMin,
            question.numericMax,
            question.numericSteps,
            question.numericUnit,
            question.numericLabels
          )
          allChoices = _.map(numericValues, function(value) {
            return {
              id: value.number,
              label: value.label,
            }
          })
        } else {
          allChoices = question.getAllChoices(true)
        }
        _.each(allChoices, function(choice) {
          var choiceNumber = getChoiceNumber(allChoices, choice.id)
          var choiceRow = createChoiceRow(
            choice,
            choiceNumber,
            hasImageColumn,
            question.choiceImagesEnabled
          )
          rows.push(choiceRow)
        })
      }

      // details
      var details = getQuestionDetails(question)
      if (details.length) {
        var detailsRow = createDetailsRow('Details', details, totalColumns)
        rows.push(detailsRow)
      }

      // action logic
      var actionLogic = _.filter(question.logic, { type: 'ACTION' })
      if (actionLogic.length) {
        var actionLogicRow = createLogicRow(actionLogic, question, totalColumns)
        rows.push(actionLogicRow)
      }

      return createTable({ rows: rows })
    }

    function createChoiceHeaderRow(asStatements, totalColumns) {
      return createRow({
        children: [
          createCell({
            children: [
              createParagraph({
                text: asStatements ? 'Statements:' : 'Choices:',
                style: 'rowSubtitle',
              }),
            ],
            columnSpan: totalColumns,
            shading: { fill: 'daf0ee' },
          }),
        ],
      })
    }

    function createHiddenRow(totalColumns) {
      return createRow({
        children: [
          createCell({
            children: [
              createParagraph({
                alignment: DOCXService.AlignmentType.CENTER,
                text: 'HIDDEN',
                style: 'hidden',
              }),
            ],
            shading: { fill: '9e9ea6' },
            columnSpan: totalColumns,
          }),
        ],
      })
    }

    function createChoiceRow(
      choice,
      choiceNumber,
      hasImageColumn,
      imageEnabled
    ) {
      var cells = [
        createCell({
          children: [
            createParagraph({
              alignment: DOCXService.AlignmentType.CENTER,
              text: choice.isHeading ? '' : choiceNumber + '',
            }),
          ],
        }),
      ]

      if (imageEnabled) {
        var imageCellChildren = []
        if (choice.imageUrl) {
          imageCellChildren.push(
            createParagraph({
              alignment: DOCXService.AlignmentType.CENTER,
              children: [createImage(choice.imageUrl, 100, 100)],
            })
          )
          imageCellChildren.push(
            createParagraph({
              alignment: DOCXService.AlignmentType.CENTER,
              children: [createHyperLink('Image URL', choice.imageUrl)],
            })
          )
        } else {
          imageCellChildren.push(createParagraph(''))
        }
        cells.push(
          createCell({
            children: imageCellChildren,
            width: { size: DOCXService.convertPxtoPt(120) },
          })
        )
      }

      var choiceValueText = choice.label
      if (_.isNumber(choice.score)) {
        choiceValueText += ' (' + choice.score + ')'
      }
      cells.push(
        createCell({
          children: [
            createParagraph({
              text: choiceValueText,
              style: choice.isHeading ? 'bold' : '',
            }),
          ],
          columnSpan: hasImageColumn && !imageEnabled ? 2 : 0,
        })
      )

      var choiceAttributes = getChoiceAttributes(choice)
      cells.push(
        createCell({
          children: [
            createParagraph({
              alignment: DOCXService.AlignmentType.CENTER,
              children: [
                createText({
                  text: choiceAttributes.join(', '),
                  italics: true,
                }),
              ],
            }),
          ],
          // IMPORTANT: in order to apply width on this column,
          // it needs to be in percentage
          width: { size: 15, type: DOCXService.WidthType.PERCENTAGE },
        })
      )

      return createRow({ children: cells })
    }

    function createDetailsRow(rowLabel, details, totalColumns) {
      var detailsCellChildren = _.map(details, function(detail) {
        return createParagraph({
          children: [
            createText({ text: detail.label ? detail.label + ': ' : '' }),
            createText({ text: detail.value, bold: true }),
          ],
        })
      })
      return createRow({
        children: [
          createCell({
            children: [
              createParagraph({ text: rowLabel + ':', style: 'bold' }),
            ],
            shading: { fill: 'f2f2f2' },
          }),
          createCell({
            children: detailsCellChildren,
            columnSpan: totalColumns - 1,
            shading: { fill: 'f2f2f2' },
          }),
        ],
      })
    }

    function createLogicRow(logicArray, question, totalColumns) {
      var logicCellChildren = []
      _.each(logicArray, function(logic, logicIdx) {
        logicCellChildren.push(
          createParagraph({
            text: logic.makeSummaryTitle(question),
            style: 'bold',
          })
        )
        _.each(logic.conditions, function(condition, conditionIdx) {
          var text = [createText(condition.toString())]
          if (conditionIdx !== logic.conditions.length - 1) {
            text.push(createText({ text: ' and,', italics: true }))
          }
          logicCellChildren.push(
            createParagraph({
              children: text,
              bullet: { level: 0 },
            })
          )
        })
        if (logic.isAction()) {
          logicCellChildren.push(
            createParagraph({ text: 'Then...', style: 'bold' })
          )
          _.each(logic.actions, function(action, actionIdx) {
            var text = [createText(action.toString())]
            if (actionIdx !== logic.actions.length - 1) {
              text.push(createText({ text: ' and,', italics: true }))
            }
            logicCellChildren.push(
              createParagraph({
                children: text,
                bullet: { level: 0 },
              })
            )
          })
        }
        if (logicIdx !== logicArray.length - 1) {
          logicCellChildren.push(createLineBreak())
        }
      })
      return createRow({
        children: [
          createCell({
            children: [createParagraph({ text: 'Logic:', style: 'bold' })],
            shading: { fill: 'ffc000' },
          }),
          createCell({
            children: logicCellChildren,
            columnSpan: totalColumns - 1,
            shading: { fill: 'ffc000' },
          }),
        ],
      })
    }

    function getViewsNumberById(survey, viewIds) {
      return _(survey.views)
        .filter(function(view) {
          return _.includes(viewIds, view.value.id)
        })
        .map(function(view) {
          if (view.isQuestion()) {
            return 'Q' + view.getNumber()
          }
          if (view.isSection()) {
            return 'S' + view.getNumber()
          }
        })
        .join(', ')
    }

    function getGroupsNameById(survey, groupIds) {
      return _(survey.viewGroups)
        .filter(function(group) {
          return _.includes(groupIds, group.id)
        })
        .map('name')
        .join(', ')
    }

    function getChoiceNumber(choices, choiceId) {
      var sansHeadings = _.filter(choices, function(choice) {
        return !choice.isHeading
      })
      var idx = _.findIndex(sansHeadings, { id: choiceId })
      return idx + 1
    }

    function getChoiceAttributes(choice) {
      var attributes = []
      if (choice.isHeading) {
        attributes.push('Header')
      }
      if (choice.isCapture) {
        attributes.push('TC')
      }
      if (choice.isExclusive) {
        attributes.push('E')
      }
      if (choice.isIndependent) {
        attributes.push('I')
      }
      if (choice.isAnchored) {
        attributes.push('A')
      }
      if (choice.isHidden) {
        attributes.push('hidden')
      }
      return attributes
    }

    function getChoicesDetails(question, asStatements) {
      var details = []
      if (question.type === question.Types.NUMERIC) {
        details.push({
          label: 'Start',
          value: _.isNumber(question.numericMin)
            ? question.numericMin + ''
            : '',
        })
        details.push({
          label: 'End',
          value: _.isNumber(question.numericMax)
            ? question.numericMax + ''
            : '',
        })
        details.push({
          label: 'Unit',
          value: numericService.getUnit(question.numericUnit).label,
        })
        details.push({
          label: 'Steps',
          value: _.isNumber(question.numericSteps)
            ? question.numericSteps + ''
            : '',
        })
      } else {
        // Randomize
        var items = asStatements ? question.statements : question.choices
        var hasHeadings = _.some(items, function(item) {
          return item.isHeading
        })
        var randomizeHeadings = asStatements
          ? question.randomizeStatementHeadings
          : question.randomizeChoiceHeadings
        var randomizeChoices = asStatements
          ? question.randomizeStatements
          : question.randomizeChoices
        if (randomizeChoices) {
          var randomizeDetailsLabel = 'Randomize'
          if (hasHeadings) {
            randomizeDetailsLabel += ' ('
            randomizeDetailsLabel += asStatements ? 'Statements' : 'Choices'
            randomizeDetailsLabel += ')'
          }
          details.push({
            label: randomizeDetailsLabel,
            value: _.capitalize(randomizeChoices),
          })
        }
        if (hasHeadings && randomizeHeadings) {
          details.push({
            label: 'Randomize (Headings)',
            value: _.capitalize(randomizeHeadings),
          })
        }

        // Hide Labels
        if (
          question.isChoiceImagesEnabled(asStatements) &&
          question.isChoiceLabelsHidden(asStatements)
        ) {
          details.push({
            label: '',
            value: 'Hide Labels',
          })
        }

        // Borrow From
        var lender = asStatements
          ? question.statementLender
          : question.choiceLender
        var lenderQuestion = question.survey.getQuestion(lender.id)
        if (lenderQuestion) {
          details.push({
            label: 'Borrow from',
            value: lenderQuestion.getTitleLabel({ number: true }),
          })
          if (lenderQuestion.isUsedAsMatrix()) {
            details.push({
              label: 'Target',
              value:
                lender.target === lender.Target.STATEMENTS
                  ? 'Statements'
                  : 'Choices',
            })
          }

          var reducerOptions = lenderQuestion.isUsedAsMatrix()
            ? lender.targetsStatements()
              ? lender.MatrixStatementReducerOptions
              : lender.MatrixChoiceReducerOptions
            : lender.ChoiceReducerOptions
          details.push({
            label: 'Reducer',
            value: _.find(reducerOptions, { value: lender.reducer }).label,
          })

          var lenderChoices = lenderQuestion.getProjectedChoices(true)
          var lenderStatements = lenderQuestion.getProjectedStatements(true)

          if (
            lenderQuestion.isUsedAsMatrix() &&
            lender.target &&
            lender.isSelectionReducer()
          ) {
            if (lender.targetsStatements()) {
              var choicesDetailsValue = _(lenderChoices)
                .filter(function(choice) {
                  return _.includes(lender.choiceIds, choice.id)
                })
                .map('value')
                .join(', ')
              details.push({
                label: 'Choices',
                value: choicesDetailsValue,
              })
            }
            if (lender.targetsChoices()) {
              var statementsDetailsValue = _(lenderStatements)
                .filter(function(statement) {
                  return _.includes(lender.statementIds, statement.id)
                })
                .map('value')
                .join(', ')
              details.push({
                label: 'Statements',
                value: statementsDetailsValue,
              })
            }
          }

          if (lender.loopKey) {
            var loopVariable = lender.getLoopVariable(question.survey)
            details.push({
              label: 'Loop Variable',
              value: loopVariable && loopVariable.label,
            })
          }

          if (lender.exclusionIds.length) {
            var exclusionChoices = lender.targetsStatements()
              ? lenderStatements
              : lenderChoices
            var excludeDetailsValue = _(exclusionChoices)
              .filter(function(choice) {
                return _.includes(lender.exclusionIds, choice.id)
              })
              .map('value')
              .join(', ')
            details.push({
              label: 'Exclude',
              value: excludeDetailsValue,
            })
          }

          if (lender.persistOrder) {
            details.push({
              label: '',
              value: 'Persist Order',
            })
          }
        }
      }
      return details
    }

    function getQuestionDetails(question) {
      var details = []
      switch (question.type) {
        case question.Types.MATRIX:
          if (
            question.displayType !== question.MatrixDisplayTypes.SCALE &&
            question.displayType !== question.MatrixDisplayTypes.DND
          ) {
            details.push({
              label: 'Maximum choices respondent can select',
              value: _.isNumber(question.maxSelections)
                ? question.maxSelections + ''
                : '-',
            })
          }
          details.push({
            label: 'Display Type',
            value: _.find(question.MatrixDisplayTypeOptions, {
              value: question.displayType,
            }).label,
          })
          if (question.statementSamplingEnabled) {
            details.push({
              label: 'Number of statements to sample',
              value: _.isNumber(question.statementSamplingAmount)
                ? question.statementSamplingAmount + ''
                : '',
            })
          }
          if (question.choiceSamplingEnabled) {
            details.push({
              label: 'Number of choices to sample',
              value: _.isNumber(question.choiceSamplingAmount)
                ? question.choiceSamplingAmount + ''
                : '',
            })
          }
          if (
            question.displayType === question.MatrixDisplayTypes.DEFAULT &&
            question.flipTableAxis
          ) {
            details.push({
              label: '',
              value: 'Flip table axis',
            })
          }
          if (question.matrixUniqueSelection) {
            details.push({
              label: '',
              value: 'Unique Selection',
            })
          }
          break
        case question.Types.CHOICE:
        case question.Types.RANK:
        case question.Types.SCALE:
        case question.Types.HIDDEN_VARIABLES:
          if (question.type !== question.Types.SCALE) {
            if (_.isNumber(question.minSelections)) {
              details.push({
                label: 'Minimum choices respondent should select',
                value: question.minSelections + '',
              })
            }
            details.push({
              label: 'Maximum choices respondent can select',
              value: _.isNumber(question.maxSelections)
                ? question.maxSelections + ''
                : '-',
            })
          }
          if (question.choiceSamplingEnabled) {
            details.push({
              label: 'Number of choices to sample',
              value: _.isNumber(question.choiceSamplingAmount)
                ? question.choiceSamplingAmount + ''
                : '',
            })
          }
          break
        case question.Types.SCORE:
          if (question.scoreType === 'custom') {
            if (question.scoreLeftExtremityLabel) {
              details.push({
                label: 'Range Labels (Left Text)',
                value: question.scoreLeftExtremityLabel,
              })
            }
            if (question.scoreRightExtremityLabel) {
              details.push({
                label: 'Range Labels (Right Text)',
                value: question.scoreRightExtremityLabel,
              })
            }
            if (question.scoreLabel) {
              details.push({
                label: 'Score Label',
                value: question.scoreLabel,
              })
            }
            if (question.scoreDefinition) {
              details.push({
                label: 'Score Definition',
                value: question.scoreDefinition,
              })
            }
          }
          if (question.matrixEnabled && question.statementSamplingEnabled) {
            details.push({
              label: 'Number of statements to sample',
              value: _.isNumber(question.statementSamplingAmount)
                ? question.statementSamplingAmount + ''
                : '',
            })
          }
          break
        case question.Types.CONSTANT_SUM:
          var quantifier = _.find(question.ConstantSumTotalQuantifierOptions, {
            value: question.constantSumTotalQuantifier,
          })
          var totalRequiredValue = quantifier.label
          if (
            quantifier.value === question.ConstantSumTotalQuantifiers.BETWEEN
          ) {
            totalRequiredValue +=
              ' ' +
              question.constantSumTotalMin +
              ' ~ ' +
              question.constantSumTotalMax
          } else if (
            quantifier.value !== question.ConstantSumTotalQuantifiers.NONE
          ) {
            totalRequiredValue += ' ' + question.constantSumTotal
          }
          details.push({
            label: 'Total Required',
            value: totalRequiredValue,
          })
          details.push({
            label: 'Minimum number allowed to be entered',
            value: question.numberMinValue + '',
          })
          details.push({
            label: 'Maximum number allowed to be entered',
            value: question.numberMaxValue + '',
          })
          if (_.isNumber(question.numberInterval)) {
            details.push({
              label: 'Interval number allowed to be entered',
              value: question.numberInterval + '',
            })
          }
          if (question.textPrefix) {
            details.push({
              label: 'Prefix',
              value: question.textPrefix,
            })
          }
          if (question.textSuffix) {
            details.push({
              label: 'Suffix',
              value: question.textSuffix,
            })
          }
          if (question.constantSumShowTotal) {
            details.push({
              label: '',
              value: 'Show total',
            })
          }
          if (question.matrixEnabled && question.flipTableAxis) {
            details.push({
              label: '',
              value: 'Flip table axis',
            })
          }
          break
        case question.Types.NUMERIC:
          if (question.numericDiffLeft) {
            details.push({
              label: 'Semantic Differential (Left Text)',
              value: question.numericDiffLeft,
            })
          }
          if (question.numericDiffRight) {
            details.push({
              label: 'Semantic Differential (Right Text)',
              value: question.numericDiffRight,
            })
          }
          break
        case question.Types.RATING:
          var ratingPresets = questionEditor.getOptionPresets(
            questionEditor.OptionTags.RATING
          )
          details.push({
            label: 'Rating type',
            value: _.find(ratingPresets, {
              value: question.data.ratingTemplate,
            }).label,
          })
          break
        case question.Types.PLACES_NEAR_ME:
          if (question.maxSelections) {
            details.push({
              label: '',
              value: 'Allow Multiple Selections',
            })
          }
          if (question.isSearchEnabled) {
            details.push({
              label: '',
              value: 'Show search field',
            })
          }
          if (question.defaultSearchQuery) {
            details.push({
              label: 'Search term',
              value: question.defaultSearchQuery,
            })
          }
          break
        case question.Types.LOCATION:
          if (question.locationRestrictCountryCode) {
            details.push({
              label: 'Location restricted to',
              value: countryService.getByCC(
                question.locationRestrictCountryCode
              ).name,
            })
          } else {
            details.push({
              label: 'Location biased toward',
              value: countryService.getByCC(question.locationBiasCountryCode)
                .name,
            })
          }
          details.push({
            label: 'Location type',
            value:
              question.locationType === question.LocationTypes.DEFAULT
                ? 'Geographic'
                : 'Places',
          })
          if (question.locationType === question.LocationTypes.PLACES) {
            details.push({
              label: 'Places Types Filter',
              value: question.locationTypesFilter.length
                ? question.locationTypesFilter.join(', ')
                : 'Show all',
            })
          }
          if (question.locationSearchTerms) {
            details.push({
              label: 'Search term',
              value: question.locationSearchTerms,
            })
          }
          if (question.locationShowSearchField) {
            details.push({
              label: '',
              value: 'Show search field',
            })
          }
          if (question.locationExcludePrevious) {
            details.push({
              label: '',
              value: 'Exclude previous selected places',
            })
          }
          break
        case question.Types.SINGLE_TEXT:
          details.push({
            label: 'Number of fields',
            value: question.textFieldNumber + '',
          })
          details.push({
            label: 'Minimum required fields',
            value: question.minAnswers + '',
          })
          var Validators = {
            EMAIL: 'Email Address',
            NUMERIC: 'Numeric',
            NUMERIC_RANGE: 'Numeric Range',
          }
          details.push({
            label: 'Validator',
            value: Validators[question.textValidator] || 'Text',
          })
          if (
            question.textValidator === null &&
            _.isNumber(question.minCharacters)
          ) {
            details.push({
              label: 'Minimum characters',
              value: question.minCharacters + '',
            })
          }
          if (question.textValidator === 'NUMERIC_RANGE') {
            details.push({
              label: 'Range Minimum',
              value: question.textNumericMin + '',
            })
            details.push({
              label: 'Range Maximum',
              value: question.textNumericMax + '',
            })
          }
          if (
            question.textValidator === 'NUMERIC' ||
            question.textValidator === 'NUMERIC_RANGE'
          ) {
            if (question.textPrefix) {
              details.push({
                label: 'Prefix',
                value: question.textPrefix,
              })
            }
            if (question.textSuffix) {
              details.push({
                label: 'Suffix',
                value: question.textSuffix,
              })
            }
          }
          break
        case question.Types.TEXT:
          if (_.isNumber(question.minCharacters)) {
            details.push({
              label: 'Minimum characters',
              value: question.minCharacters + '',
            })
          }
          break
      }
      if (question.reference) {
        details.push({ label: 'Reference', value: question.reference })
      }
      if (!question.isCompulsory) {
        details.push({ label: '', value: 'Optional' })
      }
      return details
    }

    function createDocument(options) {
      options = _.defaultsDeep(options, {
        styles: {
          default: {
            document: {
              run: {
                font: 'Arial',
              },
            },
            heading1: {
              run: {
                size: 32,
                color: '444444',
              },
            },
            heading2: {
              run: {
                size: 26,
                color: '444444',
              },
            },
            heading5: {
              run: {
                size: 20,
                color: '444444',
              },
            },
          },
          paragraphStyles: [
            {
              id: 'bold',
              basedOn: 'Normal',
              next: 'Normal',
              run: {
                bold: true,
              },
            },
            {
              id: 'rowTitle',
              basedOn: 'Normal',
              next: 'Normal',
              run: {
                bold: true,
                size: 24,
              },
            },
            {
              id: 'rowSubtitle',
              basedOn: 'Normal',
              next: 'Normal',
              run: {
                bold: true,
                size: 22,
              },
            },
            {
              id: 'viewTitle',
              basedOn: 'Normal',
              next: 'Normal',
              run: {
                bold: true,
                color: 'ffffff',
                size: 24,
              },
            },
            {
              id: 'viewType',
              basedOn: 'Normal',
              next: 'Normal',
              run: {
                italics: true,
                bold: true,
                color: '808080',
              },
            },
            {
              id: 'hidden',
              basedOn: 'Normal',
              next: 'Normal',
              run: {
                bold: true,
                italics: true,
                color: 'ffffff',
                size: 24,
              },
            },
          ],
        },
      })
      return DOCXService.createDocument(options)
    }

    function createTable(options) {
      options = _.defaultsDeep(options, {
        alignment: DOCXService.AlignmentType.CENTER,
        width: { size: 100, type: DOCXService.WidthType.PERCENTAGE },
      })
      return DOCXService.createTable(options)
    }

    function createRow(options) {
      return DOCXService.createRow(options)
    }

    function createCell(options) {
      options = _.defaults(options, {
        verticalAlign: DOCXService.VerticalAlign.CENTER,
        margins: {
          top: DOCXService.convertPxtoPt(4),
          bottom: DOCXService.convertPxtoPt(4),
          left: DOCXService.convertPxtoPt(8),
          right: DOCXService.convertPxtoPt(8),
        },
      })
      return DOCXService.createCell(options)
    }

    function createParagraph(options) {
      if (_.isString(options)) {
        options = { text: options }
      }
      options = _.defaults(options, {
        alignment: DOCXService.AlignmentType.LEFT,
      })
      return DOCXService.createParagraph(options)
    }

    function createText(options) {
      return DOCXService.createText(options)
    }

    function createHyperLink(text, url) {
      var options = {
        children: [createText({ text: text, style: 'Hyperlink' })],
        link: url,
      }
      return DOCXService.createHyperLink(options)
    }

    function createImage(imageUrl, maxWidth, maxHeight) {
      var image = cachedImage[imageUrl]
      var options
      if (image) {
        var maxSize = glUtils.clampImageSize(
          image.width,
          image.height,
          maxWidth,
          maxHeight
        )
        options = {
          data: image.src,
          transformation: {
            width: maxSize.width,
            height: maxSize.height,
          },
        }
      } else {
        // if image isn't loaded, set data to empty string to display
        // broken image on the doc and set width and height to 100
        options = {
          data: '',
          transformation: {
            width: 100,
            height: 100,
          },
        }
      }
      return DOCXService.createImage(options)
    }

    function createLineBreak() {
      return createParagraph('')
    }
  }
})()
