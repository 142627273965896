;(function() {
  'use strict'

  Controller.$inject = ["geocodeService", "glAnalytics"];
  angular.module('app.core').component('placeSearch', {
    controller: Controller,
    templateUrl: 'place-search.html',
    bindings: {
      query: '@',
      searchOptions: '<',
      onSearching: '&',
      onSelect: '&',
    },
  })

  /* @ngInject */
  function Controller(geocodeService, glAnalytics) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.search = _.debounce(search, 500)
    ctrl.select = select

    function onInit() {}

    function search() {
      if (ctrl.searching) {
        return
      }

      ctrl.onSearching()
      ctrl.searching = true
      ctrl.showResults = true

      glAnalytics.track('place-search', 'search', {
        query: ctrl.query,
      })
      geocodeService
        .searchPlace(ctrl.query, ctrl.searchOptions)
        .then(function(results) {
          ctrl.searching = false
          ctrl.results = results
        })
    }

    function select(result) {
      if (!result) {
        return
      }

      glAnalytics.track('place-search', 'select', {
        placeId: result.place_id,
      })
      geocodeService
        .getPlaceDetails(result.place_id)
        .then(function(placeDetails) {
          // These mappings make sense for AU, US and some other countries. May not be meaningful to all countries.
          var address = _.pull(
            [
              nameByType(placeDetails.address_components, 'street_number'),
              nameByType(placeDetails.address_components, 'route'),
            ],
            null
          ).join(' ')
          var data = {
            sourceId: placeDetails.place_id,
            name: placeDetails.name,
            addressFormatted: placeDetails.formatted_address,
            address: address || null,
            city: nameByType(placeDetails.address_components, 'locality'),
            state: nameByType(
              placeDetails.address_components,
              'administrative_area_level_1'
            ),
            postalCode: nameByType(
              placeDetails.address_components,
              'postal_code'
            ),
            countryCode: nameByType(
              placeDetails.address_components,
              'country',
              true
            ),
            location: {
              latitude: placeDetails.geometry.location.lat(),
              longitude: placeDetails.geometry.location.lng(),
            },
          }
          ctrl.onSelect({ $addressData: data })
          ctrl.results = null
          ctrl.showResults = false
        })

      function nameByType(components, type, getShortName) {
        var name = getShortName ? 'short_name' : 'long_name'
        return _.reduce(
          components,
          function(result, value) {
            return _.includes(value.types, type) ? value[name] : result
          },
          null
        )
      }
    }
  }
})()
