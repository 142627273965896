;(function() {
  'use strict'

  Service.$inject = ["$q", "$http", "configService", "CSVExport", "glUtils", "surveyReport"];
  angular.module('glow.reporting').factory('reportQuestionExporter', Service)

  function Service($q, $http, configService, CSVExport, glUtils, surveyReport) {
    var exporters = {
      choice: exportChoice,
      scale: exportChoice,
      mood: exportChoice,
      rating: exportChoice,
      numeric: exportChoice,
      hiddenvariables: exportChoice,
      rank: exportRank,
      nps: exportNPS,
      placesnearme: exportPlaces,
      matrix: exportMatrix,
      constantsum: exportConstantSum,
      score: exportScore,
      singletext: exportTextAnswers,
      text: exportTextAnswers,
      scan: exportTextAnswers,

      // unsupported
      image: null,
    }

    return {
      toData: toData,
      canExport: canExport,
      exportWorkbook: exportWorkbook,
    }

    function toData(
      survey,
      question,
      filterSets,
      filterSetsOperator,
      responseType,
      loopKeyId
    ) {
      return $q(function(resolve, reject) {
        var filterCell = getFilterCell(filterSets, filterSetsOperator)
        var totalAnsweredCell = getTotalAnsweredCell(question)
        var responseTypeCell = getResponseTypeCell(responseType)
        var topRow = [filterCell]
        var subRow = [responseTypeCell, totalAnsweredCell]
        var bottomRows = [
          [], // empty row
          ['Answered', question.questionResults.answered],
          ['Not Asked', question.questionResults.notAsked],
        ]

        // question rows
        var deferred = $q.defer()
        var exporter = exporters[question.type]
        var isExportTextAnswers = [
          exporters.singletext,
          exporters.text,
          exporters.scan,
        ].includes(exporter)
        if (isExportTextAnswers) {
          surveyReport
            .getTextAnswers(
              survey,
              question.id,
              filterSets,
              filterSetsOperator,
              responseType,
              loopKeyId
            )
            .then(function(textAnswers) {
              var questionRows = exporter(question, textAnswers)
              deferred.resolve(questionRows)
            })
            .catch(function(error) {
              reject(error)
            })
        } else {
          var questionRows = exporter(question)
          deferred.resolve(questionRows)
        }

        // extra rows (text captured answers)
        var extraDeferred = $q.defer()
        if (question.hasOtherAnswers) {
          surveyReport
            .getOtherAnswers(
              survey,
              question.id,
              filterSets,
              filterSetsOperator,
              responseType,
              loopKeyId
            )
            .then(function(otherAnswers) {
              var extraRows = exportOtherAnswers(question, otherAnswers)
              extraDeferred.resolve(extraRows)
            })
            .catch(function(error) {
              reject(error)
            })
        } else {
          extraDeferred.resolve([]) // no extra rows
        }

        var promises = [deferred.promise, extraDeferred.promise]
        $q.all(promises).then(function(data) {
          var questionRows = data[0]
          var extraRows = data[1]
          var rows = _.concat(
            [topRow, subRow],
            questionRows,
            bottomRows,
            extraRows
          )
          resolve(new CSVExport(rows, question.title))
        })
      })
    }

    function canExport(type) {
      return !!exporters[type]
    }

    function getFilterCell(filterSets, filterSetsOperator) {
      var labels = _.map(filterSets, 'label')
      if (labels.length > 1) {
        var text = labels.join(' ' + filterSetsOperator.toUpperCase() + ' ')
        return 'Applied Filters: ' + text
      }
      return 'Applied Filters: ' + labels[0]
    }

    function getTotalAnsweredCell(question) {
      return (
        question.questionResults.answered +
        ' of ' +
        question.questionResults.count
      )
    }

    function getResponseTypeCell(responseType) {
      return 'Response Type: ' + (responseType || 'All')
    }

    function exportChoice(question) {
      var rows = []
      rows.push([question.title, 'Total (n)', 'Total (%)'])
      _.each(question.choices, function(choice) {
        var cLabel = choice.displayLabel || choice.option
        var value = question.questionResults.values[choice.id]
        var count = value ? value.count : 0
        var answered = question.questionResults.answered || 0
        var percent = (count / answered) * 100 || 0 // fallback for 0/0=NaN
        var row = [cLabel, count, percent + '%']
        rows.push(row)
      })
      return rows
    }

    function exportRank(question) {
      var numRanks = question.maxSelections || question.choices.length
      var rows = []
      var head = [question.title, 'Total (n)', 'Total (%)']
      _.times(numRanks, function(i) {
        i++
        head.push('Rank ' + i + ' (n)')
        head.push('Rank ' + i + ' (%)')
      })
      rows.push(head)
      _.each(question.choices, function(choice) {
        var cLabel = choice.displayLabel || choice.option
        var value = question.questionResults.values[choice.id]
        var cCount = value ? value.count : 0
        var cAnswered = question.questionResults.answered || 0
        var cPercent = (cCount / cAnswered) * 100 || 0 // fallback for 0/0=NaN
        var row = [cLabel, cCount, cPercent + '%']
        _.times(numRanks, function(i) {
          i++
          var ranking = value ? value.rankings[i] : null
          var rCount = ranking ? ranking.count : 0
          var rAnswered = question.questionResults.answered || 0
          var rPercent = (rCount / rAnswered) * 100 || 0 // fallback for 0/0=NaN
          row.push(rCount)
          row.push(rPercent + '%')
        })
        rows.push(row)
      })
      return rows
    }

    function exportNPS(question) {
      var rows = []
      rows.push([question.title, 'Total (n)', 'Total (%)'])
      var groups = [
        { key: 'promoter', label: 'Promoters' },
        { key: 'passive', label: 'Passives' },
        { key: 'detractor', label: 'Detractors' },
      ]
      _.each(groups, function(group) {
        var value = question.questionResults.npsGroups[group.key]
        var count = value ? value.count : 0
        var answered = question.questionResults.answered || 0
        var percent = (count / answered) * 100 || 0 // fallback for 0/0=NaN
        rows.push([group.label, count, percent + '%'])
      })
      return rows
    }

    function exportScore(question) {
      if (question.isMatrix) {
        return exportMatrix(question)
      } else {
        return exportChoice(question)
      }
    }

    function exportPlaces(question) {
      var rows = []
      rows.push([
        question.title,
        'Total (n)',
        'Total (%)',
        'Latitude',
        'Longitude',
      ])
      _.each(question.questionResults.values, function(element) {
        var name =
          element.name + (element.address ? ', ' + element.address : '')
        var count = element.count || 0
        var answered = question.questionResults.answered || 0
        var percent = (count / answered) * 100 || 0 // fallback for 0/0=NaN
        rows.push([name, count, percent + '%', element.lat, element.lng])
      })
      return rows
    }

    function exportMatrix(question) {
      var rows = []
      var head = [question.title, 'Total (n)', 'Total (%)']
      _.each(question.choices, function(choice) {
        head.push(choice.option + ' (n)')
        head.push(choice.option + ' (%)')
      })
      rows.push(head)
      _.each(question.statements, function(statement) {
        var value = question.questionResults.values[statement.id]
        var sCount = value ? value.count : 0
        var sAnswered = question.questionResults.answered || 0
        var sPercent = (sCount / sAnswered) * 100 || 0 // fallback for 0/0=NaN
        var row = [statement.statement, sCount, sPercent + '%']
        _.each(question.choices, function(choice) {
          var choiceValue = value && value.values[choice.id]
          var cCount = choiceValue ? choiceValue.count : 0
          var cPercent = (cCount / sCount) * 100 || 0 // fallback for 0/0=NaN
          row.push(cCount)
          row.push(cPercent + '%')
        })
        rows.push(row)
      })
      return rows
    }

    function exportConstantSum(question) {
      var rows = []
      if (question.isMatrix) {
        var head = [question.title, 'Total (n)', 'Total (means)']
        _.each(question.choices, function(choice) {
          head.push(choice.option + ' (n)')
          head.push(choice.option + ' (mean)')
        })
        rows.push(head)
        _.each(question.statements, function(statement) {
          var value = question.questionResults.values[statement.id]
          var sCount = value ? value.count : 0
          var sMeans = 0
          _.each(question.choices, function(choice) {
            var choiceValue = value && value.values[choice.id]
            var cMean = choiceValue ? Math.round(choiceValue.average) : 0
            sMeans += cMean
          })
          var row = [statement.statement, sCount, sMeans]
          _.each(question.choices, function(choice) {
            var choiceValue = value && value.values[choice.id]
            var cCount = choiceValue ? choiceValue.count : 0
            var cAverage = choiceValue ? Math.round(choiceValue.average) : 0
            row.push(cCount)
            row.push(cAverage)
          })
          rows.push(row)
        })
      } else {
        rows.push([question.title, 'Total (n)', 'Total (mean)'])
        _.each(question.choices, function(choice) {
          var cLabel = choice.displayLabel || choice.option
          var value = question.questionResults.values[choice.id]
          var count = value ? value.count : 0
          var average = value ? Math.round(value.average) : 0
          var row = [cLabel, count, average]
          rows.push(row)
        })
      }
      return rows
    }

    function exportTextAnswers(question, textAnswers) {
      _.remove(textAnswers, function(response) {
        return !response.textAnswer
      })
      textAnswers.sort(function(a, b) {
        var aTextAnswer = a.textAnswer.toLowerCase()
        var bTextAnswer = b.textAnswer.toLowerCase()
        if (aTextAnswer < bTextAnswer) {
          return -1
        }
        if (aTextAnswer > bTextAnswer) {
          return 1
        }
        return 0
      })
      var rows = []
      rows.push(['Response Id', question.title])
      _.each(textAnswers, function(response) {
        var row = [response.responseId]
        var texts = response.textAnswer.split('|')
        _.each(texts, function(text) {
          row.push(text)
        })
        rows.push(row)
      })
      return rows
    }

    function exportOtherAnswers(question, otherAnswers) {
      var rows = [[]] // empty row
      var head = ['Response Id', 'Choice', 'Text']
      if (question.isMatrix) {
        head.splice(1, 0, 'Statement')
      }
      rows.push(head)
      _.each(otherAnswers, function(response) {
        if (question.isMatrix) {
          _.each(response.otherAnswers, function(choiceIds, statementId) {
            var statement = _.find(question.statements, { id: statementId })
            _.each(choiceIds, function(otherAnswer, choiceId) {
              var choice = _.find(question.choices, { id: choiceId })
              var row = [
                response.responseId,
                statement.statement,
                choice.displayLabel || choice.option,
                otherAnswer,
              ]
              rows.push(row)
            })
          })
        } else {
          _.each(response.otherAnswers, function(otherAnswer, choiceId) {
            var choice = _.find(question.choices, { id: choiceId })
            var row = [
              response.responseId,
              choice.displayLabel || choice.option,
              otherAnswer,
            ]
            rows.push(row)
          })
        }
      })
      return rows
    }

    function exportWorkbook(
      survey,
      filterSets,
      filterSetsOperator,
      responseType
    ) {
      return $q(function(resolve) {
        var workbook = {
          name: survey.title,
          sheets: [],
        }
        var promises = survey.questions.map(function(question, index) {
          if (!canExport(question.type)) return
          if (question.isHidden) return
          return toData(
            survey,
            question,
            filterSets,
            filterSetsOperator,
            responseType
          )
            .then(function(data) {
              workbook.sheets[index] = {
                name: question.shortTitle,
                rows: data.rows,
              }
            })
            .catch(function(error) {
              // catch the error to continue with the process
              console.error(error)
            })
        })
        $q.all(promises).then(function() {
          // remove unsupported/empty sheets
          _.remove(workbook.sheets, function(sheet) {
            return !sheet
          })

          $http({
            method: 'POST',
            url: configService.getNodeUrl('/export-workbook'),
            data: workbook,
            responseType: 'application/json',
          }).then(function(resp) {
            var fileName = 'survey-workbook.xlsx'
            glUtils.downloadUrl(resp.data.url, fileName)
            resolve()
          })
        })
      })
    }
  }
})()
