;(function() {
  'use strict'

  Controller.$inject = ["$scope", "imagesService", "subscriberService", "glToast"];
  angular.module('core.imagePrompt').controller('ImagePrompt', Controller)

  /* @ngInject */
  function Controller($scope, imagesService, subscriberService, glToast) {
    $scope.onFileChange = onFileChange
    $scope.remove = remove

    function onFileChange(elem) {
      $scope.$applyAsync(function() {
        if (!imagesService.isValidFile(elem.files[0])) {
          return glToast.show('Invalid file type', {
            position: 'bottom-left',
          })
        }

        $scope.loading = true
        var reader = new FileReader()
        reader.readAsDataURL(elem.files[0])
        reader.onload = onImageLoad

        function onImageLoad(e) {
          var imageData = e.target.result
          var data = {
            groupId: subscriberService.getSubscriber().id,
            imageData: imageData,
            source: 'portal.glowfeed.com',
          }
          imagesService.create(data).then(function(data) {
            saveAndClose(data.imageUrl)
          })
        }
      })
    }

    function remove() {
      saveAndClose(null)
    }

    function saveAndClose(imageUrl) {
      $scope.loading = true
      $scope.options
        .save(imageUrl)
        .then($scope.dialog.close, $scope.dialog.cancel)
    }
  }
})()
