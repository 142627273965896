;(function() {
  'use strict'

  config.$inject = ["$stateProvider"];
  angular.module('app.kit-manager', []).config(config)

  /* @ngInject */
  function config($stateProvider) {
    $stateProvider.state('kit-manager', {
      url: '/kit-manager',
      parent: 'portal',
      templateUrl: 'kit-manager.html',
      controller: 'KitManager',
      controllerAs: '$ctrl',
      title: 'Kit Manager',
    })
  }
})()
