;(function() {
  'use strict'

  Controller.$inject = ["publicationService", "featureService"];
  angular.module('app.core').component('publicationCard', {
    controller: Controller,
    templateUrl: 'publication-card.html',
    bindings: {
      publication: '<',
      canRate: '<',
      newRibbon: '<',
      isTypeHidden: '<',
    },
  })

  /* @ngInject */
  function Controller(publicationService, featureService) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.rate = rate

    function onInit() {
      ctrl.imageStyle = {
        backgroundImage: 'url(' + ctrl.publication.getImageUrl(350) + ')',
      }
      ctrl.canAddPremiumPublications = featureService.canAddPremiumPublications()
    }

    function rate(stars) {
      publicationService.rate(ctrl.publication, stars)
    }
  }
})()
