;(function() {
  'use strict'

  Controller.$inject = ["$scope"];
  angular.module('app.core').component('keyUnlocker', {
    controller: Controller,
    templateUrl: 'key-unlocker.html',
    bindings: {
      secret: '@',
      onUnlock: '&',
    },
  })

  /* @ngInject */
  function Controller($scope) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.$onDestroy = onDestroy

    function onInit() {
      ctrl.keys = ''
      window.addEventListener('keydown', onKeyDown)
    }

    function onKeyDown(e) {
      ctrl.keys += e.key
      if (!ctrl.secret.startsWith(ctrl.keys)) {
        ctrl.keys = ''
      }
      if (ctrl.secret === ctrl.keys) {
        ctrl.keys = ''
        $scope.$applyAsync(function() {
          ctrl.onUnlock()
        })
      }
    }

    function onDestroy() {
      window.removeEventListener('keydown', onKeyDown)
    }
  }
})()
