;(function() {
  'use strict'

  interactionsService.$inject = ["$q", "$log", "$filter", "edgeService", "glDialog", "interactionsResource", "postsResource", "pagesService", "subscriberInteractionsResource", "subscriberService", "userService", "glSurveyUtils", "statsService"];
  angular
    .module('core.services')
    .factory('interactionsService', interactionsService)

  /*
   * ElementsService
   *
   * @ngInject */
  function interactionsService(
    $q,
    $log,
    $filter,
    edgeService,
    glDialog,
    interactionsResource,
    postsResource,
    pagesService,
    subscriberInteractionsResource,
    subscriberService,
    userService,
    glSurveyUtils,
    statsService
  ) {
    $log = $log.create('interactionsService')

    var data = {
      interactions: [],
      groupedInteractions: [],
      origGroupedInteractions: [],
    }

    var iconTypes = {
      feedback: 'gi-chat blue',
      idea: 'gi-idea blue',
      complaint: 'gi-complaint blue',
      praise: 'gi-praise blue',
      suggestion: 'gi-suggestion blue',
      request: 'gi-request blue',
      question: 'gi-question blue',
      'survey-response': 'gi-survey green',
      followup: 'gi-communicate orange',
    }

    var moods = {
      '1': { class: 'gi gi-mood-6', label: 'Very Angry' },
      '2': { class: 'gi gi-mood-5', label: 'Angry' },
      '3': { class: 'gi gi-mood-4', label: 'Meh' },
      '4': { class: 'gi gi-mood-3', label: 'Neutral' },
      '5': { class: 'gi gi-mood-2', label: 'Happy' },
      '6': { class: 'gi gi-mood-1', label: 'Very Happy' },
    }

    var service = {
      data: data,
      filter: filter,
      get: get,
      getByRelated: getByRelated,
      getBySubscriber: getBySubscriber,
      getElementInteractions: getElementInteractions,
      getSubscriberInteractions: getSubscriberInteractions,
      checkForInteractions: checkForInteractions,
      updateResources: updateResources,
      showInteraction: showInteraction,
      showBatchDeleteDialog: showBatchDeleteDialog,
      submitReply: submitReply,
      submitFollowupReply: submitFollowupReply,
      markAsActioned: markAsActioned,
      flagInteraction: flagInteraction,
      unflagInteraction: unflagInteraction,
      flagComment: flagComment,
      unflagComment: unflagComment,
    }

    return service

    /**
     * PRIVATE - transform interaction properties - add element name and format date
     * @param { Array } interactions array of interaction resources
     * @return { array } array of interaction resources with elementName appended
     */
    function transformInteractions(interactions) {
      pagesService
        .getAll()
        .then(function(pages) {
          _.forEach(interactions, function(interaction) {
            // undo backend behaviour changes (until available for all types)
            _.each(interaction.answers, function(answer) {
              glSurveyUtils.undoQuestionBehaviours(answer.question)
            })

            setParentType(interaction)
            setUpdatedAt(interaction)
            addIcon(interaction)
            addMood(interaction)
            addFollowupIcon(interaction)
            addSummary(interaction)
            addSource(interaction, pages)
            addIsDeleted(interaction)
          })
        })
        .catch(function() {
          $log.error('could not fetch pages.')
        })

      return interactions

      /* set the interactions parent type - many feedback types */
      function setParentType(interaction) {
        var feedbackTypes = [
          'feedback',
          'idea',
          'complaint',
          'praise',
          'suggestion',
          'request',
          'question',
        ]

        var isFeedbackType = _.memoize(function(type) {
          return feedbackTypes.indexOf(type) !== -1
        })

        interaction.parentType = isFeedbackType(interaction.type)
          ? 'feedback'
          : interaction.type
      }

      /* ensure updatedAt exists */
      function setUpdatedAt(interaction) {
        interaction.updatedAt = interaction.updatedAt
          ? interaction.updatedAt
          : interaction.createdAt
      }

      /* add interaction icon class */
      function addIcon(interaction) {
        interaction.iconClass = iconTypes[interaction.type] || 'gi-chat blue'
      }

      /* add mood icon class */
      function addMood(interaction) {
        interaction.mood =
          interaction.moodExit || interaction.moodEntry || interaction.mood
        interaction.moodClass = moods[interaction.mood]
          ? moods[interaction.mood].class
          : null
        interaction.moodLabel = moods[interaction.mood]
          ? moods[interaction.mood].label
          : null
      }

      /* add followup icon class */
      function addFollowupIcon(interaction) {
        var followupRequested = !!_.find(interaction.answers, function(answer) {
          return (
            answer.question.type === 'followup' &&
            answer.choiceAnswers.length > 0
          )
        })

        if (followupRequested) {
          interaction.followupClass =
            interaction.comments && interaction.comments.length > 0
              ? 'gi-chat'
              : 'gi-communicate'
        }
      }

      /* add interaction summary - displayed in interaction list */
      function addSummary(interaction) {
        switch (interaction.type) {
          case 'followup':
            interaction.summary =
              'Survey follow-up requested: ' + interaction.surveyTitle
            break
          case 'survey-response':
            interaction.summary = interaction.surveyTitle
            break
          default:
          // feedback - do nothing, already has a summary
        }
      }

      /* add source information to interaction */
      function addSource(interaction, pages) {
        var page = _.find(pages, {
          id: interaction.chain && interaction.chain.page,
        })
        interaction.sourceName = page && page.title
        interaction.sourceClass = 'gi gi-copy'
      }

      /* add isDeleted attribute to interaction - used in interaction list UI and factory  */
      function addIsDeleted(interaction) {
        interaction.isDeleted = !!interaction.responseDeletedAt
      }
    }

    /**
     * @name get
     * @description get interaction by ID
     * @param  {String} id interaction ID
     * @return {Promise}
     */
    function get(id) {
      var deferred = $q.defer()

      interactionsResource
        .getById({ id: id })
        .success(function(interaction) {
          deferred.resolve(transformInteractions([interaction])[0])
        })
        .error(deferred.reject)

      return deferred.promise
    }

    /**
     * @name getByRelated
     * @description get interaction by related object ID - i.e. by survey-response ID
     * @param  {String} id related object ID
     * @return {Promise}
     */
    function getByRelated(id) {
      var deferred = $q.defer()

      interactionsResource
        .getByRelatedObject({ relatedObjectId: id })
        .success(function(interaction) {
          deferred.resolve(transformInteractions([interaction])[0])
        })
        .error(deferred.reject)

      return deferred.promise
    }

    /**
     * @name getSubscriberInteractions
     * @description Get all interactions for a subscriber account
     * @param {String} subscriberId subscibers id
     * @param {Object} options request options
     * @return {Promise} promise
     */
    function getBySubscriber(subscriberId, options) {
      var deferred = $q.defer()

      _.extend((options = options || {}), { id: subscriberId })

      interactionsResource
        .getBySubscriber(options)
        .success(function(data) {
          deferred.resolve(transformInteractions(data))
        })
        .error(deferred.reject)

      return deferred.promise
    }

    function checkForInteractions(subscriberId) {
      return getBySubscriber(subscriberId, { top: 1 }).then(function(
        interactions
      ) {
        return !!interactions.length
      })
    }

    /**
     * @name filter
     * @description filter interactions based on inboxFilter object
     * @param  {Object} newVal inboxFilter obeject
     */
    function filter(newVal) {
      var interactions = data.origGroupedInteractions

      // filter by text
      interactions = $filter('interactionsByText')(interactions, newVal.text)

      // filter by unread
      interactions = $filter('interactionsByUnread')(
        interactions,
        newVal.unread
      )

      // filter by mood
      interactions = $filter('interactionsByMood')(interactions, newVal.mood)

      data.groupedInteractions = interactions
    }

    /**
     * @name getElementInteractions
     * @description get an elements interactions
     * @param  {String} elementId element ID
     * @param  {Object} options   query options
     * @return {Promise}
     */
    function getElementInteractions(elementId, options) {
      var deferred = $q.defer()

      _.extend((options = options || {}), { id: elementId })

      interactionsResource
        .getByElement(options)
        .success(function(resources) {
          resources = transformInteractions(resources)
          updateResources(resources)
          deferred.resolve(resources)
        })
        .error(deferred.reject)

      return deferred.promise
    }

    /**
     * @name getSubscriberInteractions
     * @description Get all interactions for a subscriber account
     * @param {String} subscriberId subscibers id
     * @return {Promise} promise
     */
    function getSubscriberInteractions(subscriberId, options) {
      var deferred = $q.defer()

      _.extend((options = options || {}), { id: subscriberId })

      interactionsResource
        .getBySubscriber(options)
        .success(function(resources) {
          resources = transformInteractions(resources)
          updateResources(resources)
          deferred.resolve(resources)
        })
        .error(deferred.reject)

      return deferred.promise
    }

    /**
     * @name updateResources
     * @description update interactions data
     * @param  {Array} resources array of interactions
     */
    function updateResources(resources) {
      data.interactions = resources
      data.groupedInteractions = $filter('groupInteractions')(resources)
      data.origGroupedInteractions = _.clone(data.groupedInteractions)
    }

    /**
     * @name showInteraction
     * @description open interaction in dialog with appropriate template
     * @param  {Object} $event      click event
     * @param  {Object} interaction interaction object
     * @param  {Object} action (optional) true if interaction is to be actioned
     * @param  {Object} canModify (optional) to allow delete interaction
     * @param  {Object} survey (optional) to sort interaction answers by survey questions order
     */
    function showInteraction($event, interaction, action, canModify, survey) {
      switch (interaction.parentType) {
        case 'feedback':
          glDialog.show({
            controller: 'FeedbackInteraction',
            targetEvent: $event,
            clickOutsideToClose: true,
            templateUrl: 'feedback-interaction.template.html',
            locals: {
              interaction: interaction,
              doAction: action,
            },
          })
          break

        case 'survey-response':
        case 'followup':
          glDialog.show({
            controller: 'SurveyInteraction',
            targetEvent: $event,
            clickOutsideToClose: true,
            templateUrl: 'survey-interaction.template.html',
            locals: {
              interaction: interaction,
              doAction: action,
              byRelated: interaction.parentType === 'survey-response',
              canModify: canModify,
              survey: survey,
            },
          })
      }

      // action interaction locally so no need to refresh list
      // TODO: only action interaction once succefully opened - make sure interaction is sent to dialog by
      // reference
      if (action) {
        interaction.actionedBy = userService.getUser().id
      }
    }

    /**
     * @name showBatchDeleteDialog
     * @description show the survey-response-batch-delete dialog
     * @param  {Array} interactions array of interactions to be deleted
     */
    function showBatchDeleteDialog(interactions) {
      glDialog.show({
        templateUrl: 'survey-response-batch-delete.template.html',
        controller: 'SurveyResponseBatchDelete',
        controllerAs: 'deleteResponses',
        clickOutsideToClose: true,
        locals: {
          interactions: interactions,
        },
      })
    }

    /**
     * @name submitReply
     * @description submit a reply to a post interaction
     * @param  {String} id interaction post ID
     * @param  {String} reply      comment to reply with
     * @return {Promise}
     */
    function submitReply(id, reply) {
      var deferred = $q.defer()

      postsResource
        .reply({ id: id, comment: reply })
        .success(deferred.resolve)
        .error(deferred.reject)

      return deferred.promise
    }

    /**
     * @name submitFollowupReply
     * @description submit initial reply to followup interaction - this will create the post
     * @param  {String} id    interaction ID
     * @param  {Object} reply reply object
     * @return {Promise}
     */
    function submitFollowupReply(id, reply) {
      var deferred = $q.defer()

      interactionsResource
        .reply({ id: id, comment: reply })
        .success(deferred.resolve)
        .error(deferred.reject)

      return deferred.promise
    }

    /**
     * @name markAsActioned
     * @description mark an interation as actioned by the current user
     * @param  {Object} interaction interaction object
     */
    function markAsActioned(interaction) {
      var data = {
        id: subscriberService.getSubscriber().id,
        interactionId: interaction.id,
      }

      // optimistically mark interaction as read
      subscriberInteractionsResource
        .markAsActioned(data)
        .success(angular.noop)
        .error(angular.noop)

      // optimistically update inbox unread count
      statsService.updateStat('inboxUnread', function(value) {
        return value - 1
      })
    }

    /**
     * @name flagInteraction
     * @description flag post
     * @param  {String} postId ID of post to flag
     * @return {Promise} resolved on flag success
     */
    function flagInteraction(postId) {
      return edgeService.create('flag', 'post', postId)
    }

    /**
     * @name  unflagInteraction
     * @description unflag post
     * @param  {Straing} edgeId ID of edge to remove
     * @return {Promise} resolved on unflag sucess
     */
    function unflagInteraction(edgeId) {
      return edgeService.remove(edgeId)
    }

    /**
     * @name  flagComment
     * @description flag comment
     * @param  {String} commentId ID of comment to flag
     * @return {Promise} resolved on flag success
     */
    function flagComment(commentId) {
      return edgeService.create('flag', 'comment', commentId)
    }

    /**
     * @name unflagComment
     * @description unflag comment
     * @param  {Straing} edgeId ID of edge to remove
     * @return {Promise} resolved on unflag sucess
     */
    function unflagComment(edgeId) {
      return edgeService.remove(edgeId)
    }
  }
})()
