;(function() {
  'use strict'

  config.$inject = ["$stateProvider"];
  angular.module('app.places', []).config(config)

  /* @ngInject */
  function config($stateProvider) {
    $stateProvider.state('places', {
      url: '/places',
      parent: 'portal',
      controller: 'Places',
      controllerAs: '$ctrl',
      templateUrl: 'places.html',
      title: 'Places',
    })
  }
})()
