;(function() {
  'use strict'

  SurveyTemplateEditController.$inject = ["$log", "$state", "glUtils", "glTips", "surveyTemplateService", "surveyService"];
  angular
    .module('app.survey-templates')
    .controller('SurveyTemplateEditController', SurveyTemplateEditController)

  /* @ngInject */
  function SurveyTemplateEditController(
    $log,
    $state,
    glUtils,
    glTips,
    surveyTemplateService,
    surveyService
  ) {
    $log = $log.create('SurveyTemplateEditController')

    var ctrl = this

    // Constants
    ctrl.States = {
      LOADING: 'loading',
      ERROR: 'error',
      READY: 'ready',
      SUCCESS: 'success',
    }

    // Methods
    ctrl.setState = setState
    ctrl.isState = isState
    ctrl.onSurveyCreate = onSurveyCreate
    ctrl.onRefresh = onRefresh

    activate()

    /**
     * Initialise
     */
    function activate() {
      ctrl.setState(ctrl.States.LOADING)
      if ($state.params.cloneTemplateId) {
        ctrl.templateId = 'new'
        cloneSurveyTemplate($state.params.cloneTemplateId)
      } else if ($state.params.cloneSurveyId) {
        ctrl.templateId = 'new'
        cloneSurveyToTemplate($state.params.cloneSurveyId)
      } else {
        ctrl.templateId =
          $state.params.surveyId === 'new' ? null : $state.params.surveyId
        getSurveyTemplate()
      }
    }

    /**
     * @name getSurveyTemplates
     * @desc get all available survey templates
     */
    function getSurveyTemplate() {
      surveyTemplateService
        .getById(ctrl.templateId)
        .then(getSuccess)
        .catch(getError)
    }

    /**
     * @name cloneSurvey
     * @desc clone an existing survey to a new template
     */
    function cloneSurveyToTemplate(surveyId) {
      surveyService
        .getAsSurvey(surveyId)
        .then(function(survey) {
          sanitiseSurvey(survey)
          getSuccess(survey)
        })
        .catch(getError)
    }

    /**
     * @name cloneSurveyTemplates
     * @desc clone an existing survey template
     */
    function cloneSurveyTemplate(templateId) {
      surveyTemplateService
        .getById(templateId)
        .then(function(survey) {
          sanitiseSurvey(survey)
          getSuccess(survey)
        })
        .catch(getError)
    }

    function getSuccess(data) {
      ctrl.surveyTemplate = data
      ctrl.setState(ctrl.States.READY)
      glTips.ready()
    }

    function getError() {
      ctrl.setState(ctrl.States.ERROR)
      $log.error('could not load templates')
    }

    /**
     * @name Set State
     * @desc change state of this view
     * @param {String} state The State to change to
     */
    function setState(state) {
      ctrl.state = state
    }

    /**
     * @name Is State
     * @desc check if we are on a specified state
     * @param {String} state A State value
     */
    function isState(state) {
      return ctrl.state === state
    }

    /**
     * @name sanitiseSurvey
     * @desc sanitise the survey so there is no cross contamination between the original and cloned version
     * @param {Object} survey The survey to be sanitised
     */
    function sanitiseSurvey(survey) {
      survey.toNewTemplate()
    }

    function onSurveyCreate(surveyId) {
      glUtils.changeParams(
        { surveyId: surveyId, cloneTemplateId: null, cloneSurveyId: null },
        true
      )
    }

    function onRefresh() {
      var promise = surveyTemplateService
        .getById(ctrl.surveyTemplate.id)
        .then(function(data) {
          ctrl.surveyTemplate = data
        })
      ctrl.surveyTemplate.queue.add(promise, 'Refreshing Template')
    }
  }
})()
