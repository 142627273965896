;(function() {
  'use strict'

  Factory.$inject = ["glAnalytics"];
  angular.module('glow.reporting').factory('Filter', Factory)

  /* @ngInject */
  function Factory(glAnalytics) {
    var Types = {
      DATE: 'date',
      DIMENSION: 'dimension',
      CHANNEL: 'channel',
      QUESTION: 'question',
      PROFILE: 'profile',
      PAGE: 'page',
      ANSWERED: 'answered', // this is currently not exposed on the UI
      SEGMENT: 'segment',
    }

    var DateTypes = {
      TODAY: 'today',
      THIS_WEEK: 'this-week',
      LAST_WEEK: 'last-week',
      THIS_MONTH: 'this-month',
      LAST_MONTH: 'last-month',
      THIS_YEAR: 'this-year',
      CUSTOM: 'custom',
    }

    var QuestionCategories = {
      CHOICE: 'choice',
      TEXT: 'text',
      NPS: 'nps',
      MOOD: 'mood',
      LOCATION: 'location',
      PLACES_NEAR_ME: 'placesnearme',
      RANK: 'rank',
      NUMERIC: 'numeric',
      MATRIX: 'matrix',
      CONSTANT_SUM: 'constantsum',
      SCORE: 'score',
      HIDDEN_VARIABLES: 'hiddenvariables',
    }

    var questionTypeCategoryMap = {
      choice: QuestionCategories.CHOICE,
      scale: QuestionCategories.CHOICE,
      sex: QuestionCategories.CHOICE,
      agerange: QuestionCategories.CHOICE,
      rating: QuestionCategories.CHOICE,
      hiddenvariables: QuestionCategories.CHOICE,
      rank: QuestionCategories.RANK,
      text: QuestionCategories.TEXT,
      singletext: QuestionCategories.TEXT,
      scan: QuestionCategories.TEXT,
      nps: QuestionCategories.NPS,
      mood: QuestionCategories.MOOD,
      placesnearme: QuestionCategories.PLACES_NEAR_ME,
      location: QuestionCategories.LOCATION,
      numeric: QuestionCategories.NUMERIC,
      matrix: QuestionCategories.MATRIX,
      constantsum: QuestionCategories.CONSTANT_SUM,
      score: QuestionCategories.SCORE,
    }

    var Defaults = [
      {
        type: Types.DATE,
        data: {
          dateType: DateTypes.CUSTOM,
          start: null,
          end: null,
        },
      },
      {
        type: Types.DIMENSION,
        data: {
          category: null,
          values: [],
        },
      },
      {
        type: Types.CHANNEL,
        data: {
          values: [],
        },
      },
      {
        type: Types.QUESTION,
        data: {
          questionId: null,
          category: null,
          values: [],
        },
      },
      {
        type: Types.PROFILE,
        data: {
          questionId: null,
          category: null,
          values: [],
        },
      },
      {
        type: Types.PAGE,
        data: {
          values: [],
        },
      },
      {
        type: Types.ANSWERED,
        data: {
          questionId: null,
        },
      },
      {
        type: Types.SEGMENT,
        data: {
          values: [],
        },
      },
    ]

    function Filter(type) {
      var defaults = _.find(Defaults, { type: type })
      _.defaultsDeep(this, defaults)
      this.isNew = true
    }

    Filter.prototype.Types = Filter.Types = Types

    Filter.prototype.DateTypes = Filter.DateTypes = DateTypes

    Filter.prototype.QuestionCategories = Filter.QuestionCategories = QuestionCategories

    Filter.prototype.getQuestionCategory = Filter.getQuestionCategory = function(
      questionType
    ) {
      return questionTypeCategoryMap[questionType || this.data.questionType]
    }

    Filter.prototype.deserialize = function(data) {
      // MIGRATION:
      // rank and matrix aspect was an object with too many keys
      // we've reduced it down to simpler keys:
      // Rank: `data.aspect = <number>` (eg `2`)
      // Matrix: `data.aspect = <statementId>`
      // Date Added: 13 Oct 2017 (remove in 2018?)
      // BE Ticket: https://engagementinnovation.atlassian.net/browse/GLOWSYS-2147
      if (
        (data.data.questionType === 'rank' ||
          data.data.questionType === 'matrix') &&
        (data.data.aspect && _.isPlainObject(data.data.aspect))
      ) {
        data.data.aspect = data.data.aspect.id
        console.info('Filter: migrated rank/matrix aspect')
        glAnalytics.track('Migrations', 'migrate-filter-aspect')
      }

      this.type = data.type
      this.data = _.cloneDeep(data.data)
      if (this.type === Types.DATE) {
        this.data.start = moment(this.data.start)
        this.data.end = moment(this.data.end)
      }
      this.isNew = false
      return this
    }

    Filter.prototype.serialize = function() {
      var data = {}
      data.type = this.type
      data.data = _.cloneDeep(this.data)
      if (this.type === Types.DATE) {
        data.data.start = data.data.start.toISOString()
        data.data.end = data.data.end.toISOString()
      }
      return data
    }

    Filter.prototype.isValid = function() {
      switch (this.type) {
        case Types.QUESTION:
        case Types.PROFILE:
          return this.isValidQuestion()
        case Types.DATE:
          return this.isValidDate()
        case Types.DIMENSION:
          return this.isValidDimension()
        case Types.CHANNEL:
          return this.isValidChannel()
        case Types.PAGE:
          return this.isValidPage()
        case Types.SEGMENT:
          return this.isValidSegment()
      }
    }

    Filter.prototype.isValidQuestion = function() {
      if (!this.data.questionId) {
        return false
      }
      if (!this.data.category) {
        return false
      }
      if (!this.data.values || !this.data.values.length) {
        return false
      }
      return true
    }

    Filter.prototype.isValidDate = function() {
      return (
        this.data.start &&
        this.data.end &&
        this.data.start.isBefore(this.data.end)
      )
    }

    Filter.prototype.isValidDimension = function() {
      return this.data.category && this.data.values && this.data.values.length
    }

    Filter.prototype.isValidChannel = function() {
      return this.data.values && this.data.values.length
    }

    Filter.prototype.isValidPage = function() {
      return this.data.values && this.data.values.length
    }

    Filter.prototype.isValidSegment = function() {
      return this.data.values.length
    }

    return Filter
  }
})()
