;(function() {
  'use strict'

  consumeInviteConfig.$inject = ["$stateProvider"];
  angular.module('app.consume-invite', []).config(consumeInviteConfig)

  /* @ngInject */
  function consumeInviteConfig($stateProvider) {
    $stateProvider.state('consume-invite', {
      url: '/invites/:token?organisationName',
      params: {
        token: { dynamic: true },
        organisationName: { dynamic: true },
      },
      parent: 'portal',
      templateUrl: 'consume-invite.template.html',
      controller: 'ConsumeInviteController',
      controllerAs: '$ctrl',
      title: 'Join Organisation',
    })
  }
})()
