;(function() {
  'use strict'

  Controller.$inject = ["userService"];
  angular.module('app.core').component('viewPicker', {
    controller: Controller,
    templateUrl: 'view-picker.html',
    bindings: {
      title: '@',
      singleTab: '<',
      isQuestionOnly: '<',
      onSelect: '&',
      onCancel: '&',
    },
  })

  var lastTab = 'new'

  /* @ngInject */
  function Controller(userService) {
    var ctrl = this

    ctrl.selectView = selectView
    ctrl.selectQuestionTemplate = selectQuestionTemplate
    ctrl.selectViews = selectViews
    ctrl.$onInit = onInit
    ctrl.$onDestroy = onDestroy

    function onInit() {
      if (!ctrl.singleTab) {
        ctrl.tabs = [
          { label: 'New', value: 'new' },
          { label: 'Import from', value: 'import-from' },
          { label: 'Question library', value: 'library' },
          {
            label: 'Omni import',
            value: 'omni-import',
            hidden: !userService.isSSR(),
          },
        ]
        ctrl.tab = lastTab
      } else {
        ctrl.tab = ctrl.singleTab
      }
    }

    function selectView(questionType) {
      if (questionType) {
        ctrl.onSelect({
          $response: {
            type: 'question-type',
            value: questionType,
          },
        })
      } else {
        ctrl.onSelect({
          $response: {
            type: 'section',
          },
        })
      }
    }

    function selectQuestionTemplate(template) {
      ctrl.onSelect({
        $response: {
          type: 'question-template',
          value: template,
        },
      })
    }

    function selectViews(views, viewGroups, shuffleGroups) {
      ctrl.onSelect({
        $response: {
          type: 'views',
          value: {
            views: views,
            viewGroups: viewGroups,
            shuffleGroups: shuffleGroups,
          },
        },
      })
    }

    function onDestroy() {
      lastTab = ctrl.tab
    }
  }
})()
