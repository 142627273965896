;(function() {
  'use strict'

  QuestionTemplatesController.$inject = ["glUtils", "$scope", "$log", "$state", "configService", "viewPicker"];
  angular
    .module('app.question-templates')
    .controller('QuestionTemplatesController', QuestionTemplatesController)

  /* @ngInject */
  function QuestionTemplatesController(
    glUtils,
    $scope,
    $log,
    $state,
    configService,
    viewPicker
  ) {
    $log = $log.create('QuestionTemplatesController')

    $scope.onStateChange = onStateChange
    $scope.onSelect = onSelect
    $scope.downloadCSV = downloadCSV
    $scope.create = create

    activate()

    /* activate
     * @desc set breadcrumbs and initial state of template-list
     */
    function activate() {
      // set template-list state
      var filters = $state.params.filters
        ? JSON.parse(atob($state.params.filters))
        : {}
      var pages = parseInt($state.params.pages, 10) || 1
      $scope.state = {
        pages: pages,
        filters: {
          query: { value: filters.query },
          type: { value: filters.type },
          state: { value: filters.state },
        },
      }
    }

    /** onStateChange
     * @desc update url params with new state (pages and filters)
     * @param {Object} state the template-list state
     */
    function onStateChange(state) {
      // parse filters into a clean url-param
      var filters
      if (!_.isEmpty(state.filterString)) {
        filters = {
          query: state.filters.query.value,
          type: state.filters.type.value,
          state: state.filters.state.value,
        }
        filters = _(filters)
          .omitBy(_.isNil)
          .omitBy(_.isEmpty)
          .value()
        filters = btoa(JSON.stringify(filters))
      }
      glUtils.changeParams({ pages: state.pages, filters: filters })
    }

    /** onSelect
     * @desc fired when user clicks an item in the template-list
     */
    function onSelect(action, template) {
      if (!action) {
        return $state.go('question-templates-edit', { id: template.id })
      }
      if (action.name === 'clone') {
        return $state.go('question-templates-edit', {
          id: 'new',
          source: template.clone(),
        })
      }
    }

    /**
     * @name Show Question Picker
     * @desc shows a question picker dialog and uses the type to create a new question template
     */
    function create() {
      viewPicker.showQuestionTypes().then(function(response) {
        return $state.go('question-templates-edit', {
          questionId: 'new',
          type: response.value,
        })
      })
    }

    function downloadCSV() {
      var rows = []
      var header = ['Link', 'Question', 'Type', 'Tags', 'Status']
      rows.push(header)

      // generate a row for each template
      $scope.templates.forEach(function(template) {
        var link = configService.getSubscriberPortalUrl(
          '/question-templates/',
          template.id
        )
        var question = template.name
        var type = template.type
        var tags = template.tags.join('\r')
        var status = template.questions
          .map(function(question) {
            return question.state
          })
          .join(', ')
        rows.push([link, question, type, tags, status])
      })

      // generate the csv
      // code from https://stackoverflow.com/a/14966131/1531255
      var data = 'data:text/csv;charset=utf-8,'
      rows.forEach(function(rowArray) {
        // we wrap each cell in double-quotes to prevent
        // any commas prematurely marking the end of the cell
        var row = rowArray
          .map(function(val) {
            return '"' + val + '"'
          })
          .join(',')
        data += row + '\r\n'
      })
      var encodedUri = encodeURI(data)

      // create the link and simulate a click to download
      var link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', 'question-templates.csv')
      document.body.appendChild(link) // required for firefox
      link.click() // this will download the data file named "question-templates.csv".
    }
  }
})()
