;(function() {
  'use strict'

  Controller.$inject = ["$state", "api", "configService", "StateFactory"];
  angular.module('app.export').controller('ExportController', Controller)

  /* @ngInject */
  function Controller($state, api, configService, StateFactory) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.download = download
    ctrl.$onDestroy = onDestroy

    function onInit() {
      ctrl.exportId = $state.params.exportId
      ctrl.state = new StateFactory(['loading', 'ready', 'error'])
      ctrl.state.loading()
      check()
    }

    function check() {
      clearTimeout(ctrl.poll)
      api.exports
        .get({ id: ctrl.exportId })
        .then(function(resp) {
          if (resp.complete) {
            ctrl.isSPSS = resp.isSPSS
            ctrl.state.ready()
          } else {
            ctrl.poll = setTimeout(check, 1000)
          }
        })
        .catch(function(err) {
          console.error(err)
          ctrl.state.error()
        })
    }

    function download() {
      window.location.href = configService.getApiUrl(
        '/exports/' + ctrl.exportId + '/download'
      )
    }

    function onDestroy() {
      clearTimeout(ctrl.poll)
    }
  }
})()
