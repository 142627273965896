;(function() {
  'use strict'

  Controller.$inject = ["app", "configService", "glTips", "publicationService"];
  angular.module('app.core').controller('StoreController', Controller)

  /* @ngInject */
  function Controller(app, configService, glTips, publicationService) {
    var ctrl = this

    ctrl.isVisible = isVisible

    init()

    function init() {
      // limit the reports to 10k and sort by creation date descending
      var paginationObj = {
        top: 10000,
        skip: 0,
        orderby: 'createdAt desc',
      }
      ctrl.all = publicationService.getByView(
        'store',
        'inStore eq true', // TODO: remove once this has been filtered on BE
        paginationObj
      )
      app.setTags({
        title: 'Glow Reports Store',
        description:
          'Pre-packaged, high quality insights across a variety of topics and categories.',
        image: configService.getSubscriberPortalUrl(
          '/img/publication-store-og-image.jpg'
        ),
      })
      glTips.ready()
    }

    function isVisible(publication) {
      return publication.isFiltered(ctrl.filter)
    }
  }
})()
