;(function() {
  'use strict'

  angular.module('app.core').factory('ResponseFilterValue', Factory)

  /* @ngInject */
  function Factory() {
    var Quantifiers = {
      EQUALS: 'EQUALS',
      CONTAINS: 'CONTAINS',
      OMITS: 'OMITS',
      LESS_THAN: 'LESS_THAN',
      GREATER_THAN: 'GREATER_THAN',
    }

    function ResponseFilterValue() {
      this.applyDefaults()
    }

    ResponseFilterValue.Quantifiers = ResponseFilterValue.prototype.Quantifiers = Quantifiers

    ResponseFilterValue.prototype.applyDefaults = function() {
      _.defaultsDeep(this, {
        value: null,
        quantifier: null,
      })
    }

    ResponseFilterValue.prototype.deserialize = function(data) {
      this.value = data.value
      this.quantifier = data.quantifier
      this.applyDefaults()
      return this
    }

    ResponseFilterValue.prototype.serialize = function() {
      var data = {}
      data.value = this.value
      data.quantifier = this.quantifier
      return data
    }

    ResponseFilterValue.prototype.setQuantifier = function(value) {
      this.quantifier = value
      return this
    }

    return ResponseFilterValue
  }
})()
