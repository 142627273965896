;(function() {
  'use strict'

  Service.$inject = ["$q", "surveyReport", "LocationSet"];
  angular.module('glow.reporting').factory('locationFilter', Service)

  /* @ngInject */
  function Service($q, surveyReport, LocationSet) {
    /**
     * Cache Structure:
     *
     *  {
     *      question.id: {
     *          expires: Moment,
     *          locations: LocationSet
     *      }
     *  }
     *
     */
    var cache = {}

    return {
      getLocations: getLocations,
    }

    function getLocations(survey, questionId, responseType) {
      var deferred = $q.defer()
      var now = moment()
      var cached = cache[questionId]

      if (cached && cached.expires.isAfter(now)) {
        return $q.resolve(cached.locations)
      }

      surveyReport
        .getTextAnswers(survey, questionId, null, null, responseType)
        .then(function(textAnswers) {
          var locations = new LocationSet().fromTextAnswers(textAnswers)
          var expires = now.add(15, 'minutes')

          cache[questionId] = {
            locations: locations,
            expires: expires,
          }

          deferred.resolve(locations)
        })

      return deferred.promise
    }
  }
})()
