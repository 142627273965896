;(function() {
  'use strict'

  Controller.$inject = ["countryService", "glAnalytics"];
  angular.module('app.core').component('publicationCountrySelect', {
    controller: Controller,
    templateUrl: 'publication-country-select.html',
    bindings: {
      onChange: '&',
    },
  })

  /* @ngInject */
  function Controller(countryService, glAnalytics) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.addCountry = addCountry
    ctrl.removeCountry = removeCountry

    function onInit() {
      ctrl.Countries = _.map(countryService.getAll(), function(country) {
        return { value: country.cc, label: country.name }
      })
      ctrl.selectedCountries = []
    }

    function addCountry(countryCode) {
      if (_.some(ctrl.selectedCountries, { cc: countryCode })) {
        return
      }
      var country = countryService.getByCC(countryCode)
      ctrl.selectedCountries.push(country)
      ctrl.onChange({ $selectedCountries: ctrl.selectedCountries })
      glAnalytics.track(
        'publications',
        'filtered-by-publication-country',
        _.kebabCase(countryCode)
      )
    }

    function removeCountry(country) {
      if (!_.some(ctrl.selectedCountries, { cc: country.cc })) {
        return
      }
      _.remove(ctrl.selectedCountries, { cc: country.cc })
      ctrl.onChange({ $selectedCountries: ctrl.selectedCountries })
    }
  }
})()
