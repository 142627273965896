;(function() {
  'use strict'

  Service.$inject = ["userService", "subscriberService", "glDialog"];
  angular.module('app.core').service('uacService', Service)

  /* @ngInject */
  function Service(userService, subscriberService, glDialog) {
    var self = this

    self.canManageUsers = canManageUsers
    self.canManageProjects = canManageProjects
    self.canManageSurveys = canManageSurveys
    self.canManageChannels = canManageChannels
    self.canLaunchChannels = canLaunchChannels
    self.canManageAnalysisFilters = canManageAnalysisFilters
    self.canManageAnalysisSettings = canManageAnalysisSettings
    self.canExportAndShareAnalysis = canExportAndShareAnalysis
    self.canViewAnalysisCrosstabs = canViewAnalysisCrosstabs
    self.canManageAnalysisCrosstabs = canManageAnalysisCrosstabs
    self.canViewAnalysisResponses = canViewAnalysisResponses
    self.canManageAnalysisResponses = canManageAnalysisResponses
    self.canManageReports = canManageReports
    self.showAlertDialog = showAlertDialog

    /**
     * @description check user permission to invite, remove and revoke access of other users (except owner or other users that has this permissions or themselves)
     * @param {Boolean} showAlert
     * @return {Boolean}
     */
    function canManageUsers(showAlert) {
      var isSSR = userService.isSSR()
      var member = subscriberService.getMemberByUserId(userService.getUser().id)
      var isPermitted = isSSR || member.canManageUsers
      if (!isPermitted && showAlert) {
        showAlertDialog('manage users')
      }
      return isPermitted
    }

    /**
     * @description check user permission to create/edit projects that user have access to
     * @param {Boolean} showAlert
     * @return {Boolean}
     */
    function canManageProjects(showAlert) {
      var isSSR = userService.isSSR()
      var member = subscriberService.getMemberByUserId(userService.getUser().id)
      var isPermitted = isSSR || member.canManageProjects
      if (!isPermitted && showAlert) {
        showAlertDialog('manage projects')
      }
      return isPermitted
    }

    /**
     * @description check user permission to create/edit surveys
     * @param {Boolean} showAlert
     * @return {Boolean}
     */
    function canManageSurveys(showAlert) {
      var isSSR = userService.isSSR()
      var member = subscriberService.getMemberByUserId(userService.getUser().id)
      var isPermitted = isSSR || member.canManageSurveys
      if (!isPermitted && showAlert) {
        showAlertDialog('manage surveys')
      }
      return isPermitted
    }

    /**
     * @description check user permission to create/edit channels
     * @param {Boolean} showAlert
     * @return {Boolean}
     */
    function canManageChannels(showAlert) {
      var isSSR = userService.isSSR()
      var member = subscriberService.getMemberByUserId(userService.getUser().id)
      var isPermitted = isSSR || member.canManageChannels
      if (!isPermitted && showAlert) {
        showAlertDialog('manage channels')
      }
      return isPermitted
    }

    /**
     * @description check user permission to actually launch a channel
     * @param {Boolean} showAlert
     * @return {Boolean}
     */
    function canLaunchChannels(showAlert) {
      var isSSR = userService.isSSR()
      var member = subscriberService.getMemberByUserId(userService.getUser().id)
      var isPermitted = isSSR || member.canLaunchChannels
      if (!isPermitted && showAlert) {
        showAlertDialog('launch channels')
      }
      return isPermitted
    }

    /**
     * @description check user permission to create/edit/duplicate/delete analysis filters
     * @param {Boolean} showAlert
     * @return {Boolean}
     */
    function canManageAnalysisFilters(showAlert) {
      var isSSR = userService.isSSR()
      var member = subscriberService.getMemberByUserId(userService.getUser().id)
      var isPermitted = isSSR || member.canManageAnalysisFilters
      if (!isPermitted && showAlert) {
        showAlertDialog('manage analysis filters')
      }
      return isPermitted
    }

    /**
     * @description check user permission to create/edit/delete analysis settings
     * @param {Boolean} showAlert
     * @return {Boolean}
     */
    function canManageAnalysisSettings(showAlert) {
      var isSSR = userService.isSSR()
      var member = subscriberService.getMemberByUserId(userService.getUser().id)
      var isPermitted = isSSR || member.canManageAnalysisSettings
      if (!isPermitted && showAlert) {
        showAlertDialog('manage analysis settings')
      }
      return isPermitted
    }

    /**
     * @description check user permission to export and share analysis
     * @param {Boolean} showAlert
     * @return {Boolean}
     */
    function canExportAndShareAnalysis(showAlert) {
      var isSSR = userService.isSSR()
      var member = subscriberService.getMemberByUserId(userService.getUser().id)
      var isPermitted = isSSR || member.canExportAndShareAnalysis
      if (!isPermitted && showAlert) {
        showAlertDialog('export and share analysis')
      }
      return isPermitted
    }

    /**
     * @description check user permission to view analysis crosstabs
     * @param {Boolean} showAlert
     * @return {Boolean}
     */
    function canViewAnalysisCrosstabs(showAlert) {
      var isSSR = userService.isSSR()
      var member = subscriberService.getMemberByUserId(userService.getUser().id)
      var isPermitted = isSSR || member.canViewAnalysisCrosstabs
      if (!isPermitted && showAlert) {
        showAlertDialog('view analysis crosstabs')
      }
      return isPermitted
    }

    /**
     * @description check user permission to create/edit/delete analysis crosstabs
     * @param {Boolean} showAlert
     * @return {Boolean}
     */
    function canManageAnalysisCrosstabs(showAlert) {
      if (!userService.isLoggedIn()) return false
      var isSSR = userService.isSSR()
      var member = subscriberService.getMemberByUserId(userService.getUser().id)
      var isPermitted = isSSR || member.canManageAnalysisCrosstabs
      if (!isPermitted && showAlert) {
        showAlertDialog('manage analysis crosstabs')
      }
      return isPermitted
    }

    /**
     * @description check user permission to view analysis responses
     * @param {Boolean} showAlert
     * @return {Boolean}
     */
    function canViewAnalysisResponses(showAlert) {
      var isSSR = userService.isSSR()
      var member = subscriberService.getMemberByUserId(userService.getUser().id)
      var isPermitted = isSSR || member.canViewAnalysisResponses
      if (!isPermitted && showAlert) {
        showAlertDialog('view analysis responses')
      }
      return isPermitted
    }

    /**
     * @description check user permission to delete analysis responses
     * @param {Boolean} showAlert
     * @return {Boolean}
     */
    function canManageAnalysisResponses(showAlert) {
      var isSSR = userService.isSSR()
      var member = subscriberService.getMemberByUserId(userService.getUser().id)
      var isPermitted = isSSR || member.canManageAnalysisResponses
      if (!isPermitted && showAlert) {
        showAlertDialog('manage analysis responses')
      }
      return isPermitted
    }

    /**
     * @description check user permission to create/edit reports(publications)
     * @param {Boolean} showAlert
     * @return {Boolean}
     */
    function canManageReports(showAlert) {
      var isSSR = userService.isSSR()
      var member = subscriberService.getMemberByUserId(userService.getUser().id)
      var isPermitted = isSSR || member.canManageReports
      if (!isPermitted && showAlert) {
        showAlertDialog('manage reports')
      }
      return isPermitted
    }

    function showAlertDialog(permission) {
      glDialog.alert(
        'Not Allowed',
        "You don't have permission to " +
          permission +
          '. Please contact the owner of your organisation.'
      )
    }
  }
})()
