;(function() {
  'use strict'

  Factory.$inject = ["configService", "cloudinaryService"];
  angular.module('app.core').factory('Publisher', Factory)

  /* @ngInject */
  function Factory(configService, cloudinaryService) {
    var Defaults = {
      id: null,
      name: null,
      imageUrl: null,
      about: null,
      ownerId: null,
      isSubscribed: false,
    }

    function Publisher() {
      _.defaultsDeep(this, Defaults)
    }

    Publisher.prototype.deserialize = function(data) {
      var meta = _.isPlainObject(data.data) ? data.data : {}
      this.id = data.id
      this.name = data.name
      this.imageUrl = data.logoImageUrl
      this.about = data.about
      this.ownerId = data.ownerId
      this.isSubscribed = data.following
      this.ctaLabel = meta.ctaLabel // eg 'Website'
      this.ctaURL = meta.ctaURL // without https:// prefix
      _.defaultsDeep(this, Defaults)
      return this
    }

    Publisher.prototype.serialize = function() {
      var data = {}
      data.id = this.id
      data.name = this.name
      data.logoImageUrl = this.imageUrl
      data.about = this.about
      data.ownerId = this.ownerId
      data.following = this.isSubscribed
      data.data = {}
      data.data.ctaLabel = this.ctaLabel
      data.data.ctaURL = this.ctaURL
      return data
    }

    Publisher.prototype.isNew = function() {
      return !this.id
    }

    Publisher.prototype.getImageUrl = function(size) {
      return cloudinaryService.transform(this.imageUrl, size || 350, 'jpg')
    }

    Publisher.prototype.getStoreLink = function() {
      return configService.getSubscriberPortalUrl('/store/', this.id)
    }

    Publisher.prototype.getTags = function() {
      return {
        title: this.name,
        description: this.about,
        image: this.imageUrl,
        url: this.getStoreLink(),
      }
    }

    Publisher.prototype.hasCta = function() {
      return this.ctaLabel && this.ctaURL
    }

    Publisher.prototype.getFullCtaUrl = function() {
      return 'https://' + this.ctaURL
    }

    Publisher.prototype.validate = function() {
      var errors = []
      if (!this.name) {
        errors.push('Name is required')
      }
      if (!this.imageUrl) {
        errors.push('Logo is required')
      }
      if (!this.about) {
        errors.push('About is required')
      }
      this.errors = errors.length ? errors : null
      return !this.errors
    }

    return Publisher
  }
})()
