;(function() {
  'use strict'

  PageLinksController.$inject = ["$scope", "$log", "linkService", "clipboardService", "glDialog"];
  angular
    .module('app.page-links')
    .controller('PageLinksController', PageLinksController)

  /* @ngInject */
  function PageLinksController(
    $scope,
    $log,
    linkService,
    clipboardService,
    glDialog
  ) {
    $log = $log.create('PageLinksController')

    var vm = this

    // methods
    vm.showAddLinksToPage = showAddLinksToPage
    vm.copy = clipboardService.copy

    activate()

    /**
     * Initialise
     */
    function activate() {
      getPageLinks()
    }

    /**
     * @name getPageLinks
     * @description get list of link associated with the page
     */
    function getPageLinks() {
      linkService
        .getByPage($scope.page.page.id)
        .then(getSuccess)
        .catch(getError)

      function getSuccess(data) {
        vm.links = data
        vm.state = 'ready'
      }

      function getError() {
        vm.state = 'error'
        $log.error('could not load links')
      }
    }

    /**
     * @name showAddLinksToPage
     * @description show the add-page-link-dialog dialog
     */
    function showAddLinksToPage() {
      glDialog.show({
        templateUrl: 'add-page-links-dialog.template.html',
        controller: 'AddPageLinksDialogController',
        bindToController: true,
        controllerAs: 'addLinks',
        locals: {
          page: $scope.page.page,
        },
      })
    }
  }
})()
