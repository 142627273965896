;(function() {
  'use strict'

  angular.module('glow.reporting').component('reportInfoMood', {
    controller: Controller,
    templateUrl: 'report-info-mood.html',
    bindings: {
      question: '<',
    },
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit

    function onInit() {
      var happinessScore = {
        '1': 0,
        '2': 20,
        '3': 40,
        '4': 60,
        '5': 80,
        '6': 100,
      }
      var score = 0
      _.each(ctrl.question.questionResults.values, function(value) {
        score += happinessScore[value.optionValue] * value.count
      })
      if (score > 0) {
        score =
          (score /
            (ctrl.question.questionResults.answered * happinessScore['6'])) *
          100
      }
      ctrl.score = 'Happiness: ' + Math.round(score) + '%'
    }
  }
})()
