;(function() {
  'use strict'

  Factory.$inject = ["Asset"];
  angular.module('app.core').factory('ProjectResource', Factory)

  /* @ngInject */
  function Factory(Asset) {
    var Types = {
      FILE: 'FILE',
      LINK: 'LINK',
    }

    var TypeInfo = [
      {
        type: Types.FILE,
        label: 'File',
        icon: 'file-download',
        desc: 'Upload a file.',
        message: 'Upload a file to attach to this project.',
      },
      {
        type: Types.LINK,
        label: 'Link',
        icon: 'link',
        desc: 'Link to an external page.',
        message: 'Add a link to an external page that related to this project.',
      },
    ]

    function ProjectResource() {
      this.applyDefaults()
    }

    ProjectResource.prototype.Types = ProjectResource.Types = Types
    ProjectResource.prototype.getTypeInfo = ProjectResource.getTypeInfo = getTypeInfo

    ProjectResource.prototype.applyDefaults = function() {
      var now = moment()
      _.defaults(this, {
        id: null,
        projectId: null,
        name: null,
        type: null,
        url: null, // LINK
        assetId: null, // FILE
        createdAt: now,
        updatedAt: now,
        isRemoved: false,
      })
    }

    ProjectResource.prototype.deserialize = function(data) {
      var meta = _.isPlainObject(data.data) ? data.data : {}
      this.id = data.id
      this.projectId = data.projectId
      this.name = data.name
      this.type = this.getTypeInfo(data.type)
      this.url = data.url
      this.asset = data.asset ? new Asset().deserialize(data.asset) : null
      this.assetId = data.assetId
      this.isRemoved = meta.isRemoved
      this.createdAt = data.createdAt ? moment(data.createdAt) : null
      this.updatedAt = data.updatedAt ? moment(data.updatedAt) : null
      this.applyDefaults()
      return this
    }

    ProjectResource.prototype.serialize = function(keepAssets) {
      var data = {}
      data.id = this.id
      data.projectId = this.projectId
      data.name = this.name
      data.type = this.type.type
      data.url = this.url
      data.asset = this.asset ? this.asset.serialize() : null
      data.assetId = this.asset ? this.asset.id : this.assetId
      if (keepAssets !== true) {
        data.asset = null
      }
      data.data = {}
      data.data.isRemoved = this.isRemoved
      data.createdAt = this.createdAt && this.createdAt.toISOString()
      data.updatedAt = this.updatedAt && this.updatedAt.toISOString()
      return data
    }

    ProjectResource.prototype.isNew = function() {
      return !this.id
    }

    ProjectResource.prototype.validate = function() {
      var errors = []
      if (!this.type) {
        errors.push('Type is required.')
      }
      if (!this.name) {
        errors.push('Name is required.')
      }
      if (this.type && this.type.type === Types.FILE) {
        if (!this.asset) {
          errors.push('A file must be selected.')
        }
      }
      if (this.type && this.type.type === Types.LINK) {
        if (!this.url) {
          errors.push('A valid URL must be entered.')
        }
        if (
          !_.startsWith(this.url, 'http://') &&
          !_.startsWith(this.url, 'https://')
        ) {
          errors.push(
            'A valid URL that starts with http:// or https:// is required.'
          )
        }
      }
      this.errors = errors
      return errors.length === 0
    }

    ProjectResource.prototype.clone = function() {
      return new ProjectResource().deserialize(this.serialize(true))
    }

    ProjectResource.prototype.become = function(resource) {
      this.deserialize(resource.serialize(true))
    }

    ProjectResource.prototype.isFile = function() {
      return this.type.type === this.Types.FILE
    }

    ProjectResource.prototype.isLink = function() {
      return this.type.type === this.Types.LINK
    }

    function getTypeInfo(type) {
      if (type) {
        return _.cloneDeep(_.find(TypeInfo, { type: type }))
      }
      return _.cloneDeep(TypeInfo)
    }

    return ProjectResource
  }
})()
