;(function() {
  'use strict'

  Controller.$inject = ["colors"];
  angular.module('app.core').component('gStarburst', {
    controller: Controller,
    templateUrl: 'g-starburst.html',
    bindings: {
      backgroundColor: '@',
      labelColor: '@',
      label: '@',
    },
  })

  /* @ngInject */
  function Controller(colors) {
    var ctrl = this

    ctrl.$onInit = onInit

    function onInit() {
      ctrl.backgroundColor = ctrl.backgroundColor || colors.Colors.ORANGE_GLOW
      ctrl.labelColor = ctrl.labelColor || colors.Colors.WHITE
      ctrl.labelStyle = {
        backgroundColor: colors.parse(ctrl.backgroundColor),
        color: colors.parse(ctrl.labelColor),
      }
    }
  }
})()
