;(function() {
  'use strict'

  Controller.$inject = ["glPrefs", "subSelector"];
  angular.module('core.subSelector').component('subSelector', {
    controller: Controller,
    templateUrl: 'sub-selector.html',
    bindings: {
      onSelect: '&',
      onCancel: '&',
    },
  })

  /* @ngInject */
  function Controller(glPrefs, subSelector) {
    var ctrl = this
    var prefs = glPrefs.create('sub-selector')
    var lastReq = 0

    ctrl.$onInit = onInit
    ctrl.search = _.throttle(search, 400)
    ctrl.select = select
    ctrl.remove = remove

    function onInit() {
      ctrl.recent = prefs.get('recent') || []
    }

    function search() {
      if (ctrl.query) {
        ctrl.subscribers = []
        var req = ++lastReq
        subSelector.search(ctrl.query).then(function(subscribers) {
          if (lastReq !== req) {
            return
          }
          ctrl.subscribers = subscribers
        })
      }
    }

    function select(event, subscriber) {
      if (event.defaultPrevented) {
        return
      }
      var isNew = !_.find(ctrl.recent, { id: subscriber.id })
      if (isNew) {
        ctrl.recent.push({
          name: subscriber.name,
          id: subscriber.id,
        })
        prefs.set('recent', ctrl.recent)
      }
      ctrl.onSelect({ $id: subscriber.id })
    }

    function remove(event, subscriber) {
      event.preventDefault()

      _.remove(ctrl.recent, function(item) {
        return item.id === subscriber.id
      })
      prefs.set('recent', ctrl.recent)
    }
  }
})()
