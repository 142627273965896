;(function() {
  'use strict'

  angular.module('app.core').factory('Datapack', Factory)

  /* @ngInject */
  function Factory() {
    function Datapack(survey) {
      this.survey = survey
      this.applyDefaults()
    }

    Datapack.prototype.applyDefaults = function() {
      _.defaultsDeep(this, {
        id: null,
        surveyId: null,
        refCounts: {},
        mappings: {},
        createdAt: null,
        updatedAt: null,
      })
    }

    Datapack.prototype.deserialize = function(data) {
      this.id = data.id
      this.surveyId = data.surveyId
      this.refCounts = data.refCounts
      this.mappings = data.mappings
      this.createdAt = data.createdAt ? moment(data.createdAt) : null
      this.updatedAt = data.updatedAt ? moment(data.updatedAt) : null
      this.applyDefaults()
      return this
    }

    Datapack.prototype.serialize = function() {
      var data = {}
      data.id = this.id
      data.surveyId = this.surveyId
      data.refCounts = _.cloneDeep(this.refCounts)
      data.mappings = _.cloneDeep(this.mappings)
      data.createdAt = this.createdAt ? this.createdAt.toISOString() : null
      data.updatedAt = this.updatedAt ? this.updatedAt.toISOString() : null
      return data
    }

    Datapack.prototype.clone = function() {
      return new Datapack(this.survey).deserialize(this.serialize())
    }

    Datapack.prototype.copy = function(datapack) {
      this.deserialize(datapack.serialize())
    }

    return Datapack
  }
})()
