;(function() {
  'use strict'

  Controller.$inject = ["publicationService"];
  angular.module('app.core').controller('LibraryController', Controller)

  /* @ngInject */
  function Controller(publicationService) {
    var ctrl = this

    ctrl.isVisible = isVisible

    init()

    function init() {
      ctrl.all = publicationService.getByView('library')
    }

    function isVisible(publication) {
      return publication.isFiltered(ctrl.filter)
    }
  }
})()
