;(function() {
  'use strict'

  Controller.$inject = ["reportTable"];
  angular
    .module('glow.reporting.charts')
    .component('glReportTableQuestion', Component())

  /* @ngInject */
  function Component() {
    return {
      controller: Controller,
      templateUrl: 'report-table-question.html',
      bindings: {
        question: '<',
        isAbsolute: '<',
        filterSetGroup: '<',
        maxHeight: '<',
        height: '=',
        allowRowClick: '=',
        onRowClick: '&',
        onRowFocus: '&',
        onSort: '&',
      },
    }
  }

  /* @ngInject */
  function Controller(reportTable) {
    var ctrl = this

    ctrl.$onChanges = onChanges

    function onChanges() {
      ctrl.data = reportTable.fromQuestion(
        ctrl.question,
        ctrl.filterSetGroup.getSelected(),
        ctrl.isAbsolute
      )
    }
  }
})()
