;(function() {
  'use strict'

  angular.module('app.core').component('loginDialog', {
    controller: Controller,
    templateUrl: 'login-dialog.html',
    bindings: {
      title: '<',
      options: '<',
      onLogin: '&',
      onClose: '&',
      onRequestPasswordReset: '&',
    },
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit

    function onInit() {
      // ...
    }
  }
})()
