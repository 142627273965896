;(function() {
  'use strict'

  Controller.$inject = ["$q", "StateFactory", "surveyServiceNext", "reportLinkService", "crosstabService", "scrollService", "uacService", "glToast", "glUtils"];
  angular.module('glow.reporting').component('surveyReportCrosstabs', {
    controller: Controller,
    templateUrl: 'report-crosstabs.html',
    bindings: {
      surveyId: '<',
      reportOptions: '<',
      filterSetGroup: '<',
      surveyLabelsToRewrite: '<',
    },
  })

  /* @ngInject */
  function Controller(
    $q,
    StateFactory,
    surveyServiceNext,
    reportLinkService,
    crosstabService,
    scrollService,
    uacService,
    glToast,
    glUtils
  ) {
    var ctrl = this
    ctrl.ItemClassPrefix = 'crosstab-'

    ctrl.$onInit = $onInit
    ctrl.isZero = isZero
    ctrl.isHidden = isHidden
    ctrl.add = add
    ctrl.duplicate = duplicate
    ctrl.remove = remove
    ctrl.addBulk = addBulk
    ctrl.hasProcessingCrosstab = hasProcessingCrosstab
    ctrl.exportWorkbook = exportWorkbook
    ctrl.move = move

    function $onInit() {
      ctrl.state = new StateFactory(['loading', 'ready', 'error'])
      ctrl.state.loading()
      $q.all([loadCrosstabs(), loadSurvey()])
        .then(function() {
          ctrl.state.ready()
        })
        .catch(function() {
          ctrl.state.error()
        })
    }

    function loadCrosstabs() {
      return crosstabService
        .getBySurvey(ctrl.surveyId)
        .then(function(crosstabs) {
          ctrl.crosstabs = _.orderBy(crosstabs, 'order')
          findHiddenCrosstabs()
        })
    }

    function loadSurvey() {
      return surveyServiceNext.get(ctrl.surveyId).then(function(survey) {
        ctrl.survey = survey
        if (ctrl.surveyLabelsToRewrite) {
          ctrl.survey.rewriteLabels(ctrl.surveyLabelsToRewrite)
        }
      })
    }

    function findHiddenCrosstabs() {
      ctrl.hiddenCrosstabIds = []
      _.each(ctrl.crosstabs, function(crosstab) {
        var crosstabViewIds = _.concat(
          crosstab.primaryQuestionId,
          crosstab.secondaryQuestionIds
        )
        var hidden = false
        if (ctrl.reportOptions.visibilityMode) {
          hidden = _.some(crosstabViewIds, function(crosstabViewId) {
            return !reportLinkService.isViewVisible(
              crosstabViewId,
              ctrl.reportOptions.visibilityMode,
              ctrl.reportOptions.visibilityViewIds
            )
          })
        }
        if (hidden) {
          ctrl.hiddenCrosstabIds.push(crosstab.id)
        }
      })
    }

    function isZero() {
      return !_.some(ctrl.crosstabs, function(crosstab) {
        return !_.includes(ctrl.hiddenCrosstabIds, crosstab.id)
      })
    }

    function isHidden(crosstab) {
      return _.includes(ctrl.hiddenCrosstabIds, crosstab.id)
    }

    function add() {
      var crosstab = crosstabService.instantiate(ctrl.surveyId)
      ctrl.crosstabs.push(crosstab)
      crosstab.order = glUtils.getItemOrder(crosstab, ctrl.crosstabs)
      var index = ctrl.crosstabs.length - 1
      scrollService.animateTo('.' + ctrl.ItemClassPrefix + index)
    }

    function duplicate(crosstab) {
      var index = ctrl.crosstabs.indexOf(crosstab)
      var toIndex = index + 1
      var newCrosstab = crosstab.duplicate()
      newCrosstab.isDuplicated = true
      ctrl.crosstabs.splice(toIndex, 0, newCrosstab)
      newCrosstab.order = glUtils.getItemOrder(newCrosstab, ctrl.crosstabs)
      scrollService.animateTo('.' + ctrl.itemClassPrefix + toIndex)
    }

    function remove(crosstab) {
      _.remove(ctrl.crosstabs, crosstab)
    }

    function addBulk(crosstab, bulkPrimaryQuestionIds) {
      var index = ctrl.crosstabs.indexOf(crosstab)
      var promises = []
      _.each(bulkPrimaryQuestionIds, function(primaryQuestionId) {
        var newCrosstab = crosstab.duplicate()
        newCrosstab.primaryQuestionId = primaryQuestionId
        newCrosstab.name = ctrl.survey.getQuestion(primaryQuestionId).title
        newCrosstab.isGeneratingBulk = true
        ctrl.crosstabs.splice(++index, 0, newCrosstab)
        newCrosstab.order = glUtils.getItemOrder(newCrosstab, ctrl.crosstabs)

        var promise = crosstabService.save(newCrosstab).then(function() {
          newCrosstab.isGeneratingBulk = false
        })
        promises.push(promise)
      })
      crosstab.isSaving = true
      $q.all(promises)
        .then(function() {
          remove(crosstab)
        })
        .catch(function(err) {
          console.error(err)
          crosstab.isSaving = false
          glToast.show('Error generating crosstabs')
        })
    }

    function hasProcessingCrosstab() {
      return _.some(ctrl.crosstabs, function(crosstab) {
        return crosstab.isGenerating
      })
    }

    function exportWorkbook() {
      if (!ctrl.reportOptions.canExportReport) {
        uacService.showAlertDialog('export crosstabs workbook')
        return
      }
      if (ctrl.isExporting) return
      ctrl.isExporting = true
      var crosstabs = ctrl.crosstabs.filter(function(crosstab) {
        return !isHidden(crosstab)
      })
      crosstabService
        .exportWorkbook(
          crosstabs,
          ctrl.survey,
          ctrl.filterSetGroup,
          ctrl.absolute
        )
        .then(function() {
          glToast.show('Crosstabs exported!')
        })
        .catch(function(errMessage) {
          glToast.show('Cannot export crosstabs: ' + errMessage)
        })
        .finally(function() {
          ctrl.isExporting = false
        })
    }

    function move(crosstab, amount, index) {
      glUtils.animateMove(ctrl.ItemClassPrefix, index, amount).then(function() {
        var fromIndex = _.indexOf(ctrl.crosstabs, crosstab)
        var toIndex = fromIndex + amount
        ctrl.crosstabs.splice(
          toIndex,
          0,
          ctrl.crosstabs.splice(fromIndex, 1)[0]
        )

        var promises = []
        var order = glUtils.getItemOrder(crosstab, ctrl.crosstabs)
        if (order === null) {
          // if the order is null, that means it can't get proper order number
          // so we need to reset the whole crosstabs order number and save them all
          glUtils.resetItemsOrder(ctrl.crosstabs)
          _.each(ctrl.crosstabs, function(item) {
            if (item.isNew()) return // skip new crosstabs
            item.isSaving = true
            var promise = crosstabService.save(item).then(function() {
              item.isSaving = false
            })
            promises.push(promise)
          })
        } else {
          crosstab.order = order
          crosstab.isSaving = true
          var promise = crosstabService.save(crosstab).then(function() {
            crosstab.isSaving = false
          })
          promises.push(promise)
        }

        $q.all(promises).catch(function(err) {
          console.error(err)
          glToast.show('Error moving crosstab')
        })
      })
    }
  }
})()
