;(function() {
  'use strict'

  angular.module('glow.components').factory('LoopTracker', Factory)

  /* @ngInject */
  function Factory() {
    function LoopTracker(onModified) {
      this.selectedByLoopId = {}
      this.onModified = onModified
    }

    LoopTracker.prototype.has = function(loopId) {
      return !!this.selectedByLoopId[loopId]
    }

    LoopTracker.prototype.getSelected = function(loopId) {
      return this.selectedByLoopId[loopId] || null
    }

    LoopTracker.prototype.setSelected = function(loopId, loopKey) {
      var curr = this.getSelected(loopId)
      if (curr !== loopKey) {
        this.selectedByLoopId[loopId] = loopKey
        this.onModified()
      }
    }

    LoopTracker.prototype.getKeysParam = function() {
      var keys = []
      _.each(this.selectedByLoopId, function(loopKey) {
        keys.push(loopKey)
      })
      return keys.length ? keys.join(',') : undefined
    }

    return LoopTracker
  }
})()
