;(function() {
  'use strict'

  Service.$inject = ["api", "QueryOptimisedResponse", "Response"];
  angular.module('app.core').service('responseService', Service)

  /* @ngInject */
  function Service(api, QueryOptimisedResponse, Response) {
    this.list = list
    this.get = get

    function list(surveyId, filter) {
      return api.responses
        .list({ surveyId: surveyId, filter, top: 100 })
        .then(function(data) {
          return data.map(function(data) {
            return new QueryOptimisedResponse().deserialize(data)
          })
        })
    }

    function get(responseId) {
      return api.responses.get({ id: responseId }).then(function(data) {
        return new Response().deserialize(data)
      })
    }
  }
})()
