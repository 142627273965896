;(function() {
  'use strict'

  angular.module('app.core').component('channelWebhook', {
    controller: Controller,
    templateUrl: 'channel-webhook.html',
    bindings: {
      channel: '<',
    },
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.isEnabled = isEnabled
    ctrl.toggle = toggle

    function onInit() {
      // ...
    }

    function isEnabled() {
      return ctrl.channel.webhook.enabled
    }

    function toggle() {
      ctrl.channel.webhook.enabled = !ctrl.channel.webhook.enabled
    }
  }
})()
