;(function() {
  'use strict'

  Controller.$inject = ["resourceService", "glAnalytics"];
  angular.module('app.core').component('resourceCategoryToggle', {
    controller: Controller,
    templateUrl: 'resource-category-toggle.html',
    bindings: {
      onChange: '&',
    },
  })

  /* @ngInject */
  function Controller(resourceService, glAnalytics) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.selectAll = selectAll
    ctrl.select = select

    ctrl.categories = makeCategories()

    function onInit() {
      ctrl.allSelected = true
    }

    function selectAll() {
      ctrl.allSelected = true
      ctrl.categories = makeCategories()
      ctrl.onChange({ $selectedCategories: [] })
    }

    function select(category) {
      category.selected = !category.selected
      ctrl.selectedCategories = _.filter(ctrl.categories, { selected: true })
      ctrl.allSelected = ctrl.selectedCategories.length === 0
      ctrl.onChange({ $selectedCategories: ctrl.selectedCategories })
      if (category.selected) {
        glAnalytics.track(
          'publications',
          'filtered-by-resource-category',
          _.kebabCase(category.label)
        )
      }
    }

    function makeCategories() {
      return _.map(resourceService.CategoryInfo, function(category) {
        category.selected = false
        return category
      })
    }
  }
})()
