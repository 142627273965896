;(function() {
  'use strict'

  angular.module('glow.reporting').component('reportLegend', {
    controller: Controller,
    templateUrl: 'report-legend.html',
    bindings: {
      legend: '<',
      onHover: '&',
      onHide: '&',
    },
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onChanges = onChanges
    ctrl.toggleHidden = toggleHidden

    function onChanges() {
      // ...
    }

    function toggleHidden(item) {
      item.isHidden = !item.isHidden
      var hide = _(ctrl.legend)
        .filter({ isHidden: true })
        .map('value')
        .value()
      ctrl.onHide({ $hide: hide })
    }
  }
})()
