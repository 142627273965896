;(function() {
  'use strict'

  angular.module('glow.reporting.charts').component('glReportCloudChart', {
    templateUrl: 'report-cloud-chart.template.html',
    bindings: {
      height: '=',
      question: '=',
      allowChartClick: '=',
      onWordClick: '&',
    },
  })
})()
