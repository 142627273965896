;(function() {
  'use strict'

  angular.module('app.core').component('channelTargeting', {
    controller: Controller,
    templateUrl: 'channel-targeting.html',
    bindings: {
      channel: '<',
      showCustomUpgradeWarning: '<',
      onSave: '&',
      onChange: '&',
    },
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.toggle = toggle

    function onInit() {
      ctrl.isUpdateRestricted = ctrl.channel.panel.isUpdateRestricted()
    }

    function toggle() {
      ctrl.channel.targeting.enabled = !ctrl.channel.targeting.enabled
      if (!ctrl.channel.targeting.enabled) {
        ctrl.channel.targeting.presetId = null
        _.remove(ctrl.channel.panel.targets)
      }
    }
  }
})()
