;(function() {
  'use strict'

  config.$inject = ["$stateProvider"];
  angular.module('app.core').config(config)

  /* @ngInject */
  function config($stateProvider) {
    $stateProvider.state('auth', {
      abstract: true,
      templateUrl: 'auth.html',
      resolve: {
        /* @ngInject */
        mrp: ["plansService", function(plansService) {
          // preload a cached version of mrp for synchronous usage
          // throughout the platform
          return plansService.loadMRP()
        }],

        /* @ngInject */
        user: ["userService", function(userService) {
          if (userService.hasCredentials()) {
            return userService.getUser() || userService.login()
          }
        }],

        /* @ngInject */
        subscriber: ["user", "subscriberService", "userService", function(user, subscriberService, userService) {
          if (user) {
            var subscriberId = userService.getSubscriberId()
            return subscriberService.loadSubscriber(subscriberId)
          }
        }],

        /* @ngInject */
        publishers: ["subscriber", "publisherService", function(subscriber, publisherService) {
          if (subscriber) {
            return publisherService.getMine()
          }
        }],
      },
    })
  }
})()
