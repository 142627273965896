;(function() {
  'use strict'

  Factory.$inject = ["MarginOfError"];
  angular.module('glow.reporting').factory('ReportSettings', Factory)

  /* @ngInject */
  function Factory(MarginOfError) {
    function ReportSettings() {
      this.applyDefaults()
    }

    ReportSettings.prototype.applyDefaults = function() {
      _.defaultsDeep(this, {
        marginOfError: new MarginOfError(),
        errors: [],
      })
    }

    ReportSettings.prototype.deserialize = function(data) {
      data = _.isPlainObject(data) ? data : {}
      var marginOfErrorData = lazyMigrateMarginOfError(data.marginOfError)
      this.marginOfError.deserialize(marginOfErrorData)
      this.applyDefaults()
      return this
    }

    ReportSettings.prototype.serialize = function() {
      var data = {}
      data.marginOfError = this.marginOfError.serialize()
      return data
    }

    ReportSettings.prototype.validate = function() {
      var errors = []
      if (!this.marginOfError.validate()) {
        errors = _.concat(errors, this.marginOfError.errors)
      }
      this.errors = errors.length ? errors : null
      return !errors.length
    }

    function lazyMigrateMarginOfError(data) {
      data = _.isPlainObject(data) ? data : {}
      if (typeof data.visible !== 'undefined') {
        data.enabled = data.visible
        delete data.visible
      }
      if (typeof data.hideFromSharedReports !== 'undefined') {
        data.isHiddenFromShared = data.hideFromSharedReports
        delete data.hideFromSharedReports
      }
      return data
    }

    return ReportSettings
  }
})()
