;(function() {
  'use strict'

  Factory.$inject = ["configService", "responseTypeService"];
  angular.module('glow.components').factory('ReportLink', Factory)

  /* @ngInject */
  function Factory(configService, responseTypeService) {
    var Modes = {
      ALL: 'ALL',
      INCLUDE: 'INCLUDE',
      EXCLUDE: 'EXCLUDE',
    }

    function ReportLink() {
      this.applyDefaults()
    }

    ReportLink.prototype.Modes = ReportLink.Modes = Modes

    ReportLink.prototype.applyDefaults = function() {
      _.defaultsDeep(this, {
        id: null,
        surveyId: null,
        name: null,
        // isFilters: false,
        isRestricted: false,
        forResource: false,
        token: null,
        mode: Modes.ALL,
        viewIds: [],
        loopVariables: {},
        expiresAt: null,
        responseType: responseTypeService.Types.COMPLETE,
        canExport: false,
        errors: [],
      })
    }

    ReportLink.prototype.deserialize = function(data) {
      this.id = data.id
      this.surveyId = data.surveyId
      this.name = data.data.name
      // this.isFilters = data.filtersEnabled
      this.isRestricted = data.isRestricted
      this.forResource = data.data.forResource
      this.token = data.token
      this.mode = data.data.mode
      this.viewIds = data.data.viewIds
      this.loopVariables = _.isPlainObject(data.data.loopVariables)
        ? data.data.loopVariables
        : {}
      this.expiresAt = data.expiresAt ? moment(data.expiresAt) : null
      this.responseType = data.data.responseType
      this.canExport = data.data.canExport
      this.errors = []
      this.applyDefaults()
      return this
    }

    ReportLink.prototype.serialize = function() {
      var data = {}
      data.data = {}

      data.id = this.id
      data.surveyId = this.surveyId
      data.data.name = this.name
      // data.filtersEnabled = this.isFilters
      data.isRestricted = this.isRestricted
      data.data.forResource = this.forResource
      data.token = this.token
      data.data.mode = this.mode
      data.data.viewIds = this.viewIds.slice()
      data.data.loopVariables = this.loopVariables
      data.expiresAt = this.expiresAt && this.expiresAt.toISOString()
      data.data.responseType = this.responseType
      data.data.canExport = this.canExport
      return data
    }

    ReportLink.prototype.validate = function() {
      var errors = []
      if (this.mode === Modes.INCLUDE && !this.viewIds.length) {
        errors.push('At least one question or section required')
      }
      this.errors = errors.length ? errors : null
      return !errors.length
    }

    ReportLink.prototype.isExpired = function() {
      var now = moment()
      return this.expiresAt ? this.expiresAt.isBefore(now) : false
    }

    ReportLink.prototype.copy = function(link) {
      this.deserialize(link.serialize())
    }

    ReportLink.prototype.clone = function(link) {
      return new ReportLink().deserialize(this.serialize())
    }

    ReportLink.prototype.getUrl = function() {
      return (
        configService.getSubscriberPortalUrl() +
        '/shared-report/' +
        this.surveyId +
        '?token=' +
        this.token
      )
    }

    ReportLink.prototype.getLoopVariables = function(loopId) {
      var variables = this.loopVariables[loopId]
      if (!variables) {
        variables = []
        this.loopVariables[loopId] = variables
      }
      return variables
    }

    ReportLink.prototype.setLoopVariables = function(loopId, variables) {
      this.loopVariables[loopId] = variables
    }

    ReportLink.prototype.getAllLoopVariableIds = function() {
      var ids = []
      _.each(this.loopVariables, function(variableIds) {
        variableIds.forEach(function(variableId) {
          ids.push(variableId)
        })
      })
      return ids
    }

    return ReportLink
  }
})()
