;(function() {
  'use strict'

  Controller.$inject = ["userService", "subscriberService", "externalPanelService", "stateService", "glToast"];
  angular.module('app.account').component('accountAdmin', {
    controller: Controller,
    templateUrl: 'account-admin.html',
  })

  /* @ngInject */
  function Controller(
    userService,
    subscriberService,
    externalPanelService,
    stateService,
    glToast
  ) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.save = save

    function onInit() {
      ctrl.state = stateService.define(['loading', 'error', 'ready'])
      ctrl.subscriber = subscriberService.getSubscriber()
      ctrl.isSSR = userService.isSSR()

      ctrl.state.loading()
      externalPanelService
        .list()
        .then(function(externalPanels) {
          ctrl.externalPanels = externalPanels.filter(function(externalPanel) {
            return !externalPanel.ssrOnly
          })
          ctrl.state.ready()
        })
        .catch(function(error) {
          console.error(error)
          ctrl.state.error()
        })
    }

    function save() {
      ctrl.isSaving = true
      subscriberService
        .saveSubscriber(ctrl.subscriber)
        .catch(function() {
          glToast.action('Could not update account settings.', 'Retry', save, {
            timeout: 5000,
          })
        })
        .finally(function() {
          ctrl.isSaving = false
        })
    }
  }
})()
