;(function() {
  'use strict'

  Controller.$inject = ["$scope", "$timeout", "$window", "$document", "surveyReport", "reportQuestionExporter", "glAnalytics", "responseTypeService", "uacService", "reportMediaBtn", "glToast"];
  angular.module('glow.reporting').component('reportZoom', {
    controller: Controller,
    templateUrl: 'report-zoom.html',
    bindings: {
      survey: '<',
      question: '<',
      filterSetGroup: '<',
      loopTracker: '<',
      onClose: '&',
      onJump: '&',
    },
  })

  /* @ngInject */
  function Controller(
    $scope,
    $timeout,
    $window,
    $document,
    surveyReport,
    reportQuestionExporter,
    glAnalytics,
    responseTypeService,
    uacService,
    reportMediaBtn,
    glToast
  ) {
    var ctrl = this

    ctrl.chartIcons = {
      heatmap: 'public',
      pointmap: 'location',
      gauge: 'gauge',
      pie: 'pie-chart',
      bar: 'bar-chart',
      cloud: 'cloud',
      rating: 'bar-chart',
      // radar: 'radar',
      stacked: 'stacked-chart',
      matrixbar: 'bar-chart',
      gallery: 'gallery',
    }
    ctrl.chartPercentSupported = {
      bar: true,
      pie: true,
      stacked: true,
      matrixbar: true,
    }
    ctrl.mediaBtnType = reportMediaBtn.Types.IMAGE

    ctrl.$onChanges = onChanges
    ctrl.$onDestroy = onDestroy
    ctrl.prev = prev
    ctrl.next = next
    ctrl.jump = jump
    ctrl.getSelectedLoopKeyId = getSelectedLoopKeyId
    ctrl.exportDownload = exportDownload
    ctrl.onTableSort = onTableSort
    ctrl.toMarkdown = toMarkdown
    ctrl.canTogglePercentage = canTogglePercentage
    ctrl.togglePercentage = togglePercentage
    ctrl.canRotateChart = canRotateChart
    ctrl.canEditTheme = canEditTheme
    ctrl.editTheme = editTheme
    ctrl.shouldShowNewChart = shouldShowNewChart

    $scope.$on('survey-reporting:load-start', function() {
      ctrl.loading = true
    })
    $scope.$on('survey-reporting:load-end', function() {
      ctrl.loading = false
    })

    function init() {
      $document.on('keydown', onKeyDown)
      $window.addEventListener('resize', updateHeights)
      updateHeights()
      ctrl.hasInit = true
    }

    function onChanges(changes) {
      if (!ctrl.hasInit) init()
      if (changes.survey) {
        ctrl.questions = _.chain(ctrl.survey.tiles)
          .filter({ type: 'question' })
          .map('question')
          .value()
        ctrl.jumpOptions = _.map(ctrl.questions, function(question) {
          return {
            label: question.listTitle,
            value: question.id,
          }
        })
      }
      if (changes.question) {
        if (ctrl.question.type === 'score' && ctrl.question.isMatrix) {
          // score doesn't show "All Statements" option on statement switcher
          // so we pre-select the subType with first statement
          ctrl.subtype = ctrl.question.statements[0].id
        } else {
          ctrl.subtype = null
        }
      }
      ctrl.remounting = true
      ctrl.sortConfig = null
      ctrl.chartConfig = _.find(surveyReport.chartConfigs, function(config) {
        var isType = config.questionType === ctrl.question.type
        var hasSameMulti = _.isBoolean(config.isMulti)
          ? config.isMulti === ctrl.question.isMulti
          : true
        var hasSameMatrix = _.isBoolean(config.isMatrix)
          ? config.isMatrix === ctrl.question.isMatrix
          : true
        return isType && hasSameMulti && hasSameMatrix
      })
      ctrl.chartType = ctrl.chartConfig.default
      ctrl.isPercentage = _.defaultTo(ctrl.isPercentage, true)
      ctrl.canExport = reportQuestionExporter.canExport(ctrl.question.type)
      ctrl.isDataTable = !_.includes(
        ['singletext', 'text', 'scan', 'location', 'image'],
        ctrl.question.type
      )
      ctrl.isAnswersTable = _.includes(
        ['singletext', 'text', 'scan'],
        ctrl.question.type
      )
      ctrl.isOtherAnswersTable = ctrl.question.hasOtherAnswers

      if (ctrl.isDataTable) {
        ctrl.table = 'data'
      } else if (ctrl.isAnswersTable) {
        ctrl.table = 'answers'
      } else if (ctrl.isOtherAnswersTable) {
        ctrl.table = 'other'
      } else {
        ctrl.table = null
      }

      var questions = ctrl.questions
      var questionsReverse = questions.slice().reverse()
      var currentIdx = _.indexOf(questions, ctrl.question)
      var reverseIdx = _.indexOf(questionsReverse, ctrl.question)
      ctrl.prevQuestion = _.find(questionsReverse, function(question, idx) {
        return idx > reverseIdx && !question.isHidden
      })
      ctrl.nextQuestion = _.find(questions, function(question, idx) {
        return idx > currentIdx && !question.isHidden
      })

      // force all child components to remount. some child components
      // (chart colors, tables etc) aren't responding correctly to
      // prop changes when flipping between questions and i don't
      // have time to investigate further
      // - ash
      $timeout(function() {
        ctrl.remounting = false
      }, 5)
    }

    function onDestroy() {
      $document.off('keydown', onKeyDown)
      $window.removeEventListener('resize', updateHeights)
    }

    function onKeyDown(e) {
      if (
        document.activeElement.nodeName === 'INPUT' ||
        document.activeElement.nodeName === 'TEXTAREA'
      ) {
        return
      }
      if (e.keyCode === 37) {
        $scope.$applyAsync(function() {
          ctrl.prev()
        })
      }
      if (e.keyCode === 39) {
        $scope.$applyAsync(function() {
          ctrl.next()
        })
      }
    }

    function updateHeights() {
      $scope.$applyAsync(function() {
        // var chartHeight =
        //   $window.innerWidth < 415 ? 300 : $window.innerHeight - 340
        // limit the chart height to maximum 500px
        // ctrl.chartHeight = chartHeight > 500 ? 500 : chartHeight
        ctrl.chartHeight = 380 // matches question-chart
        // ctrl.tableMaxHeight =
        //   $window.innerWidth < 900 ? null : $window.innerHeight - 450
        ctrl.shouldShowAllMatrixBarChart = $window.innerWidth > 900
      })
    }

    function getSelectedLoopKeyId() {
      return ctrl.question.loop
        ? ctrl.loopTracker.getSelected(ctrl.question.loop.id)
        : null
    }

    function exportDownload() {
      reportQuestionExporter
        .toData(
          ctrl.survey,
          ctrl.question,
          ctrl.filterSetGroup.getSelected(),
          ctrl.filterSetGroup.getOperator(),
          responseTypeService.getSelected(),
          getSelectedLoopKeyId()
        )
        .then(function(data) {
          data.download()
        })
        .catch(function(error) {
          glToast.show('Failed to Download as CSV. Message: ' + error.message)
        })
      glAnalytics.track('ReportQuestionExport', 'download', ctrl.question.id)
    }

    function next() {
      if (!ctrl.nextQuestion) return
      ctrl.onJump({ $question: ctrl.nextQuestion })
    }

    function prev() {
      if (!ctrl.prevQuestion) return
      ctrl.onJump({ $question: ctrl.prevQuestion })
    }

    function jump(questionId) {
      var question = _.find(ctrl.questions, { id: questionId })
      ctrl.onJump({ $question: question })
    }

    function onTableSort(sortConfig) {
      ctrl.sortConfig = sortConfig
    }

    function toMarkdown(string) {
      return window.marked(string)
    }

    function canTogglePercentage() {
      return ['bar', 'pie', 'doughnut'].includes(ctrl.chartType)
    }

    function togglePercentage() {
      ctrl.isPercentage = !ctrl.isPercentage
    }

    function canRotateChart() {
      return ['bar', 'rating', 'matrixbar'].includes(ctrl.chartType)
    }

    var newChartTypes = ['bar', 'pie', 'doughnut', 'radar']
    function shouldShowNewChart() {
      return newChartTypes.includes(ctrl.chartType)
    }

    function canEditTheme() {
      return (
        ctrl.survey.options.showReportSettingsButton &&
        !_.includes(['cloud', 'gallery', 'pointmap', 'heatmap'], ctrl.chartType)
      )
    }

    function editTheme() {
      if (!ctrl.survey.options.canEditReportSettings) {
        return uacService.showAlertDialog('edit chart theme')
      }
      $scope.$emit('edit-question-theme', ctrl.question)
    }
  }
})()
