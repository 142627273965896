;(function() {
  'use strict'

  Controller.$inject = ["glAnalytics", "StateFactory", "subscriberService", "invoiceService", "invoicesService", "chargeService"];
  angular.module('app.core').component('invoices', {
    controller: Controller,
    templateUrl: 'invoices.html',
  })

  /* @ngInject */
  function Controller(
    glAnalytics,
    StateFactory,
    subscriberService,
    invoiceService,
    invoicesService,
    chargeService
  ) {
    var ctrl = this

    ctrl.tabs = [
      {
        label: 'Invoices',
        value: 'invoices',
        display: {
          type: 'invoice',
          filter: function(invoice) {
            if (ctrl.project && !hasProjectRelation(invoice, ctrl.project)) {
              return false
            }
            if (ctrl.channel && !hasChannelRelation(invoice, ctrl.channel)) {
              return false
            }
            return true
          },
        },
      },
      {
        label: 'Current Usage',
        value: 'usage',
        display: {
          type: 'upcoming',
        },
      },
      {
        label: 'Purchases',
        value: 'purchases',
        display: {
          type: 'purchase',
          filter: function(charge) {
            return !charge.invoiceId
          },
        },
      },
      {
        label: 'Subscriptions',
        value: 'subscriptions',
        display: {
          type: 'invoiceItem',
          filter: function(invoiceItem) {
            if (ctrl.project || ctrl.channel) {
              return false
            }
            return !!invoiceItem.plan
          },
        },
      },
      {
        label: 'Rewards',
        value: 'rewards',
        display: {
          type: 'invoiceItem',
          filter: function(invoiceItem) {
            if (ctrl.project && invoiceItem.project.id !== ctrl.project) {
              return false
            }
            if (ctrl.channel && invoiceItem.channel.id !== ctrl.channel) {
              return false
            }
            // NOTE: this is also firing for publication purchases.
            // To my knowledge there is currently no way to
            return !invoiceItem.plan && invoiceItem.channel.type === 'Custom'
          },
        },
      },
      {
        label: 'Panel',
        value: 'panel',
        display: {
          type: 'invoiceItem',
          filter: function(invoiceItem) {
            if (ctrl.project && invoiceItem.project.id !== ctrl.project) {
              return false
            }
            if (ctrl.channel && invoiceItem.channel.id !== ctrl.channel) {
              return false
            }
            return !invoiceItem.plan && invoiceItem.channel.type === 'Panel'
          },
        },
      },
    ]

    ctrl.$onInit = onInit
    ctrl.changeTab = changeTab
    ctrl.openInvoice = openInvoice

    function onInit() {
      ctrl.subscriber = subscriberService.getSubscriber()
      ctrl.taxCode = subscriberService.getTax().code
      ctrl.state = new StateFactory(['loading', 'error', 'ready'])
      ctrl.state.loading()
      getInvoices()
        .then(getUpcomingInvoice)
        .then(getCharges)
        .then(function() {
          changeTab(ctrl.tabs[0])
          ctrl.state.ready()
        })
        .catch(function(err) {
          console.log(err)
          ctrl.state.error()
        })
    }

    function getInvoices() {
      return invoicesService
        .getBySubscriber(ctrl.subscriber.id)
        .then(function(invoices) {
          ctrl.invoices = invoices
          ctrl.invoiceItems = _(invoices)
            .map('items')
            .flatten()
            .value()
          // Individual credit items should not be displayed
          _.remove(ctrl.invoiceItems, function(item) {
            return item.isCredit()
          })
          ctrl.projects = createProjectOptions(ctrl.invoiceItems)
          ctrl.channels = createChannelOptions(ctrl.invoiceItems)
        })
    }

    function getCharges() {
      return chargeService
        .getBySubscriber(ctrl.subscriber.id)
        .then(function(charges) {
          ctrl.charges = charges
        })
    }

    function getUpcomingInvoice() {
      return invoicesService
        .getUpcomingBySubscriber(ctrl.subscriber.id)
        .then(function(upcomingInvoice) {
          ctrl.upcomingInvoice = upcomingInvoice
        })
        .catch(function() {
          // do nothing, there is no upcoming invoice
        })
    }

    function changeTab(tab) {
      ctrl.tab = tab
    }

    function openInvoice(invoice) {
      glAnalytics.track('invoice', 'view')
      invoiceService.show(invoice)
    }

    function createProjectOptions(invoiceItems) {
      var options = _(invoiceItems)
        .filter('project.id')
        .map('project')
        .uniqBy('id')
        .map(function(project) {
          return {
            label: project.name,
            value: project.id,
          }
        })
        .value()
      options.unshift({
        label: 'All Projects',
        value: undefined,
      })
      return options
    }

    function createChannelOptions(invoiceItems) {
      var options = _(invoiceItems)
        .filter('channel.id')
        .map('channel')
        .uniqBy('id')
        .map(function(channel) {
          return {
            label: channel.name,
            value: channel.id,
          }
        })
        .value()
      options.unshift({
        label: 'All Channels',
        value: undefined,
      })
      return options
    }

    function hasProjectRelation(invoice, projectId) {
      return _.some(invoice.items, function(invoiceItem) {
        return invoiceItem.project.id === projectId
      })
    }

    function hasChannelRelation(invoice, channelId) {
      return _.some(invoice.items, function(invoiceItem) {
        return invoiceItem.channel.id === channelId
      })
    }
  }
})()
