;(function() {
  'use strict'

  questionTemplateEditorConfig.$inject = ["$stateProvider"];
  angular
    .module('app.question-templates-edit', [])
    .config(questionTemplateEditorConfig)

  /* @ngInject */
  function questionTemplateEditorConfig($stateProvider) {
    $stateProvider.state('question-templates-edit', {
      url: '/question-templates/:questionId?cloneTemplateId?type',
      params: {
        questionId: { dynamic: true },
        type: { dynamic: true },
      },
      parent: 'portal',
      templateUrl: 'question-templates-edit.html',
      controller: 'QuestionTemplateEditController',
      controllerAs: '$ctrl',
      title: 'Edit Question Template',
    })
  }
})()
