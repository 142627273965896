;(function() {
  'use strict'

  Service.$inject = ["$q", "$rootScope", "glDialog", "glAuthService", "assetService", "projectResourcesResource", "ProjectResource"];
  angular.module('app.core').service('projectResourceService', Service)

  /* @ngInject */
  function Service(
    $q,
    $rootScope,
    glDialog,
    glAuthService,
    assetService,
    projectResourcesResource,
    ProjectResource
  ) {
    var self = this

    self.byProject = {}

    self.getByProject = getByProject
    self.showEditor = showEditor
    self.save = save

    init()

    function init() {
      $rootScope.$on(glAuthService.Events.USER_CHANGE, onUserChange)
    }

    function getLoader(projectId) {
      var loader = self.byProject[projectId]
      if (!loader) {
        self.byProject[projectId] = loader = {
          loading: false,
          error: null,
          resources: null,
        }
      }
      return loader
    }

    function upsertProjectResource(projectResource) {
      var loader = getLoader(projectResource.projectId)
      if (!loader.resources) {
        loader.resources = [projectResource]
      } else {
        var match = _.find(loader.resources, { id: projectResource.id })
        if (match) {
          match.become(projectResource)
        } else {
          loader.resources.push(projectResource)
        }
      }
    }

    function getByProject(projectId) {
      var loader = getLoader(projectId)
      loader.error = null
      loader.loading = true
      projectResourcesResource
        .get({ projectId: projectId })
        .success(function(x, resp) {
          var resources = resp.map(function(data) {
            return new ProjectResource().deserialize(data)
          })
          populateAssets(resources).then(function() {
            loader.resources = resources
            loader.loading = false
          })
        })
        .error(function(error) {
          loader.error = error
          loader.loading = false
        })
      return self.byProject[projectId]
    }

    function populateAssets(resources) {
      var promises = []
      _.each(resources, function(resource) {
        if (resource.assetId) {
          promises.push(
            $q(function(resolve) {
              assetService.get(resource.assetId).then(function(asset) {
                resource.asset = asset
                resolve()
              })
            })
          )
        }
      })
      return $q.all(promises)
    }

    function save(projectResource) {
      return $q(function(resolve, reject) {
        if (!projectResource.validate() || projectResource.saveLoading) {
          return reject()
        }
        projectResource.saveError = null
        projectResource.saveLoading = true
        var action = projectResource.isNew() ? 'create' : 'update'
        projectResourcesResource[action](projectResource.serialize())
          .success(function(x, resp) {
            if (resp.id) projectResource.id = resp.id
            projectResource.saveLoading = false
            upsertProjectResource(projectResource)
            resolve(projectResource)
          })
          .error(function(error) {
            projectResource.saveError = error
            projectResource.saveLoading = false
            reject(error)
          })
      })
    }

    function showEditor(projectId, projectResource) {
      // prettier-ignore
      var template = [
        '<gl-dialog class="project-resource-editor__dialog">',
          '<project-resource-editor ',
            'project-id="projectId" ',
            'resource="projectResource" ',
            'on-done="dialog.close()" ',
            'on-cancel="dialog.cancel()" ',
          '/>',
        '</gl-dialog>',
      ]
      return glDialog.show({
        template: template.join(''),
        clickOutsideToClose: false,
        escapeToClose: true,
        locals: {
          projectId: projectId,
          projectResource: projectResource,
        },
      })
    }

    function onUserChange() {
      _.each(self.byProject, function(val, key) {
        delete self.byProject[key]
      })
    }
  }
})()
