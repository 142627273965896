;(function() {
  'use strict'

  angular.module('app.core').component('panelTargetPresetEditor', {
    controller: Controller,
    templateUrl: 'panel-target-preset-editor.html',
    bindings: {
      onSave: '&',
      onCancel: '&',
    },
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.onSubmit = onSubmit

    function onInit() {
      // ...
    }

    function onSubmit() {
      if (!ctrl.name) return
      ctrl.onSave({ $name: ctrl.name })
    }
  }
})()
