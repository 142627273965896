;(function() {
  'use strict'

  Controller.$inject = ["$window", "placeService", "configService"];
  angular.module('app.core').component('placeListItem', {
    controller: Controller,
    templateUrl: 'place-list-item.html',
    bindings: {
      place: '<',
      searchBounds: '<',
      selectable: '<',
      selected: '<',
      locked: '<',
      channel: '<',
      onSelect: '&',
    },
  })

  /* @ngInject */
  function Controller($window, placeService, configService) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.edit = edit
    ctrl.cancel = cancel
    ctrl.save = save
    ctrl.remove = remove
    ctrl.confirmRemove = confirmRemove
    ctrl.rejectRemove = rejectRemove
    ctrl.toggleSelect = toggleSelect
    ctrl.invalidate = invalidate
    ctrl.setAddress = setAddress
    ctrl.goToChannel = goToChannel

    function onInit() {
      ctrl.mapMarker = ctrl.place.hasLocation() ? ctrl.place.location : null
      ctrl.searchOptions = {
        type: 'address',
        searchBounds: ctrl.searchBounds,
      }
    }

    function edit(event) {
      event.preventDefault()
      ctrl.place.mode = 'edit'
    }

    function remove(event) {
      event.preventDefault()
      ctrl.place.mode = 'remove'
    }

    function confirmRemove() {
      ctrl.onSelect({ $selected: false })
      placeService.remove(ctrl.place).then(function() {
        ctrl.onSelect({ $selected: false })
      })
    }

    function rejectRemove() {
      ctrl.place.mode = 'view'
      ctrl.place.error = null
    }

    function cancel() {
      if (ctrl.place.isNew()) {
        placeService.remove(ctrl.place)
      } else {
        ctrl.place.revert()
        ctrl.place.mode = 'view'
      }
    }

    function save() {
      if (!ctrl.place.validate()) {
        return
      }
      placeService.save(ctrl.place).then(function() {
        ctrl.place.mode = 'view'
      })
    }

    function toggleSelect(event) {
      if (event.defaultPrevented) {
        return
      }
      if (ctrl.selectable && !ctrl.locked) {
        ctrl.onSelect({ $selected: !ctrl.selected })
      }
    }

    function invalidate() {
      // Remove the sourceId until a new one is chosen
      ctrl.place.sourceId = null
    }

    function setAddress(data) {
      ctrl.place.setAddress(data)
      ctrl.place.error = null
      ctrl.mapMarker = ctrl.place.location
    }

    function goToChannel() {
      var url =
        configService.getSubscriberPortalUrl() +
        '/surveys/' +
        ctrl.channel.survey.id +
        '?tab=channels'
      $window.open(url, '_blank')
    }
  }
})()
