;(function() {
  'use strict'

  angular.module('app.core').component('responseFilterRule', {
    controller: Controller,
    templateUrl: 'response-filter-rule.html',
    bindings: {
      root: '<',
      rule: '<',
      isReadOnly: '<readOnly',
      onChange: '&?',
    },
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this
    var NUMBER_DOT_REGEX = /^[0-9]+(\.[0-9]+)?$/

    ctrl.$onInit = onInit
    ctrl.onNumbersStrBlur = onNumbersStrBlur

    function onInit() {
      ctrl.numbersStr = ''
      if (ctrl.rule.numbers.items.length) {
        ctrl.numbersStr = ctrl.rule.numbers.items.join(',')
      }
    }

    function onNumbersStrBlur() {
      // Update numbers.items array and numbersStr value
      var numbers = getNumbersFromStr() || []
      ctrl.rule.numbers.items = numbers
      ctrl.numbersStr = numbers.join(',')
    }

    function getNumbersFromStr() {
      if (!ctrl.numbersStr) return null
      var numbers = ctrl.numbersStr
        .split(',')
        .map(function(str) {
          return NUMBER_DOT_REGEX.test(str) ? +str : null
        })
        .filter(function(number) {
          return number !== null
        })
      return numbers.length ? numbers : null
    }
  }
})()
