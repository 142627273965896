;(function() {
  'use strict'

  Controller.$inject = ["$timeout", "$q", "Channel", "channelService", "channelCostService", "helpService", "glDialog", "glToast", "productService", "placeService", "userService", "subscriberService", "accountService", "paymentMethodsService", "featureService", "clipboardService", "uacService"];
  angular.module('app.core').component('channelListItem', {
    controller: Controller,
    templateUrl: 'channel-list-item.html',
    bindings: {
      channel: '<',
      survey: '<',
      translations: '<',
      corpus: '<',
      onRemove: '&',
      onSaved: '&',
    },
  })

  /* @ngInject */
  function Controller(
    $timeout,
    $q,
    Channel,
    channelService,
    channelCostService,
    helpService,
    glDialog,
    glToast,
    productService,
    placeService,
    userService,
    subscriberService,
    accountService,
    paymentMethodsService,
    featureService,
    clipboardService,
    uacService
  ) {
    var ctrl = this
    var Types = Channel.Type
    var cachedTranslationChecks = {} /* [Translation.id]: isIncompleteBoolean */

    ctrl.$onInit = onInit
    ctrl.$onChanges = onChanges
    ctrl.edit = edit
    ctrl.canEdit = canEdit
    ctrl.save = save
    ctrl.setEnabled = setEnabled
    ctrl.cancel = cancel
    ctrl.remove = remove
    ctrl.selectProducts = selectProducts
    ctrl.selectPlaces = selectPlaces
    ctrl.help = help
    ctrl.updateCost = updateCost
    ctrl.usesUnsupportedFeature = usesUnsupportedFeature
    ctrl.usesIncompleteTranslation = usesIncompleteTranslation
    ctrl.copyLink = copyLink

    function onInit() {
      ctrl.isSSR = userService.isSSR()
      ctrl.subscriber = subscriberService.getSubscriber()
      ctrl.frozen = subscriberService.isFrozen()
      ctrl.canUseReward =
        !_.includes([Types.PANEL, Types.EXTERNAL_PANEL], ctrl.channel.type) &&
        (ctrl.channel.reward || ctrl.isSSR)
      ctrl.canUseTranslation = featureService.canUseTranslation()
      ctrl.canUseTagQuotas = featureService.canUseTagQuotas()
      ctrl.canUseChannelRedirects =
        featureService.canUseChannelRedirects() &&
        !_.includes([Types.PANEL, Types.EXTERNAL_PANEL], ctrl.channel.type)
      ctrl.canUseChannelCustomTargeting = featureService.canUseChannelCustomTargeting()
      ctrl.canUseBasicCode = ctrl.isSSR && ctrl.channel.type === Types.CUSTOM
      ctrl.isExpanded = ctrl.channel.isNew
      ctrl.isUpdateRestricted =
        ctrl.channel.type === Types.PANEL
          ? ctrl.channel.panel.isUpdateRestricted()
          : ctrl.channel.enabled
    }

    function updateCost() {
      $timeout(function() {
        ctrl.cost = channelCostService.calculate(ctrl.channel)
      })
    }

    function onChanges() {
      ctrl.channel.setActiveTags(ctrl.survey.getTags())
    }

    function edit() {
      // Clone so that network or cached copies don't overwrite current edits
      ctrl.channel = ctrl.channel.clone()
      ctrl.isExpanded = true
    }

    function canEdit() {
      if (ctrl.channel.type === ctrl.channel.Type.PLACES) {
        return ctrl.isSSR
      }

      return uacService.canManageChannels()
    }

    function checkAbility() {
      if (ctrl.isSSR) {
        return $q.resolve()
      }
      if (
        ctrl.channel.type === ctrl.channel.Type.PANEL &&
        !featureService.canAddPanel()
      ) {
        return promptUpgrade('channel-panel')
      }
      if (ctrl.channel.reward && !featureService.canAddRewards()) {
        return promptUpgrade('channel-rewards')
      }
      return $q.resolve()
    }

    function checkHostingCredit() {
      return $q(function(resolve, reject) {
        // channel not uses hosting credit dont need hosting credit
        // invoice customers dont need hosting credit and could have negative balance
        if (
          !ctrl.channel.usesHostingCredit() ||
          ctrl.subscriber.billing.paymentType === 'INVOICE'
        ) {
          return resolve()
        }
        if (
          ctrl.subscriber.hostingCredit.isZero() &&
          !ctrl.subscriber.hostingCredit.isAutoRenew()
        ) {
          glDialog.alert(
            'Insufficient Credits Balance',
            'Your account has <b>zero</b> hosting credit remaining. Please topup more credits in the Account -> Billing section'
          )
          return reject()
        }
        resolve()
      })
    }

    function checkCreditCard() {
      return $q(function(resolve, reject) {
        // channels uses hosting credit don't need a creditcard
        // invoice customers dont need a creditcard
        if (
          ctrl.channel.usesHostingCredit() ||
          ctrl.subscriber.billing.paymentType === 'INVOICE'
        ) {
          return resolve()
        }
        // check if they have a creditcard
        paymentMethodsService
          .checkPaymentMethod(ctrl.subscriber.id)
          .then(resolve)
          .catch(function() {
            // no creditcard, prompt the user to add one
            accountService
              .showPurchaseDialog({
                type: 'add-payment-method',
              })
              .then(resolve)
              .catch(reject)
          })
      })
    }

    function promptUpgrade(context) {
      return accountService.promptUpgrade({ context: context })
    }

    function confirmReward() {
      if (!ctrl.channel.reward) return $q.resolve()
      var reward = ctrl.channel.reward
      var existingReward =
        ctrl.channel.lastSnapshot && ctrl.channel.lastSnapshot.reward

      if (!existingReward) {
        return channelService.confirmReward(ctrl.channel, ctrl.survey)
      }

      var targetChanged =
        reward.spendTarget.amount !== existingReward.spendTarget
      var notifyChanged =
        reward.notifyOnSpendTarget !== existingReward.notifyOnSpendTarget
      var disableChanged =
        reward.disableChannelOnSpendTarget !==
        existingReward.disableChannelOnSpendTarget
      var requiresConfirmation =
        targetChanged || notifyChanged || disableChanged
      return requiresConfirmation
        ? channelService.confirmReward(ctrl.channel, ctrl.survey)
        : $q.resolve()
    }

    function save() {
      if (!uacService.canManageChannels(true) || !ctrl.channel.validate()) {
        return
      }

      if (ctrl.channel.targeting.enabled && ctrl.presetIsModified) {
        ctrl.channel.targeting.presetId = null
      }

      ctrl.error = null
      ctrl.isSaving = true
      channelService
        .save(ctrl.channel)
        .then(function() {
          ctrl.onSaved()
          ctrl.isExpanded = false
        })
        .catch(function(error) {
          if (error.name === 'PanelError') {
            glDialog.alert('Panel Error', error.errors.join('\n'))
          }
          if (error && error.message) {
            ctrl.error = error
          }
        })
        .finally(function() {
          ctrl.isSaving = false
        })
    }

    function setEnabled(enabled) {
      if (!uacService.canLaunchChannels(true)) return
      if (ctrl.frozen && enabled) {
        return glDialog.alert(
          'Account Frozen',
          'Your account has no valid payment method or credit remaining. Please add a valid payment method in the Account -> Billing section'
        )
      }
      if (enabled) {
        checkAbility()
          .then(checkHostingCredit)
          .then(checkCreditCard)
          .then(confirmReward)
          .then(function() {
            var cost = channelCostService.calculate(ctrl.channel)
            if (!cost.isHostingCredit) {
              ctrl.channel.responsePrice = cost.response.amount
              ctrl.channel.exitPrice = cost.exit.amount
            }
            console.log('cost', cost)
            return channelService.confirmCharges(ctrl.channel)
          })
          .then(function() {
            setEnabledCommit(enabled)
          })
      } else {
        if (ctrl.channel.type === ctrl.channel.Type.PANEL) {
          glDialog
            .confirm(
              'Stop channel',
              'Once this channel is stopped you will not be able to start it up again.'
            )
            .then(function() {
              setEnabledCommit(enabled)
            })
        } else {
          setEnabledCommit(enabled)
        }
      }
    }

    function setEnabledCommit(enabled) {
      var action = enabled ? 'enable' : 'disable'
      channelService[action](ctrl.channel).catch(function() {
        glToast.show(ctrl.channel.error)
      })
    }

    function cancel() {
      if (ctrl.channel.isNew) {
        return ctrl.onRemove()
      }
      ctrl.channel.revert()
      ctrl.isExpanded = false
    }

    function remove() {
      if (!uacService.canManageChannels(true) || ctrl.isRemoving) return

      glDialog
        .confirm(
          'Remove channel',
          'Are you sure you want to remove this channel?'
        )
        .then(function() {
          ctrl.isRemoving = true
          channelService
            .remove(ctrl.channel)
            .then(function() {
              ctrl.onRemove()
            })
            .catch(function() {
              glToast.show(ctrl.channel.error)
            })
            .finally(function() {
              ctrl.isRemoving = false
            })
        })
    }

    function selectProducts() {
      productService.showProductManagerDialog(ctrl.channel)
    }

    function selectPlaces() {
      placeService.showPlaceManagerDialog(ctrl.channel)
    }

    function help() {
      helpService.chat()
    }

    function usesUnsupportedFeature() {
      // We let users play around with panel channel custom targeting but
      // if their plan doesn't support it we don't let them save.
      // They see an warning message in the targeting UI.
      if (
        ctrl.channel.type === ctrl.channel.Type.PANEL &&
        ctrl.channel.targeting.enabled &&
        (!ctrl.channel.targeting.presetId ||
          (ctrl.channel.targeting.presetId && ctrl.presetIsModified)) &&
        !ctrl.canUseChannelCustomTargeting
      ) {
        return true
      }
      return false
    }

    function usesIncompleteTranslation() {
      // this function runs every digest and its expensive
      // so we cache the result locally and only check it again when the
      // selected translation changes.
      if (!ctrl.channel.translationId) return false
      var isIncomplete = cachedTranslationChecks[ctrl.channel.translationId]
      if (_.isBoolean(isIncomplete)) {
        return isIncomplete
      }
      var translation = ctrl.translations.value.find(function(translation) {
        return translation.id === ctrl.channel.translationId
      })
      isIncomplete = translation && translation.isIncomplete(ctrl.corpus)
      cachedTranslationChecks[ctrl.channel.translationId] = isIncomplete
      return isIncomplete
    }

    function copyLink(event) {
      event.preventDefault()
      event.stopPropagation()
      clipboardService.copy(ctrl.channel.getShareableLink())
    }
  }
})()
