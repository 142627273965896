;(function() {
  'use strict'

  Controller.$inject = ["$element"];
  angular.module('glow.reporting').component('reportStat', {
    controller: Controller,
    templateUrl: 'report-stat.html',
    bindings: {
      name: '@',
      value: '<',
      hint: '@',
    },
  })

  /* @ngInject */
  function Controller($element) {}
})()
