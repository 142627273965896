;(function() {
  'use strict'

  Service.$inject = ["glDialog"];
  angular.module('glow.reporting').service('reportSettingsService', Service)

  /* @ngInject */
  function Service(glDialog) {
    this.showDialog = showDialog

    function showDialog(surveyId) {
      // prettier-ignore
      var template = [
        '<gl-dialog class="report-settings__dialog">',
          '<report-settings survey-id="surveyId" on-close="dialog.close()" />',
        '</gl-dialog>',
      ]
      return glDialog.show({
        template: template.join(' '),
        clickOutsideToClose: true,
        escapeToClose: true,
        locals: {
          surveyId: surveyId,
        },
      })
    }
  }
})()
