;(function() {
  'use strict'

  Controller.$inject = ["$scope", "Filter"];
  angular.module('glow.reporting').controller('ReportFilterEditor', Controller)

  /* @ngInject */
  function Controller($scope, Filter) {
    var ctrl = this
    var Types = Filter.Types

    var filterInfo = [
      {
        name: 'Question',
        type: Types.QUESTION,
        description: 'Filter by the answer to a particular question.',
      },
      {
        name: 'Profile',
        type: Types.PROFILE,
        description:
          'Filter by profiling data such as Age, Gender, and Location.',
      },
      {
        name: 'Response Channel',
        type: Types.CHANNEL,
        description: 'Filter by Response Channels that you created.',
      },
      {
        name: 'Date Range',
        type: Types.DATE,
        description: 'Filter by responses within a date range.',
      },
      {
        name: 'Dimension',
        type: Types.DIMENSION,
        description: 'Filter by dimensions associated with your responses.',
      },
      {
        name: 'Segment',
        type: Types.SEGMENT,
        description: 'Filter responses by a custom segment.',
      },
      {
        name: 'Page',
        type: Types.PAGE,
        description: 'Filter by page.',
      },
    ]

    ctrl.setType = setType
    ctrl.cancel = cancel
    ctrl.save = save

    // TODO: how can we use real component lifecycle methods with our glDialog?
    onInit()

    function onInit() {
      ctrl.filterInfo = getFilterInfo()
      ctrl.survey = $scope.survey
      if ($scope.filter) {
        ctrl.filter = _.cloneDeep($scope.filter)
        ctrl.title = getTitle()
        ctrl.subtitle = getSubtitle()
      }
    }

    function setType(type) {
      ctrl.filter = new Filter(type)
      ctrl.title = getTitle()
      ctrl.subtitle = getSubtitle()
    }

    function cancel() {
      $scope.dialog.cancel()
    }

    function save() {
      $scope.dialog.close(ctrl.filter)
    }

    function getFilterInfo() {
      var info = _.cloneDeep(filterInfo)
      var filterOptions = ctrl.survey.filterOptions

      if (!_.find(ctrl.survey.questions, { isProfiling: true })) {
        _.remove(info, { type: Types.PROFILE })
      }
      if (
        _.isEmpty(filterOptions.reportingDimensions.customReportingDimensions)
      ) {
        _.remove(info, { type: Types.DIMENSION })
      }
      if (!filterOptions.reportingDimensions.systemReportingDimensions.page) {
        _.remove(info, { type: Types.PAGE })
      }
      if (
        !filterOptions.reportingDimensions.systemReportingDimensions.channel
      ) {
        _.remove(info, { type: Types.CHANNEL })
      }

      return info
    }

    function getTitle() {
      var info = _.find(filterInfo, { type: ctrl.filter.type })
      if (ctrl.filter.isNew) {
        return 'New ' + info.name + ' Filter'
      }
      switch (info.type) {
        case Types.QUESTION:
        case Types.PROFILE:
          return _.find(ctrl.survey.questions, {
            id: ctrl.filter.data.questionId,
          }).listTitle
        case Types.DIMENSION:
          return ctrl.filter.data.category
        case Types.CHANNEL:
        case Types.DATE:
        case Types.PAGE:
        case Types.SEGMENT:
          return info.name
      }
    }

    function getSubtitle() {
      if (ctrl.filter.isNew) {
        return
      }
      var info = _.find(filterInfo, { type: ctrl.filter.type })
      switch (info.type) {
        case Types.QUESTION:
        case Types.PROFILE:
        case Types.DIMENSION:
          return info.name
        case Types.CHANNEL:
        case Types.DATE:
        case Types.PAGE:
          return
      }
    }
  }
})()
