;(function() {
  'use strict'

  Controller.$inject = ["configService"];
  angular
    .module('core.surveyListSelect')
    .component('glSpSurveyListSelect', Component())

  function Component() {
    return {
      controller: Controller,
      templateUrl: 'survey-list-select.template.html',
      bindings: {
        listTitle: '@',
        surveys: '<',
        order: '<',
        onSurveySelect: '&',
      },
    }
  }

  /* @ngInject */
  function Controller(configService) {
    var ctrl = this

    // methods
    ctrl.getPreviewUrl = getPreviewUrl

    ctrl.$onInit = onInit

    function onInit() {}

    /**
     * @name getPreviewUrl
     * @description get survey preview URL based on current environment
     * @param  {String} surveyId survey ID
     * @return {String}          preview URL
     */
    function getPreviewUrl(surveyId) {
      return (
        configService.getUserPortalUrl() +
        '/survey/' +
        surveyId +
        '?readOnly=true'
      )
    }
  }
})()
