;(function() {
  'use strict'

  d3.$inject = ["$window"];
  angular.module('core.services').factory('d3', d3)

  /* @ngInject */
  function d3($window) {
    return $window.d3
  }
})()
