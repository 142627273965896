;(function() {
  'use strict'

  Controller.$inject = ["$scope", "glUtils"];
  angular.module('app.core').component('surveyExplorerPrefilter', {
    controller: Controller,
    templateUrl: 'survey-explorer-prefilter.html',
    bindings: {
      filters: '<',
      filter: '<',
      global: '<',
      onEdit: '&',
      onSelect: '&',
      onRemove: '&',
      onDelete: '&',
    },
  })

  /* @ngInject */
  function Controller($scope, glUtils) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.toggle = toggle
    ctrl.select = select
    ctrl.edit = edit
    ctrl.del = del
    ctrl.$onDestroy = onDestroy

    function onInit() {
      ctrl.id = glUtils.uuid()
    }

    function toggle(event) {
      if (event) {
        event.selectId = ctrl.id
      }
      if (ctrl.open) {
        close()
      } else {
        open()
      }
    }

    function open() {
      if (ctrl.open) return
      ctrl.open = true
      document.addEventListener('click', onDocumentClick)
    }

    function close() {
      if (!ctrl.open) return
      ctrl.open = false
      document.removeEventListener('click', onDocumentClick)
    }

    function onDocumentClick(event) {
      if (event.selectId === ctrl.id) return
      $scope.$applyAsync(function() {
        close()
      })
    }

    function select(event, filter) {
      if (event.defaultPrevented) return
      ctrl.onSelect({ $filter: filter })
      close()
    }

    function edit(event, filter) {
      event.preventDefault()
      ctrl.onEdit({ $filter: filter })
      close()
    }

    function del(event, filter) {
      event.preventDefault()
      ctrl.onDelete({ $filter: filter })
      close()
    }

    function onDestroy() {
      close()
    }
  }
})()
