;(function() {
  'use strict'

  Service.$inject = ["$q", "glDialog", "questionLibrary"];
  angular.module('app.core').service('viewPicker', Service)

  /* @ngInject */
  function Service($q, glDialog, questionLibrary) {
    this.show = show
    this.showQuestionTypes = showQuestionTypes
    this.showTemplates = showTemplates

    function show(options) {
      options = options || {}
      var template = [
        '<gl-dialog class="view-picker__dialog">',
        '<view-picker ',
        'title="{{ title }}" ',
        'single-tab="singleTab" ',
        'is-question-only="isQuestionOnly" ',
        'on-select="dialog.close($response)" ',
        'on-cancel="dialog.cancel()" ',
        '/>',
        '</gl-dialog>',
      ]
      return glDialog
        .show({
          template: template.join(''),
          clickOutsideToClose: false,
          escapeToClose: false,
          locals: {
            title: options.title,
            singleTab: options.singleTab,
            isQuestionOnly: options.isQuestionOnly,
          },
        })
        .then(function(response) {
          if (response.type === 'question-template') {
            return selectTemplateVariation(response.value)
              .then(function(question) {
                return {
                  type: 'question',
                  value: question,
                }
              })
              .catch(function() {
                return show()
              })
          }
          return response
        })
    }

    function showQuestionTypes(title) {
      return show({
        title: title || 'Select Type',
        singleTab: 'new',
        isQuestionOnly: true,
      })
    }

    function showTemplates(title) {
      return show({
        title: title || 'Select From Library',
        singleTab: 'library',
      })
    }

    function selectTemplateVariation(template) {
      var hasVariations = _.includes(['choice', 'scale'], template.type)
      if (hasVariations) {
        return $q(function(resolve, reject) {
          questionLibrary.showVariationsDialog({
            onSelect: function(question) {
              resolve(template.useQuestion(question))
            },
            onBack: function() {
              reject()
            },
            template: template,
          })
        })
      } else {
        return $q.resolve(template.useQuestion(template.questions[0]))
      }
    }
  }
})()
