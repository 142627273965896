;(function() {
  'use strict'

  Controller.$inject = ["Filter"];
  angular.module('glow.reporting').component('glQuestionFilter', Component())

  function Component() {
    return {
      controller: Controller,
      templateUrl: 'question-filter.template.html',
      bindings: {
        survey: '=',
        filter: '=',
        profiling: '=',
      },
    }
  }

  /* @ngInject */
  function Controller(Filter) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.select = select

    function onInit() {
      ctrl.questions = _.cloneDeep(ctrl.survey.questions)
      ctrl.questions = _.filter(ctrl.questions, function(question) {
        if (ctrl.profiling) {
          return question.isProfiling
        }
        return !question.isProfiling && question.placement === 'chart'
      })
      ctrl.questions = _.filter(ctrl.questions, function(question) {
        var category = Filter.getQuestionCategory(question.type)
        // only support question filters that have a category
        return !!category
      })

      if (!ctrl.filter.isNew) {
        ctrl.question = _.cloneDeep(
          _.find(ctrl.questions, { id: ctrl.filter.data.questionId })
        )
      } else {
        ctrl.question = {}
      }
    }

    function select(questionId) {
      if (ctrl.filter.data.questionId === questionId) {
        return
      }
      ctrl.question = _.cloneDeep(_.find(ctrl.questions, { id: questionId }))
      ctrl.filter.data.questionId = questionId
      ctrl.filter.data.category = Filter.getQuestionCategory(ctrl.question.type)
      _.remove(ctrl.filter.data.values)
    }
  }
})()
