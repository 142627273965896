;(function() {
  'use strict'

  config.$inject = ["$stateProvider"];
  angular.module('app.core').config(config)

  /* @ngInject */
  function config($stateProvider) {
    $stateProvider.state('publicationEditor', {
      url: '/publication-editor/:id?projectId',
      parent: 'portal',
      templateUrl: 'publication-editor.html',
      controller: 'PublicationEditorController',
      controllerAs: '$ctrl',
      title: 'Report',
    })

    $stateProvider.state('insights-publication-editor-deprecated', {
      url: '/insights/publication-editor/:id',
      redirectTo: 'publicationEditor',
    })
  }
})()
