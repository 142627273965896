/* globals $clamp */
;(function() {
  'use strict'

  Controller.$inject = ["$element", "$timeout", "$window"];
  angular.module('app.core').component('gClamp', {
    controller: Controller,
    templateUrl: 'g-clamp.html',
    bindings: {
      lines: '<',
      text: '<',
    },
  })

  /* @ngInject */
  function Controller($element, $timeout, $window) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.$onChanges = onChanges

    function onInit() {
      ctrl.element = $element.find('div')[0]
      $element.addClass('g-clamp')
      angular.element($window).on(
        'resize',
        _.debounce(function() {
          // Clamping mutates the text in the DOM so need to reset if width grows
          ctrl.element.innerText = ctrl.text
          clamp()
        }, 400)
      )
    }

    function onChanges() {
      clamp()
    }

    function clamp() {
      // Hide text while clamping is calculated
      $element.addClass('-invisible')
      $timeout(function() {
        $clamp(ctrl.element, { clamp: ctrl.lines })
        $element.removeClass('-invisible')
      }, 0)
    }
  }
})()
