;(function() {
  'use strict'

  surveyTemplateEditorConfig.$inject = ["$stateProvider"];
  angular
    .module('app.survey-template-edit', [])
    .config(surveyTemplateEditorConfig)

  /* @ngInject */
  function surveyTemplateEditorConfig($stateProvider) {
    $stateProvider.state('survey-template-edit', {
      url: '/survey-templates/:surveyId?cloneTemplateId?cloneSurveyId',
      parent: 'portal',
      templateUrl: 'survey-template-edit.html',
      controller: 'SurveyTemplateEditController',
      controllerAs: '$ctrl',
      title: 'Edit Survey Template',
    })
  }
})()
