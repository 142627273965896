;(function() {
  'use strict'

  Service.$inject = ["d3"];
  angular
    .module('glow.reporting.charts')
    .factory('reportD3ChartService', Service)

  /* @ngInject */
  function Service(d3) {
    return {
      parseData: parseData,
      sortColumns: sortColumns,
      buildChartConfig: buildChartConfig,
      buildDonutConfig: buildDonutConfig,
    }

    function parseData(question, absolute) {
      switch (question.type) {
        case 'constantsum':
          return parseConstantSum(question, absolute)
        default:
          return parseChoice(question, absolute)
      }
    }

    function parseChoice(question, absolute) {
      var columns = []
      var colors = {}
      var orders = {}

      _.each(question.choices, function(choice) {
        if (
          question.questionResults &&
          question.questionResults.values &&
          question.questionResults.values[choice.id]
        ) {
          var label = choice.displayLabel || choice.option
          columns.push([
            label,
            question.questionResults.values[choice.id].count,
          ])
        }
        colors[choice.displayLabel || choice.option] = choice.color
        orders[choice.displayLabel || choice.option] = choice.order
      })

      var data = {}
      data.columns = columns
      data.colors = colors
      data.orders = orders

      if (!absolute) {
        var responseCount = question.questionResults.answered
        _.each(data.columns, function(column) {
          column[1] = _.round(column[1] / responseCount, 3)
        })
      }

      return data
    }

    function parseConstantSum(question, absolute) {
      var columns = []
      var colors = {}
      var orders = {}
      _.each(question.choices, function(choice) {
        if (
          question.questionResults &&
          question.questionResults.values &&
          question.questionResults.values[choice.id]
        ) {
          var questionTotal = question.questionResults.total
          var choiceTotal = question.questionResults.values[choice.id].total
          var choiceTotalRatio = choiceTotal / questionTotal
          columns.push([
            choice.option,
            absolute ? choiceTotal : choiceTotalRatio,
          ])
        }
        colors[choice.option] = choice.color
        orders[choice.option] = choice.order
      })
      var data = {}
      data.columns = columns
      data.colors = colors
      data.orders = orders
      return data
    }

    function buildChartConfig(
      id,
      type,
      columns,
      colors,
      canClick,
      onClick,
      absolute,
      hideLabels,
      rotated
    ) {
      var config = {
        bindto: '#' + id,
        data: {
          type: type,
          columns: columns,
          order: null,
          colors: colors,
          // NOTE: this adds a cursor on hover but click makes highlight
          // sticky, so disabled it
          // selection: {
          //     enabled: true
          // },
          labels: {
            format: hideLabels ? angular.noop : d3.format('.1%'),
          },
        },
        axis: {
          rotated: rotated,
          x: {
            tick: {
              format: angular.noop,
            },
          },
          y: {
            min: 0,
            padding: {
              top: rotated ? (hideLabels ? 0 : 50) : 10,
              bottom: 0,
            },
            tick: {
              values: [0, 0.2, 0.4, 0.6, 0.8, 1],
              format: d3.format('.0%'),
            },
          },
        },
        bar: {
          width: {
            ratio: 1,
          },
        },
        legend: {
          show: false,
        },
        grid: {
          focus: {
            show: false,
          },
        },
        size: {
          // height: 230
        },
        tooltip: {
          grouped: false,
          format: {
            value: function(value) {
              return _.includes(['bar', 'pie'], type)
                ? d3.format('.1%')(value)
                : value
            },
            name: function(name) {
              return _.truncate(name, { length: 40 })
            },
          },
        },
      }

      if (absolute && _.includes(['bar', 'pie'], type)) {
        var max =
          absolute.max ||
          _.maxBy(columns, function(column) {
            return column[1]
          })[1]
        config.axis = {
          rotated: rotated,
          x: {
            tick: {
              format: angular.noop,
            },
          },
          y: {
            min: 0,
            max: max,
            padding: {
              top: rotated ? (hideLabels ? 0 : 50) : 10,
              bottom: 0,
            },
          },
        }
        if (!hideLabels) {
          config.data.labels.format = function(value) {
            return value
          }
        }
        config.pie = {
          label: {
            format: function(value) {
              return value
            },
          },
        }
        config.tooltip.format.value = function(value) {
          return value
        }
      }

      if (canClick) {
        config.data.onclick = function() {
          onClick({
            $value: arguments[0].name,
          })
        }
      }

      return config
    }

    function buildDonutConfig(
      id,
      columns,
      colors,
      onRender,
      canClick,
      onClick
    ) {
      var config = {
        bindto: '#' + id,
        data: {
          type: 'donut',
          columns: columns,
          colors: colors,
          order: null,
        },
        legend: {
          show: false,
        },
        tooltip: {
          format: {
            value: function(value) {
              return value
            },
          },
        },
        size: {
          // height: height ? height : null
        },
        padding: {
          top: 0,
          bottom: 0,
        },
      }

      if (canClick) {
        config.data.onclick = function() {
          onClick({
            $value: arguments[0].name,
          })
        }
      }

      if (onRender) {
        config.onrendered = onRender
      }

      return config
    }

    function sortColumns(sortConfig, data) {
      // TODO: the data coming in through sortConfig is a private format
      // used internally by the report-table component for sorting rows.
      // This should be refactored to be some kind of question-level api
      // for sorting that is used by child components.

      if (!sortConfig) {
        return data.columns
      }

      // generate a unique id for each column
      var ColumnIdMap = {}

      _.forEach(data.columns, function(column) {
        ColumnIdMap[column] = _.uniqueId('id-')
      })

      // tmp data structure for sorting
      var sorted = _.chain(data.columns)
        .map(function(column) {
          return {
            id: ColumnIdMap[column],
            'choice.text': column[0],
            'total.sort': column[1],
            'number.text': data.orders[column[0]],
          }
        })
        .sortBy(sortConfig.key)
        .map('id')
        .value()

      if (sortConfig.ascending) {
        _.reverse(sorted)
      }

      // sort data.columns
      return _.sortBy(data.columns, function(column) {
        return _.indexOf(sorted, ColumnIdMap[column])
      })
    }
  }
})()
