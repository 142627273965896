;(function() {
  'use strict'

  Controller.$inject = ["ProjectResource", "projectResourceService"];
  angular.module('app.projects').component('projectResourceEditor', {
    controller: Controller,
    templateUrl: 'project-resource-editor.html',
    bindings: {
      projectId: '<',
      resource: '<',
      onDone: '&',
      onCancel: '&',
    },
  })

  /* @ngInject */
  function Controller(ProjectResource, projectResourceService) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.save = save

    function onInit() {
      ctrl.types = ProjectResource.getTypeInfo()

      if (!ctrl.resource) {
        ctrl.resource = new ProjectResource()
        ctrl.resource.projectId = ctrl.projectId
      } else {
        ctrl.resource = ctrl.resource.clone()
      }
    }

    function save() {
      if (!ctrl.resource.validate()) {
        ctrl.showErrors = true
        return
      }
      ctrl.showErrors = false
      projectResourceService.save(ctrl.resource).then(function() {
        ctrl.onDone()
      })
    }
  }
})()
