;(function() {
  'use strict'

  glSidenavDirective.$inject = ["$rootScope", "$animate", "$compile", "$document"];
  angular.module('core.sidenav').directive('glSidenav', glSidenavDirective)

  /**
   * sidenav directive, slide in-out left side navigation
   *
   * @ngInject */
  function glSidenavDirective($rootScope, $animate, $compile, $document) {
    return {
      restrict: 'EA',
      scope: {
        top: '<',
      },
      link: function(scope, element) {
        var parent = element.parent()
        var body = $document.find('body').eq(0)

        var backdrop = $compile('<gl-backdrop class="gl-sidenav-backdrop">')(
          scope
        )

        // add sidenav class
        element.addClass('gl-sidenav')
        element.css({ top: (scope.top || 0) + 'px' })

        // default to closed
        scope.isOpen = false

        // on sidenav:clsoe event set isOpen to false
        $rootScope.$on('sidenav:close', function() {
          scope.isOspen = false
        })

        // on sidenav:toggle event toggle isOpen value
        $rootScope.$on('sidenav:toggle', function() {
          scope.isOpen = !scope.isOpen
        })

        // watch for isOpen change and update sidenav
        scope.$watch('isOpen', updateSidenav)

        /**
         * close the sidenav when click event on backdrop recieved
         */
        function close(e) {
          e.preventDefault()
          e.stopPropagation()

          scope.isOpen = false
          scope.$digest()
        }

        /**
         * update the sidenav and backdrop element
         */
        function updateSidenav() {
          backdrop[scope.isOpen ? 'on' : 'off']('click', close)
          body[scope.isOpen ? 'addClass' : 'removeClass']('no-scroll')

          $animate[scope.isOpen ? 'enter' : 'leave'](backdrop, parent)
          $animate[scope.isOpen ? 'addClass' : 'removeClass'](
            element,
            'gl-open'
          )
        }
      },
    }
  }
})()
