;(function() {
  'use strict'

  Controller.$inject = ["surveyReport", "responseTypeService"];
  angular.module('glow.reporting').component('reportComparison', {
    controller: Controller,
    templateUrl: 'report-comparison.html',
    bindings: {
      survey: '<',
      options: '<',
      questionId: '<',
      filterSetGroup: '<',
      onBack: '&',
    },
  })

  /* @ngInject */
  function Controller(surveyReport, responseTypeService) {
    var ctrl = this
    var ids = 0

    ctrl.$onInit = onInit
    ctrl.selectFilterSetAtIndex = selectFilterSetAtIndex
    ctrl.remove = remove

    function onInit() {
      ctrl.filterSets = _.map(ctrl.filterSetGroup.filterSets, function(
        filterSet,
        index
      ) {
        return {
          label: filterSet.label,
          value: index,
        }
      })
      ctrl.comparisons = [
        {
          id: ++ids,
        },
      ]
      // default the first comparison chart to default filterSet (index 0)
      selectFilterSetAtIndex(ctrl.comparisons[0], 0)
    }

    function createFilterSetGroup(filterSetIndex) {
      var filterSetGroup = ctrl.filterSetGroup.duplicate()
      var filterSet = filterSetGroup.filterSets[filterSetIndex]
      filterSetGroup.selectSet(filterSet)
      return filterSetGroup
    }

    function selectFilterSetAtIndex(comparison, index) {
      var isNew = !comparison.filterSetGroup
      comparison.filterSetIndex = index
      comparison.filterSetGroup = createFilterSetGroup(index)
      surveyReport
        .get(
          ctrl.survey.id,
          ctrl.options,
          comparison.filterSetGroup.getSelected(),
          null,
          false,
          responseTypeService.getSelected()
        )
        .then(function(survey) {
          comparison.survey = survey
          comparison.question = _.find(survey.questions, {
            id: ctrl.questionId,
          })
          calculateAbsolute()
        })
      if (isNew) {
        ctrl.comparisons.push({ id: ++ids })
      }
    }

    function remove(comparison) {
      _.remove(ctrl.comparisons, comparison)
      calculateAbsolute()
    }

    function calculateAbsolute() {
      var max = 0
      _.each(ctrl.comparisons, function(comparison) {
        if (!comparison.question) {
          return
        }
        var questionMax = 0
        if (comparison.question.type === 'constantsum') {
          var absoluteMax = comparison.question.questionResults.absolute.max
          if (absoluteMax > questionMax) {
            questionMax = absoluteMax
          }
        } else {
          _.each(comparison.question.questionResults.values, function(value) {
            if (value.count > questionMax) {
              questionMax = value.count
            }
          })
        }
        if (questionMax > max) {
          max = questionMax
        }
      })
      ctrl.absolute = {
        max: max,
      }
    }
  }
})()
