;(function() {
  'use strict'

  PageController.$inject = ["$log", "$state", "$window", "$stateParams", "$q", "glToast", "configService", "glUtils", "imagesService", "modulesService", "pagesService"];
  angular.module('app.page').controller('PageController', PageController)

  /* @ngInject */
  function PageController(
    $log,
    $state,
    $window,
    $stateParams,
    $q,
    glToast,
    configService,
    glUtils,
    imagesService,
    modulesService,
    pagesService
  ) {
    $log = $log.create('PageController')
    var vm = this
    var broadcast = glUtils.broadcaster('page')

    // methods
    vm.editLogo = editLogo
    vm.editFeature = editFeature
    vm.previewPage = previewPage

    activate()

    /**
     * Initialise
     */
    function activate() {
      if ($stateParams.pageId) {
        // pageId provided
        getPageData($stateParams.pageId)
      } else {
        // no pageId in route - get last page (will return first page by default)
        pagesService.getLastPage().then(function(page) {
          if (!page) {
            vm.state = 'zero'
            return
          }
          $state.go('page', { pageId: page.id })
        })
      }
    }

    /**
     * @name getPage
     * @desciption get the page by ID in the state parameters
     */
    function getPageData(pageId) {
      var dataPromises = []

      dataPromises.push(pagesService.getPage(pageId))
      dataPromises.push(modulesService.getByPage(pageId))

      $q.all(dataPromises)
        .then(getSuccess)
        .catch(getError)

      function getSuccess(data) {
        vm.page = data[0]
        vm.modules = data[1]
        vm.state = 'ready'
      }

      function getError() {
        vm.state = 'error'
        $log.error('could not load page: ' + pageId)
      }
    }

    function editLogo() {
      imagesService.prompt({
        title: 'Page Logo',
        imageUrl: vm.page.logoUrl,
        save: function(imageUrl) {
          var oldImageUrl = vm.page.logoUrl
          vm.page.logoUrl = imageUrl
          return pagesService.update(vm.page).then(
            function() {
              vm.page.logoUrl = imageUrl
              glToast.show(imageUrl ? 'Logo updated' : 'Logo removed')
              broadcast('logo-updated')
            },
            function() {
              vm.page.logoUrl = oldImageUrl
              glToast.show('Upload error, please try again.')
            }
          )
        },
      })
    }

    function editFeature() {
      imagesService.prompt({
        title: 'Page Banner Image',
        imageUrl: vm.page.featureImageUrl,
        save: function(imageUrl) {
          var oldImageUrl = vm.page.featureImageUrl
          vm.page.featureImageUrl = imageUrl
          return pagesService.update(vm.page).then(
            function() {
              glToast.show(imageUrl ? 'Feature updated' : 'Feature removed')
              broadcast('feature-updated')
            },
            function() {
              vm.page.featureImageUrl = oldImageUrl
              glToast.show('Upload error, please try again.')
            }
          )
        },
      })
    }

    /**
     * @name previewPage
     * @description show page in the user portal in a new tab
     */
    function previewPage() {
      $window.open(
        configService.getUserPortalUrl() + '/page/' + vm.page.id,
        '_blank'
      )
    }
  }
})()
