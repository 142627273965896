;(function() {
  'use strict'

  Controller.$inject = ["glToast", "api"];
  angular.module('app.core').component('segmentManager', {
    controller: Controller,
    templateUrl: 'segment-manager.html',
    bindings: {
      surveyId: '<',
      onCancel: '&',
      onComplete: '&',
    },
  })

  /* @ngInject */
  function Controller(glToast, api) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.canSave = canSave
    ctrl.save = save

    function onInit() {
      // ...
    }

    function canSave() {
      return ctrl.action && ctrl.segment && ctrl.responseIds
    }

    function save() {
      if (!canSave()) return
      ctrl.saving = true
      var data = {
        surveyId: ctrl.surveyId,
        segment: ctrl.segment,
        responseIds: getResponseIds(),
      }

      var apply = ctrl.action === 'add' ? api.segments.add : api.segments.remove
      apply(data)
        .then(function(resp) {
          glToast.show('Segment applied')
          ctrl.onComplete({ $newSegments: resp })
        })
        .catch(function() {
          glToast.show('Segment could not be applied')
        })
        .finally(function() {
          ctrl.saving = false
        })
    }

    function getResponseIds() {
      return ctrl.responseIds
        .split('\n')
        .map(function(value) {
          return value.trim()
        })
        .filter(function(value) {
          return !!value
        })
    }
  }
})()
