;(function() {
  'use strict'

  Controller.$inject = ["$scope", "$log", "userService", "jobRoleService", "StateFactory", "glToast"];
  angular.module('app.profile').controller('Profile', Controller)

  /* @ngInject */
  function Controller(
    $scope,
    $log,
    userService,
    jobRoleService,
    StateFactory,
    glToast
  ) {
    var ctrl = this
    var log = $log.create('Profile')

    ctrl.$onInit = onInit
    ctrl.state = new StateFactory(['loading', 'ready', 'error'])
    ctrl.jobRoles = jobRoleService.getAll()
    ctrl.save = save
    ctrl.onJobRoleChange = onJobRoleChange

    function onInit() {
      ctrl.jobRole = {} // required by multiselect
      ctrl.state.loading()
      getProfile()
        .then(function() {
          ctrl.state.ready()
        })
        .catch(function() {
          ctrl.starte.error()
          log.error('could not load profile')
        })
    }

    function getProfile() {
      return userService.getUserProfile().then(function(user) {
        if (user.jobRole) {
          ctrl.jobRole = { id: user.jobRole.id }
        }
        ctrl.user = user
      })
    }

    function onJobRoleChange() {
      // HACK: multiselect routines
      ctrl.user.jobRole = _.find(ctrl.jobRoles, { id: ctrl.jobRole.id })
    }

    function save() {
      ctrl.isSaving = true
      userService
        .saveUserProfile(ctrl.user)
        .catch(function() {
          glToast.action('Profile could not be saved.', 'Retry', save, {
            timeout: 5000,
          })
        })
        .finally(function() {
          ctrl.isSaving = false
        })
    }
  }
})()
