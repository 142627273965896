;(function() {
  'use strict'

  frameConfig.$inject = ["$stateProvider"];
  angular.module('app.core').config(frameConfig)

  /* @ngInject */
  function frameConfig($stateProvider) {
    $stateProvider.state('frame', {
      url: '/frame?url&mw&title',
      templateUrl: 'frame.template.html',
      controller: 'FrameController',
      controllerAs: '$ctrl',
      public: true,
    })
  }
})()
