;(function() {
  'use strict'

  Controller.$inject = ["userService", "kitsService", "imageService", "$scope", "$q", "usersResource", "glToast", "subscriberService", "SurveyTheme"];
  angular.module('app.core').component('surveySettings', {
    controller: Controller,
    templateUrl: 'survey-settings.html',
    bindings: {
      survey: '<',
      isForbidden: '<',
      onDone: '&',
    },
  })

  /* @ngInject */
  function Controller(
    userService,
    kitsService,
    imageService,
    $scope,
    $q,
    usersResource,
    glToast,
    subscriberService,
    SurveyTheme
  ) {
    var ctrl = this

    ctrl.isSSR = userService.isSSR()
    ctrl.Tabs = [
      {
        label: 'General',
        value: 'general',
        ssr: false,
        survey: true,
        template: true,
      },
      {
        label: 'Outro',
        value: 'outro',
        ssr: false,
        survey: true,
        template: true,
      },
      {
        label: 'Theme',
        value: 'theme',
        ssr: false,
        survey: true,
        template: true,
      },
      {
        label: 'Respondents',
        value: 'respondents',
        ssr: true,
        survey: true,
        template: true,
      },
      {
        label: 'Kits',
        value: 'kits',
        ssr: true,
        survey: false,
        template: true,
      },
    ]

    ctrl.$onInit = onInit
    ctrl.toggleFollowup = toggleFollowup
    ctrl.onKitSelect = onKitSelect
    ctrl.update = update
    ctrl.setShuffleItems = setShuffleItems
    ctrl.getShuffleItemsLabel = getShuffleItemsLabel
    ctrl.formatMultiOptions = formatMultiOptions
    ctrl.onLoopImageChange = onLoopImageChange
    ctrl.onThemeChange = onThemeChange
    ctrl.newTheme = newTheme
    ctrl.cancelTheme = cancelTheme
    ctrl.saveTheme = saveTheme

    function onInit() {
      if (ctrl.survey.isTemplate) {
        loadKits()
      }
      if (ctrl.isSSR) {
        loadOperationManagers()
      }
      ctrl.subscriber = subscriberService.getSubscriber()
      ctrl.tabs = getVisibleTabs()
      ctrl.selectedTab = ctrl.tabs[0].value
      update()
      setupTheme()
    }

    function update() {
      populateViewGroupOptions()
      populateShuffleGroupOptions()
      populateShuffleItemOptions()
      cleanUpSelectedShuffleItems()
    }

    function populateViewGroupOptions() {
      _.each(ctrl.survey.viewGroups, function(group) {
        group.options = ctrl.survey.views
          .filter(function(view) {
            var viewId = view.value.id
            var viewGroup = ctrl.survey.getViewGroupByViewId(viewId)
            return !viewGroup || viewGroup.id === group.id
          })
          .map(function(view) {
            return {
              label: view.value.getTitleLabel({ number: true }),
              value: view.value.id,
            }
          })
      })
    }

    function populateShuffleGroupOptions() {
      _.each(ctrl.survey.shuffleGroups, function(group) {
        group.options = ctrl.survey.viewGroups
          .filter(function(viewGroup) {
            var shuffleGroup = ctrl.survey.getShuffleGroupByViewGroupId(
              viewGroup.id
            )
            return !shuffleGroup || shuffleGroup.id === group.id
          })
          .map(function(viewGroup) {
            return {
              label: viewGroup.name,
              value: viewGroup.id,
            }
          })
      })
    }

    function populateShuffleItemOptions() {
      _.each(ctrl.survey.viewGroups, function(viewGroup) {
        viewGroup.shuffleOptions = _.filter(viewGroup.options, function(
          option
        ) {
          return _.includes(viewGroup.viewIds, option.value)
        })
        if (viewGroup.shuffleOptions.length > 0) {
          viewGroup.shuffleOptions.unshift({
            label: 'All',
            value: 'all',
          })
        }
      })
    }

    function cleanUpSelectedShuffleItems() {
      _.each(ctrl.survey.viewGroups, function(viewGroup) {
        if (!viewGroup.viewIds.length) {
          viewGroup.shuffleIds = []
        } else if (_.includes(viewGroup.shuffleIds, 'all')) {
          viewGroup.shuffleIds = _.concat(['all'], viewGroup.viewIds)
        } else {
          viewGroup.shuffleIds = _.filter(viewGroup.shuffleIds, function(
            viewId
          ) {
            if (viewId === 'all') return true
            return _.includes(viewGroup.viewIds, viewId)
          })
        }
      })
    }

    function setShuffleItems(viewGroup, value) {
      var wasAllSelected = _.includes(viewGroup.shuffleIds, 'all')
      var isAllSelected = _.includes(value, 'all')
      var shouldSelectAll = _.every(viewGroup.viewIds, function(viewId) {
        return _.includes(value, viewId)
      })
      viewGroup.shuffleIds = value.slice()
      if (!wasAllSelected && isAllSelected) {
        viewGroup.shuffleIds = _.concat(['all'], viewGroup.viewIds)
      }
      if (wasAllSelected && !isAllSelected) {
        viewGroup.shuffleIds = []
      }
      if (wasAllSelected && isAllSelected && !shouldSelectAll) {
        viewGroup.shuffleIds = _.without(value, 'all')
      }
      if (!wasAllSelected && !isAllSelected && shouldSelectAll) {
        viewGroup.shuffleIds = _.concat(['all'], value)
      }
    }

    function getShuffleItemsLabel(viewGroup) {
      var label = ''
      if (!viewGroup.shuffleIds.length) {
        label = 'Shuffle no items'
      } else if (_.includes(viewGroup.shuffleIds, 'all')) {
        label = 'Shuffle all items'
      } else {
        var options = _.filter(viewGroup.shuffleOptions, function(option) {
          return _.includes(viewGroup.shuffleIds, option.value)
        })
        var formatted = formatMultiOptions(options)
        label = 'Shuffle ' + formatted
      }
      return label
    }

    function formatMultiOptions(options) {
      // get only the question/section numbers
      return _.map(options, function(option) {
        return option.label.split(':')[0]
      }).join(', ')
    }

    function getVisibleTabs() {
      return _.filter(ctrl.Tabs, function(tab) {
        if (tab.ssr && !ctrl.isSSR) {
          return false
        }

        if (ctrl.survey.isTemplate) {
          return tab.template ? true : false
        }

        return tab.survey ? true : false
      })
    }

    function toggleFollowup() {
      ctrl.survey[ctrl.survey.isFollowup ? 'addFollowup' : 'removeFollowup']()
    }

    function loadKits() {
      kitsService
        .getByOwner(userService.getSubscriberId())
        .then(function(kits) {
          ctrl.kits = _.map(kits, function(kit) {
            if (_.find(ctrl.survey.kits, { id: kit.id })) {
              kit.selected = true
            }
            return kit
          })
        })
    }

    function loadOperationManagers() {
      ctrl.operationsManagerOptions = []
      usersResource
        .get({ filter: 'userRole.roles eq ROLE_SUBSCRIBER_SUPPORT' })
        .success(function(users) {
          $scope.$applyAsync(function() {
            ctrl.operationsManagerOptions = [{ label: 'None', value: '' }]
            users.forEach(function(user) {
              ctrl.operationsManagerOptions.push({
                label: user.email,
                value: user.id,
              })
            })
          })
        })
    }

    function onKitSelect(kit) {
      if (kit.selected) {
        ctrl.survey.kits.push(_.cloneDeep(kit))
      } else {
        _.remove(ctrl.survey.kits, { id: kit.id })
      }
    }

    function onLoopImageChange(viewGroup, variable, imageData) {
      console.log('onLoopImageChange')
      if (!imageData) {
        variable.imageData = null
        variable.imageUrl = null
        return $q.resolve()
      }
      var promise = imageService.create(imageData).then(function(imageUrl) {
        variable.imageUrl = imageUrl
      })
      ctrl.survey.queue.add(promise, 'Uploading Loop Image')
      return promise
    }

    function setupTheme() {
      var themes = _.isArray(ctrl.subscriber.data.surveyThemes)
        ? ctrl.subscriber.data.surveyThemes.slice()
        : []
      themes.unshift(SurveyTheme.GlowTheme) // add default theme

      ctrl.themes = _.map(themes, function(theme) {
        return new SurveyTheme().deserialize(theme)
      })
      ctrl.themeOptions = _.map(ctrl.themes, function(theme) {
        return {
          value: theme.id,
          label: theme.label,
        }
      })

      var theme = _.find(ctrl.themes, { id: ctrl.survey.themeId })
      if (!theme) {
        // theme doesn't exist, revert to default and set the survey themeId to null
        theme = ctrl.themes[0]
        ctrl.survey.themeId = null
      }
      ctrl.currentTheme = theme
    }

    function onThemeChange(value) {
      if (ctrl.currentTheme.id === value) {
        return
      }
      ctrl.survey.themeId = value
      ctrl.currentTheme.revert()
      ctrl.currentTheme = _.find(ctrl.themes, { id: value })
      ctrl.themeModified = false
    }

    function newTheme() {
      ctrl.currentTheme.revert()
      ctrl.currentTheme = new SurveyTheme()
      ctrl.currentTheme.isNew = true
    }

    function cancelTheme() {
      ctrl.currentTheme = _.find(ctrl.themes, { id: ctrl.survey.themeId })
    }

    function saveTheme() {
      if (
        ctrl.isSavingTheme ||
        ctrl.currentTheme.isDefault ||
        !ctrl.currentTheme.validate()
      ) {
        return
      }
      ctrl.isSavingTheme = true

      var themes = _.reject(ctrl.themes, { isDefault: true }).map(function(
        theme
      ) {
        return theme.serialize()
      })
      if (ctrl.currentTheme.isNew) {
        themes.push(ctrl.currentTheme.serialize())
      }
      ctrl.subscriber.data.surveyThemes = themes

      subscriberService
        .saveSubscriber(ctrl.subscriber)
        .then(function() {
          if (ctrl.currentTheme.isNew) {
            ctrl.survey.themeId = ctrl.currentTheme.id
          }
          setupTheme()
          glToast.show('Theme has been saved.')
        })
        .catch(function(err) {
          console.error('Failed to save theme', err)
          glToast.show('There was an issue saving theme. Please try again.')
        })
        .finally(function() {
          ctrl.isSavingTheme = false
          ctrl.themeModified = false
        })
    }
  }
})()
