;(function() {
  'use strict'

  Service.$inject = ["$q", "glDialog"];
  angular.module('app.core').service('viewEditor', Service)

  /* @ngInject */
  function Service($q, glDialog) {
    this.show = show

    function show(view, index, survey, isForbidden) {
      // prettier-ignore
      var template = [
        '<gl-dialog class="view-editor__dialog">',
          '<view-editor ',
            'view="view"',
            'index="index"',
            'survey="survey"',
            'is-forbidden="isForbidden"',
            'on-done="dialog.close({ ',
              'clickedSave: $clickedSave,',
              'clickedSaveAsTemplate: $clickedSaveAsTemplate,',
              'clickedChangeType: $clickedChangeType,',
              'clickedDuplicate: $clickedDuplicate,',
              'clickedDelete: $clickedDelete',
            ' })"',
            'on-cancel="dialog.cancel()"',
          '/>',
        '</gl-dialog>',
      ]
      return glDialog.show({
        template: template.join(''),
        clickOutsideToClose: true,
        escapeToClose: true,
        locals: {
          view: view,
          index: index,
          survey: survey,
          isForbidden: isForbidden,
        },
      })
    }
  }
})()
