;(function() {
  'use strict'

  angular.module('core.surveyTemplateGrid').component('surveyTemplateGrid', {
    controller: Controller,
    templateUrl: 'survey-template-grid.html',
    bindings: {
      templates: '<',
      onFlip: '&',
      onSelect: '&',
      onPreview: '&',
    },
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit

    function onInit() {
      // ...
    }
  }
})()
