;(function() {
  'use strict'

  AddPageElementsDialogController.$inject = ["$log", "$q", "elementService"];
  angular
    .module('core.addPageElementsDialog')
    .controller(
      'AddPageElementsDialogController',
      AddPageElementsDialogController
    )

  /* @ngInject */
  function AddPageElementsDialogController($log, $q, elementService) {
    $log = $log.create('AddPageElementsDialogController')

    var vm = this

    // object to hold selected elements
    vm.selectedIds = {}

    // methods
    vm.save = save

    activate()

    /**
     * Initialise
     */
    function activate() {
      getElements()
    }

    /**
     * @name getElements
     * @desciption get subscribers elements and add to view model
     */
    function getElements() {
      elementService
        .getBySubscriber()
        .then(getSuccess)
        .catch(getError)

      function getSuccess(data) {
        // make copy of elements so remove does not effect source
        var elements = _.clone(data)

        // remove elements already associated to the page
        _.forEach(vm.pageElements, function(pageElement) {
          _.remove(elements, function(element) {
            return element.id === pageElement.id
          })
        })
        vm.elements = elements
        vm.state = 'ready'
      }

      function getError() {
        vm.state = 'error'
        $log.error('could not load elements')
      }
    }

    /**
     * @name save
     * @description save the element associations and close dialog on success
     */
    function save() {
      vm.saving = true
      vm.saveError = null
      addAssociations()
        .then(function() {
          vm.$scope.dialog.close()
        })
        .catch(function() {
          vm.saveError = 'Assocation could not be processed succesfully'
          $log.error('could not associate element')
        })
        .finally(function() {
          vm.saving = false
        })
    }

    /**
     * @name addAssociations
     * @description associated elements selected with the current page
     * @return {Promise} queue of element association promsises - one per selection
     */
    function addAssociations() {
      var associations = []

      _.forIn(vm.selected, function(value, key) {
        if (value) {
          associations.push(elementService.associateWithPage(vm.page.id, key))
        }
      })

      return $q.all(associations)
    }
  }
})()
