;(function() {
  'use strict'

  angular.module('glow.reporting').component('filterSetList', {
    controller: Controller,
    templateUrl: 'filter-set-list.html',
    bindings: {
      group: '<',
      canModify: '<',
      onSelect: '&',
      onToggle: '&',
      onEdit: '&',
      onDuplicate: '&',
      onRemove: '&',
      onAdd: '&',
      onMove: '&',
    },
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit

    function onInit() {
      // ...
    }
  }
})()
