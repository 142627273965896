;(function() {
  'use strict'

  Controller.$inject = ["glDialog"];
  angular.module('app.core').component('channelTagQuotas', {
    controller: Controller,
    templateUrl: 'channel-tag-quotas.html',
    bindings: {
      channel: '<',
      survey: '<',
      onSave: '&',
    },
  })

  /* @ngInject */
  function Controller(glDialog) {
    var ctrl = this

    ctrl.toggle = toggle

    function toggle() {
      ctrl.channel.tagQuotasEnabled = !ctrl.channel.tagQuotasEnabled

      if (
        ctrl.channel.tagQuotasEnabled &&
        ctrl.survey.tagQuotaSettings.enabled
      ) {
        glDialog
          .confirm(
            'Legacy Tag Quotas',
            'Enabling Legacy Tag Quotas for this channel will disable the global Tag Quotas. Meaning all your existing channels that are dependant of global tag quotas will no longer be limited. Are you sure you want to continue?'
          )
          .catch(function() {
            ctrl.channel.tagQuotasEnabled = false
          })
      }
    }
  }
})()
