;(function() {
  'use strict'

  interactionListService.$inject = ["$log", "$filter", "$q", "interactionsService", "InteractionList"];
  angular
    .module('core.interactionList')
    .service('interactionListService', interactionListService)

  /* @ngInject */
  function interactionListService(
    $log,
    $filter,
    $q,
    interactionsService,
    InteractionList
  ) {
    $log = $log.create('interactionListService')

    return {
      get: get,
    }

    /**
     * Get an interaction list
     * @param {object} options
     * @returns {InteractionsList} The interaction list instance
     */
    function get(options) {
      if (options.data) {
        return new InteractionList(options).fromData(options.data)
      }
      return new InteractionList(options)
    }
  }
})()
