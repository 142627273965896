;(function() {
  'use strict'

  angular.module('app.core').component('channelStatsTotal', {
    controller: Controller,
    templateUrl: 'channel-stats-total.html',
    bindings: {
      channel: '<',
    },
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.getActualIncidenceRate = getActualIncidenceRate
    ctrl.getPanelPortions = getPanelPortions
    ctrl.getNeeded = getNeeded
    ctrl.getRemaining = getRemaining

    function onInit() {
      // ...
    }

    function getActualIncidenceRate() {
      var exits = ctrl.channel.exitCount
      var responses = ctrl.channel.responseCount
      if (exits + responses === 0) return '0%'
      var percent = (responses / (responses + exits)) * 100
      return Math.round(percent) + '%'
    }

    function getPanelPortions() {
      if (ctrl.panelPortions) return ctrl.panelPortions
      var responses = ctrl.channel.responseCount
      var exits = ctrl.channel.exitCount
      var needed = getNeeded()
      var projected = needed + exits
      var remainder = needed - responses
      ctrl.panelPortions = {
        responses: (responses / projected) * 100,
        exits: (exits / projected) * 100,
        remainder: (remainder / projected) * 100,
      }
      return ctrl.panelPortions
    }

    function getNeeded() {
      if (ctrl.channel.usesPanel()) {
        return ctrl.channel.panel.requiredCompletes
      }
      return ctrl.channel.responseLimit
    }

    function getRemaining() {
      return _.clamp(getNeeded() - ctrl.channel.responseCount, 0, Infinity)
    }
  }
})()
