;(function() {
  'use strict'

  Controller.$inject = ["StateFactory", "api"];
  angular.module('app.core').component('surveyCaptureChart', {
    controller: Controller,
    templateUrl: 'survey-capture-chart.html',
    bindings: {
      surveyId: '<',
    },
  })

  /* @ngInject */
  function Controller(StateFactory, api) {
    var ctrl = this

    ctrl.$onInit = onInit

    function onInit() {
      ctrl.state = new StateFactory(['loading', 'ready', 'error'])
      ctrl.state.loading()
      api.surveyReports
        .getResponseTimeSeries({ surveyId: ctrl.surveyId })
        .then(function(timeseries) {
          ctrl.timeseries = timeseries
          ctrl.state.ready()
          build()
          console.log('timeseries', timeseries)
        })
        .catch(function(err) {
          ctrl.state.error()
          console.error(err)
        })
    }

    function build() {
      var elem = document.getElementById('survey-capture-chart')
      var ctx = elem.getContext('2d')

      // convert to a simplified lookup format
      var counts = {}
      _.each(ctrl.timeseries, function(item) {
        if (item.resolution !== 'DAY') return
        var date = moment(item.timestamp)
        var id = date.format('DD-MM-YYYY')
        var count = item.totalSamples
        counts[id] = count
      })

      var labels = []
      var data = []

      var cursor = moment().startOf('day')
      while (labels.length < 14) {
        var date = cursor.clone()
        var id = date.format('DD-MM-YYYY')
        var label = date.format('MMM D')
        var count = counts[id] || 0
        labels.push(label)
        data.push(count)
        cursor.subtract(1, 'day')
      }

      labels.reverse()
      data.reverse()

      var config = {
        plugins: [ChartDataLabels],
        type: 'bar',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Responses',
              data: data,
              backgroundColor: '#2196f3',
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          borderRadius: 4,
          scales: {
            x: {
              // display: false,
              // ticks: {
              //   maxRotation: 0,
              // },
              grid: {
                display: false,
              },
            },
            y: {
              ticks: {
                display: false,
              },
              grid: {
                tickLength: 0,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            datalabels: {
              anchor: 'end',
              align: 'start',
              offset: 10,
              color: 'white',
              font: {
                family: 'Roboto, sans-serif',
                weight: '500',
                lineHeight: 0,
              },
              clip: true,
            },
          },
        },
      }
      ctrl.chart = new Chart(ctx, config)
    }
  }
})()
