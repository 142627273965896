;(function() {
  'use strict'

  Service.$inject = ["$log", "$q", "$rootScope", "userService", "glPrefs", "glUtils", "glDialog", "surveyReportFiltersResource", "filterSetMongoService", "filterSetMigrationService", "FilterSetGroup"];
  angular.module('glow.reporting').factory('filterSetService', Service)

  /* @ngInject */
  function Service(
    $log,
    $q,
    $rootScope,
    userService,
    glPrefs,
    glUtils,
    glDialog,
    surveyReportFiltersResource,
    filterSetMongoService,
    filterSetMigrationService,
    FilterSetGroup
  ) {
    var log = $log.create('filterSetService')
    var prefs = glPrefs.create('filter-set-service')
    var deviceSets = prefs.get('device-sets') || {}
    var group

    var Events = {
      REQUEST_FILTER_CHANGE: 'reportFilters:upsertQuestionFilter',
    }

    return {
      loadGroup: loadGroup,
      // getFilters: getFilters,
      getQuery: getQuery,
      save: save,
      remove: remove,
      showFilterSetDialog: showFilterSetDialog,
      showFilterEditor: showFilterEditor,
      showSaveDialog: showSaveDialog,
      requestFilterChange: requestFilterChange,
      Events: Events,
    }

    function loadGroup(survey, accessType, token) {
      return $q(function(resolve, reject) {
        var params = {
          surveyId: survey.id,
          token: token,
        }
        surveyReportFiltersResource
          .get(params)
          .success(function(x, filterSets) {
            var ownerId
            if (accessType === 'subscriber') {
              ownerId = userService.getSubscriberId()
            }
            if (accessType === 'user') {
              ownerId = userService.getUser().id
            }
            if (!ownerId) {
              log.info('no ownerId... using device persistence')
              // migrate device sets
              filterSetMigrationService.migrateDeviceSets(
                deviceSets,
                survey,
                prefs
              )
              var sets = deviceSets[survey.id]
              _.each(sets, function(deviceSet) {
                filterSets.push(deviceSet)
              })
            }
            var options = {
              survey: survey,
              accessType: accessType,
              ownerId: ownerId,
              filterSets: filterSets,
            }
            group = new FilterSetGroup(options)
            resolve(group)
          })
          .error(reject)
      })
    }

    // function getFilters (predicateOrType) {
    //     var filterSet = group && group.selected;
    //     if (filterSet) {
    //         return filterSet.find(predicateOrType);
    //     }
    //     return [];
    // }

    function getQuery() {
      if (group) {
        return filterSetMongoService.parse(
          group.getSelected(),
          group.getOperator()
        )
      }
    }

    function save(filterSet) {
      return $q(function(resolve, reject) {
        var data
        if (!filterSet.ownerId) {
          if (!filterSet.id) {
            filterSet.id = glUtils.uuid()
          }
          data = filterSet.serialize()
          deviceSets[filterSet.surveyId] = deviceSets[filterSet.surveyId] || []
          var sets = deviceSets[filterSet.surveyId]
          var idx = _.findIndex(sets, { id: data.id })
          if (idx !== -1) {
            sets[idx] = data
          } else {
            sets.push(data)
          }
          prefs.set('device-sets', deviceSets)
          filterSet.modified = false
          filterSet.pin()
          resolve(filterSet)
          return
        }
        var action = filterSet.id ? 'update' : 'create'
        data = filterSet.serialize()
        filterSet.isSaving = true
        surveyReportFiltersResource[action](data)
          .success(function(x, response) {
            if (response.id) {
              filterSet.id = response.id
            }
            filterSet.modified = false
            filterSet.pin()
            resolve(filterSet)
            filterSet.isSaving = false
          })
          .error(function() {
            reject()
            filterSet.isSaving = false
          })
      })
    }

    function remove(filterSet) {
      return $q(function(resolve, reject) {
        if (!filterSet.ownerId) {
          if (filterSet.id) {
            _.remove(deviceSets[filterSet.surveyId], { id: filterSet.id })
            prefs.set('device-sets', deviceSets)
          }
          resolve()
          return
        }
        surveyReportFiltersResource
          .delete({
            surveyId: filterSet.surveyId,
            ownerId: filterSet.ownerId,
            id: filterSet.id,
          })
          .success(function() {
            resolve()
          })
          .error(function() {
            reject()
          })
      })
    }

    function showFilterSetDialog(filterSet, report) {
      // prettier-ignore
      var template = [
        '<gl-dialog class="filter-set-dialog__dialog">',
          '<filter-set-dialog ',
            'filter-set="filterSet" ',
            'report="report" ',
            'on-save="dialog.close()" ',
            'on-cancel="dialog.cancel()" ',
          '/>',
        '</gl-dialog>',
      ]
      return glDialog.show({
        template: template.join(' '),
        clickOutsideToClose: false,
        locals: {
          filterSet: filterSet,
          report: report,
        },
      })
    }

    // TODO: move to another service?
    function showFilterEditor(survey, filter) {
      return glDialog
        .show({
          controller: 'ReportFilterEditor',
          controllerAs: '$ctrl',
          clickOutsideToClose: false,
          templateUrl: 'report-filter-editor.html',
          locals: {
            survey: survey,
            filter: filter,
          },
        })
        .then(function(filter) {
          filter.isNew = false
          return filter
        })
    }

    function showSaveDialog(filterSet) {
      var template = [
        '<gl-dialog class="filter-set-save__dialog">',
        '<filter-set-save ',
        'filter-set="filterSet"',
        'on-save="dialog.close(true)"',
        'on-discard="dialog.close(false)"',
        'on-close="dialog.cancel()"',
        '/>',
        '</gl-dialog>',
      ]
      return glDialog.show({
        clickOutsideToClose: false,
        template: template.join(''),
        locals: {
          filterSet: filterSet,
        },
      })
    }

    function requestFilterChange(options) {
      $rootScope.$broadcast(Events.REQUEST_FILTER_CHANGE, options)
    }
  }
})()
