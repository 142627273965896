;(function() {
  'use strict'

  Controller.$inject = ["$timeout", "reportD3ChartService", "c3", "d3", "renderService"];
  angular
    .module('glow.reporting.charts')
    .component('glReportNpsChart', Component())

  /* @ngInject */
  function Component() {
    return {
      controller: Controller,
      templateUrl: 'report-nps-chart.template.html',
      bindings: {
        height: '=',
        question: '<',
        allowChartClick: '=',
        onChartClick: '&',
        focus: '<',
        hide: '<',
      },
    }
  }

  /* @ngInject */
  function Controller($timeout, reportD3ChartService, c3, d3, renderService) {
    var ctrl = this
    var colors = {
      Promoters: '#4caf50',
      Passives: '#838990',
      Detractors: '#f50057',
    }

    ctrl.chartId = _.uniqueId('glReportNpsChart')
    ctrl.$onChanges = onChanges

    function onChanges(changes) {
      if (!ctrl.chart) {
        $timeout(makeChart, 10)
      } else if (changes.question) {
        refreshChart()
      } else if (changes.hide) {
        ctrl.chart.show()
        if (ctrl.hide.length) {
          ctrl.chart.hide(ctrl.hide)
        }
      }
      if (ctrl.chart) {
        ctrl.chart.focus(ctrl.focus)
      }
    }

    function refreshChart() {
      ctrl.chart.unload()
      $timeout(function() {
        buildColumns()
        ctrl.chart.load({ columns: ctrl.columns })
      })
    }

    function makeChart() {
      buildColumns()
      var config = reportD3ChartService.buildDonutConfig(
        ctrl.chartId,
        ctrl.columns,
        colors,
        drawNpsScore,
        ctrl.allowChartClick,
        ctrl.onChartClick
      )
      renderService.queue(function() {
        ctrl.chart = c3.generate(config)
      })
    }

    function drawNpsScore() {
      var svg = d3.select('#' + ctrl.chartId).select('svg')
      if (!svg.node()) return // fixes: https://trello.com/c/2ppfLfyH/139-nps-chart-error
      var size = parseInt(svg.style('height'))

      svg.selectAll('text').remove()

      svg
        .append('text')
        .attr('x', '50%')
        .attr('y', '50%')
        .attr('dy', '.1em')
        .text(Math.round(ctrl.question.questionResults.npsScore))
        .attr('text-anchor', 'middle')
        .style('font-size', function() {
          return size / 3.3 + 'px'
        })

      svg
        .append('text')
        .attr('x', '50%')
        .attr('y', '50%')
        .text('NPS')
        .attr('dy', '2em')
        .attr('text-anchor', 'middle')
        .style('font-size', function() {
          return size / 18 + 'px'
        })
    }

    function buildColumns() {
      var npsGroups = ctrl.question.questionResults.npsGroups
      ctrl.columns = []
      ctrl.columns.push(['Promoters', npsGroups['promoter'].count])
      ctrl.columns.push(['Passives', npsGroups['passive'].count])
      ctrl.columns.push(['Detractors', npsGroups['detractor'].count])
    }
  }
})()
