;(function() {
  'use strict'

  Controller.$inject = ["websiteService"];
  angular.module('core.spFooter').component('spFooter', {
    controller: Controller,
    templateUrl: 'sp-footer.html',
  })

  /* @ngInject */
  function Controller(websiteService) {
    var ctrl = this

    ctrl.$onInit = onInit

    function onInit() {
      ctrl.year = moment().get('year')
      ctrl.termsUrl = websiteService.getTermsUrl()
      ctrl.privacyUrl = websiteService.getPrivacyUrl()
    }
  }
})()
