;(function() {
  'use strict'

  Service.$inject = ["$q", "glDialog"];
  angular.module('app.core').service('paymentMethodEditorService', Service)

  /* @ngInject */
  function Service($q, glDialog) {
    var Defaults = {
      title: 'Add Payment Method',
    }

    this.show = show

    function show(options) {
      options = _.defaults(options, {}, Defaults)
      var template = [
        '<gl-dialog class="payment-method-editor__dialog">',
        '<g-dialog-header ',
        'title="{{ title }}" ',
        'on-close="dialog.cancel()" ',
        '></g-dialog-header>',
        '<payment-method-editor ',
        'on-complete="dialog.close()" ',
        'payment-method="paymentMethod" ',
        '/>',
        '</gl-dialog>',
      ]
      return glDialog.show({ template: template.join(''), locals: options })
    }
  }
})()
