;(function() {
  'use strict'

  angular.module('glow.reporting').component('glDimensionsFilter', Component())

  function Component() {
    return {
      controller: Controller,
      templateUrl: 'dimensions-filter.template.html',
      bindings: {
        survey: '=',
        filter: '=',
      },
    }
  }

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.setCategory = setCategory
    ctrl.toggle = toggle

    function onInit() {
      ctrl.categories = getCategories()
      ctrl.category = ctrl.filter.data.category
        ? _.find(ctrl.categories, { id: ctrl.filter.data.category })
        : {}
      _.each(ctrl.filter.data.values, function(choice) {
        var dimension = _.find(ctrl.category.dimensions, {
          value: choice.value,
        })
        dimension.isSelected = true
      })
    }

    function setCategory(categoryId) {
      ctrl.category = _.cloneDeep(_.find(ctrl.categories, { id: categoryId }))
      ctrl.filter.data.category = ctrl.category.id
      _.remove(ctrl.filter.data.values)
    }

    function toggle(dimension) {
      if (dimension.isSelected) {
        ctrl.filter.data.values.push({ value: dimension.value })
      } else {
        _.remove(ctrl.filter.data.values, { value: dimension.value })
      }
    }

    function getCategories() {
      var categories = []
      var customReportingDimensions = _.cloneDeep(
        ctrl.survey.filterOptions.reportingDimensions.customReportingDimensions
      )
      var systemReportingDimensions = _.cloneDeep(
        ctrl.survey.filterOptions.reportingDimensions.systemReportingDimensions
      )
      _.each(customReportingDimensions, function(dimensions, key) {
        var category = {
          id: key,
          value: key,
          dimensions: [],
        }
        _.each(dimensions, function(dimension, index) {
          var dim = {
            value: dimension,
            label: dimension,
          }
          if (key === 'link') {
            dim.id = systemReportingDimensions.link[index]
            dim.value = dimension.replace(/__DOT__/g, '.')
            dim.label = dimension.replace(/__DOT__/g, '.')
          }
          category.dimensions.push(dim)
        })
        categories.push(category)
      })
      return categories
    }
  }
})()
