;(function() {
  'use strict'

  angular.module('app.core').component('gZero', {
    controller: Controller,
    templateUrl: 'g-zero.html',
    bindings: {
      title: '@',
      message: '@',
      icon: '@',
      image: '@',
    },
  })

  /* @ngInject */
  function Controller() {
    // ...
  }
})()
