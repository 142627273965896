;(function() {
  'use strict'

  Controller.$inject = ["linkService", "userService", "glUtils", "StateFactory"];
  angular
    .module('core.linkEditorDialog')
    .component('linkEditorDialog', Component())

  function Component() {
    return {
      controller: Controller,
      templateUrl: 'link-editor-dialog.html',
      bindings: {
        link: '<',
        close: '&',
        cancel: '&',
      },
    }
  }

  /* @ngInject */
  function Controller(linkService, userService, glUtils, StateFactory) {
    var ctrl = this

    // Constants
    var TOKEN_REGEX = /^[a-zA-Z0-9]{8}$/

    // Methods
    ctrl.save = save

    ctrl.$onInit = onInit

    function onInit() {
      ctrl.state = new StateFactory(['loading', 'ready'])

      ctrl.token = getValidToken(ctrl.link.longUrl)
      ctrl.allowMultiResponse = ctrl.link.isNew ? true : !ctrl.token
      if (!ctrl.allowMultiResponse) {
        // Rebuild the longUrl without the token because it's handled by the checkbox
        ctrl.link.longUrl = glUtils.pullParams(ctrl.link.longUrl, {
          token: undefined,
        })
      }

      ctrl.isSSR = userService.isSSR()
      ctrl.state.ready()
    }

    function getValidToken(url) {
      var params = glUtils.getQueryParams(url)

      if (!params) {
        return
      }

      return TOKEN_REGEX.test(params.token) && params.token
    }

    function addTokenToUrl(url) {
      var baseUrl = url.split('?')[0]
      var params = glUtils.getQueryParams(url) || {}
      params.token = ctrl.token || glUtils.randomString(8)

      return glUtils.addParams(baseUrl, params)
    }

    /**
     * @name save
     * @description save the link and close dialog on success
     */
    function save() {
      ctrl.state.loading()

      var action = ctrl.link.isNew ? 'create' : 'update'

      var params = glUtils.getQueryParams(ctrl.link.longUrl)
      if (params && params.hasOwnProperty('token')) {
        // TODO: If tokens drive other behaviour later this may no longer be valid
        ctrl.saveError =
          'Single response token should not be added manually to the Long URL.'
        ctrl.state.ready()
        return
      }

      if (!ctrl.allowMultiResponse) {
        ctrl.link.longUrl = addTokenToUrl(ctrl.link.longUrl)
      }

      linkService[action](ctrl.link)
        .then(function(savedLink) {
          ctrl.close({ savedLink: savedLink })
        })
        .catch(function(error) {
          ctrl.saveError =
            error.status === 409
              ? 'Short URL already in use.'
              : 'Could not save link.'
          if (!ctrl.allowMultiResponse) {
            // Rebuild the longUrl without the token because it's handled by the checkbox
            ctrl.link.longUrl = glUtils.pullParams(ctrl.link.longUrl, {
              token: undefined,
            })
          }
        })
        .finally(function() {
          ctrl.state.ready()
        })
    }
  }
})()
