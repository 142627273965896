/* globals google */
;(function() {
  'use strict'

  Controller.$inject = ["$element", "mapService"];
  angular.module('app.core').component('placeMap', {
    controller: Controller,
    templateUrl: 'place-map.html',
    bindings: {
      markerLocation: '<',
      defaultBounds: '<',
    },
  })

  /* @ngInject */
  function Controller($element, mapService) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.$onChanges = onChanges

    function onInit() {
      var options = {
        zoom: 14,
        mapTypeId: 'roadmap',
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
      }
      var elem = $element[0].querySelector('.place-map__map')
      mapService.load().then(function() {
        ctrl.map = new google.maps.Map(elem, options)
        updateMap()
      })
    }

    function onChanges() {
      if (ctrl.map) {
        updateMap()
      }
    }

    function updateMap() {
      if (ctrl.markerLocation) {
        addMarker(ctrl.markerLocation)
        ctrl.map.panTo(makeLatLng(ctrl.markerLocation))
        ctrl.map.setZoom(14)
      } else if (ctrl.defaultBounds) {
        ctrl.map.fitBounds(ctrl.defaultBounds, 0)
        ctrl.map.panTo(ctrl.defaultBounds.getCenter())
      } else {
        // Fallback on Australia
        var bounds = new google.maps.LatLngBounds()
        bounds.extend(
          makeLatLng({ latitude: -11.885944, longitude: 112.773279 })
        ) // top left
        bounds.extend(
          makeLatLng({ latitude: -44.657796, longitude: 154.162186 })
        ) // bottom right
        ctrl.map.fitBounds(bounds, 0)
        ctrl.map.panTo(bounds.getCenter())
      }
    }

    function makeLatLng(location) {
      return new google.maps.LatLng(location.latitude, location.longitude)
    }

    function addMarker(location) {
      // Remove existing marker then add new one
      if (ctrl.marker) {
        ctrl.marker.setMap(null)
      }
      ctrl.marker = makeMarker(location)
      ctrl.marker.setMap(ctrl.map)
    }

    function makeMarker(location) {
      var marker = {
        position: { lat: location.latitude, lng: location.longitude },
      }
      return new google.maps.Marker(marker)
    }
  }
})()
