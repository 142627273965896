;(function() {
  'use strict'

  angular.module('app.core').component('channelNotifications', {
    controller: Controller,
    templateUrl: 'channel-notifications.html',
    bindings: {
      channel: '<',
      onSave: '&',
    },
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onChanges = onChanges
    ctrl.toggle = toggle

    function onChanges() {
      ctrl.notificationsEnabled = ctrl.channel.notifyOnEvery
    }

    function toggle() {
      ctrl.notificationsEnabled = !ctrl.notificationsEnabled
      if (ctrl.notificationsEnabled) {
        ctrl.channel.enableNotifications()
      } else {
        ctrl.channel.disableNotifications()
      }
    }
  }
})()
