;(function() {
  'use strict'

  config.$inject = ["$stateProvider"];
  angular.module('app.project', []).config(config)

  /* @ngInject */
  function config($stateProvider) {
    $stateProvider.state('project', {
      url: '/projects/:id',
      parent: 'portal',
      templateUrl: 'project.html',
      controller: 'Project',
      controllerAs: '$ctrl',
      title: 'Project',
    })
  }
})()
