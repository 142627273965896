;(function() {
  'use strict'

  Controller.$inject = ["$state", "glDialog", "glToast", "kitsService"];
  angular.module('core.kits').component('glKitList', Component())

  /* @ngInject */
  function Component() {
    return {
      controller: Controller,
      templateUrl: 'kit-list.html',
      bindings: {
        kits: '<',
      },
    }
  }

  /* @ngInject */
  function Controller($state, glDialog, glToast, kitsService) {
    var ctrl = this

    ctrl.States = {
      LOADING: 'loading',
      ERROR: 'error',
      READY: 'ready',
      SUCCESS: 'success',
    }

    ctrl.view = view
    ctrl.cloneKit = cloneKit
    ctrl.deleteKit = deleteKit

    ctrl.$onInit = onInit

    function onInit() {
      ctrl.state = ctrl.States.READY
    }

    /**
     * @name view
     * @desc view/edit a new Kit
     */
    function view(kit) {
      $state.go('kits-edit', { kitId: kit.id })
    }

    /**
     * @name Clone Kit
     * @desc clone the selected kit
     */
    function cloneKit(kitId) {
      $state.go('kits-edit', { kitId: 'new', cloneKitId: kitId })
    }

    /**
     * @name Delete Kit
     * @desc delete the selected kit
     */
    function deleteKit(kitId) {
      glDialog
        .confirm('Delete', 'Are you sure you want to delete this kit?')
        .then(function() {
          kitsService
            .deleteKit(kitId)
            .then(function() {
              _.remove(ctrl.kits, { id: kitId })
              glToast.show('Kit deleted')
            })
            .catch(function() {
              glToast.show('Unable to delete kit')
            })
        })
    }
  }
})()
