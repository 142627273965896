;(function() {
  'use strict'

  Controller.$inject = ["glUtils", "glDialog"];
  angular.module('app.core').component('surveyLoi', {
    controller: Controller,
    templateUrl: 'survey-loi.html',
    bindings: {
      survey: '<',
    },
  })

  /* @ngInject */
  function Controller(glUtils, glDialog) {
    var ctrl = this

    ctrl.getTimeLabel = getTimeLabel
    ctrl.showInfo = showInfo

    function getTimeLabel() {
      ctrl.survey.calculateLoi()
      return ctrl.survey.lengthOfInterview.durationLabel
    }

    function showInfo() {
      glDialog.alert(
        'Estimated Length of Interview',
        'An estimate of how long it will take respondents to complete your survey.'
      )
    }
  }
})()
