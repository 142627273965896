;(function() {
  'use strict'

  Controller.$inject = ["$q", "$state", "glDialog", "glAnalytics", "configService", "glTips", "app", "userService", "accountService", "publicationService", "publisherService", "subscriberService", "linkedinService", "clipboardService", "featureService", "uacService"];
  angular.module('app.core').controller('PublicationController', Controller)

  /* @ngInject */
  function Controller(
    $q,
    $state,
    glDialog,
    glAnalytics,
    configService,
    glTips,
    app,
    userService,
    accountService,
    publicationService,
    publisherService,
    subscriberService,
    linkedinService,
    clipboardService,
    featureService,
    uacService
  ) {
    var ctrl = this
    var Types = publicationService.Types

    ctrl.edit = edit
    ctrl.getPublication = getPublication
    ctrl.canRemoveFromLibrary = canRemoveFromLibrary
    ctrl.removeFromLibrary = removeFromLibrary
    ctrl.copyLink = copyLink
    ctrl.rate = rate
    ctrl.trackLinkedInShare = trackLinkedInShare
    ctrl.trackResourcePreview = trackResourcePreview
    ctrl.trackResourceOpen = trackResourceOpen
    ctrl.linkedInShareUrl = linkedinService.createShareUrl()
    ctrl.canManageReports = uacService.canManageReports

    init()

    function init() {
      var id = $state.params.id
      ctrl.isLoading = true
      getUserInfo()
      publicationService.getById(id, function(err, publication) {
        ctrl.isLoading = false
        if (err) return // TODO: handle
        ctrl.isPublisher = publisherService.isPublisher(publication.publisherId)
        ctrl.publication = publication
        ctrl.typeText = findTypeText()
        ctrl.signupContext = 'publication'
        ctrl.isPrivate =
          !ctrl.isSSR && !ctrl.isPublisher && !ctrl.publication.inStore
        app.setTitle(ctrl.publication.name)
        app.setTags(ctrl.publication.getTags())
        glTips.ready()
        if (!ctrl.isPublisher) {
          publicationService.trackMetric(
            ctrl.publication,
            publicationService.Metrics.VIEW
          )
        }
      })
    }

    function getUserInfo() {
      ctrl.isLoggedIn = userService.isLoggedIn()
      if (ctrl.isLoggedIn) {
        ctrl.subscriber = subscriberService.getSubscriber()
        ctrl.currency = ctrl.subscriber.billing.currency
        ctrl.canAddPremiumPublications = featureService.canAddPremiumPublications()
        ctrl.isSSR = userService.isSSR()
        ctrl.isAdmin = subscriberService.isAdmin(userService.getUser().id)
      }
    }

    function findTypeText() {
      switch (ctrl.publication.type.type) {
        case Types.PAID:
          return ctrl.publication.price
            .byCurrency(ctrl.currency || 'AUD')
            .format({
              currency: true,
            })
        default:
          return ctrl.publication.type.label
      }
    }

    function canRemoveFromLibrary() {
      return (
        !ctrl.isPublisher &&
        ctrl.publication.inLibrary &&
        ctrl.publication.type.type !== Types.PAID &&
        (ctrl.isSSR || ctrl.isAdmin)
      )
    }

    function edit() {
      $state.go('publicationEditor', { id: ctrl.publication.id })
    }

    function getPublication() {
      track('get-publication-intent')
      ensureLogin().then(function(outcome) {
        if (outcome === 'signed-up') {
          return accountService
            .promptUpgrade({ context: 'welcome' })
            .then(getUserInfo)
            .finally(function() {
              return acquire().then(subscribe)
            })
        } else {
          return acquire().then(subscribe)
        }
      })
    }

    function ensureLogin() {
      if (ctrl.isLoggedIn) {
        return $q.resolve()
      }
      return accountService
        .showAuthDialog('signup', ctrl.signupContext)
        .then(function(outcome) {
          getUserInfo()
          $state.reload()
          return outcome
        })
    }

    function acquire() {
      if (ctrl.publication.type.type === Types.FREE) {
        return publicationService.acquire(ctrl.publication)
      }
      if (ctrl.publication.type.type === Types.PREMIUM) {
        if (ctrl.isSSR || ctrl.canAddPremiumPublications) {
          return publicationService.acquire(ctrl.publication)
        } else if (ctrl.subscriber.premiumTokens.length) {
          return glDialog
            .confirm(
              'Free Premium Report',
              "Would you like to redeem '" +
                ctrl.publication.name +
                "' as a free Premium Report?"
            )
            .then(function() {
              return publicationService.acquire(
                ctrl.publication,
                ctrl.subscriber.premiumTokens[0]
              )
            })
        } else {
          return accountService
            .promptUpgrade({
              context: 'premium-publication',
            })
            .then(function() {
              getUserInfo()
              return acquire()
            })
        }
      }
      if (ctrl.publication.type.type === Types.PAID) {
        return accountService.showPurchaseDialog({
          type: 'publication',
          publication: ctrl.publication,
          context: 'premium-publication',
        })
      }
    }

    function subscribe() {
      return publisherService.subscribeTo(ctrl.publication.publisher)
    }

    function removeFromLibrary() {
      if (!canRemoveFromLibrary()) return

      var warningText =
        'Warning! Are you sure you want to remove this report?\nYou will permanently lose access!'
      if (ctrl.publication.type.type === Types.PREMIUM) {
        warningText +=
          '\n\nIf this report was purchased with premium token, the token will not be refunded.'
      }
      glDialog.confirm('Remove Report', warningText).then(function() {
        return publicationService.unAssign(ctrl.publication, ctrl.subscriber.id)
      })
    }

    function copyLink() {
      var url = [
        configService.getSubscriberPortalUrl(),
        '/publication/',
        ctrl.publication.id,
      ].join('')
      clipboardService.copy(url)
      glAnalytics.track(
        'publication',
        'share-via-clipboard',
        'publication:' + ctrl.publication.id
      )
    }

    function rate() {
      publicationService.rate(ctrl.publication)
    }

    function trackLinkedInShare() {
      glAnalytics.track(
        'publication',
        'share-via-linkedin',
        'publication:' + ctrl.publication.id
      )
    }

    function trackResourcePreview(resource) {
      track('preview-resource-' + _.kebabCase(resource.category.type))
    }

    function trackResourceOpen(resource) {
      track('open-resource-' + _.kebabCase(resource.category.type))
    }

    function track(action) {
      glAnalytics.track('publication', action, ctrl.publication.id)
    }
  }
})()
