;(function() {
  'use strict'

  angular.module('glow.reporting').component('reportInfoNps', {
    controller: Controller,
    templateUrl: 'report-info-nps.html',
    bindings: {
      question: '<',
    },
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.$onChanges = onChanges

    function onInit() {
      // ...
    }

    function onChanges() {
      ctrl.score =
        'Score: ' + Math.round(ctrl.question.questionResults.npsScore)
    }
  }
})()
