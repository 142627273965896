;(function() {
  'use strict'

  Service.$inject = ["$http", "configService", "surveyReport", "reportSetsService", "glUtils"];
  angular.module('glow.reporting').factory('reportPPTXExporter', Service)

  function Service(
    $http,
    configService,
    surveyReport,
    reportSetsService,
    glUtils
  ) {
    return {
      export: exportPPTX,
    }

    function exportPPTX(survey) {
      var data = {
        slides: survey.questions
          .filter(function(question) {
            return !question.isHidden && question.type !== 'followup'
          })
          .map(function(question) {
            return questionToSlide(question)
          }),
      }
      $http({
        method: 'POST',
        url: configService.getNodeUrl('/export-pptx'),
        data: data,
        responseType: 'application/json',
      }).then(function(resp) {
        var fileName = 'survey-presentation.pptx'
        glUtils.downloadUrl(resp.data.url, fileName)
      })
    }

    function questionToSlide(question) {
      var result = reportSetsService.getForQuestion(question)
      var sets = result.sets
      var stacked = result.stacked
      var chartType = getQuestionChartType(question)
      var title = 'Q' + question.number + ': ' + question.title
      const slide = {
        notes: title,
        chart: null,
      }
      // console.log('sets', stacked, sets)

      // code supports switching between absolute <-> percentages
      // but we don't have a UI option for users
      var asPercent = true

      if (sets.length) {
        slide.answered = question.questionResults.answered
        slide.chart = {
          type: chartType,
          data: sets.map(function(set) {
            return {
              name: set.label,
              labels: set.aspects.map(function(aspect) {
                return aspect.label
              }),
              values: set.aspects.map(function(aspect) {
                return asPercent ? aspect.percent / 100 : aspect.count
              }),
            }
          }),
          options: {
            x: 0,
            y: 0,
            w: '100%',
            h: '95%',
            barDir: 'col',
            barGrouping: stacked ? 'stacked' : 'clustered',

            dataLabelColor: 'FFFFFF',
            dataLabelFontFace: 'Arial',
            dataLabelFontSize: 12,
            dataLabelFontBold: true,

            // pies do something weird with data labels trying to show both
            // absolutes and percentages so we have to get hacky for these 2 options
            dataLabelFormatCode:
              chartType === 'bar'
                ? asPercent
                  ? '##%'
                  : undefined
                : asPercent
                ? undefined
                : '##',
            showValue: chartType === 'bar' ? true : asPercent ? false : true,

            catAxisLabelColor: '777777',
            catAxisLabelFontFace: 'Arial',
            catAxisLabelFontSize: 12,
            catAxisOrientation: 'minMax',
            catAxisMajorTickMark: 'none',
            // catLabelFormatCode:
            //   chartType === 'doughnut'
            //     ? asPercent
            //       ? '0%'
            //       : undefined
            //     : undefined,

            valAxisLabelColor: '777777',
            valAxisLabelFontFace: 'Arial',
            valAxisLabelFontSize: 12,
            valAxisLabelFormatCode: asPercent ? '##%' : undefined,
            valGridLine: {
              color: 'E5E5E5',
            },

            title: title,
            titleColor: '777777',
            titleFontFace: 'Arial',
            titleFontSize: 18,
            showTitle: true,

            chartColors:
              sets.length > 1
                ? sets.map(function(set) {
                    return set.color.replace('#', '') // pptx doesnt use hash
                  })
                : sets[0].aspects.map(function(aspect) {
                    return aspect.color.replace('#', '') // pptx doesnt use hash
                  }),
          },
        }
      }

      if (!sets.length) {
        slide.unsupported = {
          title: title,
        }
      }

      return slide
    }

    function getQuestionChartType(question) {
      var chartConfig = _.find(surveyReport.chartConfigs, function(config) {
        var typeCheck = config.questionType === question.type
        var multiCheck = config.hasOwnProperty('isMulti')
          ? config.isMulti === question.isMulti
          : true
        var matrixCheck = config.hasOwnProperty('isMatrix')
          ? config.isMatrix === question.isMatrix
          : true
        return typeCheck && multiCheck && matrixCheck
      })
      return chartConfig.default
    }
  }
})()
