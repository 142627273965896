;(function() {
  'use strict'

  Factory.$inject = ["glUtils", "ResponseFilterGroup", "Question"];
  angular.module('app.core').factory('ResponseFilter', Factory)

  // This filter outputs mongo queries for the glow_report.query_optimised_responses collection

  /* @ngInject */
  function Factory(glUtils, ResponseFilterGroup, Question) {
    function ResponseFilter(survey) {
      this.survey = survey
      this.applyDefaults()
    }

    ResponseFilter.prototype.applyDefaults = function() {
      _.defaultsDeep(this, {
        id: glUtils.uuid(),
        name: null,
        private: false,
        restricted: false,
        global: false,
        groups: [],
      })
    }

    ResponseFilter.prototype.deserialize = function(data) {
      var self = this
      this.id = data.id
      this.name = data.name
      this.private = data.private
      this.restricted = data.restricted
      this.global = data.global
      this.groups = data.groups.map(function(groupData) {
        return new ResponseFilterGroup(self).deserialize(groupData)
      })
      this.applyDefaults()
      return this
    }

    ResponseFilter.prototype.serialize = function() {
      var data = {}
      data.id = this.id
      data.name = this.name
      data.private = this.private
      data.restricted = this.restricted
      data.global = this.global
      data.groups = this.groups.map(function(group) {
        return group.serialize()
      })
      return data
    }

    ResponseFilter.prototype.init = function() {
      var group = this.addGroup()
      group.addRule()
      return this
    }

    ResponseFilter.prototype.addGroup = function() {
      var group = new ResponseFilterGroup(this)
      this.groups.push(group)
      return group
    }

    ResponseFilter.prototype.addGroupAfter = function(group, init) {
      var idx = this.groups.indexOf(group)
      var newGroup = new ResponseFilterGroup(this)
      if (init) newGroup.addRule()
      this.groups.splice(idx + 1, 0, newGroup)
      return newGroup
    }

    ResponseFilter.prototype.removeGroup = function(group) {
      var idx = this.groups.indexOf(group)
      this.groups.splice(idx, 1)
    }

    ResponseFilter.prototype.removeRule = function(group, rule) {
      group.removeRule(rule)
      if (!group.rules.length) {
        this.removeGroup(group)
      }
    }

    ResponseFilter.prototype.duplicateRule = function(group, rule) {
      group.duplicateRule(rule)
    }

    ResponseFilter.prototype.isFirstGroup = function(group) {
      return this.groups[0] === group
    }

    ResponseFilter.prototype.clone = function() {
      return new ResponseFilter(this.survey).deserialize(this.serialize())
    }

    ResponseFilter.prototype.refresh = function() {
      this.id = glUtils.uuid()
      this.groups.forEach(function(group) {
        group.refresh()
      })
      return this
    }

    ResponseFilter.prototype.duplicate = function() {
      var filter = this.clone()
      filter.refresh()
      return filter
    }

    ResponseFilter.prototype.getAnswerQuestions = function() {
      return this.getQuestions(function(question) {
        return true // all supported
      })
    }

    ResponseFilter.prototype.getQuestions = function(cb) {
      return this.survey.getQuestions().filter(function(question) {
        if (!cb) return true
        return cb(question)
      })
    }

    ResponseFilter.prototype.validate = function(requireName) {
      if (requireName && !this.name) return false
      return _.every(this.groups, function(group) {
        return group.validate()
      })
    }

    ResponseFilter.prototype.toMongo = function() {
      var groups = this.groups.map(function(group) {
        return group.toMongo()
      })
      return { $or: groups }
    }

    ResponseFilter.prototype.toDatapackMatcher = function(datapack) {
      const matchers = this.groups.map(function(group) {
        return group.toDatapackMatcher(datapack)
      })
      return function(row) {
        return _.some(matchers, function(match) {
          return match(row)
        })
      }
    }

    return ResponseFilter
  }
})()
