;(function() {
  'use strict'

  angular.module('app.core').component('channelStatsTagQuota', {
    controller: Controller,
    templateUrl: 'channel-stats-tag-quota.html',
    bindings: {
      tag: '<',
    },
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit

    function onInit() {
      // ...
    }
  }
})()
