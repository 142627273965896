;(function() {
  'use strict'

  Controller.$inject = ["cloudinaryService", "userService"];
  angular.module('core.surveyCard').component('surveyCard', {
    controller: Controller,
    templateUrl: 'survey-card.html',
    bindings: {
      survey: '<',
      onClick: '&',
      onArchive: '&',
      onUnarchive: '&',
      onClone: '&',
      onView: '&',
      onMove: '&',
    },
  })

  /* @ngInject */
  function Controller(cloudinaryService, userService) {
    var ctrl = this

    ctrl.$onInit = onInit

    function onInit() {
      ctrl.info = 'Created on ' + formatDate(ctrl.survey.createdAt)
      ctrl.subTitle = makeSubTitle()
      ctrl.isSSR = userService.isSSR()

      var imageUrl = cloudinaryService.resize(ctrl.survey.imageUrl, 250)
      ctrl.imageStyle = imageUrl && {
        backgroundImage: 'url(' + imageUrl + ')',
      }
    }

    function makeSubTitle() {
      var responseCount = ctrl.survey.responseCount
      var questionCount = ctrl.survey.questionCount
      return (
        responseCount +
        (responseCount === 1 ? ' Response, ' : ' Responses, ') +
        questionCount +
        (questionCount === 1 ? ' Question' : ' Questions')
      )
    }

    function formatDate(date) {
      return moment(date).format('DD MMM, YYYY')
    }
  }
})()
