;(function() {
  'use strict'

  Controller.$inject = ["$state", "glUtils", "userService", "uacService"];
  angular.module('core.surveyAnalyze').component('surveyAnalyze', {
    controller: Controller,
    templateUrl: 'survey-analyze.html',
    bindings: {
      surveyId: '<',
    },
  })

  /* @ngInject */
  function Controller($state, glUtils, userService, uacService) {
    var ctrl = this

    ctrl.filters = $state.params.filters
    ctrl.filtersOperator = $state.params.filtersOperator
    ctrl.setFiltersParam = setFiltersParam
    ctrl.setResponseTypeParam = setResponseTypeParam

    ctrl.$onInit = onInit

    function onInit() {
      var responseType = $state.params.responseType
      if (responseType) {
        responseType =
          responseType === 'all' ? null : responseType.toUpperCase()
      }
      var isSSR = userService.isSSR()
      ctrl.reportOptions = {
        type: 'normal',
        accessType: 'subscriber',
        canFilter: true,
        canFilterProfile: true,
        canEditFilters: uacService.canManageAnalysisFilters(),
        showShareButton: true,
        canShareReport: uacService.canExportAndShareAnalysis(),
        showExportButton: true,
        canExportReport: uacService.canExportAndShareAnalysis(),
        showReportSettingsButton: true,
        canEditReportSettings: uacService.canManageAnalysisSettings(),
        canEditResponses: uacService.canManageAnalysisResponses(),
        canViewCrosstabs: uacService.canViewAnalysisCrosstabs(),
        canEditCrosstabs: uacService.canManageAnalysisCrosstabs(),
        setTitle: false,
        onlyLoopVariableIds: null,
        showResponseTypeToggle: true,
        responseType: responseType,
      }
    }

    function setFiltersParam(filterSetIds, operator) {
      glUtils.changeParams(
        { filters: filterSetIds, filtersOperator: operator },
        false,
        false
      )
    }

    function setResponseTypeParam(responseType) {
      glUtils.changeParams({ responseType: responseType })
    }
  }
})()
