;(function() {
  'use strict'

  angular.module('app.core').component('channelVariables', {
    controller: Controller,
    templateUrl: 'channel-variables.html',
    bindings: {
      channel: '<',
    },
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.toggle = toggle
    ctrl.add = add
    ctrl.remove = remove

    function onInit() {
      ctrl.enabled = ctrl.channel.injectedVariables.length
    }

    function toggle() {
      ctrl.enabled = !ctrl.enabled
      if (!ctrl.enabled) {
        ctrl.channel.injectedVariables = []
      }
    }

    function add() {
      ctrl.channel.injectedVariables.push({ key: '', value: '' })
    }

    function remove(item) {
      _.remove(ctrl.channel.injectedVariables, item)
    }
  }
})()
