;(function() {
  'use strict'

  Factory.$inject = ["$log", "ResponseFilter", "ResponseFilterRule", "glUtils"];
  angular.module('glow.reporting').factory('FilterSet', Factory)

  /* @ngInject */
  function Factory($log, ResponseFilter, ResponseFilterRule, glUtils) {
    var log = $log.create('FilterSet')

    function FilterSet(group, options) {
      this.group = group
      this.uuid = glUtils.uuid() // draft filterSets have no id, but we need one for ng-repeat tracking!
      this.applyDefaults(options)
    }

    FilterSet.prototype.applyDefaults = function(options) {
      // defaults
      _.defaults(this, options, {
        id: null,
        label: null,
        surveyId: this.group.survey.id,
        ownerId: null,
        filter: new ResponseFilter(this.group.survey),
        hiddenCharts: [],
        isPrivate: false,
        isReadOnly: false,
        isDefault: false,
        isSelected: false,
        order: null,
        createdAt: moment(),
        updatedAt: moment(),
      })
    }

    FilterSet.prototype.deserialize = function(data) {
      if (data.surveyId !== this.group.survey.id) {
        log.error(
          "deserialize: filterSet.surveyId doesn't match group it was put in :/"
        )
      }
      this.id = data.id
      this.label = data.label
      this.surveyId = data.surveyId
      this.ownerId = data.ownerId
      this.filter.deserialize(data.filter)
      this.hiddenCharts = data.hiddenCharts || []
      this.isPrivate = data.isPrivate
      this.isReadOnly = data.ownerId
        ? this.group.ownerId !== data.ownerId
        : false
      data.data = _.isPlainObject(data.data) ? data.data : {}
      this.order = data.data.order
      this.createdAt = moment(data.createdAt)
      this.updatedAt = moment(data.updatedAt)
      this.applyDefaults()
      return this
    }

    FilterSet.prototype.serialize = function() {
      var data = {}
      data.id = this.id
      data.label = this.label
      data.surveyId = this.surveyId
      data.ownerId = this.ownerId
      data.filter = this.filter.serialize()
      data.hiddenCharts = _.clone(this.hiddenCharts)
      data.isPrivate = this.isPrivate
      data.data = {}
      data.data.order = this.order
      data.createdAt = this.createdAt.toISOString()
      data.updatedAt = this.updatedAt.toISOString()
      return data
    }

    FilterSet.prototype.init = function() {
      this.filter.init()
      return this
    }

    FilterSet.prototype.duplicate = function() {
      var filterSet = this.clone()
      filterSet.id = null
      filterSet.ownerId = this.group.ownerId
      filterSet.label = this.group.generateLabel(filterSet.label)
      filterSet.isReadOnly = false
      return filterSet
    }

    FilterSet.prototype.clone = function() {
      var data = this.serialize()
      return new FilterSet(this.group).deserialize(data)
    }

    FilterSet.prototype.pin = function() {
      this.pinned = this.serialize()
    }

    FilterSet.prototype.discard = function() {
      if (this.pinned) {
        this.deserialize(this.pinned)
        this.pinned = null
        this.modified = false
      }
    }

    FilterSet.prototype.isNew = function() {
      return !this.id
    }

    FilterSet.prototype.canEditVisibility = function() {
      return this.group.accessType === 'subscriber' && !this.isReadOnly
    }

    FilterSet.prototype.hasRules = function() {
      return !!this.getRules().length
    }

    FilterSet.prototype.getRules = function() {
      var rules = []
      _.each(this.filter.groups, function(group) {
        rules = _.concat(rules, group.rules)
      })
      return rules
    }

    FilterSet.prototype.findRules = function(predicateOrType) {
      var rules = this.getRules()
      if (!predicateOrType) {
        return rules
      }
      var predicate = _.isString(predicateOrType)
        ? { type: predicateOrType }
        : predicateOrType
      return _.filter(rules, predicate)
    }

    FilterSet.prototype.validate = function() {
      if (!this.surveyId) {
        log.error('missing surveyId') // send this to sentry!
      }
      var errors = []
      if (!this.label) {
        errors.push('Label is required')
      }
      if (!this.hasRules()) {
        errors.push('At least one rule is required')
      }
      if (!this.filter.validate()) {
        errors.push('All rules must be complete')
      }
      this.errors = _.isEmpty(errors) ? null : errors
      return !this.errors
    }

    FilterSet.prototype.createRule = function(type) {
      return new ResponseFilterRule(this.filter).setType(type)
    }

    FilterSet.prototype.distributeRule = function(rule) {
      // rule must be unique so we clone it before add it to each groups
      _.each(this.filter.groups, function(group) {
        group.rules.push(rule.clone())
      })
    }

    FilterSet.prototype.toMongo = function() {
      if (!this.filter.groups.length) return null
      return this.filter.toMongo()
    }

    return FilterSet
  }
})()
