;(function() {
  'use strict'

  ConsumeInviteController.$inject = ["$q", "$state", "userService", "subscriberService", "orgSelector", "subSelector", "StateFactory"];
  angular
    .module('app.consume-invite')
    .controller('ConsumeInviteController', ConsumeInviteController)

  /* @ngInject */
  function ConsumeInviteController(
    $q,
    $state,
    userService,
    subscriberService,
    orgSelector,
    subSelector,
    StateFactory
  ) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.consumeInvite = consumeInvite
    ctrl.switchOrg = switchOrg
    ctrl.cancel = cancel

    function onInit() {
      ctrl.user = userService.getUser()
      ctrl.isSSR = userService.isSSR()
      ctrl.token = $state.params.token
      ctrl.organisationName = $state.params.organisationName
      ctrl.state = new StateFactory([
        'ready',
        'loading',
        'success',
        'member',
        'ssr',
        'error',
      ])
      getInvite()
    }

    function getInvite() {
      ctrl.state.loading()

      $q.all([
        orgSelector.getUserSubscribers(ctrl.user.id),
        subscriberService.getInvite(ctrl.token),
      ])
        .then(function(results) {
          var userSubscribers = results[0]
          ctrl.inviteData = results[1]

          var isMember = !!_.find(userSubscribers, function(subscriber) {
            return subscriber.id === ctrl.inviteData.subscriberId
          })

          var selectedOrg = ctrl.isSSR
            ? subSelector.getSubscriberId()
            : orgSelector.getOrgId()
          ctrl.isOrgSelected = selectedOrg === ctrl.inviteData.subscriberId

          if (ctrl.isSSR) {
            ctrl.state.ssr()
          } else if (isMember) {
            ctrl.state.member()
          } else if (ctrl.inviteData.consumed) {
            ctrl.state.error()
          } else {
            ctrl.state.ready()
          }
        })
        .catch(function() {
          ctrl.state.error()
        })
    }

    function consumeInvite() {
      ctrl.state.loading()
      subscriberService
        .consumeInvite(ctrl.token)
        .then(function() {
          ctrl.state.success()
        })
        .catch(function() {
          ctrl.state.error()
        })
    }

    function switchOrg() {
      if (ctrl.isSSR) {
        subSelector.setSubscriberId(ctrl.inviteData.subscriberId) // elect the subscriber
      } else {
        orgSelector.setOrgId(ctrl.inviteData.subscriberId) // elect the organisation
      }
      redirect()
    }

    function redirect() {
      // redirect to dashboard
      var absolutePath = $state.href('dashboard', {}, { absolute: true })
      window.location.href = absolutePath
    }

    function cancel() {
      redirect()
    }
  }
})()
