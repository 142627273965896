;(function() {
  'use strict'

  interactionListDirective.$inject = ["$log", "interactionsService", "interactionListService"];
  angular
    .module('core.interactionList')
    .directive('glSpInteractionList', interactionListDirective)

  /* @ngInject */
  function interactionListDirective(
    $log,
    interactionsService,
    interactionListService
  ) {
    InteractionList.$inject = ["$scope", "$location"];
    $log = $log.create('interactionListDirective')

    return {
      restict: 'EA',
      controller: InteractionList,
      controllerAs: 'interactionList',
      templateUrl: 'interaction-list.template.html',
      scope: {
        options: '=',
      },
      bindToController: true,
    }

    function InteractionList($scope, $location) {
      var vm = this

      // Rebuild the list on certain events
      $scope.$on('InteractionList::options-changed', build)
      $scope.$on('survey-report:filtered', build)
      $scope.$on('survey-interaction:response-deleted', build)

      vm.$onInit = onInit

      function onInit() {
        build()
      }

      /**
       * Build the interaction list with current options
       */
      function build() {
        var filters = []
        var options = {}
        vm.options = vm.options || {}

        // allow manual interactions data (disables pagination and http requests, just a list)
        if (vm.options.data) {
          options.data = vm.options.data
        }

        // filter interactions by pageId
        if (vm.options.page) {
          filters.push('chain.page eq ' + vm.options.page)
        }

        // filter interactions by type
        if (vm.options.type) {
          filters.push('interaction.type eq ' + vm.options.type)
        }

        // filter interaction by source (assume all pages for now)
        if (vm.options.source && vm.options.source.length) {
          var pages = vm.options.source.map(function(page) {
            return page.id
          })
          filters.push('chain.page eq ' + pages.join(' or chain.page eq '))
        }

        // join filters together with 'and' call
        if (filters.length) {
          options.filter = filters.join(' and ')
        }

        // make interactions actioned when click
        options.action = !!vm.options.action

        // use state params for pagination if any
        var pageNum = $location.search().page
          ? parseInt($location.search().page)
          : 1

        // allow delete interactions
        options.canModify = vm.options.canModify

        // sort interaction answers by survey questions order
        options.survey = vm.options.survey

        // instantiate our interactions
        vm.interactions = interactionListService.get(options)

        // load a page
        if (!options.data) {
          vm.interactions.goPage(pageNum)
        }
      }
    }
  }
})()
