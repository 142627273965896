;(function() {
  'use strict'

  config.$inject = ["$stateProvider"];
  angular.module('app.kit', []).config(config)

  /* @ngInject */
  function config($stateProvider) {
    $stateProvider.state('kit', {
      url: '/kit/:id',
      parent: 'portal',
      controller: 'Kit',
      controllerAs: '$ctrl',
      templateUrl: 'kit.html',
      title: 'Kit',
      public: true,
    })

    $stateProvider.state('studio-kit-deprecated', {
      url: '/studio/kit/:id',
      redirectTo: 'kit',
    })
  }
})()
