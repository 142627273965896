;(function() {
  'use strict'

  angular.module('glow.reporting').component('glQuestionNpsFilter', Component())

  function Component() {
    return {
      controller: Controller,
      templateUrl: 'nps-filter.template.html',
      bindings: {
        question: '=',
        filter: '=',
      },
    }
  }

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.toggle = toggle

    function onInit() {
      ctrl.choices = [
        { id: 'promoter', option: 'Promoters' },
        { id: 'passive', option: 'Passives' },
        { id: 'detractor', option: 'Detractors' },
      ]

      if (ctrl.filter.newValue) {
        var choice = _.find(ctrl.choices, { option: ctrl.filter.newValue })
        if (choice) {
          ctrl.filter.data.values.push({ id: choice.id })
        }
        ctrl.filter.newValue = undefined
      }

      _.each(ctrl.choices, function(choice) {
        choice.isSelected = _.find(ctrl.filter.data.values, { id: choice.id })
          ? true
          : false
      })
    }

    function toggle(choice) {
      if (choice.isSelected) {
        ctrl.filter.data.values.push({ id: choice.id })
      } else {
        _.remove(ctrl.filter.data.values, { id: choice.id })
      }
    }
  }
})()
