;(function() {
  'use strict'

  Controller.$inject = ["$element", "$window", "d3", "$filter", "$timeout"];
  angular.module('app.core').component('surveyExplorerCloud', {
    controller: Controller,
    templateUrl: 'survey-explorer-cloud.html',
    bindings: {
      sentences: '<',
    },
  })

  /* @ngInject */
  function Controller($element, $window, d3, $filter, $timeout) {
    var ctrl = this

    var root
    var container
    var sentences = []
    var width
    var height
    var minFontSize
    var maxFontSize
    var maxFrequency
    var maxWords
    var items = []
    var fontFamily = 'Roboto'
    var greyColors = ['#636363', '#969696', '#bdbdbd', '#d9d9d9']

    ctrl.$onInit = onInit
    ctrl.$onChanges = onChanges
    ctrl.onDraw = ctrl.onDraw

    function onInit() {
      resize()
      calcFontSizes()
      $timeout(function() {
        build()
      }, 10)
      angular.element($window).on(
        'resize',
        _.debounce(function() {
          resize()
          build()
        }, 400)
      )
    }

    function onChanges(changes) {
      if (changes.sentences) {
        sentences = changes.sentences.currentValue
        if (!changes.sentences.isFirstChange()) {
          build()
        }
      }
    }

    function resize() {
      root = $element[0]
      container = root.querySelector('.container')
      width = container.offsetWidth
      height = container.offsetHeight

      height = height < 10 ? 450 : height
      // width = width < 10 ? $element[0].parentNode.offsetWidth : width
      width = width < 10 ? 450 : width
    }

    function calcFontSizes() {
      // Font Lerper (By Ash)
      // based on 2 tested spacial universes
      var space = (width * height) / 1000 // a space factor
      // based on a space of 1000x333px
      var largeSpace = 333
      var largeMaxSize = 80
      var largeMinSize = 30
      var largeWords = 80
      // based on a space of 290x200px
      var smallSpace = 58
      var smallMaxSize = 35
      var smallMinSize = 20
      var smallWords = 40

      var maxFactor = space / largeSpace
      maxFontSize = maxFactor * (largeMaxSize - largeMinSize) + largeMinSize

      var minFactor = smallSpace / space
      minFontSize = minFactor * (smallMaxSize - smallMinSize) + smallMinSize

      var wordFactor = space / largeSpace
      maxWords = wordFactor * (largeWords - smallWords) + smallWords
    }

    function build() {
      console.log('build')
      cleanup()
      items = itemsFromSentences()
      make()
    }

    function make() {
      var max = _.maxBy(items, 'count')
      maxFrequency = max ? max.count : 0
      cloudFactory(items)
    }

    function fill(d, i) {
      return greyColors[Math.floor(Math.random() * greyColors.length)]
    }

    function calcFontSize(freq) {
      return Math.max(minFontSize, (maxFontSize * freq) / maxFrequency)
    }

    function cleanup() {
      d3.select(container)
        .selectAll('*')
        .remove()
    }

    function itemsFromSentences() {
      var stopWordsMap = getStopWordsMap()
      var map = {}
      for (var sentence of sentences) {
        // remove punctuation and special chars
        sentence = sentence.replace(/[^a-zA-Z0-9\s]/g, '')
        // lowercase
        sentence = sentence.toLowerCase()
        // split
        var words = sentence.split(/\s+/)
        // trim
        words = words.filter(word => word.length > 0)
        // remove stopwords
        words = words.filter(word => !stopWordsMap[word])
        // upsert word map
        for (const word of words) {
          if (map[word]) {
            map[word].count++
          } else {
            map[word] = {
              text: word,
              count: 1,
            }
          }
        }
      }
      // convert to array
      items.length = 0
      for (var key in map) {
        var value = map[key]
        items.push(value)
      }
      // sort and limit
      items = _.sortBy(items, function(word) {
        return -word.count
      }).slice(0, maxWords)

      return items
    }

    function cloudFactory(items) {
      // TODO: Add fill Function Binding for own function
      // var fill = d3. ale.category20();

      d3.layout
        .cloud()
        .size([width, height])
        .words(
          items.map(function(item) {
            return {
              text: item.text,
              size: calcFontSize(item.count),
            }
          })
        )
        // .rotate(function() { return Math.floor(Math.random() * 2) * -90; })
        .rotate(function() {
          return 0
        })
        .font(fontFamily)
        .padding(5)
        .fontWeight('bold')
        .fontSize(function(d) {
          return parseInt(d.size)
        })
        .on('end', draw)
        .start()

      function draw(words) {
        // var numWords = _.size(words)
        // ctrl.onDraw({ numWords: numWords })

        // Center the drawing
        var heightTranslate = height / 2
        var widthTranslate = width / 2
        // var rootElement = $element[0] // ???

        // var selected;

        d3.select(container)
          .append('svg')
          .attr('width', width)
          .attr('height', height)
          .append('g')
          .attr(
            'transform',
            'translate(' + widthTranslate + ',' + heightTranslate + ')'
          ) // Translate to center
          .selectAll('text')
          .data(words)
          .enter()
          .append('text')
          .style('font-size', function(d) {
            return d.size + 'px'
          })
          // .style('font-size', function (d) { return d.size + 'em'; })
          .style('font-family', fontFamily)
          // .style('font-weight', 'bold')
          .style('fill', function(d, i) {
            return fill(d, i)
          })
          .attr('text-anchor', 'middle')
          .attr('transform', function(d) {
            return 'translate(' + [d.x, d.y] + ')'
          })
          .text(function(d) {
            return d.text
          })
          .on('click', function(d) {
            if (ctrl.allowChartClick) {
              ctrl.onClick({ $value: d.text })
            }
            // d3.selectAll('text').classed('active', false);
            // if (selected !== d.text) {
            //     ctrl.onClick({ element: d });
            //     d3.select(this).classed('active', true);
            //     selected = d.text;
            // } else {
            //     ctrl.onClick({ element: { text: null } });
            //     selected = null;
            // }
          })
      }
    }
  }
})()

var stopWords = [
  'a',
  'able',
  'about',
  'across',
  'after',
  'all',
  'almost',
  'also',
  'am',
  'among',
  'an',
  'and',
  'any',
  'are',
  'as',
  'at',
  'be',
  'because',
  'been',
  'but',
  'by',
  'can',
  'cannot',
  'could',
  'dear',
  'did',
  'do',
  'does',
  'either',
  'else',
  'ever',
  'every',
  'for',
  'from',
  'get',
  'got',
  'had',
  'has',
  'have',
  'he',
  'her',
  'hers',
  'him',
  'his',
  'how',
  'however',
  'i',
  'if',
  'in',
  'into',
  'is',
  'it',
  'its',
  'just',
  'least',
  'let',
  'like',
  'likely',
  'may',
  'me',
  'might',
  'most',
  'must',
  'my',
  'neither',
  'no',
  'nor',
  'not',
  'of',
  'off',
  'often',
  'on',
  'only',
  'or',
  'other',
  'our',
  'own',
  'rather',
  'said',
  'say',
  'says',
  'she',
  'should',
  'since',
  'so',
  'some',
  'than',
  'that',
  'the',
  'their',
  'them',
  'then',
  'there',
  'these',
  'they',
  'this',
  'tis',
  'to',
  'too',
  'twas',
  'us',
  'wants',
  'was',
  'we',
  'were',
  'what',
  'when',
  'where',
  'which',
  'while',
  'who',
  'whom',
  'why',
  'will',
  'with',
  'would',
  'yet',
  'you',
  'your',
  'not-asked',
]
var stopWordsMap
function getStopWordsMap() {
  if (!stopWordsMap) {
    stopWordsMap = {}
    for (const word of stopWords) {
      stopWordsMap[word] = true
    }
  }
  return stopWordsMap
}
