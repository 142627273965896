;(function() {
  'use strict'

  config.$inject = ["$stateProvider"];
  angular.module('app.beacons', []).config(config)

  /* @ngInject */
  function config($stateProvider) {
    $stateProvider.state('beacons', {
      url: '/beacons',
      parent: 'portal',
      controller: 'Beacons',
      controllerAs: '$ctrl',
      templateUrl: 'beacons.html',
      title: 'Beacons',
    })
  }
})()
