;(function() {
  'use strict'

  Controller.$inject = ["$scope", "$document"];
  angular
    .module('core.questionLibrary')
    .controller('QuestionLibraryVariations', Controller)

  /* @ngInject */
  function Controller($scope, $document) {
    var ctrl = this

    ctrl.selected = _.first(ctrl.template.questions)
    ctrl.isVariations = ctrl.template.questions.length > 1

    ctrl.back = back
    ctrl.done = done
    ctrl.onKeyUp = onKeyUp
    ctrl.previous = previous
    ctrl.next = next

    $document.on('keyup', onKeyUp)

    // Displays variations based on question types:
    // - choice
    // - scale

    function back() {
      ctrl.onBack()
      $scope.dialog.close()
    }

    function done() {
      ctrl.onSelect(ctrl.selected)
      $scope.dialog.close()
    }

    function onKeyUp(e) {
      switch (e.which) {
        case 37: // LEFT
          $scope.$applyAsync(previous)
          break
        case 39: // RIGHT
          $scope.$applyAsync(next)
          break
      }
    }

    function previous() {
      var index = _.indexOf(ctrl.template.questions, ctrl.selected)
      if (index === 0) {
        ctrl.selected = _.last(ctrl.template.questions)
      } else {
        ctrl.selected = ctrl.template.questions[index - 1]
      }
    }

    function next() {
      var index = _.indexOf(ctrl.template.questions, ctrl.selected)
      if (index === ctrl.template.questions.length - 1) {
        ctrl.selected = _.first(ctrl.template.questions)
      } else {
        ctrl.selected = ctrl.template.questions[index + 1]
      }
    }
  }
})()
