;(function() {
  'use strict'

  Service.$inject = ["$q", "Estimate", "estimatesResource", "subscriberService", "panelService", "deviceService"];
  angular.module('app.core').service('estimateService', Service)

  /* @ngInject */
  function Service(
    $q,
    Estimate,
    estimatesResource,
    subscriberService,
    panelService,
    deviceService
  ) {
    var self = this

    self.Types = Estimate.Types
    self.TypeInfo = Estimate.TypeInfo
    self.create = create
    self.save = save
    self.checkFeasibility = checkFeasibility
    self.email = email

    function create() {
      var subscriber = subscriberService.getSubscriber()
      var pricing = subscriber && subscriber.plan.pricing
      var currency = subscriber && subscriber.billing.currency
      var estimate = new Estimate(pricing, currency)
      estimate.deviceId = deviceService.getId()
      estimate.subscriberId = subscriber && subscriber.id
      return estimate
    }

    function save(estimate) {
      return $q(function(resolve, reject) {
        estimate.error = null
        estimate.isSaving = true
        estimate.calculateTotalCost()
        var action = estimate.isNew() ? 'create' : 'update'
        estimatesResource[action](estimate.serialize())
          .success(function(resp) {
            if (resp.id) {
              estimate.id = resp.id
            }
            estimate.isSaving = false
            resolve()
          })
          .error(function(error) {
            estimate.isSaving = false
            estimate.error = true
            reject(error)
          })
      })
    }

    function checkFeasibility(estimate) {
      var panel = panelService.create().forEstimate()
      panel.lengthOfInterview.become(estimate.lengthOfInterview)
      panel.requiredCompletes = estimate.responses
      panel.incidenceRate = estimate.incidenceRate
      panel.country = estimate.country
      panel.language = estimate.language
      panel.targets = estimate.targets.map(function(target) {
        return target.clone()
      })
      estimate.isCheckingFeasibility = true
      return panelService
        .save(panel)
        .then(function() {
          return panelService
            .awaitFeasibility(panel)
            .then(function(feasibility) {
              console.log('feasibility', feasibility)
              estimate.isCheckingFeasibility = false
              estimate.isFeasible = feasibility.feasible
              estimate.matches = feasibility.totalCount
            })
        })
        .catch(function(error) {
          estimate.isCheckingFeasibility = false
          console.error(error)
          return $q.reject(error)
        })
    }

    function email(estimate) {
      return $q(function(resolve, reject) {
        estimate.isEmailing = true
        estimate.buildTextRowsForEmail()
        save(estimate).then(function() {
          estimatesResource
            .emailEstimate(estimate.serialize())
            .success(function() {
              estimate.isEmailing = false
              resolve()
            })
            .error(function() {
              estimate.isEmailing = false
              reject()
            })
        })
      })
    }
  }
})()
