;(function() {
  'use strict'

  Controller.$inject = ["$location", "$timeout", "glAnalytics", "glToast", "glPrefs", "userService", "helpService"];
  angular.module('app.core').component('experience', {
    controller: Controller,
    templateUrl: 'experience.html',
  })

  /* @ngInject */
  function Controller(
    $location,
    $timeout,
    glAnalytics,
    glToast,
    glPrefs,
    userService,
    helpService
  ) {
    var ctrl = this
    var prefs = glPrefs.create('experience')

    var DisabledTimeout = 5000

    ctrl.$onInit = onInit
    ctrl.select = select
    ctrl.collapse = collapse

    function onInit() {
      ctrl.isCollapsed = prefs.get('isCollapsed')
      ctrl.Faces = [
        {
          id: 'happy',
          icon: 'mood-2',
          color: '#4caf50',
          hoverColor: '#197c1d',
        },
        {
          id: 'angry',
          icon: 'mood-5',
          color: '#ffab00',
          hoverColor: '#cc7800',
        },
      ]
    }

    function select(face) {
      if (userService.isSSR()) {
        glToast.show('SSR users cannot provide feedback :(')
      } else {
        glAnalytics.track('experience', 'clicked-' + face.id, $location.url())
        collapse(false)
        glToast.show('Thanks for the feedback!')

        // pop up help scout chat on click angry face
        if (face.id === 'angry') {
          helpService.chat()
        }
      }

      // Prevent multiple clicks
      ctrl.disabled = true
      $timeout(function() {
        ctrl.disabled = false
      }, DisabledTimeout)
    }

    function collapse(userActivated) {
      if (ctrl.isCollapsed) {
        return
      }
      if (userActivated) {
        glAnalytics.track('experience', 'clicked-collapse')
      }
      prefs.set('isCollapsed', true)
      ctrl.isCollapsed = true
    }
  }
})()
