;(function() {
  'use strict'

  angular
    .module('glow.reporting.charts')
    .component('reportSemanticDiffText', Component())

  /* @ngInject */
  function Component() {
    return {
      controller: Controller,
      templateUrl: 'report-semantic-diff-text.html',
      bindings: {
        question: '<',
      },
    }
  }

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onChanges = onChanges

    function onChanges() {
      ctrl.leftText = ctrl.question.data.numericDiffLeft
      ctrl.rightText = ctrl.question.data.numericDiffRight
    }
  }
})()
