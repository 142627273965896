;(function() {
  'use strict'

  Controller.$inject = ["industryService", "glAnalytics"];
  angular.module('app.core').component('publicationIndustrySelect', {
    controller: Controller,
    templateUrl: 'publication-industry-select.html',
    bindings: {
      onChange: '&',
    },
  })

  /* @ngInject */
  function Controller(industryService, glAnalytics) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.addIndustry = addIndustry
    ctrl.removeIndustry = removeIndustry

    function onInit() {
      ctrl.Industries = _.map(industryService.getAll(), function(industry) {
        return { value: industry.code, label: industry.name }
      })
      ctrl.selectedIndustries = []
    }

    function addIndustry(industryCode) {
      if (_.some(ctrl.selectedIndustries, { code: industryCode })) {
        return
      }
      var industry = industryService.getByCode(industryCode)
      ctrl.selectedIndustries.push(industry)
      ctrl.onChange({ $selectedIndustries: ctrl.selectedIndustries })
      glAnalytics.track(
        'publications',
        'filtered-by-publication-industry',
        _.kebabCase(industryCode)
      )
    }

    function removeIndustry(industry) {
      if (!_.some(ctrl.selectedIndustries, { code: industry.code })) {
        return
      }
      _.remove(ctrl.selectedIndustries, { code: industry.code })
      ctrl.onChange({ $selectedIndustries: ctrl.selectedIndustries })
    }
  }
})()
