;(function() {
  'use strict'

  Controller.$inject = ["colors"];
  angular.module('app.core').component('omniTag', {
    controller: Controller,
    templateUrl: 'omni-tag.html',
    bindings: {
      omni: '<',
    },
  })

  /* @ngInject */
  function Controller(colors) {
    var ctrl = this
    ctrl.getColor = colors.getByKey
  }
})()
