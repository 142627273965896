;(function() {
  'use strict'

  Controller.$inject = ["glDialog", "surveyReport"];
  angular.module('glow.reporting').component('glSegmentFilter', Component())

  function Component() {
    return {
      controller: Controller,
      templateUrl: 'segment-filter.template.html',
      bindings: {
        survey: '=',
        filter: '=',
      },
    }
  }

  /* @ngInject */
  function Controller(glDialog, surveyReport) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.isSelected = isSelected
    ctrl.setSelected = setSelected
    ctrl.manage = manage

    function onInit() {
      prepare()
    }

    function prepare() {
      if (!ctrl.survey.segments) {
        ctrl.survey.segments = []
      }
      ctrl.segments = ctrl.survey.segments
    }

    function isSelected(segment) {
      return ctrl.filter.data.values.find(function(value) {
        return value === segment
      })
    }

    function setSelected(segment, selected) {
      _.remove(ctrl.filter.data.values, function(value) {
        return value === segment
      })
      if (selected) {
        ctrl.filter.data.values.push(segment)
      }
    }

    function manage() {
      // prettier-ignore
      var template = [
        '<gl-dialog class="segment-manager__dialog">',
          '<segment-manager',
            'survey-id="surveyId" ',
            'on-cancel="dialog.cancel()" ',
            'on-complete="dialog.close($newSegments)" ',
          '/>',
        '</gl-dialog>'
      ]
      glDialog
        .show({
          template: template.join(' '),
          locals: {
            surveyId: ctrl.survey.id,
          },
        })
        .then(function(newSegments) {
          ctrl.survey.segments = newSegments
          prepare()
          surveyReport.modifyCachedSurvey(ctrl.survey.id, function(survey) {
            survey.segments = newSegments
          })
        })
    }
  }
})()
