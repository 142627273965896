;(function() {
  'use strict'

  Controller.$inject = ["publisherService"];
  angular.module('app.core').component('publicationPublisher', {
    controller: Controller,
    templateUrl: 'publication-publisher.html',
    bindings: {
      publication: '<',
      isPublisher: '<',
      isLoggedIn: '<',
    },
  })

  /* @ngInject */
  function Controller(publisherService) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.trackCtaClick = publisherService.trackCtaClick

    function onInit() {}
  }
})()
