;(function() {
  'use strict'

  Service.$inject = ["api", "glDialog", "ReportLink"];
  angular.module('glow.reporting').service('reportLinkService', Service)

  /* @ngInject */
  function Service(api, glDialog, ReportLink) {
    this.spawn = spawn
    this.save = save
    this.getById = getById
    this.getBySurvey = getBySurvey
    this.getByToken = getByToken
    this.refresh = refresh
    this.destroy = destroy
    this.showListDialog = showListDialog
    this.showEditDialog = showEditDialog
    this.isViewVisible = isViewVisible

    function spawn() {
      return new ReportLink()
    }

    function save(link) {
      link.isSaving = true
      var action = link.id ? update : create
      return action(link).finally(function() {
        link.isSaving = false
      })
    }

    function create(link) {
      return api.surveyReportShares
        .create(link.serialize())
        .then(function(data) {
          link.id = data.id
          return refresh(link)
        })
    }

    function update(link) {
      return api.surveyReportShares.update(link.serialize())
    }

    function getById(id) {
      return api.surveyReportShares.get({ id: id }).then(function(data) {
        migrate(data)
        return new ReportLink().deserialize(data)
      })
    }

    function getBySurvey(surveyId) {
      return api.surveyReportShares
        .getBySurveyId({ surveyId: surveyId })
        .then(function(data) {
          migrate(data)
          return data.map(function(data) {
            return new ReportLink().deserialize(data)
          })
        })
    }

    function getByToken(token) {
      return api.surveyReportShares
        .getByToken({ token: token })
        .then(function(data) {
          migrate(data)
          return new ReportLink().deserialize(data)
        })
    }

    function refresh(link) {
      return getById(link.id).then(function(latest) {
        link.copy(latest)
      })
    }

    function destroy(link) {
      return api.surveyReportShares.remove({
        id: link.id,
        surveyId: link.surveyId,
      })
    }

    function showListDialog(surveyId) {
      // prettier-ignore
      var template = [
        '<gl-dialog class="report-link-list__dialog">',
          '<report-link-list survey-id="surveyId" on-close="dialog.cancel()" />',
        '</gl-dialog>',
      ]
      return glDialog.show({
        template: template.join(' '),
        clickOutsideToClose: true,
        locals: {
          surveyId: surveyId,
        },
      })
    }

    function showEditDialog(link) {
      // prettier-ignore
      var template = [
        '<gl-dialog class="report-link-editor__dialog">',
          '<report-link-editor link="link" on-save="dialog.close()" on-close="dialog.cancel()" />',
        '</gl-dialog>',
      ]
      return glDialog.show({
        template: template.join(' '),
        locals: {
          link: link,
        },
      })
    }

    function migrate(datas) {
      datas = _.isArray(datas) ? datas : [datas]
      _.each(datas, function(data) {
        data.data = _.isPlainObject(data.data) ? data.data : {}

        if (!data.data.mode) {
          data.data.name = data.email
            ? data.email
            : data.isRestricted
            ? 'Restricted'
            : 'Full'
          data.data.mode = 'ALL'
          data.data.viewIds = []
        }
      })
    }

    // When viewing a survey through a report-link/shared-report
    // you can use this to figure out which questions and sections should
    // be visible to the user
    //
    // targetViewId         A question or section id
    // mode                 ReportLink.mode
    // viewIds              ReportLink.viewIds
    //
    // returns whether the targetViewId should be visible given the mode/viewIds
    function isViewVisible(targetViewId, mode, viewIds) {
      switch (mode) {
        case ReportLink.Modes.ALL:
          return true
        case ReportLink.Modes.INCLUDE:
          return viewIds.includes(targetViewId)
        case ReportLink.Modes.EXCLUDE:
          return !viewIds.includes(targetViewId)
        default:
          return true // eg, no mode
      }
    }
  }
})()
