;(function() {
  'use strict'

  Controller.$inject = ["$scope", "$timeout", "$window", "$document", "surveyReport", "reportQuestionExporter", "glAnalytics", "responseTypeService", "uacService", "reportMediaBtn", "glToast"];
  angular.module('glow.reporting').component('reportViewSection', {
    controller: Controller,
    templateUrl: 'report-view-section.html',
    bindings: {
      survey: '<',
      section: '<',
      filterSetGroup: '<',
      loopTracker: '<',
    },
  })

  /* @ngInject */
  function Controller(
    $scope,
    $timeout,
    $window,
    $document,
    surveyReport,
    reportQuestionExporter,
    glAnalytics,
    responseTypeService,
    uacService,
    reportMediaBtn,
    glToast
  ) {
    var ctrl = this

    ctrl.mediaBtnType = reportMediaBtn.Types.IMAGE

    ctrl.$onChanges = onChanges
    ctrl.$onDestroy = onDestroy
    ctrl.getSelectedLoopKeyId = getSelectedLoopKeyId
    ctrl.toMarkdown = toMarkdown

    $scope.$on('survey-reporting:load-start', function() {
      ctrl.loading = true
    })
    $scope.$on('survey-reporting:load-end', function() {
      ctrl.loading = false
    })

    function init() {
      ctrl.hasInit = true
    }

    function onChanges(changes) {
      if (!ctrl.hasInit) init()
    }

    function onDestroy() {
      // ...
    }

    function getSelectedLoopKeyId() {
      return ctrl.question.loop
        ? ctrl.loopTracker.getSelected(ctrl.question.loop.id)
        : null
    }

    function toMarkdown(string) {
      return window.marked(string)
    }
  }
})()
