;(function() {
  'use strict'

  Controller.$inject = ["StateFactory", "projectService", "userService", "subscriberService", "uacService"];
  angular.module('app.projects').component('projectEditor', {
    controller: Controller,
    templateUrl: 'project-editor.html',
    bindings: {
      originalProject: '<project',
      onCancel: '&',
      onSaved: '&',
    },
  })

  /* @ngInject */
  function Controller(
    StateFactory,
    projectService,
    userService,
    subscriberService,
    uacService
  ) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.canEditVisibility = canEditVisibility
    ctrl.save = save
    ctrl.formatPrivateToUserIds = formatPrivateToUserIds

    function onInit() {
      ctrl.currentUserId = userService.getUser().id
      ctrl.currentSubscriberId = subscriberService.getSubscriber().id
      ctrl.isSSR = userService.isSSR()
      ctrl.isAdmin = subscriberService.isAdmin(ctrl.currentUserId)

      ctrl.state = new StateFactory(['loading', 'ready', 'error'])
      ctrl.state.loading()

      ctrl.project = ctrl.originalProject.clone()

      // When a guest creates a project, ensure they are given access
      // to edit visibility
      if (ctrl.project.isNew() && !ctrl.isSSR && !ctrl.isAdmin) {
        ctrl.project.canEditVisibilityGuestIds.push(ctrl.currentUserId)
      }

      ctrl.visibilityOptions = [
        { label: 'Everyone in this organisation', value: 'EVERYONE' },
        { label: 'Specific users in this organisation', value: 'PRIVATE' },
      ]

      subscriberService
        .getSubscriberUsers(ctrl.currentSubscriberId)
        .then(function(users) {
          ctrl.users = users
          ctrl.userOptions = getUserOptions()
          ctrl.adminUserIds = getAdminUserIds()

          // Admins have implicit access and are excluded from Project.privateToGuestIds
          // But we show them here in the UI so that it's obvious they have access.
          ctrl.privateToUserIds = []
          _.each(ctrl.adminUserIds, function(userId) {
            ctrl.privateToUserIds.push(userId)
          })
          _.each(ctrl.project.privateToGuestIds, function(userId) {
            // It's possible a user was given permission and then removed from the organisation,
            // so we ignore this user to prevent errors
            if (!_.find(ctrl.users, { id: userId })) {
              return
            }
            ctrl.privateToUserIds.push(userId)
          })
          if (!ctrl.isSSR) {
            ctrl.privateToUserIds.push(ctrl.currentUserId)
          }
          ctrl.privateToUserIds = _.uniq(ctrl.privateToUserIds)

          ctrl.visibilityText = getVisibilityText()
          ctrl.state.ready()
        })
        .catch(function(err) {
          console.error(err)
          ctrl.state.error()
        })
    }

    function canEditVisibility() {
      return (
        ctrl.isSSR ||
        ctrl.isAdmin ||
        _.includes(ctrl.project.canEditVisibilityGuestIds, ctrl.currentUserId)
      )
    }

    function getAdminUserIds() {
      return _(ctrl.users)
        .filter(function(user) {
          return subscriberService.isAdmin(user.id)
        })
        .map('id')
        .value()
    }

    function getUserOptions() {
      return _(ctrl.users)
        .orderBy(function(user) {
          if (subscriberService.isAdmin(user.id)) return 1
          if (user.id === ctrl.currentUserId) return 2
          return 3
        }, 'asc')
        .map(function(user) {
          var isAdmin = subscriberService.isAdmin(user.id)
          var isMe = ctrl.currentUserId === user.id
          return {
            label: isMe ? 'Me' : user.fullname + (isAdmin ? ' (Admin)' : ''),
            value: user.id,
            disabled: isAdmin ? true : isMe,
          }
        })
        .value()
    }

    function getVisibilityText() {
      if (!ctrl.project.isPrivate()) {
        return 'This project is visible to everyone'
      }
      var names = _(ctrl.privateToUserIds)
        .orderBy(function(userId) {
          if (ctrl.currentUserId === userId) return 1
          if (subscriberService.isAdmin(userId)) return 2
          return 3
        })
        .map(function(userId) {
          if (userId === ctrl.currentUserId) return 'You'
          var user = _.find(ctrl.users, { id: userId })
          return user.fullname
        })
        .value()
      if (names.length === 1) {
        return 'This project is only visible to ' + names[0]
      }
      var text = 'This project is visible to '
      var first = true
      while (names.length) {
        var name = names.shift()
        if (first) {
          text += name
          first = false
        } else if (names.length) {
          text += ', ' + name
        } else {
          text += ' and ' + name
        }
      }
      return text
    }

    function save() {
      if (!uacService.canManageProjects(true)) return
      // Copy over access users excluding admins.
      // Admins have access by default.
      if (canEditVisibility()) {
        ctrl.project.privateToGuestIds = ctrl.privateToUserIds.filter(function(
          userId
        ) {
          if (ctrl.isSSR && ctrl.currentUserId === userId) return false
          return !subscriberService.isAdmin(userId)
        })
      }

      if (!ctrl.project.isPrivate()) {
        ctrl.project.privateToGuestIds = []
      }

      if (!ctrl.project.validate() || ctrl.project.isSaving) {
        return
      }

      projectService.save(ctrl.project).then(function() {
        ctrl.originalProject.replace(ctrl.project)
        ctrl.onSaved({ $project: ctrl.originalProject })
      })
    }

    function formatPrivateToUserIds(options) {
      if (options.length === 1 && options[0].value === ctrl.currentUserId) {
        return 'Just me'
      }
      return _.map(options, function(option) {
        return option.label
      }).join(', ')
    }
  }
})()
