;(function() {
  'use strict'

  angular.module('glow.reporting').component('loopKeySelector', {
    controller: Controller,
    templateUrl: 'loop-key-selector.html',
    bindings: {
      tracker: '<',
      loop: '<',
    },
  })

  /* @ngInject */
  function Controller() {
    // ...
  }
})()
