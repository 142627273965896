;(function() {
  'use strict'

  Controller.$inject = ["$timeout", "reportD3ChartService", "c3", "d3", "renderService"];
  angular.module('glow.reporting.charts').component('reportStackedChart', {
    controller: Controller,
    templateUrl: 'report-stacked-chart.html',
    bindings: {
      question: '<',
      absolute: '<',
      focus: '<',
      hide: '<',
      // order: '<',
      height: '<',
      allowChartClick: '<',
      onChartClick: '&',
    },
  })

  /* @ngInject */
  function Controller($timeout, reportD3ChartService, c3, d3, renderService) {
    var ctrl = this

    ctrl.$onChanges = onChanges

    function onChanges(changes) {
      if (!ctrl.hasInit) {
        ctrl.chartId = _.uniqueId('reportStackedChart')
        $timeout(build, 10)
        ctrl.hasInit = true
        return
      }
      if (changes.question || changes.absolute) {
        return build()
      }
      // if (changes.order) {
      //     console.log('order', ctrl.order);
      //     return build();
      // }
      if (changes.focus) {
        ctrl.chart.focus(ctrl.focus)
      }
      if (changes.hide) {
        ctrl.chart.show()
        if (ctrl.hide.length) {
          setTimeout(function() {
            ctrl.chart.hide(ctrl.hide)
          })
        }
      }
    }

    function build() {
      if (ctrl.chart) {
        delete ctrl.chart
      }

      if (ctrl.question.type === 'rank') {
        parseRank()
      } else if (ctrl.question.type === 'matrix') {
        parseMatrix()
      } else if (ctrl.question.type === 'constantsum') {
        parseConstantSum()
      } else {
        throw new Error(
          'cannot show stacked-chart for type:',
          ctrl.question.type
        )
      }

      var config = {
        bindto: '#' + ctrl.chartId,
        data: {
          type: 'bar',
          columns: ctrl.columns,
          groups: [ctrl.groups],
          colors: ctrl.colors,
          onclick: onChartClick,
          order: null,
          // NOTE: this adds a cursor on hover but click makes highlight
          // sticky, so disabled it
          // selection: {
          //     enabled: true
          // }
        },
        axis: {
          rotated: true,
          x: {
            type: 'category',
            categories: ctrl.categories,
          },
          y: {
            min: 0,
            max: 1,
            padding: 0,
            tick: {
              values: [0, 0.2, 0.4, 0.6, 0.8, 1],
              format: d3.format('.0%'),
            },
          },
        },
        grid: {
          focus: {
            show: false,
          },
        },
        bar: {
          width: {
            ratio: 0.8,
          },
        },
        tooltip: {
          grouped: false,
          format: {
            title: function() {
              // This is too long
              // return ctrl.segments[idx].text;
            },
            name: function(name, ratio, id, idx) {
              return ctrl.info[id][idx].tooltipLabel
            },
            value: function(value, ratio, id, idx) {
              return ctrl.info[id][idx].tooltip
            },
          },
        },
        legend: {
          show: false,
        },
      }
      if (ctrl.absolute) {
        var max = ctrl.absolute.max
        config.axis.y = {
          min: 0,
          max: max,
          padding: 0,
        }
        // config.data.labels.format = function (value) {
        //     return value;
        // };
        // config.tooltip.format.value = function (value) {
        //     return value;
        // }
      }

      renderService.queue(function() {
        ctrl.chart = c3.generate(config)
      })
    }

    function parseMatrix() {
      ctrl.columns = []
      ctrl.colors = []
      ctrl.groups = []
      ctrl.categories = []
      ctrl.info = {}

      var aspects = ctrl.question.aspects
      var choiceAspects = _.groupBy(aspects, 'choiceId')
      _.each(choiceAspects, function(aspects, choiceId) {
        var choice = _.find(ctrl.question.choices, { id: choiceId })
        var label = choice.option
        ctrl.info[label] = {}
        var column = []
        column.push(label)
        _.each(aspects, function(aspect, idx) {
          var tooltip = ctrl.absolute
            ? aspect.count
            : aspect.percent + '% (' + aspect.count + ')'
          ctrl.info[label][idx] = {
            value: choice.option,
            aspect: aspect.statementId,
            tooltip: tooltip,
            tooltipLabel: _.truncate(label, { length: 40 }),
          }
          column.push(ctrl.absolute ? aspect.count : aspect.ratio)
        })
        ctrl.columns.push(column)
      })

      _.each(ctrl.question.choices, function(choice) {
        var label = choice.option
        ctrl.groups.push(label)
        ctrl.colors[label] = choice.color
      })

      ctrl.categories = _.map(ctrl.question.statements, function(statement) {
        return _.truncate(statement.statement, { length: 40 })
      })
    }

    function parseRank() {
      ctrl.columns = []
      ctrl.colors = []
      ctrl.groups = []
      ctrl.categories = []
      ctrl.info = {}

      var aspects = ctrl.question.aspects
      var rankAspects = _.groupBy(aspects, 'rankId')

      // we build a map of choice->option so we don't need to do
      // super slow iterations below...
      var choiceValues = {}
      _.each(ctrl.question.choices, function(choice) {
        choiceValues[choice.id] = choice.option
      })

      _.each(rankAspects, function(aspects, rankId) {
        var rank = _.find(ctrl.question.ranks, { id: rankId })
        ctrl.info[rank.label] = {}
        var column = []
        column.push(rank.label)
        _.each(aspects, function(aspect, idx) {
          var tooltip = ctrl.absolute
            ? aspect.count
            : aspect.percent + '% (' + aspect.count + ')'
          ctrl.info[rank.label][idx] = {
            value: choiceValues[aspect.choiceId], // TODO: make this use ID's
            aspect: rank.value,
            tooltip: tooltip,
            tooltipLabel: rank.label,
          }
          column.push(ctrl.absolute ? aspect.count : aspect.ratio)
        })
        ctrl.columns.push(column)
      })
      _.each(ctrl.question.ranks, function(rank) {
        ctrl.colors[rank.label] = rank.color
        ctrl.groups.push(rank.label)
      })
      ctrl.categories = _.map(ctrl.question.choices, function(choice) {
        return _.truncate(choice.option, { length: 40 })
      })
    }

    function parseConstantSum() {
      ctrl.columns = []
      ctrl.colors = {}
      ctrl.groups = []
      ctrl.categories = []
      ctrl.info = {}

      var aspects = ctrl.question.aspects
      var choiceAspects = _.groupBy(aspects, 'choiceId')
      _.each(choiceAspects, function(aspects, choiceId) {
        var choice = _.find(ctrl.question.choices, { id: choiceId })
        var label = choice.option
        ctrl.info[label] = {}
        var column = []
        column.push(label)
        _.each(aspects, function(aspect, idx) {
          var tooltip = ctrl.absolute
            ? aspect.total
            : aspect.totalPercent + '% (' + aspect.total + ')'
          ctrl.info[label][idx] = {
            value: choice.option,
            aspect: aspect.statementId,
            tooltip: tooltip,
            tooltipLabel: _.truncate(label, { length: 40 }),
          }
          column.push(ctrl.absolute ? aspect.total : aspect.totalRatio)
        })
        ctrl.columns.push(column)
      })

      _.each(ctrl.question.choices, function(choice) {
        var label = choice.option
        ctrl.groups.push(label)
        ctrl.colors[label] = choice.color
      })

      ctrl.categories = _.map(ctrl.question.statements, function(statement) {
        return _.truncate(statement.statement, { length: 40 })
      })
    }

    function onChartClick(d) {
      if (!ctrl.allowChartClick) {
        return
      }
      var info = ctrl.info[d.id][d.index]
      ctrl.onChartClick({
        $value: info.value,
        $aspect: info.aspect,
      })
    }
  }
})()
