;(function() {
  'use strict'

  Service.$inject = ["$q", "$rootScope", "AUTH_EVENTS", "glDialog", "glPrefs", "subscribersResource"];
  angular.module('core.subSelector').service('subSelector', Service)

  /* @ngInject */
  function Service(
    $q,
    $rootScope,
    AUTH_EVENTS,
    glDialog,
    glPrefs,
    subscribersResource
  ) {
    var self = this
    var prefs = glPrefs.create('subSelector')

    self.search = search
    self.select = select
    self.getSubscriberId = getSubscriberId
    self.setSubscriberId = setSubscriberId

    init()

    function init() {
      $rootScope.$on(AUTH_EVENTS.logoutSuccess, onLogout)
    }

    function search(query) {
      return $q(function(resolve, reject) {
        var params = {
          filter:
            'administrator.email regex /' +
            query +
            "/i or id eq '" +
            query +
            "' or name regex /" +
            query +
            '/i',
          andFilters: 'enabled eq true',
          orderby: 'name+asc',
          skip: 0,
          top: 50,
        }

        subscribersResource
          .get(params)
          .success(function(x, subscribers) {
            // console.log('subscribers', subscribers);
            resolve(subscribers)
          })
          .error(function() {
            // console.log('error');
            reject()
          })
      })
    }

    function select(reselect) {
      if (!reselect) {
        var subscriberId = getSubscriberId()
        if (subscriberId) {
          return $q.resolve(subscriberId)
        }
      }
      var template = [
        '<gl-dialog class="sub-selector__dialog">',
        '<sub-selector on-select="dialog.close($id)" on-cancel="dialog.cancel()" />',
        '</gl-dialog>',
      ].join('')
      return glDialog
        .show({
          template: template,
        })
        .then(function(subscriberId) {
          setSubscriberId(subscriberId)
          return subscriberId
        })
    }

    function getSubscriberId() {
      return prefs.get('subscriberId')
    }

    function setSubscriberId(subscriberId) {
      prefs.set('subscriberId', subscriberId)
    }

    function onLogout() {
      setSubscriberId(null)
    }
  }
})()
