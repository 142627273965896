;(function() {
  'use strict'

  angular.module('glow.reporting').component('filterSetCharts', {
    controller: Controller,
    templateUrl: 'filter-set-charts.html',
    bindings: {
      report: '<',
      hiddenCharts: '=',
      isReadOnly: '<readOnly',
      onChange: '&',
    },
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.setHidden = setHidden
    ctrl.selectAll = selectAll
    ctrl.selectNone = selectNone

    function onInit() {
      ctrl.charts = _(ctrl.report.questions)
        .filter({ placement: 'chart' })
        .map(function(question) {
          return {
            id: question.id,
            label: question.listTitle,
            isHidden: _.includes(ctrl.hiddenCharts, question.id),
          }
        })
        .value()
    }

    function setHidden(chart, hidden) {
      _.remove(ctrl.hiddenCharts, function(chartId) {
        return chartId === chart.id
      })
      if (hidden) {
        ctrl.hiddenCharts.push(chart.id)
      }
      chart.isHidden = hidden
      ctrl.onChange()
    }

    function selectAll() {
      _.remove(ctrl.hiddenCharts)
      _.each(ctrl.charts, function(chart) {
        chart.isHidden = false
      })
      ctrl.onChange()
    }

    function selectNone() {
      _.remove(ctrl.hiddenCharts)
      _.each(ctrl.charts, function(chart) {
        ctrl.hiddenCharts.push(chart.id)
        chart.isHidden = true
      })
      ctrl.onChange()
    }
  }
})()
