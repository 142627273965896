;(function() {
  'use strict'

  imagesService.$inject = ["$q", "$http", "imagesResource", "glDialog"];
  angular.module('core.services').factory('imagesService', imagesService)

  /* @ngInject */
  function imagesService($q, $http, imagesResource, glDialog) {
    var VALID_FILETYPES = [
      'image/bmp',
      'image/gif',
      'image/jpg',
      'image/jpeg',
      'image/png',
      'image/svg+xml',
    ]

    return {
      create: create,
      prompt: prompt,
      isValidFile: isValidFile,
      fetchBase64DataFromUrl: fetchBase64DataFromUrl,
    }

    function create(data) {
      var deferred = $q.defer()

      // for use in $q.all return consistent array length
      if (!data) {
        return $q.when(null)
      }

      imagesResource
        .create(data)
        .success(deferred.resolve)
        .error(deferred.reject)

      return deferred.promise
    }

    /**
     * Prompt
     * TODO: options etc
     * @returns {Promise} a promise which resolves to { didRemove, imageUrl }
     */
    function prompt(options) {
      return glDialog.show({
        controller: 'ImagePrompt',
        templateUrl: 'image-prompt.template.html',
        locals: { options: options },
        clickOutsideToClose: true,
      })
    }

    /**
     * @name isValidFile
     * @description check if file is of a valid image type
     * @param  {Base64}  file file data
     * @return {Boolean}      true if file is a valid image type
     */
    function isValidFile(file) {
      return file && _.indexOf(VALID_FILETYPES, file.type) >= 0
    }

    /**
     * @name fetchBase64DataFromUrl
     * @description fetch image base64 data from given url
     * @param  {String} imageUrl image url
     * @return {Promise} a promise which resolves to image base64 data
     */
    function fetchBase64DataFromUrl(imageUrl) {
      return $http({
        method: 'GET',
        url: imageUrl,
        responseType: 'blob',
      })
        .then(function(response) {
          var deferred = $q.defer()
          var reader = new FileReader()
          reader.onload = function(e) {
            deferred.resolve(e.target.result)
          }
          reader.onerror = function(e) {
            deferred.reject(e.target.error)
          }
          reader.readAsDataURL(response.data)
          return deferred.promise
        })
        .catch(function(err) {
          console.error(err)
          return $q.reject(err)
        })
    }
  }
})()
