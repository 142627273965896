;(function() {
  'use strict'

  Controller.$inject = ["$element"];
  angular.module('app.core').component('gSwitch', {
    controller: Controller,
    templateUrl: 'g-switch.html',
    bindings: {
      active: '<',
      disabled: '<',
      onChange: '&',
    },
  })

  /* @ngInject */
  function Controller($element) {
    var ctrl = this

    $element.addClass('g-switch')

    ctrl.toggle = toggle

    function toggle() {
      if (ctrl.disabled) {
        return
      }
      ctrl.onChange({ $active: !ctrl.active })
    }
  }
})()
