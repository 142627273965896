;(function() {
  'use strict'

  Controller.$inject = ["$q", "glToast", "glAnalytics", "subscriberService", "userService", "jobRoleService", "industryService", "countryService", "imageService"];
  angular.module('app.core').component('accountSetup', {
    controller: Controller,
    templateUrl: 'account-setup.html',
    bindings: {
      onComplete: '&',
    },
  })

  /* @ngInject */
  function Controller(
    $q,
    glToast,
    glAnalytics,
    subscriberService,
    userService,
    jobRoleService,
    industryService,
    countryService,
    imageService
  ) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.back = back
    ctrl.next = next
    ctrl.setLogoImage = setLogoImage
    ctrl.noop = angular.noop

    function onInit() {
      ctrl.subscriber = subscriberService.getSubscriber()
      userService.getUserProfile().then(function(data) {
        ctrl.user = data
        ctrl.user.registrationContext = ctrl.user.registrationContext || {}
        setupFormData()
        setupPages()
        track('landed')
      })
    }

    function track(action, label) {
      glAnalytics.track('account-setup', action, label)
    }

    function setupFormData() {
      ctrl.JobRoles = jobRoleService.getAll()
      ctrl.profileData = {
        fullname: ctrl.user.fullname,
        phoneNumber: ctrl.subscriber.phoneNumber,
        jobRole: ctrl.user.jobRole || {},
      }

      ctrl.Industries = industryService.getAll()
      ctrl.Countries = _.map(countryService.getAll(), function(country) {
        country.recommended = _.includes(['AU', 'GB'], country.cc)
        return country
      })
      ctrl.Employees = [
        { label: '1-4 Employees', key: '1-4' },
        { label: '5-19 Employees', key: '5-19' },
        { label: '20-199 Employees', key: '20-199' },
        { label: '200+ Employees', key: '200+' },
      ]
      ctrl.BusinessTypes = [
        { label: 'Academic', key: 'ACADEMIC' },
        { label: 'Industry', key: 'INDUSTRY' },
        { label: 'Agency', key: 'AGENCY' },
      ]
      ctrl.companyData = {
        name: ctrl.subscriber.name,
        industry: ctrl.subscriber.industry || {},
        employees: {}, // TODO: Could this ever be prefilled?
        businessType: {},
        country: ctrl.subscriber.country || {},
      }
      if (ctrl.subscriber.businessType) {
        var type = _.find(ctrl.BusinessTypes, {
          key: ctrl.subscriber.businessType,
        })
        if (type) ctrl.companyData.businessType = _.clone(type)
      }

      ctrl.brandData = {
        logoImageUrl: ctrl.subscriber.portalTheme.logoImageUrl,
      }
    }

    function setupPages() {
      ctrl.pages = [
        {
          key: 'profile',
          title: 'Your Profile',
          isComplete: profileIsComplete,
          next: profileNext,
        },
        {
          key: 'company',
          title: 'Your Business Profile',
          isComplete: companyIsComplete,
          next: companyNext,
        },
        {
          key: 'brand',
          title: 'Your Brand',
          isComplete: brandIsComplete,
          next: brandNext,
        },
      ]

      ctrl.currentPageIndex = 0
      ctrl.currentPage = ctrl.pages[ctrl.currentPageIndex]
    }

    function back() {
      if (ctrl.currentPageIndex === 0) {
        return
      }
      ctrl.currentPage = ctrl.pages[--ctrl.currentPageIndex]
    }

    function next() {
      if (!ctrl.currentPage.isComplete()) {
        return // Enter key triggers this method
      }
      ctrl.footerSpinner = true
      ctrl.currentPage
        .next()
        .then(function() {
          if (++ctrl.currentPageIndex < ctrl.pages.length) {
            ctrl.currentPage = ctrl.pages[ctrl.currentPageIndex]
          } else {
            ctrl.onComplete()
            track('completed')
          }
        })
        .catch(function() {
          glToast.show('Could not save your details.')
        })
        .finally(function() {
          ctrl.footerSpinner = false
        })
    }

    function profileIsComplete() {
      return ctrl.profileForm.$valid && !_.isEmpty(ctrl.profileData.jobRole)
    }

    function companyIsComplete() {
      return (
        ctrl.companyForm.$valid &&
        !_.isEmpty(ctrl.companyData.industry) &&
        !_.isEmpty(ctrl.companyData.employees) &&
        !_.isEmpty(ctrl.companyData.country)
      )
    }

    function brandIsComplete() {
      // Branding is optional
      return true
    }

    function profileNext() {
      ctrl.user.fullname = ctrl.profileData.fullname
      ctrl.subscriber.phoneNumber = ctrl.profileData.phoneNumber
      ctrl.user.jobRole = jobRoleService.getById(ctrl.profileData.jobRole.id)
      return saveUser().then(saveSubscriber)
    }

    function companyNext() {
      ctrl.subscriber.name = ctrl.companyData.name
      ctrl.subscriber.businessType = ctrl.companyData.businessType.key
      ctrl.subscriber.industry = industryService.getById(
        ctrl.companyData.industry.id
      )
      ctrl.user.registrationContext.employees = ctrl.companyData.employees.key
      ctrl.subscriber.country = countryService.getById(
        ctrl.companyData.country.id
      )
      return saveUser().then(saveSubscriber)
    }

    function brandNext() {
      ctrl.subscriber.portalTheme.logoImageUrl = ctrl.brandData.logoImageUrl
      return saveSubscriber()
    }

    function setLogoImage(imageData) {
      if (!imageData) {
        return
      }

      ctrl.footerSpinner = true
      ctrl.brandData.logoImageData = imageData
      return imageService.create(imageData).then(function(imageUrl) {
        ctrl.brandData.logoImageUrl = imageUrl
        ctrl.footerSpinner = false
      })
    }

    function saveUser() {
      return userService.saveUserProfile(ctrl.user)
    }

    function saveSubscriber() {
      return subscriberService.saveSubscriber(ctrl.subscriber)
    }
  }
})()
