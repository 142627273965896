;(function() {
  'use strict'

  CommunityModuleController.$inject = ["$scope", "$log", "$q", "modulesService", "elementService"];
  angular
    .module('app.page-modules')
    .controller('CommunityModuleController', CommunityModuleController)

  /* @ngInject */
  function CommunityModuleController(
    $scope,
    $log,
    $q,
    modulesService,
    elementService
  ) {
    var vm = this
    var log = $log.create('CommunityModuleController')

    var defaults = {
      type: 'community',
      pageId: vm.pageId,
      data: {
        elementId: undefined,
      },
    }

    vm.schema = modulesService.getSchema('community')
    vm.module = vm.module ? _.cloneDeep(vm.module) : defaults

    vm.canRemove = canRemove

    vm.save = save
    vm.remove = remove
    vm.close = close

    activate()

    function activate() {
      if (!vm.pageId) {
        vm.error = 'Something went wrong. Please try again.'
        return log.error('community module settings requires pageId')
      }
    }

    function canRemove() {
      var hasID = !!vm.module.id

      return hasID
    }

    function save() {
      vm.saving = true
      vm.error = null

      if (!vm.module.data.elementId) {
        // Add the basic element before saving
        elementService
          .getSubscriberBasicElement()
          .then(function(basicElement) {
            vm.module.data.elementId = basicElement.id
            saveModule()
          })
          .catch(function(error) {
            vm.error = 'Could not save.'
            log.error(error)
            vm.saving = false
          })
      } else {
        saveModule()
      }

      function saveModule() {
        modulesService
          .save(vm.module)
          .then(function() {
            $scope.dialog.close({
              modify: true,
              module: vm.module,
            })
          })
          .catch(function(error) {
            vm.error = error
            log.error('error saving community module')
          })
          .finally(function() {
            vm.saving = false
          })
      }
    }

    function remove() {
      vm.saving = true
      vm.error = null

      modulesService
        .remove(vm.module.id)
        .then(function() {
          $scope.dialog.close({
            modify: true,
            module: undefined,
          })
        })
        .catch(function(error) {
          vm.error = error
          log.error('error removing community module')
        })
        .finally(function() {
          vm.saving = false
        })
    }

    function close() {
      $scope.dialog.close()
    }
  }
})()
