;(function() {
  'use strict'

  Controller.$inject = ["$q", "api"];
  angular.module('app.core').component('responseDeleteDialog', {
    controller: Controller,
    templateUrl: 'response-delete-dialog.html',
    bindings: {
      surveyId: '<',
      selectedIds: '<',
      onCancel: '&',
      onDone: '&',
    },
  })

  /* @ngInject */
  function Controller($q, api) {
    var ctrl = this

    var textReasons = ['OTHER']

    ctrl.$onInit = onInit
    ctrl.isTextField = isTextField
    ctrl.canSubmit = canSubmit
    ctrl.submit = submit

    ctrl.reasonOptions = [
      { value: 'TEST', label: 'Test Response' },
      { value: 'SUSPICIOUS', label: 'Suspicious' },
      { value: 'INCONSISTENT', label: 'Inconsistent' },
      { value: 'OTHER', label: 'Other' },
    ]

    function onInit() {
      ctrl.title = ctrl.selectedIds ? 'Delete Selected' : 'Batch Delete'
      ctrl.responseIds = ''
      ctrl.reason = null
      ctrl.text = ''
    }

    function isTextField() {
      return textReasons.includes(ctrl.reason)
    }

    function canSubmit() {
      if (ctrl.submitting) return false
      if (!ctrl.reason) return false
      var responseIds = getResponseIds()
      if (!responseIds.length) return false
      return true
    }

    function submit() {
      if (!canSubmit()) return
      var responseIds = getResponseIds()
      ctrl.error = false
      ctrl.invalidIds = null
      ctrl.submitting = true
      deleteResponseIds(responseIds)
        .then(function(invalidIds) {
          if (invalidIds.length) {
            if (!ctrl.selectedIds) ctrl.invalidIds = invalidIds
            ctrl.responseIds = invalidIds.join('\n')
            ctrl.error = true
            ctrl.submitting = false
          } else {
            ctrl.onDone()
          }
        })
        .catch(function(err) {
          console.error(err)
          ctrl.submitting = false
          ctrl.error = true
        })
    }

    function deleteResponseIds(responseIds) {
      return $q(function(resolve, reject) {
        // the backend can now handle deletions without batching in 5's
        // but we'll leave the code here so its easy to re-enable as needed
        var chunks = [responseIds] // _.chunk(responseIds, 5)
        var invalidIds = []
        function next() {
          var chunk = chunks.pop()
          api.surveys
            .batchDeleteResponses({
              responseIds: chunk,
              reason: ctrl.reason,
              text: getText(),
            })
            .then(function() {
              check()
            })
            .catch(function(resp) {
              // if the error response is an array it's a bunch of invalid id's
              // otherwise this is a genuine api error
              if (_.isArray(resp)) {
                invalidIds = invalidIds.concat(resp)
                check()
              } else {
                reject(resp)
              }
            })
        }
        function check() {
          if (chunks.length) {
            next()
          } else {
            resolve(invalidIds)
          }
        }
        next()
      })
    }

    function getResponseIds() {
      if (ctrl.selectedIds) {
        return ctrl.selectedIds
      }
      return ctrl.responseIds
        .split('\n')
        .map(function(value) {
          return value.trim()
        })
        .filter(function(value) {
          return !!value
        })
    }

    function getText() {
      var text
      if (isTextField()) text = ctrl.text
      return text || 'N/A'
    }
  }
})()
