;(function() {
  'use strict'

  angular
    .module('core.spinner')
    .directive('glSpinner', glSpinnerDirective)
    .directive('glSpinnerSize', glSpinnerSizeDirective)

  function glSpinnerDirective() {
    return {
      restrict: 'E',
      templateUrl: 'spinner.tpl.html',
      scope: {
        color: '@',
        size: '@',
      },
      link: link,
    }

    function link(scope) {
      scope.size = scope.size || 30
    }
  }

  function glSpinnerSizeDirective() {
    return function(scope, element, attrs) {
      attrs.$observe('glSpinnerSize', function(value) {
        attrs.$set('r', value)
      })
    }
  }
})()
