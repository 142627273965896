;(function() {
  'use strict'

  Controller.$inject = ["$state", "glDialog", "glAuthService", "userService"];
  angular.module('app.login').controller('LoginController', Controller)

  /* @ngInject */
  function Controller($state, glDialog, glAuthService, userService) {
    var ctrl = this

    ctrl.allowedRoles = [
      glAuthService.Roles.SUBSCRIBER,
      glAuthService.Roles.SUBSCRIBER_SUPPORT,
    ]

    ctrl.loginSuccess = userService.redirectToRequestedUrl
    ctrl.loginError = loginError
    ctrl.resetPassword = resetPassword

    function loginError(error) {
      glDialog.alert(null, error)
    }

    function resetPassword() {
      $state.go('passwordReset')
    }
  }
})()
