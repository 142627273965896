;(function() {
  'use strict'

  angular
    .module('glow.reporting')
    .component('glQuestionRankFilter', Component())

  function Component() {
    return {
      controller: Controller,
      templateUrl: 'rank-filter.template.html',
      bindings: {
        question: '=',
        survey: '=',
        filter: '=',
      },
    }
  }

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.toggle = toggle

    function onInit() {
      ctrl.ranks = [{ label: 'Any Rank' }]
      _.each(ctrl.question.ranks, function(rank) {
        ctrl.ranks.push({
          label: rank.label,
          value: rank.value,
        })
      })

      if (ctrl.filter.newAspect) {
        ctrl.filter.data.aspect = ctrl.filter.newAspect
        ctrl.filter.newAspect = undefined
      }

      if (ctrl.filter.newValue) {
        var choice = _.find(ctrl.question.choices, {
          option: ctrl.filter.newValue,
        })

        // Only add the choice if not already selected/added
        if (choice && !_.find(ctrl.filter.data.values, { id: choice.id })) {
          ctrl.filter.data.values.push({ id: choice.id })
        }
        ctrl.filter.newValue = undefined
      }

      _.each(ctrl.filter.data.values, function(choice) {
        var questionChoice = _.find(ctrl.question.choices, { id: choice.id })
        if (questionChoice) {
          questionChoice.isSelected = true
        }
      })
    }

    function toggle(choice) {
      if (choice.isSelected) {
        ctrl.filter.data.values.push({ id: choice.id })
      } else {
        _.remove(ctrl.filter.data.values, { id: choice.id })
      }
    }
  }
})()
