;(function() {
  'use strict'

  Controller.$inject = ["glAnalytics", "publicationService"];
  angular.module('app.core').component('publicationRatingForm', {
    controller: Controller,
    templateUrl: 'publication-rating-form.html',
    bindings: {
      publication: '<',
      stars: '<',
      onSubmit: '&',
    },
  })

  /* @ngInject */
  function Controller(glAnalytics, publicationService) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.submit = submit

    function onInit() {
      track('rating-intent')
    }

    function submit() {
      ctrl.loading = true
      publicationService
        .trackMetric(ctrl.publication, publicationService.Metrics.RATE, {
          stars: ctrl.stars,
          data: {
            reason: ctrl.reason,
          },
        })
        .then(function() {
          track('rating-submitted')
          ctrl.onSubmit()
        })
    }

    function track(action) {
      var labels = {
        publication: ctrl.publication.id,
      }
      var value = ctrl.stars
      glAnalytics.track('publication', action, labels, value)
    }
  }
})()
