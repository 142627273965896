;(function() {
  'use strict'

  Controller.$inject = ["plansService", "currencyService", "helpService", "subscriberService"];
  angular.module('app.core').component('accountResponsePricing', {
    templateUrl: 'account-response-pricing.html',
    controller: Controller,
    bindings: {
      showCaption: '<',
      currency: '<',
    },
  })

  /* @ngInject */
  function Controller(
    plansService,
    currencyService,
    helpService,
    subscriberService
  ) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.getPanelPrice = getPanelPrice
    ctrl.help = helpService.chat

    function onInit() {
      ctrl.subscriber = subscriberService.getSubscriber()
      ctrl.pricing = ctrl.subscriber.plan.pricing
      // ctrl.pricing = plansService.getMRP()
      ctrl.currencies = currencyService.getAll().map(function(currency) {
        return {
          label: currency.code,
          value: currency.code,
        }
      })

      if (!ctrl.currency) {
        var subscriber = subscriberService.getSubscriber()
        if (subscriber) {
          ctrl.currency = subscriber.billing.currency
        } else {
          ctrl.currency = _.find(ctrl.currencies, { value: 'AUD' })
            ? 'AUD'
            : ctrl.currencies[0].value
        }
      }
    }

    function getPanelPrice(response) {
      var base = ctrl.pricing.base.byCurrency(ctrl.currency)
      var panel = response.price.byCurrency(ctrl.currency)
      var cost = base.clone()
      cost.amount += panel.amount
      return cost.format()
    }
  }
})()
