;(function() {
  'use strict'

  Controller.$inject = ["surveyReport", "subscriberService", "userService"];
  angular.module('app.core').component('exportResponsesDialog', {
    controller: Controller,
    templateUrl: 'export-responses-dialog.html',
    bindings: {
      survey: '<',
      onClose: '&',
    },
  })

  /* @ngInject */
  function Controller(surveyReport, subscriberService, userService) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.exportResponses = exportResponses

    function onInit() {
      ctrl.subscriber = subscriberService.getSubscriber()
      ctrl.isSSR = userService.isSSR()
      ctrl.exportFormatOptions = [
        {
          label: 'CSV',
          value: 'qcsv',
        },
        {
          label: 'XLSX',
          value: 'qxlsx',
        },
        {
          label: 'SPSS Compatible xlsx',
          value: 'qspss',
        },
      ]
      ctrl.responseTypeOptions = [
        {
          label: 'All',
          value: null,
        },
        {
          label: 'Completes',
          value: 'COMPLETE',
        },
        {
          label: 'Exits',
          value: 'EXIT',
        },
        {
          label: 'Overquota',
          value: 'OVERQUOTA',
        },
      ]
      // pre select export format and response type
      ctrl.exportFormat = 'qcsv'
      ctrl.responseType = 'COMPLETE'
    }

    function exportResponses() {
      var options = {
        surveyId: ctrl.survey.id,
        responseType: ctrl.responseType,
      }
      if (ctrl.exportFormat === 'qxlsx') {
        options.exportFormat = 'xlsx'
      }
      if (ctrl.exportFormat === 'qspss') {
        options.exportFormat = 'spss'
      }
      // IMPORTANT: SPSS doesn't support durations so we should always set it to false
      options.withDuration =
        options.exportFormat !== 'spss' &&
        ctrl.isSSR &&
        ctrl.subscriber.data.withDurationExport
      surveyReport.createExport(options)
      ctrl.onClose()
    }
  }
})()
