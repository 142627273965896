;(function() {
  'use strict'

  Controller.$inject = ["$q", "userService", "subscriberService", "beaconService", "placeService", "productService"];
  angular.module('app.core').component('beaconManager', {
    controller: Controller,
    templateUrl: 'beacon-manager.html',
    bindings: {},
  })

  /* @ngInject */
  function Controller(
    $q,
    userService,
    subscriberService,
    beaconService,
    placeService,
    productService
  ) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.create = create
    ctrl.remove = remove
    ctrl.orderBy = orderBy
    ctrl.applyFilter = applyFilter

    function onInit() {
      ctrl.isSSR = userService.isSSR()
      ctrl.ownerId = subscriberService.getSubscriber().id
      ctrl.loading = true
      $q.all([loadBeacons(), loadPlaces(), loadProducts()])
        .then(function() {
          applyFilter()
        })
        .catch(function(error) {
          ctrl.error = error
        })
        .finally(function() {
          ctrl.loading = false
        })
    }

    function loadBeacons() {
      return beaconService.list(ctrl.ownerId).then(function(beacons) {
        ctrl.beacons = beacons
      })
    }

    function loadData(service) {
      return $q(function(resolve, reject) {
        service.list(ctrl.ownerId, function(items, loading, error) {
          if (loading) return
          if (error) return reject(error)
          var options = _.map(items, function(item) {
            return {
              label: item.name,
              value: item.id,
            }
          })
          resolve(options)
        })
      })
    }

    function loadPlaces() {
      return loadData(placeService).then(function(options) {
        ctrl.placeOptions = options
      })
    }

    function loadProducts() {
      return loadData(productService).then(function(options) {
        ctrl.productOptions = options
      })
    }

    function create() {
      clearFilter()
      var existing = _.find(ctrl.beacons, function(beacon) {
        return beacon.isNew()
      })
      if (existing) {
        return
      }
      var beacon = beaconService.create({
        ownerId: ctrl.ownerId,
        mode: 'edit',
      })
      ctrl.beacons.unshift(beacon)
    }

    function remove(beacon) {
      beaconService.remove(beacon).then(function() {
        _.remove(ctrl.beacons, beacon)
        applyFilter()
      })
    }

    function orderBy(beacon) {
      return beacon.createdAt.unix()
    }

    function applyFilter() {
      if (!ctrl.query) {
        ctrl.filteredBeacons = ctrl.beacons
        return
      }
      ctrl.filteredBeacons = _.filter(ctrl.beacons, function(beacon) {
        var id = ctrl.query.length === 36 ? beacon.id : ''
        var tokens = [
          id,
          beacon.slug,
          beacon.type,
          beacon.title,
          beacon.description,
        ]
          .join(' ')
          .toLowerCase()
        var visible = _.includes(tokens, ctrl.query.toLowerCase())
        return visible
      })
    }

    function clearFilter() {
      ctrl.query = null
      applyFilter()
    }
  }
})()
