;(function() {
  'use strict'

  SurveyInteractionController.$inject = ["$scope", "$q", "interactionsService", "userService", "glDialog", "Pipes", "clipboardService"];
  angular
    .module('core.interaction')
    .controller('SurveyInteraction', SurveyInteractionController)

  /* @ngInject */
  function SurveyInteractionController(
    $scope,
    $q,
    interactionsService,
    userService,
    glDialog,
    Pipes,
    clipboardService
  ) {
    var moodInfo = [
      { value: '1', label: 'Very Angry' },
      { value: '2', label: 'Angry' },
      { value: '3', label: 'Meh' },
      { value: '4', label: 'Neutral' },
      { value: '5', label: 'Happy' },
      { value: '6', label: 'Very Happy' },
    ]

    $scope.getDisplayName = getDisplayName
    $scope.getEmail = getEmail
    $scope.interactionIcon = interactionIcon
    $scope.actionInteraction = actionInteraction
    $scope.commentCircleText = commentCircleText
    $scope.commentCreatedAt = commentCreatedAt
    $scope.flagFeedback = flagFeedback
    $scope.flagComment = flagComment
    $scope.isCustomData = isCustomData
    $scope.copyToClipboard = clipboardService.copy

    activate()

    /**
     * Initialise
     */
    function activate() {
      getInteraction()
        .then(function(interaction) {
          $scope.interaction = interaction

          if ($scope.survey) {
            $scope.interaction.answers = _.orderBy(
              $scope.interaction.answers,
              function(answer) {
                var question = _.find($scope.survey.questions, {
                  id: answer.question.id,
                })
                return question.order
              }
            )
          }

          // extract questions for survey-pipe compiling
          $scope.questions = _.map(interaction.answers, function(answer) {
            return answer.question
          })
          $scope.responses = interaction.answers

          function getAnswer(questionNum) {
            var idx = questionNum - 1 // index is all we have, it can break sometimes
            var question = $scope.questions[idx]
            var response = $scope.responses[idx]
            var answer
            // interaction answer extractor! (TM)
            switch (question.type) {
              case 'choice':
                answer = getChoiceAnswer(response)
                break
              case 'singletext':
              case 'text':
                answer = response.textAnswer
                  ? response.textAnswer.replace(/\|/g, ', ')
                  : ''
                break
              case 'scan':
              case 'nps':
                answer = response.textAnswer
                break
              case 'mood':
                answer = getMoodLabel(response.textAnswer)
                break
              case 'placesnearme':
                if (response.placesAnswers.length) {
                  answer =
                    response.placesAnswers[0].name ||
                    response.placesAnswers[0].title
                }
                break
              case 'constantsum':
                if (response.statementAnswers.length) {
                  var statements = {}
                  _.each(response.statementAnswers, function(statement) {
                    if (!statements[statement.statement]) {
                      statements[statement.statement] = []
                    }
                    var text = statement.option + '(' + statement.number + ')'
                    statements[statement.statement].push(text)
                  })
                  answer = _.map(statements, function(texts, statement) {
                    return statement + ': [' + texts.join(', ') + ']'
                  }).join(', ')
                  break
                }
                answer = _.map(response.choiceAnswers, function(choice) {
                  return choice.option + '(' + choice.number + ')'
                }).join(', ')
                break
              case 'matrix':
                answer = getMatrixAnswer(response)
                break
              case 'score':
                if (response.statementAnswers.length) {
                  answer = getMatrixAnswer(response)
                } else {
                  answer = getChoiceAnswer(response)
                }
                break
            }
            return answer
          }

          function getChoiceAnswer(response) {
            var choice = response.choiceAnswers[0]
            return choice ? choice.option : ''
          }

          function getMatrixAnswer(response) {
            var statements = {}
            _.each(response.statementAnswers, function(statement) {
              if (!statements[statement.statement]) {
                statements[statement.statement] = []
              }
              statements[statement.statement].push(statement.option)
            })
            return _.map(statements, function(texts, statement) {
              return statement + ': [' + texts.join(', ') + ']'
            }).join(', ')
          }

          function getDimension(type, name) {
            return '<unknown>'
          }

          function getParam(name) {
            return '<unknown>'
          }

          function getList(list) {
            return null
          }

          function getTag(tag) {
            return false
          }

          function getVariable(key) {
            return null
          }

          _.each($scope.interaction.answers, function(answer) {
            var pipes = new Pipes(answer.question.title)
            answer.question.titleInjected = pipes.injectValues(
              getAnswer,
              getDimension,
              getParam,
              getList,
              getTag,
              getVariable
            )
          })

          actionInteraction($scope.interaction)
          generateDescription($scope.interaction)
          $scope.state = 'ready'
        })
        .catch(function() {
          $scope.state = 'error'
        })
    }

    function getMoodLabel(value) {
      var info = _.find(moodInfo, { value: value })
      return info && info.label
    }

    /**
     * @name getDisplayName
     * @description get name to display
     * @return {String} the display name
     */
    function getDisplayName() {
      if ($scope.interaction.requestedBy) {
        return $scope.interaction.requestedBy.fullname
      } else {
        // Older interactions don't have requestedBy
        return $scope.interaction.username
      }
    }

    /**
     * @name getEmail
     * @description get the requestor's email
     * @return {String} the email
     */
    function getEmail() {
      if ($scope.interaction.requestedBy) {
        return $scope.interaction.requestedBy.email
      } else {
        // Older interactions don't have requestedBy
        return $scope.interaction.answers[$scope.interaction.answers.length - 1]
          .textAnswer
      }
    }

    /**
     * @name getInteraction
     * @description get interaction by related survey response ID
     * @return {Promise}
     */
    function getInteraction() {
      // check interaction has an id - older survey responses will not so just show existing interaction
      // as these will not have any comment thread attached anyway
      if ($scope.interaction.id) {
        if ($scope.byRelated) {
          return interactionsService.getByRelated($scope.interaction.id)
        } else {
          return interactionsService.get($scope.interaction.id)
        }
      } else {
        return $q.when($scope.interaction)
      }
    }

    /**
     * @name interactionIcon
     * @description get interaction icon class(s)
     * @param  {Object} interaction interaction to generate icon class(s) for
     * @return {String}             icon class(s)
     */
    function interactionIcon(interaction) {
      var iconTypes = {
        feedback: 'gi-chat blue',
        idea: 'gi-idea blue',
        complaint: 'gi-complaint blue',
        praise: 'gi-praise blue',
        suggestion: 'gi-suggestion blue',
        request: 'gi-request blue',
        question: 'gi-question blue',
        'survey-response': 'gi-survey green',
        followup: 'gi-communicate orange',
      }

      return iconTypes[interaction.type] || 'gi-chat blue'
    }

    /**
     * @name actionInteraction
     * @description set interaction as actioned
     * @param  {Object} interaction interaction to action
     */
    function actionInteraction(interaction) {
      if (!interaction.actionedBy && $scope.doAction) {
        interactionsService.markAsActioned(interaction)
        interaction.actionedBy = userService.getUser().id
      }
    }

    /**
     * @name generateDescription
     * @description generate description for the interaction dialog
     * @param  {Object} interaction interaction object
     */
    function generateDescription(interaction) {
      if (interaction.type === 'followup') {
        // followup request
        interaction.description =
          'has requested a follow up from ' +
          interaction.elementName.toUpperCase() +
          '\n' +
          'in response to the ' +
          interaction.surveyTitle.toUpperCase() +
          ' survey'
      } else {
        // standard survey response
        interaction.description =
          'A user has submitted a survey response to ' +
          interaction.surveyTitle.toUpperCase()
      }
    }

    /**
     * @name  commentCircleText
     * @description get first two letters of commentors username to use in comment icon
     * @param  {Object} comment comment to generate comment icon text for
     * @return {String}         two letter string for use in icon
     */
    function commentCircleText(comment) {
      if (comment.creator && !comment.creator.hack) {
        return (
          comment.creator.name[0].toUpperCase() +
          comment.creator.name[1].toLowerCase()
        )
      } else {
        // Fix for old interactions that do not have a creator key on comments
        // TOOD remove once migration complete
        comment.creator = {
          hack: true,
        }
        switch (comment.type) {
          case 'u':
            comment.creator.name = 'Glow user'
            return 'U'
          case 's':
            comment.creator.name = 'me'
            return 'S'
          case 'c':
            comment.creator.name = 'Glow user'
            return 'C'
          case 'a':
            comment.creator.name = 'Admin user'
            return 'A'
        }
      }
    }

    /**
     * @name commentCreatedAt
     * @description get comment created at date - account for old interactions with date object
     * @param  {Object} comment comment object
     * @return {String}         comment created at date
     */
    function commentCreatedAt(comment) {
      return _.isObject(comment.createdAt)
        ? comment.createdAt.date
        : comment.createdAt
    }

    /**
     * @name flagFeedback
     * @description flag some feedback
     * @param  {Object} $event  click event
     * @param  {Object} interaction inetraction to flag
     */
    function flagFeedback($event, interaction) {
      if (
        interaction.userFlagged === true ||
        interaction.userFlagged === false
      ) {
        // still waiting on previous flag request to complete
        return
      }
      if (interaction.userFlagged) {
        doFlagFeedback()
      } else {
        glDialog
          .confirm('', 'Flag this feedback as inappropriate?', {
            targetEvent: $event,
          })
          .then(doFlagFeedback)
      }

      function doFlagFeedback() {
        if (!interaction.userFlagged) {
          // flag
          interaction.userFlagged = true

          interactionsService.flagInteraction(interaction.id).then(
            function(edgeId) {
              interaction.userFlagged = edgeId
            },
            function() {
              delete interaction.userFlagged
            }
          )
        } else {
          // unflag
          var edgeId = _.clone(interaction.userFlagged)
          interaction.userFlagged = false

          interactionsService.unflagInteraction(edgeId).then(function() {
            delete interaction.userFlagged
          })
        }
      }
    }

    /**
     * @name flagComment
     * @description flag a comment
     * @param  {Object} $event  click event
     * @param  {Object} comment comment to flag
     */
    function flagComment($event, comment) {
      if (comment.userFlagged === true || comment.userFlagged === false) {
        // still waiting on previous flag request to complete
        return
      }
      if (comment.userFlagged) {
        doFlagComment()
      } else {
        glDialog
          .confirm('', 'Flag this comment as inappropriate?', {
            targetEvent: $event,
          })
          .then(doFlagComment)
      }

      function doFlagComment() {
        if (!comment.userFlagged) {
          // flag
          comment.userFlagged = true

          interactionsService.flagComment(comment.id).then(
            function(edgeId) {
              comment.userFlagged = edgeId
            },
            function() {
              delete comment.userFlagged
            }
          )
        } else {
          // unflag
          var edgeId = _.clone(comment.userFlagged)
          comment.userFlagged = false

          interactionsService.unflagComment(edgeId).then(function() {
            delete comment.userFlagged
          })
        }
      }
    }

    function isCustomData() {
      return !_.isEmpty($scope.interaction.customData)
    }
  }
})()
