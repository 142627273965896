;(function() {
  'use strict'

  config.$inject = ["$stateProvider"];
  angular.module('app.products', []).config(config)

  /* @ngInject */
  function config($stateProvider) {
    $stateProvider.state('products', {
      url: '/products',
      parent: 'portal',
      controller: 'Products',
      controllerAs: '$ctrl',
      templateUrl: 'products.html',
      title: 'Products',
    })
  }
})()
