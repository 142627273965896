;(function() {
  'use strict'

  Controller.$inject = ["$q", "$state", "StateFactory", "glAnalytics", "userService", "subscriberService", "kitsService", "surveyTemplateService", "surveyTemplateDialog"];
  angular.module('app.kit').controller('Kit', Controller)

  /* @ngInject */
  function Controller(
    $q,
    $state,
    StateFactory,
    glAnalytics,
    userService,
    subscriberService,
    kitsService,
    surveyTemplateService,
    surveyTemplateDialog
  ) {
    var ctrl = this

    ctrl.onTemplateFlip = onTemplateFlip
    ctrl.select = select
    ctrl.preview = surveyTemplateService.preview

    init()

    function init() {
      ctrl.id = $state.params.id
      ctrl.isLoggedIn = userService.isLoggedIn()
      ctrl.state = new StateFactory(['loading', 'loaded', 'error'])
      ctrl.state.loading()
      loadKit()
        .then(loadTemplates)
        .then(function() {
          glAnalytics.track('surveys', 'create-from-kit-opened', ctrl.kit.name)
          ctrl.state.loaded()
        })
        .catch(function() {
          ctrl.state.error()
        })
    }

    function loadKit() {
      if (!ctrl.isLoggedIn) {
        return kitsService.getById(ctrl.id).then(function(kit) {
          ctrl.kit = kit
        })
      } else {
        var subscriber = subscriberService.getSubscriber()
        return kitsService.getAssignedKits(subscriber.id).then(function(kits) {
          ctrl.kit = _.find(kits, { id: ctrl.id })
          if (!ctrl.kit) {
            return $q.reject()
          }
        })
      }
    }

    function loadTemplates() {
      return surveyTemplateService
        .getByKit(ctrl.kit.id)
        .then(function(templates) {
          ctrl.kit.templates = templates
        })
    }

    function onTemplateFlip(template) {
      glAnalytics.track(
        'surveys',
        'create-from-kit-survey-flipped',
        template.title
      )
    }

    function select(template) {
      surveyTemplateDialog.show({ template: template })
    }
  }
})()
