;(function() {
  'use strict'

  Controller.$inject = ["$timeout"];
  angular.module('glow.reporting').component('reportLoadingSpinner', {
    controller: Controller,
    templateUrl: 'report-loading-spinner.html',
    bindings: {
      overlay: '<',
    },
  })

  /* @ngInject */
  function Controller($timeout) {
    var ctrl = this
    var loadingTextTimer

    ctrl.$onInit = onInit
    ctrl.$onDestroy = onDestroy

    function onInit() {
      ctrl.loadingText = 'Loading Analysis…'

      loadingTextTimer = $timeout(function() {
        ctrl.loadingText +=
          "\nThere's a lot of data in here, this could take a few minutes. \nOnce you stop collecting new data, analysis will start to magically become a lot faster each time you use it."
      }, 10000) // 10 seconds
    }

    function onDestroy() {
      $timeout.cancel(loadingTextTimer)
    }
  }
})()
