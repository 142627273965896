;(function() {
  'use strict'

  Controller.$inject = ["glUtils", "userService"];
  angular.module('glow.reporting').component('surveyReportCounts', {
    controller: Controller,
    templateUrl: 'survey-report-counts.html',
    bindings: {
      amount: '<',
      total: '<',
      deleted: '<',
      hidePrefix: '<',
    },
  })

  /* @njInject */
  function Controller(glUtils, userService) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.$onChanges = onChanges

    function onInit() {
      ctrl.isSSR = userService.isSSR()
    }

    function onChanges(changes) {
      if (changes.deleted) {
        ctrl.deletedText =
          ctrl.deleted > 0
            ? glUtils.numberSwitch(
                ctrl.deleted,
                [ctrl.deleted, 'response deleted'].join(' '),
                [ctrl.deleted, 'responses deleted'].join(' ')
              )
            : ''
      }
    }
  }
})()
