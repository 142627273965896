;(function() {
  'use strict'

  angular.module('core.kitGrid').component('kitGrid', {
    controller: Controller,
    templateUrl: 'kit-grid.html',
    bindings: {
      kits: '<',
      onSelect: '&',
    },
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit

    function onInit() {
      // ...
    }
  }
})()
