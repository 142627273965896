;(function() {
  'use strict'

  angular.module('app.core').component('gTabs', {
    controller: Controller,
    templateUrl: 'g-tabs.html',
    bindings: {
      tabs: '<',
      selected: '<',
      onChange: '&',
      mini: '<',
      edge: '<',
      borderless: '<',
    },
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.select = select

    function onInit() {
      if (!ctrl.selected) {
        select(ctrl.tabs[0])
      }
    }

    function select(tab) {
      if (ctrl.selected === tab) {
        return
      }
      if (tab.enabled && !tab.enabled()) {
        return
      }
      var data = _.find(ctrl.tabs, { value: ctrl.selected })
      if (data && data.resolveOnLeave) {
        return data.resolveOnLeave().then(function() {
          selectFinalize(tab)
        })
      } else {
        selectFinalize(tab)
      }
    }

    function selectFinalize(tab) {
      ctrl.selected = tab.value
      ctrl.onChange({ $value: tab.value, $tab: tab })
    }
  }
})()
