;(function() {
  'use strict'

  Controller.$inject = ["filterSetService", "responseFilterService", "glToast"];
  angular.module('app.core').component('filterSetDialog', {
    controller: Controller,
    templateUrl: 'filter-set-dialog.html',
    bindings: {
      filterSet: '<',
      report: '<',
      onSave: '&',
      onCancel: '&',
    },
  })

  /* @ngInject */
  function Controller(filterSetService, responseFilterService, glToast) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.setModified = setModified
    ctrl.save = save
    ctrl.cancel = cancel

    function onInit() {
      ctrl.channels = ctrl.report.channels
      ctrl.dimensions = responseFilterService.extractDimensionsFromReport(
        ctrl.report
      )
      ctrl.isReadOnly =
        ctrl.filterSet.isReadOnly || !ctrl.report.options.canEditFilters
      ctrl.visibilityOptions = [
        {
          label: 'Public (Shared Reports/Dynamic Reports)',
          value: false,
        },
        {
          label: 'Private (Glow Account Holders)',
          value: true,
        },
      ]
      ctrl.dialogTitle =
        (ctrl.isReadOnly ? 'View' : ctrl.filterSet.isNew() ? 'Add' : 'Edit') +
        ' Filter'
      ctrl.tabs = [
        { label: 'Filter', value: 'filter' },
        { label: 'Charts', value: 'charts' },
      ]
      if (ctrl.filterSet.canEditVisibility()) {
        ctrl.tabs.push({
          label: 'Visibility',
          value: 'visibility',
        })
      }
    }

    function setModified() {
      ctrl.filterSet.modified = true
    }

    function save() {
      if (ctrl.filterSet.isSaving) return
      if (!ctrl.filterSet.validate()) {
        glToast.show('Please complete the filter before saving.')
        return
      }

      filterSetService
        .save(ctrl.filterSet)
        .then(function() {
          ctrl.onSave()
        })
        .catch(function() {
          glToast.show('Error saving filter.')
        })
    }

    function cancel() {
      if (ctrl.filterSet.isSaving) return
      ctrl.onCancel()
    }
  }
})()
