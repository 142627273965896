;(function() {
  'use strict'

  angular
    .module('glow.reporting')
    .component('surveyExplorerExcludedViewsDialog', {
      controller: Controller,
      templateUrl: 'survey-explorer-excluded-views-dialog.html',
      bindings: {
        excludedViews: '<',
        onRestore: '&',
        onClose: '&',
      },
    })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.restore = restore
    ctrl.close = close

    function onInit() {
      // ...
    }

    function restore(item) {
      ctrl.onRestore({ $item: item })
    }

    function close() {
      ctrl.onClose()
    }
  }
})()
