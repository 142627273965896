;(function() {
  'use strict'

  Controller.$inject = ["glPrefs", "glToast", "StateFactory", "opportunityService", "userService", "subscriberService"];
  angular
    .module('core.registerInterest')
    .component('registerInterest', Component())

  function Component() {
    return {
      controller: Controller,
      templateUrl: 'register-interest.html',
      bindings: {
        context: '@',
      },
    }
  }

  /* @ngInject */
  function Controller(
    glPrefs,
    glToast,
    StateFactory,
    opportunityService,
    userService,
    subscriberService
  ) {
    var ctrl = this

    var prefs
    var prefKey
    var prefOptions
    var user
    var subscriber

    ctrl.registerInterest = registerInterest

    ctrl.$onInit = onInit

    function onInit() {
      ctrl.state = new StateFactory(['loading', 'ready'])
      user = userService.getUser()
      subscriber = subscriberService.getSubscriber()

      // Remove spaces for the namespace
      prefs = glPrefs.create(_.camelCase(ctrl.context))
      prefKey = 'registeredInterest'
      prefOptions = { user: true, expiry: '72 hours' }
      ctrl.hasRegistered = prefs.get(prefKey, prefOptions)

      ctrl.state.ready()
    }

    function registerInterest() {
      var interest = {
        businessName: subscriber.name + ' subscriberId: ' + subscriber.id,
        businessAddress: 'Interested in ' + ctrl.context,
        email: user.email,
        phoneNumber: subscriber.phoneNumber || undefined,
        firstName: user.fullname,
        lastName: 'userId: ' + user.id,
      }

      doRegisterInterest()

      function doRegisterInterest() {
        ctrl.state.loading()
        opportunityService
          .send(interest)
          .then(function() {
            ctrl.hasRegistered = true
            prefs.set(prefKey, ctrl.hasRegistered, prefOptions)
          })
          .catch(function() {
            glToast.action(
              'Could not register interest.',
              'retry',
              doRegisterInterest
            )
            console.error('Could not register interest')
          })
          .finally(function() {
            ctrl.state.ready()
          })
      }
    }
  }
})()
