;(function() {
  'use strict'

  Controller.$inject = ["$scope", "Question", "FilterSet", "ResponseFilterRule", "glToast", "surveyReport", "crosstabService", "responseTypeService", "uacService"];
  angular.module('glow.reporting').component('reportCrosstab', {
    controller: Controller,
    templateUrl: 'report-crosstab.html',
    bindings: {
      survey: '<',
      reportOptions: '<',
      filterSetGroup: '<',
      crosstab: '<',
      absolute: '<',
      canMoveUp: '<',
      canMoveDown: '<',
      onDuplicate: '&',
      onRemove: '&',
      onAddBulk: '&',
      onMoveUp: '&',
      onMoveDown: '&',
    },
  })

  /* @ngInject */
  function Controller(
    $scope,
    Question,
    FilterSet,
    ResponseFilterRule,
    glToast,
    surveyReport,
    crosstabService,
    responseTypeService,
    uacService
  ) {
    var ctrl = this
    var SupportedPrimaryQuestionTypes = [
      Question.Types.CHOICE,
      Question.Types.SCALE,
      Question.Types.NPS,
      Question.Types.MOOD,
      Question.Types.RATING,
      Question.Types.RANK,
      Question.Types.MATRIX,
      Question.Types.SCORE,
      Question.Types.HIDDEN_VARIABLES,
    ]
    var SupportedSecondaryQuestionTypes = [
      Question.Types.CHOICE,
      Question.Types.SCALE,
      Question.Types.NPS,
      Question.Types.MOOD,
      Question.Types.RATING,
      Question.Types.SCORE,
      Question.Types.HIDDEN_VARIABLES,
    ]
    var cache = {
      key: null,
    }

    ctrl.$onInit = $onInit
    ctrl.setBulk = setBulk
    ctrl.setPrimaryQuestionId = setPrimaryQuestionId
    ctrl.setPrimaryQuestionIds = setPrimaryQuestionIds
    ctrl.setSecondaryQuestionIds = setSecondaryQuestionIds
    ctrl.toggleExpanded = toggleExpanded
    ctrl.canModify = canModify
    ctrl.exportCrosstab = exportCrosstab
    ctrl.edit = edit
    ctrl.validate = validate
    ctrl.save = save
    ctrl.cancel = cancel
    ctrl.generate = generate
    ctrl.remove = remove
    ctrl.formatMultiOptions = formatMultiOptions
    ctrl.getCached = getCached
    ctrl.editPrimaryLabel = editPrimaryLabel
    ctrl.editSecondaryLabel = editSecondaryLabel
    ctrl.editSecondaryItemLabel = editSecondaryItemLabel
    ctrl.editPrimaryAspectLabel = editPrimaryAspectLabel
    ctrl.editPrimaryItemLabel = editPrimaryItemLabel
    ctrl.hasOldData = hasOldData

    ctrl.hasPrimaryAspect = crosstabService.hasPrimaryAspect
    ctrl.hasPrimaryScore = crosstabService.hasPrimaryScore
    ctrl.getPrimaryLabel = crosstabService.getPrimaryLabel
    ctrl.getCrossFilterSetLabel = crosstabService.getCrossFilterSetLabel
    ctrl.getCrossFilterSetCount = crosstabService.getCrossFilterSetCount
    ctrl.getFilterSetTotal = crosstabService.getFilterSetTotal
    ctrl.getFilterSetScore = crosstabService.getFilterSetScore
    ctrl.getSecondaryLabel = crosstabService.getSecondaryLabel
    ctrl.getSecondaryItems = crosstabService.getSecondaryItems
    ctrl.getCombinedSecondaryItems = crosstabService.getCombinedSecondaryItems
    ctrl.getPrimaryItems = crosstabService.getPrimaryItems
    ctrl.getPrimaryItemCount = crosstabService.getPrimaryItemCount
    ctrl.getPrimaryTotal = crosstabService.getPrimaryTotal
    ctrl.getPrimaryScore = crosstabService.getPrimaryScore
    ctrl.getCrossCount = crosstabService.getCrossCount
    ctrl.getSecondaryItemTotal = crosstabService.getSecondaryItemTotal
    ctrl.getSecondaryItemScore = crosstabService.getSecondaryItemScore
    ctrl.getCountDisplay = crosstabService.getCountDisplay
    ctrl.shouldAddScoreRow = crosstabService.shouldAddScoreRow

    function $onInit() {
      function viewToOption(view) {
        return {
          label: view.value.getTitleLabel({ number: true }),
          short: 'Q' + view.value.getNumber(),
          value: view.value.id,
        }
      }
      ctrl.allPrimaryQuestionOptions = ctrl.survey.views
        .filter(function(view) {
          return (
            view.isQuestion() &&
            view.value.isType(SupportedPrimaryQuestionTypes)
          )
        })
        .map(viewToOption)
      ctrl.allSecondaryQuestionOptions = ctrl.survey.views
        .filter(function(view) {
          return (
            view.isQuestion() &&
            view.value.isType(SupportedSecondaryQuestionTypes) &&
            !view.value.isUsedAsMatrix()
          )
        })
        .map(viewToOption)

      if (ctrl.crosstab.isNew()) {
        edit()
      }
    }

    function setBulk(bulk) {
      if (ctrl.isBulk === bulk) return
      ctrl.isBulk = bulk
      // reset primary question(s)
      ctrl.crosstab.primaryQuestionId = null
      ctrl.bulkPrimaryQuestionIds = []
      updateOptions()
    }

    function setPrimaryQuestionId(questionId) {
      ctrl.crosstab.primaryQuestionId = questionId
      updateOptions()
    }

    function setPrimaryQuestionIds(questionIds) {
      ctrl.bulkPrimaryQuestionIds = questionIds
      updateOptions()
    }

    function setSecondaryQuestionIds(questionIds) {
      ctrl.crosstab.secondaryQuestionIds = questionIds
      updateOptions()
    }

    function updateOptions() {
      ctrl.secondaryOptions = ctrl.allSecondaryQuestionOptions.filter(function(
        option
      ) {
        if (ctrl.isBulk) {
          return !_.includes(ctrl.bulkPrimaryQuestionIds, option.value)
        } else {
          return option.value !== ctrl.crosstab.primaryQuestionId
        }
      })
      ctrl.primaryOptions = ctrl.allPrimaryQuestionOptions.filter(function(
        option
      ) {
        return !_.includes(ctrl.crosstab.secondaryQuestionIds, option.value)
      })
    }

    function updateFilterSetOptions() {
      ctrl.filterSetOptions = ctrl.filterSetGroup.filterSets.map(function(
        filterSet
      ) {
        return {
          // no id means the "All Responses" filter set, we just rename it here.
          label: filterSet.id ? filterSet.label : 'None',
          value: filterSet.id,
        }
      })
      ctrl.crossFilterSetOptions = ctrl.filterSetOptions.filter(function(
        filterSetOption
      ) {
        return !!filterSetOption.value
      })
    }

    function toggleExpanded() {
      ctrl.crosstab.isExpanded = !ctrl.crosstab.isExpanded
      if (ctrl.crosstab.isExpanded && !ctrl.crosstab.isGenerated()) {
        generate()
      }
    }

    function canModify() {
      return ctrl.reportOptions.canEditCrosstabs
    }

    function exportCrosstab() {
      if (!ctrl.reportOptions.canExportReport) {
        uacService.showAlertDialog('export crosstab CSV')
        return
      }
      crosstabService.exportCSV(
        ctrl.crosstab,
        ctrl.survey,
        ctrl.filterSetGroup,
        ctrl.absolute
      )
    }

    function edit() {
      ctrl.crosstab.isEditing = true
      ctrl.crosstab.isExpanded = true
      updateOptions()
      updateFilterSetOptions()
    }

    function validate() {
      if (ctrl.isBulk) {
        if (!ctrl.bulkPrimaryQuestionIds.length) {
          return false
        }
        if (
          !ctrl.crosstab.secondaryQuestionIds.length &&
          !ctrl.crosstab.crossFilterSetIds.length
        ) {
          return false
        }
        return true
      }
      return ctrl.crosstab.validate()
    }

    function save(noGenerate) {
      if (!validate() || ctrl.crosstab.isSaving) return
      if (!canModify()) {
        return console.log('skipping save - no modify permission')
      }
      if (ctrl.isBulk) {
        return ctrl.onAddBulk({
          $bulkPrimaryQuestionIds: ctrl.bulkPrimaryQuestionIds,
        })
      }
      ctrl.crosstab.isSaving = true
      crosstabService
        .save(ctrl.crosstab)
        .then(function() {
          ctrl.crosstab.isSaving = false
          ctrl.crosstab.isEditing = false
          if (!noGenerate) generate()
        })
        .catch(function(err) {
          console.error(err)
          ctrl.crosstab.isSaving = false
          glToast.show('Error saving crosstab')
        })
    }

    function cancel() {
      if (ctrl.crosstab.isNew()) {
        ctrl.onRemove()
      } else {
        ctrl.crosstab.revert()
        ctrl.crosstab.isEditing = false
        if (!ctrl.crosstab.isGenerated()) {
          generate()
        }
      }
    }

    function generate() {
      if (ctrl.crosstab.isGenerating) return

      ctrl.crosstab.isGenerating = true
      ctrl.crosstab.counts = {}

      var pQuestion = ctrl.survey.getQuestion(ctrl.crosstab.primaryQuestionId)
      var isMatrix = pQuestion.isUsedAsMatrix()
      var isRank = pQuestion.isType(Question.Types.RANK)
      var isMulti = pQuestion.maxSelections !== 1
      var hasScore = pQuestion.isType(Question.Types.SCORE)
      var pStatements = pQuestion.getVisibleStatements()
      var pChoices = pQuestion.getVisibleChoices()
      var promiseFns = []

      var preFilterSet
      if (ctrl.crosstab.filterSetId) {
        var filterSet = ctrl.filterSetGroup.filterSets.find(function(
          filterSet
        ) {
          return filterSet.id === ctrl.crosstab.filterSetId
        })
        if (filterSet) {
          preFilterSet = filterSet.clone()
        } else {
          console.error('crosstab filterSet not found')
        }
      }

      // ensure the report is pre filtered with answered primary question only
      var filterSet = preFilterSet
        ? preFilterSet.clone()
        : new FilterSet({ survey: ctrl.survey })
      if (!filterSet.filter.groups.length) {
        filterSet.filter.addGroup()
      }
      var rule = filterSet.createRule(ResponseFilterRule.Types.ANSWER)
      rule.questions.setQuantifier('ANY')
      rule.questions.items.push(pQuestion.id)
      filterSet.distributeRule(rule)

      // one report for the base data
      promiseFns.push(function() {
        return surveyReport
          .get(
            ctrl.survey.id,
            ctrl.reportOptions,
            [filterSet],
            null,
            false,
            responseTypeService.Types.COMPLETE,
            null,
            true
          )
          .then(function(report) {
            report.isBase = true
            return report
          })
      })

      // a report for each cross filter
      _.each(ctrl.crosstab.crossFilterSetIds, function(crossFilterSetId) {
        var filterSet = ctrl.filterSetGroup.filterSets.find(function(
          filterSet
        ) {
          return filterSet.id === crossFilterSetId
        })
        if (!filterSet)
          return console.error(
            'crosstab referencing cross filter set that doesnt exist'
          )
        // Ensure the cross filter report is filtered with answered primary question only
        filterSet = filterSet.clone()
        var rule = filterSet.createRule(ResponseFilterRule.Types.ANSWER)
        rule.questions.setQuantifier('ANY')
        rule.questions.items.push(pQuestion.id)
        filterSet.distributeRule(rule)

        // If there's a preFilterSet, combine it with AND
        var filterSets = [filterSet]
        if (preFilterSet) {
          var clonedPreFilterSet = preFilterSet.clone()
          clonedPreFilterSet.distributeRule(rule)
          filterSets.push(clonedPreFilterSet)
        }

        promiseFns.push(function() {
          return surveyReport
            .get(
              ctrl.survey.id,
              ctrl.reportOptions,
              filterSets,
              ctrl.filterSetGroup.Operators.AND,
              false,
              responseTypeService.Types.COMPLETE,
              null,
              true
            )
            .then(function(report) {
              report.crossFilterSet = filterSet
              return report
            })
        })
      })

      // a report for each primary item
      function addReport(questionId, category, choiceId, aspect, notes) {
        var filterSet = preFilterSet
          ? preFilterSet.clone()
          : new FilterSet({ survey: ctrl.survey })
        if (!filterSet.filter.groups.length) {
          filterSet.filter.addGroup()
        }
        var rule = filterSet.createRule(ResponseFilterRule.Types.QUESTION)
        rule.questionId = questionId
        if (aspect && category === 'matrix') {
          rule.statementIds.push(aspect)
        }
        if (aspect && category === 'rank') {
          rule.numbers.setQuantifier('EQUALS')
          rule.numbers.items.push(aspect)
        }
        rule.choices.setQuantifier('ANY')
        rule.choices.items.push(choiceId)
        filterSet.distributeRule(rule)

        promiseFns.push(function() {
          return surveyReport
            .get(
              ctrl.survey.id,
              ctrl.reportOptions,
              [filterSet],
              null,
              false,
              responseTypeService.Types.COMPLETE,
              null,
              true
            )
            .then(function(report) {
              report.notes = notes
              return report
            })
        })
      }
      if (isMatrix) {
        _.each(pStatements, function(statement) {
          _.each(pChoices, function(choice) {
            addReport(pQuestion.id, 'matrix', choice.id, statement.id, {
              statementId: statement.id,
              statement: statement.label,
              choiceId: choice.id,
              choice: choice.label,
            })
          })
        })
      } else if (isRank) {
        _.times(pQuestion.getNumberOfRanks(), function(rankIndex) {
          var rank = rankIndex + 1
          _.each(pChoices, function(choice) {
            addReport(pQuestion.id, 'rank', choice.id, rank, {
              rank: rank,
              choiceId: choice.id,
              choice: choice.label,
            })
          })
        })
      } else {
        _.each(pChoices, function(choice) {
          addReport(pQuestion.id, 'choice', choice.id, null, {
            choiceId: choice.id,
            choice: choice.label,
          })
        })
      }

      function onProgress(percent) {
        ctrl.crosstab.progress = percent + '%'
      }

      function setCrossReportCounts(report, baseReport, crossCountKey) {
        _.each(ctrl.crosstab.secondaryQuestionIds, function(sQuestionId) {
          var sQuestion = ctrl.survey.getQuestion(sQuestionId)
          var sReportQuestion = report.questions.find(function(question) {
            return question.id === sQuestion.id
          })
          sQuestion.getVisibleChoices().forEach(function(sChoice) {
            var sBaseReportQuestion = baseReport.questions.find(function(
              question
            ) {
              return question.id === sQuestion.id
            })
            var itemTotal =
              sBaseReportQuestion.questionResults.values[sChoice.id].count
            var itemTotalKey = 'secondaryItemTotal-' + sChoice.id
            ctrl.crosstab.counts[itemTotalKey] = itemTotal

            var totalSelected =
              sReportQuestion.questionResults.values[sChoice.id].count

            var key = crossCountKey + sChoice.id
            ctrl.crosstab.counts[key] = {
              percent: (totalSelected / itemTotal) * 100 || 0, // fallback for 0/0=NaN
              total: totalSelected,
            }
          })
        })
      }

      function setCrossReportsScore(reports, statementId) {
        // map the score value by choice id
        var scoreByChoiceId = {} // [choiceId]: 40
        _.each(pChoices, function(pChoice) {
          if (_.isNumber(pChoice.score)) {
            scoreByChoiceId[pChoice.id] = pChoice.score
          }
        })

        // collect report counts for each secondary items
        var scoreCountsByItemId = {} // [sChoiceId]: {}
        _.each(ctrl.crosstab.secondaryQuestionIds, function(sQuestionId) {
          var sQuestion = ctrl.survey.getQuestion(sQuestionId)
          sQuestion.getVisibleChoices().forEach(function(sChoice) {
            if (!scoreCountsByItemId[sChoice.id]) {
              scoreCountsByItemId[sChoice.id] = {} // [reportChoiceId]: totalSelected
            }
            _.each(reports, function(report) {
              // skip report for non score choices
              if (!_.includes(_.keys(scoreByChoiceId), report.notes.choiceId))
                return

              var sReportQuestion = report.questions.find(function(question) {
                return question.id === sQuestion.id
              })
              var totalSelected =
                sReportQuestion.questionResults.values[sChoice.id].count
              scoreCountsByItemId[sChoice.id][
                report.notes.choiceId
              ] = totalSelected
            })
          })
        })

        // calculate the score for each secondary items
        _.each(scoreCountsByItemId, function(counts, sChoiceId) {
          var sumProduct = 0
          var totalCount = 0
          _.each(counts, function(count, scoreChoiceId) {
            var score = scoreByChoiceId[scoreChoiceId]
            sumProduct += count * score
            totalCount += count
          })
          var statementKey = statementId ? statementId + '-' : ''
          var itemScoreKey = 'secondaryItemScore-' + statementKey + sChoiceId
          ctrl.crosstab.counts[itemScoreKey] =
            Math.round(sumProduct / totalCount) || 0 // fallback for 0/0=NaN
        })
      }

      runConcurrentPromises(promiseFns, 10, onProgress).then(function(reports) {
        $scope.$applyAsync(function() {
          var baseReport = reports.shift()
          console.log('baseReport', baseReport)
          console.log('reports', reports)
          var pReportQuestion = baseReport.questions.find(function(question) {
            return pQuestion.id === question.id
          })

          var crossFilterReports = _.remove(reports, function(report) {
            return report.crossFilterSet
          })

          if (isMatrix) {
            // base report counts
            _.each(pStatements, function(pStatement) {
              var results = pReportQuestion.questionResults
              var values = results.values[pStatement.id].values
              var totalStatementAnswered = results.values[pStatement.id].count
              var totalChoicesAnswered = 0
              _.each(values, function(value, choiceId) {
                totalChoicesAnswered += value.count
              })
              _.each(pChoices, function(pChoice) {
                var totalSelected = values[pChoice.id].count
                var primaryItemCountKey =
                  'primaryItemCount-' + pStatement.id + '-' + pChoice.id
                var percentVal = isMulti
                  ? totalSelected / totalStatementAnswered
                  : totalSelected / totalChoicesAnswered
                ctrl.crosstab.counts[primaryItemCountKey] = {
                  percent: percentVal * 100 || 0, // fallback for 0/0=NaN
                  total: totalSelected,
                }
              })
              if (hasScore) {
                var primaryScoreKey = 'primaryScore-' + pStatement.id
                ctrl.crosstab.counts[primaryScoreKey] =
                  Math.round(
                    pReportQuestion.questionResults.score[pStatement.id]
                  ) || 0 // fallback for Math.round(undefined)=NaN
              }
            })
            var primaryTotalKey = 'primaryTotal'
            ctrl.crosstab.counts[primaryTotalKey] =
              pReportQuestion.questionResults.answered

            // cross filter set counts
            _.each(crossFilterReports, function(fReport) {
              var crossFilterSetId = fReport.crossFilterSet.id
              var fReportQuestion = fReport.questions.find(function(question) {
                return pQuestion.id === question.id
              })
              _.each(pStatements, function(pStatement) {
                var results = fReportQuestion.questionResults
                var values = results.values[pStatement.id].values
                var totalStatementAnswered = results.values[pStatement.id].count
                var totalChoicesAnswered = 0
                _.each(values, function(value, choiceId) {
                  totalChoicesAnswered += value.count
                })
                _.each(pChoices, function(pChoice) {
                  var totalSelected = values[pChoice.id].count
                  var key =
                    'crossFilterSetCount-' +
                    pStatement.id +
                    '|' +
                    pChoice.id +
                    '|' +
                    crossFilterSetId
                  var percentVal = isMulti
                    ? totalSelected / totalStatementAnswered
                    : totalSelected / totalChoicesAnswered
                  ctrl.crosstab.counts[key] = {
                    percent: percentVal * 100 || 0, // fallback for 0/0=NaN
                    total: totalSelected,
                  }
                })
                if (hasScore) {
                  var crossFilterSetScoreKey =
                    'crossFilterSetScore-' +
                    pStatement.id +
                    '-' +
                    crossFilterSetId
                  ctrl.crosstab.counts[crossFilterSetScoreKey] =
                    Math.round(
                      fReportQuestion.questionResults.score[pStatement.id]
                    ) || 0 // fallback for Math.round(undefined)=NaN
                }
              })
              var crossFilterSetTotalKey =
                'crossFilterSetTotal-' + crossFilterSetId
              ctrl.crosstab.counts[crossFilterSetTotalKey] =
                fReportQuestion.questionResults.answered
            })

            // cross report counts + totals
            _.each(pStatements, function(pStatement, statementIndex) {
              _.each(pChoices, function(pChoice, choiceIndex) {
                var reportIndex = statementIndex * pChoices.length + choiceIndex // reports are in pStatement to pChoice order
                var report = reports[reportIndex]
                var crossCountKey =
                  'crossCount|' + pStatement.id + '|' + pChoice.id + '|'
                setCrossReportCounts(report, baseReport, crossCountKey)
              })
            })

            // cross reports score per statement
            if (hasScore) {
              var reportsByStatement = _.groupBy(reports, 'notes.statementId')
              _.each(reportsByStatement, function(
                statementReports,
                statementId
              ) {
                setCrossReportsScore(statementReports, statementId)
              })
            }
          } else if (isRank) {
            // base report counts
            var totalAnswered = pReportQuestion.questionResults.answered
            _.times(pQuestion.getNumberOfRanks(), function(rankIndex) {
              var rank = rankIndex + 1
              _.each(pChoices, function(pChoice) {
                var totalSelected =
                  pReportQuestion.questionResults.values[pChoice.id].rankings[
                    rank
                  ].count
                var primaryItemCountKey =
                  'primaryItemCount-' + pChoice.id + '-' + rank
                ctrl.crosstab.counts[primaryItemCountKey] = {
                  percent: (totalSelected / totalAnswered) * 100 || 0, // fallback for 0/0=NaN
                  total: totalSelected,
                }
              })
            })
            var primaryTotalKey = 'primaryTotal'
            ctrl.crosstab.counts[primaryTotalKey] = totalAnswered

            // cross filter set counts
            _.each(crossFilterReports, function(fReport) {
              var crossFilterSetId = fReport.crossFilterSet.id
              var fReportQuestion = fReport.questions.find(function(question) {
                return pQuestion.id === question.id
              })
              var totalAnswered = fReportQuestion.questionResults.answered
              _.times(pQuestion.getNumberOfRanks(), function(rankIndex) {
                var rank = rankIndex + 1
                _.each(pChoices, function(pChoice) {
                  var totalSelected =
                    fReportQuestion.questionResults.values[pChoice.id].rankings[
                      rank
                    ].count
                  var key =
                    'crossFilterSetCount-' +
                    pChoice.id +
                    '|' +
                    rank +
                    '|' +
                    crossFilterSetId
                  ctrl.crosstab.counts[key] = {
                    percent: (totalSelected / totalAnswered) * 100 || 0, // fallback for 0/0=NaN
                    total: totalSelected,
                  }
                })
              })
              var crossFilterSetTotalKey =
                'crossFilterSetTotal-' + crossFilterSetId
              ctrl.crosstab.counts[crossFilterSetTotalKey] = totalAnswered
            })

            // cross report counts + totals
            _.each(pChoices, function(pChoice, choiceIndex) {
              _.times(pQuestion.getNumberOfRanks(), function(rankIndex) {
                var rank = rankIndex + 1
                var reportIndex = rankIndex * pChoices.length + choiceIndex // reports are in rank to pChoice order
                var report = reports[reportIndex]
                var crossCountKey =
                  'crossCount|' + pChoice.id + '|' + rank + '|'
                setCrossReportCounts(report, baseReport, crossCountKey)
              })
            })
          } else {
            // base report counts
            var totalAnswered = pReportQuestion.questionResults.answered
            _.each(pChoices, function(pChoice) {
              var totalSelected =
                pReportQuestion.questionResults.values[pChoice.id].count
              var primaryItemCountKey = 'primaryItemCount-' + pChoice.id
              ctrl.crosstab.counts[primaryItemCountKey] = {
                percent: (totalSelected / totalAnswered) * 100 || 0, // fallback for 0/0=NaN
                total: totalSelected,
              }
            })
            if (hasScore) {
              var primaryScoreKey = 'primaryScore'
              ctrl.crosstab.counts[primaryScoreKey] =
                Math.round(pReportQuestion.questionResults.score) || 0 // fallback for Math.round(undefined)=NaN
            }
            var primaryTotalKey = 'primaryTotal'
            ctrl.crosstab.counts[primaryTotalKey] = totalAnswered

            // cross filter set counts
            _.each(crossFilterReports, function(fReport) {
              var crossFilterSetId = fReport.crossFilterSet.id
              var fReportQuestion = fReport.questions.find(function(question) {
                return pQuestion.id === question.id
              })
              var totalAnswered = fReportQuestion.questionResults.answered
              _.each(pChoices, function(pChoice) {
                var totalSelected =
                  fReportQuestion.questionResults.values[pChoice.id].count
                var key =
                  'crossFilterSetCount-' + pChoice.id + '|' + crossFilterSetId
                ctrl.crosstab.counts[key] = {
                  percent: (totalSelected / totalAnswered) * 100 || 0, // fallback for 0/0=NaN
                  total: totalSelected,
                }
              })
              if (hasScore) {
                var crossFilterSetScoreKey =
                  'crossFilterSetScore-' + crossFilterSetId
                ctrl.crosstab.counts[crossFilterSetScoreKey] =
                  Math.round(fReportQuestion.questionResults.score) || 0 // fallback for Math.round(undefined)=NaN
              }
              var crossFilterSetTotalKey =
                'crossFilterSetTotal-' + crossFilterSetId
              ctrl.crosstab.counts[crossFilterSetTotalKey] = totalAnswered
            })

            // cross report counts + totals
            _.each(pChoices, function(pChoice, index) {
              var report = reports[index] // reports are in pChoice order
              var crossCountKey = 'crossCount|' + pChoice.id + '|'
              setCrossReportCounts(report, baseReport, crossCountKey)
            })

            // cross reports score
            if (hasScore) {
              setCrossReportsScore(reports)
            }
          }

          ctrl.crosstab.isGenerating = false
          var responseCount =
            ctrl.survey.responseCount + ctrl.survey.deletedResponseCount
          ctrl.crosstab.generatedAtResponseCount = responseCount
          save(true)
        })
      })
    }

    function remove() {
      ctrl.crosstab.isRemoving = true
      crosstabService
        .remove(ctrl.crosstab)
        .then(function() {
          ctrl.crosstab.isRemoving = false
          ctrl.onRemove()
        })
        .catch(function(err) {
          console.error(err)
          ctrl.crosstab.isRemoving = false
          glToast.show('Error removing crosstab')
        })
    }

    function formatMultiOptions(options) {
      return options
        .map(function(option) {
          return option.short
        })
        .join(', ')
    }

    function getCached(field, build) {
      if (cache.key !== ctrl.crosstab.version) {
        cache = { key: ctrl.crosstab.version }
      }
      if (!cache[field]) {
        cache[field] = build()
      }
      return cache[field]
    }

    function editPrimaryLabel() {
      if (!canModify()) return
      var key = 'primaryLabel-' + ctrl.crosstab.primaryQuestionId
      var rename = ctrl.crosstab.renames[key]
      var question = ctrl.survey.getQuestion(ctrl.crosstab.primaryQuestionId)
      var original = question.getTitleLabel({ number: false })
      crosstabService.showRenameDialog(original, rename).then(function(value) {
        ctrl.crosstab.renames[key] = value
        cache[key] = undefined
        save(true)
      })
    }

    function editSecondaryLabel(questionId) {
      if (!canModify()) return
      var key = 'secondaryLabel-' + questionId
      var rename = ctrl.crosstab.renames[key]
      var original = ctrl.survey
        .getQuestion(questionId)
        .getTitleLabel({ number: false })
      crosstabService.showRenameDialog(original, rename).then(function(value) {
        ctrl.crosstab.renames[key] = value
        cache[key] = undefined
        save(true)
      })
    }

    function editSecondaryItemLabel(questionId, sItemId) {
      if (!canModify()) return
      var key = 'secondaryItemLabel-' + questionId + '-' + sItemId
      var rename = ctrl.crosstab.renames[key]
      var question = ctrl.survey.getQuestion(questionId)
      var original = question.getVisibleChoices().find(function(choice) {
        return choice.id === sItemId
      }).label
      crosstabService.showRenameDialog(original, rename).then(function(value) {
        ctrl.crosstab.renames[key] = value
        cache['secondaryItems-' + questionId] = undefined
        cache['combinedSecondaryItems'] = undefined
        save(true)
      })
    }

    function editPrimaryAspectLabel(pItem) {
      if (!canModify()) return
      var key = 'primaryAspectLabel-' + pItem.aspect.id
      var rename = ctrl.crosstab.renames[key]
      var question = ctrl.survey.getQuestion(ctrl.crosstab.primaryQuestionId)
      if (!question.isType(Question.Types.RANK)) return // can only rename rank choice aspects
      var original = question.getVisibleChoices().find(function(choice) {
        return choice.id === pItem.aspect.id
      }).label
      crosstabService.showRenameDialog(original, rename).then(function(value) {
        ctrl.crosstab.renames[key] = value
        cache[key] = undefined
        save(true)
      })
    }

    function editPrimaryItemLabel(pItemId) {
      if (!canModify()) return
      var key = 'primaryItemLabel-' + pItemId
      var rename = ctrl.crosstab.renames[key]
      var question = ctrl.survey.getQuestion(ctrl.crosstab.primaryQuestionId)
      if (question.isType(Question.Types.RANK)) return // can't rename "Rank 1" etc
      var original = question.getVisibleChoices().find(function(choice) {
        return choice.id === pItemId
      }).label
      crosstabService.showRenameDialog(original, rename).then(function(value) {
        ctrl.crosstab.renames[key] = value
        cache[key] = undefined
        save(true)
      })
    }

    function hasOldData() {
      var responseCount =
        ctrl.survey.responseCount + ctrl.survey.deletedResponseCount
      return ctrl.crosstab.hasOldData(responseCount)
    }

    /**
     * This utility loads an array of promises similar to how Promise.all/$q.all
     * works, except it will only load a maximum of X concurrently.
     * It also outputs the progress percentage so that we show it in the UI.
     */
    function runConcurrentPromises(fns, concurrent, onProgress) {
      // switch to true to see log output
      var debug = false
      function log() {
        if (!debug) return
        console.log.apply(this, arguments)
      }
      return new Promise(function(resolve, reject) {
        log(
          'running ' +
            fns.length +
            ' promises with max concurrency: ' +
            concurrent
        )
        var start = performance.now()
        var total = fns.length
        var incomplete = fns.map(function(fn, idx) {
          return { fn: fn, idx: idx }
        })
        var running = 0
        var complete = 0
        var results = []
        var err

        function check() {
          if (err) return
          var percent = Math.round((complete / total) * 100)
          log({
            running: running,
            complete: complete,
            percent: percent,
            remaining: incomplete.length,
          })
          // report progress
          $scope.$applyAsync(function() {
            onProgress(percent)
          })
          // if everything is complete, resolve with our results
          if (complete === total) {
            $scope.$applyAsync(function() {
              var secs = (performance.now() - start) / 1000
              log('completed in ' + secs + 's')
              resolve(results)
            })
            return
          }
          // if we've hit max concurrency, pause
          if (running >= concurrent) {
            return
          }
          // if no more incomplete, pause
          if (!incomplete.length) return
          // start 'er up
          running++
          var item = incomplete.shift()
          item
            .fn()
            .then(function(result) {
              if (err) return
              results[item.idx] = result
              running--
              complete++
              check()
            })
            .catch(function(_err) {
              if (err) return
              err = _err
              $scope.$applyAsync(function() {
                reject(err)
              })
            })
          check()
        }
        check()
      })
    }
  }
})()
