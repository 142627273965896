;(function() {
  'use strict'

  ActivityController.$inject = ["$rootScope", "$log", "interactionsService", "pagesService", "subscriberService", "glToast", "glUtils", "glAuthService", "configService", "glTips", "uacService"];
  angular
    .module('app.activity')
    .controller('ActivityController', ActivityController)

  /* @ngInject */
  function ActivityController(
    $rootScope,
    $log,
    interactionsService,
    pagesService,
    subscriberService,
    glToast,
    glUtils,
    glAuthService,
    configService,
    glTips,
    uacService
  ) {
    var vm = this

    $log = $log.create('ActivityController')

    // chart options
    vm.listOptions = {
      action: true,
      source: [],
      canModify: uacService.canManageAnalysisResponses(),
    }

    vm.dropdownSettings = {
      displayProp: 'title',
      showCheckAll: false,
    }

    // methods
    vm.exportInbox = exportInbox
    vm.applyFilter = applyFilter

    activate()

    /**
     * Initialise
     */
    function activate() {
      getGraphInteractions()
      getSourceOptions()
      glTips.ready()
    }

    /**
     * @name getGraphInteractions
     * @desciption get graph interactions and add to view model
     */
    function getGraphInteractions() {
      var subscriberId = subscriberService.getSubscriber().id
      var options = {
        top: 5000,
        orderBy: 'inboxUpdatedAt desc',
        filter:
          "createdAt gte '" +
          moment()
            .startOf('day')
            .subtract(28, 'days')
            .toISOString() +
          "'",
      }

      vm.graphInteractions = interactionsService.getSubscriberInteractions(
        subscriberId,
        options
      )
    }

    /**
     * @name getSourceOptions
     * @description get source options for dropdown filter
     */
    function getSourceOptions() {
      pagesService
        .getAll()
        .then(getSuccess)
        .catch(getError)

      function getSuccess(data) {
        vm.sourceOptions = data
      }

      function getError() {
        $log.error('Could not retrieve pages')
      }
    }

    /**
     * @name exportInbox
     * @description export and download all conversations to CSV format
     */
    function exportInbox() {
      var token = glAuthService.getToken()
      var baseUrl = configService.getApiUrl()

      var url = glUtils.addParams(baseUrl + '/posts', {
        type: 'csv',
        api_token: token,
        subscriberId: subscriberService.getSubscriber().id,
      })

      glToast.show('Starting download...')
      window.location.href = url
    }

    /**
     * @name applyFilter
     * @description tell interaction list that options have changed so it can filter the data
     */
    function applyFilter() {
      $rootScope.$broadcast('InteractionList::options-changed')
    }
  }
})()
