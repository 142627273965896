;(function() {
  'use strict'

  angular.module('core.glView').component('glView', {
    templateUrl: 'gl-view.html',
    transclude: {
      content: '?glViewContent',
      footer: '?glViewFooter',
    },
  })
})()
