;(function() {
  'use strict'

  pageConfig.$inject = ["$stateProvider"];
  angular.module('app.page', []).config(pageConfig)

  /* @ngInject */
  function pageConfig($stateProvider) {
    $stateProvider.state('page', {
      url: '/page/:pageId',
      parent: 'portal',
      templateUrl: 'page.template.html',
      controller: 'PageController',
      controllerAs: 'page',
      title: 'Page',
      redirectTo: 'page.general',
    })
  }
})()
