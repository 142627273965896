;(function() {
  'use strict'

  statsService.$inject = ["$log", "$q", "subscriberStatsResource", "subscriberService"];
  angular.module('core.services').factory('statsService', statsService)

  /**
   * StatsService
   *
   * @ngInject */
  function statsService($log, $q, subscriberStatsResource, subscriberService) {
    $log = $log.create('statsService')

    var currentStats = {}

    return {
      get: get,
      updateStat: updateStat,
    }

    /**
     * @name get
     * @description get subscriber stats
     * @param {Boolean} force - set to true to ignore cache and force request to API
     * @return {Promise}
     */
    function get(force) {
      var deferred = $q.defer()

      if (!_.isEmpty(currentStats) && !force) {
        return $q.when(currentStats)
      }

      subscriberStatsResource
        .getInboxUnread({ id: subscriberService.getSubscriber().id })
        .success(function(data) {
          _.assignIn(currentStats, data)
          deferred.resolve(currentStats)
        })
        .error(deferred.reject)

      return deferred.promise
    }

    /**
     * @name updateStat
     * @description [description]
     * @param  {[type]} stat     [description]
     * @param  {[type]} newValue [description]
     * @return {[type]}          [description]
     */
    function updateStat(stat, newValue) {
      if (!currentStats[stat]) {
        return $log.error(
          'could not update ' + stat + ' as stat does not exist'
        )
      }

      if (_.isFunction(newValue)) {
        // apply function to stat
        currentStats[stat] = newValue(currentStats[stat])
      } else {
        // set as value
        currentStats[stat] = newValue
      }
    }
  }
})()
