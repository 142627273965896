;(function() {
  'use strict'

  Config.$inject = ["$stateProvider"];
  angular.module('app.login', []).config(Config)

  /* @ngInject */
  function Config($stateProvider) {
    $stateProvider.state('login', {
      url: '/login',
      templateUrl: 'login.html',
      controller: 'LoginController',
      controllerAs: '$ctrl',
      title: 'Login',
      public: true,
    })
  }
})()
