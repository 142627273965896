;(function() {
  'use strict'

  Controller.$inject = ["$scope", "$element", "$document"];
  angular.module('app.core').component('gCard', {
    controller: Controller,
    templateUrl: 'g-card.html',
    bindings: {
      ref: '&',
      onClick: '&?',
      clickable: '<',
      border: '<',
    },
    transclude: {
      front: 'gCardFront',
      back: '?gCardBack',
    },
  })

  /* @ngInject */
  function Controller($scope, $element, $document) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.click = click
    ctrl.clickFront = clickFront
    ctrl.isClickable = isClickable

    function onInit() {
      ctrl.uuid = _.uniqueId()
      ctrl.ref({
        $card: {
          flip: flip,
        },
      })
    }

    function flip() {
      if (ctrl.isFlipped) {
        return unflip()
      }
      var front = $element[0].querySelector('.g-card__front')
      var back = $element[0].querySelector('.g-card__back')
      back.style.height = front.offsetHeight + 'px'
      ctrl.isFlipped = true
      // angular.element($element.children()[0]).on('mouseleave', unflip);
      if (!ctrl.body) {
        ctrl.body = angular.element($document[0].body)
      }
      ctrl.body.on('click', unflip)
    }

    function unflip(event) {
      if (event && event.cardId === ctrl.uuid) {
        return
      }
      // angular.element($element.children()[0]).off('mouseleave', unflip);
      ctrl.body.off('click', unflip)
      $scope.$applyAsync(function() {
        ctrl.isFlipped = false
      })
    }

    function click(event) {
      // stamp the event
      event.cardId = ctrl.uuid
    }

    function clickFront(event) {
      if (event.defaultPrevented) {
        return
      }
      if (ctrl.onClick && isClickable()) {
        ctrl.onClick({ $event: event })
      }
    }

    function isClickable() {
      return ctrl.clickable !== false
    }
  }
})()
