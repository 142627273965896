;(function() {
  'use strict'

  Controller.$inject = ["subscriberService", "userService", "glToast", "glDialog", "glEmail", "accountService", "uacService"];
  angular.module('app.account').component('accountUsers', {
    controller: Controller,
    templateUrl: 'account-users.html',
  })

  /* @ngInject */
  function Controller(
    subscriberService,
    userService,
    glToast,
    glDialog,
    glEmail,
    accountService,
    uacService
  ) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.canEditUAC = canEditUAC
    ctrl.editUAC = editUAC
    ctrl.isValidEmail = isValidEmail
    ctrl.sendInvite = sendInvite
    ctrl.remove = remove

    function onInit() {
      ctrl.subscriber = subscriberService.getSubscriber()
      ctrl.isAdmin = subscriberService.isAdmin(userService.getUser().id)
      ctrl.isSSR = userService.isSSR()
      ctrl.canManageUsers = uacService.canManageUsers()
      prepare()
    }

    function prepare() {
      subscriberService
        .getSubscriberUsers(ctrl.subscriber.id)
        .then(getSuccess)
        .catch(getError)

      function getSuccess(users) {
        _.each(ctrl.subscriber.members, function(member) {
          member.isAdmin = subscriberService.isAdmin(member.userId)
          member.permissionsText = makePermissionsText(member)
          var user = _.find(users, { id: member.userId })
          member.fullname = user ? user.fullname : ''
          member.email = user ? user.email : member.invitedEmail
        })
        ctrl.subscriber.members = _.sortBy(ctrl.subscriber.members, function(
          member
        ) {
          // sort order :
          // 1. Admin/Owner
          // 2. Users sorted by creation date
          // 3. Pending Invites sorted by creation date
          if (member.isAdmin) {
            return -1
          }
          return (
            moment(member.createdAt).unix() +
            (!member.userId ? Number.MAX_SAFE_INTEGER : 0)
          )
        })
        ctrl.state = 'ready'
      }

      function getError() {
        ctrl.state = 'error'
      }
    }

    function canEditUAC(member) {
      // SSR can edit UAC for all users except admin
      // Admin role can edit UAC for all users except themselves
      // User role can't edit UAC for admin, themselves, other users with manage users permission
      var userIsYourself = userService.getUser().id === member.userId
      var userIsAdmin = member.isAdmin
      var userCanManageUsers = member.canManageUsers
      return (
        (ctrl.isSSR && !userIsAdmin) ||
        (ctrl.isAdmin && !userIsYourself) ||
        (ctrl.canManageUsers &&
          !userIsYourself &&
          !userIsAdmin &&
          !userCanManageUsers)
      )
    }

    function editUAC(member) {
      accountService.showUacEditorDialog(member).then(function() {
        // update the permission text
        var updatedMember = subscriberService.getMemberById(member.id)
        updatedMember.permissionsText = makePermissionsText(updatedMember)
      })
    }

    function isValidEmail() {
      return glEmail.isValid(ctrl.emailToInvite)
    }

    function sendInvite() {
      if (ctrl.isSending || !isValidEmail()) return
      accountService.showSubscriberRenamerDialog().then(send)
    }

    function send() {
      ctrl.isSending = true
      subscriberService
        .sendInvite(ctrl.emailToInvite)
        .then(function() {
          // update the permission text
          var newMember = _.last(ctrl.subscriber.members)
          newMember.permissionsText = makePermissionsText(newMember)
          glToast.show(
            'Successfully sent invite to user email ' + ctrl.emailToInvite
          )
          ctrl.emailToInvite = ''
        })
        .catch(function(err) {
          if (err.__status === 400) {
            glToast.show(
              'Failed send invite. User already joined this organisation'
            )
          } else {
            glToast.show('Failed send invite')
          }
        })
        .finally(function() {
          ctrl.isSending = false
        })
    }

    function remove(member) {
      glDialog
        .confirm(
          'Remove user',
          'Are you sure you want to remove "' +
            (member.fullname || member.email) +
            '" from this organisation?'
        )
        .then(function() {
          member.isRemoving = true
          subscriberService
            .removeMember(member.id)
            .then(function() {
              glToast.show(
                'Successfully removed user ' +
                  (member.fullname || member.email) +
                  ' from organisation'
              )
            })
            .catch(function() {
              glToast.show('Failed remove user')
            })
            .finally(function() {
              setTimeout(function() {
                member.isRemoving = false
              })
            })
        })
    }

    function makePermissionsText(member) {
      if (
        member.canManageUsers &&
        member.canManageProjects &&
        member.canManageSurveys &&
        member.canManageChannels &&
        member.canLaunchChannels &&
        member.canManageAnalysisFilters &&
        member.canManageAnalysisSettings &&
        member.canExportAndShareAnalysis &&
        member.canViewAnalysisCrosstabs &&
        member.canManageAnalysisCrosstabs &&
        member.canViewAnalysisResponses &&
        member.canManageAnalysisResponses &&
        member.canManageReports
      )
        return 'All'

      var manageTexts = []
      if (member.canManageUsers) {
        manageTexts.push('users')
      }
      if (member.canManageProjects) {
        manageTexts.push('projects')
      }
      if (member.canManageSurveys) {
        manageTexts.push('surveys')
      }
      if (member.canManageChannels) {
        manageTexts.push('channels')
      }
      if (member.canManageAnalysisFilters) {
        manageTexts.push('analysis filters')
      }
      if (member.canManageAnalysisSettings) {
        manageTexts.push('analysis settings')
      }
      if (member.canManageAnalysisCrosstabs) {
        manageTexts.push('analysis crosstabs')
      }
      if (member.canManageAnalysisResponses) {
        manageTexts.push('analysis responses')
      }
      if (member.canManageReports) {
        manageTexts.push('reports')
      }
      var manageText = manageTexts.length
        ? 'Manage ' + manageTexts.join(', ')
        : ''

      var viewTexts = []
      if (
        member.canViewAnalysisCrosstabs &&
        !member.canManageAnalysisCrosstabs
      ) {
        viewTexts.push('analysis crosstabs')
      }
      if (
        member.canViewAnalysisResponses &&
        !member.canManageAnalysisResponses
      ) {
        viewTexts.push('analysis responses')
      }
      var viewText = viewTexts.length ? 'View ' + viewTexts.join(', ') : ''

      var launchText = member.canLaunchChannels ? 'Launch channels' : ''

      var texts = []
      if (manageText) {
        texts.push(manageText)
      }
      if (viewText) {
        texts.push(viewText)
      }
      if (launchText) {
        texts.push(launchText)
      }

      if (texts.length) {
        var last = texts.pop()
        var text = texts.length ? texts.join('; ') + ' and ' : ''
        return text + last
      } else {
        return 'None'
      }
    }
  }
})()
