;(function() {
  'use strict'

  edgeService.$inject = ["$q", "edgesResource", "userService"];
  angular.module('core.services').factory('edgeService', edgeService)

  /* @ngInject */
  function edgeService($q, edgesResource, userService) {
    return {
      create: create,
      remove: remove,
    }

    /**
     * @name create
     * @description create an edge
     * @param  {String} edgeName name of edge
     * @param  {String} type     type of egde
     * @param  {String} id       id of node
     * @return {Promise}
     */
    function create(edgeName, type, id) {
      var deferred = $q.defer()

      if (!id) {
        deferred.reject()
      }

      edgesResource
        .create({
          nodes: [
            {
              type: 'user',
              id: userService.getUser().id,
            },
            {
              type: type,
              id: id,
            },
          ],
          edge: {
            type: edgeName,
          },
        })
        .success(function(resp) {
          deferred.resolve(resp.id)
        })
        .error(deferred.reject)

      return deferred.promise
    }

    /**
     * @name remove
     * @description remove edge
     * @param  {String} id of edge to remove
     * @return {Promise}
     */
    function remove(id) {
      var deferred = $q.defer()

      edgesResource
        .remove({ id: id })
        .success(deferred.resolve)
        .error(deferred.reject)

      return deferred.promise
    }
  }
})()
