;(function() {
  'use strict'

  Controller.$inject = ["$scope", "$timeout", "$window", "$document"];
  angular.module('app.core').component('page', {
    controller: Controller,
    templateUrl: 'page.html',
    bindings: {
      hideSidebar: '<',
    },
    transclude: {
      content: 'pageContent',
      sidebar: 'pageSidebar',
    },
  })

  /* @ngInject */
  function Controller($scope, $timeout, $window, $document) {
    var ctrl = this
    var onDebouncedResize = _.debounce(onResize, 300)

    ctrl.$onInit = onInit
    ctrl.$onDestroy = onDestroy

    function onInit() {
      $window.addEventListener('resize', onDebouncedResize)
      $timeout(function() {
        update()
        ctrl.init = true
      })
    }

    function onResize() {
      $scope.$applyAsync(update)
    }

    function update() {
      ctrl.mobile = $window.innerWidth <= 1000
      ctrl.open = !ctrl.mobile
      if (ctrl.mobile) {
        ctrl.height = $document[0].body.scrollHeight - 60
      } else {
        ctrl.height = 'inherit'
        // ctrl.height = $document[0].body.scrollHeight - 60 - 54
      }
    }

    function onDestroy() {
      $window.removeEventListener('resize', onDebouncedResize)
    }
  }
})()
