;(function() {
  'use strict'

  Controller.$inject = ["$q", "glAnalytics", "glDialog", "glToast", "glPrefs", "api", "colors", "channelService", "responseService", "accountService", "subscriberService", "translationService", "externalPanelService", "uacService", "userService", "surveyReport", "responseFilterService", "surveyServiceNext", "featureService", "TagQuotaSettings", "ResponseFilter", "StateFactory"];
  angular.module('app.core').component('surveyChannels', {
    controller: Controller,
    templateUrl: 'survey-channels.html',
    bindings: {
      survey: '<',
      translations: '<',
    },
  })

  /* @ngInject */
  function Controller(
    $q,
    glAnalytics,
    glDialog,
    glToast,
    glPrefs,
    api,
    colors,
    channelService,
    responseService,
    accountService,
    subscriberService,
    translationService,
    externalPanelService,
    uacService,
    userService,
    surveyReport,
    responseFilterService,
    surveyServiceNext,
    featureService,
    TagQuotaSettings,
    ResponseFilter,
    StateFactory
  ) {
    var ctrl = this
    var prefs = glPrefs.create('survey-channels')
    var prefsOptions = { user: true }

    ctrl.channelState = new StateFactory(['loading', 'zero', 'list', 'error'])

    ctrl.$onInit = onInit
    ctrl.setTab = setTab

    ctrl.addChannel = addChannel
    ctrl.removeChannel = removeChannel

    ctrl.getSelectedResponses = getSelectedResponses
    ctrl.isAllResponsesSelected = isAllResponsesSelected
    ctrl.toggleSelectAllResponses = toggleSelectAllResponses
    ctrl.editResponseFilter = editResponseFilter
    ctrl.manageSegment = manageSegment
    ctrl.deselectResponses = deselectResponses
    ctrl.deleteSelectedResponses = deleteSelectedResponses
    ctrl.deleteResponses = deleteResponses
    ctrl.exportDeletedResponses = exportDeletedResponses
    ctrl.showExportResponsesDialog = showExportResponsesDialog
    ctrl.viewResponse = viewResponse
    ctrl.updateTagQuotasTab = updateTagQuotasTab
    ctrl.onTagQuotasTypesChange = onTagQuotasTypesChange
    ctrl.onHiddenTagsChange = onHiddenTagsChange
    ctrl.tagsFilter = tagsFilter
    ctrl.saveTagQuotas = saveTagQuotas

    function onInit() {
      ctrl.tab = 'channels'
      ctrl.subscriber = subscriberService.getSubscriber()
      ctrl.isSSR = userService.isSSR()
      ctrl.corpus = translationService.corpus(ctrl.survey.serialize())
      ctrl.canUseTagQuotas = featureService.canUseTagQuotas()
      prepareTagQuotasTab()

      ctrl.channelState.loading()
      $q.all([loadExternalPanelProviders(), loadChannels()]).then(function() {
        updateChannelState()
        updateTagQuotasTab()
      })

      glAnalytics.track(
        'channel',
        'view-audiences-tab',
        ctrl.survey.id + ' - ' + ctrl.survey.title
      )
    }

    function setTab(tab) {
      if (tab === 'responses') {
        ctrl.hasInitResponses = true
        loadResponses()
      }
      if (tab === 'quotas' && !ctrl.hasInitQuotas) {
        ctrl.hasInitQuotas = true
        initTagQuotas()
      }
      ctrl.tab = tab
    }

    function loadExternalPanelProviders() {
      return externalPanelService.list()
    }

    function loadChannels() {
      return channelService.getBySurvey(ctrl.survey.id, function(
        channels,
        loading,
        error
      ) {
        ctrl.channels = channels
        ctrl.channelsLoading = loading
        ctrl.channelsError = error
      })
    }

    function loadResponses(forceLoading) {
      if (forceLoading) {
        ctrl.responses = null
      }
      ctrl.responsesError = false
      return loadDimensions()
        .then(function() {
          var filter = ctrl.filter ? ctrl.filter.toMongo() : null
          console.log('filter', JSON.stringify(filter, null, 2))
          return responseService
            .list(ctrl.survey.id, filter)
            .then(function(responses) {
              ctrl.responses = responses
            })
        })
        .catch(function(err) {
          ctrl.responsesError = true
          console.error(err)
        })
    }

    function loadDimensions() {
      if (ctrl.dimensions) return $q.resolve()
      // HACK: we need to fetch an entire report so that we can extract
      // the dimensions which are then used in filters
      return api.surveyReports
        .get({ id: ctrl.survey.id, filter: { type: 'COMPLETE' } })
        .then(function(report) {
          ctrl.dimensions = responseFilterService.extractDimensionsFromReport(
            report
          )
        })
    }

    function addChannel() {
      if (!uacService.canManageChannels(true)) return
      ctrl.tab = 'channels'
      channelService
        .showPicker({ surveyId: ctrl.survey.id })
        .then(function(channel) {
          var currency = ctrl.subscriber.billing.currency
          if (channel.type === channel.Type.PANEL && !currency) {
            return accountService.showPurchaseDialog({
              type: 'add-payment-method',
              context: 'add-panel-channel',
            })
          }
          if (channel.type === channel.Type.PANEL) {
            channel.panel.lengthOfInterview.become(
              ctrl.survey.lengthOfInterview
            )
          }
          channel.name = 'C' + (ctrl.channels.length + 1) + '. ' + channel.name
          ctrl.channels.unshift(channel)
        })
        .finally(updateChannelState)
    }

    function removeChannel(channel) {
      _.remove(ctrl.channels, { id: channel.id })
      updateChannelState()
      updateTagQuotasTab()
    }

    function updateChannelState() {
      if (ctrl.channelsError) {
        ctrl.channelState.error()
      } else if (!ctrl.channels.length && ctrl.channelsLoading) {
        ctrl.channelState.loading()
      } else if (!ctrl.channels.length) {
        ctrl.channelState.zero()
      } else if (ctrl.channels.length) {
        ctrl.channelState.list()
      }
    }

    function getSelectedResponses() {
      if (!ctrl.responses) return []
      return ctrl.responses.filter(function(response) {
        return response.selected
      })
    }

    function isAllResponsesSelected() {
      var selected = getSelectedResponses().length
      return selected > 0 && selected === ctrl.responses.length
    }

    function toggleSelectAllResponses() {
      var value = isAllResponsesSelected() ? false : true
      ctrl.responses.forEach(function(response) {
        response.selected = value
      })
    }

    function editResponseFilter() {
      // prettier-ignore
      var template = [
        '<gl-dialog class="response-filter-dialog__dialog">',
          '<response-filter-dialog ',
            'survey="survey" ',
            'channels="channels" ',
            'dimensions="dimensions" ',
            'filter="filter" ',
            'on-apply="dialog.close($filter)" ',
            'on-cancel="dialog.cancel()" ',
          '/>',
        '</gl-dialog>',
      ]
      // if we dont have a filter create one
      // if we already have one, clone it so it can be edited discretely
      var filter
      if (ctrl.filter) {
        filter = ctrl.filter.clone()
      } else {
        filter = new ResponseFilter(ctrl.survey)
        filter.init()
      }
      glDialog
        .show({
          template: template.join(' '),
          clickOutsideToClose: false,
          escapeToClose: true,
          locals: {
            survey: ctrl.survey,
            channels: ctrl.channels,
            dimensions: ctrl.dimensions,
            filter: filter,
          },
        })
        .then(function(filter) {
          // if no filter is supplied this means the user clicked "remove"
          ctrl.filter = filter
          loadResponses(true)
        })
    }

    function manageSegment() {
      // prettier-ignore
      var template = [
        '<gl-dialog class="segment-manager__dialog">',
          '<segment-manager',
            'survey-id="surveyId" ',
            'on-cancel="dialog.cancel()" ',
            'on-complete="dialog.close($newSegments)" ',
          '/>',
        '</gl-dialog>'
      ]
      glDialog
        .show({
          template: template.join(' '),
          locals: {
            surveyId: ctrl.survey.id,
          },
        })
        .then(function(newSegments) {
          ctrl.survey.segments = newSegments
          surveyReport.modifyCachedSurvey(ctrl.survey.id, function(survey) {
            survey.segments = newSegments
          })
        })
    }

    function deselectResponses() {
      ctrl.responses.forEach(function(response) {
        response.selected = false
      })
    }

    function deleteSelectedResponses() {
      var responseIds = getSelectedResponses().map(function(response) {
        return response.id
      })
      deleteResponses(responseIds)
    }

    function deleteResponses(selectedIds) {
      if (!uacService.canManageAnalysisResponses(true)) return
      // prettier-ignore
      var template = [
        '<gl-dialog class="response-delete-dialog__dialog">',
          '<response-delete-dialog ',
            'survey-id="surveyId" ',
            'selected-ids="selectedIds" ',
            'on-cancel="dialog.cancel()" ',
            'on-done="dialog.close()" ',
          '/>',
        '</gl-dialog>',
      ]
      glDialog
        .show({
          template: template.join(' '),
          clickOutsideToClose: true,
          escapeToClose: true,
          locals: {
            surveyId: ctrl.survey.id,
            selectedIds: selectedIds,
          },
        })
        .then(function() {
          loadResponses()
        })
    }

    function exportDeletedResponses() {
      surveyReport.createExport({
        surveyId: ctrl.survey.id,
        deleted: true,
        withDuration: ctrl.isSSR && ctrl.subscriber.data.withDurationExport,
      })
    }

    function showExportResponsesDialog() {
      // prettier-ignore
      var template = [
        '<gl-dialog class="export-responses-dialog__dialog">',
          '<export-responses-dialog ',
            'survey="survey" ',
            'on-close="dialog.close()" ',
          '/>',
        '</gl-dialog>',
      ]
      glDialog.show({
        template: template.join(' '),
        clickOutsideToClose: false,
        escapeToClose: true,
        locals: {
          survey: ctrl.survey,
        },
      })
    }

    function viewResponse(e, response) {
      if (e.defaultPrevented) return
      if (!uacService.canViewAnalysisResponses(true)) return
      // prettier-ignore
      var template = [
        '<gl-dialog class="response-details-dialog__dialog">',
          '<response-details-dialog ',
            'survey="survey" ',
            'response-id="responseId" ',
            'on-close="dialog.cancel()" ',
          '/>',
        '</gl-dialog>',
      ]
      glDialog.show({
        template: template.join(' '),
        clickOutsideToClose: true,
        escapeToClose: true,
        locals: {
          survey: ctrl.survey,
          responseId: response.id,
        },
      })
    }

    function prepareTagQuotasTab() {
      ctrl.tagQuotasTypeOptions = [
        { label: 'None', value: TagQuotaSettings.Types.NONE, promoted: true },
        {
          label: 'All Channels',
          value: TagQuotaSettings.Types.ALL,
          promoted: true,
        },
      ]

      ctrl.selectedTagQuotasTypes =
        ctrl.survey.tagQuotaSettings.type === TagQuotaSettings.Types.ANY
          ? ctrl.survey.tagQuotaSettings.channelIds
          : [ctrl.survey.tagQuotaSettings.type]
    }

    function updateTagQuotasTab() {
      if (!ctrl.canUseTagQuotas) return

      // update tag quotas type options
      ctrl.tagQuotasTypeOptions = ctrl.tagQuotasTypeOptions.slice(0, 2) // keep only the first two options
      _.each(ctrl.channels, function(channel) {
        ctrl.tagQuotasTypeOptions.push({
          label: channel.name,
          value: channel.id,
          tag: channel.type.toUpperCase(),
          tagColor: colors.Colors.GREY_SILVER,
        })
      })

      // check if any channel has tag quotas enabled, update the tag quota enabled status
      var isChannelTagQuotasEnabled = _.some(ctrl.channels, {
        tagQuotasEnabled: true,
      })
      if (isChannelTagQuotasEnabled === ctrl.survey.tagQuotaSettings.enabled) {
        ctrl.survey.tagQuotaSettings.enabled = !isChannelTagQuotasEnabled
        ctrl.savingTagQuotas = true
        surveyServiceNext
          .save(ctrl.survey)
          .catch(function(err) {
            glToast.show(
              'Error updating quotas. Please try again later or contact support.'
            )
          })
          .finally(function() {
            ctrl.savingTagQuotas = false
          })
      }
    }

    function onTagQuotasTypesChange(values) {
      var types = TagQuotaSettings.Types
      var selectedType
      var selectedChannelIds = []

      if (!values.length) {
        // if no tag quotas type is selected, select NONE
        selectedType = types.NONE
      } else {
        var diff = _.difference(values, ctrl.selectedTagQuotasTypes)[0]
        if ([types.ALL, types.NONE].includes(diff)) {
          selectedType = diff
        } else {
          selectedType = types.ANY
          selectedChannelIds = values.filter(function(value) {
            return ![types.ALL, types.NONE].includes(value)
          })
        }
      }

      ctrl.selectedTagQuotasTypes = selectedChannelIds.length
        ? selectedChannelIds
        : [selectedType]
      ctrl.survey.tagQuotaSettings.type = selectedType
      ctrl.survey.tagQuotaSettings.channelIds = selectedChannelIds
    }

    function initTagQuotas() {
      var tagQuotas = ctrl.survey.tagQuotaSettings.tagQuotas || {}
      var tagCounts = ctrl.survey.tagCounts || {}
      ctrl.tags = _.map(ctrl.survey.getTags(), function(tag) {
        return {
          name: tag,
          quota: _.isNumber(tagQuotas[tag]) ? tagQuotas[tag] : null,
          count: tagCounts[tag] || 0,
        }
      })

      ctrl.hiddenTagOptions = _.map(ctrl.tags, 'name')
      ctrl.hiddenTagsPrefsKey = 'hiddenTags-' + ctrl.survey.id
      var hiddenTags = prefs.get(ctrl.hiddenTagsPrefsKey, prefsOptions)
      ctrl.selectedHiddenTags = _.filter(hiddenTags, function(tag) {
        return _.includes(ctrl.hiddenTagOptions, tag)
      })
    }

    function onHiddenTagsChange(value) {
      ctrl.selectedHiddenTags = value
      prefs.set(ctrl.hiddenTagsPrefsKey, ctrl.selectedHiddenTags, prefsOptions)
    }

    function tagsFilter(tag) {
      return !_.includes(ctrl.selectedHiddenTags, tag.name)
    }

    function saveTagQuotas() {
      if (ctrl.savingTagQuotas) return

      _.each(ctrl.tags, function(tag) {
        ctrl.survey.tagQuotaSettings.tagQuotas[tag.name] = tag.quota
      })

      ctrl.savingTagQuotas = true
      surveyServiceNext
        .save(ctrl.survey)
        .then(function() {
          glToast.show('Tag quotas has been successfully saved!')
        })
        .catch(function(err) {
          glToast.show('Tag quotas could not be saved! Please try again.')
        })
        .finally(function() {
          ctrl.savingTagQuotas = false
        })
    }
  }
})()
