;(function() {
  'use strict'

  angular.module('app.core').component('channelCostSummary', {
    templateUrl: 'channel-cost-summary.html',
    controller: Controller,
    bindings: {
      channel: '<',
      survey: '<',
      cost: '<',
    },
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit

    function onInit() {
      //...
    }
  }
})()
