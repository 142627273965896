;(function() {
  'use strict'

  Controller.$inject = ["reportMediaBtn", "mediaView"];
  angular.module('glow.reporting').component('reportMediaBtn', {
    controller: Controller,
    templateUrl: 'report-media-btn.html',
    bindings: {
      type: '@',
      labelProp: '@label',
      url: '<',
      video: '<',
    },
  })

  /* @ngInject */
  function Controller(reportMediaBtn, mediaView) {
    var ctrl = this

    ctrl.icons = reportMediaBtn.Icons

    ctrl.$onInit = onInit
    ctrl.expandMedia = expandMedia

    function onInit() {
      var defaultLabel = reportMediaBtn.DefaultLabels[ctrl.type]
      ctrl.label = ctrl.labelProp || defaultLabel
    }

    function expandMedia() {
      switch (ctrl.type) {
        case reportMediaBtn.Types.IMAGE:
          mediaView.showImage(ctrl.url)
          break
        case reportMediaBtn.Types.VIDEO:
          mediaView.showVideo(ctrl.video)
          break
        case reportMediaBtn.Types.YOUTUBE:
          mediaView.showYoutubeVideo(ctrl.url)
          break
        case reportMediaBtn.Types.AUDIO:
          mediaView.showAudio(ctrl.url)
          break
      }
    }
  }
})()
