;(function() {
  'use strict'

  pageElementsConfig.$inject = ["$stateProvider"];
  angular.module('app.page-elements', []).config(pageElementsConfig)

  /* @ngInject */
  function pageElementsConfig($stateProvider) {
    $stateProvider.state('page.elements', {
      url: '/elements',
      templateUrl: 'page-elements.template.html',
      controller: 'PageElementsController',
      controllerAs: 'pageElements',
      title: 'Page Elements',
    })
  }
})()
