;(function() {
  'use strict'

  Controller.$inject = ["$element"];
  angular.module('app.core').component('gSegments', {
    controller: Controller,
    templateUrl: 'g-segments.html',
    bindings: {
      segments: '<',
      active: '@',
    },
  })

  /* @ngInject */
  function Controller($element) {
    $element.addClass('g-segments')
  }
})()
