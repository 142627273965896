;(function() {
  'use strict'

  MessageModuleController.$inject = ["$scope", "$log", "$q", "surveyService", "modulesService"];
  angular
    .module('app.page-modules')
    .controller('MessageModuleController', MessageModuleController)

  /* @ngInject */
  function MessageModuleController(
    $scope,
    $log,
    $q,
    surveyService,
    modulesService
  ) {
    var vm = this
    var log = $log.create('MessageModuleController')

    var defaults = {
      type: 'feedback',
      pageId: vm.pageId,
      data: {
        title: undefined,
      },
    }

    vm.schema = modulesService.getSchema('feedback')
    vm.module = vm.module ? _.cloneDeep(vm.module) : defaults
    // TODO: hack as current BE sets module.data to a string
    if (_.isString(vm.module.data)) {
      vm.module.data = {
        title: vm.module.data,
      }
    }

    vm.save = save
    vm.remove = remove
    vm.close = close

    activate()

    function activate() {
      if (!vm.pageId) {
        vm.error = 'Something went wrong. Please try again.'
        vm.state = 'error'
        return log.error('message module settings requires pageId')
      }
      vm.state = 'ready'
    }

    function save() {
      vm.saving = true

      modulesService
        .save(vm.module)
        .then(function() {
          $scope.dialog.close({
            modify: true,
            module: vm.module,
          })
        })
        .catch(function(error) {
          vm.error = error
          log.error('error saving message module')
        })
        .finally(function() {
          vm.saving = false
        })
    }

    function remove() {
      vm.saving = true
      vm.error = null

      modulesService
        .remove(vm.module.id)
        .then(function() {
          $scope.dialog.close({
            modify: true,
            module: undefined,
          })
        })
        .catch(function(error) {
          vm.error = error
          log.error('error removing message module')
        })
        .finally(function() {
          vm.saving = false
        })
    }

    function close() {
      $scope.dialog.close()
    }
  }
})()
