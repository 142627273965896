;(function() {
  'use strict'

  Controller.$inject = ["$element"];
  angular
    .module('glow.reporting.charts')
    .component('questionChartResizer', Component())

  /* @ngInject */
  function Component() {
    return {
      controller: Controller,
      templateUrl: 'question-chart-resizer.html',
      transclude: true,
      bindings: {
        rotated: '<',
        key: '@',
      },
    }
  }

  var startSizeCache = {
    /* [key]: { width, height } */
  }

  /* @ngInject */
  function Controller($element) {
    var ctrl = this

    ctrl.$onChanges = onChanges
    ctrl.setRecommendedSize = setRecommendedSize
    ctrl.$onDestroy = onDestroy

    function onChanges(changed) {
      // if the key props changes we need to rebuild/recalculate sizing
      if (!changed.key) return

      ctrl.content = $element[0].querySelector(
        '.question-chart-resizer__content'
      )

      var startSize = startSizeCache[ctrl.key]
      setWidth(startSize ? startSize.width : null)
      setHeight(startSize ? startSize.height : null)

      ctrl.x = $element[0].querySelector('.question-chart-resizer__edge-x')
      ctrl.x.addEventListener('pointerdown', onXStart)
      ctrl.x.addEventListener('dblclick', onXReset)
      ctrl.y = $element[0].querySelector('.question-chart-resizer__edge-y')
      ctrl.y.addEventListener('pointerdown', onYStart)
      ctrl.y.addEventListener('dblclick', onYReset)
    }

    function setWidth(value, animate, cb) {
      var width = ctrl.rotated ? '650px' : '100%'
      if (value) {
        width = value + 'px'
      }
      if (animate) {
        ctrl.content.style.transition = 'all 0.15s ease-out'
      }
      ctrl.content.style.width = width
      if (animate) {
        ctrl.content.addEventListener(
          'transitionend',
          function() {
            ctrl.content.style.transition = 'none'
            if (cb) cb()
          },
          { once: true }
        )
      } else {
        if (cb) cb()
      }
    }

    function setHeight(value, animate, cb) {
      var height = 380 + 'px'
      if (value) {
        height = value + 'px'
      }
      if (animate) {
        ctrl.content.style.transition = 'all 0.15s ease-out'
      }
      ctrl.content.style.height = height
      if (animate) {
        ctrl.content.addEventListener(
          'transitionend',
          function() {
            ctrl.content.style.transition = 'none'
            if (cb) cb()
          },
          { once: true }
        )
      } else {
        if (cb) cb()
      }
    }

    function onXStart(e) {
      ctrl.startWidth = ctrl.content.offsetWidth
      ctrl.xStart = e.clientX
      ctrl.x.style.cursor = 'grabbing'
      ctrl.x.setPointerCapture(e.pointerId)
      ctrl.x.addEventListener('pointermove', onXMove)
      ctrl.x.addEventListener('pointerup', onXEnd)
    }

    function onXMove(e) {
      var distance = e.clientX - ctrl.xStart
      var width = Math.max(ctrl.startWidth + distance * 2, 150)
      ctrl.content.style.width = width + 'px'
    }

    function onXEnd(e) {
      ctrl.x.style.cursor = ''
      ctrl.x.removeEventListener('pointermove', onXMove)
      ctrl.x.removeEventListener('pointerup', onXEnd)
      ctrl.x.releasePointerCapture(e.pointerId)
      storeStartSize()
    }

    function onYStart(e) {
      ctrl.startHeight = ctrl.content.offsetHeight
      ctrl.yStart = e.clientY
      ctrl.y.style.cursor = 'grabbing'
      ctrl.y.setPointerCapture(e.pointerId)
      ctrl.y.addEventListener('pointermove', onYMove)
      ctrl.y.addEventListener('pointerup', onYEnd)
    }

    function onYMove(e) {
      var distance = e.clientY - ctrl.yStart
      var width = Math.max(ctrl.startHeight + distance, 150)
      ctrl.content.style.height = width + 'px'
    }

    function onYEnd(e) {
      ctrl.y.style.cursor = ''
      ctrl.y.removeEventListener('pointermove', onYMove)
      ctrl.y.removeEventListener('pointerup', onYEnd)
      ctrl.y.releasePointerCapture(e.pointerId)
      storeStartSize()
    }

    function onXReset(e) {
      var size = ctrl.recommendedSize
      setWidth(size ? size.width : null, true, function() {
        storeStartSize()
      })
    }

    function onYReset(e) {
      var size = ctrl.recommendedSize
      setHeight(size ? size.height : null, true, function() {
        storeStartSize()
      })
    }

    function storeStartSize() {
      var startSize = startSizeCache[ctrl.key]
      if (!startSize) {
        startSize = startSizeCache[ctrl.key] = {}
      }
      startSize.width = ctrl.content.offsetWidth
      startSize.height = ctrl.content.offsetHeight
    }

    function setRecommendedSize(size) {
      // the chart itself calculates what the best width
      // to display is and calls this method.
      // if the chart hasn't been manually resized, we'll
      // use this width.
      ctrl.recommendedSize = size
      var startSize = startSizeCache[ctrl.key]
      if (startSize) return
      if (size.width) ctrl.content.style.width = size.width + 'px'
      if (size.height) ctrl.content.style.height = size.height + 'px'
    }

    function removeListeners() {
      ctrl.x.removeEventListener('pointerdown', onXStart)
      ctrl.x.removeEventListener('dblclick', onXReset)
      ctrl.y.removeEventListener('pointerdown', onYStart)
      ctrl.y.removeEventListener('dblclick', onYReset)
    }

    function onDestroy() {
      removeListeners()
    }
  }
})()
