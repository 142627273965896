;(function() {
  'use strict'

  Directive.$inject = ["$transitions", "$animate", "$window", "$document", "glTips", "glUtils", "$timeout"];
  angular.module('core.tips').directive('glTip', Directive)

  /* @ngInject */
  function Directive(
    $transitions,
    $animate,
    $window,
    $document,
    glTips,
    glUtils,
    $timeout
  ) {
    return {
      restrict: 'E',
      link: PostLink,
      templateUrl: 'tip.template.html',
    }

    function PostLink(scope, elem) {
      var options = scope.options

      var onResizeThrottled
      var arrowElem
      var targetBounds
      var tipBounds
      var scrollY
      var pageHeight
      var windowWidth
      var windowHeight
      var arrowIsAbove
      var tipPos = {}

      scope.data = {}
      scope.hide = hide
      scope.cancel = cancel
      scope.done = done
      scope.destroy = destroy
      scope.close = close
      scope.onHideTipsChange = onHideTipsChange

      elem.addClass('tip')
      scope.$applyAsync(init)

      function init() {
        // create a throttled resize function
        onResizeThrottled = _.throttle(onResize, 100)

        // listen for any done events
        if (options.doneEvent) {
          scope.$on(options.doneEvent, done)
        }

        // wait for a start event or start immediately?
        if (options.startEvent) {
          scope.$on(options.startEvent, start)
        } else {
          start()
        }

        // cleanup on destroy
        scope.$on('$destroy', onDestroy)

        // destroy displayed tips if resetting
        scope.$on('tips:reset', destroy)
      }

      function start() {
        // attach to a target or center on the screen?
        if (options.target) {
          attach()
          scrollToTip()
        } else {
          center()
        }

        // hide on focus option
        if (options.hideFocus && options.target) {
          options.target.addEventListener('focus', hide)
        }

        // done on blur option
        if (options.doneBlur && options.target) {
          options.target.addEventListener('blur', done)
        }

        // done on click option
        if (options.doneClick && options.target) {
          options.target.addEventListener('click', done)
        }

        // listen for any hide events and then hide
        if (options.hideEvent) {
          scope.$on(options.hideEvent, hide)
        }

        // listen for any state changes
        $transitions.onStart(null, onStateChange)

        // reposition on window resize
        $window.addEventListener('resize', onResizeThrottled)

        // fade in and show the tip
        show()
      }

      function show() {
        elem.css({ display: 'block' })
        return $animate.addClass(elem, 'visible')
      }

      function hide() {
        return $animate.removeClass(elem, 'visible').then(function() {
          elem.css({ display: 'none' })
        })
      }

      function center() {
        // get dimensions for all the things
        arrowElem = angular.element(elem[0].getElementsByClassName('tip-arrow'))
        tipBounds = elem[0].getBoundingClientRect()
        scrollY = $window.pageYOffset
        pageHeight = $document[0].body.scrollHeight
        windowWidth = $window.innerWidth
        windowHeight = $window.innerHeight

        // center fixed in window
        elem.css({
          top: windowHeight / 2 - tipBounds.height / 2 + 'px',
          left: windowWidth / 2 - tipBounds.width / 2 + 'px',
          position: 'fixed',
        })

        // no arrow
        arrowElem.css({ display: 'none' })
      }

      function attach() {
        // get dimensions for all the things
        arrowElem = angular.element(elem[0].getElementsByClassName('tip-arrow'))
        targetBounds = options.target.getBoundingClientRect()
        tipBounds = elem[0].getBoundingClientRect()
        scrollY = $window.pageYOffset
        pageHeight = $document[0].body.scrollHeight
        windowWidth = $window.innerWidth

        // force width style to bounds width to prevent collapsing
        elem.css({ width: tipBounds.width + 'px' })

        // make fixed if specified
        if (options.fixed) {
          elem.css({ position: 'fixed' })
        }

        // give z-index if specified
        if (options.zIndex) {
          elem.css({ zIndex: options.zIndex })
        }

        // console.log('targetBounds', targetBounds);
        // console.log('tipBounds', tipBounds);
        // console.log('scrollY', scrollY);
        // console.log('pageHeight', pageHeight);
        // console.log('windowWidth', windowWidth);

        positionHorizontally()
        positionVertically()
        positionPointer()
      }

      function positionHorizontally() {
        // start by centering
        tipPos.x =
          targetBounds.left + targetBounds.width / 2 - tipBounds.width / 2

        // ensure it doesn't go off the left/right edges
        tipPos.x = _.clamp(tipPos.x, 3, windowWidth - tipBounds.width - 3)

        // update position
        elem.css({ left: tipPos.x + 'px' })
      }

      function positionVertically() {
        // recalculate tipBounds (horizontally position could have changed it)
        tipBounds = elem[0].getBoundingClientRect()

        // prefer to put above target
        if (targetBounds.top >= tipBounds.height) {
          tipPos.y = targetBounds.top + scrollY - tipBounds.height - 15
          arrowIsAbove = false

          // otherwise try below
        } else if (pageHeight - targetBounds.bottom >= tipBounds.height) {
          tipPos.y = targetBounds.top + targetBounds.height + scrollY + 15
          arrowIsAbove = true

          // if it doesnt fit anywhere, just go above
        } else {
          tipPos.y = targetBounds.top + scrollY - tipBounds.height - 15
          arrowIsAbove = false
        }

        // update position
        elem.css({ top: tipPos.y + 'px' })
      }

      function positionPointer() {
        // add class for above/below
        arrowElem.addClass(arrowIsAbove ? 'tip-arrow-above' : 'tip-arrow-below')

        // move pointer horizontally to be right above element
        var left = targetBounds.left + targetBounds.width / 2 - tipPos.x

        // ensure it doesnt go past the edges
        left = _.clamp(left, 12, tipBounds.width - 12)

        // update position
        arrowElem.css({ left: left + 'px' })
      }

      function scrollToTip() {
        // only scroll if its off the screen
        if (!isVisible()) {
          glUtils.scrollToElementAnimated(elem[0])
        }
      }

      function onStateChange(trans) {
        var toState = trans.to()

        // if the tip has a done state and we are going there - mark it as done!
        if (options.doneState && options.doneState === toState.name) {
          done(true)

          // otherwise destroy myself
        } else {
          destroy()
        }
      }

      function destroy() {
        // animate fade-out and then destroy myself
        return hide().then(function() {
          scope.$destroy()
          elem.remove()
        })
      }

      function cancel(allPages) {
        // tell tips service to ignore some/all tips
        glTips.ignore(allPages)

        // destroy myself
        destroy()
      }

      function done(routeDidChange) {
        // mark this tip as done
        glTips.markAsDone(options.id)

        if (scope.data.hideTips) {
          // Hide tips on this page
          cancel()
        } else {
          // destroy myself - and if the route didn't change, show the next tip
          destroy().then(function() {
            if (routeDidChange !== true) {
              glTips.next()
            }
          })
        }
      }

      function onHideTipsChange() {
        // HACK: adding this timeout to force the attach
        $timeout(attach, 0)
      }

      function onResize() {
        // recalc position the same way as `start()`
        if (options.target) {
          scope.$applyAsync(attach)
        } else {
          scope.$applyAsync(center)
        }
      }

      function onDestroy() {
        // cleanup listeners

        if (options.hideFocus && options.target) {
          options.target.removeEventListener('focus', hide)
        }

        if (options.doneBlur && options.target) {
          options.target.removeEventListener('blur', done)
        }

        if (options.doneClick && options.target) {
          options.target.removeEventListener('click', done)
        }

        $window.removeEventListener('resize', onResizeThrottled)
      }

      function isVisible() {
        var target = elem[0].getBoundingClientRect()
        var viewHeight = Math.max(
          $document[0].documentElement.clientHeight,
          $window.innerHeight
        )
        return (
          target.bottom >= 0 &&
          target.bottom <= viewHeight &&
          (target.top >= 0 && target.top <= viewHeight)
        )
      }
    }
  }
})()
