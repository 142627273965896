;(function() {
  'use strict'

  angular.module('app.core').component('gFormFooter', {
    controller: Controller,
    templateUrl: 'g-form-footer.html',
    bindings: {
      step: '<',
      steps: '<',
      backLabel: '@',
      nextLabel: '@',
      disableBack: '<',
      disableNext: '<',
      hideBack: '<',
      hideNext: '<',
      spinner: '<',
      onBack: '&?',
      onNext: '&?',
    },
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onChanges = onChanges

    function onChanges() {
      ctrl.hasProgress = ctrl.step && ctrl.steps
      ctrl.progress = getProgress()
    }

    function getProgress() {
      if (!ctrl.step || !ctrl.steps) {
        return
      }

      var progress = new Array(ctrl.steps)
      _.fill(progress, '-empty', 0, ctrl.steps)
      _.fill(progress, '-full', 0, ctrl.step)
      return progress
    }
  }
})()
