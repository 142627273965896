;(function() {
  'use strict'

  Controller.$inject = ["projectService", "uacService"];
  angular.module('app.projects').component('projectCard', {
    controller: Controller,
    templateUrl: 'project-card.html',
    bindings: {
      project: '<',
      onClick: '&?',
      onArchive: '&',
      onUnarchive: '&',
    },
  })

  /* @ngInject */
  function Controller(projectService, uacService) {
    var ctrl = this

    ctrl.$onChanges = onChanges
    ctrl.edit = edit

    function onChanges() {
      var info = ''
      var surveyCount = ctrl.project.surveyCount
      switch (surveyCount) {
        case 0:
          info += 'No Surveys'
          break
        case 1:
          info += '1 Survey'
          break
        default:
          info += surveyCount + ' Surveys'
          break
      }
      if (surveyCount > 0) {
        var responseCount = ctrl.project.responseCount
        switch (responseCount) {
          case 0:
            info += ' (No Responses)'
            break
          case 1:
            info += ' (1 Response)'
            break
          default:
            info += ' (' + responseCount + ' Responses)'
            break
        }
      }
      ctrl.info = info
    }

    function edit() {
      if (!uacService.canManageProjects(true)) return
      projectService.edit(ctrl.project)
    }
  }
})()
