;(function() {
  'use strict'

  CreatePageController.$inject = ["$log", "$state", "pagesService", "glToast", "subscriberService"];
  angular
    .module('app.create-page')
    .controller('CreatePageController', CreatePageController)

  /* @ngInject */
  function CreatePageController(
    $log,
    $state,
    pagesService,
    glToast,
    subscriberService
  ) {
    $log = $log.create('createPageController')

    var vm = this
    var subscriber = subscriberService.getSubscriber()

    // set new page defaults
    vm.page = {
      featureImageUrl: subscriber.portalTheme.featureImageUrl,
      logoUrl: subscriber.portalTheme.logoImageUrl,
      themeColor: subscriber.portalTheme.color,
      subTitle: null,
      visibleInDirectory: true,
      hashTags: [],
    }

    // methods
    vm.createPage = createPage
    vm.onLogoChange = onLogoChange
    vm.onFeatureChange = onFeatureChange
    vm.onFileError = onFileError

    /**
     * @name createPage
     * @description create page based on form data
     */
    function createPage() {
      vm.saving = true
      pagesService
        .create(vm.page)
        .then(function(pageId) {
          $state.go('page', { pageId: pageId })
        })
        .catch(function(message) {
          glToast.action('Page could not be saved.', 'retry', createPage)
          $log.error('could not create page.', message)
        })
        .finally(function() {
          vm.saving = false
        })
    }

    /**
     * @name onLogoChange
     * @param {String} imageData raw image data
     * @description set logoUrl on input change
     */
    function onLogoChange(imageData) {
      vm.page.logoData = imageData
    }

    /**
     * @name onFeatureChange
     * @param {String} imageData raw image data
     * @description set featureImageUrl on input change
     */
    function onFeatureChange(imageData) {
      vm.page.featureImageData = imageData
    }

    /**
     * @name onFileError
     * @param {String} message error message returned from the input imput file upload directive
     */
    function onFileError(message) {
      glToast.show(message)
    }
  }
})()
