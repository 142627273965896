;(function() {
  'use strict'

  angular.module('app.core').directive('glScrollClass', Directive)

  /* @ngInject */
  function Directive() {
    return {
      restrict: 'A',
      link: PostLink,
    }

    function PostLink(scope, elem, attrs) {
      var scrollClass = attrs.glScrollClass

      elem.on('scroll', _.throttle(checkClass, 100))
      scope.$watch(checkClass)

      function checkClass() {
        if (elem[0].scrollHeight > elem[0].clientHeight) {
          elem.addClass(scrollClass)

          if (elem[0].scrollTop > 0) {
            elem.addClass(scrollClass + '-top')
          } else {
            elem.removeClass(scrollClass + '-top')
          }

          if (elem[0].scrollTop + elem[0].clientHeight < elem[0].scrollHeight) {
            elem.addClass(scrollClass + '-bottom')
          } else {
            elem.removeClass(scrollClass + '-bottom')
          }
        } else {
          elem.removeClass(scrollClass)
          elem.removeClass(scrollClass + '-top')
          elem.removeClass(scrollClass + '-bottom')
        }
      }
    }
  }
})()
