;(function() {
  'use strict'

  Config.$inject = ["$stateProvider"];
  angular.module('app.password-reset', []).config(Config)

  /* @ngInject */
  function Config($stateProvider) {
    $stateProvider.state('passwordReset', {
      url: '/password-reset/:token',
      params: {
        token: '',
      },
      templateUrl: 'password-reset.html',
      controller: 'PasswordResetController',
      controllerAs: '$ctrl',
      title: 'Reset Password',
      public: true,
    })
  }
})()
