;(function() {
  'use strict'

  angular.module('app.core').component('surveyTemplateInstructions', {
    controller: Controller,
    templateUrl: 'survey-template-instructions.html',
    bindings: {
      templateName: '@',
      onClose: '&',
    },
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.close = close

    function close() {
      ctrl.onClose({ $dontShowAgain: ctrl.dontShowAgain })
    }
  }
})()
