/* eslint-disable no-unused-vars, no-redeclare */
;(function() {
  'use strict'

  configCloud.$inject = ["$provide"];
  wordcloudDirective.$inject = ["$window", "d3", "$filter", "$timeout"];
  d3WorldCloudDecorator.$inject = ["$delegate"];
  angular
    .module('core.wordcloud')
    .config(configCloud)
    .directive('wordcloud', wordcloudDirective)

  /* @ngInject */
  function configCloud($provide) {
    $provide.decorator('d3', d3WorldCloudDecorator)
  }

  /* @ngInject */
  function wordcloudDirective($window, d3, $filter, $timeout) {
    return {
      restrict: 'E',
      scope: {
        filter: '@',
        data: '=',
        preProcessedData: '=?',
        questionId: '=?',
        onDraw: '&',
        ignoreWords: '=',
        allowChartClick: '=',
        onClick: '&',
      },
      link: function postLink(scope, element) {
        var width
        var height

        var fontFamily = 'Roboto'

        var minFontSize
        var maxFontSize
        var maxFrequency
        var maxWords
        var words

        // var resizeTimer;
        // var orangeColors = ['#faa41a', '#f47421'];
        var greyColors = ['#636363', '#969696', '#bdbdbd', '#d9d9d9']

        $timeout(init, 10)

        function init() {
          setSize()
          makeResponsive()
          checkConfig()
          calcFontSizes()
          scope.onDraw = scope.onDraw || angular.noop

          scope.$watch('data', onData)
        }

        function setSize() {
          width = element[0].parentNode.clientWidth
          height = element[0].parentNode.clientHeight

          // TODO get a more consistent container to put the cloud in.
          height = height < 10 ? 450 : height
          width = width < 10 ? element[0].parentNode.offsetWidth : width
          width = width < 10 ? 450 : width
        }

        function makeResponsive() {
          angular.element($window).on(
            'resize',
            _.debounce(function() {
              setSize()
              onData()
            }, 400)
          )
        }

        function calcFontSizes() {
          // Font Lerper (By Ash)
          // based on 2 tested spacial universes
          var space = (width * height) / 1000 // a space factor
          // based on a space of 1000x333px
          var largeSpace = 333
          var largeMaxSize = 80
          var largeMinSize = 30
          var largeWords = 80
          // based on a space of 290x200px
          var smallSpace = 58
          var smallMaxSize = 35
          var smallMinSize = 20
          var smallWords = 40

          var maxFactor = space / largeSpace
          maxFontSize = maxFactor * (largeMaxSize - largeMinSize) + largeMinSize

          var minFactor = smallSpace / space
          minFontSize = minFactor * (smallMaxSize - smallMinSize) + smallMinSize

          var wordFactor = space / largeSpace
          maxWords = wordFactor * (largeWords - smallWords) + smallWords
        }

        function checkConfig() {
          if (!scope.filter) {
            throw new Error('No filter defined')
          }
        }

        function onData() {
          var newData = scope.data
          cleanup()
          if (scope.preProcessedData === true) {
            words = $filter(scope.filter)(
              newData,
              maxWords,
              scope.ignoreWords,
              scope.questionId
            )
            make()
          } else {
            if (newData && newData.length > 0 && angular.isArray(newData)) {
              words = $filter(scope.filter)(
                newData,
                maxWords,
                scope.ignoreWords
              )
              make()
            } else {
              scope.onDraw(0)
            }
          }
        }

        function make() {
          var max = _.maxBy(words, 'total')
          maxFrequency = max ? max.total : 0
          cloudFactory(words)
        }

        function fill() {
          // (d, i)
          return greyColors[Math.floor(Math.random() * greyColors.length)]

          // EXPERIMENT: large words have orange variant, others grey
          // var colors = d.size === maxFontSize ? orangeColors : greyColors;
          // return colors[Math.floor(Math.random() * colors.length)];

          // EXPERIMENT: hues of orange based on text size
          // var minOpacity = 0.1;
          // var workingOpacity = (1 - minOpacity); // 0.6
          // var factor = d.size / maxFontSize; // eg 50/50=1 25/50=0.5
          // var preOpacity = workingOpacity * factor; // eg 0.6 0.3
          // var opacity = preOpacity + minOpacity;
          // var color = 'rgba(250, 164, 26, ' + opacity + ')';
          // return color;
        }

        function calcFontSize(freq) {
          return Math.max(minFontSize, (maxFontSize * freq) / maxFrequency)
        }

        function cleanup() {
          d3.select(element[0])
            .selectAll('*')
            .remove()
        }

        // CloudFactory - so high in the sky ;)
        // Keep the anonym functions here for readability
        function cloudFactory(words) {
          // TODO: Add fill Function Binding for own function
          // var fill = d3.scale.category20();

          d3.layout
            .cloud()
            .size([width, height])
            .words(
              words.map(function(d) {
                // return {text: d.word, size: Math.random() * fontSize};
                return { text: d.word, size: calcFontSize(d.total) }
              })
            )
            // .rotate(function() { return Math.floor(Math.random() * 2) * -90; })
            .rotate(function() {
              return 0
            })
            .font(fontFamily)
            .padding(5)
            .fontWeight('bold')
            .fontSize(function(d) {
              return parseInt(d.size)
            })
            .on('end', draw)
            .start()

          function draw(words) {
            var numWords = _.size(words)
            scope.onDraw({ numWords: numWords })

            // Center the drawing
            var heightTranslate = height / 2
            var widthTranslate = width / 2
            var rootElement = element[0]

            // var selected;

            d3.select(rootElement)
              .append('svg')
              .attr('width', width)
              .attr('height', height)
              .append('g')
              .attr(
                'transform',
                'translate(' + widthTranslate + ',' + heightTranslate + ')'
              ) // Translate to center
              .selectAll('text')
              .data(words)
              .enter()
              .append('text')
              .style('font-size', function(d) {
                return d.size + 'px'
              })
              // .style('font-size', function (d) { return d.size + 'em'; })
              .style('font-family', fontFamily)
              // .style('font-weight', 'bold')
              .style('fill', function(d, i) {
                return fill(d, i)
              })
              .attr('text-anchor', 'middle')
              .attr('transform', function(d) {
                return 'translate(' + [d.x, d.y] + ')'
              })
              .text(function(d) {
                return d.text
              })
              .on('click', function(d) {
                if (scope.allowChartClick) {
                  scope.onClick({ $value: d.text })
                }
                // d3.selectAll('text').classed('active', false);
                // if (selected !== d.text) {
                //     scope.onClick({ element: d });
                //     d3.select(this).classed('active', true);
                //     selected = d.text;
                // } else {
                //     scope.onClick({ element: { text: null } });
                //     selected = null;
                // }
              })
          }
        }
      },
    }
  }

  /* @ngInject */
  function d3WorldCloudDecorator($delegate) {
    // Word cloud layout by Jason Davies, http://www.jasondavies.com/word-cloud/
    // Algorithm due to Jonathan Feinberg, http://static.mrfeinberg.com/bv_ch03.pdf
    var d3 = $delegate
    ;(function(exports) {
      function cloud() {
        var size = [256, 256],
          text = cloudText,
          font = cloudFont,
          fontSize = cloudFontSize,
          fontStyle = cloudFontNormal,
          fontWeight = cloudFontNormal,
          rotate = cloudRotate,
          padding = cloudPadding,
          spiral = archimedeanSpiral,
          words = [],
          timeInterval = Infinity,
          event = d3.dispatch('word', 'end'),
          timer = null,
          cloud = {}

        cloud.start = function() {
          var board = zeroArray((size[0] >> 5) * size[1]),
            bounds = null,
            n = words.length,
            i = -1,
            tags = [],
            data = words
              .map(function(d, i) {
                d.text = text.call(this, d, i)
                d.font = font.call(this, d, i)
                d.style = fontStyle.call(this, d, i)
                d.weight = fontWeight.call(this, d, i)
                d.rotate = rotate.call(this, d, i)
                d.size = ~~fontSize.call(this, d, i)
                d.padding = cloudPadding.call(this, d, i)
                return d
              })
              .sort(function(a, b) {
                return b.size - a.size
              })

          if (timer) clearInterval(timer)
          timer = setInterval(step, 0)
          step()

          return cloud

          function step() {
            var start = +new Date(),
              d
            while (+new Date() - start < timeInterval && ++i < n && timer) {
              d = data[i]
              d.x = (size[0] * (Math.random() + 0.5)) >> 1
              d.y = (size[1] * (Math.random() + 0.5)) >> 1
              cloudSprite(d, data, i)
              if (place(board, d, bounds)) {
                tags.push(d)
                event.call('word', null, d)
                if (bounds) cloudBounds(bounds, d)
                else
                  bounds = [
                    { x: d.x + d.x0, y: d.y + d.y0 },
                    { x: d.x + d.x1, y: d.y + d.y1 },
                  ]
                // Temporary hack
                d.x -= size[0] >> 1
                d.y -= size[1] >> 1
              }
            }
            if (i >= n) {
              cloud.stop()
              event.call('end', null, tags, bounds)
            }
          }
        }

        cloud.stop = function() {
          if (timer) {
            clearInterval(timer)
            timer = null
          }
          return cloud
        }

        cloud.timeInterval = function(x) {
          if (!arguments.length) return timeInterval
          timeInterval = x == null ? Infinity : x
          return cloud
        }

        function place(board, tag, bounds) {
          var perimeter = [{ x: 0, y: 0 }, { x: size[0], y: size[1] }],
            startX = tag.x,
            startY = tag.y,
            maxDelta = Math.sqrt(size[0] * size[0] + size[1] * size[1]),
            s = spiral(size),
            dt = Math.random() < 0.5 ? 1 : -1,
            t = -dt,
            dxdy,
            dx,
            dy

          while ((dxdy = s((t += dt)))) {
            dx = ~~dxdy[0]
            dy = ~~dxdy[1]

            if (Math.min(dx, dy) > maxDelta) break

            tag.x = startX + dx
            tag.y = startY + dy

            if (
              tag.x + tag.x0 < 0 ||
              tag.y + tag.y0 < 0 ||
              tag.x + tag.x1 > size[0] ||
              tag.y + tag.y1 > size[1]
            )
              continue
            // TODO only check for collisions within current bounds.
            if (!bounds || !cloudCollide(tag, board, size[0])) {
              if (!bounds || collideRects(tag, bounds)) {
                var sprite = tag.sprite,
                  w = tag.width >> 5,
                  sw = size[0] >> 5,
                  lx = tag.x - (w << 4),
                  sx = lx & 0x7f,
                  msx = 32 - sx,
                  h = tag.y1 - tag.y0,
                  x = (tag.y + tag.y0) * sw + (lx >> 5),
                  last
                for (var j = 0; j < h; j++) {
                  last = 0
                  for (var i = 0; i <= w; i++) {
                    board[x + i] |=
                      (last << msx) |
                      (i < w ? (last = sprite[j * w + i]) >>> sx : 0)
                  }
                  x += sw
                }
                delete tag.sprite
                return true
              }
            }
          }
          return false
        }

        cloud.words = function(x) {
          if (!arguments.length) return words
          words = x
          return cloud
        }

        cloud.size = function(x) {
          if (!arguments.length) return size
          size = [+x[0], +x[1]]
          return cloud
        }

        cloud.font = function(x) {
          if (!arguments.length) return font
          font = functor(x)
          return cloud
        }

        cloud.fontStyle = function(x) {
          if (!arguments.length) return fontStyle
          fontStyle = functor(x)
          return cloud
        }

        cloud.fontWeight = function(x) {
          if (!arguments.length) return fontWeight
          fontWeight = functor(x)
          return cloud
        }

        cloud.rotate = function(x) {
          if (!arguments.length) return rotate
          rotate = functor(x)
          return cloud
        }

        cloud.text = function(x) {
          if (!arguments.length) return text
          text = functor(x)
          return cloud
        }

        cloud.spiral = function(x) {
          if (!arguments.length) return spiral
          spiral = spirals[x + ''] || x
          return cloud
        }

        cloud.fontSize = function(x) {
          if (!arguments.length) return fontSize
          fontSize = functor(x)
          return cloud
        }

        cloud.padding = function(x) {
          if (!arguments.length) return padding
          padding = functor(x)
          return cloud
        }

        return rebind(cloud, event, 'on')
      }

      // this used to be d3.rebind but they removed it in v4
      // https://stackoverflow.com/questions/47844765/d3-rebind-in-d3-v4
      function rebind(target, source) {
        var i = 1,
          n = arguments.length,
          method
        while (++i < n)
          target[(method = arguments[i])] = d3_rebind(
            target,
            source,
            source[method]
          )
        return target
      }

      function d3_rebind(target, source, method) {
        return function() {
          var value = method.apply(source, arguments)
          return value === source ? target : value
        }
      }

      // this used to be d3.functor but they removed it v4
      // https://groups.google.com/forum/#!topic/d3-js/86_jy82NNSY
      function functor(v) {
        return typeof v === 'function'
          ? v
          : function() {
              return v
            }
      }

      function cloudText(d) {
        return d.text
      }

      function cloudFont() {
        return 'serif'
      }

      function cloudFontNormal() {
        return 'normal'
      }

      function cloudFontSize(d) {
        return Math.sqrt(d.value)
      }

      function cloudRotate() {
        return (~~(Math.random() * 6) - 3) * 30
      }

      function cloudPadding() {
        return 1
      }

      // Fetches a monochrome sprite bitmap for the specified text.
      // Load in batches for speed.
      function cloudSprite(d, data, di) {
        if (d.sprite) return
        c.clearRect(0, 0, (cw << 5) / ratio, ch / ratio)
        var x = 0,
          y = 0,
          maxh = 0,
          n = data.length
        di--
        while (++di < n) {
          d = data[di]
          c.save()
          c.font =
            d.style +
            ' ' +
            d.weight +
            ' ' +
            ~~((d.size + 1) / ratio) +
            'px ' +
            d.font
          var w = c.measureText(d.text + 'm').width * ratio,
            h = d.size << 1
          if (d.rotate) {
            var sr = Math.sin(d.rotate * cloudRadians),
              cr = Math.cos(d.rotate * cloudRadians),
              wcr = w * cr,
              wsr = w * sr,
              hcr = h * cr,
              hsr = h * sr
            w =
              ((Math.max(Math.abs(wcr + hsr), Math.abs(wcr - hsr)) + 0x1f) >>
                5) <<
              5
            h = ~~Math.max(Math.abs(wsr + hcr), Math.abs(wsr - hcr))
          } else {
            w = ((w + 0x1f) >> 5) << 5
          }
          if (h > maxh) maxh = h
          if (x + w >= cw << 5) {
            x = 0
            y += maxh
            maxh = 0
          }
          if (y + h >= ch) break
          c.translate((x + (w >> 1)) / ratio, (y + (h >> 1)) / ratio)
          if (d.rotate) c.rotate(d.rotate * cloudRadians)
          c.fillText(d.text, 0, 0)
          c.restore()
          d.width = w
          d.height = h
          d.xoff = x
          d.yoff = y
          d.x1 = w >> 1
          d.y1 = h >> 1
          d.x0 = -d.x1
          d.y0 = -d.y1
          x += w
        }
        var pixels = c.getImageData(0, 0, (cw << 5) / ratio, ch / ratio).data,
          sprite = []
        while (--di >= 0) {
          d = data[di]
          var w = d.width,
            w32 = w >> 5,
            h = d.y1 - d.y0,
            p = d.padding
          // Zero the buffer
          for (var i = 0; i < h * w32; i++) sprite[i] = 0
          x = d.xoff
          if (x == null) return
          y = d.yoff
          var seen = 0,
            seenRow = -1
          for (var j = 0; j < h; j++) {
            for (var i = 0; i < w; i++) {
              var k = w32 * j + (i >> 5),
                m = pixels[((y + j) * (cw << 5) + (x + i)) << 2]
                  ? 1 << (31 - (i % 32))
                  : 0
              if (p) {
                if (j) sprite[k - w32] |= m
                if (j < w - 1) sprite[k + w32] |= m
                m |= (m << 1) | (m >> 1)
              }
              sprite[k] |= m
              seen |= m
            }
            if (seen) seenRow = j
            else {
              d.y0++
              h--
              j--
              y++
            }
          }
          d.y1 = d.y0 + seenRow
          d.sprite = sprite.slice(0, (d.y1 - d.y0) * w32)
        }
      }

      // Use mask-based collision detection.
      function cloudCollide(tag, board, sw) {
        sw >>= 5
        var sprite = tag.sprite,
          w = tag.width >> 5,
          lx = tag.x - (w << 4),
          sx = lx & 0x7f,
          msx = 32 - sx,
          h = tag.y1 - tag.y0,
          x = (tag.y + tag.y0) * sw + (lx >> 5),
          last
        for (var j = 0; j < h; j++) {
          last = 0
          for (var i = 0; i <= w; i++) {
            if (
              ((last << msx) |
                (i < w ? (last = sprite[j * w + i]) >>> sx : 0)) &
              board[x + i]
            )
              return true
          }
          x += sw
        }
        return false
      }

      function cloudBounds(bounds, d) {
        var b0 = bounds[0],
          b1 = bounds[1]
        if (d.x + d.x0 < b0.x) b0.x = d.x + d.x0
        if (d.y + d.y0 < b0.y) b0.y = d.y + d.y0
        if (d.x + d.x1 > b1.x) b1.x = d.x + d.x1
        if (d.y + d.y1 > b1.y) b1.y = d.y + d.y1
      }

      function collideRects(a, b) {
        return (
          a.x + a.x1 > b[0].x &&
          a.x + a.x0 < b[1].x &&
          a.y + a.y1 > b[0].y &&
          a.y + a.y0 < b[1].y
        )
      }

      function archimedeanSpiral(size) {
        var e = size[0] / size[1]
        return function(t) {
          return [e * (t *= 0.1) * Math.cos(t), t * Math.sin(t)]
        }
      }

      function rectangularSpiral(size) {
        var dy = 4,
          dx = (dy * size[0]) / size[1],
          x = 0,
          y = 0
        return function(t) {
          var sign = t < 0 ? -1 : 1
          // See triangular numbers: T_n = n * (n + 1) / 2.
          switch ((Math.sqrt(1 + 4 * sign * t) - sign) & 3) {
            case 0:
              x += dx
              break
            case 1:
              y += dy
              break
            case 2:
              x -= dx
              break
            default:
              y -= dy
              break
          }
          return [x, y]
        }
      }

      // TODO reuse arrays?
      function zeroArray(n) {
        var a = [],
          i = -1
        while (++i < n) a[i] = 0
        return a
      }

      var cloudRadians = Math.PI / 180,
        cw = (1 << 11) >> 5,
        ch = 1 << 11,
        canvas,
        ratio = 1

      if (typeof document !== 'undefined') {
        canvas = document.createElement('canvas')
        canvas.width = 1
        canvas.height = 1
        ratio = Math.sqrt(
          canvas.getContext('2d').getImageData(0, 0, 1, 1).data.length >> 2
        )
        canvas.width = (cw << 5) / ratio
        canvas.height = ch / ratio
      } else {
        // node-canvas support
        var Canvas = require('canvas')
        canvas = new Canvas(cw << 5, ch)
      }

      var c = canvas.getContext('2d'),
        spirals = {
          archimedean: archimedeanSpiral,
          rectangular: rectangularSpiral,
        }
      c.fillStyle = 'red'
      c.textAlign = 'center'

      exports.cloud = cloud
    })(typeof exports === 'undefined' ? d3.layout || (d3.layout = {}) : exports)
    return d3
  }
})()
