;(function() {
  'use strict'

  Controller.$inject = ["$rootScope", "glUtils", "Publication", "subscriberService", "featureService"];
  angular.module('app.core').component('publicationOffer', {
    controller: Controller,
    templateUrl: 'publication-offer.html',
    bindings: {
      // ...
    },
  })

  var hidden = false // in-memory across component

  /* @ngInject */
  function Controller(
    $rootScope,
    glUtils,
    Publication,
    subscriberService,
    featureService
  ) {
    var ctrl = this

    ctrl.Types = Publication.Types

    ctrl.$onInit = onInit
    ctrl.hasOffer = hasOffer
    ctrl.getText = getText
    ctrl.hide = hide

    function onInit() {
      $rootScope.$on(subscriberService.Events.CHANGE, update)
      update()
      ctrl.hidden = hidden
    }

    function update() {
      ctrl.subscriber = subscriberService.getSubscriber()
    }

    function hasOffer() {
      if (!ctrl.subscriber) {
        // 1 free when you sign up
        return true
      }
      if (featureService.canAddPremiumPublications()) {
        // nope!
        return false
      }
      // only if you have tokens
      return ctrl.subscriber.premiumTokens.length > 0
    }

    function getText() {
      if (!ctrl.subscriber) {
        return 'Get a Premium report free when you sign up.'
      }
      var tokens = ctrl.subscriber.premiumTokens.length
      return glUtils.numberSwitch(
        tokens,
        'You have 1 free Premium report remaining.',
        'You have ' + tokens + ' free Premium reports remaining.'
      )
    }

    function hide() {
      ctrl.hidden = hidden = true
    }
  }
})()
