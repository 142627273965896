;(function() {
  'use strict'

  Controller.$inject = ["$rootScope", "$scope", "reportTable", "responseTypeService"];
  angular
    .module('glow.reporting.charts')
    .component('glReportTableText', Component())

  /* @ngInject */
  function Component() {
    return {
      controller: Controller,
      templateUrl: 'report-table-text.template.html',
      bindings: {
        survey: '<',
        questionId: '<',
        filterSetGroup: '<',
        loopKeyId: '<',
        maxHeight: '<',
        height: '=',
        allowRowClick: '=',
        onRowFocus: '&',
        onRowClick: '&?',
      },
    }
  }

  /* @ngInject */
  function Controller($rootScope, $scope, reportTable, responseTypeService) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.$onChanges = onChanges

    function onInit() {
      $scope.$on('survey-reporting:load-end', load)
    }

    function onChanges(changes) {
      if (
        changes.questionId &&
        changes.questionId.currentValue !== changes.questionId.previousValue
      ) {
        load()
      }
    }

    function load() {
      ctrl.data = null
      reportTable
        .getFromTextAnswers(
          ctrl.survey,
          ctrl.questionId,
          ctrl.filterSetGroup.getSelected(),
          ctrl.filterSetGroup.getOperator(),
          responseTypeService.getSelected(),
          ctrl.loopKeyId
        )
        .then(function(data) {
          ctrl.data = data
          $rootScope.$broadcast('gl-masonry:update-hint')
        })
    }
  }
})()
