;(function() {
  'use strict'

  Controller.$inject = ["publisherService", "glDialog"];
  angular.module('app.core').component('publisherSubscribeBtn', {
    controller: Controller,
    templateUrl: 'publisher-subscribe-btn.html',
    bindings: {
      publisher: '<',
      block: '<',
    },
  })

  /* @ngInject */
  function Controller(publisherService, glDialog) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.toggle = toggle

    function onInit() {
      // ...
    }

    function toggle() {
      if (ctrl.publisher.isSubscribed) {
        glDialog
          .confirm(
            'Unsubscribe',
            'Are you sure you want unsubscribe from this publisher? You will no longer receive updates when they publish new content.'
          )
          .then(function() {
            ctrl.loading = true
            publisherService
              .unsubscribeFrom(ctrl.publisher)
              .finally(function() {
                ctrl.loading = false
              })
          })
      } else {
        ctrl.loading = true
        publisherService.subscribeTo(ctrl.publisher).finally(function() {
          ctrl.loading = false
        })
      }
    }
  }
})()
