;(function() {
  'use strict'

  pageLinksConfig.$inject = ["$stateProvider"];
  angular.module('app.page-links', []).config(pageLinksConfig)

  /* @ngInject */
  function pageLinksConfig($stateProvider) {
    $stateProvider.state('page.links', {
      url: '/links',
      templateUrl: 'page-links.template.html',
      controller: 'PageLinksController',
      controllerAs: 'pageLinks',
      title: 'Page Links',
    })
  }
})()
