;(function() {
  'use strict'

  config.$inject = ["$stateProvider"];
  angular.module('app.core').config(config)

  /* @ngInject */
  function config($stateProvider) {
    $stateProvider.state('publication', {
      url: '/publication/:id',
      parent: 'portal',
      templateUrl: 'publication.html',
      controller: 'PublicationController',
      controllerAs: '$ctrl',
      title: 'Report',
      public: true,
    })

    $stateProvider.state('insights-publication-deprecated', {
      url: '/insights/publication/:id',
      redirectTo: 'publication',
    })

    $stateProvider.state('pack-redirect', {
      url: '/packs/:id',
      redirectTo: 'publication',
    })

    $stateProvider.state('insights-pack-redirect', {
      url: '/insights/packs/:id',
      redirectTo: 'publication',
    })

    $stateProvider.state('pack-purchase-redirect', {
      url: '/packs/:id/purchase',
      redirectTo: 'publication',
    })
  }
})()
