;(function() {
  'use strict'

  Controller.$inject = ["LeastFill"];
  angular.module('app.core').component('channelTagInjection', {
    controller: Controller,
    templateUrl: 'channel-tag-injection.html',
    bindings: {
      channel: '<',
    },
  })

  /* @ngInject */
  function Controller(LeastFill) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.toggle = toggle
    ctrl.addLeastFill = addLeastFill
    ctrl.removeLeastFill = removeLeastFill
    ctrl.update = update

    function onInit() {
      // enable the switch if injectedTags OR leastFills not empty
      ctrl.enabled =
        ctrl.channel.injectedTags.length || ctrl.channel.leastFills.length
      update()
    }

    function toggle() {
      ctrl.enabled = !ctrl.enabled
      if (!ctrl.enabled) {
        ctrl.channel.injectedTags = []
        ctrl.channel.leastFills = []
      }
    }

    function addLeastFill() {
      ctrl.channel.leastFills.push(new LeastFill())
    }

    function removeLeastFill(leastFill) {
      _.remove(ctrl.channel.leastFills, leastFill)
    }

    function update() {
      populateTagOptions()
      populateAmountOptions()
    }

    function populateTagOptions() {
      ctrl.tagOptions = ctrl.channel.tags
        .filter(function(tag) {
          var leastFill = _.find(ctrl.channel.leastFills, function(data) {
            return _.includes(data.tags, tag.name)
          })
          var isInjected = _.includes(ctrl.channel.injectedTags, tag.name)
          return isInjected || !leastFill
        })
        .map(function(tag) {
          return { label: tag.name, value: tag.name }
        })

      _.each(ctrl.channel.leastFills, function(leastFill) {
        leastFill.tagOptions = ctrl.channel.tags
          .filter(function(tag) {
            var selected = _.find(ctrl.channel.leastFills, function(data) {
              return _.includes(data.tags, tag.name)
            })
            var isInjected = _.includes(ctrl.channel.injectedTags, tag.name)
            return !isInjected && (!selected || leastFill.id === selected.id)
          })
          .map(function(tag) {
            return { label: tag.name, value: tag.name }
          })
      })
    }

    function populateAmountOptions() {
      _.each(ctrl.channel.leastFills, function(leastFill) {
        leastFill.populateAmountOptions()
        leastFill.cleanUp()
      })
    }
  }
})()
