;(function() {
  'use strict'

  angular.module('core.services').service('renderService', Service)

  /* @ngInject */
  function Service() {
    var self = this

    self.stack = []
    self.queue = queue

    function queue(fn) {
      self.stack.push(fn)

      if (self.isProcessing) {
        return
      }
      self.isProcessing = true
      exhaust(function() {
        self.isProcessing = false
      })
    }

    function exhaust(done) {
      var fn = self.stack.shift()
      if (!fn) {
        return done()
      }
      setTimeout(function() {
        fn()
        setTimeout(function() {
          exhaust(done)
        }, 50)
      }, 50)
    }
  }
})()
