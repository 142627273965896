;(function() {
  'use strict'

  surveyService.$inject = ["$q", "$log", "api", "subscriberService", "surveysResource", "projectSurveysResource", "glSurveyUtils", "Survey"];
  angular.module('core.services').factory('surveyService', surveyService)

  /**
   * surveyService
   *
   * @ngInject */
  function surveyService(
    $q,
    $log,
    api,
    subscriberService,
    surveysResource,
    projectSurveysResource,
    glSurveyUtils,
    Survey
  ) {
    $log = $log.create('surveyService')

    var SeriesResolutions = {
      DAY: 'DAY',
      HOUR: 'HOUR',
    }

    var SeriesTypes = {
      SUBSCRIBER: 'subscriber',
      PROJECT: 'project',
      SURVEY: 'survey',
    }

    var KEY_FORMAT = 'DD MMM YYYY hA'

    var service = {
      get: get,
      getAsSurvey: getAsSurvey,
      getBySubscriber: getBySubscriber,
      getSummariesBySubscriber: getSummariesBySubscriber,
      getByProject: getByProject,
      getResponses: getResponses,
      getSeries: getSeries,
      sendViaEmail: sendViaEmail,
      deleteResponse: deleteResponse,
      batchDeleteResponses: batchDeleteResponses,
      order: order,
      SeriesResolutions: SeriesResolutions,
      SeriesTypes: SeriesTypes,
      KEY_FORMAT: KEY_FORMAT,
    }
    return service

    function order(surveys) {
      return _.orderBy(
        surveys,
        [
          function(survey) {
            return survey.activeChannelCount > 0
          },
          function(survey) {
            return moment(survey.updatedAt).unix()
          },
        ],
        ['desc', 'desc']
      )
    }

    function transformSurveys(surveys) {
      _.each(surveys, function(survey) {
        // TODO: is this necessary? It's done in the survey factory
        // glSurveyUtils.parseSandboxData(survey)

        // ensure questions array is in correct order
        survey.questions = _.orderBy(survey.questions, 'order')
      })

      return surveys
    }

    /**
     * @name getBySubscriber
     * @description Get all surveys for subscriber
     * @param {String} subscriberId subscriber ID
     * @param  {Object} options     query options
     * @return {promise}
     */
    function getBySubscriber(subscriberId, options) {
      var deferred = $q.defer()
      subscriberId = subscriberId || subscriberService.getSubscriber().id
      options = options || {}

      _.defaults(options, {
        subscriberId: subscriberId,
        filter: 'type.value ne QUICK',
      })

      // HACK: The surveys endpoint only returns 100 surveys, but
      // Coles OneShop has 758 surveys (experience surveys for each element)
      // TODO: Limit needs to be lifted or better thought out.
      options.top = 1000

      surveysResource
        .getBySubscriber(options)
        .success(function(surveys) {
          transformSurveys(surveys)
          deferred.resolve(surveys)
        })
        .error(deferred.reject)

      return deferred.promise
    }

    function getSummariesBySubscriber(subscriberId) {
      return api.surveys.getSummariesBySubscriber({
        subscriberId: subscriberId,
      })
    }

    /**
     * Get surveys of a project
     *
     * @param {!String} projectId The project id
     * @param {?Object} options Query options
     * @return {Promise}
     */
    function getByProject(projectId, options) {
      return $q(function(resolve, reject) {
        if (!projectId) {
          return reject(new Error('Missing project id.'))
        }
        options = options || {}
        options.projectId = projectId
        projectSurveysResource
          .get(options)
          .success(function(res, surveys) {
            transformSurveys(surveys)
            resolve(order(surveys))
          })
          .error(reject)
      })
    }

    /**
     * @name get
     * @description get survey by id
     * @param  {String} surveyId survey ID
     * @return {promise}
     */
    function get(surveyId) {
      return $q(function(resolve, reject) {
        surveysResource
          .get({ id: surveyId })
          .success(function(x, survey) {
            transformSurveys([survey])
            resolve(survey)
          })
          .error(function(error) {
            reject(error)
          })
      })
    }

    /**
     * @name getAsSurvey
     * @description get survey by id and return a survey proto
     * @param  {String} surveyId survey ID
     * @return {promise}
     */
    // TODO deprecate this when the 'get' method is replaced to return the proper survey __proto__
    function getAsSurvey(surveyId) {
      return $q(function(resolve, reject) {
        surveysResource
          .get({ id: surveyId })
          .success(function(x, data) {
            transformSurveys([data])
            var survey = new Survey().deserialize(data)
            resolve(survey)
          })
          .error(function() {
            reject()
          })
      })
    }

    // OBSOLETE
    // Will return 500 error
    // error: "Cannot use object of type Ei\Survey\Query\Write\SurveyResponseReport as array with code: 0"
    /**
     * @name getResponses
     * @description get survey responses
     * @param  {String} surveyId survey ID
     * @return {Promise}
     */
    function getResponses(surveyId, options) {
      return $q(function(resolve, reject) {
        _.assign((options = options || {}), {
          surveyId: surveyId,
          subscriberId: subscriberService.getSubscriber().id,
        })

        surveysResource
          .getSurveyResponseReport(options)
          .success(function(res) {
            // remove all <not-asked> values
            var NA = '<not-asked>'
            _.each(res, function(response) {
              _.remove(response.questions, function(question) {
                var shouldRemove =
                  !!_.find(question.choiceAnswers, { option: NA }) ||
                  question.textAnswer === NA
                return shouldRemove
              })
            })

            resolve(res)
          })
          .error(reject)
      })
    }

    function getSeries(options) {
      return $q(function(resolve, reject) {
        options = _.defaults(options, {
          type: null,
          typeId: null,
          start: null,
          end: null,
          resolution: null,
        })

        if (options.type === SeriesTypes.SUBSCRIBER) {
          options.typeId = subscriberService.getSubscriber().id
        }
        if (!options.type || !options.typeId) {
          $log.error('type and typeId required')
          return reject()
        }
        if (!options.start || !options.end) {
          $log.error('start and end date required')
          return reject()
        }
        options.start = options.start.toISOString()
        options.end = options.end.toISOString()

        options[options.type + 'Id'] = options.typeId
        delete options.type
        delete options.typeId

        surveysResource
          .getSurveyTimeSeriesReport(options)
          .success(function(x, response) {
            _.each(response, function(doc) {
              doc.date = moment(doc.timestamp)
              doc.key = doc.date.format(KEY_FORMAT)
            })
            resolve(response)
          })
          .error(function() {
            reject()
          })
      })
    }

    /**
     * @name sendViaEmail
     * @param  {Object} mailData email options object
     * @return {Promise}
     */
    function sendViaEmail(mailData) {
      var deferred = $q.defer()

      surveysResource
        .sendViaEmail(mailData)
        .success(deferred.resolve)
        .error(deferred.reject)

      return deferred.promise
    }

    /**
     * @name deleteResponse
     * @description deletes
     * @param  {String} responseId the id of the response to delete
     * @param  {String} reason the delete reason
     * @param  {String} text the delete text
     * @return {Promise}
     */
    function deleteResponse(responseId, reason, text) {
      var deferred = $q.defer()

      surveysResource
        .deleteResponse({ id: responseId, reason: reason, text: text })
        .success(deferred.resolve)
        .error(deferred.reject)

      return deferred.promise
    }

    /**
     * @name batchDeleteResponses
     * @description batch delete responses
     * @param  {Array} responseIds array of responses ids to delete
     * @param  {String} reason the delete reason
     * @param  {String} text the delete text
     * @return {Promise}
     */
    function batchDeleteResponses(responseIds, reason, text) {
      var deferred = $q.defer()

      surveysResource
        .batchDeleteResponses({
          responseIds: responseIds,
          reason: reason,
          text: text,
        })
        .success(deferred.resolve)
        .error(deferred.reject)

      return deferred.promise
    }
  }
})()
