;(function() {
  'use strict'

  Controller.$inject = ["$window", "$timeout", "surveyServiceNext", "surveyTemplateService"];
  angular.module('app.core').component('surveyOutdatedBar', {
    controller: Controller,
    templateUrl: 'survey-outdated-bar.html',
    bindings: {
      survey: '<',
    },
  })

  /* @ngInject */
  function Controller(
    $window,
    $timeout,
    surveyServiceNext,
    surveyTemplateService
  ) {
    var ctrl = this
    ctrl.$onInit = onInit
    ctrl.$onDestroy = onDestroy
    ctrl.reload = reload

    function onInit() {
      startFetchVersion()
      $window.addEventListener('focus', startFetchVersion)
      $window.addEventListener('blur', stopFetchVersion)
    }

    function startFetchVersion() {
      if (!document.hasFocus()) return

      stopFetchVersion()
      ctrl.fetchVersionTimer = $timeout(function() {
        if (
          ctrl.survey.isNew() ||
          ctrl.survey.isOutdated() ||
          ctrl.survey.queue.isProcessing()
        ) {
          return startFetchVersion()
        }
        var service
        if (ctrl.survey.isTemplate) {
          service = surveyTemplateService
        } else {
          service = surveyServiceNext
        }
        service.fetchVersion(ctrl.survey).then(startFetchVersion)
      }, 5000) // 5 seconds
    }

    function stopFetchVersion() {
      if (ctrl.fetchVersionTimer) {
        $timeout.cancel(ctrl.fetchVersionTimer)
      }
    }

    function reload() {
      window.location.reload()
    }

    function onDestroy() {
      stopFetchVersion()
      $window.removeEventListener('focus', startFetchVersion)
      $window.removeEventListener('blur', stopFetchVersion)
    }
  }
})()
