;(function() {
  'use strict'

  Controller.$inject = ["userService", "subscriberService", "placeService", "channelService", "helpService", "geocodeService", "countryService", "featureService", "glAnalytics"];
  angular.module('app.core').component('placeManager', {
    controller: Controller,
    templateUrl: 'place-manager.html',
    bindings: {
      channel: '<',
    },
  })

  /* @ngInject */
  function Controller(
    userService,
    subscriberService,
    placeService,
    channelService,
    helpService,
    geocodeService,
    countryService,
    featureService,
    glAnalytics
  ) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.isLoading = isLoading
    ctrl.create = create
    ctrl.orderBy = orderBy
    ctrl.applyFilter = applyFilter
    ctrl.isSelected = isSelected
    ctrl.getIsLocked = getIsLocked
    ctrl.getChannelName = getChannelName
    ctrl.setSelected = setSelected
    ctrl.selectAll = selectAll
    ctrl.contact = helpService.chat

    function onInit() {
      ctrl.isSSR = userService.isSSR()
      ctrl.ownerId = subscriberService.getSubscriber().id
      channelService.getByOwner(ctrl.ownerId, function(
        channels,
        loading,
        error
      ) {
        ctrl.channels = channels
        ctrl.channelsLoading = loading
        ctrl.channelsError = error
        findPlaceChannels()
      })
      placeService.list(ctrl.ownerId, function(places, loading, error) {
        ctrl.places = places
        ctrl.loading = loading
        ctrl.error = error
        applyFilter()
        findPlaceChannels()
      })
      ctrl.gettingSearchBounds = true
      getSearchBounds().then(function(bounds) {
        // This is Google's LatLngBounds object
        ctrl.searchBounds = bounds
        ctrl.gettingSearchBounds = false
      })
    }

    function findPlaceChannels() {
      ctrl.placeChannels = {}
      _.each(ctrl.channels, function(channel) {
        _.each(channel.places, function(placeId) {
          ctrl.placeChannels[placeId] = channel
        })
      })
    }

    function isLoading() {
      return (
        (ctrl.loading && !ctrl.places.length) ||
        (ctrl.channelsLoading && !ctrl.channels.length) ||
        ctrl.gettingSearchBounds
      )
    }

    function create() {
      clearFilter()
      var existing = _.find(ctrl.places, function(place) {
        return place.isNew()
      })
      if (existing) {
        return
      }
      if (!ctrl.isSSR && !featureService.canCreatePlace()) {
        ctrl.showQuota = true
        return
      }
      var place = placeService.create({
        ownerId: ctrl.ownerId,
        mode: 'edit',
      })
      ctrl.places.unshift(place)
    }

    function orderBy(place) {
      return place.createdAt.unix()
    }

    function applyFilter() {
      ctrl.hiddenSelections = 0
      if (!ctrl.query) {
        ctrl.filteredPlaces = ctrl.places
        return
      }
      ctrl.filteredPlaces = _.filter(ctrl.places, function(place) {
        var tokens = [place.name, place.address].join(' ').toLowerCase()
        var visible = _.includes(tokens, ctrl.query.toLowerCase())
        if (
          !visible &&
          ctrl.channel &&
          _.includes(ctrl.channel.places, place.id)
        ) {
          ctrl.hiddenSelections++
        }
        return visible
      })
    }

    function clearFilter() {
      ctrl.query = null
      applyFilter()
    }

    function isSelected(place) {
      if (!ctrl.channel) {
        return
      }
      return _.includes(ctrl.channel.places, place.id)
    }

    function getIsLocked(place) {
      var placeChannel = ctrl.placeChannels[place.id]
      var sameChannel =
        ctrl.channel && placeChannel && placeChannel.id === ctrl.channel.id
      var otherChannelDisabled = placeChannel && !placeChannel.enabled
      if (!placeChannel || sameChannel || otherChannelDisabled) {
        return false
      }
      return true
    }

    function getChannelName(place) {
      var placeChannel = ctrl.placeChannels[place.id]
      return placeChannel && placeChannel.name
    }

    function setSelected(place, selected) {
      if (!ctrl.channel) {
        return
      }
      _.remove(ctrl.channel.places, function(id) {
        return id === place.id
      })
      ctrl.channel.removePlaceToTake(place.id)
      if (selected) {
        ctrl.channel.places.push(place.id)
        var otherChannel = ctrl.placeChannels[place.id]
        if (otherChannel) {
          ctrl.channel.addPlaceToTake(place.id, otherChannel.id)
        }
      }
    }

    function selectAll(selected) {
      _(ctrl.filteredPlaces)
        .orderBy(orderBy, ['desc'])
        .each(function(place) {
          if (getIsLocked(place)) return
          setSelected(place, selected)
        })
    }

    function getSearchBounds() {
      var subscriber = subscriberService.getSubscriber()
      var countryName =
        (subscriber.country && subscriber.country.name) ||
        countryService.getByCC('AU').name

      glAnalytics.track('place-manager', 'getSearchBounds', {
        subscriberId: subscriber.id,
        countryName: countryName,
      })
      return geocodeService.getCountryBounds(countryName)
    }
  }
})()
