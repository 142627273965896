;(function() {
  'use strict'

  config.$inject = ["$stateProvider"];
  angular.module('app.account', []).config(config)

  /* @ngInject */
  function config($stateProvider) {
    $stateProvider.state('account', {
      url: '/account/:tab',
      parent: 'portal',
      params: {
        tab: { value: 'details' }, // Default
      },
      templateUrl: 'account.html',
      controller: 'Account',
      controllerAs: '$ctrl',
    })
  }
})()
