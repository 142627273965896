;(function() {
  'use strict'

  angular.module('core.validators').directive('hex', hexDirective)

  /* @ngInject */
  function hexDirective() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: link,
    }

    function link(scope, element, attrs, ngModel) {
      ngModel.$validators.hex = hexValidator
    }

    function hexValidator(modelValue) {
      if (!modelValue) {
        return true
      } else {
        return /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(modelValue)
      }
    }
  }
})()
