;(function() {
  'use strict'

  angular.module('glow.reporting').component('filterSetSave', {
    controller: Controller,
    templateUrl: 'filter-set-save.html',
    bindings: {
      filterSet: '<',
      onSave: '&',
      onDiscard: '&',
      onClose: '&',
    },
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.save = save
    ctrl.canSave = canSave

    function onInit() {
      ctrl.promptLabel = !ctrl.filterSet.label
      ctrl.label = ''
      if (ctrl.filterSet.isNew()) {
        ctrl.title = 'Unsaved Filter'
        ctrl.message = 'Would you like to save this filter?'
      } else {
        ctrl.title = 'Unsaved Changes'
        ctrl.message = 'Would you like to save the changes to this filter?'
      }
    }

    function save() {
      if (!canSave()) {
        return
      }
      if (ctrl.promptLabel) {
        ctrl.filterSet.label = ctrl.label
      }
      ctrl.onSave()
    }

    function canSave() {
      return ctrl.promptLabel ? !!ctrl.label : true
    }
  }
})()
