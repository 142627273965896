;(function() {
  'use strict'

  FrameController.$inject = ["$sce", "$state", "glUtils"];
  angular.module('app.core').controller('FrameController', FrameController)

  /* @ngInject */
  function FrameController($sce, $state, glUtils) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.loadContent = loadContent

    function onInit() {
      if ($state.params.url) {
        ctrl.frameUrl = $sce.trustAsResourceUrl($state.params.url)
        ctrl.title = $state.params.title
      }
      ctrl.frameStyle = {}
      if ($state.params.mw) {
        ctrl.frameStyle.width = $state.params.mw + 'px'
      }
    }

    function loadContent() {
      if (!ctrl.urlInput) return
      if (!_.startsWith(ctrl.urlInput, 'https://')) {
        ctrl.urlInput = 'https://' + ctrl.urlInput
      }
      if (!glUtils.validateUrl(ctrl.urlInput)) {
        ctrl.error = 'invalid url format'
        return
      }
      ctrl.error = null

      // ensure URL is decoded before encode
      var url = encodeURI(decodeURI(ctrl.urlInput))
      var params = { url: url, mw: null, title: null }
      if (ctrl.mwInput) {
        params.mw = ctrl.mwInput
      }
      if (ctrl.titleInput) {
        params.title = ctrl.titleInput
      }
      $state.go('frame', params, { reload: true })
    }
  }
})()
