;(function() {
  'use strict'

  Controller.$inject = ["$state", "channelCostService", "userService", "subscriberService"];
  angular.module('app.core').component('channelConfirmCharges', {
    templateUrl: 'channel-confirm-charges.html',
    controller: Controller,
    bindings: {
      channel: '<',
      onConfirm: '&',
      onCancel: '&',
    },
  })

  /* @ngInject */
  function Controller(
    $state,
    channelCostService,
    userService,
    subscriberService
  ) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.goToAccountBilling = goToAccountBilling

    function onInit() {
      ctrl.isSSR = userService.isSSR()
      ctrl.isAdmin = subscriberService.isAdmin(userService.getUser().id)
      ctrl.canTopup = ctrl.isSSR || ctrl.isAdmin
      ctrl.cost = channelCostService.calculate(ctrl.channel)
    }

    function goToAccountBilling() {
      var url = $state.href('account', { tab: 'billing' }, { absolute: true })
      window.open(url, '_blank') // Open the URL in a new tab
      ctrl.onCancel()
    }
  }
})()
