;(function() {
  'use strict'

  angular
    .module('core.questionLibrary')
    .factory('QuestionLibraryFilter', Factory)

  /* @ngInject */
  function Factory() {
    function QuestionLibraryFilter() {
      // ...
    }

    QuestionLibraryFilter.prototype.update = function(options) {
      var self = this

      options = options || {}

      if (options.templates) {
        self.templates = options.templates
      }
      if (options.pathways) {
        self.pathways = options.pathways
      }

      // reduce to only templates that contain all pathways selected
      var templates = _.filter(self.templates, function(template) {
        var matchedPathways = 0
        _.each(self.pathways, function(pathway) {
          var match = _.find(template.tags, function(tag) {
            return _.startsWith(tag, pathway)
          })
          if (match) {
            matchedPathways++
          }
        })
        return matchedPathways === self.pathways.length
      })

      var tags = self.getUniqueTags(templates)
      var paths = self.buildPaths(tags)

      self.addCounts(paths, templates)

      // mark selected paths
      _.each(paths, function(path) {
        _.each(self.pathways, function(pathway) {
          if (_.startsWith(pathway, path.pathway)) {
            path.isSelected = true
            return false
          }
        })
      })

      // build sections and levels

      var sections = []
      var sectionPaths = _.filter(paths, { isSection: true })

      _.each(sectionPaths, function(path) {
        var section = {
          name: path.name,
          levels: [
            {
              selected: _.find(path.children, { isSelected: true }),
              paths: path.children,
            },
          ],
        }

        var add = true
        while (add) {
          var prevLevel = _.last(section.levels)
          if (prevLevel.selected && prevLevel.selected.children.length) {
            section.levels.push({
              selected: _.find(prevLevel.selected.children, {
                isSelected: true,
              }),
              paths: prevLevel.selected.children,
            })
          } else {
            add = false
          }
        }

        sections.push(section)
      })

      // HACK: there is no data to support ordering the sections so we hardcode some here, any unmatched sections will be added underneath
      var ORDER = {
        Fields: 1,
        Features: 2,
        Research: 3,
      }
      sections = _.orderBy(sections, function(section) {
        return ORDER[section.name] || 9999
      })

      self.sections = sections
      return self.sections
    }

    QuestionLibraryFilter.prototype.getUniqueTags = function(templates) {
      return _(templates)
        .map('tags')
        .flatten()
        .uniq()
        .value()
    }

    QuestionLibraryFilter.prototype.buildPaths = function(tags) {
      var self = this
      var paths = {}
      // var startTime = moment(); // for timer

      _.each(tags, function(tag) {
        var pathNames = tag.split('/')

        var parentPathway
        _.each(pathNames, function(pathName, depth) {
          // catch empty strings
          if (!pathName) {
            return
          }

          // ensure it exists
          var path = self.upsertBasePath(paths, pathName, parentPathway)

          // add the child if it doesn't exist
          var childName = pathNames[depth + 1]
          // if none, stop!
          if (!childName) {
            return
          }
          var child = self.upsertBasePath(paths, childName, path.pathway)
          if (child && !_.includes(path.children, child)) {
            path.children.push(child)
          }

          // update parentPathway for next path
          parentPathway = path.pathway
        })
      })

      // console.log('paths', paths);

      // remove sections without children
      _.each(paths, function(path, key) {
        if (path.isSection && !path.children.length) {
          delete paths[key]
        }
      })

      // var timeLog = 'buildPaths() took ' + moment().diff(startTime) + 'ms';
      // console.info(timeLog, paths);

      return paths
    }

    // utility to ensure segment has been created
    QuestionLibraryFilter.prototype.upsertBasePath = function(
      collection,
      pathName,
      parentPathway
    ) {
      var pathway = parentPathway ? parentPathway + '/' + pathName : pathName

      // create it if it doesn't exist
      if (!collection[pathway]) {
        collection[pathway] = {
          name: pathName,
          pathway: pathway,
          parentPathway: parentPathway,
          isSection: !parentPathway,
          children: [],
          templates: 0,
        }
      }

      return collection[pathway]
    }

    QuestionLibraryFilter.prototype.addCounts = function(paths, templates) {
      var pathsNotFound = []

      _.each(templates, function(template) {
        // we need to increment counts for each node but ensuring that if template has:
        // Fields/Product/Beverage - AND - Fields/Product/Food
        // doesn't increment Fields/Product twice
        var incremented = {}
        _.each(template.tags, function(tag) {
          var segments = tag.split('/')
          _.remove(segments, _.isEmpty) // remove empty strings and artifacts etc

          while (segments.length) {
            var key = segments.join('/')
            var path = paths[key]

            if (!path) {
              pathsNotFound.push(key)
            } else if (!incremented[key]) {
              path.templates++
              incremented[key] = true
            }
            segments.pop()
          }
        })
      })

      if (pathsNotFound.length) {
        console.log('excluded paths not found: ', pathsNotFound)
      }
    }

    QuestionLibraryFilter.prototype.toggle = function(path) {
      var self = this

      if (_.includes(self.pathways, path.pathway)) {
        _.remove(self.pathways, function(pathway) {
          return _.startsWith(pathway, path.pathway)
        })
      } else {
        self.pathways.push(path.pathway)
      }

      // console.log('pathways:', _.clone(self.pathways));
    }

    return QuestionLibraryFilter
  }
})()
