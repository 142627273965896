;(function() {
  'use strict'

  Service.$inject = ["glDialog", "Resource"];
  angular.module('app.core').service('resourceService', Service)

  /* @ngInject */
  function Service(glDialog, Resource) {
    var self = this

    self.Types = Resource.Types
    self.TypeInfo = Resource.getTypeInfo()
    self.Categories = Resource.Categories
    self.CategoryInfo = Resource.getCategoryInfo()
    self.getBlank = getBlank
    self.showEditor = showEditor

    init()

    function init() {
      // ...
    }

    function getBlank() {
      return new Resource()
    }

    function showEditor(resource) {
      var template = [
        '<gl-dialog class="resource-editor__dialog">',
        '<resource-editor ',
        'resource="resource" ',
        'on-discard="dialog.cancel()" ',
        'on-commit="dialog.close($resource)" ',
        '/>',
        '</gl-dialog>',
      ]
      return glDialog.show({
        template: template.join(''),
        clickOutsideToClose: false,
        escapeToClose: true,
        locals: {
          resource: resource,
        },
      })
    }
  }
})()
