;(function() {
  'use strict'

  Controller.$inject = ["configService", "productService", "subscriberService", "clipboardService"];
  angular.module('app.core').component('channelFormProducts', {
    controller: Controller,
    templateUrl: 'channel-form-products.html',
    bindings: {
      channel: '<',
      survey: '<',
      onSave: '&',
      selectProducts: '&',
    },
  })

  /* @ngInject */
  function Controller(
    configService,
    productService,
    subscriberService,
    clipboardService
  ) {
    var ctrl = this

    ctrl.glowThisUrl = configService.getZoneUrl()

    ctrl.$onInit = onInit
    ctrl.removeProduct = removeProduct
    ctrl.getProductLabel = getProductLabel
    ctrl.copyToClipboard = clipboardService.copy

    function onInit() {
      ctrl.ownerId = subscriberService.getSubscriber().id
      productService.list(ctrl.ownerId, function(products, loading) {
        ctrl.products = products
        ctrl.ready = products.length || !loading
      })
    }

    function removeProduct(productId) {
      _.remove(ctrl.channel.products, function(id) {
        return id === productId
      })
    }

    function getProductLabel(productId) {
      var product = _.find(ctrl.products, { id: productId })
      return product.brand + ' ' + product.name
    }
  }
})()
