;(function() {
  'use strict'

  angular
    .module('glow.reporting')
    .component('glQuestionTextFilter', Component())

  function Component() {
    return {
      controller: Controller,
      templateUrl: 'text-filter.template.html',
      bindings: {
        question: '<',
        survey: '<',
        filter: '<',
      },
    }
  }

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.filterSuggestions = filterSuggestions
    ctrl.add = add
    ctrl.remove = remove
    ctrl.onKeydown = onKeydown

    ctrl.$onChanges = onChanges

    function onChanges() {
      ctrl.selections = ctrl.filter.data.values
      ctrl.words = ctrl.suggestions = getSuggestions()

      if (ctrl.filter.newValue) {
        var word = _.find(ctrl.words, { text: ctrl.filter.newValue })
        if (word && !_.find(ctrl.selections, { text: word.text })) {
          ctrl.selections.push(word)
        }
        ctrl.filter.newValue = undefined
      }

      filterSuggestions()
    }

    function getSuggestions() {
      return _.map(
        ctrl.survey.filterOptions.questions[ctrl.question.id].values,
        function(value, key) {
          return {
            text: key,
            textLowerCase: key.toLowerCase(),
            count: value.count,
          }
        }
      )
    }

    function add(word) {
      ctrl.selections.push(word)
      ctrl.query = ''
      ctrl.filterSuggestions()
    }

    function remove(word) {
      _.remove(ctrl.selections, word)
      ctrl.filterSuggestions()
    }

    function filterSuggestions() {
      var query = ctrl.query ? ctrl.query.toLowerCase() : ''
      ctrl.suggestions = _.filter(ctrl.words, function(word) {
        return (
          _.includes(word.textLowerCase, query) &&
          !_.find(ctrl.selections, { text: word.text })
        )
      })
      adjustFocus()
    }

    function adjustFocus(wrap) {
      if (ctrl.focus < 0) {
        ctrl.focus = wrap ? ctrl.suggestions.length - 1 : 0
      }
      if (ctrl.focus > ctrl.suggestions.length) {
        ctrl.focus = wrap ? 0 : ctrl.suggestions.length - 1
      }
    }

    function onKeydown(event) {
      // backspace
      if (event.keyCode === 8 && !ctrl.query) {
        ctrl.selections.pop()
        ctrl.filterSuggestions()
      }

      // enter
      if (event.keyCode === 13) {
        if (ctrl.suggestions[ctrl.focus]) {
          ctrl.add(ctrl.suggestions[ctrl.focus])
        }
      }

      // down
      if (event.keyCode === 40) {
        ctrl.focus++
        adjustFocus(true)
      }

      // up
      if (event.keyCode === 38) {
        ctrl.focus--
        adjustFocus(true)
      }
    }
  }
})()
