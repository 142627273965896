;(function() {
  'use strict'

  Controller.$inject = ["glToast", "subscriberService", "userService", "externalPanelService"];
  angular.module('app.core').component('channelFormExternalPanel', {
    templateUrl: 'channel-form-external-panel.html',
    controller: Controller,
    bindings: {
      channel: '<',
      survey: '<',
      cost: '<',
      onCostShouldUpdate: '&',
      onSave: '&',
    },
  })

  /* @ngInject */
  function Controller(
    glToast,
    subscriberService,
    userService,
    externalPanelService
  ) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.change = change
    ctrl.setAsPreferred = setAsPreferred

    function onInit() {
      ctrl.subscriber = subscriberService.getSubscriber()
      ctrl.user = userService.getUser()
      ctrl.isSSR = userService.isSSR()
      ctrl.isAdmin = subscriberService.isAdmin(ctrl.user.id)

      if (!ctrl.channel.provider) {
        var provider = externalPanelService.get(
          ctrl.subscriber.data.preferredExternalPanelId,
          true
        )
        if (provider && provider.isActive()) {
          ctrl.channel.provider = provider
        }
      }

      ctrl.onCostShouldUpdate()
    }

    function change() {
      externalPanelService.showSelectorDialog().then(function(provider) {
        ctrl.channel.provider = provider
      })
    }

    function setAsPreferred() {
      var oldPreferredProviderId = ctrl.subscriber.data.preferredExternalPanelId
      ctrl.subscriber.data.preferredExternalPanelId = ctrl.channel.provider.id

      subscriberService.saveSubscriber(ctrl.subscriber).catch(function() {
        ctrl.subscriber.data.preferredExternalPanelId = oldPreferredProviderId
        glToast.show('Error setting preferred provider')
      })
    }
  }
})()
