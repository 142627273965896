;(function() {
  'use strict'

  Controller.$inject = ["questionSummary"];
  var component = {
    bindings: {
      question: '=',
      showStat: '=',
      onClick: '&?',
    },
    controller: Controller,
    templateUrl: 'question-summary.template.html',
  }

  angular.module('glow.reporting').component('glQuestionSummary', component)

  /* @ngInject */
  function Controller(questionSummary) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.getText = getText

    function onInit() {
      ctrl.config = questionSummary.getConfig(ctrl.question.type)
      ctrl.isClickable = !!ctrl.onClick
    }

    function getText() {
      return ctrl.showStat
        ? questionSummary.getStat(ctrl.question)
        : ctrl.question.title
    }
  }
})()
