;(function() {
  'use strict'

  Service.$inject = ["$q", "subscriberService", "productsResource"];
  angular.module('core.services').factory('productsService', Service)

  /* @ngInject */
  function Service($q, subscriberService, productsResource) {
    return {
      initNewProduct: initNewProduct,
      getBySubscriber: getBySubscriber,
      getBySurvey: getBySurvey,
      get: getProduct,
      save: save,
      delete: deleteProduct,
    }

    // Move to a factory when needed
    function initNewProduct() {
      var subscriberId = subscriberService.getSubscriber().id
      var Defaults = {
        ownerId: subscriberId,
        data: {},
      }

      return _.cloneDeep(Defaults)
    }

    // Private. All get methods wrap around this one.
    function get(options) {
      return $q(function(resolve, reject) {
        productsResource
          .get(options)
          .success(onSuccess)
          .error(onError)

        function onSuccess(data) {
          resolve(data)
        }

        function onError(data, status) {
          reject({ data: data, status: status })
        }
      })
    }

    function getBySubscriber() {
      var subscriberId = subscriberService.getSubscriber().id
      var options = {
        ownerId: subscriberId,
      }

      return get(options)
    }

    function getBySurvey(surveyId) {
      var subscriberId = subscriberService.getSubscriber().id
      var options = {
        ownerId: subscriberId,
        surveyId: surveyId,
      }

      return get(options)
    }

    function getProduct(productId) {
      var options = {
        id: productId,
      }

      return get(options)
    }

    function save(product) {
      return $q(function(resolve, reject) {
        var action = product.id ? 'update' : 'create'

        productsResource[action](product)
          .success(onSuccess)
          .error(onError)

        function onSuccess(data) {
          // Id is returned on create
          resolve(data.id)
        }

        function onError(data, status) {
          reject({ data: data, status: status })
        }
      })
    }

    function deleteProduct(product) {
      return $q(function(resolve, reject) {
        productsResource
          .delete({ id: product.id })
          .success(onSuccess)
          .error(onError)

        function onSuccess() {
          resolve()
        }

        function onError(data, status) {
          reject({ data: data, status: status })
        }
      })
    }
  }
})()
