;(function() {
  'use strict'

  toDayFilter.$inject = ["$log"];
  angular.module('core.filters').filter('toDay', toDayFilter)

  /**
   * @name timeago
   * @description converts a date time string into 'Today', 'Yesterday' or '12 Feb 2015' format strings
   * @param {String} isoDateString
   * @returns {String} custom formatting date string
   */
  /* @ngInject */
  function toDayFilter($log) {
    return function(isoDateString) {
      if (!isoDateString) {
        return ''
      }
      var now = moment()
      var then = moment(isoDateString)
      if (!then.isValid()) {
        $log.error(
          'toDayFilter requires input to be ISO 8601 format, expect incorrect dates'
        )
      }
      if (then.isSame(now, 'day')) {
        return 'Today'
      } else if (then.isSame(now.subtract(1, 'day'), 'day')) {
        return 'Yesterday'
      } else if (then.isSame(now, 'year')) {
        return then.format('MMM D')
      } else {
        return then.format('MMM D YYYY')
      }
    }
  }
})()
