;(function() {
  'use strict'

  angular.module('app.core').service('responseFilterService', Service)

  /* @ngInject */
  function Service() {
    this.extractDimensionsFromReport = extractDimensionsFromReport

    function extractDimensionsFromReport(report) {
      // remap to simpler field names, these ones are ridiculous
      var dimensions = {
        system:
          report.filterOptions.reportingDimensions.systemReportingDimensions,
        report:
          report.filterOptions.reportingDimensions.customReportingDimensions,
      }
      // remove links (they mean nothing to users)
      delete dimensions.system.link
      delete dimensions.report.link
      // remove channels (these have their own filter type)
      delete dimensions.system.channel
      delete dimensions.report.channel
      // remove tags (these have their own filter type)
      _.each(dimensions.report, function(_, name) {
        if (name.startsWith('tag:')) delete dimensions.report[name]
      })
      return dimensions
    }
  }
})()
