;(function() {
  'use strict'

  Service.$inject = ["glUtils"];
  angular.module('glow.reporting').factory('filterSetMigrationService', Service)

  /* @ngInject */
  function Service(glUtils) {
    return {
      migrateDeviceSets: migrateDeviceSets,
      migrate: migrate,
    }

    function migrateDeviceSets(deviceSets, survey, prefs) {
      var sets = deviceSets[survey.id]
      if (!sets || !sets.length) return

      // migrate sets
      _.each(sets, function(set) {
        if (set.filter) return // set has been migrated previously
        migrate(set, survey)
      })

      // remove sets that can't be migrated
      _.remove(sets, function(set) {
        return !set.filter
      })

      // update the device local storage
      prefs.set('device-sets', deviceSets)
    }

    function migrate(filterSet, survey) {
      if (!filterSet.filters || !filterSet.filters.length) {
        // do not migrate empty filters
        return null
      }

      // set defaults to prevent error down the line
      _.each(filterSet.filters, function(filter) {
        filter.data = _.isPlainObject(filter.data) ? filter.data : {}
        filter.data.values = _.isArray(filter.data.values)
          ? filter.data.values
          : []
      })

      var filtersByType = _.groupBy(filterSet.filters, 'type')
      convertPageDimensionToPage(filtersByType)

      var rules = convertFiltersToRules(filtersByType, survey)
      if (!rules.length) {
        // do not migrate if rules are empty
        return null
      }

      var responseFilter = {
        id: glUtils.uuid(),
        name: null,
        groups: [
          {
            id: glUtils.uuid(),
            rules: rules,
            isGroup: true,
          },
        ],
      }
      filterSet.filter = responseFilter
      delete filterSet.filters

      filterSet.createdAt = moment().toISOString()
      filterSet.updatedAt = moment().toISOString()

      return filterSet
    }

    function convertPageDimensionToPage(filtersByType) {
      if (!filtersByType.dimension) return

      var pageDimensionFilters = _.remove(filtersByType.dimension, function(
        filter
      ) {
        return filter.data.category === 'page'
      })
      if (!pageDimensionFilters.length) return

      // convert page dimension filter to page filter
      _.each(pageDimensionFilters, function(filter) {
        filter.type = 'page'
        filter.data.values = _.map(filter.data.values, function(value) {
          return { id: value.value[0], label: value.value[1] }
        })
      })
      filtersByType.page = _.concat(
        filtersByType.page || [],
        pageDimensionFilters
      )

      if (!filtersByType.dimension.length) {
        delete filtersByType.dimension
      }
    }

    function convertFiltersToRules(filtersByType, survey) {
      var rules = []
      _.each(filtersByType, function(filters, type) {
        switch (type) {
          case 'date': {
            function createDateRule(date, quantifier) {
              var formattedDate = moment(date)
              if (!formattedDate.isValid()) {
                return null // Return null if the date is invalid
              }
              return createRule({
                type: 'DATE',
                date: {
                  value: formattedDate.format('DD/MM/YYYY'),
                  quantifier,
                },
              })
            }
            var filter = filters[0] // only take the first one
            if (filter.data.start) {
              var startDateRule = createDateRule(filter.data.start, 'GTE')
              if (startDateRule) {
                rules.push(startDateRule)
              }
            }
            if (filter.data.end) {
              var endDateRule = createDateRule(filter.data.end, 'LTE')
              if (endDateRule) {
                rules.push(endDateRule)
              }
            }
            break
          }
          case 'dimension': {
            // remove filters without category and link dimension(not supported)
            _.remove(filters, function(filter) {
              return !filter.data.category || filter.data.category === 'link'
            })

            // create tag rule
            var tagFilters = _.remove(filters, function(filter) {
              return filter.data.category.startsWith('tag:')
            })
            if (tagFilters.length) {
              var tags = _.chain(tagFilters)
                .map(function(filter) {
                  return filter.data.category.split('tag:')
                })
                .flatten()
                .compact()
                .uniq()
                .value()
              if (tags.length) {
                var tagRule = createRule({
                  type: 'TAG',
                  tags: { items: tags, quantifier: 'ANY' },
                })
                rules.push(tagRule)
              }
            }

            // create dimension rules
            var filtersByCategory = _.groupBy(filters, 'data.category')
            _.each(filtersByCategory, function(filters, category) {
              var values = _.chain(filters)
                .map(function(filter) {
                  return _.map(filter.data.values, 'value')
                })
                .flatten()
                .compact()
                .uniq()
                .value()
              if (!values.length) return // continue

              var dimensionRule = createRule({
                type: 'DIMENSION',
                dimensionName: category,
                dimensionValues: { items: values, quantifier: 'ANY' },
              })
              rules.push(dimensionRule)
            })
            break
          }
          case 'channel': {
            var channelIds = _.chain(filters)
              .map(function(filter) {
                return filter.data.values
              })
              .flatten()
              .compact()
              .uniq()
              .value()
            if (!channelIds.length) break // skip

            var rule = createRule({
              type: 'CHANNEL',
              channelIds: channelIds,
            })
            rules.push(rule)
            break
          }
          case 'question': {
            _.each(filters, function(filter) {
              var question = survey.getQuestion(filter.data.questionId)
              if (!question) return // continue

              var options = getQuestionRuleOptions(filter, question)
              switch (filter.data.category) {
                case 'choice':
                case 'mood':
                case 'score':
                case 'matrix': {
                  var choiceIds = _.chain(filter.data.values)
                    .map('id')
                    .compact()
                    .uniq()
                    .value()
                  if (!choiceIds.length) break // skip

                  var rule = createRule(options)
                  rule.choices.items = choiceIds
                  rule.choices.quantifier = 'ANY'
                  rules.push(rule)
                  break
                }
                case 'numeric': {
                  var numbers = _.chain(filter.data.values)
                    .map('number')
                    .uniq()
                    .value()
                  if (!numbers.length) break // skip

                  var rule = createRule(options)
                  rule.numbers.items = numbers
                  rule.numbers.quantifier = 'ANY'
                  rules.push(rule)
                  break
                }
                case 'text': {
                  var texts = _.chain(filter.data.values)
                    .map('text')
                    .uniq()
                    .value()
                  if (!texts.length) break // skip

                  var rule = createRule(options)
                  rule.text.value = texts.join(',')
                  rule.text.quantifier = 'INCLUDES'
                  rules.push(rule)
                  break
                }
                case 'nps': {
                  function getChoiceIds(values) {
                    return _.chain(question.choices)
                      .filter(function(choice) {
                        return values.includes(+choice.value)
                      })
                      .map('id')
                      .value()
                  }
                  var npsGroups = {
                    detractor: getChoiceIds([0, 1, 2, 3, 4, 5, 6]),
                    passive: getChoiceIds([7, 8]),
                    promoter: getChoiceIds([9, 10]),
                  }
                  var choiceIds = _.chain(filter.data.values)
                    .map(function(value) {
                      return npsGroups[value.id]
                    })
                    .flatten()
                    .compact()
                    .uniq()
                    .value()
                  if (!choiceIds.length) break // skip

                  var rule = createRule(options)
                  rule.choices.items = choiceIds
                  rule.choices.quantifier = 'ANY'
                  rules.push(rule)
                  break
                }
                case 'location': {
                  var locations = _.chain(filter.data.values)
                    .filter(function(value) {
                      return [0, 1, 2, 3, 4].includes(value.slot)
                    })
                    .map(function(value) {
                      return value.slot + ':' + value.value.toLowerCase()
                    })
                    .uniq()
                    .value()
                  if (!locations.length) break // skip

                  var rule = createRule(options)
                  rule.locations = locations
                  rules.push(rule)
                  break
                }
                case 'rank': {
                  var choiceIds = _.chain(filter.data.values)
                    .map('id')
                    .compact()
                    .uniq()
                    .value()
                  if (!choiceIds.length) break // skip

                  var rule = createRule(options)
                  rule.choices.items = choiceIds
                  rule.choices.quantifier = 'ANY'
                  var aspect = getAspect(filter) // aspect is the rank
                  rule.numbers.items = aspect ? [aspect] : [0]
                  rule.numbers.quantifier = aspect ? 'EQUALS' : 'GREATER_THAN'
                  rules.push(rule)
                  break
                }
                case 'placesnearme':
                case 'constantsum':
                  break // NOT supported
              }
            })
            break
          }
          case 'profile':
            break // NOT supported
          case 'page': {
            var pageIds = _.chain(filters)
              .map(function(filter) {
                return _.map(filter.data.values, 'id')
              })
              .flatten()
              .compact()
              .uniq()
              .value()
            if (!pageIds.length) break // skip

            var rule = createRule({
              type: 'DIMENSION',
              dimensionName: 'page',
              dimensionValues: { items: pageIds, quantifier: 'ANY' },
            })
            rules.push(rule)
            break
          }
          case 'answered': {
            var questionIds = _.chain(filters)
              .map(function(filter) {
                return filter.data.questionId
              })
              .compact()
              .uniq()
              .value()
            if (!questionIds.length) break // skip

            var rule = createRule({
              type: 'ANSWER',
              questions: { items: questionIds, quantifier: 'ALL' },
            })
            rules.push(rule)
            break
          }
          case 'segment': {
            var segments = _.chain(filters)
              .map(function(filter) {
                return filter.data.values
              })
              .flatten()
              .compact()
              .uniq()
              .value()
            if (!segments.length) break // skip

            var rule = createRule({
              type: 'SEGMENT',
              segments: { items: segments, quantifier: 'ANY' },
            })
            rules.push(rule)
            break
          }
        }
      })
      return rules
    }

    function createRule(options) {
      return _.defaultsDeep({}, options, {
        id: glUtils.uuid(),
        type: null,
        questionId: null,
        statementIds: [],
        choices: {
          items: [],
          quantifier: 'ANY',
        },
        choiceId: null,
        locations: [],
        text: {
          value: null,
          quantifier: 'CONTAINS',
        },
        numbers: {
          items: [],
          quantifier: 'EQUALS',
        },
        questions: {
          items: [],
          quantifier: 'ANY',
        },
        channelIds: [],
        date: {
          value: null,
          quantifier: 'GTE',
        },
        dimensionName: null,
        dimensionValues: {
          items: [],
          quantifier: 'ANY',
        },
        segments: {
          items: [],
          quantifier: 'ANY',
        },
        tags: {
          items: [],
          quantifier: 'ANY',
        },
        kinds: [],
        isRule: true,
      })
    }

    function getAspect(filter) {
      return _.isPlainObject(filter.data.aspect)
        ? filter.data.aspect.id
        : filter.data.aspect
    }

    function getQuestionRuleOptions(filter, question) {
      var options = {
        type: 'QUESTION',
        questionId: filter.data.questionId,
      }
      var aspect = getAspect(filter)

      // if there's no aspect but the question is matrix, then add all visible statements
      if (!aspect && question.isUsedAsMatrix()) {
        options.statementIds = _.map(question.getVisibleStatements(), 'id')
      }
      // if there's aspect and not rank, add as statement id
      if (aspect && filter.data.category !== 'rank') {
        options.statementIds = [aspect]
      }
      return options
    }
  }
})()
