;(function() {
  'use strict'

  Controller.$inject = ["userService"];
  angular.module('app.core').component('secretWord', {
    controller: Controller,
    bindings: {
      word: '@',
      onExecute: '&',
      ssr: '<',
    },
  })

  /* @ngInject */
  function Controller(userService) {
    var ctrl = this
    var ignoredElems = ['INPUT', 'TEXTAREA']
    var buffer = ''

    ctrl.$onInit = onInit
    ctrl.$onDestroy = onDestroy

    function onInit() {
      ctrl.enabled = ctrl.ssr === true ? userService.isSSR() : true
      window.addEventListener('keydown', onKeyDown)
    }

    function onDestroy() {
      window.removeEventListener('keydown', onKeyDown)
    }

    function onKeyDown(e) {
      if (!ctrl.enabled) return
      // dont listen when the user is typing into an input/textarea
      if (ignoredElems.includes(document.activeElement.nodeName)) {
        return
      }
      // if the character isn't part of the word, reset!
      if (!ctrl.word.includes(e.key)) {
        buffer = ''
      }
      // append the character
      buffer += e.key
      // if the buffer isn't forming into the word, reset!
      if (!ctrl.word.includes(buffer)) {
        buffer = ''
      }
      // if the buffer matches the word, execute! (and reset)
      if (buffer === ctrl.word) {
        buffer = ''
        ctrl.onExecute()
      }
    }
  }
})()
