;(function() {
  'use strict'

  appConfig.$inject = ["$urlServiceProvider", "$locationProvider", "appProvider", "$compileProvider"];
  appRun.$inject = ["$transitions", "$rootScope", "$state", "$location", "glUtils", "app", "userService", "helpService", "updateService"];
  angular
    .module('GlowSubscriberPortal', [
      'ui.router',

      'ngAnimate',
      'ngMessages',
      'ngSanitize',
      'ngCookies',

      'monospaced.elastic',

      'glow.lib',
      'glow.components',
      'glow.reporting',

      'app.core',
      'app.survey',

      'app.errors',
      'app.login',
      'app.signup',
      'app.shared-report',
      'app.shared-dashboard',
      'app.export',

      'app.dashboard',
      'app.activity',
      'app.create-page',
      'app.survey-edit',
      'app.survey-templates',
      'app.survey-template-edit',
      'app.question-templates',
      'app.question-templates-edit',
      'app.password-reset',
      'app.page',
      'app.page-elements',
      'app.page-general',
      'app.page-links',
      'app.page-modules',
      'app.projects',
      'app.profile',
      'app.products',
      'app.places',
      'app.beacons',
      'app.project',
      'app.account',
      'app.kit-manager',
      'app.kits',
      'app.kit',
      'app.kits-edit',
      'app.consume-invite',

      'templates',

      'color.picker',
      'duScroll',
    ])
    .config(appConfig)
    .run(appRun)

  /* @ngInject */
  function appConfig(
    $urlServiceProvider,
    $locationProvider,
    appProvider,
    $compileProvider
  ) {
    $urlServiceProvider.rules.otherwise('/')
    $locationProvider.html5Mode(true)

    appProvider.setAppName('Glow')
    appProvider.setDefaultTags({
      title:
        'Glow - simple, end to end brand, market, customer and employee research.',
      type: 'website',
      siteName: 'Glow',
      // url: 'http://portal.glowfeed.com',
      description:
        'The Glow site for businesses. Sign up and log in here to see your all ' +
        'feedback and interact with your customers.',
      image: 'http://static.glowfeed.com/images/glow-social-img-xsml.png',
    })

    // var isProd = (GlowBuild.env === 'Production');
    // $compileProvider.debugInfoEnabled(!isProd);
    // $compileProvider.debugInfoEnabled(false);

    // TODO: enable when we reach v1.5.9 for ~10% performance
    // https://github.com/angular/angular.js/pull/14850
    $compileProvider.commentDirectivesEnabled(false)
    $compileProvider.cssClassDirectivesEnabled(false)
  }

  /* @ngInject */
  function appRun(
    $transitions,
    $rootScope,
    $state,
    $location,
    glUtils,
    app,
    userService,
    helpService,
    updateService
  ) {
    $rootScope.app = app

    glUtils.fixRouterScroll()

    // check for SSR query param on load
    if ($location.search().ssrsid) {
      userService.setRequestedSubscriber($location.search().ssrsid)
    }

    // Initialize HelpScout
    helpService.init()

    // Start update checker
    updateService.start()

    // authorisation check
    $transitions.onStart(null, function(trans) {
      var toState = trans.to()
      console.debug('routing to: ', toState.name)

      // if authenticated route but no user, route to login
      if (!toState.public && !userService.hasCredentials()) {
        trans.abort()
        // save requested url to route to after login
        userService.saveRequestedUrl()

        // if requested route is consume-invite, then redirect to signup page
        if (toState.name === 'consume-invite') {
          return $state.go(
            'signup',
            {
              organisationName: $location.search().organisationName,
            },
            {
              // transition options to set parameter
              // the reason to use this is because URL parameters can be altered and not a reliable source
              // to determine where the state redirected from
              custom: { redirectedFrom: toState.name },
            }
          )
        }

        return $state.go('login', { ssrsid: $location.search().ssrsid })
      }

      // if user but not authenticated route, route to portal - default route for logged in users
      if (
        userService.hasCredentials() &&
        _.includes(['login', 'home'], toState.name)
      ) {
        trans.abort()
        userService.redirectToRequestedUrl()
      }
    })

    // handle state change error
    $transitions.onError(null, function(trans) {
      // https://ui-router.github.io/ng1/docs/latest/classes/transition.rejection.html#superseded
      // don't error on redirectTo superceded errors
      // couldn't find the enum definitions so i used `2` instead
      if (trans.error().type === 2) {
        return
      }

      var error = trans.error().detail
      console.debug('routing error:', trans.error())
      trans.abort()
      if (!error) {
        return
      }

      switch (error.__status) {
        case 403:
          userService.logout().then(function() {
            $state.go('login')
          })
          break
        case 404:
          $state.go('error-notfound', null, { location: false })
          break
        case 503:
          $state.go('error-maintenance', null, { location: false })
          break
        default:
          $state.go('error-server', null, { location: false })
          break
      }
    })
  }
})()
