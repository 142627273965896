;(function() {
  'use strict'

  angular.module('app.core').component('surveyAnalyze2', {
    controller: Controller,
    templateUrl: 'survey-analyze-2.html',
    bindings: {
      survey: '<',
    },
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit

    function onInit() {
      // todo: survey analysis specific options (as opposed to
      // shared analysis or publication analysis)
      ctrl.options = {}
    }
  }
})()
