;(function() {
  'use strict'

  elementService.$inject = ["$injector", "$q", "elementsResource", "elementSourcesResource", "elementStatsResource", "pageElementsResource"];
  angular.module('core.services').factory('elementService', elementService)

  /**
   * ElementsService
   *
   * @ngInject */
  function elementService(
    $injector,
    $q,
    elementsResource,
    elementSourcesResource,
    elementStatsResource,
    pageElementsResource
  ) {
    var elements // cache of subscriber elements
    var basicElement // cache of subscriber's basic element
    var pageElements = {} // cache of element by page ID

    var iconTypes = {
      place: 'gi-location',
      product: 'gi-category',
      other: 'gi-other',
    }

    var service = {
      getBySubscriber: getBySubscriber,
      getSubscriberBasicElement: getSubscriberBasicElement,
      getByPage: getByPage,
      associateWithPage: associateWithPage,
      dissociateFromPage: dissociateFromPage,
      clearElementCache: clearElementCache,
    }
    return service

    /**
     * PRIVATE - transform/add element properties
     * @param { Array } elements array of element resources
     * @return { array } array of transformed elements
     */
    function transformElements(elements) {
      removeBasic(elements)

      _.forEach(elements, function(element) {
        addIcon(element)
      })

      return elements

      /* remove basic element - should not be visible to user */
      function removeBasic(elements) {
        _.remove(elements, function(element) {
          // cache basic element
          if (element.type === 'basic') {
            basicElement = element
          }
          return element.type === 'basic'
        })
      }

      /* add element icon class */
      function addIcon(element) {
        element.iconClass = iconTypes[element.type] || 'gi-other'
      }
    }

    /**
     * @name getBySubscriber
     * @description Get subscriber elements
     * @param {Boolean} force true when forcing a new request to server to update elements
     * @return {Promise}
     */
    function getBySubscriber(force) {
      // manually inject subscriberService to avoid circular dependency:
      // `userService <- subscriberService <- elementService <- userService`
      var subscriberService =
        subscriberService || $injector.get('subscriberService')

      var deferred = $q.defer()

      // if already cached, return elements
      if (elements && !force) {
        return $q.when(elements)
      }

      elementsResource
        .getBySubscriber({ subscriberId: subscriberService.getSubscriber().id })
        .success(function(data) {
          // cache data
          elements = transformElements(data)

          deferred.resolve(elements)
        })
        .error(deferred.reject)

      return deferred.promise
    }

    /**
     * @name getSubscriberBasicElement
     * @description Get subscriber's basic element
     * @return {Object}
     */
    function getSubscriberBasicElement() {
      var deferred = $q.defer()

      // if already cached, return basic element
      if (basicElement) {
        return $q.when(basicElement)
      }

      getBySubscriber()
        .then(function() {
          // Basic element is cached rather than resolved by the getBySubscriber promise
          if (basicElement) {
            deferred.resolve(basicElement)
          } else {
            deferred.reject('No basic element')
          }
        })
        .catch(function(error) {
          deferred.reject(error)
        })

      return deferred.promise
    }

    /**
     * @name getByPage
     * @description Get elements associated with a page
     * @param {String} pageId page ID
     * @return {Promise}
     */
    function getByPage(pageId) {
      var deferred = $q.defer()

      // if already cached, return elements
      if (pageElements[pageId]) {
        return $q.when(pageElements[pageId])
      }

      elementsResource
        .get({ pageId: pageId })
        .success(function(data) {
          // cache data
          pageElements[pageId] = transformElements(data)

          deferred.resolve(pageElements[pageId])
        })
        .error(deferred.reject)

      return deferred.promise
    }

    /**
     * @name associateWithPage
     * @description create an element association with a page
     * @param  {String} pageId    page ID to be associated with
     * @param  {String} elementId element ID
     * @return {Promise}
     */
    function associateWithPage(pageId, elementId) {
      var deferred = $q.defer()

      pageElementsResource
        .create({ pageId: pageId, elementId: elementId })
        .success(associateSuccess)
        .error(deferred.reject)

      function associateSuccess() {
        // add element to page elements
        var element = _.find(elements, function(element) {
          return element.id === elementId
        })
        pageElements[pageId].push(element)
        deferred.resolve()
      }

      return deferred.promise
    }

    /**
     * @name dissociateFromPage
     * @description remove association between page and element
     * @param  {String} pageId    page ID to be associated with
     * @param  {String} elementId element ID
     * @return {Promise}
     */
    function dissociateFromPage(pageId, elementId) {
      var deferred = $q.defer()

      pageElementsResource
        .delete({ pageId: pageId, elementId: elementId })
        .success(dissociateSuccess)
        .error(deferred.reject)

      function dissociateSuccess() {
        _.remove(pageElements[pageId], function(element) {
          return element.id === elementId
        })
        deferred.resolve()
      }

      return deferred.promise
    }

    /**
     * @name clearElementCache
     * @description clear cached elements
     */
    function clearElementCache() {
      elements = null
    }

    /**
     * @name updateElement
     * @description update an element
     * @param {String} id element id
     * @param {Array} categories array of feedback category strings
     * @return {promise} promise
     */
    // TODO: remove this at some point?
    // function saveElementCategories (id, categories) {
    //     var deferred = $q.defer();

    //     elementsResource.updateFeedbackCategroies({ id: id, feedbackCategories: categories })
    //         .success(deferred.resolve)
    //         .error(deferred.reject);

    //     return deferred.promise;
    // }
  }
})()
