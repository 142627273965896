;(function() {
  'use strict'

  Controller.$inject = ["glTips", "filterService", "$timeout"];
  angular.module('glow.reporting').component('filter', Component())

  /* @ngInject */
  function Component() {
    return {
      templateUrl: 'filter.html',
      controller: Controller,
      bindings: {
        survey: '<',
        filter: '<',
        isReadOnly: '<readOnly',
        onEdit: '&?',
        onRemove: '&?',
      },
    }
  }

  /* @ngInject */
  function Controller(glTips, filterService, $timeout) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.$onChanges = onChanges
    ctrl.edit = edit
    ctrl.remove = remove

    function onInit() {
      $timeout(glTips.ready, 500)
    }

    function onChanges() {
      update(ctrl.filter)
    }

    function update(filter) {
      ctrl.filter = filter
      ctrl.info = filterService.getInfo(ctrl.survey, filter)
    }

    function edit() {
      if (ctrl.isReadOnly) {
        return
      }
      if (ctrl.onEdit) {
        ctrl.onEdit({ $filter: ctrl.filter })
      }
    }

    function remove(event) {
      event.stopPropagation()
      if (ctrl.onRemove) {
        ctrl.onRemove({ $filter: ctrl.filter })
      }
    }
  }
})()
