;(function() {
  'use strict'

  interactionListFactory.$inject = ["$log", "$state", "$location", "$window", "$filter", "interactionsService", "subscriberService"];
  angular
    .module('core.interactionList')
    .factory('InteractionList', interactionListFactory)

  /* @ngInject */
  function interactionListFactory(
    $log,
    $state,
    $location,
    $window,
    $filter,
    interactionsService,
    subscriberService
  ) {
    $log = $log.create('InteractionList')

    /**
     * InteractionList
     * @class
     * @classdesc An interaction list with states and methods to paginate and view interactions
     * @param {object} options - The constructor options
     */
    function InteractionList(options) {
      this.subscriberId = subscriberService.getSubscriber().id
      this.pages = {}
      this.options = {}

      _.defaultsDeep(this, options, this.Defaults)
    }

    /**
     * Instance Defaults
     */
    InteractionList.prototype.Defaults = {
      action: false,
      perPage: 50,
      orderBy: 'inboxUpdatedAt desc',
      state: 'idle',
      canModify: false,
      survey: null,
    }

    /**
     * Enum for states
     * @readonly
     * @num {string}
     */
    InteractionList.prototype.States = {
      IDLE: 'idle',
      LOADING: 'loading',
      READY: 'ready',
      ERROR: 'error',
    }

    /**
     * useData
     * @param {object} data - just show manual data
     */
    InteractionList.prototype.fromData = function(data) {
      this.pageNum = 0
      this.currentPage = this.pages[this.pageNum] = $filter(
        'groupInteractions'
      )(data)
      this.state = this.States.READY
      this.exhausted = true

      return this
    }

    /**
     * Goto page
     * @param {number} pageNum - the page number to load
     */
    InteractionList.prototype.goPage = function(pageNum) {
      var self = this
      var options = {
        top: this.perPage,
        skip: pageNum ? this.perPage * (pageNum - 1) : 0,
        orderBy: this.orderBy,
        filter: this.filter,
        fullInteraction: 1,
      }

      $location.search({ page: pageNum })

      if (this.pages[pageNum]) {
        setPage(this.pages[pageNum])
        return
      }

      this.state = this.States.LOADING

      interactionsService
        .getBySubscriber(this.subscriberId, options)
        .then(function(interactions) {
          setPage($filter('groupInteractions')(interactions))
          self.exhausted = self.perPage > interactions.length
        })

      function setPage(interactions) {
        self.currentPage = self.pages[pageNum] = interactions
        self.pageNum = pageNum
        self.state = self.States.READY
        $window.scrollTo(0, 0)
      }
    }

    /**
     * Goto previous page
     */
    InteractionList.prototype.prevPage = function() {
      if (this.isPrev()) {
        this.goPage(this.pageNum + 1)
      }
    }

    /**
     * Goto next page
     */
    InteractionList.prototype.nextPage = function() {
      if (this.isNext()) {
        this.goPage(this.pageNum - 1)
      }
    }

    /**
     * Check if there is a previous page
     * @returns {boolean} whether there is a previous page
     */
    InteractionList.prototype.isPrev = function() {
      var isInteractions = !!this.currentPage
      // var lessThanPageAmount = isInteractions && this.currentPage.length < this.perPage;
      var notExhausted = !this.exhausted

      return isInteractions && notExhausted // && lessThanPageAmount;
    }

    /**
     * Check if there is a next page
     * @returns {boolean} whether there is a next page
     */
    InteractionList.prototype.isNext = function() {
      return this.pageNum && this.pageNum > 1
    }

    /**
     * Show an interaction
     * @param {object} e - The event
     * @param {object} interaction - The data for the interaction
     */
    InteractionList.prototype.show = function(e, interaction) {
      interactionsService.showInteraction(
        e,
        interaction,
        this.action,
        this.canModify,
        this.survey
      )
    }

    InteractionList.prototype.getSelectedOnCurrentPage = function() {
      return _.filter(_.flatten(this.currentPage), { isSelected: true })
    }

    InteractionList.prototype.clearSelectedOnCurrentPage = function() {
      _.each(this.getSelectedOnCurrentPage(), function(interaction) {
        interaction.isSelected = false
      })
    }

    InteractionList.prototype.showDeleteResponsesDialog = function() {
      interactionsService.showBatchDeleteDialog(this.getSelectedOnCurrentPage())
    }

    InteractionList.prototype.onClickCheckbox = function(e, interaction) {
      if (interaction.isDeleted) return
      e.stopPropagation()
      interaction.isSelected = !interaction.isSelected
    }

    return InteractionList
  }
})()
