;(function() {
  'use strict'

  AddPageLinksDialogController.$inject = ["$log", "configService", "linkService"];
  angular
    .module('core.addPageLinksDialog')
    .controller('AddPageLinksDialogController', AddPageLinksDialogController)

  /* @ngInject */
  function AddPageLinksDialogController($log, configService, linkService) {
    $log = $log.create('AddPageLinksDialogController')

    var vm = this

    // link object
    vm.link = {
      reportingDimensions: {
        crd: [{ key: null, value: null }],
      },
    }

    vm.host = configService.getGlowLinkHost()

    // methods
    vm.save = save
    vm.dimChange = dimChange
    vm.dimBlur = dimBlur

    /**
     * @name save
     * @description save the element associations and close dialog on success
     */
    function save() {
      vm.saveError = null
      vm.saving = true

      // keep copy of dimensions in case of failure
      var dims = _.cloneDeep(vm.link.reportingDimensions)
      transformCrd()
      linkService
        .addToPage(vm.page.id, vm.link)
        .then(function() {
          vm.$scope.dialog.close()
        })
        .catch(function(error) {
          vm.link.reportingDimensions = dims

          if (JSON.parse(error).message === 'Duplicate shortUrl found') {
            vm.saveError = 'The link requested is already in use.'
            // $log.error('duplicate link ' + vm.link.fragmentRequest);
          } else {
            vm.saveError = 'Link could not be created'
            // $log.error('could not create link ' + vm.link.fragmentRequest);
          }
        })
        .finally(function() {
          vm.saving = false
        })
    }

    /**
     * @name transformCrd
     * @description transform crd array into obect for API
     */
    function transformCrd() {
      var transformedCrd = {}
      _.forEach(vm.link.reportingDimensions.crd, function(dim) {
        if (vm.addCrd && dim.key && dim.value) {
          transformedCrd[dim.key] = dim.value
        }
      })
      vm.link.reportingDimensions.crd = transformedCrd
    }

    /**
     * @name dimChange
     * @description on input change check to see if a new dimension input row is needed
     * @param  {Number} index dimension index in crd array
     */
    function dimChange(index) {
      var crds = vm.link.reportingDimensions.crd
      var crd = crds[index]
      if (crd.key && crd.value && isLast(index)) {
        crds.push({
          key: null,
          value: null,
        })
      }
    }

    /**
     * @name dimBlur
     * @description on input blur remove the row if both inputs are empty
     * @param  {Number} index dimension index in crd array
     */
    function dimBlur(index) {
      var crds = vm.link.reportingDimensions.crd
      var crd = crds[index]
      if (!crd.key && !crd.value && !isLast(index)) {
        crds.splice(index, 1)
      }
    }

    /**
     * @name isLast
     * @description check if index is the last index in the crd array
     * @param  {Number}  index index
     * @return {Boolean}       true if index is the last
     */
    function isLast(index) {
      return index === vm.link.reportingDimensions.crd.length - 1
    }
  }
})()
