;(function() {
  'use strict'

  Controller.$inject = ["userService", "View"];
  angular.module('app.core').component('viewEditor', {
    controller: Controller,
    templateUrl: 'view-editor.html',
    bindings: {
      view: '<',
      index: '<',
      survey: '<',
      isForbidden: '<',
      onDone: '&',
      onCancel: '&',
    },
  })

  /* @ngInject */
  function Controller(userService, View) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.isSSR = userService.isSSR()

    ctrl.getTitle = getTitle
    ctrl.canUseViewGroup = canUseViewGroup
    ctrl.getViewGroup = getViewGroup
    ctrl.setViewGroup = setViewGroup
    ctrl.addViewGroup = addViewGroup
    ctrl.removeViewGroup = removeViewGroup

    function onInit() {
      prepareGroupOptions()
    }

    function getTitle() {
      switch (ctrl.view.type) {
        case View.Types.INTRO:
          return 'Intro'
        case View.Types.SECTION:
          return 'Section'
        case View.Types.QUESTION:
          return [
            'Question ',
            ctrl.view.getNumber(),
            ' (',
            ctrl.view.value.label,
            ')',
          ].join('')
      }
    }

    function canUseViewGroup() {
      return ctrl.view.isType([View.Types.SECTION, View.Types.QUESTION])
    }

    function prepareGroupOptions() {
      if (!canUseViewGroup()) return

      ctrl.groupOptions = _.map(ctrl.survey.viewGroups, function(group) {
        return {
          label: group.name,
          value: group.id,
          data: group,
        }
      })
      ctrl.groupOptions.unshift({ label: 'None', value: null })
    }

    function getViewGroup() {
      var viewId = ctrl.view.value.id
      return ctrl.survey.getViewGroupByViewId(viewId)
    }

    function setViewGroup(viewGroupId, cancel) {
      var isCustom = !_.find(ctrl.groupOptions, { value: viewGroupId })
      if (isCustom) return cancel()
      ctrl.survey.setViewGroupForView(ctrl.view, viewGroupId)
    }

    function addViewGroup(option) {
      var isDuplicate = !!_.find(ctrl.groupOptions, { label: option.label })
      if (!option.label || isDuplicate) return
      var group = ctrl.survey.addViewGroup(option.label)
      ctrl.groupOptions = ctrl.groupOptions.concat({
        label: group.name,
        value: group.id,
        isCustom: true,
        data: group,
      })
      setViewGroup(group.id)
    }

    function removeViewGroup(option) {
      ctrl.groupOptions = _.filter(ctrl.groupOptions, function(item) {
        return item.value !== option.value
      })
      ctrl.survey.removeViewGroup(option.data)
    }
  }
})()
