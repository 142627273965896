;(function() {
  'use strict'

  Controller.$inject = ["$q", "$state", "glAnalytics", "featureService", "projectService", "userService", "surveyTemplateService", "accountService", "uacService"];
  angular.module('app.core').component('surveyTemplateDialog', {
    controller: Controller,
    templateUrl: 'survey-template-dialog.html',
    bindings: {
      template: '<',
      onDone: '&',
      onClose: '&',
    },
  })

  /* @ngInject */
  function Controller(
    $q,
    $state,
    glAnalytics,
    featureService,
    projectService,
    userService,
    surveyTemplateService,
    accountService,
    uacService
  ) {
    var ctrl = this

    ctrl.selectProject = selectProject
    ctrl.createFromTemplate = createFromTemplate
    ctrl.preview = surveyTemplateService.preview
    ctrl.$onInit = onInit

    function onInit() {
      if (userService.isLoggedIn()) {
        ctrl.loadingProjects = true
        projectService
          .load()
          .then(function(projects) {
            ctrl.projects = projects
            ctrl.showProjectPicker = ctrl.projects.length
          })
          .finally(function() {
            ctrl.loadingProjects = false
          })
      }
    }

    function selectProject(project) {
      ctrl.projectId = project.id
      createFromTemplate(ctrl.template)
    }

    function createFromTemplate(template) {
      window.featureService = featureService
      ensureLoggedIn().then(function() {
        if (featureService.canCreateSurvey()) {
          if (!uacService.canManageSurveys(true)) {
            return ctrl.onClose()
          }
          ctrl.onDone({})
          glAnalytics.track(
            'surveys',
            'create-from-kit-survey-template',
            template.title
          )
          $state.go('survey', {
            projectId: ctrl.projectId,
            surveyId: 'new',
            tab: 'editor',
            title: template.title,
            surveyTemplateId: template.id,
          })
        } else {
          ctrl.onClose()
          accountService.promptUpgrade({ context: 'more-surveys' })
        }
      })
    }

    function ensureLoggedIn() {
      return $q(function(resolve, reject) {
        if (userService.isLoggedIn()) {
          return resolve()
        }
        accountService
          .showAuthDialog('signup')
          .then(resolve)
          .catch(reject)
      })
    }
  }
})()
