;(function() {
  'use strict'

  Controller.$inject = ["$window", "productService", "configService"];
  angular.module('app.core').component('productListItem', {
    controller: Controller,
    templateUrl: 'product-list-item.html',
    bindings: {
      product: '<',
      selectable: '<',
      selected: '<',
      locked: '<',
      channel: '<',
      onSelect: '&',
    },
  })

  /* @ngInject */
  function Controller($window, productService, configService) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.edit = edit
    ctrl.cancel = cancel
    ctrl.save = save
    ctrl.remove = remove
    ctrl.confirmRemove = confirmRemove
    ctrl.rejectRemove = rejectRemove
    ctrl.toggleSelect = toggleSelect
    ctrl.goToChannel = goToChannel

    function onInit() {
      // ...
    }

    function edit(event) {
      event.preventDefault()
      ctrl.product.mode = 'edit'
    }

    function remove(event) {
      event.preventDefault()
      ctrl.product.mode = 'remove'
    }

    function confirmRemove() {
      productService.remove(ctrl.product).then(function() {
        ctrl.onSelect({ $selected: false })
      })
    }

    function rejectRemove() {
      ctrl.product.mode = 'view'
      ctrl.product.error = null
    }

    function cancel() {
      if (ctrl.product.isNew()) {
        productService.remove(ctrl.product)
      } else {
        ctrl.product.revert()
        ctrl.product.mode = 'view'
      }
    }

    function save() {
      if (!ctrl.product.validate()) {
        return
      }
      productService.save(ctrl.product).then(function() {
        ctrl.product.mode = 'view'
      })
    }

    function toggleSelect(event) {
      if (event.defaultPrevented) {
        return
      }
      if (ctrl.selectable && !ctrl.locked) {
        ctrl.onSelect({ $selected: !ctrl.selected })
      }
    }

    function goToChannel() {
      var url =
        configService.getSubscriberPortalUrl() +
        '/surveys/' +
        ctrl.channel.survey.id +
        '?tab=channels'
      $window.open(url, '_blank')
    }
  }
})()
