;(function() {
  'use strict'

  Controller.$inject = ["$scope", "glToast"];
  angular
    .module('app.survey-edit')
    .controller('SurveyExporterImport', Controller)

  /* @ngInject */
  function Controller($scope, glToast) {
    var ctrl = this

    ctrl.apply = apply
    ctrl.close = close

    function apply() {
      var didImport = ctrl.survey.import(ctrl.data)
      if (!didImport) {
        return glToast.show('Invalid JSON format. Please check your input!')
      }
      $scope.dialog.close()
    }

    function close() {
      $scope.dialog.cancel()
    }
  }
})()
