;(function() {
  'use strict'

  angular.module('app.core').component('logicSummary', {
    templateUrl: 'logic-summary.html',
    bindings: {
      survey: '<',
      logic: '<',
      question: '<',
    },
  })
})()
