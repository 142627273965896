;(function() {
  'use strict'

  angular.module('app.core').component('resourceSummary', {
    templateUrl: 'resource-summary.html',
    bindings: {
      resource: '<',
      canMoveUp: '<',
      canMoveDown: '<',
      onEdit: '&?',
      onRemove: '&?',
      onMoveUp: '&',
      onMoveDown: '&',
    },
  })
})()
