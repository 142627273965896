;(function() {
  'use strict'

  angular.module('app.core').factory('surveyExplorerPPTXService', Service)

  /* @ngInject */
  function Service() {
    var worker
    var ids = -1
    var handlers = new Map()

    return {
      generate: generate,
    }

    function generate(ppt) {
      if (!worker) createWorker()
      return new Promise(function(resolve, reject) {
        var id = ++ids
        handlers.set(id, { resolve, reject })
        worker.postMessage({
          id: id,
          ppt: ppt,
        })
      })
    }

    function createWorker() {
      worker = new Worker('/survey-explorer-pptx.worker.js')
      worker.onmessage = e => {
        var id = e.data.id
        var handler = handlers.get(id)
        if (e.data.resp) {
          handler.resolve(e.data.resp)
        } else {
          handler.reject(e.data.err)
        }
        handlers.delete(id)
      }
    }
  }
})()
