;(function() {
  'use strict'

  Controller.$inject = ["publisherService"];
  angular.module('app.core').component('publisherEditor', {
    controller: Controller,
    templateUrl: 'publisher-editor.html',
    bindings: {
      publisher: '<',
      onClose: '&',
      onDone: '&',
    },
  })

  /* @ngInject */
  function Controller(publisherService) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.save = save

    function onInit() {
      if (!ctrl.publisher) {
        ctrl.publisher = publisherService.getBlank()
      }
    }

    function save() {
      if (!ctrl.publisher.validate()) {
        return
      }
      publisherService.save(ctrl.publisher).then(function() {
        ctrl.onDone({ $publisher: ctrl.publisher })
      })
    }
  }
})()
