;(function() {
  'use strict'

  Controller.$inject = ["$scope", "subscriberService", "userService", "uacService", "glToast"];
  angular.module('app.account').component('accountTheme', {
    controller: Controller,
    templateUrl: 'account-theme.html',
  })

  /* @ngInject */
  function Controller(
    $scope,
    subscriberService,
    userService,
    uacService,
    glToast
  ) {
    var ctrl = this

    ctrl.save = save

    ctrl.$onInit = onInit

    function onInit() {
      getSubscriber()
    }

    function getSubscriber() {
      ctrl.subscriber = subscriberService.getSubscriber()
      ctrl.isAdmin = subscriberService.isAdmin(userService.getUser().id)
      ctrl.isSSR = userService.isSSR()
      ctrl.canModify =
        ctrl.isAdmin || ctrl.isSSR || uacService.canManageAnalysisSettings()
      ctrl.state = 'ready'
    }

    function save() {
      ctrl.saving = true
      subscriberService
        .saveSubscriber(ctrl.subscriber)
        .then(function() {
          $scope.$broadcast('theme-settings:rebuild')
        })
        .catch(function() {
          glToast.action('Could not update account settings.', 'Retry', save, {
            timeout: 5000,
          })
        })
        .finally(function() {
          ctrl.saving = false
        })
    }
  }
})()
