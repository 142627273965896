;(function() {
  'use strict'

  createPageConfig.$inject = ["$stateProvider"];
  angular.module('app.create-page', []).config(createPageConfig)

  /* @ngInject */
  function createPageConfig($stateProvider) {
    $stateProvider.state('create-page', {
      url: '/page/create',
      parent: 'portal',
      templateUrl: 'create-page.template.html',
      controller: 'CreatePageController',
      controllerAs: 'createPage',
      title: 'New Page',
    })
  }
})()
