;(function() {
  'use strict'

  Service.$inject = ["$log", "$q", "surveyReport"];
  angular.module('glow.reporting.charts').factory('reportTable', Service)

  function Service($log, $q, surveyReport) {
    var log = $log.create('reportTable')

    return {
      fromQuestion: fromQuestion,
      getFromOtherAnswers: getFromOtherAnswers,
      getFromTextAnswers: getFromTextAnswers,
    }

    function fromQuestion(question, filterSets, isAbsolute) {
      switch (question.type) {
        // aspect based
        case 'rank':
          return fromRank(question, isAbsolute)
        case 'matrix':
          return fromMatrix(question, isAbsolute)

        // non-aspect based
        case 'nps':
          return fromNPSQuestion(question, filterSets)
        case 'placesnearme':
          return fromPlacesNearMeQuestion(question)

        case 'constantsum':
          if (question.isMatrix) {
            return fromConstantSumMatrixQuestion(question)
          }
          return fromConstantSumQuestion(question, filterSets)
        case 'score':
          if (question.isMatrix) {
            return fromMatrix(question, isAbsolute)
          }
          return fromChoiceQuestion(question, filterSets)
        default:
          // TODO: validate that these are actually choice based
          return fromChoiceQuestion(question, filterSets)
      }
    }

    function fromRank(question, isAbsolute) {
      var columns = [
        {
          text: 'Choice',
          key: 'choice',
          minWidth: '67px',
          sortable: true,
          useSortValue: true,
        },
      ]
      _.each(question.ranks, function(rank) {
        columns.push({
          color: rank.color,
          key: rank.id,
          width: '37px',
          sortable: true,
          useSortValue: true,
          alignRight: true,
        })
      })
      columns.push({
        text: 'Total',
        key: 'total',
        width: '57px',
        sortable: true,
        useSortValue: true,
        alignRight: true,
      })

      var rows = []
      _.each(question.choices, function(choice, idx) {
        var row = {
          choice: {
            // value: choice.option,
            text: choice.option,
            sort: idx + 1,
          },
          clickValues: {
            $value: choice.option,
          },
        }
        var total = 0
        _.each(question.ranks, function(rank) {
          var aspect = _.find(question.aspects, {
            choiceId: choice.id,
            rankId: rank.id,
          })
          row[rank.id] = {
            text: isAbsolute ? aspect.count : aspect.percent + '%',
            sort: isAbsolute ? aspect.count : aspect.percent,
          }
          total += isAbsolute ? aspect.count : aspect.ratio
        })
        if (!isAbsolute) {
          total = Math.round(total * 100)
        }
        row.total = {
          text: total + (isAbsolute ? '' : '%'),
          sort: total,
        }
        rows.push(row)
      })

      var footer = {
        choice: {
          text: 'Total responses',
        },
      }
      var total = 0
      _.each(question.ranks, function(rank) {
        var rankTotal = 0
        _.each(question.choices, function(choice) {
          var aspect = _.find(question.aspects, {
            choiceId: choice.id,
            rankId: rank.id,
          })
          rankTotal += aspect.count
        })
        total += rankTotal
        footer[rank.id] = {
          text: rankTotal + '',
        }
      })
      footer.total = {
        text: total + '',
      }

      return {
        columns: columns,
        rows: rows,
        footer: footer,
        sort: {
          key: 'choice.sort',
          ascending: false,
        },
      }
    }

    function fromMatrix(question, isAbsolute) {
      var columns = [
        {
          text: 'Statement',
          key: 'statement',
          minWidth: '86px',
          sortable: true,
          useSortValue: true,
        },
      ]
      _.each(question.choices, function(choice, idx) {
        if (choice.data && choice.data.isModHidden) {
          // invisible but still used for calculations
          return
        }
        columns.push({
          color: choice.color,
          key: 'choice' + idx,
          width: '37px',
          sortable: true,
          useSortValue: true,
          alignRight: true,
        })
      })

      if (question.type === 'score') {
        columns.push({
          text: 'Score',
          key: 'score',
          width: '57px',
          sortable: true,
          useSortValue: true,
          alignRight: true,
        })
      }

      columns.push({
        text: 'Total',
        key: 'total',
        width: '57px',
        sortable: true,
        useSortValue: true,
        alignRight: true,
      })

      var rows = []
      _.each(question.statements, function(statement, idx) {
        var row = {
          statement: {
            // value: statement.id,
            text: statement.statement,
            sort: idx + 1,
          },
          clickValues: {
            $aspect: statement.id,
          },
        }
        var sAnswers = question.questionResults.values[statement.id].count
        _.each(question.choices, function(choice, idx) {
          var key = 'choice' + idx
          var cAnswers =
            question.questionResults.values[statement.id].values[choice.id]
              .count
          var cRatio = cAnswers / sAnswers
          var cPercent = Math.round(cRatio * 100)
          row[key] = {
            text: isAbsolute ? cAnswers : cPercent + '%',
            sort: isAbsolute ? cAnswers : cPercent,
          }
        })
        var total
        if (isAbsolute) {
          total = sAnswers
        } else {
          var qAnswers = question.questionResults.answered
          total = Math.round((sAnswers / qAnswers) * 100)
        }

        if (question.type === 'score') {
          var score = question.questionResults.score[statement.id]
          score = _.isNumber(score) ? Math.round(score) : '-'
          row.score = {
            text: score + '',
            sort: score,
          }
        }

        row.total = {
          text: total + (isAbsolute ? '' : '%'),
          sort: total,
        }
        rows.push(row)
      })

      var footer = {
        statement: {
          text: 'Total responses',
        },
      }
      _.each(question.choices, function(choice, idx) {
        var choiceTotal = 0
        _.each(question.statements, function(statement) {
          var aspect = _.find(question.aspects, {
            statementId: statement.id,
            choiceId: choice.id,
          })
          choiceTotal += aspect.count
        })
        footer['choice' + idx] = {
          text: choiceTotal + '',
        }
      })
      var total = 0
      _.each(question.statements, function(statement) {
        total += question.questionResults.values[statement.id].count
      })
      footer.total = {
        text: total + '',
      }

      return {
        columns: columns,
        rows: rows,
        footer: footer,
        sort: {
          key: 'statement.sort',
          ascending: false,
        },
      }
    }

    function getFromOtherAnswers(
      survey,
      question,
      filterSets,
      filterSetsOperator,
      responseType,
      loopKeyId
    ) {
      return $q(function(resolve, reject) {
        surveyReport
          .getOtherAnswers(
            survey,
            question.id,
            filterSets,
            filterSetsOperator,
            responseType,
            loopKeyId
          )
          .then(function(answers) {
            var columns = [
              {
                text: 'Choice',
                key: 'choice',
                sortable: false,
                width: '50%',
              },
              {
                text: 'Text',
                key: 'answer',
                sortable: false,
                width: '50%',
              },
            ]
            if (question.isMatrix) {
              _.each(columns, function(column) {
                column.width = '33%' // set columns width to 33%
              })
              columns.unshift({
                text: 'Statement',
                key: 'statement',
                sortable: false,
                width: '34%', // to make up for total of 100%
              })
            }

            var rows = []
            var sortIdx = 0
            _.each(answers, function(answer) {
              if (question.isMatrix) {
                _.each(answer.otherAnswers, function(choiceIds, statementId) {
                  var statement = _.find(question.statements, {
                    id: statementId,
                  })
                  _.each(choiceIds, function(otherAnswer, choiceId) {
                    var choice = _.find(question.choices, { id: choiceId })
                    var row = {
                      statement: {
                        value: statement.statement,
                        text: statement.statement,
                        color: statement.color,
                        icon: statement.icon,
                      },
                      choice: {
                        value: choice.option,
                        text: choice.displayLabel || choice.option,
                        color: choice.color,
                        icon: choice.icon,
                      },
                      answer: {
                        text: otherAnswer,
                        sort: sortIdx,
                      },
                      clickValues: {
                        $interactionId: answer.responseId,
                      },
                    }
                    rows.push(row)
                    sortIdx++
                  })
                })
              } else {
                _.each(answer.otherAnswers, function(otherAnswer, choiceId) {
                  var choice = _.find(question.choices, { id: choiceId })
                  var row = {
                    choice: {
                      value: choice.option,
                      text: choice.displayLabel || choice.option,
                      color: choice.color,
                      icon: choice.icon,
                    },
                    answer: {
                      text: otherAnswer,
                      sort: sortIdx,
                    },
                    clickValues: {
                      $interactionId: answer.responseId,
                    },
                  }
                  rows.push(row)
                  sortIdx++
                })
              }
            })

            resolve({
              columns: columns,
              rows: rows,
              sort: {
                key: 'answer.sort',
                ascending: false,
              },
            })
          })
          .catch(function(error) {
            reject(error)
          })
      })
    }

    function getFromTextAnswers(
      survey,
      questionId,
      filterSets,
      filterSetsOperator,
      responseType,
      loopKeyId
    ) {
      return $q(function(resolve, reject) {
        surveyReport
          .getTextAnswers(
            survey,
            questionId,
            filterSets,
            filterSetsOperator,
            responseType,
            loopKeyId
          )
          .then(function(textAnswers) {
            var columns = [
              {
                text: 'Response',
                key: 'answer',
                sortable: true,
              },
            ]

            _.remove(textAnswers, function(response) {
              return !response.textAnswer
            })

            var rows = []
            _.each(textAnswers, function(response, index) {
              var texts = response.textAnswer.split('|')
              _.each(texts, function(text) {
                var row = {
                  answer: {
                    text: text,
                    sort: index,
                  },
                  clickValues: {
                    $interactionId: response.responseId,
                  },
                }
                rows.push(row)
              })
            })

            resolve({
              columns: columns,
              rows: rows,
              sort: {
                key: 'answer.sort',
                ascending: false,
              },
            })
          })
          .catch(function(error) {
            reject(error)
          })
      })
    }

    function fromChoiceQuestion(question, filterSets) {
      var columns = [
        {
          text: '#',
          key: 'number',
          sortable: true,
          width: '43px',
          alignCenter: true,
        },
        {
          text: question.isHiddenVariables ? 'Variable' : 'Choice',
          key: 'choice',
          sortable: true,
        },
      ]

      if (question.type === 'score') {
        columns.push({
          text: 'Score',
          key: 'score',
          width: '57px',
          sortable: true,
          useSortValue: true,
          alignRight: true,
        })
      }

      columns.push({
        text: 'Total',
        key: 'total',
        sortable: true,
        width: '90px',
        alignCenter: true,
        useSortValue: true,
      })

      var rows = generateRowsFromChoices(question, filterSets)

      return {
        columns: columns,
        rows: rows,
        sort: {
          key: 'number.text',
          ascending: false,
        },
      }
    }

    function getQuestionFilter(questionId, filterSets) {
      var questionFilter
      _.each(filterSets, function(filterSet) {
        questionFilter = filterSet.findRules({
          questionId: questionId,
        })[0]
        if (questionFilter) {
          return false // break
        }
      })
      return questionFilter
    }

    function generateRowsFromChoices(question, filterSets) {
      var questionFilter = getQuestionFilter(question.id, filterSets)
      var filteredNonMulti =
        questionFilter && !(question.type === 'choice' && question.isMulti)
      var choices = _.cloneDeep(question.choices)

      // Sort the rating question choices in reverse
      if (question.type === 'rating') {
        choices = _.orderBy(choices, ['order'], ['desc'])
      }

      var rows = []
      // create a row for every choice
      _.each(choices, function(choice, index) {
        if (choice.data && choice.data.isModHidden) {
          // invisible but still used for calculations
          return
        }

        var isFiltered =
          questionFilter && _.includes(questionFilter.choices.items, choice.id)
            ? true
            : false

        var row = {
          focusValues: {
            $value: choice.displayLabel || choice.option,
          },
          number: {
            text: index + 1,
          },
          choice: {
            value: choice.option,
            text: choice.displayLabel || choice.option,
            color: choice.color,
            icon: choice.icon,
            isFiltered: isFiltered,
          },
          total: {
            // these are defaults (chartData is assigned below)
            text: filteredNonMulti && !isFiltered ? '-' : 0,
            sort: -1,
          },
          clickValues: {
            $value: choice.displayLabel || choice.option,
          },
        }

        if (question.type === 'score') {
          row.score = {
            text: choice.score,
            sort: choice.score,
          }
        }

        if (question.type === 'rating') {
          row.choice.ratingIcon = {
            icon: 'gi-star',
            count: row.choice.value,
          }
        }

        rows.push(row)
      })

      // populate rows from response data

      _.each(question.questionResults.values, function(option) {
        // find the row to be updated
        var row = _.find(rows, function(row) {
          if (_.isObject(option.optionValue)) {
            return // this data is malformed, ignore it
          }
          return (
            row.choice.value.toLowerCase() === option.optionValue.toLowerCase()
          )
        })

        if (!row) {
          log.warn('no row found for choice: ', option)
          return
        }

        var total = option.count
        var percent = (
          (option.count / question.questionResults.answered) *
          100
        ).toFixed(1)
        row.total.text = total + ' (' + percent + '%)'
        row.total.sort = total
      })

      return rows
    }

    function fromNPSQuestion(question, filterSets) {
      var questionFilter = getQuestionFilter(question.id, filterSets)
      var columns = [
        {
          text: 'Category',
          key: 'category',
          sortable: true,
          useSortValue: true,
        },
        {
          text: 'Total',
          key: 'total',
          sortable: true,
          width: '90px',
          alignCenter: true,
          useSortValue: true,
        },
      ]

      var rows = []
      var npsGroups = _.cloneDeep(question.questionResults.npsGroups)

      var GroupInfo = [
        {
          key: 'promoter',
          text: 'Promoters (9-10)',
          focusKey: 'Promoters',
          sort: 0,
          color: '#4caf50',
          choices: ['9', '10'],
        },
        {
          key: 'passive',
          text: 'Passives (7-8)',
          focusKey: 'Passives',
          sort: 1,
          color: '#838990',
          choices: ['7', '8'],
        },
        {
          key: 'detractor',
          text: 'Detractors (0-6)',
          focusKey: 'Detractors',
          sort: 2,
          color: '#f50057',
          choices: ['0', '1', '2', '3', '4', '5', '6'],
        },
      ]
      _.each(npsGroups, function(group, key) {
        var info = _.find(GroupInfo, { key: key })
        var showValues = true
        var isFilteredChoice = false

        // if the question is filtered for this choice, only show his numerical values
        if (questionFilter) {
          isFilteredChoice = !!_.find(questionFilter.choices.items, function(
            choiceId
          ) {
            var choice = _.find(question.choices, { id: choiceId })
            return choice && _.includes(info.choices, choice.option)
          })
          if (!isFilteredChoice) {
            showValues = false
          }
        }

        var percent = (
          (group.count / question.questionResults.answered) *
          100
        ).toFixed(1)

        rows.push({
          focusValues: {
            $value: info.focusKey,
          },
          category: {
            text: info.text,
            sort: info.sort,
            color: info.color,
            isFiltered: isFilteredChoice,
          },
          total: {
            text: showValues ? group.count + ' (' + percent + '%)' : '-',
            sort: showValues ? group.count : -1,
          },
          clickValues: {
            $value: info.focusKey,
          },
        })
      })

      return {
        columns: columns,
        rows: rows,
        sort: {
          key: 'category.sort',
          ascending: false,
        },
      }
    }

    function fromPlacesNearMeQuestion(question) {
      // var questionFilter = filterSetService.getFilters({ data: { questionId: question.id }})[0];

      var columns = [
        {
          text: '#',
          key: 'number',
          sortable: true,
          width: '50px',
          alignCenter: true,
          useSortValue: true,
        },
        { text: 'Name', key: 'name', sortable: true },
        {
          text: 'Total',
          key: 'total',
          sortable: true,
          width: '90px',
          alignCenter: true,
          useSortValue: true,
        },
      ]

      var rows = []

      // calculate total
      // var total = 0
      // _.each(question.questionResults.values, function(element) {
      //   total += element.count
      // })

      // convert values to element array
      var elements = _.map(question.questionResults.values)

      // build rows
      _.each(elements, function(element) {
        var percent = (
          (element.count / question.questionResults.answered) *
          100
        ).toFixed(1)
        rows.push({
          focusValues: {
            $value: { label: element.label }, // TODO: flatten
          },
          number: {
            text: element.label,
            sort: element.number,
          },
          name: {
            color: element.color,
            text:
              element.name + (element.address ? ', ' + element.address : ''),
          },
          total: {
            text: element.count + ' (' + percent + '%)',
            sort: element.count,
          },
          clickValues: {
            $value: element.sourceId,
          },
        })
      })

      return {
        columns: columns,
        rows: rows,
        sort: {
          key: 'number.sort',
          ascending: false,
        },
      }
    }

    function fromConstantSumQuestion(question, filterSets) {
      var columns = [
        {
          text: '#',
          key: 'number',
          sortable: true,
          width: '43px',
          alignCenter: true,
        },
        {
          text: 'Choice',
          key: 'choice',
          sortable: true,
        },
        {
          text: 'Total',
          key: 'total',
          sortable: true,
          width: '57px',
          useSortValue: true,
          alignRight: true,
        },
        {
          text: 'Average',
          key: 'average',
          sortable: true,
          width: '69px',
          useSortValue: true,
          alignRight: true,
        },
        {
          text: 'Count',
          key: 'count',
          sortable: true,
          width: '57px',
          useSortValue: true,
          alignRight: true,
        },
      ]

      var questionFilter = getQuestionFilter(question.id, filterSets)
      var rows = []
      _.each(question.choices, function(choice, index) {
        // ignore/dont-show if hidden
        if (choice.data && choice.data.isHidden) {
          return
        }
        var isFiltered =
          questionFilter && questionFilter.choiceId === choice.id ? true : false
        var choiceResult = question.questionResults.values[choice.id]
        var row = {
          focusValues: {
            $value: choice.displayLabel || choice.option,
          },
          number: {
            text: index + 1,
          },
          choice: {
            value: choice.option,
            text: choice.displayLabel || choice.option,
            color: choice.color,
            icon: choice.icon,
            isFiltered: isFiltered,
          },
          total: {
            text: choiceResult.total,
            sort: choiceResult.total,
          },
          average: {
            text: Math.round(choiceResult.average),
            sort: choiceResult.average,
          },
          count: {
            text: choiceResult.count,
            sort: choiceResult.count,
          },
          clickValues: {
            $value: choice.displayLabel || choice.option,
          },
        }
        rows.push(row)
      })

      return {
        columns: columns,
        rows: rows,
        sort: {
          key: 'number.text',
          ascending: false,
        },
      }
    }

    function fromConstantSumMatrixQuestion(question) {
      var columns = [
        {
          text: 'Statement',
          key: 'statement',
          minWidth: '86px',
          sortable: true,
          useSortValue: true,
        },
      ]
      _.each(question.choices, function(choice, idx) {
        columns.push({
          color: choice.color,
          key: 'choice' + idx,
          width: '37px',
          sortable: true,
          useSortValue: true,
          alignRight: true,
        })
      })
      columns.push({
        text: 'Total',
        key: 'total',
        width: '57px',
        sortable: true,
        useSortValue: true,
        alignRight: true,
      })
      columns.push({
        text: 'Average',
        key: 'average',
        width: '69px',
        sortable: true,
        useSortValue: true,
        alignRight: true,
      })

      var rows = []
      _.each(question.statements, function(statement, idx) {
        var row = {
          statement: {
            text: statement.statement,
            sort: idx + 1,
          },
          clickValues: {
            $aspect: statement.id,
          },
        }
        var totalNumber = 0
        _.each(question.choices, function(choice, idx) {
          var key = 'choice' + idx
          var aspect = _.find(question.aspects, {
            choiceId: choice.id,
            statementId: statement.id,
          })
          row[key] = {
            text: aspect.total,
            sort: aspect.total,
          }
          totalNumber += aspect.total
        })
        row.total = {
          text: totalNumber,
          sort: totalNumber,
        }
        var average = Math.round(totalNumber / question.choices.length)
        row.average = {
          text: average,
          sort: average,
        }
        rows.push(row)
      })

      var footer = {
        statement: {
          text: 'Total responses',
        },
      }
      var totalResponses = 0
      _.each(question.choices, function(choice, idx) {
        var choiceTotalResponses = 0
        _.each(question.statements, function(statement) {
          var aspect = _.find(question.aspects, {
            statementId: statement.id,
            choiceId: choice.id,
          })
          choiceTotalResponses += aspect.count
        })
        totalResponses += choiceTotalResponses
        footer['choice' + idx] = {
          text: choiceTotalResponses + '',
        }
      })
      footer.total = {
        text: totalResponses + '',
      }
      var averageResponses = Math.round(
        totalResponses / question.choices.length
      )
      footer.average = {
        text: averageResponses + '',
      }

      return {
        columns: columns,
        rows: rows,
        footer: footer,
        sort: {
          key: 'statement.sort',
          ascending: false,
        },
      }
    }
  }
})()
