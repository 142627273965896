;(function() {
  'use strict'

  Service.$inject = ["glDialog"];
  angular.module('app.core').service('surveyTemplateDialog', Service)

  /* @ngInject */
  function Service(glDialog) {
    this.show = show

    function show(options) {
      options = options || {}
      // prettier-ignore
      var template = [
        '<gl-dialog class="survey-template-dialog__dialog">',
        '<survey-template-dialog ',
          'project-id="projectId" ',
          'template="template" ',
          'on-done="dialog.close()" ',
          'on-close="dialog.cancel()" ',
        '/>',
        '</gl-dialog>',
      ]
      return glDialog.show({
        template: template.join(''),
        clickOutsideToClose: false,
        escapeToClose: true,
        locals: {
          projectId: options.projectId,
          template: options.template,
        },
      })
    }
  }
})()
