;(function() {
  'use strict'

  Service.$inject = ["subscriberService"];
  angular.module('app.core').service('featureService', Service)

  /* @ngInject */
  function Service(subscriberService) {
    this.canAddPanel = requireFeature('canAddPanel')
    this.canAddPremiumPublications = requireFeature('canAddPremiumPublications')
    this.canAddRewards = requireFeature('canAddRewards')
    this.canCloneSurveys = requireFeature('canCloneSurveys')
    this.canExportResponses = requireFeature('canExportResponses')
    this.canUseAdvancedAnalysisSharing = requireFeature(
      'canUseAdvancedAnalysisSharing'
    )
    this.canUseChannelRedirects = requireFeature('canUseChannelRedirects')
    this.canUseChannelCustomTargeting = requireFeature(
      'canUseChannelCustomTargeting'
    )
    this.canUseCustomTheme = requireFeature('canUseCustomTheme')
    this.canUseProjectResources = requireFeature('canUseProjectResources')
    this.canUseQuickPanel = requireFeature('canUseQuickPanel')
    this.canUseResearchServices = requireFeature('canUseResearchServices')
    this.canUseTagQuotas = requireFeature('canUseTagQuotas')
    this.canUseTranslation = requireFeature('canUseTranslation')
    this.hasUnlimitedAnalysisHistory = checkSubscriber(
      hasUnlimitedAnalysisHistory
    )
    this.getAnalysisHistoryDays = checkSubscriber(getAnalysisHistoryDays, 0)
    this.canCreatePlace = checkSubscriber(canCreatePlace)
    this.canCreateProduct = checkSubscriber(canCreateProduct)
    this.canCreateProject = checkSubscriber(canCreateProject)
    this.hasUnlimitedProjects = checkSubscriber(hasUnlimitedProjects)
    this.canCreateSurvey = checkSubscriber(canCreateSurvey)
    this.hasUnlimitedSurveys = checkSubscriber(hasUnlimitedSurveys)
    this.getRemainingSurveys = checkSubscriber(getRemainingSurveys, 0)

    this.canUpgrade = checkSubscriber(canUpgrade)

    function requireFeature(feature) {
      return function() {
        var subscriber = subscriberService.getSubscriber()
        if (!subscriber) return false
        return subscriber.plan.features[feature]
      }
    }

    // Guard against calling these methods when logged out
    function checkSubscriber(callback, defaultValue) {
      return function() {
        return getSubscriber() ? callback() : defaultValue
      }
    }

    function getSubscriber() {
      return subscriberService.getSubscriber()
    }

    function canUpgrade() {
      return getSubscriber().plan.canUpgrade
    }

    function canCreateProject() {
      return hasUnlimitedProjects() || getRemainingProjects() > 0
    }

    function hasUnlimitedProjects() {
      return getSubscriber().plan.features.maxProjects === null
    }

    function getRemainingProjects() {
      var quota = getSubscriber().plan.features.maxProjects
      var used = getSubscriber().usage.projects
      return _.clamp(quota - used, 0, quota)
    }

    function canCreateSurvey() {
      return hasUnlimitedSurveys() || getRemainingSurveys() > 0
    }

    function hasUnlimitedSurveys() {
      return getSubscriber().plan.features.maxSurveys === null
    }

    function getRemainingSurveys() {
      var quota = getSubscriber().plan.features.maxSurveys
      var used = getSubscriber().usage.surveys
      return _.clamp(quota - used, 0, quota)
    }

    function hasUnlimitedAnalysisHistory() {
      return getSubscriber().plan.features.maxAnalysisHistoryDays === null
    }

    function getAnalysisHistoryDays() {
      return getSubscriber().plan.features.maxAnalysisHistoryDays
    }

    function canCreatePlace() {
      return hasUnlimitedPlaces() || getRemainingPlaces() > 0
    }

    function hasUnlimitedPlaces() {
      return getSubscriber().plan.features.maxPlaces === null
    }

    function getRemainingPlaces() {
      var quota = getSubscriber().plan.features.maxPlaces
      var used = getSubscriber().usage.places
      return _.clamp(quota - used, 0, quota)
    }

    function canCreateProduct() {
      return hasUnlimitedProducts() || getRemainingProducts() > 0
    }

    function hasUnlimitedProducts() {
      return getSubscriber().plan.features.maxProducts === null
    }

    function getRemainingProducts() {
      var quota = getSubscriber().plan.features.maxProducts
      var used = getSubscriber().usage.products
      return _.clamp(quota - used, 0, quota)
    }
  }
})()
