;(function() {
  'use strict'

  config.$inject = ["$stateProvider"];
  angular.module('app.core').config(config)

  /* @ngInject */
  function config($stateProvider) {
    $stateProvider.state('publisherStore', {
      url: '/store/:publisherId',
      parent: 'portal',
      templateUrl: 'publisher-store.html',
      controller: 'PublisherStoreController',
      controllerAs: '$ctrl',
      title: 'Store',
      public: true,
    })

    $stateProvider.state('insights-publisher-store-deprecated', {
      url: '/insights/store/:publisherId',
      redirectTo: 'publisherStore',
    })
  }
})()
