;(function() {
  'use strict'

  angular.module('app.core').component('placeManagerDialog', {
    templateUrl: 'place-manager-dialog.html',
    bindings: {
      channel: '<',
      onDone: '&',
      onCancel: '&',
    },
  })
})()
