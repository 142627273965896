;(function() {
  'use strict'

  Controller.$inject = ["$scope", "$rootScope", "$window", "glAnalytics", "interactionsService", "filterSetService", "surveyReport", "reportQuestionExporter", "responseTypeService", "uacService", "reportMediaBtn", "glToast"];
  angular.module('glow.reporting').component('reportWidget', {
    controller: Controller,
    templateUrl: 'report-widget.html',
    bindings: {
      survey: '<',
      question: '<',
      filterSetGroup: '<',
      loopTracker: '<',
      chartType: '=?',
      onChartTypeChange: '&',
      table: '<',
      onTableChange: '&',
      isComparing: '<',
      onCompare: '&',
      onZoom: '&',
      absolute: '<',
      onTogglePercentage: '&',
      isPercentage: '<',
    },
  })

  /* @ngInject */
  function Controller(
    $scope,
    $rootScope,
    $window,
    glAnalytics,
    interactionsService,
    filterSetService,
    surveyReport,
    reportQuestionExporter,
    responseTypeService,
    uacService,
    reportMediaBtn,
    glToast
  ) {
    var ctrl = this

    var chartConfig = [
      { chartType: 'bar', canShowPercentage: true },
      { chartType: 'pie', canShowPercentage: true },
      { chartType: 'doughnut', canShowPercentage: true },
    ]

    ctrl.mediaBtnType = reportMediaBtn.Types.IMAGE

    // methods
    ctrl.hasValidLoopConfig = hasValidLoopConfig
    ctrl.canShowChart = canShowChart
    ctrl.showInteraction = showInteraction
    ctrl.requestFilterChange = requestFilterChange
    ctrl.onTableSort = onTableSort
    ctrl.getSelectedLoopKeyId = getSelectedLoopKeyId
    ctrl.exportDownload = exportDownload
    ctrl.setChartType = setChartType
    ctrl.setTable = setTable
    ctrl.canTogglePercentage = canTogglePercentage
    ctrl.togglePercentage = togglePercentage
    ctrl.toMarkdown = toMarkdown
    ctrl.getLegend = getLegend
    ctrl.shouldShowRotateButton = shouldShowRotateButton
    ctrl.canEditTheme = canEditTheme
    ctrl.editTheme = editTheme

    ctrl.$onInit = onInit
    ctrl.$onChanges = onChanges

    function onInit() {
      // isPercentage may be bound as false, which we want to keep
      ctrl.isPercentage = _.defaultTo(ctrl.isPercentage, true)
      ctrl.showResults = !ctrl.question.isHidden || ctrl.isComparing
      if (ctrl.question.type === 'score' && ctrl.question.isMatrix) {
        // score doesn't show "All Statements" option on statement switcher
        // so we pre-select the subType with first statement
        ctrl.subtype = ctrl.question.statements[0].id
      } else {
        ctrl.subtype = null
      }
    }

    function onChanges(changes) {
      if (changes.question && changes.question.isFirstChange()) {
        ctrl.allowChartClick = ctrl.allowRowClick =
          ctrl.survey.options.canEditFilters
        ctrl.chartConfig = _.find(surveyReport.chartConfigs, function(config) {
          var typeCheck = config.questionType === ctrl.question.type
          var multiCheck = config.hasOwnProperty('isMulti')
            ? config.isMulti === ctrl.question.isMulti
            : true
          var matrixCheck = config.hasOwnProperty('isMatrix')
            ? config.isMatrix === ctrl.question.isMatrix
            : true

          return typeCheck && multiCheck && matrixCheck
        })
        ctrl.chartType = ctrl.chartType || ctrl.chartConfig.default
        ctrl.canExport = reportQuestionExporter.canExport(ctrl.question.type)
        ctrl.tableMaxHeight = 269
        ctrl.chartHeight = getChartHeight()
        ctrl.isDataTable = !_.includes(
          ['singletext', 'text', 'scan', 'location', 'image'],
          ctrl.question.type
        )
        ctrl.isAnswersTable = _.includes(
          ['singletext', 'text', 'scan'],
          ctrl.question.type
        )
        if (ctrl.table) {
          // do nothing
        } else if (ctrl.isComparing && ctrl.isDataTable) {
          ctrl.table = 'data'
        } else if (ctrl.isComparing && ctrl.isAnswersTable) {
          ctrl.table = 'answers'
        }
      }

      if (ctrl.question.hasResults) {
        ctrl.isOtherAnswersTable = ctrl.question.hasOtherAnswers
      }

      if (changes.absolute) {
        if (ctrl.absolute) {
          // do nothing
        } else if (ctrl.question.type === 'constantsum') {
          ctrl.absolute = {
            max: ctrl.question.questionResults.absolute.max,
          }
        } else {
          ctrl.absolute = {
            max: ctrl.question.questionResults.popularCount,
          }
        }
      }
    }

    function getSelectedLoopKeyId() {
      return ctrl.question.loop
        ? ctrl.loopTracker.getSelected(ctrl.question.loop.id)
        : null
    }

    function exportDownload() {
      reportQuestionExporter
        .toData(
          ctrl.survey,
          ctrl.question,
          ctrl.filterSetGroup.getSelected(),
          ctrl.filterSetGroup.getOperator(),
          responseTypeService.getSelected(),
          getSelectedLoopKeyId()
        )
        .then(function(data) {
          data.download()
        })
        .catch(function(error) {
          glToast.show('Failed to Download as CSV. Message: ' + error.message)
        })
      glAnalytics.track('ReportQuestionExport', 'download', ctrl.question.id)
    }

    function hasValidLoopConfig() {
      if (!ctrl.question.loop) return true
      return ctrl.loopTracker.has(ctrl.question.loop.id)
    }

    function canShowChart(type) {
      return _.includes(ctrl.chartConfig.types, type)
    }

    function showInteraction(interactionId) {
      interactionsService.showInteraction(
        null,
        {
          id: interactionId,
          parentType: 'survey-response',
        },
        null,
        ctrl.survey.options.canEditResponses,
        ctrl.survey
      )
    }

    function getChartHeight() {
      var isMobile = $window.innerWidth < 415
      if (isMobile) {
        return 150
      } else if (ctrl.question.type === 'location') {
        return 580
      } else {
        return 290
      }
    }

    function requestFilterChange(value, aspect) {
      filterSetService.requestFilterChange({
        questionId: ctrl.question.id,
        questionType: ctrl.question.type,
        isProfiling: ctrl.question.isProfiling,
        value: value,
        aspect: aspect,
      })
    }

    function onTableSort(sortConfig) {
      // TODO: validate sortConfig by question type?
      ctrl.sortConfig = sortConfig
    }

    function setChartType(chartType) {
      ctrl.chartType = chartType
      $rootScope.$broadcast('gl-masonry:update-hint')
      ctrl.onChartTypeChange({ $chartType: chartType })
    }

    function setTable(table) {
      ctrl.table = ctrl.table === table ? null : table
      $rootScope.$broadcast('gl-masonry:update-hint')
      ctrl.onTableChange({ $table: ctrl.table })
    }

    function canTogglePercentage() {
      var config = _.find(chartConfig, { chartType: ctrl.chartType })
      return config && config.canShowPercentage
    }

    function togglePercentage() {
      ctrl.isPercentage = !ctrl.isPercentage
      ctrl.onTogglePercentage({ $isPercentage: ctrl.isPercentage })
    }

    function toMarkdown(string) {
      return window.marked(string)
    }

    function getLegend() {
      if (ctrl.question.isMaxDiff && ctrl.chartType === 'matrixbar') {
        return ctrl.question.statementLegend
      }
      if (ctrl.question.type === 'rank' && ctrl.chartType === 'matrixbar') {
        return ctrl.question.choiceLegend
      }
      return ctrl.question.legend
    }

    function shouldShowRotateButton() {
      return _.includes(['bar', 'rating'], ctrl.chartType)
    }

    function canEditTheme() {
      return (
        ctrl.survey.options.showReportSettingsButton &&
        !_.includes(['cloud', 'gallery', 'pointmap', 'heatmap'], ctrl.chartType)
      )
    }

    function editTheme() {
      if (!ctrl.survey.options.canEditReportSettings) {
        return uacService.showAlertDialog('edit chart theme')
      }
      $scope.$emit('edit-question-theme', ctrl.question)
    }
  }
})()
