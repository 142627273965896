;(function() {
  'use strict'

  glBootLoader.$inject = ["$transitions"];
  angular.module('core.bootLoader').directive('glBootLoader', glBootLoader)

  /* @ngInject */
  function glBootLoader($transitions) {
    return {
      restrict: 'EA',
      templateUrl: 'boot-loader.template.html',
      link: link,
    }

    function link(scope, element) {
      // on first state chage success remove the loader
      var loaded = $transitions.onSuccess(null, function() {
        element.remove()
        loaded()
      })
    }
  }
})()
