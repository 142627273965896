;(function() {
  'use strict'

  angular.module('app.core').factory('ResponseAnswer', Factory)

  /* @ngInject */
  function Factory() {
    function ResponseAnswer() {
      this.applyDefaults()
    }

    ResponseAnswer.prototype.applyDefaults = function() {
      _.defaultsDeep(this, {
        questionId: null,
        selected: [
          /*
          {
            statementId
            choiceId
            rank
            number
            text
            skipped
          }
        */
        ],
        places: [
          /*
          {
            id
            name
            address
            city
            state
            country
          }
          */
        ],
        number: null,
        text: null,
        seen: null,
        skipped: null,
        hiddenChoiceIds: [],
        hiddenStatementIds: [],
      })
    }

    ResponseAnswer.prototype.deserialize = function(data) {
      this.questionId = data.questionId
      var selected = []
      _.each(data.statementAnswers, function(item) {
        selected.push({
          statementId: item.id,
          choiceId: item.optionId,
          rank: null,
          number: item.number,
          text: item.text,
          skipped: item.skipped,
        })
      })
      _.each(data.choiceAnswers, function(item) {
        selected.push({
          statementId: null,
          choiceId: item.id,
          rank: item.rank,
          number: item.number,
          text: item.text,
          skipped: false,
        })
      })
      this.selected = selected
      var places = []
      _.each(data.placesAnswers, function(item) {
        places.push({
          id: item.sourceId,
          label: _.remove([
            item.name,
            item.address,
            item.city,
            item.state,
            item.country,
          ]).join(', '),
        })
      })
      this.places = places
      this.number = data.numericAnswer
      this.text = data.textAnswer === '<not-asked>' ? null : data.textAnswer // remove the bullshit
      this.seen = data.seen
      this.skipped = data.skipped
      this.hiddenChoiceIds = data.hiddenChoiceIds
      this.hiddenStatementIds = data.hiddenStatementIds
      this.applyDefaults()
      return this
    }

    return ResponseAnswer
  }
})()
