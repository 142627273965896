;(function() {
  'use strict'

  compileHtmlDirective.$inject = ["$compile"];
  angular
    .module('core.compileHtml')
    .directive('compileHtml', compileHtmlDirective)

  /* @ngInject */
  function compileHtmlDirective($compile) {
    return {
      restrict: 'A',
      link: link,
    }

    function link(scope, element, attrs) {
      scope.$watch(attrs.compileHtml, doCompile)

      function doCompile() {
        var htmlString = scope.$eval(attrs.compileHtml)
        element.html(htmlString)
        try {
          $compile(element.contents())(scope)
        } catch (err) {
          console.error('[compile-html] failed to parse: ' + htmlString)
        }
      }
    }
  }
})()
