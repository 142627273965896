;(function() {
  'use strict'

  PageGeneralController.$inject = ["$rootScope", "$scope", "$state", "glDialog", "glTips", "pagesService", "glToast"];
  angular
    .module('app.page-general')
    .controller('PageGeneralController', PageGeneralController)

  /* @ngInject */
  function PageGeneralController(
    $rootScope,
    $scope,
    $state,
    glDialog,
    glTips,
    pagesService,
    glToast
  ) {
    var vm = this

    // make copy of page for this view model as to not polute the global
    vm.page = _.cloneDeep($scope.page.page)

    // methods
    vm.updatePage = updatePage
    vm.deletePage = deletePage

    glTips.ready()

    /**
     * @name updatePage
     * @description update page details
     */
    function updatePage() {
      vm.saving = true
      // HACK:
      // Updates to the images are not reflected in vm.page, so need to copy them manually.
      vm.page.featureImageUrl = $scope.page.page.featureImageUrl
      vm.page.logoUrl = $scope.page.page.logoUrl

      pagesService
        .update(vm.page)
        .catch(function() {
          glToast.action('Page could not be saved.', 'Retry', updatePage, {
            timeout: 5000,
          })
        })
        .finally(function() {
          vm.saving = false
          $rootScope.$broadcast('page-general:saved')
        })
    }

    /**
     * @name deletePage
     * @description remove page from this subscription
     */
    function deletePage() {
      glDialog
        .confirm(
          'Are you sure?',
          'Deleting this page will no longer allow you to view only feedback attributed to this page.',
          {
            clickOutsideToClose: true,
            locals: {
              cancelText: 'No',
              closeText: 'Yes',
            },
          }
        )
        .then(doDelete)

      function doDelete() {
        pagesService
          .deletePage(vm.page)
          .then(function() {
            $state.go('dashboard')
          })
          .catch(function() {
            glToast.action('Could not delete page.', 'Retry', deletePage, {
              timeout: 5000,
            })
          })
      }
    }
  }
})()
