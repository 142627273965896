;(function() {
  'use strict'

  Controller.$inject = ["$state", "StateFactory", "userService", "subscriberService", "kitsService"];
  angular.module('app.kits').controller('Kits', Controller)

  /* @ngInject */
  function Controller(
    $state,
    StateFactory,
    userService,
    subscriberService,
    kitsService
  ) {
    var ctrl = this

    ctrl.select = select

    init()

    function init() {
      ctrl.isLoggedIn = userService.isLoggedIn()
      ctrl.promoId = $state.params.promo
      ctrl.state = new StateFactory(['loading', 'loaded', 'zero', 'error'])
      ctrl.state.loading()
      getKits()
        .then(function(kits) {
          // HACK: exclude kits with no surveys
          _.remove(kits, { surveyTemplateCount: 0 })

          ctrl.kits = kits
          var hasKits = ctrl.kits.length > 0

          if (ctrl.promoId) {
            ctrl.promo = _.find(ctrl.kits, { id: ctrl.promoId })
            _.remove(ctrl.kits, { id: ctrl.promoId })
          }

          if (hasKits) {
            ctrl.state.loaded()
          } else {
            ctrl.state.zero()
          }
        })
        .catch(function() {
          ctrl.state.error()
        })
    }

    function getKits() {
      if (ctrl.isLoggedIn) {
        var subscriber = subscriberService.getSubscriber()
        return kitsService.getAssignedKits(subscriber.id)
      } else {
        return kitsService.getAll()
      }
    }

    function select(kit) {
      $state.go('kit', { id: kit.id })
    }
  }
})()
