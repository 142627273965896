;(function() {
  'use strict'

  Controller.$inject = ["subscriberService"];
  angular.module('app.core').component('channelConfirmReward', {
    templateUrl: 'channel-confirm-reward.html',
    controller: Controller,
    bindings: {
      channel: '<',
      survey: '<',
      onConfirm: '&',
      onCancel: '&',
    },
  })

  /* @ngInject */
  function Controller(subscriberService) {
    var ctrl = this

    ctrl.$onInit = onInit

    function onInit() {
      ctrl.subscriber = subscriberService.getSubscriber()
      ctrl.plan = ctrl.subscriber.plan
      ctrl.currency = ctrl.subscriber.billing.currency || 'AUD'
      ctrl.costPerResponse = _.find(ctrl.plan.pricing.responses, {
        type: ctrl.survey.lengthOfInterview.type.type,
      })
        .price.byCurrency(ctrl.currency)
        .format()
      ctrl.costPerExit =
        ctrl.survey.hasExitLogic() &&
        ctrl.plan.pricing.exits.byCurrency(ctrl.currency).format()
      ctrl.spendTarget = ctrl.channel.reward.spendTarget.format()
    }
  }
})()
