;(function() {
  'use strict'

  Controller.$inject = ["subscriberService"];
  angular.module('core.kitSections').component('kitSections', {
    controller: Controller,
    templateUrl: 'kit-sections.html',
    bindings: {
      control: '&',
      kits: '<',
      onSelect: '&',
    },
  })

  /* @ngInject */
  function Controller(subscriberService) {
    var ctrl = this

    ctrl.$onInit = onInit

    function onInit() {
      ctrl.subscriber = subscriberService.getSubscriber()

      // Cloning so as not to mutate the original data
      ctrl.otherKits = _.cloneDeep(ctrl.kits)

      ctrl.bespokeKits = _.remove(ctrl.otherKits, { bespoke: true })
      ctrl.hasBespokeKits = ctrl.bespokeKits.length > 0

      ctrl.recommendedKits = _.remove(ctrl.otherKits, isRecommendedKit)
      ctrl.hasRecommendedKits = ctrl.recommendedKits.length > 0

      ctrl.hasOtherKits = ctrl.otherKits.length > 0

      // Expose onInit so that parents can call it when kits need to be re-ordered
      ctrl.control({ $controller: { refresh: onInit } })
    }

    function isRecommendedKit(kit) {
      if (!ctrl.subscriber || !kit) {
        return false
      }

      if (
        kit.country &&
        kit.industry &&
        ctrl.subscriber.country &&
        ctrl.subscriber.industry
      ) {
        return (
          kit.country.id === ctrl.subscriber.country.id &&
          kit.industry.id === ctrl.subscriber.industry.id
        )
      }
    }
  }
})()
