;(function() {
  'use strict'

  Controller.$inject = ["$state", "glTips", "app", "linkService", "userService", "publicationService", "publisherService", "configService"];
  angular.module('app.core').controller('PublisherStoreController', Controller)

  /* @ngInject */
  function Controller(
    $state,
    glTips,
    app,
    linkService,
    userService,
    publicationService,
    publisherService,
    configService
  ) {
    var ctrl = this

    ctrl.isVisible = isVisible
    ctrl.share = share
    ctrl.trackCtaClick = publisherService.trackCtaClick

    init()

    function init() {
      ctrl.publisherId = $state.params.publisherId
      ctrl.isLoggedIn = userService.isLoggedIn()
      ctrl.isPublisher = publisherService.isPublisher(ctrl.publisherId)
      publisherService.getById(ctrl.publisherId).then(function(publisher) {
        ctrl.publisher = publisher
        var filters = [
          'inStore eq true', // TODO: remove once this has been filtered on BE
          'ownerId eq ' + ctrl.publisher.ownerId,
        ]
        // limit the reports to 10k and sort by creation date descending
        var paginationObj = {
          top: 10000,
          skip: 0,
          orderby: 'createdAt desc',
        }
        ctrl.all = publicationService.getByView(
          'store',
          filters.join(' and '),
          paginationObj
        )
        app.setTitle(ctrl.publisher.name)
        app.setTags(ctrl.publisher.getTags())
        glTips.ready()
      })
    }

    function isVisible(publication) {
      return publication.isFiltered(ctrl.filter)
    }

    function share() {
      var url = configService.getSubscriberPortalUrl(
        '/insights/store/' + ctrl.publisherId
      )
      linkService.generate(url, true)
    }
  }
})()
