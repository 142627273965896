;(function() {
  'use strict'

  PageElementsController.$inject = ["$scope", "$log", "$stateParams", "elementService", "glToast", "glDialog"];
  angular
    .module('app.page-elements')
    .controller('PageElementsController', PageElementsController)

  /* @ngInject */
  function PageElementsController(
    $scope,
    $log,
    $stateParams,
    elementService,
    glToast,
    glDialog
  ) {
    $log = $log.create('PageElementsController')

    var vm = this

    // methods
    vm.showAddElementsToPage = showAddElementsToPage
    vm.dissociateElement = dissociateElement

    activate()

    /**
     * Initialise
     */
    function activate() {
      getPageElements()
    }

    /**
     * @name getPageElements
     * @description get list of elements associated with the page
     */
    function getPageElements() {
      elementService
        .getByPage($scope.page.page.id)
        .then(getSuccess)
        .catch(getError)

      function getSuccess(data) {
        vm.elements = data
        vm.state = 'ready'
      }

      function getError() {
        vm.state = 'error'
        $log.error('could not load elements')
      }
    }

    /**
     * @name showAddElementsToPage
     * @description show the add-page-elements-dialog dialog
     */
    function showAddElementsToPage() {
      glDialog.show({
        templateUrl: 'add-page-elements-dialog.template.html',
        controller: 'AddPageElementsDialogController',
        bindToController: true,
        controllerAs: 'addElements',
        locals: {
          page: $scope.page.page,
          pageElements: vm.elements,
        },
      })
    }

    /**
     * @name dissociateElement
     * @description confirm element dissociation and then remove it from the page
     */
    function dissociateElement(element) {
      // confirm action
      glDialog
        .confirm(
          'Comfirm',
          'Are you sure you want to remove ' +
            element.title +
            "'s accociation with this page?"
        )
        .then(confirmed)

      function confirmed() {
        vm.dissociating = true

        elementService
          .dissociateFromPage($scope.page.page.id, element.id)
          .catch(function() {
            glToast.show('Element could not be dissociated', {
              position: 'bottom-right',
            })
            $log.error('Could not dissociate element ' + element.id)
          })
          .finally(function() {
            vm.dissociating = false
          })
      }
    }
  }
})()
