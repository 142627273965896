;(function() {
  'use strict'

  Controller.$inject = ["$q", "$state", "StateFactory", "glAnalytics", "glDialog", "glToast", "featureService", "userService", "subscriberService", "projectService", "surveyService", "surveyServiceNext", "accountService", "channelService", "projectResourceService", "uacService", "publicationService"];
  angular.module('app.project').controller('Project', Controller)

  /* @ngInject */
  function Controller(
    $q,
    $state,
    StateFactory,
    glAnalytics,
    glDialog,
    glToast,
    featureService,
    userService,
    subscriberService,
    projectService,
    surveyService,
    surveyServiceNext,
    accountService,
    channelService,
    projectResourceService,
    uacService,
    publicationService
  ) {
    var ctrl = this

    ctrl.state = new StateFactory(['loading', 'loaded', 'error'])

    ctrl.setTab = setTab
    ctrl.getTitle = getTitle
    ctrl.create = create
    ctrl.createReport = createReport
    ctrl.view = view
    ctrl.editProject = editProject
    ctrl.archiveProject = projectService.promptAndArchive
    ctrl.unarchiveProject = projectService.promptAndUnarchive
    ctrl.archive = archive
    ctrl.unArchive = unArchive
    ctrl.clone = clone
    ctrl.move = move
    ctrl.addResource = addResource

    init()

    function init() {
      ctrl.id = $state.params.id
      ctrl.isSSR = userService.isSSR()
      ctrl.subscriber = subscriberService.getSubscriber()
      ctrl.state.loading()
      ctrl.canUseProjectResources = featureService.canUseProjectResources()
      ctrl.canUseResearchServices = featureService.canUseResearchServices()

      loadProject()
        .then(function() {
          return $q.all([loadSurveys(), loadReports()])
        })
        .then(function() {
          if (!ctrl.surveys.length && ctrl.reports.length) {
            setTab('reports')
          } else {
            setTab('surveys')
          }
          ctrl.state.loaded()
        })
        .catch(function() {
          ctrl.state.error()
        })
    }

    function loadProject() {
      return projectService.get(ctrl.id).then(function(project) {
        ctrl.project = project
      })
    }

    function loadSurveys() {
      return surveyService
        .getByProject(ctrl.project.id)
        .then(function(surveys) {
          ctrl.archived = _.remove(surveys, { isArchived: true })
          ctrl.surveys = surveys
        })
    }

    function loadReports() {
      return publicationService
        .getByProject(ctrl.project.id)
        .then(function(reports) {
          ctrl.reports = reports
        })
    }

    function setTab(tab) {
      ctrl.tab = tab
    }

    function getTitle() {
      if (!ctrl.project) return 'Project'
      return ctrl.project.isPrivate()
        ? ctrl.project.name + ' (Private)'
        : ctrl.project.name
    }

    function create() {
      accountService
        .promptUpgrade({
          context: 'more-surveys',
          bypassCondition: featureService.canCreateSurvey(),
        })
        .then(function() {
          createNewSurvey()
        })
    }

    function createNewSurvey() {
      if (!uacService.canManageSurveys(true)) return
      $state.go('survey', {
        projectId: ctrl.project.id,
        surveyId: 'new',
        tab: 'editor',
      })
    }

    function createReport() {
      if (!uacService.canManageReports(true)) return
      $state.go('publicationEditor', { id: 'new', projectId: ctrl.project.id })
    }

    function view(survey) {
      if (ctrl.project.isArchived) return
      $state.go('survey', {
        surveyId: survey.id,
      })
    }

    function editProject() {
      if (!uacService.canManageProjects(true)) return
      projectService.edit(ctrl.project)
    }

    function archive(survey) {
      if (!uacService.canManageSurveys(true)) return
      var message =
        survey.activeChannelCount > 0
          ? 'Archiving this survey will turn off all live channels and people will no ' +
            'longer be able to respond to this survey. Do you still wish to archive it?'
          : 'Are you sure you want to archive this survey?'
      glDialog
        .confirm('Archive Survey', message)
        .then(function() {
          return channelService.disableSurveyChannels(
            survey.id,
            ctrl.subscriber.id
          )
        })
        .then(function() {
          survey.activeChannelCount = 0
        })
        .then(function() {
          _.remove(ctrl.surveys, survey)
          ctrl.archived.unshift(survey)
          surveyServiceNext.archive(survey).catch(function(err) {
            glToast.show('Error archiving survey')
            _.remove(ctrl.archived, survey)
            ctrl.surveys.unshift(survey) // KISS: it wont return to the exact original position
            order()
            throw err
          })
          order()
        })
        .catch(function() {
          glToast.show('Error archiving survey')
        })
    }

    function unArchive(survey) {
      if (ctrl.project.isArchived) return
      if (!uacService.canManageSurveys(true)) return
      glDialog
        .confirm(
          'Un-Archive Survey',
          'Are you sure you want to un-archive this survey?'
        )
        .then(function() {
          _.remove(ctrl.archived, survey)
          ctrl.surveys.unshift(survey)
          surveyServiceNext.unArchive(survey).catch(function(err) {
            glToast.show('Error archiving survey')
            _.remove(ctrl.surveys, survey)
            ctrl.archived.unshift(survey)
            order()
            throw err
          })
          order()
        })
    }

    function clone(survey) {
      accountService
        .promptUpgrade({
          bypassCondition:
            featureService.canCloneSurveys() || userService.isSSR(),
          context: 'clone-survey',
        })
        .then(function() {
          if (!uacService.canManageSurveys(true)) return
          projectService
            .pick({
              headerTitle: 'Duplicate To Project',
            })
            .then(function(selection) {
              glAnalytics.track('surveys', 'clone-from-survey', survey.id)
              var projectId = selection.project.id
              $state.go('survey', {
                projectId: projectId,
                cloneSurveyId: survey.id,
                surveyId: 'new',
              })
            })
        })
    }

    function move(survey) {
      if (!uacService.canManageSurveys(true)) return
      var currentProjectId = survey.projectId
      projectService
        .pick({
          headerTitle: 'Move To Project',
          hideProjectId: currentProjectId,
        })
        .then(function(selection) {
          survey.projectId = selection.project.id
          if (survey.isArchived) {
            _.remove(ctrl.archived, survey)
          } else {
            _.remove(ctrl.surveys, survey)
          }
          surveyServiceNext
            .changeProject(survey.id, survey.projectId)
            .then(function() {
              glToast.show('Survey has been moved')
            })
            .catch(function() {
              survey.projectId = currentProjectId
              if (survey.isArchived) {
                ctrl.archived.unshift(survey)
              } else {
                ctrl.surveys.unshift(survey)
              }
              order()
              glToast.show('Error moving survey')
            })
        })
    }

    function addResource() {
      if (!uacService.canManageProjects(true)) return
      projectResourceService.showEditor(ctrl.id)
    }

    function order() {
      ctrl.surveys = surveyService.order(ctrl.surveys)
      ctrl.archived = surveyService.order(ctrl.archived)
    }
  }
})()
