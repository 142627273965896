;(function() {
  'use strict'

  angular.module('glow.reporting').component('filterSetEditor', {
    controller: Controller,
    templateUrl: 'filter-set-editor.html',
    bindings: {
      survey: '<',
      filterSet: '<',
      canModify: '<',
      onAdd: '&',
      onEdit: '&',
      onRemove: '&',
      onUpdatePrivacy: '&',
      onChange: '&',
      onBack: '&',
      onSave: '&',
    },
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.save = save

    function onInit() {
      ctrl.tabs = [
        { label: 'Filters', value: 'Filters' },
        { label: 'Charts', value: 'Charts' },
      ]
    }

    function save() {
      if (!ctrl.filterSet.label) {
        ctrl.errors = ['Name is required']
        return
      }
      ctrl.onSave({ $filterSet: ctrl.filterSet })
    }
  }
})()
