;(function() {
  'use strict'

  Controller.$inject = ["$log", "Filter"];
  angular.module('glow.reporting').component('glDateFilter', Component())

  function Component() {
    return {
      controller: Controller,
      templateUrl: 'date-filter.template.html',
      bindings: {
        survey: '=',
        filter: '=',
      },
    }
  }

  /* @ngInject */
  function Controller($log, Filter) {
    var ctrl = this
    var log = $log.create('glDateFilter')
    var Types = (ctrl.Types = Filter.DateTypes)
    var presets

    ctrl.$onInit = onInit
    ctrl.onStartChange = onStartChange
    ctrl.onEndChange = onEndChange
    ctrl.setPreset = setPreset

    function onInit() {
      initPresets()
      ctrl.presets = calculatePresets(ctrl.survey.minDate, ctrl.survey.maxDate)
      setPreset(ctrl.filter.data.dateType) // TODO: rename `dateType` to `presetType`
    }

    function initPresets() {
      presets = [
        {
          id: Types.TODAY,
          name: 'Today',
          start: moment().startOf('day'),
          end: moment().endOf('day'),
        },
        {
          id: Types.THIS_WEEK,
          name: 'This Week',
          start: moment()
            .startOf('week')
            .add(1, 'day'), // 1 day to shift to Monday
          end: moment()
            .endOf('week')
            .add(1, 'day'), // 1 day to shift to Sunday
        },
        {
          id: Types.LAST_WEEK,
          name: 'Last Week',
          start: moment()
            .subtract(1, 'week')
            .startOf('week')
            .add(1, 'day'), // 1 day to shift to Monday
          end: moment()
            .subtract(1, 'week')
            .endOf('week')
            .add(1, 'day'), // 1 day to shift to Sunday
        },
        {
          id: Types.THIS_MONTH,
          name: 'This Month',
          start: moment().startOf('month'),
          end: moment().endOf('month'),
        },
        {
          id: Types.LAST_MONTH,
          name: 'Last Month',
          start: moment()
            .subtract(1, 'month')
            .startOf('month'),
          end: moment()
            .subtract(1, 'month')
            .endOf('month'),
        },
        {
          id: Types.THIS_YEAR,
          name: 'This Year',
          start: moment().startOf('year'),
          end: moment().endOf('year'),
        },
        {
          id: Types.CUSTOM,
          name: 'Custom',
          start: ctrl.filter.data.start || ctrl.survey.minDate,
          end: ctrl.filter.data.end || ctrl.survey.maxDate,
        },
      ]
    }

    function calculatePresets(min, max) {
      if (!min || !max) {
        return log.error(
          'calculatePresets: no min/max date - is this survey even published?'
        )
      }
      var clampedPresets = []

      _.each(presets, function(preset) {
        // The min date must be before the end of the preset, and the max date must be after the start of the preset
        // min<=end max>=start
        var isUsable =
          (min.isSameOrBefore(preset.end) && max.isSameOrAfter(preset.start)) ||
          preset.id === Types.CUSTOM
        if (isUsable) {
          // clamp the preset dates to the min/max bounds
          preset.start = moment.max(min, preset.start)
          preset.end = moment.min(max, preset.end)
          clampedPresets.push(preset)
        }
      })
      return clampedPresets
    }

    function setPreset(type) {
      ctrl.selectedPreset = findPreset(type)
      if (!ctrl.selectedPreset) {
        return log.error('setPreset: no preset found', type)
      }
      ctrl.filter.data.dateType = type
      ctrl.filter.data.start = ctrl.selectedPreset.start
      ctrl.filter.data.end = ctrl.selectedPreset.end
    }

    function onStartChange(date) {
      ctrl.filter.data.dateType = Types.CUSTOM
      ctrl.selectedPreset = findPreset(Types.CUSTOM)
      ctrl.filter.data.start = date.startOf('day')
    }

    function onEndChange(date) {
      ctrl.filter.data.dateType = Types.CUSTOM
      ctrl.selectedPreset = findPreset(Types.CUSTOM)
      ctrl.filter.data.end = date.endOf('day')
    }

    function findPreset(type) {
      var preset = _.find(ctrl.presets, { id: type })
      return _.cloneDeep(preset)
    }
  }
})()
