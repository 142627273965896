;(function() {
  'use strict'

  Controller.$inject = ["$q", "$state", "StateFactory", "channelService"];
  angular.module('app.core').controller('PublicChannelStats', Controller)

  /* @ngInject */
  function Controller($q, $state, StateFactory, channelService) {
    var ctrl = this

    ctrl.$onInit = onInit

    function onInit() {
      ctrl.channelId = $state.params.channelId
      ctrl.state = new StateFactory(['loading', 'ready', 'error'])
      ctrl.state.loading()
      loadChannel()
        .then(function() {
          ctrl.state.ready()
        })
        .catch(function(error) {
          console.error(error)
          ctrl.state.error()
        })
    }

    function loadChannel() {
      return channelService
        .get(ctrl.channelId)
        .then(function(channel) {
          ctrl.channel = channel
        })
        .catch(function(err) {
          return $q.reject(err)
        })
    }
  }
})()
