;(function() {
  'use strict'

  Controller.$inject = ["publicationService", "glAnalytics"];
  angular.module('app.core').component('publicationTypeToggle', {
    controller: Controller,
    templateUrl: 'publication-type-toggle.html',
    bindings: {
      onChange: '&',
    },
  })

  /* @ngInject */
  function Controller(publicationService, glAnalytics) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.selectAll = selectAll
    ctrl.select = select

    ctrl.types = makeTypes()

    function onInit() {
      ctrl.allSelected = true
    }

    function selectAll() {
      ctrl.allSelected = true
      ctrl.types = makeTypes()
      ctrl.onChange({ $selectedTypes: [] })
    }

    function select(type) {
      type.selected = !type.selected
      ctrl.selectedTypes = _.filter(ctrl.types, { selected: true })
      ctrl.allSelected = ctrl.selectedTypes.length === 0
      ctrl.onChange({ $selectedTypes: ctrl.selectedTypes })
      if (type.selected) {
        glAnalytics.track(
          'publications',
          'filtered-by-publication-type',
          _.kebabCase(type.label)
        )
      }
    }

    function makeTypes() {
      return _.map(publicationService.TypeInfo, function(type) {
        type.selected = false
        return type
      })
    }
  }
})()
