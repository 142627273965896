;(function() {
  'use strict'

  angular.module('glow.reporting').component('reportInfoScoreDefinition', {
    templateUrl: 'report-info-score-definition.html',
    bindings: {
      question: '<',
    },
  })
})()
