;(function() {
  'use strict'

  Factory.$inject = ["glUtils", "ResponseFilterList", "ResponseFilterValue", "Question"];
  angular.module('app.core').factory('ResponseFilterRule', Factory)

  /* @ngInject */
  function Factory(glUtils, ResponseFilterList, ResponseFilterValue, Question) {
    /**
     * QUESTION (choice-based)
     * - questionId: the id of the question
     * - choices: list of choices + ANY, ALL, NONE, EXACTLY
     *
     * QUESTION (matrix-based)
     * - questionId: the id of the question
     * - statementIds: list of statementIds
     * - choices: list of choices + ANY, ALL, NONE, EXACTLY
     *
     * QUESTION (text-based)
     * - questionId: the id of the question
     * - text: value to search + INCLUDES, EQUALS, EXCLUDES
     *
     * QUESTION (number-based)
     * - questionId: the id of the question
     * - numbers: value(s) to match + EQUALS, LESS_THAN, GREATER_THAN, ANY, NONE
     *
     * QUESTION (rank-based)
     * - questionId: the id of the question
     * - choices: list of choices + ANY, ALL, NONE, EXACTLY
     * - numbers: value(s) to match + EQUALS, LESS_THAN, GREATER_THAN, ANY, NONE
     *
     * QUESTION (sum-based)
     * - questionId: the id of the question
     * - statementIds: list of statementIds (if matrix)
     * - choiceId: the id of the choice
     * - numbers: value(s) to match + EQUALS, LESS_THAN, GREATER_THAN, ANY, NONE
     *
     * QUESTION (location-based)
     * - questionId: the id of the question
     * - locations: list of locations(slot:value) eg 0:australia
     *
     * ANSWER
     * - questions: list of questionIds + ANY, ALL, NONE
     *
     * SEEN
     * - questions: list of questionIds + ANY, ALL, NONE
     *
     * SKIP
     * - questions: list of questionIds + ANY, ALL, NONE
     *
     * CHANNEL
     * - channelIds: the channel ids to include
     *
     * DATE
     * - date: value to match + GTE, EQUALS, LTE
     *
     * DIMENSION
     * - dimensionName: the name of the dimension (can be system or reporting dimension)
     * - dimensionValues: list of dimension values + ANY, ALL, NONE
     *
     * SEGMENT
     * - segments: list of segment names + ANY, ALL, NONE
     *
     * TAG
     * - tags: list of tags + ANY, ALL, NONE
     *
     * KIND
     * - kinds: list of response types (COMPLETE, EXIT, OVERQUOTA) + ANY, ALL, NONE
     *
     * ID
     * - text: value to search + INCLUDES, EQUALS, EXCLUDES
     *
     */

    var Types = {
      QUESTION: 'QUESTION',
      ANSWER: 'ANSWER',
      SEEN: 'SEEN',
      SKIP: 'SKIP',
      CHANNEL: 'CHANNEL',
      DATE: 'DATE',
      DIMENSION: 'DIMENSION',
      SEGMENT: 'SEGMENT',
      TAG: 'TAG',
      KIND: 'KIND',
      ID: 'ID',
    }

    function ResponseFilterRule(filter) {
      this.isRule = true
      this.filter = filter
      this.questionsById = {}
      this.applyDefaults()
    }

    ResponseFilterRule.Types = ResponseFilterRule.prototype.Types = Types

    ResponseFilterRule.prototype.applyDefaults = function() {
      _.defaultsDeep(this, {
        id: glUtils.uuid(),
        type: null,
        questionId: null,
        statementIds: [],
        choices: new ResponseFilterList().setQuantifier('ANY'),
        choiceId: null,
        locations: [],
        text: new ResponseFilterValue().setQuantifier('CONTAINS'),
        numbers: new ResponseFilterList().setQuantifier('EQUALS'),
        questions: new ResponseFilterList().setQuantifier('ANY'),
        channelIds: [],
        date: new ResponseFilterValue().setQuantifier('GTE'),
        dimensionName: null,
        dimensionValues: new ResponseFilterList().setQuantifier('ANY'),
        segments: new ResponseFilterList().setQuantifier('ANY'),
        tags: new ResponseFilterList().setQuantifier('ANY'),
        kinds: [],
      })
    }

    ResponseFilterRule.prototype.deserialize = function(data) {
      this.id = data.id
      this.type = data.type
      this.questionId = data.questionId
      this.statementIds = data.statementIds
      this.choices.deserialize(data.choices)
      this.choiceId = data.choiceId
      this.locations = data.locations
      this.text.deserialize(data.text)
      this.numbers.deserialize(data.numbers)
      this.questions.deserialize(data.questions)
      this.channelIds = data.channelIds
      this.date.deserialize(data.date)
      this.dimensionName = data.dimensionName
      this.dimensionValues.deserialize(data.dimensionValues)
      this.segments.deserialize(data.segments)
      this.tags.deserialize(data.tags)
      this.kinds = data.kinds
      this.applyDefaults()
      return this
    }

    ResponseFilterRule.prototype.serialize = function() {
      var data = {}
      data.id = this.id
      data.type = this.type
      data.questionId = this.questionId
      data.statementIds = this.statementIds.slice()
      data.choices = this.choices.serialize()
      data.choiceId = this.choiceId
      data.locations = this.locations.slice()
      data.text = this.text.serialize()
      data.numbers = this.numbers.serialize()
      data.questions = this.questions.serialize()
      data.channelIds = this.channelIds.slice()
      data.date = this.date.serialize()
      data.dimensionName = this.dimensionName
      data.dimensionValues = this.dimensionValues.serialize()
      data.segments = this.segments.serialize()
      data.tags = this.tags.serialize()
      data.kinds = this.kinds.slice()
      data.isRule = true
      return data
    }

    ResponseFilterRule.prototype.setType = function(type) {
      this.type = type
      // TODO: should we reset any previous type fields that are no longer needed?
      return this
    }

    ResponseFilterRule.prototype.isType = function(type) {
      return this.type === type
    }

    ResponseFilterRule.prototype.clone = function() {
      return new ResponseFilterRule(this.filter).deserialize(this.serialize())
    }

    ResponseFilterRule.prototype.refresh = function() {
      this.id = glUtils.uuid()
      return this
    }

    ResponseFilterRule.prototype.getQuestion = function(questionId) {
      if (!questionId) return null
      // memoize
      var question = this.questionsById[questionId]
      if (!question) {
        question = this.filter.survey.getQuestion(questionId)
        this.questionsById[questionId] = question
      }
      return question
    }

    ResponseFilterRule.prototype.usesQuestion = function() {
      if (this.type === Types.QUESTION) {
        return true
      }
    }

    ResponseFilterRule.prototype.usesStatements = function() {
      if (this.type === Types.QUESTION) {
        if (this.isMatrixBased()) {
          return true
        }
      }
    }

    ResponseFilterRule.prototype.usesChoices = function() {
      if (this.type === Types.QUESTION) {
        if (this.isChoiceBased()) {
          return true
        }
        if (this.isRankBased()) {
          return true
        }
      }
    }

    ResponseFilterRule.prototype.usesChoice = function() {
      if (this.type === Types.QUESTION) {
        if (this.isSumBased()) {
          return true
        }
      }
    }

    ResponseFilterRule.prototype.usesLocation = function() {
      if (this.type === Types.QUESTION) {
        if (this.isLocationBased()) {
          return true
        }
      }
    }

    ResponseFilterRule.prototype.usesText = function() {
      if (this.type === Types.QUESTION) {
        if (this.isTextBased()) {
          return true
        }
      }
      if (this.type === Types.ID) {
        return true
      }
    }

    ResponseFilterRule.prototype.usesNumbers = function() {
      if (this.type === Types.QUESTION) {
        if (this.isNumberBased()) {
          return true
        }
        if (this.isSumBased()) {
          return true
        }
        if (this.isRankBased()) {
          return true
        }
      }
    }

    ResponseFilterRule.prototype.usesQuestions = function() {
      if (this.type === Types.ANSWER) {
        return true
      }
      if (this.type === Types.SEEN) {
        return true
      }
      if (this.type === Types.SKIP) {
        return true
      }
    }

    ResponseFilterRule.prototype.usesChannels = function() {
      if (this.type === Types.CHANNEL) {
        return true
      }
    }

    ResponseFilterRule.prototype.usesDate = function() {
      if (this.type === Types.DATE) {
        return true
      }
    }

    ResponseFilterRule.prototype.usesDimension = function() {
      if (this.type === Types.DIMENSION) {
        return true
      }
    }

    ResponseFilterRule.prototype.usesSegments = function() {
      if (this.type === Types.SEGMENT) {
        return true
      }
    }

    ResponseFilterRule.prototype.usesTags = function() {
      if (this.type === Types.TAG) {
        return true
      }
    }

    ResponseFilterRule.prototype.usesKind = function() {
      if (this.type === Types.KIND) {
        return true
      }
    }

    var ChoiceBasedTypes = [
      Question.Types.CHOICE,
      Question.Types.SCALE,
      Question.Types.NPS,
      Question.Types.MOOD,
      Question.Types.RATING,
      Question.Types.MATRIX,
      Question.Types.SCORE,
      Question.Types.HIDDEN_VARIABLES,
    ]
    ResponseFilterRule.prototype.isChoiceBased = function() {
      var question = this.getQuestion(this.questionId)
      if (question) {
        if (ChoiceBasedTypes.includes(question.type)) {
          return true
        }
      }
    }

    ResponseFilterRule.prototype.isMatrixBased = function() {
      var question = this.getQuestion(this.questionId)
      if (question) {
        return question.isUsedAsMatrix()
      }
    }

    var RankBasedTypes = [Question.Types.RANK]
    ResponseFilterRule.prototype.isRankBased = function() {
      var question = this.getQuestion(this.questionId)
      if (question) {
        if (RankBasedTypes.includes(question.type)) {
          return true
        }
      }
    }

    var SumBasedTypes = [Question.Types.CONSTANT_SUM]
    ResponseFilterRule.prototype.isSumBased = function() {
      var question = this.getQuestion(this.questionId)
      if (question) {
        if (SumBasedTypes.includes(question.type)) {
          return true
        }
      }
    }

    ResponseFilterRule.prototype.isLocationBased = function() {
      var question = this.getQuestion(this.questionId)
      if (question) {
        if (question.type === Question.Types.LOCATION) {
          return true
        }
      }
    }

    ResponseFilterRule.prototype.isTextBased = function() {
      var question = this.getQuestion(this.questionId)
      if (question) {
        if (question.type === Question.Types.SINGLE_TEXT) {
          return true
        }
        if (question.type === Question.Types.TEXT) {
          return true
        }
        if (question.type === Question.Types.SCAN) {
          return true
        }
      }
    }

    ResponseFilterRule.prototype.isNumberBased = function() {
      var question = this.getQuestion(this.questionId)
      if (question) {
        if (question.type === Question.Types.NUMERIC) {
          return true
        }
      }
    }

    ResponseFilterRule.prototype.clean = function() {
      // if you select a question with choices/statements and then change the
      // question, we need to clear out those choices/statements so they don't
      // get included in queries etc
      var question = this.getQuestion(this.questionId)
      if (question) {
        var choiceIds = question.getVisibleChoices().map(function(choice) {
          return choice.id
        })
        _.remove(this.choices.items, function(choiceId) {
          return !choiceIds.includes(choiceId)
        })
        if (this.choiceId && !choiceIds.includes(this.choiceId)) {
          this.choiceId = null
        }
        var statementIds = question
          .getVisibleStatements()
          .map(function(statement) {
            return statement.id
          })
        _.remove(this.statementIds, function(statementId) {
          return !statementIds.includes(statementId)
        })
      }
    }

    ResponseFilterRule.prototype.toMongo = function() {
      var self = this
      var query = {}
      this.clean()
      if (this.type === Types.QUESTION) {
        if (this.isChoiceBased()) {
          var choices = this.choices.items.map(function(choiceId) {
            return { 'choiceAnswers.id': choiceId }
          })
          if (this.choices.quantifier === 'ANY') {
            query = {
              answers: {
                $elemMatch: {
                  questionId: this.questionId,
                  $or: choices,
                },
              },
            }
          }
          if (this.choices.quantifier === 'ALL') {
            query = {
              answers: {
                $elemMatch: {
                  questionId: this.questionId,
                  $and: choices,
                },
              },
            }
          }
          if (this.choices.quantifier === 'NONE') {
            query = {
              answers: {
                $not: {
                  $elemMatch: {
                    questionId: this.questionId,
                    $or: choices,
                  },
                },
              },
            }
          }
          if (this.choices.quantifier === 'EXACT') {
            query = {
              answers: {
                $elemMatch: {
                  questionId: this.questionId,
                  choiceAnswers: {
                    $size: choices.length,
                  },
                  $and: choices,
                },
              },
            }
          }
        }
        if (this.isRankBased()) {
          var number = this.numbers.items[0]
          var numbers = this.numbers.items
          var quantifier = this.numbers.quantifier
          var choices = this.choices.items.map(function(choiceId) {
            if (quantifier === 'EQUALS') {
              return {
                choiceAnswers: {
                  $elemMatch: {
                    id: choiceId,
                    rank: number,
                  },
                },
              }
            }
            if (quantifier === 'LESS_THAN') {
              return {
                choiceAnswers: {
                  $elemMatch: {
                    id: choiceId,
                    rank: { $lt: number },
                  },
                },
              }
            }
            if (quantifier === 'GREATER_THAN') {
              return {
                choiceAnswers: {
                  $elemMatch: {
                    id: choiceId,
                    rank: { $gt: number },
                  },
                },
              }
            }
            if (quantifier === 'ANY') {
              return {
                choiceAnswers: {
                  $elemMatch: {
                    id: choiceId,
                    rank: { $in: numbers },
                  },
                },
              }
            }
            if (quantifier === 'NONE') {
              return {
                choiceAnswers: {
                  $elemMatch: {
                    id: choiceId,
                    rank: { $nin: numbers },
                  },
                },
              }
            }
          })
          if (this.choices.quantifier === 'ANY') {
            query = {
              answers: {
                $elemMatch: {
                  questionId: this.questionId,
                  $or: choices,
                },
              },
            }
          }
          if (this.choices.quantifier === 'ALL') {
            query = {
              answers: {
                $elemMatch: {
                  questionId: this.questionId,
                  $and: choices,
                },
              },
            }
          }
          if (this.choices.quantifier === 'NONE') {
            query = {
              answers: {
                $not: {
                  $elemMatch: {
                    questionId: this.questionId,
                    $or: choices,
                  },
                },
              },
            }
          }
        }
        if (this.isMatrixBased()) {
          var questionId = this.questionId
          var choices = this.choices.items.map(function(choiceId) {
            return { optionId: choiceId }
          })
          query = {
            answers: {
              $elemMatch: {
                questionId: questionId,
                statementAnswers: {
                  // $elemMatch: {
                  //   $and: [
                  //     {
                  //       $or: statements,
                  //     },
                  //     {
                  //       $or: choices,
                  //     },
                  //   ],
                  // },
                },
              },
            },
          }
          if (this.choices.quantifier === 'ANY') {
            query = {
              answers: {
                $elemMatch: {
                  questionId: questionId,
                  statementAnswers: {
                    $elemMatch: {
                      id: { $in: this.statementIds },
                      optionId: { $in: this.choices.items },
                    },
                  },
                },
              },
            }
          }
          if (this.choices.quantifier === 'ALL') {
            query = {
              answers: {
                $elemMatch: {
                  questionId: questionId,
                  statementAnswers: {
                    $all: this.choices.items.map(function(choiceId) {
                      return {
                        $elemMatch: {
                          id: { $in: self.statementIds },
                          optionId: choiceId,
                        },
                      }
                    }),
                  },
                },
              },
            }
          }
          if (this.choices.quantifier === 'NONE') {
            query = {
              answers: {
                $elemMatch: {
                  questionId: questionId,
                  statementAnswers: {
                    $not: {
                      $all: this.choices.items.map(function(choiceId) {
                        return {
                          $elemMatch: {
                            id: { $in: self.statementIds },
                            optionId: choiceId,
                          },
                        }
                      }),
                    },
                  },
                },
              },
            }
          }
          if (this.choices.quantifier === 'EXACT') {
            query = {
              answers: {
                $elemMatch: {
                  questionId: questionId,
                  statementAnswers: {
                    $size: this.choices.items.length,
                    $all: this.choices.items.map(function(choiceId) {
                      return {
                        $elemMatch: {
                          id: { $in: self.statementIds },
                          optionId: choiceId,
                        },
                      }
                    }),
                  },
                },
              },
            }
          }
        }
        if (this.isSumBased()) {
          var questionId = this.questionId
          var statementIds = this.statementIds
          var choiceId = this.choiceId
          var number = this.numbers.items[0]
          var numbers = this.numbers.items
          var quantifier = this.numbers.quantifier
          if (!statementIds.length) {
            if (quantifier === 'EQUALS') {
              query = {
                answers: {
                  $elemMatch: {
                    questionId: questionId,
                    choiceAnswers: {
                      $elemMatch: {
                        id: choiceId,
                        number: number,
                      },
                    },
                  },
                },
              }
            }
            if (quantifier === 'LESS_THAN') {
              query = {
                answers: {
                  $elemMatch: {
                    questionId: questionId,
                    choiceAnswers: {
                      $elemMatch: {
                        id: choiceId,
                        number: { $lt: number },
                      },
                    },
                  },
                },
              }
            }
            if (quantifier === 'GREATER_THAN') {
              query = {
                answers: {
                  $elemMatch: {
                    questionId: questionId,
                    choiceAnswers: {
                      $elemMatch: {
                        id: choiceId,
                        number: { $gt: number },
                      },
                    },
                  },
                },
              }
            }
            if (quantifier === 'ANY') {
              query = {
                answers: {
                  $elemMatch: {
                    questionId: questionId,
                    choiceAnswers: {
                      $elemMatch: {
                        id: choiceId,
                        number: { $in: numbers },
                      },
                    },
                  },
                },
              }
            }
            if (quantifier === 'NONE') {
              query = {
                answers: {
                  $elemMatch: {
                    questionId: questionId,
                    choiceAnswers: {
                      $elemMatch: {
                        id: choiceId,
                        number: { $nin: numbers },
                      },
                    },
                  },
                },
              }
            }
          }
          if (statementIds.length) {
            if (quantifier === 'EQUALS') {
              query = {
                answers: {
                  $elemMatch: {
                    questionId: questionId,
                    statementAnswers: {
                      $elemMatch: {
                        id: { $in: statementIds },
                        optionId: choiceId,
                        number: number,
                      },
                    },
                  },
                },
              }
            }
            if (quantifier === 'LESS_THAN') {
              query = {
                answers: {
                  $elemMatch: {
                    questionId: questionId,
                    statementAnswers: {
                      $elemMatch: {
                        id: { $in: statementIds },
                        optionId: choiceId,
                        number: { $lt: number },
                      },
                    },
                  },
                },
              }
            }
            if (quantifier === 'GREATER_THAN') {
              query = {
                answers: {
                  $elemMatch: {
                    questionId: questionId,
                    statementAnswers: {
                      $elemMatch: {
                        id: { $in: statementIds },
                        optionId: choiceId,
                        number: { $gt: number },
                      },
                    },
                  },
                },
              }
            }
            if (quantifier === 'ANY') {
              query = {
                answers: {
                  $elemMatch: {
                    questionId: questionId,
                    statementAnswers: {
                      $elemMatch: {
                        id: { $in: statementIds },
                        optionId: choiceId,
                        number: { $in: numbers },
                      },
                    },
                  },
                },
              }
            }
            if (quantifier === 'NONE') {
              query = {
                answers: {
                  $elemMatch: {
                    questionId: questionId,
                    statementAnswers: {
                      $elemMatch: {
                        id: { $in: statementIds },
                        optionId: choiceId,
                        number: { $nin: numbers },
                      },
                    },
                  },
                },
              }
            }
          }
        }
        if (this.isLocationBased()) {
          var texts = this.locations.map(function(location) {
            var splitted = location.split(':')
            var slot = splitted[0]
            var value = splitted[1]
            var regex = '^([^\\|]+\\|){' + slot + '}' + value
            return {
              textAnswer: {
                $options: 'i',
                $regex: regex,
              },
            }
          })
          query = {
            answers: {
              $elemMatch: {
                questionId: this.questionId,
                $or: texts,
              },
            },
          }
        }
        if (this.isTextBased()) {
          var includesExcludesRegex = _(this.text.value)
            .split(',')
            .map(_.trim)
            .join('|')
          if (this.text.quantifier === 'INCLUDES') {
            query = {
              answers: {
                $elemMatch: {
                  questionId: this.questionId,
                  textAnswer: {
                    $options: 'i',
                    $regex: includesExcludesRegex,
                  },
                },
              },
            }
          }
          if (this.text.quantifier === 'EQUALS') {
            query = {
              answers: {
                $elemMatch: {
                  questionId: this.questionId,
                  textAnswer: {
                    $options: 'i',
                    $regex: '^' + this.text.value + '$',
                  },
                },
              },
            }
          }
          if (this.text.quantifier === 'EXCLUDES') {
            query = {
              answers: {
                $not: {
                  $elemMatch: {
                    questionId: this.questionId,
                    textAnswer: {
                      $options: 'i',
                      $regex: includesExcludesRegex,
                    },
                  },
                },
              },
            }
          }
        }
        if (this.isNumberBased()) {
          var number = this.numbers.items[0]
          var numbers = this.numbers.items
          var quantifier = this.numbers.quantifier
          if (quantifier === 'EQUALS') {
            query = {
              answers: {
                $elemMatch: {
                  questionId: this.questionId,
                  numericAnswer: number,
                },
              },
            }
          }
          if (quantifier === 'LESS_THAN') {
            query = {
              answers: {
                $elemMatch: {
                  questionId: this.questionId,
                  numericAnswer: { $lt: number },
                },
              },
            }
          }
          if (quantifier === 'GREATER_THAN') {
            query = {
              answers: {
                $elemMatch: {
                  questionId: this.questionId,
                  numericAnswer: { $gt: number },
                },
              },
            }
          }
          if (quantifier === 'ANY') {
            query = {
              answers: {
                $elemMatch: {
                  questionId: this.questionId,
                  numericAnswer: { $in: numbers },
                },
              },
            }
          }
          if (quantifier === 'NONE') {
            query = {
              answers: {
                $elemMatch: {
                  questionId: this.questionId,
                  numericAnswer: { $nin: numbers },
                },
              },
            }
          }
        }
      }
      if (this.type === Types.ANSWER) {
        var elemMatches = this.questions.items.map(function(questionId) {
          return {
            answers: {
              $elemMatch: {
                questionId: questionId,
                answered: true,
              },
            },
          }
        })
        if (this.questions.quantifier === 'ANY') {
          query = {
            $or: elemMatches,
          }
        }
        if (this.questions.quantifier === 'ALL') {
          query = {
            $and: elemMatches,
          }
        }
        if (this.questions.quantifier === 'NONE') {
          query = {
            $nor: elemMatches,
          }
        }
      }
      if (this.type === Types.SEEN) {
        var elemMatches = this.questions.items.map(function(questionId) {
          return {
            answers: {
              $elemMatch: {
                questionId: questionId,
                seen: true,
              },
            },
          }
        })
        if (this.questions.quantifier === 'ANY') {
          query = {
            $or: elemMatches,
          }
        }
        if (this.questions.quantifier === 'ALL') {
          query = {
            $and: elemMatches,
          }
        }
        if (this.questions.quantifier === 'NONE') {
          query = {
            $nor: elemMatches,
          }
        }
      }
      if (this.type === Types.SKIP) {
        var elemMatches = this.questions.items.map(function(questionId) {
          return {
            answers: {
              $elemMatch: {
                questionId: questionId,
                skipped: true,
              },
            },
          }
        })
        if (this.questions.quantifier === 'ANY') {
          query = {
            $or: elemMatches,
          }
        }
        if (this.questions.quantifier === 'ALL') {
          query = {
            $and: elemMatches,
          }
        }
        if (this.questions.quantifier === 'NONE') {
          query = {
            $nor: elemMatches,
          }
        }
      }
      if (this.type === Types.CHANNEL) {
        query = {
          'reportingDimensions.systemReportingDimensions.channel': {
            $in: this.channelIds,
          },
        }
      }
      if (this.type === Types.DATE) {
        var parts = this.date.value.split('/')
        var day = parseInt(parts[0])
        var month = parseInt(parts[1]) - 1
        var year = parseInt(parts[2])
        var date = moment()
          .year(year)
          .month(month)
          .date(day)
        if (this.date.quantifier === 'EQUALS') {
          query = {
            responseCreatedAt: {
              $gte: date.startOf('day').toISOString(),
              $lte: date.endOf('day').toISOString(),
            },
          }
        }
        if (this.date.quantifier === 'GTE') {
          query = {
            responseCreatedAt: {
              $gte: date.startOf('day').toISOString(),
            },
          }
        }
        if (this.date.quantifier === 'LTE') {
          query = {
            responseCreatedAt: {
              $lte: date.endOf('day').toISOString(),
            },
          }
        }
      }
      if (this.type === Types.DIMENSION) {
        var name = this.dimensionName
        var matchers = this.dimensionValues.items.map(function(value) {
          var q = { $or: [] }
          var system = {}
          system['reportingDimensions.systemReportingDimensions.' + name] = value // prettier-ignore
          q.$or.push(system)
          var report = {}
          report['reportingDimensions.customReportingDimensions.' + name] = value // prettier-ignore
          q.$or.push(report)
          return q
        })
        if (this.dimensionValues.quantifier === 'ANY') {
          query = {
            $or: matchers,
          }
        }
        if (this.dimensionValues.quantifier === 'ALL') {
          query = {
            $and: matchers,
          }
        }
        if (this.dimensionValues.quantifier === 'NONE') {
          query = {
            $nor: matchers,
          }
        }
      }
      if (this.type === Types.SEGMENT) {
        if (this.segments.quantifier === 'ANY') {
          query = {
            segments: {
              $in: this.segments.items,
            },
          }
        }
        if (this.segments.quantifier === 'ALL') {
          query = {
            segments: {
              $all: this.segments.items,
            },
          }
        }
        if (this.segments.quantifier === 'NONE') {
          query = {
            segments: {
              $nin: this.segments.items,
            },
          }
        }
      }
      if (this.type === Types.TAG) {
        var matches = this.tags.items.map(function(tag) {
          var item = {}
          var path = 'reportingDimensions.customReportingDimensions.tag:' + tag
          item[path] = { $exists: true }
          return item
        })
        if (this.tags.quantifier === 'ANY') {
          query = {
            $or: matches,
          }
        }
        if (this.tags.quantifier === 'ALL') {
          query = {
            $and: matches,
          }
        }
        if (this.tags.quantifier === 'NONE') {
          query = {
            $nor: matches,
          }
        }
      }
      if (this.type === Types.KIND) {
        query = {
          type: { $in: this.kinds },
        }
      }
      if (this.type === Types.ID) {
        var includesExcludesRegex = _(this.text.value)
          .split(',')
          .map(_.trim)
          .join('|')
        if (this.text.quantifier === 'INCLUDES') {
          query = {
            responseId: {
              $options: 'i',
              $regex: includesExcludesRegex,
            },
          }
        }
        if (this.text.quantifier === 'EQUALS') {
          query = {
            responseId: {
              $options: 'i',
              $regex: '^' + this.text.value + '$',
            },
          }
        }
        if (this.text.quantifier === 'EXCLUDES') {
          query = {
            responseId: {
              $not: {
                $options: 'i',
                $regex: includesExcludesRegex,
              },
            },
          }
        }
      }
      return query
    }

    ResponseFilterRule.prototype.toDatapackMatcher = function(datapack) {
      if (this.type === Types.QUESTION) {
        var questionId = this.questionId
        if (this.isMatrixBased() && !this.isSumBased()) {
          var statementIds = this.statementIds
          var choiceIds = this.choices.items
          var sets = statementIds.map(function(statementId) {
            var idxs = choiceIds.map(function(choiceId) {
              var key = `q_${questionId}_l_-_s_${statementId}_c_${choiceId}_selected`
              var idx = datapack.mappings.columns[key]
              return idx
            })
            return idxs
          })
          if (this.choices.quantifier === 'ANY') {
            return function(row) {
              return _.some(sets, function(idxs) {
                return _.some(idxs, idx => {
                  return row[idx] === '1'
                })
              })
            }
          }
          if (this.choices.quantifier === 'ALL') {
            return function(row) {
              return _.some(sets, function(idxs) {
                return _.every(idxs, idx => {
                  return row[idx] === '1'
                })
              })
            }
          }
          if (this.choices.quantifier === 'NONE') {
            return function(row) {
              return _.some(sets, function(idxs) {
                return !_.some(idxs, idx => {
                  return row[idx] === '1'
                })
              })
            }
          }
        }
        if (this.isChoiceBased()) {
          var idxs = this.choices.items.map(function(choiceId) {
            var key = `q_${questionId}_l_-_c_${choiceId}_selected`
            var idx = datapack.mappings.columns[key]
            return idx
          })
          if (this.choices.quantifier === 'ANY') {
            return function(row) {
              return _.some(idxs, function(idx) {
                return row[idx] === '1'
              })
            }
          }
          if (this.choices.quantifier === 'ALL') {
            return function(row) {
              return _.every(idxs, function(idx) {
                return row[idx] === '1'
              })
            }
          }
          if (this.choices.quantifier === 'NONE') {
            return function(row) {
              return _.every(idxs, function(idx) {
                return row[idx] !== '1'
              })
            }
          }
          if (this.choices.quantifier === 'EXACT') {
            var choices = this.getQuestion(questionId)
              .getVisibleChoices()
              .map(function(choice) {
                var key = `q_${questionId}_l_-_c_${choice.id}_selected`
                var idx = datapack.mappings.columns[key]
                return {
                  idx: idx,
                  selected: idxs.includes(idx),
                }
              })
            return function(row) {
              return _.every(choices, function(choice) {
                return choice.selected
                  ? row[choice.idx] === '1'
                  : row[choice.idx] !== '1'
              })
            }
          }
        }
        if (this.isRankBased()) {
          var number = this.numbers.items[0]
          var numbers = this.numbers.items
          var numberQuantifier = this.numbers.quantifier
          var choices = this.choices.items
          var choiceQuantifier = this.choices.quantifier
          var numberMatchers = choices.map(function(choiceId) {
            var key = `q_${questionId}_l_-_c_${choiceId}_rank`
            var idx = datapack.mappings.columns[key]
            if (numberQuantifier === 'EQUALS') {
              return function(row) {
                return +row[idx] === number
              }
            }
            if (numberQuantifier === 'LESS_THAN') {
              return function(row) {
                return +row[idx] < number
              }
            }
            if (numberQuantifier === 'GREATER_THAN') {
              return function(row) {
                return +row[idx] > number
              }
            }
            if (numberQuantifier === 'ANY') {
              return function(row) {
                return numbers.includes(+row[idx])
              }
            }
            if (numberQuantifier === 'NONE') {
              return function(row) {
                return !numbers.includes(+row[idx])
              }
            }
          })
          if (choiceQuantifier === 'ANY') {
            return function(row) {
              return _.some(numberMatchers, function(match) {
                return match(row)
              })
            }
          }
          if (choiceQuantifier === 'ALL') {
            return function(row) {
              return _.every(numberMatchers, function(match) {
                return match(row)
              })
            }
          }
          if (choiceQuantifier === 'NONE') {
            return function(row) {
              return !_.every(numberMatchers, function(match) {
                return match(row)
              })
            }
          }
        }
        if (this.isSumBased()) {
          var questionId = this.questionId
          var statementIds = this.statementIds
          var choiceId = this.choiceId
          var number = this.numbers.items[0]
          var numbers = this.numbers.items
          var quantifier = this.numbers.quantifier
          if (!statementIds.length) {
            var key = `q_${questionId}_l_-_c_${choiceId}_number`
            var idx = datapack.mappings.columns[key]
            if (quantifier === 'EQUALS') {
              return function(row) {
                return +row[idx] === number
              }
            }
            if (quantifier === 'LESS_THAN') {
              return function(row) {
                return +row[idx] < number
              }
            }
            if (quantifier === 'GREATER_THAN') {
              return function(row) {
                return +row[idx] > number
              }
            }
            if (quantifier === 'ANY') {
              return function(row) {
                return _.some(numbers, function(number) {
                  return +row[idx] === number
                })
              }
            }
            if (quantifier === 'NONE') {
              return function(row) {
                return !_.some(numbers, function(number) {
                  return +row[idx] === number
                })
              }
            }
          }
          if (statementIds.length) {
            var keys = statementIds.map(function(statementId) {
              return `q_${questionId}_l_-_s_${statementId}_c_${choiceId}_number`
            })
            var idxs = keys.map(function(key) {
              return datapack.mappings.columns[key]
            })

            if (quantifier === 'EQUALS') {
              return function(row) {
                return _.some(idxs, function(idx) {
                  return +row[idx] === number
                })
              }
            }
            if (quantifier === 'LESS_THAN') {
              return function(row) {
                return _.some(idxs, function(idx) {
                  return +row[idx] < number
                })
              }
            }
            if (quantifier === 'GREATER_THAN') {
              return function(row) {
                return _.some(idxs, function(idx) {
                  return +row[idx] > number
                })
              }
            }
            if (quantifier === 'ANY') {
              return function(row) {
                return _.some(idxs, function(idx) {
                  return numbers.includes(+row[idx])
                })
              }
            }
            if (quantifier === 'NONE') {
              return function(row) {
                return !_.some(idxs, function(idx) {
                  return numbers.includes(+row[idx])
                })
              }
            }
          }
        }
        if (this.isLocationBased()) {
          var regexes = this.locations.map(function(location) {
            var splitted = location.split(':')
            var slot = splitted[0]
            var value = splitted[1]
            var regex = '^([^\\|]+\\|){' + slot + '}' + value
            return new RegExp(regex, 'i')
          })
          var key = `q_${questionId}_l_-_text`
          var idx = datapack.mappings.columns[key]
          return function(row) {
            return _.some(regexes, function(regex) {
              return regex.test(row[idx])
            })
          }
        }
        if (this.isTextBased()) {
          var key = `q_${questionId}_l_-_text`
          var idx = datapack.mappings.columns[key]
          var value = _(this.text.value)
            .split(',')
            .map(_.trim)
            .join('|')
          if (this.text.quantifier === 'INCLUDES') {
            var regex = new RegExp(value, 'i')
            return function(row) {
              return regex.test(row[idx])
            }
          }
          if (this.text.quantifier === 'EQUALS') {
            var regex = new RegExp(`^${value}$`, 'i')
            return function(row) {
              return regex.test(row[idx])
            }
          }
          if (this.text.quantifier === 'EXCLUDES') {
            var regex = new RegExp(value, 'i')
            return function(row) {
              return !regex.test(row[idx])
            }
          }
        }
        if (this.isNumberBased()) {
          var number = this.numbers.items[0]
          var numbers = this.numbers.items
          var quantifier = this.numbers.quantifier
          var key = `q_${questionId}_l_-_number`
          var idx = datapack.mappings.columns[key]
          if (quantifier === 'EQUALS') {
            return function(row) {
              return +row[idx] === number
            }
          }
          if (quantifier === 'LESS_THAN') {
            return function(row) {
              return +row[idx] < number
            }
          }
          if (quantifier === 'GREATER_THAN') {
            return function(row) {
              return +row[idx] > number
            }
          }
          if (quantifier === 'ANY') {
            return function(row) {
              return _.some(numbers, function(number) {
                return +row[idx] === number
              })
            }
          }
          if (quantifier === 'NONE') {
            return function(row) {
              return !_.some(numbers, function(number) {
                return +row[idx] === number
              })
            }
          }
        }
      }
      if (this.type === Types.ANSWER) {
        var idxs = this.questions.items.map(function(questionId) {
          var key = `q_${questionId}_l_-_answered`
          var idx = datapack.mappings.columns[key]
          return idx
        })
        if (this.questions.quantifier === 'ANY') {
          return function(row) {
            return _.some(idxs, function(idx) {
              return row[idx] === '1'
            })
          }
        }
        if (this.questions.quantifier === 'ALL') {
          return function(row) {
            return _.every(idxs, function(idx) {
              return row[idx] === '1'
            })
          }
        }
        if (this.questions.quantifier === 'NONE') {
          return function(row) {
            return _.every(idxs, function(idx) {
              return row[idx] !== '1'
            })
          }
        }
      }
      if (this.type === Types.SEEN) {
        var idxs = this.questions.items.map(function(questionId) {
          var key = `q_${questionId}_l_-_seen`
          var idx = datapack.mappings.columns[key]
          return idx
        })
        if (this.questions.quantifier === 'ANY') {
          return function(row) {
            return _.some(idxs, function(idx) {
              return row[idx] === '1'
            })
          }
        }
        if (this.questions.quantifier === 'ALL') {
          return function(row) {
            return _.every(idxs, function(idx) {
              return row[idx] === '1'
            })
          }
        }
        if (this.questions.quantifier === 'NONE') {
          return function(row) {
            return _.every(idxs, function(idx) {
              return row[idx] !== '1'
            })
          }
        }
      }
      if (this.type === Types.SKIP) {
        var idxs = this.questions.items.map(function(questionId) {
          var key = `q_${questionId}_l_-_skipped`
          var idx = datapack.mappings.columns[key]
          return idx
        })
        if (this.questions.quantifier === 'ANY') {
          return function(row) {
            return _.some(idxs, function(idx) {
              return row[idx] === '1'
            })
          }
        }
        if (this.questions.quantifier === 'ALL') {
          return function(row) {
            return _.every(idxs, function(idx) {
              return row[idx] === '1'
            })
          }
        }
        if (this.questions.quantifier === 'NONE') {
          return function(row) {
            return _.every(idxs, function(idx) {
              return row[idx] !== '1'
            })
          }
        }
      }
      if (this.type === Types.CHANNEL) {
        var idx = datapack.mappings.columns.channelId
        var channelIds = this.channelIds
        return function(row) {
          return _.some(channelIds, function(channelId) {
            return row[idx] === channelId
          })
        }
      }
      if (this.type === Types.DATE) {
        var idx = datapack.mappings.columns.createdAt
        var parts = this.date.value.split('/')
        var day = parseInt(parts[0])
        var month = parseInt(parts[1]) - 1
        var year = parseInt(parts[2])
        var date = moment()
          .year(year)
          .month(month)
          .date(day)
        if (this.date.quantifier === 'EQUALS') {
          return function(row) {
            return row[idx].isSame(date, 'day')
          }
        }
        if (this.date.quantifier === 'GTE') {
          return function(row) {
            return row[idx].isSameOrAfter(date, 'day')
          }
        }
        if (this.date.quantifier === 'LTE') {
          return function(row) {
            return row[idx].isSameOrBefore(date, 'day')
          }
        }
      }
      if (this.type === Types.DIMENSION) {
        var cKey = `crd_${this.dimensionName}_value`
        var sKey = `srd_${this.dimensionName}_value`
        var idx
        if (datapack.mappings.columns.hasOwnProperty(cKey)) {
          idx = datapack.mappings.columns[cKey]
        } else {
          idx = datapack.mappings.columns[sKey]
        }
        var values = this.dimensionValues
        if (values.quantifier === 'ANY') {
          return function(row) {
            return _.some(values.items, function(value) {
              return row[idx] === value
            })
          }
        }
        if (values.quantifier === 'ALL') {
          return function(row) {
            return _.every(values.items, function(value) {
              return row[idx] === value
            })
          }
        }
        if (values.quantifier === 'NONE') {
          return function(row) {
            return _.every(values.items, function(value) {
              return row[idx] !== value
            })
          }
        }
      }
      if (this.type === Types.SEGMENT) {
        var idxs = this.segments.items.map(function(segment) {
          var key = `segment_${segment}_bool`
          var idx = datapack.mappings.columns[key]
          return idx
        })
        if (this.segments.quantifier === 'ANY') {
          return function(row) {
            return _.some(idxs, function(idx) {
              return row[idx] === '1'
            })
          }
        }
        if (this.segments.quantifier === 'ALL') {
          return function(row) {
            return _.every(idxs, function(idx) {
              return row[idx] === '1'
            })
          }
        }
        if (this.segments.quantifier === 'NONE') {
          return function(row) {
            return !_.some(idxs, function(idx) {
              return row[idx] === '1'
            })
          }
        }
      }
      if (this.type === Types.TAG) {
        var idxs = this.tags.items.map(function(tag) {
          var key = `crd_tag:${tag}_value`
          var idx = datapack.mappings.columns[key]
          return idx
        })
        if (this.tags.quantifier === 'ANY') {
          return function(row) {
            return _.some(idxs, function(idx) {
              return row[idx] === 'true'
            })
          }
        }
        if (this.tags.quantifier === 'ALL') {
          return function(row) {
            return _.every(idxs, function(idx) {
              return row[idx] === 'true'
            })
          }
        }
        if (this.tags.quantifier === 'NONE') {
          return function(row) {
            return _.every(idxs, function(idx) {
              return row[idx] !== 'true'
            })
          }
        }
      }
      if (this.type === Types.KIND) {
        var kinds = this.kinds
        var idx = datapack.mappings.columns.responseType
        return function(row) {
          var value = row[idx]
          var match = kinds.includes(value)
          return match
        }
      }
      if (this.type === Types.ID) {
        var idx = datapack.mappings.columns.id
        var ids = this.text.value.split(',').map(_.trim)
        if (this.text.quantifier === 'INCLUDES') {
          return function(row) {
            return ids.includes(row[idx])
          }
        }
        if (this.text.quantifier === 'EXCLUDES') {
          return function(row) {
            return !ids.includes(row[idx])
          }
        }
        if (this.text.quantifier === 'EQUALS') {
          return function(row) {
            return row[idx] === this.text.value
          }
        }
      }
    }

    ResponseFilterRule.prototype.validate = function() {
      this.clean()
      if (!this.type) {
        return false
      }
      if (this.usesQuestion()) {
        if (!this.questionId) return false
      }
      if (this.usesChoices()) {
        if (!this.choices.items.length) return false
      }
      if (this.usesChoice()) {
        if (!this.choiceId) return false
      }
      if (this.usesStatements()) {
        if (!this.statementIds.length) return false
      }
      if (this.usesText()) {
        if (!this.text.value) return false
      }
      if (this.usesNumbers()) {
        if (!this.numbers.items.length) return false
        var valid = _.every(this.numbers.items, function(number) {
          return _.isNumber(number)
        })
        if (!valid) return false
      }
      if (this.usesLocation()) {
        if (!this.locations.length) return false
      }
      if (this.usesQuestions()) {
        if (!this.questions.items.length) return false
      }
      if (this.usesChannels()) {
        if (!this.channelIds.length) return false
      }
      if (this.usesDate()) {
        if (!this.date.value) return false
      }
      if (this.usesDimension()) {
        if (!this.dimensionName) return false
        if (!this.dimensionValues.items.length) return false
      }
      if (this.usesSegments()) {
        if (!this.segments.items.length) return false
      }
      if (this.usesTags()) {
        if (!this.tags.items.length) return false
      }
      if (this.usesKind()) {
        if (!this.kinds.length) return false
      }
      return true
    }

    return ResponseFilterRule
  }
})()
