;(function() {
  'use strict'

  Controller.$inject = ["$q", "$state", "glDialog", "glUtils", "glTips", "glToast", "scrollService", "kitsService", "countryService", "industryService"];
  angular.module('core.kits').component('kitEditor', Component())

  function Component() {
    return {
      controller: Controller,
      templateUrl: 'kit-editor.html',
      bindings: {
        kit: '=',
      },
    }
  }

  /* @ngInject */
  function Controller(
    $q,
    $state,
    glDialog,
    glUtils,
    glTips,
    glToast,
    scrollService,
    kitsService,
    countryService,
    industryService
  ) {
    var broadcast = glUtils.broadcaster('kit-editor')

    var ctrl = this

    ctrl.setImage = setImage
    ctrl.save = save
    ctrl.deleteKit = deleteKit
    ctrl.onCountrySelect = onCountrySelect
    ctrl.onIndustrySelect = onIndustrySelect

    ctrl.$onInit = onInit

    function onInit() {
      buildTitle()
      prepare()

      ctrl.Countries = countryService.getAll()
      _.forEach(ctrl.Countries, function(country) {
        country.recommended = _.includes(['AU', 'GB'], country.cc)
      })
      ctrl.Industries = industryService.getAll()
      glTips.ready()
    }

    /**
     * @name buildTitle
     * @desc build the editor title
     */
    function buildTitle() {
      ctrl.editorTitle = ctrl.kit.isNew() ? 'New Kit' : 'Edit Kit'
    }

    function prepare() {
      // gl-multiselect dropdown needs an object. Cannot handle null
      ctrl.kit.country = ctrl.kit.country || {}
      ctrl.kit.industry = ctrl.kit.industry || {}
    }

    /**
     * @name Set Image
     * @desc sets the kit intro image
     * @param {String} imageData the base64 encoded image
     */
    function setImage(imageData) {
      ctrl.kit
        .setImage(imageData)
        .then(function() {
          broadcast('kit-image-added') // for tip succession
        })
        .catch(function() {
          glToast.show('Failed to save image')
        })
    }

    function onCountrySelect(id) {
      ctrl.kit.country = countryService.getById(id)
    }

    function onIndustrySelect(id) {
      ctrl.kit.industry = industryService.getById(id)
    }

    /**
     * @name Save
     * @desc saves a kit and displays a toast once completed. Also displays any errors and scrolls to them.
     * @returns {Promise}
     */
    function save() {
      return $q(function(resolve, reject) {
        ctrl.showErrors = false

        ctrl.kit
          .save()
          .then(function() {
            glToast.show('Your kit has been saved')
            ctrl.form.$setPristine()

            if (!ctrl.kitId) {
              buildTitle()
              glUtils.changeParams(
                { kitId: ctrl.kit.id, cloneKitId: undefined },
                true
              )
            }
            prepare()
            resolve()
          })
          .catch(function(data) {
            if (data.errors) {
              ctrl.showErrors = true
              scrollService.animateTo('.kit-editor__errors')
            } else {
              glDialog.alert(
                'Error',
                'There was an issue saving your kit. Please try again.'
              )
            }
            reject()
          })
      })
    }

    /**
     * @name Publish
     * @desc confirms publishing, saves the kit and publishes it.
     */

    // TODO implement once kit publishing it supported
    // function publish () {
    //     glDialog.confirm(
    //         'Publish Kit',
    //         [
    //             'Published Kits will be able to be consumed by survey creators.',
    //             'Are you sure you want to publish this kit?'
    //         ].join(' '),
    //         {
    //             locals: {
    //                 cancelText: 'No',
    //                 closeText: 'Yes'
    //             }
    //         }
    //     ).then(doPublish);
    //
    //     function doPublish () {
    //         var done = ctrl.queue.add('Publishing');
    //         ctrl.kit.publish().then(function () {
    //             glToast.show('Your kit is live!');
    //             broadcast('publish');
    //         }, function () {
    //             glToast.action('Error publishing kit', 'Retry', doPublish);
    //         }).finally(done);
    //     }
    // }

    /**
     * @name deleteKit
     * @desc deletes this kit then re-routes to the kits list
     */
    function deleteKit() {
      glDialog
        .confirm('Delete', 'Are you sure you want to delete this kit?')
        .then(function() {
          kitsService
            .deleteKit(ctrl.kit.id)
            .then(function() {
              glToast.show('Kit deleted')
              $state.go('kit-manager')
            })
            .catch(function() {
              glToast.show('Unable to delete kit')
            })
        })
    }
  }
})()
