;(function() {
  'use strict'

  SurveyTemplatesController.$inject = ["$log", "$state", "glTips", "configService", "surveyTemplateService", "subscriberService"];
  angular
    .module('app.survey-templates')
    .controller('SurveyTemplatesController', SurveyTemplatesController)

  /* @ngInject */
  function SurveyTemplatesController(
    $log,
    $state,
    glTips,
    configService,
    surveyTemplateService,
    subscriberService
  ) {
    $log = $log.create('SurveyTemplatesController')

    var ctrl = this

    // Constants
    ctrl.States = {
      LOADING: 'loading',
      ERROR: 'error',
      READY: 'ready',
      SUCCESS: 'success',
    }

    // Methods
    ctrl.create = create
    ctrl.view = view
    ctrl.cloneTemplate = cloneTemplate
    ctrl.getPreviewUrl = getPreviewUrl

    activate()

    /**
     * Initialise
     */
    function activate() {
      ctrl.state = ctrl.States.LOADING
      getSurveyTemplates()
    }

    /**
     * @name getSurveyTemplates
     * @desc get all available survey templates
     */
    function getSurveyTemplates() {
      surveyTemplateService
        .get(subscriberService.getSubscriber().id)
        .then(getSuccess)
        .catch(getError)

      function getSuccess(data) {
        ctrl.surveyTemplates = data
        ctrl.state = ctrl.States.READY
        glTips.ready()
      }

      function getError() {
        ctrl.state = ctrl.States.ERROR
        $log.error('could not load templates')
      }
    }

    /**
     * @name Create
     * @desc create a new Survey Templates
     */
    function create() {
      $state.go('survey-template-edit', { surveyId: 'new' })
    }

    /**
     * @name view
     * @desc view/edit a new Survey Templates
     */
    function view(template) {
      $state.go('survey-template-edit', { surveyId: template.id })
    }

    /**
     * @name Clone Template
     * @desc clone the selected template
     */
    function cloneTemplate(templateId) {
      $state.go('survey-template-edit', {
        surveyId: 'new',
        cloneTemplateId: templateId,
      })
    }

    /**
     * @name getPreviewUrl
     * @description get survey preview URL based on current environment
     * @param  {String} surveyId survey ID
     * @return {String}          preview URL
     */
    function getPreviewUrl(surveyId) {
      return (
        configService.getUserPortalUrl() +
        '/survey/' +
        surveyId +
        '?readOnly=true&template=true'
      )
    }
  }
})()
