;(function() {
  'use strict'

  Controller.$inject = ["glUtils"];
  angular.module('glow.reporting').component('filterSetBlock', {
    controller: Controller,
    templateUrl: 'filter-set-block.html',
    bindings: {
      group: '<',
      filter: '<',
      canModify: '<',
      onSelect: '&',
      onToggle: '&',
      onEdit: '&',
      onDuplicate: '&',
      onRemove: '&',
      onMove: '&',
    },
  })

  /* @ngInject */
  function Controller(glUtils) {
    var ctrl = this
    var ItemClassPrefix = 'filterset-'

    ctrl.$onInit = onInit
    ctrl.getItemClass = getItemClass
    ctrl.move = move

    function onInit() {
      // ...
    }

    function getItemClass(filterSet, index) {
      var itemClass = {
        '-selected': filterSet.isSelected,
        '-static': filterSet.isReadOnly,
        '-default': filterSet.isDefault,
        '-editable': !filterSet.isReadOnly && ctrl.canModify,
      }
      itemClass[ItemClassPrefix + index] =
        !filterSet.isReadOnly && ctrl.canModify
      return itemClass
    }

    function move(filterSet, amount, index) {
      glUtils.animateMove(ItemClassPrefix, index, amount).then(function() {
        ctrl.onMove({ $filterSet: filterSet, $amount: amount })
      })
    }
  }
})()
