;(function() {
  'use strict'

  Controller.$inject = ["StateFactory", "projectService"];
  angular.module('app.core').component('projectPicker', {
    controller: Controller,
    templateUrl: 'project-picker.html',
    bindings: {
      headerTitle: '<',
      promptTitle: '<',
      hideProjectId: '<',
      onSelect: '&',
      onClose: '&',
    },
  })

  /* @ngInject */
  function Controller(StateFactory, projectService) {
    var ctrl = this

    ctrl.$onInit = onInit

    function onInit() {
      ctrl.state = new StateFactory(['loading', 'loaded', 'error'])
      ctrl.state.loading()
      loadProjects()
        .then(ctrl.state.loaded)
        .catch(ctrl.state.error)
    }

    function loadProjects() {
      return projectService.load().then(function(projects) {
        if (ctrl.hideProjectId) {
          projects = _.filter(projects, function(project) {
            return project.id !== ctrl.hideProjectId
          })
        }
        ctrl.projects = projects
      })
    }
  }
})()
