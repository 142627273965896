;(function() {
  'use strict'

  Controller.$inject = ["cloudinaryService"];
  angular.module('app.core').component('channelTypeCard', {
    controller: Controller,
    templateUrl: 'channel-type-card.html',
    bindings: {
      imageUrl: '<',
      name: '<',
      desc: '<',
      infoUrl: '<',
      onClick: '&',
    },
  })

  /* @ngInject */
  function Controller(cloudinaryService) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.click = click

    function onInit() {
      var imageUrl = cloudinaryService.resize(ctrl.imageUrl, 250)
      ctrl.imageStyle = imageUrl && {
        backgroundImage: 'url(' + imageUrl + ')',
      }
    }

    function click(event) {
      if (!event.defaultPrevented) {
        ctrl.onClick({ $event: event })
      }
    }
  }
})()
