;(function() {
  'use strict'

  Controller.$inject = ["$element", "$window", "translationService", "$sce", "configService", "glTips", "glDialog", "glUtils", "featureService", "clipboardService", "tagsPickerService"];
  angular.module('app.core').component('surveyPreviewOverlay', {
    controller: Controller,
    templateUrl: 'survey-preview-overlay.html',
    bindings: {
      survey: '<',
      translations: '<',
      onClose: '&',
    },
  })

  /* @ngInject */
  function Controller(
    $element,
    $window,
    translationService,
    $sce,
    configService,
    glTips,
    glDialog,
    glUtils,
    featureService,
    clipboardService,
    tagsPickerService
  ) {
    var ctrl = this
    var broadcast = glUtils.broadcaster('survey-preview')

    ctrl.$onInit = onInit
    ctrl.$onDestroy = onDestroy
    ctrl.expand = expand
    ctrl.copyLink = copyLink
    ctrl.getFrameStyle = getFrameStyle
    ctrl.getIFrameStyle = getIFrameStyle
    ctrl.showTranslateDialog = showTranslateDialog
    ctrl.showTagsPickerDialog = showTagsPickerDialog

    function onInit() {
      ctrl.canUseTranslation = featureService.canUseTranslation()
      ctrl.frame = $element.find('iframe')[0]
      ctrl.baseUrl = configService.getUserPortalUrl('/survey/' + ctrl.survey.id)
      ctrl.injectedTags = []
      updatePreviewUrl()
      updateFrameUrl()
      $window.addEventListener('message', onMessage, false)
      ctrl.mobile = false
      glTips.next()
      broadcast('landed')
    }

    function getTranslationParam() {
      return ctrl.translationId ? '&translationId=' + ctrl.translationId : ''
    }

    function getTagInjectionParam() {
      return ctrl.injectedTags.length > 0
        ? '&tags=' + ctrl.injectedTags.join(',')
        : ''
    }

    function updateFrameUrl() {
      var url =
        ctrl.baseUrl +
        '?readOnly=true&embedded=true' +
        (ctrl.survey.isTemplate ? '&template=true' : '') +
        getTranslationParam() +
        getTagInjectionParam()
      ctrl.frameUrl = $sce.trustAsResourceUrl(url)
    }

    function updatePreviewUrl() {
      ctrl.previewUrl =
        ctrl.baseUrl +
        '?readOnly=true' +
        (ctrl.survey.isTemplate ? '&template=true' : '') +
        getTranslationParam() +
        getTagInjectionParam()
    }

    function onDestroy() {
      $window.removeEventListener('message', onMessage, false)
    }

    function onMessage(message) {
      if (message.data === 'survey-done-pressed') {
        broadcast('done-pressed')
        glTips.next()
      }
    }

    function expand() {
      $window.open(ctrl.previewUrl, '_blank')
      ctrl.onClose()
    }

    function copyLink() {
      broadcast('preview-link-copied')
      clipboardService.copy(ctrl.previewUrl, { showToast: false })
      glDialog.alert(
        'Link Copied!',
        "We've added a preview link to your clipboard. No responses through this link will be saved."
      )
    }

    function showTranslateDialog() {
      translationService
        .showPicker(ctrl.survey, ctrl.translationId)
        .then(function(translationId) {
          ctrl.translationId = translationId
          updateFrameUrl()
          updatePreviewUrl()
        })
    }

    function showTagsPickerDialog() {
      var title = 'Inject tags...'
      var description = 'Give respondents tag(s) before they start this survey.'
      tagsPickerService
        .show(ctrl.survey, ctrl.injectedTags, title, description)
        .then(function(tags) {
          ctrl.injectedTags = tags
          updateFrameUrl()
          updatePreviewUrl()
        })
    }

    function getFrameStyle() {
      if (ctrl.mobile) {
        return {
          maxWidth: '375px',
          maxHeight: '667px',
        }
      } else {
        return {
          // background: 'none',
          borderRadius: '0',
          padding: '24px',
        }
      }
    }

    function getIFrameStyle() {
      if (ctrl.mobile) {
        return {
          // ...
        }
      } else {
        return {
          // borderRadius: '0',
        }
      }
    }
  }
})()
