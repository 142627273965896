;(function() {
  'use strict'

  angular.module('app.beacons').controller('Beacons', Controller)

  /* @ngInject */
  function Controller() {
    var ctrl = this

    init()

    function init() {
      ctrl.subTitle =
        'Create and manage beacons to link to your products, places, and more.'
      ctrl.infoUrl =
        'https://docs.glowfeed.com/article/369-what-is-the-places-channel-type'
    }
  }
})()
