;(function() {
  'use strict'

  Controller.$inject = ["subscriberService"];
  angular.module('app.core').component('channelRewardInfo', {
    templateUrl: 'channel-reward-info.html',
    controller: Controller,
    bindings: {
      survey: '<',
    },
  })

  /* @ngInject */
  function Controller(subscriberService) {
    var ctrl = this

    ctrl.$onInit = onInit

    function onInit() {
      ctrl.subscriber = subscriberService.getSubscriber()
      ctrl.plan = ctrl.subscriber.plan
      var loi = ctrl.survey.lengthOfInterview
      ctrl.currency = ctrl.subscriber.billing.currency || 'AUD'
      ctrl.costPerExit = ctrl.plan.pricing.exits
        .byCurrency(ctrl.currency)
        .format()
      ctrl.tableRows = _.map(loi.types, function(type) {
        return {
          label: type.typeLabel,
          value: _.find(ctrl.plan.pricing.responses, {
            type: type.type,
          })
            .price.byCurrency(ctrl.currency)
            .format({ currency: true }),
          entryUnits: type.entryUnits,
          isHighlighted: type.typeLabel === loi.type.typeLabel,
        }
      })
    }
  }
})()
