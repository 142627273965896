;(function() {
  'use strict'

  Service.$inject = ["$rootScope", "$state", "glDialog"];
  angular.module('app.core').service('accountSetupDialog', Service)

  /* @ngInject */
  function Service($rootScope, $state, glDialog) {
    var Events = {
      COMPLETED: 'accountSetupDialog:COMPLETED',
    }

    this.Events = Events
    this.show = show

    function show() {
      // prettier-ignore
      var template = [
        '<gl-dialog class="account-setup__dialog">',
        '<g-dialog-header ',
          'title="Complete Your Profile"',
          'on-close="dialog.cancel()"',
        '></g-dialog-header>',
        '<account-setup on-complete="dialog.close()" />',
        '</gl-dialog>',
      ]
      return (
        glDialog
          .show({ template: template.join('') })
          .then(function() {
            $rootScope.$emit(Events.COMPLETED)
          })
          // Always reload to ensure partial account updates are applied on the current route
          .finally($state.reload)
      )
    }
  }
})()
