;(function() {
  'use strict'

  Controller.$inject = ["$element"];
  angular.module('app.core').component('gMultiSwitch', {
    controller: Controller,
    templateUrl: 'g-multi-switch.html',
    bindings: {
      options: '<',
      selected: '<',
      onChange: '&',
    },
  })

  /* @ngInject */
  function Controller($element) {
    var ctrl = this

    $element.addClass('g-multi-switch')

    ctrl.select = select

    function select(option) {
      if (ctrl.selected === option.value) {
        return
      }
      if (option.enabled && !option.enabled()) {
        return
      }
      var data = _.find(ctrl.options, { value: ctrl.selected })
      if (data && data.resolveOnLeave) {
        return data.resolveOnLeave().then(function() {
          ctrl.onChange({ $value: option.value })
        })
      }
      ctrl.onChange({ $value: option.value })
    }
  }
})()
