;(function() {
  'use strict'

  Controller.$inject = ["$element", "locationFilter", "responseTypeService"];
  angular
    .module('glow.reporting')
    .component('glQuestionLocationFilter', Component())

  function Component() {
    return {
      controller: Controller,
      templateUrl: 'location-filter.template.html',
      bindings: {
        survey: '<',
        question: '=',
        filter: '=',
      },
    }
  }

  /* @ngInject */
  function Controller($element, locationFilter, responseTypeService) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.toggleItem = toggleItem
    ctrl.removeTag = removeTag
    ctrl.findTagFromItem = findTagFromItem
    ctrl.isItemVisible = isItemVisible

    function onInit() {
      $element.addClass('location-filter')

      locationFilter
        .getLocations(
          ctrl.survey,
          ctrl.question.id,
          responseTypeService.getSelected()
        )
        .then(function(locations) {
          var searchTypes = [
            locations.Type.COUNTRY,
            locations.Type.STATE,
            locations.Type.CITY,
            locations.Type.SUBURB,
          ]

          ctrl.items = []
          _.each(searchTypes, function(type) {
            var items = locations.getByType(type)
            ctrl.items = ctrl.items.concat(items)
          })

          ctrl.tags = ctrl.filter.data.values
          ctrl.ready = true
        })
    }

    function toggleItem(item) {
      $element.find('input')[0].focus()

      var tag = findTagFromItem(item)

      if (tag) {
        removeTag(tag)
      } else {
        ctrl.tags.push({
          text: item.info.name + ': ' + item.name,
          value: item.name,
          slot: item.info.slot,
        })
      }
    }

    function removeTag(tag) {
      _.remove(ctrl.tags, tag)
    }

    function findTagFromItem(item) {
      return _.find(ctrl.tags, { slot: item.info.slot, value: item.name })
    }

    function isItemVisible(item) {
      if (!ctrl.query) {
        return true
      }
      return _.includes(item.nameLowerCase, ctrl.query.toLowerCase())
    }
  }
})()
