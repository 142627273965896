/* globals google, markerClusterer */
;(function() {
  'use strict'

  Controller.$inject = ["$element", "$compile", "$scope", "$log", "mapService", "reportMapChartStyles"];
  angular.module('app.core').component('surveyExplorerMap', {
    controller: Controller,
    templateUrl: 'survey-explorer-map.html',
    bindings: {
      locationSet: '<',
      isMarkers: '<',
    },
  })

  /* @ngInject */
  function Controller(
    $element,
    $compile,
    $scope,
    $log,
    mapService,
    reportMapChartStyles
  ) {
    var ctrl = this
    $log = $log.create('surveyExplorerMap')

    var map
    var heatmap
    var markerCluster
    var infoWindow
    var infoWindowScope
    var heatmapData = []
    var markers = []
    var clusterInfoCache = {}

    var MAX_ZOOM_LEVEL = 21

    var mapOptions = {
      center: { lat: -34.397, lng: 150.644 }, // arbitrary, doesnt matter
      zoom: 8,
      maxZoom: MAX_ZOOM_LEVEL,
      zoomControl: false,
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
      styles: reportMapChartStyles,
    }

    ctrl.$onInit = onInit
    ctrl.$onChanges = onChanges

    function onInit() {
      mapService.load().then(function() {
        // create map and add click listener
        var mapElement = $element[0].querySelector('.survey-explorer-map__map')
        map = new google.maps.Map(mapElement, mapOptions)
        map.addListener('click', onMapClick)

        // initialise a heatmap with no data
        heatmap = new google.maps.visualization.HeatmapLayer({ map: map })

        // initialise a marker clusterer with no data
        markerCluster = new markerClusterer.MarkerClusterer({
          map: map,
          // set max zoom level to prevent cluster from disappearing
          // when multiple markers are at the same position.
          algorithmOptions: { maxZoom: MAX_ZOOM_LEVEL },
          onClusterClick: onClusterClick,
        })

        // create a new isolated scope for the InfoWindow content
        infoWindowScope = $scope.$new(true)
        infoWindowScope.zoom = function(clusterBounds) {
          map.fitBounds(clusterBounds)
        }

        // Compile the template once and link it to the scope
        var templateHtml = getInfoWindowTemplate()
        var compiledTemplate = $compile(templateHtml)(infoWindowScope)

        // initialise an info window
        infoWindow = new google.maps.InfoWindow({
          content: compiledTemplate[0],
          maxWidth: 300,
          headerDisabled: true,
        })

        populateMapData()
        updateMapView()
      })
    }

    function onChanges(changes) {
      if (!map) return
      if (changes.locationSet) {
        populateMapData()
      }
      updateMapView()
    }

    function populateMapData() {
      // reset cache and data arrays
      clusterInfoCache = {}
      heatmapData.length = 0
      markers.length = 0

      // get a fresh bounds
      var bounds = makeBaseBounds()

      ctrl.locationSet.locations.forEach(function(location) {
        var locationLatlng = location[ctrl.locationSet.Type.LATLNG]
        var locationName = location[ctrl.locationSet.Type.NAME]
        var ll = locationLatlng.split(',')

        // Skip invalid data and log the error
        if (isNaN(ll[0]) || isNaN(ll[1])) {
          $log.error(
            `Missing latlng data (${locationLatlng}) for location ${locationName}. ResponseId: ${location.interactionId}`
          )
          return
        }

        // create latlng object
        var latlng = new google.maps.LatLng(ll[0], ll[1])

        // extend map bounds incase we have locations outside of Australia
        bounds.extend(latlng)

        // add to heatmap data
        heatmapData.push(latlng)

        // create marker and add to markers array
        var marker = new google.maps.Marker({
          position: latlng,
          title: locationName,
          optimized: true,
        })
        marker.addListener('click', onMarkerClick)
        markers.push(marker)
      })

      // zoom to fit all points
      map.fitBounds(bounds)

      // dont zoom in too far
      var zoom = _.clamp(map.getZoom(), 0, 10)
      map.setZoom(zoom)
    }

    function updateMapView() {
      // clear cluster markers and close infowindow
      markerCluster.clearMarkers()
      infoWindow.close()

      if (ctrl.isMarkers) {
        // show markers with clustering
        heatmap.setData([])
        markerCluster.addMarkers(markers)
      } else {
        // show heatmap
        heatmap.setData(heatmapData)
      }
    }

    function onMapClick(event) {
      infoWindow.close()
    }

    function onMarkerClick(event) {
      var marker = this
      var count = 1
      var info = {
        type: 'marker',
        title: marker.getTitle(),
        count: count,
        percent: calcPercent(count),
      }
      showInfoWindow(marker, info)
    }

    function onClusterClick(event, cluster, map) {
      if (!cluster.key) {
        cluster.key = generateClusterKey(cluster)
      }

      var info = clusterInfoCache[cluster.key]
      if (!info) {
        var items = _(cluster.markers)
          .groupBy(function(marker) {
            return marker.getPosition().toUrlValue()
          })
          .map(function(group) {
            return {
              title: group[0].getTitle(),
              count: group.length,
              percent: calcPercent(group.length),
            }
          })
          .orderBy(['count'], ['desc'])
          .value()

        info = clusterInfoCache[cluster.key] = {
          type: 'cluster',
          count: cluster.count,
          percent: calcPercent(cluster.count),
          items: items,
          clusterBounds: cluster.bounds,
        }
      }
      showInfoWindow(cluster.marker, info)
    }

    function generateClusterKey(cluster) {
      // since clusters don't have IDs and are dynamically created,
      // we need to create our own unique key for each cluster so that we can cache them.
      var clusterPos = cluster.position.toUrlValue()
      var clusterCount = cluster.count
      var firstMarkerPos = _.first(cluster.markers)
        .getPosition()
        .toUrlValue()
      var lastMarkerPos = _.last(cluster.markers)
        .getPosition()
        .toUrlValue()
      return `${clusterPos}|${clusterCount}|${firstMarkerPos}|${lastMarkerPos}`
    }

    function calcPercent(count) {
      return ((count / markers.length) * 100).toFixed(2)
    }

    function showInfoWindow(marker, info) {
      // close infowindow if the same marker is clicked
      if (
        infoWindow.isOpen &&
        infoWindow.getPosition().equals(marker.getPosition())
      ) {
        return infoWindow.close()
      }

      // update infowindow scope and apply the changes
      infoWindowScope.info = info
      infoWindowScope.$apply()

      // open infowindow
      infoWindow.open({ anchor: marker })
    }

    function getInfoWindowTemplate() {
      return `
      <div class="survey-explorer-map__info">
        <div ng-if="info.type === 'cluster'">
          <div ng-if="info.items.length > 1" class="survey-explorer-map__info-header">
            <div class="survey-explorer-map__info-text -title">
              <span>Total (n) in this area</span>
              <span>{{ info.percent }}% ({{ info.count }})</span>
            </div>
          </div>
          <div class="survey-explorer-map__info-list">
            <div ng-repeat="item in info.items" class="survey-explorer-map__info-text">
              <span>{{ item.title }}</span>
              <span>{{ item.percent }}% ({{ item.count }})</span>
            </div>
          </div>
          <div ng-if="info.items.length > 1" class="survey-explorer-map__info-footer">
            <g-btn label="Zoom In" tertiary="true" small="true" on-click="zoom(info.clusterBounds)"></g-btn>
          </div>
        </div>
        <div ng-if="info.type === 'marker'" class="survey-explorer-map__info-text">
          <span>{{ info.title }}</span>
          <span>{{ info.percent }}% ({{ info.count }})</span>
        </div>
      </div>
      `
    }

    function makeBaseBounds() {
      var bounds = new google.maps.LatLngBounds()
      // our base bounds start with Australia (TODO: is this necessary?)
      bounds.extend(new google.maps.LatLng(-11.885944, 112.773279)) // top left of australia
      bounds.extend(new google.maps.LatLng(-44.657796, 154.162186)) // bottom right of australia
      return bounds
    }
  }
})()
