;(function() {
  'use strict'

  Controller.$inject = ["subscriberService", "userService"];
  angular.module('app.core').component('channelFormCustom', {
    templateUrl: 'channel-form-custom.html',
    controller: Controller,
    bindings: {
      channel: '<',
      survey: '<',
      cost: '<',
      onCostShouldUpdate: '&',
      onSave: '&',
    },
  })

  /* @ngInject */
  function Controller(subscriberService, userService) {
    var ctrl = this

    ctrl.$onInit = onInit

    function onInit() {
      ctrl.subscriber = subscriberService.getSubscriber()
      ctrl.isSSR = userService.isSSR()
      ctrl.onCostShouldUpdate()
    }
  }
})()
