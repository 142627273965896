;(function() {
  'use strict'

  modulesService.$inject = ["modulesResource", "$q", "userService"];
  angular.module('core.services').factory('modulesService', modulesService)

  /* @ngInject */
  function modulesService(modulesResource, $q, userService) {
    var pageModules = {} // cache page modules by page ID

    var schemas = [
      {
        type: 'feedback',
        icon: 'gi-chat',
        manageTitle: 'Message Button',
        manageDesc: 'Allow users to send you messages.',
        settingsTemplate: 'message-module.template.html',
        settingsController: 'MessageModuleController',
        settingsControllerAs: 'messageSettings',
        ssrOnly: false,
      },
      {
        type: 'survey',
        icon: 'gi-pie-chart',
        manageTitle: 'Promoted Survey',
        manageDesc:
          'Display a survey for your crowd to complete. It will be prompeted to the top of the page.',
        settingsTemplate: 'survey-module.template.html',
        settingsController: 'SurveyModuleController',
        settingsControllerAs: 'surveySettings',
        ssrOnly: true,
      },
      {
        type: 'community',
        icon: 'gi-crowd',
        manageTitle: 'Crowd',
        manageDesc: 'Displays a button for users to join your crowd.',
        settingsTemplate: 'community-module.template.html',
        settingsController: 'CommunityModuleController',
        settingsControllerAs: 'communitySettings',
        ssrOnly: true,
      },
      // {
      //     type: 'community-wall',
      //     icon: 'gi-community-wall-2',
      //     manageTitle: 'Community Wall',
      //     manageDesc: 'Let people who join your community read and post public messages on your page.',
      //     // settingsTemplate: TODO,
      //     // settingsController: TODO,
      //     // settingsControllerAs: TODO,
      // },
      // {
      //     type: 'social',
      //     icon: 'gi-link',
      //     manageTitle: 'Social Media',
      //     manageDesc: 'Displays links to your social media channels.',
      //     // settingsTemplate: TODO,
      //     // settingsController: TODO,
      //     // settingsControllerAs: TODO,
      // },
      // {
      //     type: 'fbfg',
      //     icon: 'gi-mood-2',
      //     manageTitle: 'Feedback For Good',
      //     manageDesc: 'Get more insight by letting your users know you donate to charity ' +
      //                 'each time they give you feedback.',
      //     // settingsTemplate: TODO,
      //     // settingsController: TODO,
      //     // settingsControllerAs: TODO,
      // },
      // {
      //     type: 'sentiment',
      //     icon: 'gi-mood-2',
      //     manageTitle: 'Sentiment',
      //     manageDesc: 'Add your current happiness rating to your page.',
      //     // settingsTemplate: TODO,
      //     // settingsController: TODO,
      //     // settingsControllerAs: TODO,
      // }
    ]

    return {
      getSchemas: getSchemas,
      getSchema: getSchema,
      getByPage: getByPage,
      save: save,
      remove: remove,
    }

    /**
     * @name getSchemas
     * @description get schemas and filter out based on user role (SSR user)
     * @return {Array} array of valid schema objects
     */
    function getSchemas() {
      var isSSR = userService.isSSR()
      return _.filter(schemas, function(schema) {
        return schema.ssrOnly ? isSSR : true
      })
    }

    /**
     * @name getSchema
     * @description get schema by type
     * @param  {String} type schema type
     * @return {Object}      cloned schema object
     */
    function getSchema(type) {
      return _.clone(_.find(schemas, { type: type }))
    }

    /**
     * PRIVATE - transform/add module properties
     * @param {Array} modules array of module resources
     * @return {Array} array of transformed elements
     */
    function transformModules(modules) {
      var data = {}

      _.forEach(modules, function(module) {
        data[module.type] = module
      })

      return data
    }

    /**
     * @name getByPage
     * @description get array of modules for a page ID
     * @param  {String} pageId page ID
     * @return {Array}        array of module objects
     */
    function getByPage(pageId) {
      return $q(function(resolve, reject) {
        // resolve if cached
        if (pageModules[pageId]) {
          return resolve(pageModules[pageId])
        }

        modulesResource
          .get({ pageId: pageId })
          .success(function(res, response) {
            pageModules[pageId] = transformModules(response)
            resolve(pageModules[pageId])
          })
          .error(reject)
      })
    }

    /**
     * @name save
     * @description create or update module for page
     * @param  {Object} module module object
     * @return {Promise}
     */
    function save(module) {
      return $q(function(resolve, reject) {
        var action = module.id ? 'update' : 'create'

        modulesResource[action](module)
          .success(function(res, response) {
            // Only set id on creation - an update returns a 204 so there should be no response
            if (response && response.id) {
              module.id = response.id
            }
            resolve(response)
          })
          .error(function() {
            // generic error message
            reject(
              'Something went wrong, please check your info and try again.'
            )
          })
      })
    }

    /**
     * @name remove
     * @description remove module from page
     * @param  {String} moduleId module ID
     * @return {Promise}
     */
    function remove(moduleId) {
      return $q(function(resolve, reject) {
        modulesResource
          .delete({ id: moduleId })
          .success(function() {
            resolve()
          })
          .error(function() {
            // response doesnt have human readable error so we assume what the error is...
            reject('Your page must have at least one module')
          })
      })
    }
  }
})()
