;(function() {
  'use strict'

  Factory.$inject = ["ResponseAnswer"];
  angular.module('app.core').factory('Response', Factory)

  // This factory maps to the glow_survey.survey_responses collection

  /* @ngInject */
  function Factory(ResponseAnswer) {
    function Response() {
      this.applyDefaults()
    }

    Response.prototype.applyDefaults = function() {
      _.defaultsDeep(this, {
        id: null,
        surveyId: null,
        channelId: null,
        type: null,
        answers: {},
        tags: [],
        dimensions: [],
        segments: [],
        startedAt: null,
        finishedAt: null,
        duration: null,
        createdAt: null,
        updatedAt: null,
      })
    }

    Response.prototype.deserialize = function(data) {
      var self = this
      this.id = data.id
      this.surveyId = data.surveyId
      this.channelId = data.channelId
      this.type = data.type
      this.answers = {}
      _.each(data.answers, function(answer) {
        // note: key might actually be answer.question.id (havent tested yet)
        self.answers[answer.questionId] = new ResponseAnswer().deserialize(
          answer
        )
      })
      this.tags = data.tags
      var dimensions = []
      _.each(data.customData, function(value, key) {
        dimensions.push({
          type: 'DATA',
          key: key,
          value: value,
        })
      })
      _.each(data.reportingDimensions.rd, function(value, key) {
        dimensions.push({
          type: 'SYSTEM',
          key: key,
          value: value,
        })
      })
      _.each(data.reportingDimensions.crd, function(value, key) {
        dimensions.push({
          type: 'REPORT',
          key: key,
          value: value,
        })
      })
      this.dimensions = dimensions
      this.segments = data.segments
      this.startedAt = moment(data.startedAt)
      this.finishedAt = moment(data.finishedAt)
      this.duration = data.durationSeconds
      this.createdAt = moment(data.createdAt)
      this.updatedAt = moment(data.updatedAt)
      this.applyDefaults()
      return this
    }

    Response.prototype.getCreatedAtLabel = function() {
      return this.createdAt.format('MMM D YYYY, h:mm A')
    }

    return Response
  }
})()
