;(function() {
  'use strict'

  Factory.$inject = ["Price", "Money", "LOI", "PanelTarget"];
  angular.module('app.core').factory('Estimate', Factory)

  /* @ngInject */
  function Factory(Price, Money, LOI, PanelTarget) {
    var Types = {
      PANEL: 'PANEL',
      STANDARD: 'STANDARD',
    }

    var TypeInfo = [
      {
        type: Types.PANEL,
        name: 'Consumer Panel',
        desc:
          'Target specific demographic groups or get a representative sample of the population quickly and easily - all at the click of a mouse.',
      },
      {
        type: Types.STANDARD,
        name: 'BYO Responses',
        desc:
          'Share a link with anyone so they can complete your survey, or engage a Third Party Panel partner to send you targeted responses through Glow.',
      },
    ]

    function Estimate(pricing, currency) {
      this.pricing = pricing
      this.currency = currency
      // hardcoded PM (project management) pricing to be added
      // on top of the estimate cost for PANEL type
      this.pmPricing = new Price().deserialize({
        AUD: 50000,
        USD: 35000,
        GBP: 25000,
      })
      this.applyDefaults()
    }

    Estimate.prototype.Types = Estimate.Types = Types
    Estimate.prototype.TypeInfo = Estimate.TypeInfo = TypeInfo

    Estimate.prototype.applyDefaults = function() {
      _.defaultsDeep(this, {
        id: null,
        deviceId: null,
        subscriberId: null,
        type: Types.PANEL,
        lengthOfInterview: new LOI(),
        responses: 250,
        incidenceRate: 100,
        presetId: null,
        country: 'AU',
        language: 'en',
        targets: [],
        isFeasible: null,
        totalCost: new Money({ currency: this.currency }),
        textRowsForEmail: [],
        createdAt: null,
        updatedAt: null,
      })
    }

    Estimate.prototype.deserialize = function(data) {
      this.id = data.id
      this.deviceId = data.deviceId
      this.subscriberId = data.subscriberId
      this.type = data.type
      this.lengthOfInterview = new LOI().deserialize(data.lengthOfInterview)
      this.responses = data.responses
      this.incidenceRate = data.incidenceRate
      this.presetId = data.presetId // TODO: update BE model
      this.country = data.country
      this.language = data.language
      var targets = _.isArray(data.filters) ? data.filters : []
      this.targets = targets.map(function(target) {
        // TODO: update BE model
        return new PanelTarget().deserialize(target)
      })
      this.isFeasible = data.isFeasible
      this.totalCost.amount = data.totalCost
      this.textRowsForEmail = _.isArray(data.textRowsForEmail)
        ? data.textRowsForEmail
        : []
      this.createdAt = data.createdAt ? moment(data.createdAt) : null
      this.updatedAt = data.updatedAt ? moment(data.updatedAt) : null
      this.applyDefaults()
      return this
    }

    Estimate.prototype.serialize = function() {
      var data = {}
      data.id = this.id
      data.deviceId = this.deviceId
      data.subscriberId = this.subscriberId
      data.type = this.type
      data.lengthOfInterview = this.lengthOfInterview.serialize()
      data.responses = this.responses
      data.incidenceRate = this.incidenceRate
      data.presetId = this.presetId // TODO: update BE model
      data.country = this.country
      data.language = this.language
      data.filters = this.targets.map(function(target) {
        // TODO: update BE model
        return target.serialize()
      })
      data.isFeasible = this.isFeasible
      data.totalCost = this.totalCost.amount
      data.textRowsForEmail = this.textRowsForEmail
      data.createdAt = this.createdAt ? this.createdAt.toISOString() : null
      data.updatedAt = this.updatedAt ? this.updatedAt.toISOString() : null
      return data
    }

    Estimate.prototype.isNew = function() {
      return !this.id
    }

    Estimate.prototype.clone = function() {
      // only exact copies allowed
      return new Estimate(this.pricing, this.currency).deserialize(
        this.serialize()
      )
    }

    Estimate.prototype.refresh = function() {
      this.id = null
      this.createdAt = null
      this.updatedAt = null
      return this
    }

    Estimate.prototype.replace = function(estimate) {
      this.deserialize(estimate.serialize())
    }

    Estimate.prototype.fromPanel = function(panel) {
      this.type = Types.PANEL
      this.lengthOfInterview.become(panel.lengthOfInterview)
      this.responses = panel.requiredCompletes
      this.incidenceRate = panel.incidenceRate
      this.country = panel.country
      this.language = panel.language
      this.targets = panel.targets
      return this
    }

    Estimate.prototype.validate = function() {
      var isValid = true
      var self = this
      self.errors = []

      if (!self.type) {
        self.errors.push('Estimate type is required')
        isValid = false
      }

      if (!self.pricing) {
        self.errors.push('Pricing is required')
        isValid = false
      }

      if (!self.responses) {
        self.errors.push('Required responses is required')
        isValid = false
      }
      if (self.type === Types.PANEL) {
        if (_.isNumber(self.responses) && self.responses < 100) {
          self.errors.push('Minimum 100 responses is required')
          isValid = false
        }
        if (!self.incidenceRate) {
          self.errors.push('Incidence rate is required')
          isValid = false
        }
        if (
          !_.isNumber(self.lengthOfInterview.value) ||
          self.lengthOfInterview.value < 0
        ) {
          self.errors.push('Survey length is required')
          isValid = false
        }
        if (!self.country) {
          self.errors.push('Country is required')
          isValid = false
        }
        if (!self.language) {
          self.errors.push('Language is required')
          isValid = false
        }
        _.each(self.targets, function(target) {
          // TODO: validate target
        })
      }
      return isValid
    }

    Estimate.prototype.getBaseCost = function() {
      if (!this.pricing || !this.currency) return
      return this.pricing.base.byCurrency(this.currency)
    }

    Estimate.prototype.getResponseCost = function() {
      if (!this.pricing || !this.currency) return
      // console.log(this.lengthOfInterview.type.type, this.pricing.responses)
      return _.find(this.pricing.responses, {
        type: this.lengthOfInterview.type.type,
      }).price.byCurrency(this.currency)
    }

    Estimate.prototype.getExitCost = function() {
      if (!this.pricing || !this.currency) return
      return this.pricing.exits.byCurrency(this.currency)
    }

    Estimate.prototype.getPMCost = function() {
      if (!this.currency) return
      return this.pmPricing.byCurrency(this.currency)
    }

    Estimate.prototype.calculateTotalCost = function() {
      var totalResponses = this.responses
      if (!this.totalCost.currency) {
        this.totalCost.currency = this.currency
      }
      if (this.type === Types.PANEL) {
        var pmCost = this.getPMCost()
        var baseCost = this.getBaseCost()
        var responseCost = this.getResponseCost()
        var exitCost = this.getExitCost()
        var incidencePercent = this.incidenceRate / 100
        var totalExits = this.responses / incidencePercent - this.responses
        var totalResponseCost =
          totalResponses * (responseCost.amount + baseCost.amount)
        var totalExitCost = totalExits * exitCost.amount
        this.totalCost.amount = Math.round(
          totalResponseCost + totalExitCost + pmCost.amount
        )
      }
      if (this.type === Types.STANDARD) {
        var baseCost = this.getBaseCost()
        var totalBaseCost = totalResponses * baseCost.amount
        this.totalCost.amount = Math.round(totalBaseCost)
      }
    }

    Estimate.prototype.getTotalCost = function() {
      if (!this.validate()) {
        return
      }
      this.calculateTotalCost()
      return this.totalCost
    }

    Estimate.prototype.buildTextRowsForEmail = function() {
      var rows = []
      function insert(label, value) {
        rows.push({ label: label, value: value })
      }
      insert('Audience', _.find(TypeInfo, { type: this.type }).name)
      insert('Survey length', this.lengthOfInterview.type.rangeLabel)
      insert('Required responses', this.responses)
      insert('Incidence rate', this.incidenceRate + '%')
      if (this.type === Types.PANEL) {
        insert('Country', this.country)
        insert('Language', this.language)
        // TODO: this is now targets but they aren't easy to represent
        // in a single line of text
        // this.filters.forEach(function(filter) {
        //   var value = filter.options
        //     .map(function(value) {
        //       if (filter.type === 'INTEGER_RANGE') {
        //         return value.join('-')
        //       }
        //       return filter.attribute.options.find(function(option) {
        //         return option.value === value
        //       }).label
        //     })
        //     .join(', ')
        //   insert('Filter: ' + filter.attribute.name, value)
        // })
      }
      if (this.isFeasible === true || this.isFeasible === false) {
        insert('Feasible', this.isFeasible ? 'Yes' : 'No')
      }
      insert('Total', this.getTotalCost().format({ currency: true }))
      this.textRowsForEmail = rows
    }

    return Estimate
  }
})()
