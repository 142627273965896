;(function() {
  'use strict'

  config.$inject = ["$stateProvider"];
  angular.module('app.core').config(config)

  /* @ngInject */
  function config($stateProvider) {
    $stateProvider.state('interactiveAnalysis', {
      url:
        '/interactive-analysis/:surveyId?packId&filters&filtersOperator&token&t',
      params: {
        filters: { dynamic: true },
        filtersOperator: { dynamic: true },
      },
      parent: 'portal',
      templateUrl: 'interactive-analysis.html',
      controller: 'InteractiveAnalysisController',
      controllerAs: '$ctrl',
      title: 'Interactive Analysis',
      public: true,
    })

    $stateProvider.state('insights-interactive-analysis-deprecated', {
      url: '/insights/interactive-analysis/:surveyId?packId&filter&token&t',
      redirectTo: 'interactiveAnalysis',
    })
  }
})()
