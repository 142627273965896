;(function() {
  'use strict'

  angular.module('app.core').component('loopDialog', {
    controller: Controller,
    templateUrl: 'loop-dialog.html',
    bindings: {
      survey: '<',
      onDone: '&',
      onCancel: '&',
    },
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.apply = apply

    function onInit() {
      ctrl.viewOptions = ctrl.survey.views.map(function(view) {
        return {
          label: view.value.getTitleLabel({ number: true }),
          value: view.value.id,
        }
      })
      ctrl.fromId = null
      ctrl.toId = null
      ctrl.variables = ''
    }

    function apply() {
      console.error('TODO: ensure from/to are valid range')
      var variables = ctrl.variables
        .split('\n')
        .map(function(value) {
          return value.trim()
        })
        .filter(function(value) {
          return !!value
        })

      // all views in the range are used as a template.
      // they are duplicated for each variable, assigned a new group if needed, and the
      // variable is injected into title, description, and image etc
      var active = false
      var tViews = ctrl.survey.views.filter(function(view) {
        var id = view.value.id
        if (id === ctrl.fromId) {
          active = true
        }
        if (id === ctrl.toId) {
          active = false
          return true
        }
        return active
      })

      variables.forEach(function(variable) {
        var viewGroup = null
        function checkAndAssignViewGroup(tView, view) {
          // if a template view being cloned belongs to a view group with a VARIABLE
          // label, then we need to create a new view group to be used in this set
          var tViewId = tView.value.id
          var viewId = view.value.id
          var tViewHasVariableGroup = ctrl.survey.viewGroups.find(function(g) {
            return g.name === 'VARIABLE' && g.viewIds.includes(tViewId)
          })
          if (tViewHasVariableGroup) {
            if (!viewGroup) viewGroup = ctrl.survey.addViewGroup(variable)
            viewGroup.select(viewId)
          }
        }

        // replicate views for this variable
        var views = tViews.map(function(tView) {
          // clone the view and its logic, but keep the same
          // choice & statement id's
          var view = tView.clone({
            cloneLogic: true,
            choiceIds: true,
            statementIds: true,
          })
          if (view.isSection()) {
            var section = view.value
            var title = section.title
            title = title.replaceAll('VARIABLE', variable)
            section.title = title
            section.titlePipes.set(title)
            if (section.imageUrl) {
              section.imageUrl = getVariableImage(variable)
            }
          }
          if (view.isQuestion()) {
            var question = view.value
            var title = question.title
            title = title.replaceAll('VARIABLE', variable)
            question.title = title
            question.titlePipes.set(title)
            var description = question.description || ''
            description = description.replaceAll('VARIABLE', variable)
            question.description = description
            question.descriptionPipes.set(description)
            if (question.imageUrl) {
              question.imageUrl = getVariableImage(variable)
            }
            _.each(question.choices, function(choice) {
              choice.value = choice.value.replaceAll('VARIABLE', variable)
            })
            _.each(question.statements, function(statement) {
              statement.value = statement.value.replaceAll('VARIABLE', variable)
            })
          }
          checkAndAssignViewGroup(tView, view)
          ctrl.survey.addView(view, undefined, true)
          return view
        })

        // TODO: view groups, shuffle groups, sampling!

        // rewire logic to reference the replicated set instead of the templated set
        function findReplicatedId(viewId) {
          var viewIdx = tViews.findIndex(function(v) {
            return v.value.id === viewId
          })

          // logic must point to a view outside the set so lets leave it the same
          if (viewIdx === -1) return viewId

          var view = views[viewIdx]
          var id = view.value.id
          return id
        }
        views.forEach(function(view) {
          var logic = view.value.logic
          logic.forEach(function(logic) {
            logic.conditions.forEach(function(condition) {
              if (condition.questionId) {
                condition.questionId = findReplicatedId(condition.questionId)
              }
            })
            logic.actions.forEach(function(action) {
              if (action.questionId) {
                action.questionId = findReplicatedId(action.questionId)
              }
              if (action.sectionId) {
                action.sectionId = findReplicatedId(action.sectionId)
              }
            })
          })
        })
      })

      // remove the template views
      tViews.forEach(function(tView) {
        ctrl.survey.removeView(tView)
      })

      // clean up view groups with the name VARIABLE if they have no more views
      ctrl.survey.viewGroups.forEach(function(viewGroup) {
        if (viewGroup.name === 'VARIABLE' && !viewGroup.viewIds.length) {
          ctrl.survey.removeViewGroup(viewGroup)
        }
      })

      ctrl.onDone()
    }

    function getVariableImage(variable) {
      var filename = variable
      // replace commas, spaces and ampersands with underscores
      filename = filename.replaceAll("'", '_')
      filename = filename.replaceAll(' ', '_')
      filename = filename.replaceAll('&', '_')
      // trim underscores from start and end
      filename = trimLeft(filename, '_')
      filename = trimRight(filename, '_')
      // replace two or more subsequent underscores with just a single underscore
      filename = filename.replace(new RegExp('_{2,}'), '_')
      return (
        'https://res.cloudinary.com/ei/image/upload/v1636066570/prod/variables/' +
        filename +
        '.png'
      )
    }

    function trimLeft(str, chars) {
      return str.replace(new RegExp('^[' + chars + ']+'), '')
    }

    function trimRight(str, chars) {
      return str.replace(new RegExp('[' + chars + ']+$'), '')
    }
  }
})()
