;(function() {
  'use strict'

  Controller.$inject = ["$state", "$rootScope", "glTips", "glAnalytics", "StateFactory", "featureService", "projectService", "userService", "accountService", "uacService", "incentivesService"];
  angular.module('app.projects').controller('Projects', Controller)

  /* @ngInject */
  function Controller(
    $state,
    $rootScope,
    glTips,
    glAnalytics,
    StateFactory,
    featureService,
    projectService,
    userService,
    accountService,
    uacService,
    incentivesService
  ) {
    var ctrl = this

    ctrl.state = new StateFactory([
      'loading',
      'zero',
      'ready',
      'error',
      'packViewer',
    ])

    ctrl.create = projectService.promptCreateAndView
    ctrl.edit = edit
    ctrl.goToProject = goToProject
    ctrl.createSurvey = createSurvey
    ctrl.goToKits = goToKits

    init()

    function init() {
      var user = userService.getUser()
      ctrl.isLoggedIn = userService.isLoggedIn()
      glAnalytics.track('projects', 'viewed', { loggedIn: ctrl.isLoggedIn })
      // TODO: re-add this when necessary
      // ctrl.isAdVisible = !_.includes(['steadfast', 'cardinia'], subscriberService.getSubscriber().subscriptionCode);
      var isPackViewer = user.initialRoute === 'analysisPacks'
      if (isPackViewer) {
        return ctrl.state.packViewer()
      }
      ctrl.state.loading()
      projectService
        .load()
        .then(function(projects) {
          ctrl.projects = projects
          ctrl.archived = projectService.getArchived()
          if (ctrl.projects.length) {
            ctrl.hasSurveys =
              _.maxBy(ctrl.projects, 'surveyCount').surveyCount > 0
            ctrl.state.ready()
          } else {
            ctrl.zeroProjects = projectService.getZeroDetails()
            var incentiveMsg = incentivesService.getInfoMessage(
              incentivesService.Types.CREATE_PROJECT
            )
            if (incentiveMsg) {
              ctrl.zeroProjects.message += '<br><br>' + incentiveMsg
            }
            ctrl.state.zero()
          }
          glTips.ready()
        })
        .catch(function() {
          ctrl.state.error()
        })
    }

    function createSurvey() {
      if (!uacService.canManageSurveys(true)) return
      $state.go('survey', {
        surveyId: 'new',
        tab: 'editor',
      })
    }

    function edit(project) {
      if (!uacService.canManageProjects(true)) return
      projectService.edit(project)
    }

    function goToProject(project) {
      $state.go('project', { id: project.id })
    }

    function goToKits() {
      $state.go('kits')
    }
  }
})()
