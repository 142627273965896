;(function() {
  'use strict'

  Service.$inject = ["glDialog", "Mod", "Choice"];
  angular.module('glow.reporting').service('reportModService', Service)

  /* @ngInject */
  function Service(glDialog, Mod, Choice) {
    this.showDialog = showDialog
    this.apply = apply

    function showDialog(survey, questionId) {
      // prettier-ignore
      var template = [
        '<gl-dialog class="report-mods__dialog">',
          '<report-mods survey="survey" question-id="questionId" on-done="dialog.close()" on-cancel="dialog.cancel()" />',
        '</gl-dialog>',
      ]
      return glDialog.show({
        template: template.join(' '),
        clickOutsideToClose: false,
        escapeToClose: false,
        locals: {
          survey: survey,
          questionId: questionId,
        },
      })
    }

    function apply(data) {
      for (const question of data.questions) {
        const choiceMods = _.get(question, 'data.choiceMods', [])
        if (!choiceMods.length) continue
        question.choices = []
        for (const mod of choiceMods) {
          // if (mod.hidden) continue
          question.choices.push({
            id: mod.id,
            option: mod.label,
            order: question.choices.length,
            data: {
              isModHidden: mod.hidden,
            },
          })
        }
        choiceMods.length = 0
      }
    }
  }
})()
