;(function() {
  'use strict'

  Controller.$inject = ["$scope", "$element", "questionLibrary", "QuestionLibraryFilter"];
  angular.module('core.questionLibrary').component('glQuestionLibraryFilters', {
    templateUrl: 'question-library-filters.template.html',
    controller: Controller,
    bindings: {
      templates: '<',
      pathways: '<',
      onPathwaysChange: '&',
    },
  })

  /* @ngInject */
  function Controller(
    $scope,
    $element,
    questionLibrary,
    QuestionLibraryFilter
  ) {
    var ctrl = this

    ctrl.filter = new QuestionLibraryFilter()
    ctrl.$onInit = onInit
    ctrl.$onChanges = onChanges
    ctrl.toggle = toggle

    function onInit() {
      // add class to component host
      $element.addClass('question-library-filters')
    }

    function onChanges() {
      ctrl.filter.update({
        templates: ctrl.templates,
        pathways: ctrl.pathways,
      })
    }

    function toggle(path) {
      ctrl.filter.toggle(path)
      ctrl.onPathwaysChange({ $pathways: _.clone(ctrl.filter.pathways) })
    }
  }
})()
