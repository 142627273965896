;(function() {
  'use strict'

  Controller.$inject = ["projectService", "glAnalytics"];
  angular.module('app.core').component('publicationProjectSelect', {
    controller: Controller,
    templateUrl: 'publication-project-select.html',
    bindings: {
      onChange: '&',
    },
  })

  /* @ngInject */
  function Controller(projectService, glAnalytics) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.addProject = addProject
    ctrl.removeProject = removeProject

    function onInit() {
      projectService.load().then(function(projects) {
        function orderByCreation(projects) {
          return _.orderBy(
            projects,
            function(project) {
              return project.createdAt.unix()
            },
            'desc'
          )
        }
        projects = orderByCreation(projects)
        var archived = orderByCreation(projectService.getArchived())
        var allProjects = _.concat(projects, archived)
        ctrl.projects = allProjects
        ctrl.projectOptions = _.map(allProjects, function(project) {
          return {
            value: project.id,
            label: project.name,
          }
        })
      })
      ctrl.selectedProjects = []
    }

    function addProject(projectId) {
      if (_.some(ctrl.selectedProjects, { id: projectId })) {
        return
      }
      var project = _.find(ctrl.projects, { id: projectId })
      ctrl.selectedProjects.push(project)
      ctrl.onChange({ $selectedProjects: ctrl.selectedProjects })
      glAnalytics.track(
        'publications',
        'filtered-by-publication-project',
        project.name
      )
    }

    function removeProject(project) {
      if (!_.some(ctrl.selectedProjects, { id: project.id })) {
        return
      }
      _.remove(ctrl.selectedProjects, { id: project.id })
      ctrl.onChange({ $selectedProjects: ctrl.selectedProjects })
    }
  }
})()
