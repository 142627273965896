;(function() {
  'use strict'

  Controller.$inject = ["glAnalytics"];
  angular.module('app.core').component('steadfastDialog', {
    controller: Controller,
    templateUrl: 'steadfast-dialog.html',
    bindings: {
      onClose: '&',
    },
  })

  /* @ngInject */
  function Controller(glAnalytics) {
    var ctrl = this

    ctrl.onDownload = onDownload

    function onDownload() {
      glAnalytics.track('steadfast-dialog', 'download-guide')
    }
  }
})()
