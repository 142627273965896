;(function() {
  'use strict'

  Controller.$inject = ["$rootScope", "StateFactory", "surveyServiceNext", "subscriberService"];
  angular.module('glow.reporting').component('reportSettings', {
    controller: Controller,
    templateUrl: 'report-settings.html',
    bindings: {
      surveyId: '<',
      onClose: '&',
    },
  })

  /* @ngInject */
  function Controller(
    $rootScope,
    StateFactory,
    surveyServiceNext,
    subscriberService
  ) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.save = save

    function onInit() {
      ctrl.subscriber = subscriberService.getSubscriber()
      ctrl.state = new StateFactory(['loading', 'ready', 'error', 'saving'])
      ctrl.state.loading()
      surveyServiceNext
        .get(ctrl.surveyId)
        .then(function(survey) {
          ctrl.survey = survey
          ctrl.state.ready()
        })
        .catch(function() {
          ctrl.state.error()
        })
    }

    function save() {
      if (ctrl.isSaving) return

      if (!ctrl.survey.reportSettings.validate()) {
        ctrl.errors = ctrl.survey.reportSettings.errors
        return
      }

      ctrl.errors = null
      ctrl.isSaving = true
      subscriberService
        .saveSubscriber(ctrl.subscriber)
        .then(function() {
          return surveyServiceNext.save(ctrl.survey).then(function() {
            $rootScope.$broadcast('report-settings:updated')
            ctrl.onClose()
          })
        })
        .catch(function(err) {
          if (err.data && err.data.message) {
            ctrl.errors = [err.data.message]
          } else {
            ctrl.errors = ['Something went wrong']
          }
        })
        .finally(function() {
          ctrl.isSaving = false
        })
    }
  }
})()
