;(function() {
  'use strict'

  config.$inject = ["$stateProvider"];
  angular.module('app.shared-dashboard', []).config(config)

  /* @ngInject */
  function config($stateProvider) {
    $stateProvider.state('shared-dashboard', {
      url: '/dashboards/:dashboardId',
      templateUrl: 'shared-dashboard.html',
      controller: 'SharedDashboardController',
      controllerAs: '$ctrl',
      title: 'Dashboard',
      public: true,
    })
  }
})()
