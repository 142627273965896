;(function() {
  'use strict'

  activityConfig.$inject = ["$stateProvider"];
  angular.module('app.activity', []).config(activityConfig)

  /* @ngInject */
  function activityConfig($stateProvider) {
    $stateProvider.state('activity', {
      url: '/activity',
      parent: 'portal',
      templateUrl: 'activity.template.html',
      controller: 'ActivityController',
      controllerAs: 'activity',
      title: 'Activity',
    })
  }
})()
