;(function() {
  'use strict'

  Controller.$inject = ["subscriberService"];
  angular.module('app.core').component('accountUacEditor', {
    controller: Controller,
    templateUrl: 'account-uac-editor.html',
    bindings: {
      memberSrc: '<member',
      onDone: '&',
      onCancel: '&',
    },
  })

  /* @ngInject */
  function Controller(subscriberService) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.isAllChecked = isAllChecked
    ctrl.checkAll = checkAll
    ctrl.save = save
    ctrl.cancel = cancel

    function onInit() {
      ctrl.member = _.cloneDeep(ctrl.memberSrc)
    }

    function isAllChecked() {
      return (
        ctrl.member.canManageUsers &&
        ctrl.member.canManageProjects &&
        ctrl.member.canManageSurveys &&
        ctrl.member.canManageChannels &&
        ctrl.member.canLaunchChannels &&
        ctrl.member.canManageAnalysisFilters &&
        ctrl.member.canManageAnalysisSettings &&
        ctrl.member.canExportAndShareAnalysis &&
        ctrl.member.canViewAnalysisCrosstabs &&
        ctrl.member.canManageAnalysisCrosstabs &&
        ctrl.member.canViewAnalysisResponses &&
        ctrl.member.canManageAnalysisResponses &&
        ctrl.member.canManageReports
      )
    }

    function checkAll(checked) {
      ctrl.member.canManageUsers = checked
      ctrl.member.canManageProjects = checked
      ctrl.member.canManageSurveys = checked
      ctrl.member.canManageChannels = checked
      ctrl.member.canLaunchChannels = checked
      ctrl.member.canManageAnalysisFilters = checked
      ctrl.member.canManageAnalysisSettings = checked
      ctrl.member.canExportAndShareAnalysis = checked
      ctrl.member.canViewAnalysisCrosstabs = checked
      ctrl.member.canManageAnalysisCrosstabs = checked
      ctrl.member.canViewAnalysisResponses = checked
      ctrl.member.canManageAnalysisResponses = checked
      ctrl.member.canManageReports = checked
    }

    function save() {
      if (ctrl.isSaving) return

      ctrl.error = ''
      ctrl.isSaving = true
      subscriberService
        .updateMemberUAC(ctrl.member)
        .then(function() {
          ctrl.onDone()
        })
        .catch(function(err) {
          ctrl.error = err.message
        })
        .finally(function() {
          ctrl.isSaving = false
        })
    }

    function cancel() {
      if (ctrl.isSaving) return
      ctrl.onCancel()
    }
  }
})()
