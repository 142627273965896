;(function() {
  'use strict'

  config.$inject = ["$stateProvider"];
  angular.module('app.core').config(config)

  /* @ngInject */
  function config($stateProvider) {
    $stateProvider.state('portal', {
      abstract: true,
      parent: 'auth',
      controller: 'Portal',
      templateUrl: 'portal.html',
    })
  }
})()
