;(function() {
  'use strict'

  Controller.$inject = ["$q", "$scope", "$element", "$log", "stripeService"];
  angular.module('core.stripe').component('stripeCard', {
    templateUrl: 'stripe-card.html',
    controller: Controller,
    bindings: {
      model: '=bindTo',
      onSubmit: '&',
    },
  })

  /* @ngInject */
  function Controller($q, $scope, $element, $log, stripeService) {
    var ctrl = this
    var log = $log.create('stripeCard')

    var Style = {
      base: {
        '::placeholder': {
          color: 'rgba(0, 0, 0, .24)',
        },
      },
    }

    ctrl.$onInit = onInit
    ctrl.$onDestroy = onDestroy

    function onInit() {
      $element.addClass('stripe-card')

      ctrl.elements = stripeService.createGroup()
      ctrl.fields = {
        number: {},
        expiry: {},
        cvc: {},
      }

      // number
      ctrl.numberElem = $element[0].querySelector('.stripe-card__mount.-number')
      ctrl.number = ctrl.elements.create('cardNumber', { style: Style })
      ctrl.number.mount(ctrl.numberElem)
      ctrl.number.on('change', function(event) {
        $scope.$applyAsync(function() {
          var field = ctrl.fields.number
          field.$brand = event.brand
          field.$complete = event.complete
          field.$error = event.error
          updateModel()
        })
      })

      // expiry
      ctrl.expiryElem = $element[0].querySelector('.stripe-card__mount.-expiry')
      ctrl.expiry = ctrl.elements.create('cardExpiry', { style: Style })
      ctrl.expiry.mount(ctrl.expiryElem)
      ctrl.expiry.on('change', function(event) {
        $scope.$applyAsync(function() {
          var field = ctrl.fields.expiry
          field.$complete = event.complete
          field.$error = event.error
          updateModel()
        })
      })

      // cvc
      ctrl.cvcElem = $element[0].querySelector('.stripe-card__mount.-cvc')
      ctrl.cvc = ctrl.elements.create('cardCvc', { style: Style })
      ctrl.cvc.mount(ctrl.cvcElem)
      ctrl.cvc.on('change', function(event) {
        $scope.$applyAsync(function() {
          var field = ctrl.fields.cvc
          field.$complete = event.complete
          field.$error = event.error
          updateModel()
        })
      })

      ctrl.model = {
        $complete: false,
        $error: undefined,
        submit: submit,
      }
    }

    function updateModel() {
      var number = ctrl.fields.number
      var expiry = ctrl.fields.expiry
      var cvc = ctrl.fields.cvc

      ctrl.model.$complete =
        number.$complete && expiry.$complete && cvc.$complete
      ctrl.model.$error = number.$error || expiry.$error || cvc.$error
      ctrl.model.$brand = number.$brand
    }

    function submit() {
      return $q(function(resolve, reject) {
        if (!ctrl.model.$complete) {
          log.info('submit ignored (incomplete)')
          return reject()
        }
        stripeService.createToken(ctrl.number).then(function(result) {
          // console.log('result', result);
          if (result.error) {
            return reject(result.error)
          }
          ctrl.model.last4 = result.token.card.last4
          ctrl.model.tokenId = result.token.id
          resolve()
        })
      })
    }

    function onDestroy() {
      ctrl.number.unmount()
      ctrl.expiry.unmount()
      ctrl.cvc.unmount()
    }
  }
})()
