;(function() {
  'use strict'

  Controller.$inject = ["subscriberService", "userService", "invoicesService", "InvoiceItem"];
  angular.module('app.core').component('invoice', {
    templateUrl: 'invoice.html',
    controller: Controller,
    bindings: {
      invoice: '<',
    },
  })

  /* @ngInject */
  function Controller(
    subscriberService,
    userService,
    invoicesService,
    InvoiceItem
  ) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.add = add
    ctrl.finalise = finalise
    ctrl.markAsPaid = markAsPaid

    function onInit() {
      ctrl.isSSR = userService.isSSR()
      ctrl.subscriber = subscriberService.getSubscriber()
      ctrl.user = userService.getUser()
      ctrl.taxCode = subscriberService.getTax().code
      ctrl.types = _.map(InvoiceItem.types, function(type) {
        return {
          label: type.name,
          value: type.id,
        }
      })
      if (ctrl.invoice.isPaid && ctrl.invoice.charge) {
        ctrl.paymentMessage = [
          'Paid by',
          ctrl.invoice.charge.source.brand,
          'on',
          moment.unix(ctrl.invoice.charge.created).format('DD MMM YYYY'),
          ' - Thank you!',
        ].join(' ')
      }
      if (ctrl.isSSR) {
        spawnDraft()
      }
    }

    function add() {
      var item = ctrl.draft
      spawnDraft()
      invoicesService.add(ctrl.invoice, item).catch(function() {
        ctrl.draft = item
      })
    }

    function finalise() {
      invoicesService.finalise(ctrl.invoice)
    }

    function markAsPaid() {
      invoicesService.markAsPaid(ctrl.invoice)
    }

    function spawnDraft() {
      ctrl.draft = new InvoiceItem(ctrl.invoice)
      ctrl.draft.groupId = ctrl.subscriber.id
      ctrl.draft.creator.id = ctrl.user.id
      ctrl.draft.creator.name = ctrl.user.fullname
      ctrl.draft.creator.email = ctrl.user.email
    }
  }
})()
