;(function() {
  'use strict'

  angular.module('app.core').component('gFlag', {
    templateUrl: 'g-flag.html',
    bindings: {
      country: '<',
    },
  })
})()
