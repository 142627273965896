;(function() {
  'use strict'

  Service.$inject = ["$q", "glDialog"];
  angular.module('app.core').service('logicEditor', Service)

  /* @ngInject */
  function Service($q, glDialog) {
    this.show = show

    function show(options) {
      // prettier-ignore
      var template = [
        '<gl-dialog class="logic-editor__dialog">',
          '<logic-editor ',
            'survey="survey" ',
            'view="view" ',
            'is-forbidden="isForbidden" ',
            'on-change="onChange({})" ',
            'on-done="dialog.close($clickedSave)" ',
            'on-cancel="dialog.cancel()" ',
          '/>',
        '</gl-dialog>',
      ]
      return glDialog.show({
        template: template.join(''),
        clickOutsideToClose: true,
        escapeToClose: true,
        locals: {
          survey: options.survey,
          view: options.view,
          isForbidden: options.isForbidden,
          onChange: options.onChange,
        },
      })
    }
  }
})()
