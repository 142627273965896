;(function() {
  'use strict'

  Controller.$inject = ["$element", "$timeout"];
  angular
    .module('glow.reporting')
    .component('glQuestionConstantSumFilter', Component())

  function Component() {
    return {
      controller: Controller,
      templateUrl: 'constant-sum-filter.template.html',
      bindings: {
        question: '<',
        filter: '=',
      },
    }
  }

  /* @ngInject */
  function Controller($element, $timeout) {
    var ctrl = this
    var NUMBER_REGEX = /^\s*\d+\s*$/

    ctrl.$onInit = onInit
    ctrl.$onChanges = onChanges
    ctrl.toggle = toggle
    ctrl.onBlur = onBlur

    function onInit() {
      if (ctrl.filter.newAspect && ctrl.question.isMatrix) {
        ctrl.filter.data.aspect = ctrl.filter.newAspect
        ctrl.filter.newAspect = undefined
      }

      if (ctrl.filter.newValue) {
        var choice = _.find(ctrl.question.choices, {
          option: ctrl.filter.newValue,
        })
        // Only add the choice if not already selected/added
        if (choice && !_.find(ctrl.filter.data.values, { id: choice.id })) {
          ctrl.filter.data.values.push({
            id: choice.id,
            usesStatement: ctrl.question.isMatrix,
          })
        }
        ctrl.filter.newValue = undefined
      }

      _.each(ctrl.filter.data.values, function(choice) {
        var questionChoice = _.find(ctrl.question.choices, { id: choice.id })
        if (questionChoice) {
          questionChoice.isSelected = true
          if (choice.numbers && choice.numbers.length) {
            questionChoice.numbersStr = choice.numbers.join(',')
          }
        }
      })
    }

    function onChanges() {
      ctrl.question = _.cloneDeep(ctrl.question)
      ctrl.statements = null
      if (ctrl.question.isMatrix) {
        ctrl.statements = [{ label: 'Any Statement' }]
        _.each(ctrl.question.statements, function(statement) {
          ctrl.statements.push({
            label: statement.statement,
            value: statement.id,
          })
        })
      }
    }

    function toggle(choice) {
      if (choice.isSelected) {
        ctrl.filter.data.values.push({
          id: choice.id,
          usesStatement: ctrl.question.isMatrix,
        })
        setInputFocus(choice)
      } else {
        _.remove(ctrl.filter.data.values, { id: choice.id })
      }
    }

    function setInputFocus(choice) {
      var input = angular.element(
        $element[0].querySelector('#choice' + choice.id + 'numbersStr')
      )
      $timeout(function() {
        input[0].focus()
      })
    }

    function onBlur(choice) {
      var numbers = getNumbersFromStr(choice.numbersStr)
      var filterValue = _.find(ctrl.filter.data.values, { id: choice.id })
      if (filterValue) {
        filterValue.numbers = numbers
      }
      choice.numbersStr = numbers ? numbers.join(',') : ''
    }

    function getNumbersFromStr(numbersStr) {
      if (!numbersStr) return null
      var numbers = numbersStr
        .split(',')
        .map(function(str) {
          return NUMBER_REGEX.test(str) ? +str : null
        })
        .filter(function(number) {
          return number !== null
        })
      return numbers.length ? numbers : null
    }
  }
})()
