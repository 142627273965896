;(function() {
  'use strict'

  angular.module('core.filters').filter('dateago', Filter)

  /* @ngInject */
  function Filter() {
    var FORMATS = {
      lastDay: '[Yesterday at] LT',
      sameDay: '[Today at] LT',
      nextDay: '[Tomorrow at] LT',
      lastWeek: 'dddd [at] LT',
      nextWeek: 'dddd [at] LT',
      sameElse: 'MMM D, YYYY [at] LT',
    }

    return function(date, referenceTime) {
      return date.calendar(referenceTime, FORMATS)
    }
  }
})()
