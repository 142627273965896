;(function() {
  'use strict'

  Controller.$inject = ["$scope", "$timeout", "$document", "glPrefs", "questionLibrary"];
  angular.module('core.questionLibrary').component('questionLibrary', {
    controller: Controller,
    templateUrl: 'question-library.html',
    bindings: {
      onSelect: '&',
      onNew: '&',
    },
  })

  /* @ngInject */
  function Controller($scope, $timeout, $document, glPrefs, questionLibrary) {
    var ctrl = this
    var prefs = glPrefs.create('question-library')
    var STATE_PREF = 'state'
    var lastRequest = 0

    ctrl.fetch = fetch
    ctrl.onQueryChange = _.debounce(fetch, 700, { trailing: true })
    ctrl.updatePathways = updatePathways
    ctrl.clearFilters = clearFilters
    ctrl.buttonText = buttonText

    ctrl.$onInit = onInit

    function onInit() {
      ctrl.pane = findPane()

      var state = getState()
      ctrl.pathways = state.pathways || []
      ctrl.query = state.query

      fetch(state.scrollTop)

      $scope.$on('$destroy', onDestroy)
    }

    function fetch(scrollTop) {
      var thisRequest = ++lastRequest
      var options = {
        query: ctrl.query,
        pathways: ctrl.pathways,
        state: 'PUBLISHED',
      }
      ctrl.templates = null
      questionLibrary.search(options).then(function(templates) {
        if (thisRequest !== lastRequest) {
          return
        }
        ctrl.templates = templates
        // console.log('showing ' + templates.length + ' templates');
        $timeout(function() {
          ctrl.pane.scrollTop = scrollTop
        })
      })
    }

    function updatePathways(pathways) {
      ctrl.pathways = pathways
      fetch()
    }

    function clearFilters() {
      ctrl.pathways = []
      ctrl.query = undefined
      fetch()
    }

    function getState() {
      return prefs.get(STATE_PREF, { user: true }) || {}
    }

    function setState(state) {
      prefs.set(STATE_PREF, state, { user: true })
    }
    function findPane() {
      return $document[0].body.querySelectorAll(
        '.question-library__main-right'
      )[0]
    }

    function onDestroy() {
      setState({
        pathways: ctrl.pathways,
        query: ctrl.query,
        scrollTop: ctrl.pane.scrollTop,
      })
    }

    function buttonText(template) {
      switch (template.type) {
        case 'choice':
        case 'scale':
          return template.questions.length > 1
            ? template.questions.length + ' Variations'
            : 'View'
        default:
          return 'Add'
      }
    }
  }
})()
