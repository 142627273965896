;(function() {
  'use strict'

  Controller.$inject = ["PanelTargetCell", "PanelTargetNode"];
  angular.module('app.core').component('panelTargetCellBuilder', {
    templateUrl: 'panel-target-cell-builder.html',
    controller: Controller,
    bindings: {
      target: '<',
      attributesById: '<',
      onAdd: '&',
      onCancel: '&',
    },
  })

  /* @ngInject */
  function Controller(PanelTargetCell, PanelTargetNode) {
    var ctrl = this
    var REGEX_INTEGER = new RegExp('^\\d+$')
    var NEW_LINE_REGEX = /\r\n|\r|\n/g

    ctrl.$onInit = onInit
    ctrl.isAvailable = isAvailable
    ctrl.isSelected = isSelected
    ctrl.toggleSelected = toggleSelected
    ctrl.canAdd = canAdd
    ctrl.add = add

    var addFns = {
      LIST: addList,
      INTEGER_RANGE: addIntegerRange,
      STRING: addString,
      INTEGER: addInteger,
    }

    function onInit() {
      if (ctrl.target.attributeIds.length > 1) {
        console.error('cell builder not possible with interlocking quotas')
      }
      ctrl.attribute = ctrl.attributesById[ctrl.target.attributeIds[0]]
      ctrl.title = getTitle()
      ctrl.selected = []
      ctrl.range = [null, null]
      ctrl.string = null
      ctrl.integer = null
    }

    function isAvailable(option) {
      return !_.some(ctrl.target.cells, function(cell) {
        return _.includes(cell.nodes[0].options, option.value)
      })
    }

    function isSelected(option) {
      return _.includes(ctrl.selected, option.value)
    }

    function toggleSelected(option) {
      if (isSelected(option)) {
        _.remove(ctrl.selected, function(value) {
          return value === option.value
        })
      } else {
        ctrl.selected.push(option.value)
      }
    }

    function canAdd(group) {
      switch (ctrl.attribute.type) {
        case 'LIST':
          return group ? ctrl.selected.length > 1 : ctrl.selected.length
        case 'INTEGER_RANGE':
          return (
            _.isNumber(ctrl.range[0]) &&
            _.isNumber(ctrl.range[1]) &&
            ctrl.range[0] <= ctrl.range[1] &&
            doesNotOverlapOtherRanges()
          )
        case 'STRING':
          return ctrl.string
        case 'INTEGER':
          return REGEX_INTEGER.test(ctrl.integer)
      }
    }

    function add(group) {
      var cells = []
      var addFn = addFns[ctrl.attribute.type]
      if (addFn) addFn(cells, group)
      ctrl.onAdd({ $cells: cells })
    }

    function addList(cells, group) {
      if (group) {
        var cell = new PanelTargetCell()
        var node = new PanelTargetNode()
        node.attributeId = ctrl.attribute.id
        node.options = ctrl.selected.slice()
        cell.nodes.push(node)
        cells.push(cell)
      } else {
        ctrl.selected.forEach(function(option) {
          var cell = new PanelTargetCell()
          var node = new PanelTargetNode()
          node.attributeId = ctrl.attribute.id
          node.options = [option]
          cell.nodes.push(node)
          cells.push(cell)
        })
      }
    }

    function addIntegerRange(cells) {
      var cell = new PanelTargetCell()
      var node = new PanelTargetNode()
      node.attributeId = ctrl.attribute.id
      node.options = [ctrl.range[0] + '-' + ctrl.range[1]]
      cell.nodes.push(node)
      cells.push(cell)
    }

    function addString(cells) {
      var texts = ctrl.string.split(NEW_LINE_REGEX)
      _.each(texts, function(text) {
        text = text.trim()
        if (text) {
          var cell = new PanelTargetCell()
          var node = new PanelTargetNode()
          node.attributeId = ctrl.attribute.id
          node.options = [text]
          cell.nodes.push(node)
          cells.push(cell)
        }
      })
    }

    function addInteger(cells) {
      var cell = new PanelTargetCell()
      var node = new PanelTargetNode()
      node.attributeId = ctrl.attribute.id
      node.options = [ctrl.integer]
      cell.nodes.push(node)
      cells.push(cell)
    }

    function getTitle() {
      switch (ctrl.attribute.type) {
        case 'LIST':
          return 'Add Options'
        case 'INTEGER_RANGE':
          return 'Add Range'
        case 'STRING':
        case 'INTEGER':
          return 'Add'
      }
    }

    function doesNotOverlapOtherRanges() {
      var valid = true
      _.each(ctrl.target.cells, function(cell) {
        _.each(cell.nodes, function(node) {
          _.each(node.options, function(option) {
            var otherRange = option.split('-').map(function(n) {
              return parseInt(n)
            })
            if (isOverlap(ctrl.range, otherRange)) valid = false
          })
        })
      })
      return valid
    }

    function isOverlap(r1, r2) {
      if (r1[0] < r2[0]) return r1[1] >= r2[0]
      return r1[0] <= r2[1]
    }
  }
})()
