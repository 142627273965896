;(function() {
  'use strict'

  Controller.$inject = ["$document"];
  angular.module('app.core').component('background', {
    controller: Controller,
    bindings: {
      color: '@',
    },
  })

  /* @ngInject */
  function Controller($document) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.$onDestroy = onDestroy

    function onInit() {
      if (ctrl.color) {
        $document.find('html')[0].style.backgroundColor = ctrl.color
      }
    }

    function onDestroy() {
      $document.find('html')[0].removeAttribute('style')
    }
  }
})()
