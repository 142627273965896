;(function() {
  'use strict'

  Controller.$inject = ["$state", "$window", "Resource", "assetService"];
  angular.module('app.core').component('resourceItem', {
    controller: Controller,
    templateUrl: 'resource-item.html',
    bindings: {
      resource: '<',
      inLibrary: '<',
      isPublisher: '<',
      onPreview: '&',
      onOpen: '&',
    },
  })

  /* @ngInject */
  function Controller($state, $window, Resource, assetService) {
    var ctrl = this

    ctrl.Types = Resource.Types
    ctrl.canPreviewFile = canPreviewFile
    ctrl.canPreviewAnalysis = canPreviewAnalysis
    ctrl.canPreviewLink = canPreviewLink
    ctrl.getPreviewLink = getPreviewLink
    ctrl.previewFile = previewFile
    ctrl.canOpen = canOpen
    ctrl.open = open

    function canPreview() {
      if (ctrl.isPublisher || ctrl.inLibrary) {
        return false
      }
      return true
    }

    function canPreviewFile() {
      return (
        canPreview() &&
        ctrl.resource.type.type === ctrl.Types.FILE &&
        !!ctrl.resource.previewAssetId
      )
    }

    function canPreviewAnalysis() {
      return (
        canPreview() &&
        ctrl.resource.type.type === ctrl.Types.INTERACTIVE_ANALYSIS &&
        !!ctrl.resource.surveyPreviewToken
      )
    }

    function canPreviewLink() {
      return (
        canPreview() &&
        ctrl.resource.type.type === ctrl.Types.LINK &&
        !!ctrl.resource.previewUrl
      )
    }

    function getPreviewLink() {
      if (canPreviewLink()) {
        return
      }
    }

    function previewFile() {
      // if (ctrl.resource.type.type === ctrl.Types.FILE) {
      assetService.download(
        ctrl.resource.previewAssetId,
        'Download Preview',
        'You are downloading a preview file for this report.'
      )
      ctrl.onPreview()
    }

    function canOpen() {
      return ctrl.isPublisher || ctrl.inLibrary
    }

    function open() {
      if (ctrl.resource.type.type === ctrl.Types.FILE) {
        assetService.download(
          ctrl.resource.assetId,
          'Download File',
          'You are downloading the file for this report.'
        )
      }
      if (ctrl.resource.type.type === ctrl.Types.INTERACTIVE_ANALYSIS) {
        $state.go('interactiveAnalysis', {
          surveyId: ctrl.resource.surveyId,
        })
      }
      if (ctrl.resource.type.type === ctrl.Types.LINK) {
        $window.open(ctrl.resource.url, '_blank')
      }
      ctrl.onOpen()
    }
  }
})()
