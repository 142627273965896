/* global Stripe */
;(function() {
  'use strict'

  Service.$inject = ["$log", "configService"];
  angular.module('core.stripe').factory('stripeService', Service)

  /* @ngInject */
  function Service($log, configService) {
    var log = $log.create('stripe')
    var stripe

    var APIKeys = {
      Development: 'pk_test_uIRXxpknOkYCwsYigFWJDveg',
      Staging: 'pk_test_uIRXxpknOkYCwsYigFWJDveg',
      Production: 'pk_live_oRQ56p35gTpGYasMP7kmKFIq',
    }

    init()

    return {
      createToken: stripe.createToken,
      createGroup: createGroup,
    }

    function init() {
      var env = configService.getBuildEnv()
      var apiKey = APIKeys[env]
      if (!apiKey) {
        return log.error('no apiKey')
      }
      stripe = Stripe(apiKey)
    }

    function createGroup() {
      return stripe.elements({
        // fonts: {
        //     family: 'Roboto',
        //     src: 'TODO'
        // }
      })
    }
  }
})()
