;(function() {
  'use strict'

  angular.module('app.core').component('publicationSearch', {
    controller: Controller,
    templateUrl: 'publication-search.html',
    bindings: {
      onChange: '&',
    },
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.clear = clear

    function onInit() {
      // ...
    }

    function clear() {
      ctrl.query = null
      ctrl.onChange({ $query: null })
    }
  }
})()
