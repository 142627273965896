;(function() {
  'use strict'

  angular.module('glow.reporting').component('reportCrosstabRenamer', {
    controller: Controller,
    templateUrl: 'report-crosstab-renamer.html',
    bindings: {
      original: '<',
      current: '<',
      onDone: '&',
      onCancel: '&',
    },
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit

    function onInit() {
      ctrl.value = ctrl.current
    }
  }
})()
