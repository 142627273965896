;(function() {
  'use strict'

  pagesService.$inject = ["$log", "$rootScope", "$q", "imagesService", "userService", "pagesResource", "pageElementsResource", "AUTH_EVENTS"];
  angular.module('core.services').factory('pagesService', pagesService)

  /* @ngInject */
  function pagesService(
    $log,
    $rootScope,
    $q,
    imagesService,
    userService,
    pagesResource,
    pageElementsResource,
    AUTH_EVENTS
  ) {
    var pages
    var lastPageViewed
    var pagesDeffered
    var log = $log.create('pagesService')

    // reset pages on logout
    $rootScope.$on(AUTH_EVENTS.logoutSuccess, function() {
      pages = null
      lastPageViewed = null
      pagesDeffered = null
    })

    return {
      getAll: getAll,
      getPage: getPage,
      getLastPage: getLastPage,
      create: create,
      update: update,
      deletePage: deletePage,
      cascadeTheme: cascadeTheme,
    }

    /**
     * @name getAll
     * @description get all pages for a subscriber
     * @return {Promise}
     */
    function getAll() {
      // prevent multiple requests being fired when opening survey report where 3 modules all request on load
      if (pagesDeffered) {
        return pagesDeffered.promise
      }

      pagesDeffered = $q.defer()

      // if already cached, return elements
      if (pages) {
        return $q.when(pages)
      }

      var options = {
        filter: 'groupId eq ' + userService.getSubscriberId(),
      }

      pagesResource
        .get(options)
        .success(function(res, data) {
          // cache pages - if pages exists (getPage has returned first) then we add these to the array
          // provided they are unique
          if (pages) {
            _.forEach(data, function(page) {
              if (_.findIndex(pages, { id: page.id }) === -1) {
                pages.push(page)
              }
            })
          } else {
            pages = data
          }
          pagesDeffered.resolve(pages)
        })
        .error(pagesDeffered.reject)

      return pagesDeffered.promise
    }

    /**
     * @name getPage
     * @description get a page by page ID
     * @param  {String} pageId pages ID
     * @return {Promise}
     */
    function getPage(pageId) {
      var deferred = $q.defer()

      if (!pageId) {
        deferred.reject('Page ID is required')
      } else {
        // check cache
        var index = _.findIndex(pages, { id: pageId })
        if (index !== -1) {
          setLastPage(pages[index])
          deferred.resolve(pages[index])
        } else {
          // otherwise request from API
          pagesResource
            .get({ id: pageId })
            .success(function(res, data) {
              // cache pages - if pages does not exist (this request has returned before page list)
              // so we create the pages array to maintain reference
              if (!pages) {
                pages = [data]
                setLastPage(pages[0])
                deferred.resolve(pages[0])
              } else {
                index = _.findIndex(pages, { id: pageId })
                setLastPage(pages[index])
                deferred.resolve(pages[index])
              }
            })
            .error(deferred.reject)
        }
      }

      return deferred.promise
    }

    /**
     * @name setLastPage
     * @description set lastPageViewed to the page of the last page accessed
     * @param {String} page page
     */
    function setLastPage(page) {
      lastPageViewed = page
    }

    /**
     * @name getLastPage
     * @description get the last page viewed by the user - return the first page by default
     * @return {String} page
     */
    function getLastPage() {
      if (lastPageViewed) {
        return $q.when(lastPageViewed)
      } else {
        return getAll().then(function() {
          if (_.isEmpty(pages)) {
            return null
          } else {
            setLastPage(pages[0])
            return pages[0]
          }
        })
      }
    }

    /**
     * @name  create
     * @description create new page - upload images first and then save the page with resultant image URL's
     * @param  {Object} pageData page data
     * @return {Promise}
     */
    function create(pageData) {
      var deferred = $q.defer()

      // first upload the images so we can get the URL's to use for the page
      handleImageUploads(pageData).then(function(data) {
        // set image urls
        if (data[0]) {
          pageData.logoUrl = data[0].imageUrl
        }
        if (data[1]) {
          pageData.featureImageUrl = data[1].imageUrl
        }

        // save page
        pageData.groupId = userService.getSubscriberId()
        pagesResource
          .create(pageData)
          .success(createSuccess)
          .error(deferred.reject)
      })

      return deferred.promise

      // push new page to cached pages before resolving - prevents need to refresh
      function createSuccess(data) {
        pageData.id = data.id

        // DEBT: pages is populated after entering the portal BUT the signup route can pre-install
        // pages before this happens, so we only add to pages cache if pages exists.
        if (pages) {
          pages.push(pageData)
          $rootScope.$broadcast('pagesService::page-created')
        }
        deferred.resolve(pageData.id)
      }
    }

    /**
     * @name update
     * @description update a pages data
     * @param  {Object} pageData page object to save
     * @return {Promise}
     */
    function update(pageData) {
      var deferred = $q.defer()

      // sanitise hashtags
      if (pageData.hashTags) {
        // clear empty hashtag strings due to ng-model
        pageData.hashTags = _.without(pageData.hashTags, '')
        // sanitise (remove hashes and spaces)
        pageData.hashTags = _.map(pageData.hashTags, function(tag) {
          return tag.replace(/([#\s])+/g, '')
        })
      }

      pageData.groupId = userService.getSubscriberId()
      pagesResource
        .update(pageData)
        .success(updateSuccess)
        .error(deferred.reject)

      return deferred.promise

      // update page in pages
      function updateSuccess() {
        // replace the current page with the new page - we clone it to ensure it does not bind to its source
        var index = _.findIndex(pages, { id: pageData.id })
        _.merge(pages[index], pageData)

        deferred.resolve()
      }
    }

    /**
     * @name handleImageUploads when creating a page
     * @description queue image uploads and return a promise
     * @param  {Object} pageData page data object
     * @return {Promise}
     */
    function handleImageUploads(pageData) {
      var logo = buildPageImageData(pageData.logoData, 'page.create')
      var featureImage = buildPageImageData(
        pageData.featureImageData,
        'page.create'
      )

      var imageUploads = [
        imagesService.create(logo),
        imagesService.create(featureImage),
      ]

      delete pageData.logoData
      delete pageData.featureImageData

      return $q.all(imageUploads)
    }

    /**
     * @name buildPageImageData
     * @description take image data and return a structured object to be sent to the API
     * @param  {String} imageData base64 image data
     * @param  {String} request   the opperation making the request to upload an image
     * @return {Object}           structured image upload object
     */
    function buildPageImageData(imageData, request) {
      if (!imageData) {
        return null
      }

      return {
        groupId: userService.getSubscriberId(),
        imageData: imageData,
        request: request,
        source: 'portal.glowfeed.com',
      }
    }

    /**
     * @name deletePage
     * @description remove a page from a subscription
     * @param  {Object} page page object
     * @return {Promise}
     */
    function deletePage(page) {
      var deferred = $q.defer()

      pagesResource
        .delete({ id: page.id })
        .success(removeSuccess)
        .error(deferred.reject)

      return deferred.promise

      function removeSuccess() {
        // remove page from local version of pages
        _.remove(pages, function(p) {
          return p.id === page.id
        })

        deferred.resolve()
      }
    }

    /**
     * @name cascadeTheme
     * @desc updates all pages with blank theme values with the values passed in
     * @param {Object} pageTheme - a dictionary with the signature { logoUrl, featureImageUrl, themeColor }
     * @returns {Promise}
     */
    function cascadeTheme(pageTheme) {
      var updates = []
      var keys = ['logoUrl', 'featureImageUrl', 'themeColor']

      // enforce keys we can update
      pageTheme = _.pick(pageTheme, keys)

      log.debug('cascading started:', pageTheme)

      // force get all pages because activate-screen hasnt got them yet
      return getAll(true).then(function() {
        // iterate pages
        _.each(pages, function(page) {
          // update any theme keys that are not yet set, using the ones passed we are given
          // we could just use `_.defaults(page, pageTheme)` but we need to know which page changed
          _.each(keys, function(key) {
            if (!page[key] && pageTheme[key]) {
              page[key] = pageTheme[key]
              page.didChange = true
              log.debug('cascade will update "' + key + '" on page: ' + page.id)
            }
          })
          // if we updated a page, save it and add the promise to our queue
          if (page.didChange) {
            delete page.didChange
            updates.push(update(page))
          }
        })

        // return the promise queue
        return $q.all(updates)
      })
    }
  }
})()
