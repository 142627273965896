;(function() {
  'use strict'

  angular.module('core.orgSelector').component('orgSelector', {
    controller: Controller,
    templateUrl: 'org-selector.html',
    bindings: {
      options: '<',
      onSelect: '&',
      onCancel: '&',
    },
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.select = select

    function onInit() {
      // ...
    }

    function select(event, orgId) {
      if (event.defaultPrevented) {
        return
      }
      ctrl.onSelect({ $id: orgId })
    }
  }
})()
