;(function() {
  'use strict'

  angular.module('core.kitListSelect').component('glKitListSelect', Component())

  function Component() {
    return {
      controller: Controller,
      templateUrl: 'kit-list-select.html',
      bindings: {
        kits: '<',
        onKitSelect: '&',
      },
    }
  }

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit

    function onInit() {}
  }
})()
