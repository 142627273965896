;(function() {
  'use strict'

  Service.$inject = ["$q", "glDialog"];
  angular.module('app.core').service('invoiceService', Service)

  /* @ngInject */
  function Service($q, glDialog) {
    this.show = show

    function show(invoice) {
      // prettier-ignore
      var template = [
        '<gl-dialog class="invoice__dialog">',
          '<g-dialog-header ',
            'title="{{ title }}" ',
            'on-close="dialog.cancel()" ',
          '></g-dialog-header>',
          '<invoice invoice="invoice" />',
        '</gl-dialog>',
      ]
      return glDialog.show({
        template: template.join(''),
        locals: {
          title: invoice.description || 'Invoice',
          invoice: invoice,
        },
        clickOutsideToClose: true,
      })
    }
  }
})()
