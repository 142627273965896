;(function() {
  'use strict'

  config.$inject = ["$stateProvider"];
  angular.module('app.dashboard', []).config(config)

  /* @ngInject */
  function config($stateProvider) {
    $stateProvider.state('dashboard', {
      url: '/dashboard?didVerify',
      parent: 'portal',
      templateUrl: 'dashboard.html',
      controller: 'Dashboard',
      controllerAs: '$ctrl',
      params: {
        didSignup: null,
        promotion: null,
      },
    })
  }
})()
