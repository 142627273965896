;(function() {
  'use strict'

  Controller.$inject = ["$q", "$window", "Beacon", "glUtils", "glToast", "beaconService", "configService", "imagesService", "clipboardService"];
  angular.module('app.core').component('beaconListItem', {
    controller: Controller,
    templateUrl: 'beacon-list-item.html',
    bindings: {
      beacon: '<',
      placeOptions: '<',
      productOptions: '<',
      onRemove: '&',
    },
  })

  /* @ngInject */
  function Controller(
    $q,
    $window,
    Beacon,
    glUtils,
    glToast,
    beaconService,
    configService,
    imagesService,
    clipboardService
  ) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.editLogo = editLogo
    ctrl.getLink = getLink
    ctrl.copyLink = copyLink
    ctrl.getQRUrl = getQRUrl
    ctrl.downloadQR = downloadQR
    ctrl.view = view
    ctrl.edit = edit
    ctrl.cancel = cancel
    ctrl.save = save
    ctrl.remove = remove
    ctrl.confirmRemove = confirmRemove
    ctrl.rejectRemove = rejectRemove

    function onInit() {
      ctrl.typeOptions = [Beacon.Types.PLACES, Beacon.Types.PRODUCTS]
      ctrl.measurementSystemOptions = [
        Beacon.MeasurementSystems.METRIC,
        Beacon.MeasurementSystems.IMPERIAL,
      ]
      ctrl.slugPrefix = configService.getUserPortalUrl() + '/beacon/'
    }

    function editLogo() {
      imagesService.prompt({
        title: 'Beacon Logo',
        imageUrl: ctrl.beacon.logoUrl,
        save: function(imageUrl) {
          ctrl.beacon.logoUrl = imageUrl
          return $q.resolve()
        },
      })
    }

    function getLink() {
      return ctrl.slugPrefix + (ctrl.beacon.slug || '')
    }

    function copyLink() {
      clipboardService.copy(getLink())
    }

    function getQRUrl(size) {
      if (!ctrl.beacon.slug) {
        return
      }
      var baseUrl = 'https://api.qrserver.com/v1/create-qr-code/'
      var size = size || 200
      var params = {
        bgcolor: 'fff',
        color: '4c4c4c',
        size: size + 'x' + size,
        data: getLink(),
      }
      var urlParams
      _.each(params, function(value, key) {
        urlParams = urlParams ? urlParams + '&' : '?'
        urlParams += key + '=' + encodeURIComponent(value)
      })
      return baseUrl + urlParams
    }

    function downloadQR() {
      glUtils
        .fetchBlobDataFromUrl(getQRUrl(500))
        .then(function(blob) {
          glUtils.downloadFile(blob, 'image/png', 'qr-code.png')
        })
        .catch(function(error) {
          glToast.show('Error downloading QR code. Please try again.')
        })
    }

    function view() {
      $window.open(getLink(), '_blank')
    }

    function edit() {
      ctrl.beacon.mode = 'edit'
    }

    function remove() {
      ctrl.beacon.mode = 'remove'
    }

    function confirmRemove() {
      ctrl.onRemove()
    }

    function rejectRemove() {
      ctrl.beacon.mode = 'view'
    }

    function cancel() {
      if (ctrl.beacon.isNew()) {
        ctrl.onRemove()
      } else {
        ctrl.beacon.revert()
        ctrl.beacon.mode = 'view'
        ctrl.beacon.error = null
      }
    }

    function save() {
      ctrl.beacon.cleanUp()
      if (!ctrl.beacon.validate()) {
        return
      }
      beaconService.save(ctrl.beacon).then(function() {
        ctrl.beacon.mode = 'view'
      })
    }
  }
})()
