;(function() {
  'use strict'

  surveyTemplatesConfig.$inject = ["$stateProvider"];
  angular.module('app.survey-templates', []).config(surveyTemplatesConfig)

  /* @ngInject */
  function surveyTemplatesConfig($stateProvider) {
    $stateProvider.state('survey-templates', {
      url: '/survey-templates',
      parent: 'portal',
      templateUrl: 'survey-templates.template.html',
      controller: 'SurveyTemplatesController',
      controllerAs: '$ctrl',
      title: 'Survey Templates',
    })
  }
})()
