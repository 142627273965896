;(function() {
  'use strict'

  angular.module('app.core').factory('QueryOptimisedResponse', Factory)

  // This factory maps to the glow_report.query_optimised_responses collection

  /* @ngInject */
  function Factory() {
    function QueryOptimisedResponse() {
      this.applyDefaults()
    }

    QueryOptimisedResponse.prototype.applyDefaults = function() {
      _.defaultsDeep(this, {
        id: null,
        surveyId: null,
        type: null,
        createdAt: null,
      })
    }

    QueryOptimisedResponse.prototype.deserialize = function(data) {
      this.id = data.id
      this.surveyId = data.surveyId
      this.type = data.type
      this.createdAt = moment(data.createdAt)
      this.applyDefaults()
      return this
    }

    QueryOptimisedResponse.prototype.getLabel = function() {
      return this.createdAt.format('MMM D YYYY, h:mm A')
    }

    QueryOptimisedResponse.prototype.getShortId = function() {
      return this.id.substr(0, 6)
    }

    return QueryOptimisedResponse
  }
})()
