;(function() {
  'use strict'

  angular.module('glow.reporting').factory('questionSummary', Service)

  function Service() {
    var configs = [
      {
        type: 'sex',
        imageUrl: '../img/sex-blending.png',
      },
      {
        type: 'agerange',
        imageUrl: '../img/age-blending.png',
      },
      {
        type: 'location',
        imageUrl: '../img/world-map.png',
      },
      {
        type: 'unknown',
        imageUrl: '../img/not-found.png',
      },
    ]

    return {
      getConfig: getConfig,
      getStat: getStat,
    }

    /**
     * Get Config
     * @desc get the summary config for a question type
     * @param {String} type - The question type
     * @returns {Object} config
     */
    function getConfig(type) {
      return (
        _.find(configs, { type: type }) || _.find(configs, { type: 'unknown' })
      )
    }

    /**
     * Get Stat
     * @desc get the stat for a question
     * @param {Object} question - The question
     * @returns {String} stat - The stat for the question
     */
    function getStat(question) {
      var stat
      switch (question.type) {
        case 'sex':
          stat = getSexStat(question)
          break
        case 'agerange':
          stat = getAgeRangeStat(question)
          break
        case 'location':
          stat = getLocationStat(question)
          break
      }
      return stat || '-'
    }

    function getSexStat(question) {
      var top = _.orderBy(question.questionResults.values, 'count', 'desc')[0]
      if (top) {
        var percent = Math.floor(
          (top.count / question.questionResults.answered) * 100
        )
        return percent + '% ' + top.optionValue
      }
    }

    function getAgeRangeStat(question) {
      var top = _.orderBy(question.questionResults.values, 'count', 'desc')[0]
      if (top) {
        var percent = Math.floor(
          (top.count / question.questionResults.answered) * 100
        )
        return percent + '% ' + top.optionValue
      }
    }

    function getLocationStat(question) {
      var locations = question.questionResults.locations
      var highlightType
      var types = [
        locations.Type.COUNTRY,
        locations.Type.STATE,
        locations.Type.CITY,
        locations.Type.SUBURB,
      ]

      // start at the country level and traverse until there are >1 items and use the top from that collection
      _.each(types, function(type) {
        if (locations.getByType(type).length > 1) {
          highlightType = type
          return false
        }
      })

      if (!highlightType) {
        highlightType = locations.Type.CITY
      }

      // find and use the top item from the variable type
      var top = locations.getTop(highlightType)
      if (top) {
        return top.percent + '% ' + top.name
      }
    }
  }
})()
