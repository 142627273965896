;(function() {
  'use strict'

  Controller.$inject = ["glUtils", "cloudinaryService"];
  angular.module('core.kitCard').component('kitCard', {
    controller: Controller,
    templateUrl: 'kit-card.html',
    bindings: {
      kit: '<',
      onClick: '&?',
      isLarge: '<large',
      hideName: '<',
    },
  })

  /* @ngInject */
  function Controller(glUtils, cloudinaryService) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.flip = flip

    function onInit() {
      var templateCount = ctrl.kit.surveyTemplateCount
      ctrl.templateCountText =
        templateCount +
        ' ' +
        glUtils.numberSwitch(templateCount, 'Survey', 'Surveys')

      var imageUrl = cloudinaryService.resize(ctrl.kit.imageUrl, 250)
      ctrl.imageStyle = imageUrl && {
        backgroundImage: 'url(' + imageUrl + ')',
      }
    }

    function flip(event) {
      event.preventDefault()
      ctrl.card.flip()
    }
  }
})()
