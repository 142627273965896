;(function() {
  'use strict'

  Service.$inject = ["$q", "glDialog", "questionTemplatesResource", "QuestionTemplate", "userService"];
  angular.module('core.questionLibrary').factory('questionLibrary', Service)

  /* @ngInject */
  function Service(
    $q,
    glDialog,
    questionTemplatesResource,
    QuestionTemplate,
    userService
  ) {
    return {
      showVariationsDialog: showVariationsDialog,
      search: search,
    }

    function showVariationsDialog(options) {
      return glDialog.show({
        controller: 'QuestionLibraryVariations',
        controllerAs: '$ctrl',
        templateUrl: 'question-library-variations.template.html',
        clickOutsideToClose: false,
        escapeToClose: false,
        locals: options,
      })
    }

    function search(options) {
      return $q(function(resolve, reject) {
        var subsId = userService.getSubscriberId()
        questionTemplatesResource
          .searchAvailable({
            groupId: subsId,
            title: options.query || undefined,
          })
          .success(function(x, templates) {
            // convert to template factory models
            templates = _.map(templates, function(template) {
              return new QuestionTemplate().deserialize(template)
            })

            // filter by state
            if (options.state) {
              _.each(templates, function(template) {
                _.remove(template.questions, function(question) {
                  return question.state !== options.state
                })
              })
            }

            // filter by pathways
            if (options.pathways && options.pathways.length) {
              _.remove(templates, function(template) {
                var matchedPathways = 0

                _.each(options.pathways, function(pathway) {
                  var match = _.find(template.tags, function(tag) {
                    return _.startsWith(tag, pathway)
                  })
                  if (match) {
                    matchedPathways++
                  }
                })

                return matchedPathways !== options.pathways.length
              })
            }

            // HACK: remove templates with a type we don't support variations of
            var validTypes = ['choice', 'scale']
            var rejected = _.remove(templates, function(template) {
              var isInvalid =
                template.questions.length > 1 &&
                !_.includes(validTypes, template.type)
              return isInvalid
            })
            if (rejected.length) {
              console.error(
                'rejected some templates because they have unsupported variations',
                rejected
              )
            }

            // HACK: remove core profiling questions
            _.remove(templates, function(template) {
              var coreProfiling = false
              _.each(template.questions, function(question) {
                if (
                  _.includes(
                    ['sex', 'age_range', 'location'],
                    question.profileKey
                  )
                ) {
                  coreProfiling = true
                  return false
                }
              })
              return coreProfiling
            })

            // remove templates with no variations remaining
            _.remove(templates, function(template) {
              return !template.questions.length
            })

            // remove templates with no tags
            _.remove(templates, function(template) {
              return !template.tags.length
            })

            resolve(templates)
          })
          .error(function(data, status) {
            reject({ data: data, status: status })
          })
      })
    }
  }
})()
