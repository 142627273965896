;(function() {
  'use strict'

  Controller.$inject = ["glEmail", "glAnalytics", "userService"];
  angular.module('app.core').component('publisherNewsletter', {
    controller: Controller,
    templateUrl: 'publisher-newsletter.html',
    bindings: {
      publisher: '<',
    },
  })

  /* @ngInject */
  function Controller(glEmail, glAnalytics, userService) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.valid = valid
    ctrl.join = join

    function onInit() {
      ctrl.user = userService.getUser()
    }

    function join() {
      if (!valid()) {
        return
      }
      glAnalytics.track('publisher', 'join-newsletter', {
        email: ctrl.email,
        publisher: ctrl.publisher.id,
        user: ctrl.user ? ctrl.user.id : null,
      })
      ctrl.isJoined = true
    }

    function valid() {
      return glEmail.isValid(ctrl.email)
    }
  }
})()
