;(function() {
  'use strict'

  Controller.$inject = ["$timeout", "reportD3ChartService", "c3", "renderService"];
  angular
    .module('glow.reporting.charts')
    .component('glReportBarChart', Component())

  /* @ngInject */
  function Component() {
    return {
      controller: Controller,
      templateUrl: 'report-bar-chart.template.html',
      bindings: {
        height: '=',
        question: '<',
        absolute: '<',
        hideLabels: '<',
        hide: '<',
        allowChartClick: '=',
        onChartClick: '&',
        focus: '<',
        sortConfig: '<',
        rotated: '<',
      },
    }
  }

  /* @ngInject */
  function Controller($timeout, reportD3ChartService, c3, renderService) {
    var ctrl = this

    ctrl.chartId = _.uniqueId('glReportBarChart')
    ctrl.$onChanges = onChanges

    function onChanges(changes) {
      if (!ctrl.chart) {
        $timeout(makeChart, 10)
      } else if (changes.absolute || changes.rotated) {
        makeChart()
      } else if (changes.question) {
        refreshChart()
      } else if (changes.sortConfig) {
        makeChart()
      } else if (changes.hide) {
        ctrl.chart.show()
        if (ctrl.hide.length) {
          ctrl.chart.hide(ctrl.hide)
        }
      }
      if (ctrl.chart) {
        ctrl.chart.focus(ctrl.focus)
      }
    }

    // Change the chart data to be percentage based
    function getData() {
      return reportD3ChartService.parseData(ctrl.question, ctrl.absolute)
    }

    function refreshChart() {
      var data = getData()
      var columns = reportD3ChartService.sortColumns(ctrl.sortConfig, data)
      ctrl.chart.load({ unload: null, columns: columns })
    }

    function makeChart() {
      delete ctrl.chart

      var data = getData()
      var columns = reportD3ChartService.sortColumns(ctrl.sortConfig, data)

      var config = reportD3ChartService.buildChartConfig(
        ctrl.chartId,
        'bar',
        columns,
        data.colors,
        ctrl.allowChartClick,
        ctrl.onChartClick,
        ctrl.absolute,
        ctrl.hideLabels,
        ctrl.rotated
      )

      // BUG-FIX: https://github.com/c3js/c3/issues/975
      // bars were not rendering when data changed (eg chart.load())
      // gif: https://media.giphy.com/media/xT39DbJaJ6vxZCFwGs/giphy.gif
      config.transition = {
        duration: 0,
      }
      renderService.queue(function() {
        ctrl.chart = c3.generate(config)
      })
    }
  }
})()
