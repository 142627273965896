;(function() {
  'use strict'

  SurveyModuleController.$inject = ["$scope", "$log", "subscriberService", "surveyService", "modulesService"];
  angular
    .module('app.page-modules')
    .controller('SurveyModuleController', SurveyModuleController)

  /* @ngInject */
  function SurveyModuleController(
    $scope,
    $log,
    subscriberService,
    surveyService,
    modulesService
  ) {
    var vm = this
    var log = $log.create('SurveyModuleController')

    var defaults = {
      type: 'survey',
      pageId: vm.pageId,
      data: {
        title: undefined,
        surveyId: undefined,
        linkText: undefined,
        linkUrl: undefined,
      },
    }

    vm.state = 'loading'
    vm.module = vm.module ? _.cloneDeep(vm.module) : defaults
    vm.schema = modulesService.getSchema('survey')
    vm.displayLink = !!(vm.module.data.linkText || vm.module.data.linkUrl)

    vm.isValid = isValid
    vm.canSave = canSave
    vm.canRemove = canRemove

    vm.save = save
    vm.remove = remove
    vm.close = close

    activate()

    function activate() {
      if (!vm.pageId) {
        vm.error = 'Something went wrong. Please try again.'
        vm.state = 'error'
        return log.error('survey module settings requires pageId')
      }
      getSurveys().then(
        function(surveys) {
          vm.surveys = surveys
          vm.state = 'ready'
        },
        function() {
          vm.state = 'error'
          log.error('couldnt load surveys')
        }
      )
    }

    function getSurveys() {
      var subscriberId = subscriberService.getSubscriber().id

      return surveyService.getBySubscriber(subscriberId)
    }

    function isValid() {
      var hasSurvey = !!vm.module.data.surveyId
      var validLinkData = true

      if (vm.displayLink) {
        validLinkData = vm.module.data.linkText && vm.module.data.linkUrl
      }

      return hasSurvey && validLinkData // readable
    }

    function canSave() {
      var valid = isValid()
      var isntSaving = !vm.isSaving

      return valid && isntSaving // readable
    }

    function canRemove() {
      var hasID = !!vm.module.id

      return hasID // readable
    }

    function save() {
      if (!canSave()) {
        return
      }

      vm.saving = true
      vm.error = null

      // remove link data if unchecked
      if (!vm.displayLink) {
        vm.module.data.linkText = undefined
        vm.module.data.linkUrl = undefined
      }

      modulesService
        .save(vm.module)
        .then(function() {
          $scope.dialog.close({
            modify: true,
            module: vm.module,
          })
        })
        .catch(function(error) {
          vm.error = error
          log.error('error saving survey module')
        })
        .finally(function() {
          vm.saving = false
        })
    }

    function remove() {
      if (!canRemove()) {
        return
      }

      vm.removing = true

      modulesService
        .remove(vm.module.id)
        .then(function() {
          $scope.dialog.close({
            modify: true,
            module: undefined,
          })
        })
        .catch(function(error) {
          vm.error = error
          log.error('error removing survey module')
        })
        .finally(function() {
          vm.removing = false
        })
    }

    function close() {
      $scope.dialog.close()
    }
  }
})()
