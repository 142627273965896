;(function() {
  'use strict'

  angular
    .module('core.filters')
    .filter('interactionsByMood', interactionsByMood)

  /* @ngInject */
  function interactionsByMood() {
    /**
     * @name interactionsByWord
     * @description filter grouped interactions by mood
     * @param {Array} input array of interaction objects
     * @param {Boolean} mood value to filter by (1 to 6)
     * @returns {Array} array of arrays of interactions containing the text in its content or user comments
     */
    return function(input, mood) {
      // return input or empty array if no input or mood is set to false
      if (!input || !mood) {
        return input || []
      }

      var result = []

      _.forEach(input, function(group) {
        var groupResult = []

        _.forEach(group, function(interaction) {
          if (interaction.mood === mood.toString()) {
            groupResult.push(interaction)
          }
        })

        if (groupResult.length > 0) {
          result.push(groupResult)
        }
      })
      return result
    }
  }
})()
