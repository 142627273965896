;(function() {
  'use strict'

  Service.$inject = ["Section"];
  angular.module('glow.reporting').service('reportMediaBtn', Service)

  function Service(Section) {
    var Types = {
      IMAGE: 'IMAGE',
      VIDEO: 'VIDEO',
      YOUTUBE: 'YOUTUBE',
      AUDIO: 'AUDIO',
    }

    var Icons = {
      IMAGE: { icon: 'image', size: 18 },
      VIDEO: { icon: 'video', size: 20 },
      YOUTUBE: { icon: 'youtube', size: 20 },
      AUDIO: { icon: 'volume-high', size: 18 },
    }

    var DefaultLabels = {
      IMAGE: 'Image',
      VIDEO: 'Video',
      YOUTUBE: 'Youtube',
      AUDIO: 'Audio',
    }

    return {
      Types: Types,
      Icons: Icons,
      DefaultLabels: DefaultLabels,
      getTypeBySectionMediaType: getTypeBySectionMediaType,
      getDefaultLabelBySectionMediaType: getDefaultLabelBySectionMediaType,
    }

    function getTypeBySectionMediaType(mediaType) {
      var type = ''
      switch (mediaType) {
        case Section.MediaTypes.VIDEO:
          type = Types.YOUTUBE
          break
        case Section.MediaTypes.VIDEO_UPLOAD:
          type = Types.VIDEO
          break
        case Section.MediaTypes.AUDIO:
          type = Types.AUDIO
          break
        case Section.MediaTypes.IMAGE:
          type = Types.IMAGE
          break
      }
      return type
    }

    function getDefaultLabelBySectionMediaType(mediaType) {
      var defaultLabel = ''
      switch (mediaType) {
        case Section.MediaTypes.VIDEO:
          defaultLabel = DefaultLabels.YOUTUBE
          break
        case Section.MediaTypes.VIDEO_UPLOAD:
          defaultLabel = DefaultLabels.VIDEO
          break
        case Section.MediaTypes.AUDIO:
          defaultLabel = DefaultLabels.AUDIO
          break
        case Section.MediaTypes.IMAGE:
          defaultLabel = DefaultLabels.IMAGE
          break
      }
      return defaultLabel
    }
  }
})()
