;(function() {
  'use strict'

  c3.$inject = ["$window"];
  angular.module('core.services').factory('c3', c3)

  /* @ngInject */
  function c3($window) {
    return $window.c3
  }
})()
