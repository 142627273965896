;(function() {
  'use strict'

  Controller.$inject = ["subscriberService"];
  angular.module('app.account').component('accountBilling', {
    controller: Controller,
    templateUrl: 'account-billing.html',
  })

  /* @ngInject */
  function Controller(subscriberService) {
    var ctrl = this

    ctrl.$onInit = onInit

    function onInit() {
      ctrl.subscriber = subscriberService.getSubscriber()
    }
  }
})()
