;(function() {
  'use strict'

  config.$inject = ["$stateProvider"];
  angular.module('app.core').config(config)

  /* @ngInject */
  function config($stateProvider) {
    $stateProvider.state('my-publications', {
      url: '/my-publications',
      parent: 'portal',
      templateUrl: 'my-publications.html',
      controller: 'MyPublicationsController',
      controllerAs: '$ctrl',
      title: 'My Reports',
      public: true,
    })

    $stateProvider.state('insights-my-publications-deprecated', {
      url: '/insights/my-publications',
      redirectTo: 'my-publications',
    })
  }
})()
