;(function() {
  'use strict'

  Controller.$inject = ["channelService", "userService", "subscriberService"];
  angular.module('app.core').component('channelReward', {
    templateUrl: 'channel-reward.html',
    controller: Controller,
    bindings: {
      channel: '<',
      survey: '<',
      onSave: '&',
    },
  })

  /* @ngInject */
  function Controller(channelService, userService, subscriberService) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.$onChanges = onChanges
    ctrl.toggleReward = toggleReward
    ctrl.updateSpendTarget = updateSpendTarget
    ctrl.showInfo = showInfo

    function onInit() {
      ctrl.isSSR = userService.isSSR()
    }

    function onChanges() {
      if (!ctrl.currency) {
        ctrl.subscriber = subscriberService.getSubscriber()
        ctrl.plan = ctrl.subscriber.plan
        ctrl.currency = ctrl.subscriber.billing.currency || 'AUD'
      }
      var loi = ctrl.survey.lengthOfInterview
      ctrl.costPerResponse = _.find(ctrl.plan.pricing.responses, {
        type: loi.type.type,
      }).price.byCurrency(ctrl.currency)
      ctrl.costPerExit = ctrl.plan.pricing.exits.byCurrency(ctrl.currency)
      if (ctrl.channel.reward) {
        var costPerResponseAmount = ctrl.costPerResponse.amount / 100
        var currentSpendAmount = ctrl.channel.reward.currentSpend.amount / 100
        ctrl.spendTarget = ctrl.channel.reward.spendTarget.amount / 100
        // Ensure there is enough for at least one response
        ctrl.minSpendTarget = Math.max(
          costPerResponseAmount,
          currentSpendAmount + costPerResponseAmount
        )
      }
      ctrl.subTitle = makeSubTitle()
    }

    function toggleReward() {
      if (ctrl.channel.reward) {
        ctrl.channel.removeReward()
      } else {
        ctrl.channel.addReward()
        ctrl.spendTarget = ctrl.channel.reward.spendTarget.amount / 100
        ctrl.minSpendTarget = ctrl.costPerResponse.amount / 100
      }
    }

    function updateSpendTarget() {
      if (!ctrl.spendTarget) {
        ctrl.spendTarget = 0
      }
      ctrl.spendTarget = Math.max(ctrl.spendTarget, ctrl.minSpendTarget)
      ctrl.channel.reward.spendTarget.setAmount(ctrl.spendTarget * 100)
    }

    function makeSubTitle() {
      var costPerResponseLabel = ctrl.costPerResponse.format({ currency: true })
      var costPerExitLabel = ctrl.costPerExit.format({ currency: true })
      return [
        'Boost your response rate at ' + costPerResponseLabel + ' per response',
        ctrl.survey.hasExitLogic()
          ? ' and ' + costPerExitLabel + ' per survey exit'
          : '',
        '.',
      ].join('')
    }

    function showInfo() {
      channelService.showRewardInfo({ survey: ctrl.survey })
    }
  }
})()
