;(function() {
  'use strict'

  Service.$inject = ["Filter", "FilterSetGroup"];
  angular.module('glow.reporting').factory('filterSetMongoService', Service)

  /* @ngInject */
  function Service(Filter, FilterSetGroup) {
    var Types = Filter.Types
    var DateTypes = Filter.DateTypes
    var QuestionCategories = Filter.QuestionCategories

    return {
      parse: parse,
    }

    function parse(filterSets, operator) {
      if (!_.isArray(filterSets) || !filterSets.length) {
        return
      }

      // NOTE: DEPRECATED! Filterset model has changed and the parser is within the model.
      // NOTE: Keeping these codes around for future references
      // var queries = []

      // _.each(filterSets, function(filterSet) {
      //   if (!filterSet) return // skip
      //   var filters = filterSet.filters

      //   // Add all the filterSet queries together
      //   var setQueries = []
      //   setQueries = _.concat(setQueries, buildDateRangeFilter(filters))
      //   setQueries = _.concat(setQueries, buildDimensionsFilters(filters))
      //   setQueries = _.concat(setQueries, buildChannelFilters(filters))
      //   setQueries = _.concat(setQueries, buildPagesFilters(filters))
      //   setQueries = _.concat(setQueries, buildQuestionFilters(filters))
      //   setQueries = _.concat(setQueries, buildAnsweredFilters(filters))
      //   setQueries = _.concat(setQueries, buildSegmentFilters(filters))

      //   if (setQueries.length < 1) {
      //     return // skip
      //   }

      //   queries.push({
      //     $and: setQueries,
      //   })
      // })
      var queries = filterSets.map(function(filterSet) {
        return filterSet.toMongo()
      })

      var result

      if (!queries.length) {
        result = null
      }
      if (queries.length === 1) {
        result = queries[0]
      }
      if (queries.length > 1) {
        if (operator === FilterSetGroup.Operators.OR) {
          result = { $or: queries }
        } else {
          result = { $and: queries }
        }
      }

      console.log('mongo', result)
      return result
    }

    function buildDateRangeFilter(filters) {
      var filter = _.find(filters, { type: Types.DATE })
      if (!filter || (filter && filter.data.dateType === DateTypes.ALL_TIME)) {
        return []
      }
      return [
        {
          responseCreatedAt: {
            $gt: filter.data.start.toISOString(),
            $lt: filter.data.end.toISOString(),
          },
        },
      ]
    }

    function buildDimensionsFilters(allFilters) {
      var filters = []
      var dimensionsFilters = _.filter(allFilters, { type: Types.DIMENSION })

      _.each(dimensionsFilters, function(dimensionsFilter) {
        var element =
          'reportingDimensions.customReportingDimensions.' +
          dimensionsFilter.data.category
        _.each(dimensionsFilter.data.values, function(choice) {
          var filter = {}
          filter[element] = choice.value
          filters.push(filter)
        })
      })

      if (filters.length > 1) {
        return { $or: filters }
      } else if (filters.length === 1) {
        return filters[0]
      }
      return []
    }

    function buildChannelFilters(allFilters) {
      var filters = []
      var channelFilters = _.filter(allFilters, { type: Types.CHANNEL })

      _.each(channelFilters, function(channelFilter) {
        var element = 'reportingDimensions.systemReportingDimensions.channel'
        _.each(channelFilter.data.values, function(channelId) {
          var filter = {}
          filter[element] = channelId
          filters.push(filter)
        })
      })

      if (filters.length > 1) {
        return { $or: filters }
      } else if (filters.length === 1) {
        return filters[0]
      }
      return []
    }

    function buildPagesFilters(allFilters) {
      var filters = []
      var pageFilters = _.filter(allFilters, { type: Types.PAGE })

      _.each(pageFilters, function(pageFilter) {
        var element = 'reportingDimensions.systemReportingDimensions.page'
        _.each(pageFilter.data.values, function(choice) {
          var filter = {}
          filter[element] = choice.id
          filters.push(filter)
        })
      })

      if (filters.length > 1) {
        return { $or: filters }
      } else if (filters.length === 1) {
        return filters[0]
      }

      return []
    }

    function buildQuestionFilters(filters) {
      var questionFilters = _.filter(filters, function(filter) {
        return filter.type === Types.QUESTION || filter.type === Types.PROFILE
      })

      var questionQueries = []
      _.each(questionFilters, function(questionFilter) {
        var filter

        switch (questionFilter.data.category) {
          case QuestionCategories.CHOICE:
          case QuestionCategories.MOOD:
            filter = buildChoiceAnswerFilter(
              questionFilter.data.questionId,
              questionFilter.data.values
            )
            break
          case QuestionCategories.NUMERIC:
            filter = buildNumericFilter(
              questionFilter.data.questionId,
              questionFilter.data.values
            )
            break
          case QuestionCategories.TEXT:
            filter = buildTextAnswerFilter(
              questionFilter.data.questionId,
              questionFilter.data.values
            )
            break
          case QuestionCategories.NPS:
            filter = buildNpsAnswerFilter(
              questionFilter.data.questionId,
              questionFilter.data.values
            )
            break
          case QuestionCategories.LOCATION:
            filter = buildLocationFilter(
              questionFilter.data.questionId,
              questionFilter.data.values
            )
            break
          case QuestionCategories.PLACES_NEAR_ME:
            filter = buildPlacesFilter(
              questionFilter.data.questionId,
              questionFilter.data.values
            )
            break
          case QuestionCategories.RANK:
            filter = buildRankFilter(
              questionFilter.data.questionId,
              questionFilter.data.aspect,
              questionFilter.data.values
            )
            break
          case QuestionCategories.MATRIX:
            filter = buildMatrixFilter(
              questionFilter.data.questionId,
              questionFilter.data.aspect,
              questionFilter.data.values
            )
            break
          case QuestionCategories.CONSTANT_SUM:
            filter = buildConstantSumFilter(
              questionFilter.data.questionId,
              questionFilter.data.aspect,
              questionFilter.data.values
            )
            break
          case QuestionCategories.SCORE:
            filter = buildScoreFilter(
              questionFilter.data.questionId,
              questionFilter.data.aspect,
              questionFilter.data.values
            )
            break
        }

        if (filter) {
          questionQueries.push(filter)
        }
      })

      return questionQueries

      function buildChoiceAnswerFilter(questionId, values) {
        var choiceFilters = []
        _.each(values, function(choice) {
          var choiceFilter
          if (choice.ageRange) {
            choiceFilter = {
              answers: {
                $elemMatch: {
                  questionId: questionId,
                  numericAnswer: {
                    $gte: choice.ageRange.lower,
                    $lte: choice.ageRange.upper,
                  },
                },
              },
            }
          } else {
            choiceFilter = {
              answers: {
                $elemMatch: {
                  'choiceAnswers.id': choice.id,
                  questionId: questionId,
                },
              },
            }
          }

          choiceFilters.push(choiceFilter)
        })

        if (choiceFilters.length > 1) {
          return { $or: choiceFilters }
        } else {
          return choiceFilters[0]
        }
      }

      function buildNumericFilter(questionId, values) {
        var filters = _.map(values, function(value) {
          return {
            answers: {
              $elemMatch: {
                numericAnswer: value.number,
                questionId: questionId,
              },
            },
          }
        })
        return filters.length > 1 ? { $or: filters } : filters[0]
      }

      function buildTextAnswerFilter(questionId, values) {
        var valueFilters = []
        _.each(values, function(value) {
          var textFilter = {
            answers: {
              $elemMatch: {
                textAnswer: { $regex: value.text, $options: 'i' },
                questionId: questionId,
              },
            },
          }
          valueFilters.push(textFilter)
        })

        if (valueFilters.length > 1) {
          return { $or: valueFilters }
        } else {
          return valueFilters[0]
        }
      }

      function buildLocationFilter(questionId, values) {
        var rules = []

        _.each(values, function(tag) {
          // IMPORTANT: regex being sent to the BE in a query param needs triple-escaping
          // so instead of '\|' it needs to be '\\\|'
          var regex = '^([^\\|]+\\|){' + tag.slot + '}' + tag.value

          rules.push({
            answers: {
              $elemMatch: {
                questionId: questionId,
                textAnswer: { $regex: regex, $options: 'i' },
              },
            },
          })
        })

        return rules.length > 1 ? { $or: rules } : rules[0]
      }

      function buildNpsAnswerFilter(questionId, values) {
        var npsFilters = []
        _.each(values, function(choice) {
          npsFilters.push({
            answers: {
              $elemMatch: {
                npsGroup: choice.id,
                questionId: questionId,
              },
            },
          })
        })

        if (npsFilters.length > 1) {
          return { $or: npsFilters }
        } else {
          return npsFilters[0]
        }
      }

      function buildPlacesFilter(questionId, values) {
        var placesFilters = []
        _.each(values, function(place) {
          var placesFilter = {
            answers: {
              $elemMatch: {
                'placesAnswers.sourceId': place.sourceId,
                questionId: questionId,
              },
            },
          }
          placesFilters.push(placesFilter)
        })

        if (placesFilters.length > 1) {
          return { $or: placesFilters }
        } else {
          return placesFilters[0]
        }
      }

      function buildRankFilter(questionId, rank, values) {
        var rankFilters = []
        _.each(values, function(choice) {
          var rankFilter

          if (rank) {
            rankFilter = {
              $and: [
                {
                  'answers.questionId': questionId,
                  'answers.choiceAnswers': {
                    $elemMatch: {
                      id: choice.id,
                      rank: rank,
                    },
                  },
                },
              ],
            }
          } else {
            rankFilter = {
              $and: [
                {
                  'answers.questionId': questionId,
                  'answers.choiceAnswers': {
                    $elemMatch: {
                      id: choice.id,
                    },
                  },
                },
              ],
            }
          }

          rankFilters.push(rankFilter)
        })

        if (rankFilters.length > 1) {
          return { $or: rankFilters }
        } else {
          return rankFilters[0]
        }
      }

      function buildMatrixFilter(questionId, statementId, values) {
        var filters = []
        _.each(values, function(choice) {
          filters.push({
            'answers.questionId': questionId,
            'answers.statementAnswers': {
              $elemMatch: {
                id: statementId,
                optionId: choice.id,
              },
            },
          })
        })
        return filters.length > 1 ? { $or: filters } : filters[0]
      }

      function buildConstantSumFilter(questionId, statementId, values) {
        var filters = []
        _.each(values, function(choice) {
          if (choice.usesStatement) {
            filters.push({
              'answers.questionId': questionId,
              'answers.statementAnswers': {
                $elemMatch: {
                  id: statementId,
                  optionId: choice.id,
                  number: choice.numbers ? { $in: choice.numbers } : undefined,
                },
              },
            })
          } else {
            filters.push({
              'answers.questionId': questionId,
              'answers.choiceAnswers': {
                $elemMatch: {
                  id: choice.id,
                  number: choice.numbers ? { $in: choice.numbers } : undefined,
                },
              },
            })
          }
        })
        return filters.length > 1 ? { $or: filters } : filters[0]
      }

      function buildScoreFilter(questionId, statementId, values) {
        // this function is very similar to buildConstantSumFilter() function above
        // with exception of not having `number: choice.numbers`
        var filters = []
        _.each(values, function(choice) {
          if (choice.usesStatement) {
            filters.push({
              'answers.questionId': questionId,
              'answers.statementAnswers': {
                $elemMatch: {
                  id: statementId,
                  optionId: choice.id,
                },
              },
            })
          } else {
            filters.push({
              'answers.questionId': questionId,
              'answers.choiceAnswers': {
                $elemMatch: {
                  id: choice.id,
                },
              },
            })
          }
        })
        return filters.length > 1 ? { $or: filters } : filters[0]
      }
    }

    function buildAnsweredFilters(allFilters) {
      var filters = []
      var answeredFilters = _.filter(allFilters, { type: Types.ANSWERED })
      _.each(answeredFilters, function(filter) {
        filters.push({
          answers: {
            $elemMatch: {
              questionId: filter.data.questionId,
              answered: true,
            },
          },
        })
      })
      return filters
    }

    function buildSegmentFilters(allFilters) {
      var filters = []
      var segmentFilters = _.filter(allFilters, { type: Types.SEGMENT })

      _.each(segmentFilters, function(segmentFilter) {
        _.each(segmentFilter.data.values, function(value) {
          filters.push({ segments: value })
        })
      })

      if (filters.length > 1) {
        return { $or: filters }
      } else if (filters.length === 1) {
        return filters[0]
      }
      return []
    }
  }
})()
