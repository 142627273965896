;(function() {
  'use strict'

  Controller.$inject = ["$rootScope", "$scope", "reportTable", "responseTypeService"];
  angular
    .module('glow.reporting.charts')
    .component('glReportTableOther', Component())

  /* @ngInject */
  function Component() {
    return {
      controller: Controller,
      templateUrl: 'report-table-other.template.html',
      bindings: {
        survey: '<',
        question: '=',
        filterSetGroup: '<',
        loopKeyId: '<',
        maxHeight: '<',
        height: '=',
        allowRowClick: '=',
        onRowFocus: '&',
        onRowClick: '&?',
      },
    }
  }

  /* @ngInject */
  function Controller($rootScope, $scope, reportTable, responseTypeService) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.onStatementChange = onStatementChange

    function onInit() {
      $scope.$on('survey-reporting:load-end', load)
      load()

      if (ctrl.question.isMatrix) {
        prepareStatementSelect()
      }
    }

    function load() {
      reportTable
        .getFromOtherAnswers(
          ctrl.survey,
          ctrl.question,
          ctrl.filterSetGroup.getSelected(),
          ctrl.filterSetGroup.getOperator(),
          responseTypeService.getSelected(),
          ctrl.loopKeyId
        )
        .then(function(data) {
          ctrl.data = data
          $rootScope.$broadcast('gl-masonry:update-hint')
        })
    }

    function prepareStatementSelect() {
      ctrl.statementOptions = _.map(ctrl.question.statements, function(
        statement
      ) {
        return {
          label: statement.statement,
          value: statement.statement,
        }
      })
      ctrl.statementOptions.unshift({
        label: 'All Statements',
        value: null,
      })
      ctrl.statementValue = null
    }

    function onStatementChange(statementValue) {
      _.each(ctrl.data.rows, function(row) {
        row.isHidden =
          !statementValue || row.statement.value === statementValue
            ? false
            : true
      })
    }
  }
})()
