;(function() {
  'use strict'

  angular.module('app.core').component('responseFilterDialog', {
    controller: Controller,
    templateUrl: 'response-filter-dialog.html',
    bindings: {
      survey: '<',
      channels: '<',
      dimensions: '<',
      filter: '<',
      nameable: '<',
      onApply: '&',
      onCancel: '&',
    },
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.apply = apply
    ctrl.remove = remove

    function onInit() {
      // ...
    }

    function apply() {
      ctrl.onApply({ $filter: ctrl.filter })
    }

    function remove() {
      ctrl.onApply({ $filter: null })
    }
  }
})()
