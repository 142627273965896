;(function() {
  'use strict'

  angular.module('app.core').component('moveDialog', {
    controller: Controller,
    templateUrl: 'move-dialog.html',
    bindings: {
      direction: '@',
      survey: '<',
      selectedViews: '<',
      onDone: '&',
      onCancel: '&',
    },
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit

    function onInit() {
      switch (ctrl.direction) {
        case 'up':
          ctrl.directionTitle = 'above'
          var firstSelectedViewIndex = _.head(ctrl.selectedViews).getIndex()
          ctrl.viewOptions = ctrl.survey.views
            .slice(0, firstSelectedViewIndex)
            .map(makeViewOption.bind(null, firstSelectedViewIndex))
          break
        case 'down':
          ctrl.directionTitle = 'below'
          var lastSelectedViewIndex = _.last(ctrl.selectedViews).getIndex()
          ctrl.viewOptions = ctrl.survey.views
            .slice(lastSelectedViewIndex + 1, ctrl.survey.views.length)
            .map(makeViewOption.bind(null, lastSelectedViewIndex))
          break
      }
      ctrl.amount = null
    }

    function makeViewOption(selectedViewIndex, view) {
      var amount = view.getIndex() - selectedViewIndex
      return {
        label: view.value.getTitleLabel({ number: true }),
        value: amount,
      }
    }
  }
})()
