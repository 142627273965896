;(function() {
  'use strict'

  angular.module('app.core').component('productManagerDialog', {
    templateUrl: 'product-manager-dialog.html',
    bindings: {
      channel: '<',
      onDone: '&',
      onCancel: '&',
    },
  })
})()
