;(function() {
  'use strict'

  Controller.$inject = ["$q", "glToast", "filterSetService", "glUtils"];
  angular.module('glow.reporting').component('filterSetManager', {
    controller: Controller,
    templateUrl: 'filter-set-manager.html',
    bindings: {
      group: '<',
      report: '<',
      onChange: '&',
    },
  })

  /* @ngInject */
  function Controller($q, glToast, filterSetService, glUtils) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.showSetDialog = showSetDialog
    ctrl.selectSet = selectSet
    ctrl.toggleSet = toggleSet
    ctrl.duplicateSet = duplicateSet
    ctrl.removeSet = removeSet
    ctrl.save = save
    ctrl.move = move

    function onInit() {
      // ...
    }

    function showSetDialog(filterSet) {
      if (!filterSet) {
        filterSet = ctrl.group.add()
        filterSet.order = glUtils.getItemOrder(
          filterSet,
          ctrl.group.getEditable()
        )
      } else {
        filterSet.pin()
      }
      filterSetService
        .showFilterSetDialog(filterSet, ctrl.report)
        .then(function() {
          // if the current selected filter is default ("All Responses")
          // then we select the edited filterset
          if (ctrl.group.default.isSelected) {
            selectSet(filterSet)
          } else {
            ctrl.onChange({ $skipUpdateURLParams: true })
          }
        })
        .catch(function() {
          if (filterSet.isNew()) {
            ctrl.group.remove(filterSet)
          } else {
            filterSet.discard()
          }
        })
    }

    function selectSet(filterSet) {
      ctrl.group.selectSet(filterSet)
      ctrl.onChange()
    }

    function toggleSet(filterSet) {
      ctrl.group.toggleSet(filterSet)
      ctrl.onChange()
    }

    function duplicateSet(filterSet) {
      var dup = filterSet.duplicate()
      ctrl.group.add(dup, { after: filterSet })
      dup.order = glUtils.getItemOrder(dup, ctrl.group.getEditable())
      save(dup)
    }

    function removeSet(filterSet) {
      var info = ctrl.group.remove(filterSet)
      if (filterSet.id) {
        filterSetService.remove(filterSet).catch(function() {
          ctrl.group.add(filterSet, { at: info.idx })
          glToast.show('Error deleting Filter')
        })
      }
      ctrl.group.refreshSelected()
      ctrl.onChange()
      glToast.action(
        'Filter removed.',
        'Undo',
        function() {
          filterSet.id = null
          ctrl.group.add(filterSet, { at: info.idx })
          save(filterSet, true)
        },
        { timeout: 5000 }
      )
    }

    function save(filterSet, updateURLParams) {
      filterSetService.save(filterSet).then(function() {
        ctrl.onChange({ $skipUpdateURLParams: !updateURLParams })
      })
    }

    function move(filterSet, amount) {
      var fromIndex = _.indexOf(ctrl.group.filterSets, filterSet)
      var toIndex = fromIndex + amount
      ctrl.group.filterSets.splice(
        toIndex,
        0,
        ctrl.group.filterSets.splice(fromIndex, 1)[0]
      )

      var promises = []
      var order = glUtils.getItemOrder(filterSet, ctrl.group.getEditable())
      if (order === null) {
        // if the order is null, that means it can't get proper order number
        // so we need to reset the whole filterSets order number and save them all
        var filterSets = ctrl.group.getEditable()
        glUtils.resetItemsOrder(filterSets)
        _.each(filterSets, function(item) {
          if (item.isNew()) return // skip new filterSets
          promises.push(filterSetService.save(item))
        })
      } else {
        filterSet.order = order
        promises.push(filterSetService.save(filterSet))
      }

      $q.all(promises).catch(function(err) {
        console.error(err)
        glToast.show('Error moving filterSet')
      })
    }
  }
})()
