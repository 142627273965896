;(function() {
  'use strict'

  Factory.$inject = ["Asset", "glUtils"];
  angular.module('app.core').factory('Resource', Factory)

  /* @ngInject */
  function Factory(Asset, glUtils) {
    var Types = {
      FILE: 'FILE',
      INTERACTIVE_ANALYSIS: 'INTERACTIVE_ANALYSIS',
      LINK: 'LINK',
    }

    var TypeInfo = [
      {
        type: Types.FILE,
        label: 'File',
        icon: 'file-download',
        desc: 'Upload a file.',
        message:
          'Upload a file to share to people who acquire this report. ' +
          'Optionally you can upload a preview file for people to view before ' +
          'acquiring the report.',
      },
      {
        type: Types.INTERACTIVE_ANALYSIS,
        label: 'Interactive Analysis',
        icon: 'glow',
        desc: 'Select from the Glow Studio.',
        message:
          'Choose a survey from the Glow Studio to attach to this report. ' +
          'If you select "Allow preview" then anyone will be able to preview your ' +
          'survey with a maximum sample of 50 responses.',
      },
      {
        type: Types.LINK,
        label: 'Link',
        icon: 'link',
        desc: 'Link to an external page.',
        message:
          'Add a link to an external page that can be accessed directly from this report. ' +
          'Optionally you can add a preview link for people to view before ' +
          'acquiring the report.',
      },
    ]

    var Categories = {
      ARTICLE: 'ARTICLE',
      DATA_PACK: 'DATA_PACK',
      FULL_REPORT: 'FULL_REPORT',
      HEADLINE_REPORT: 'HEADLINE_REPORT',
      HOW_TO_GUIDE: 'HOW_TO_GUIDE',
      INTERACTIVE_ANALYSIS: 'INTERACTIVE_ANALYSIS',
    }

    var CategoryInfo = [
      {
        type: Categories.ARTICLE,
        label: 'Article',
        imageUrl: '/img/resource-article.png',
      },
      {
        type: Categories.DATA_PACK,
        label: 'Data Pack',
        imageUrl: '/img/resource-data-pack.png',
      },
      {
        type: Categories.FULL_REPORT,
        label: 'Full Report',
        imageUrl: '/img/resource-full-report.png',
      },
      {
        type: Categories.HEADLINE_REPORT,
        label: 'Headline Report',
        imageUrl: '/img/resource-headline-report.png',
      },
      {
        type: Categories.HOW_TO_GUIDE,
        label: 'How-to Guide',
        imageUrl: '/img/resource-how-to-guide.png',
      },
      {
        type: Categories.INTERACTIVE_ANALYSIS,
        label: 'Interactive Analysis',
        imageUrl: '/img/resource-interactive-analysis.png',
      },
    ]

    var Defaults = {
      id: null,
      name: null,
      type: null,
      category: null,
      assetId: null, // FILE
      previewAssetId: null, // FILE
      surveyId: null, // INTERACTIVE_ANALYSIS
      surveyPreviewToken: null, // INTERACTIVE_ANALYSIS
    }

    function Resource() {
      _.defaultsDeep(this, Defaults)
    }

    Resource.prototype.Types = Resource.Types = Types
    Resource.prototype.Categories = Resource.Categories = Categories
    Resource.prototype.getCategoryInfo = Resource.getCategoryInfo = getCategoryInfo
    Resource.prototype.getTypeInfo = Resource.getTypeInfo = getTypeInfo

    Resource.prototype.deserialize = function(data) {
      this.id = data.id
      this.name = data.name
      this.type = this.getTypeInfo(data.type)
      this.category = this.getCategoryInfo(data.category)
      this.asset = data.asset ? new Asset().deserialize(data.asset) : null
      this.assetId = data.assetId
      this.previewAsset = data.previewAsset
        ? new Asset().deserialize(data.previewAsset)
        : null
      this.previewAssetId = data.previewAssetId
      this.surveyId = data.surveyId
      this.surveyPreviewToken = data.previewAnalysisToken
      this.url = data.url
      this.previewUrl = data.previewUrl
      _.defaultsDeep(this, Defaults)
      return this
    }

    Resource.prototype.serialize = function(keepAssets) {
      var data = {}
      data.id = this.id
      data.name = this.name
      data.type = this.type.type
      data.category = this.category.type
      data.asset = this.asset ? this.asset.serialize() : null
      data.assetId = this.asset ? this.asset.id : this.assetId
      data.previewAsset = this.previewAsset
        ? this.previewAsset.serialize()
        : null
      data.previewAssetId = this.previewAsset
        ? this.previewAsset.id
        : this.previewAssetId
      data.surveyId = this.surveyId
      data.previewAnalysisToken = this.surveyPreviewToken
      data.url = this.url
      data.previewUrl = this.previewUrl
      if (keepAssets !== true) {
        data.asset = null
        data.previewAsset = null
      }
      return data
    }

    Resource.prototype.validate = function() {
      var errors = []
      if (!this.type) {
        errors.push('Type is required.')
      }
      if (!this.category) {
        errors.push('Category is required.')
      }
      if (!this.name) {
        errors.push('Name is required.')
      }
      if (this.type && this.type.type === Types.FILE) {
        if (!this.asset) {
          errors.push('A file must be selected.')
        }
      }
      if (this.type && this.type.type === Types.INTERACTIVE_ANALYSIS) {
        if (!this.surveyId) {
          errors.push('A survey must be selected.')
        }
        if (this.category.type !== Categories.INTERACTIVE_ANALYSIS) {
          errors.push('Invalid category for this resource type')
        }
      }
      if (this.type && this.type.type === Types.LINK) {
        // url is mandatory
        if (!this.url || !glUtils.validateUrl(this.url)) {
          errors.push('A valid URL must be entered.')
        }
        // preview url is optional
        if (this.previewUrl && !glUtils.validateUrl(this.previewUrl)) {
          errors.push('A valid Preview URL must be entered.')
        }
      }
      this.errors = errors
      return errors.length === 0
    }

    Resource.prototype.clone = function() {
      return new Resource().deserialize(this.serialize(true))
    }

    Resource.prototype.become = function(resource) {
      this.deserialize(resource.serialize(true))
    }

    function getCollectionInfo(Collection, typeOrTypes) {
      var type = _.isString(typeOrTypes) ? typeOrTypes : null
      var types = _.isArray(typeOrTypes) ? typeOrTypes : null

      // if type specified - return just that info
      if (type) {
        return _.cloneDeep(_.find(Collection, { type: type }))
      }

      // if types array, return just those types
      if (types) {
        var info = _.filter(Collection, function(info) {
          return _.includes(types, info.type)
        })
        return _.cloneDeep(info)
      }

      // otherwise return all info
      return _.cloneDeep(Collection)
    }

    function getCategoryInfo(typeOrTypes) {
      return getCollectionInfo(CategoryInfo, typeOrTypes)
    }

    function getTypeInfo(typeOrTypes) {
      return getCollectionInfo(TypeInfo, typeOrTypes)
    }

    return Resource
  }
})()
