;(function() {
  'use strict'

  angular
    .module('glow.reporting')
    .component('glQuestionScoreFilter', Component())

  function Component() {
    return {
      controller: Controller,
      templateUrl: 'score-filter.template.html',
      bindings: {
        question: '<',
        filter: '=',
      },
    }
  }

  /* @ngInject */
  function Controller() {
    var ctrl = this

    // These codes are copied from constant-sum-filter.component.js
    // excluding the codes that handles numeric input for constant sum

    ctrl.$onInit = onInit
    ctrl.$onChanges = onChanges
    ctrl.toggle = toggle

    function onInit() {
      if (ctrl.filter.newAspect && ctrl.question.isMatrix) {
        ctrl.filter.data.aspect = ctrl.filter.newAspect
        ctrl.filter.newAspect = undefined
      }

      if (ctrl.filter.newValue) {
        var choice = _.find(ctrl.question.choices, {
          option: ctrl.filter.newValue,
        })
        // Only add the choice if not already selected/added
        if (choice && !_.find(ctrl.filter.data.values, { id: choice.id })) {
          ctrl.filter.data.values.push({
            id: choice.id,
            usesStatement: ctrl.question.isMatrix,
          })
        }
        ctrl.filter.newValue = undefined
      }

      _.each(ctrl.filter.data.values, function(choice) {
        var questionChoice = _.find(ctrl.question.choices, { id: choice.id })
        if (questionChoice) {
          questionChoice.isSelected = true
        }
      })
    }

    function onChanges() {
      ctrl.question = _.cloneDeep(ctrl.question)
      ctrl.statements = null
      if (ctrl.question.isMatrix) {
        ctrl.statements = [{ label: 'Any Statement' }]
        _.each(ctrl.question.statements, function(statement) {
          ctrl.statements.push({
            label: statement.statement,
            value: statement.id,
          })
        })
      }
    }

    function toggle(choice) {
      if (choice.isSelected) {
        ctrl.filter.data.values.push({
          id: choice.id,
          usesStatement: ctrl.question.isMatrix,
        })
      } else {
        _.remove(ctrl.filter.data.values, { id: choice.id })
      }
    }
  }
})()
