;(function() {
  'use strict'

  Controller.$inject = ["glUtils"];
  angular.module('core.surveyTemplateCard').component('surveyTemplateCard', {
    controller: Controller,
    templateUrl: 'survey-template-card.html',
    bindings: {
      template: '<',
      onClick: '&',
      onFlip: '&',
      onPreview: '&',
      isLarge: '<large',
    },
  })

  /* @ngInject */
  function Controller(glUtils) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.click = click
    ctrl.flip = flip

    function onInit() {
      var questionCount = ctrl.template.getQuestionCount()
      ctrl.questionCountText =
        questionCount +
        ' ' +
        glUtils.numberSwitch(questionCount, 'Question', 'Questions')
      ctrl.imageStyle = ctrl.template.imageUrl && {
        backgroundImage: 'url(' + ctrl.template.imageUrl + ')',
      }
    }

    function click(event) {
      if (!event.defaultPrevented) {
        ctrl.onClick({ $event: event })
      }
    }

    function flip(event) {
      event.preventDefault()
      ctrl.onFlip()
      ctrl.card.flip()
    }
  }
})()
