;(function() {
  'use strict'

  Controller.$inject = ["$timeout", "configService", "linkService", "clipboardService"];
  angular.module('app.core').component('outlink', {
    controller: Controller,
    templateUrl: 'outlink.html',
    bindings: {
      survey: '<',
    },
  })

  /* @ngInject */
  function Controller($timeout, configService, linkService, clipboardService) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.copy = copy

    function onInit() {
      var outlink = ctrl.survey.outlink
      ctrl.outlink = outlink
      ctrl.copied = null
      var surveyId = ctrl.survey.id || 'unknown'
      if (!outlink.submitLink) {
        var longUrl = configService.getUserPortalUrl('/survey/' + surveyId + '?outlink=submit') // prettier-ignore
        linkService.generate(longUrl).then(link => {
          outlink.submitLink = link.shortUrl
        })
      }
      if (!outlink.exitLink) {
        var longUrl = configService.getUserPortalUrl('/survey/' + surveyId + '?outlink=exit') // prettier-ignore
        linkService.generate(longUrl).then(link => {
          outlink.exitLink = link.shortUrl
        })
      }
      if (!outlink.quotaLink) {
        var longUrl = configService.getUserPortalUrl('/survey/' + surveyId + '?outlink=quota') // prettier-ignore
        linkService.generate(longUrl).then(link => {
          outlink.quotaLink = link.shortUrl
        })
      }
    }

    function copy(key) {
      var value = ctrl.outlink[key]
      clipboardService.copy(value)
      ctrl.copied = key
      $timeout(function() {
        if (ctrl.copied === key) {
          ctrl.copied = null
        }
      }, 3000)
    }
  }
})()
