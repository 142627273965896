;(function() {
  'use strict'

  angular
    .module('core.actionableListItem')
    .component('actionableListItem', Component())

  /* @ngInject */
  function Component() {
    return {
      controller: Controller,
      templateUrl: 'actionable-list-item.html',
      bindings: {
        icon: '@',
        title: '@',
        subTitle: '@',
        copy: '&?',
        edit: '&?',
        delete: '&?',
        hideCopy: '<',
        hideEdit: '<',
        hideDelete: '<',
        tipCopy: '@',
        tipEdit: '@',
        tipDelete: '@',
      },
    }
  }

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit

    function onInit() {
      ctrl.showCopy = !!ctrl.copy && !ctrl.hideCopy
      ctrl.showEdit = !!ctrl.edit && !ctrl.hideEdit
      ctrl.showDelete = !!ctrl.delete && !ctrl.hideDelete
    }
  }
})()
