;(function() {
  'use strict'

  Controller.$inject = ["glAnalytics", "userService", "suggestionsService"];
  angular.module('app.core').component('suggestion', {
    controller: Controller,
    templateUrl: 'suggestion.html',
    transclude: true,
  })

  /* @ngInject */
  function Controller(glAnalytics, userService, suggestionsService) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.click = click

    function onInit() {
      var user = userService.getUser()
      if (!user) {
        return
      }
      suggestionsService.getByUser(user.id).then(function(suggestions) {
        if (suggestions.length) {
          ctrl.suggestion = suggestions[0]
        }
      })
    }

    function click() {
      glAnalytics.track('suggesstion', 'clicked', ctrl.suggestion.context)
      ctrl.suggestion.onClick()
    }
  }
})()
