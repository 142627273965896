;(function() {
  'use strict'

  Controller.$inject = ["Currency", "countryService", "glAnalytics", "currencyService", "accountService"];
  angular.module('app.core').component('signupForm', {
    controller: Controller,
    templateUrl: 'signup-form.html',
    bindings: {
      options: '<',
      onCurrencyChange: '&',
      onSignup: '&',
      onRequestLogin: '&',
    },
  })

  /* @ngInject */
  function Controller(
    Currency,
    countryService,
    glAnalytics,
    currencyService,
    accountService
  ) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.setCountryAndCurrency = setCountryAndCurrency
    ctrl.validPromotion = validPromotion
    ctrl.valid = valid
    ctrl.signup = signup

    function onInit() {
      ctrl.businessTypes = [
        { label: 'Academic', value: 'ACADEMIC' },
        { label: 'Industry', value: 'INDUSTRY' },
        { label: 'Agency', value: 'AGENCY' },
      ]
      ctrl.fields = {}
      ctrl.countries = countryService.getSelectOptions()
      ctrl.promotion = ctrl.options.promotion
      if (ctrl.options.country) {
        setCountryAndCurrency(ctrl.options.country.id)
      }
    }

    function setCountryAndCurrency(countryId) {
      ctrl.fields.country = countryService.getById(countryId)
      var localCurrency = currencyService.getByCountry(ctrl.fields.country.cc)
      var currency =
        localCurrency || currencyService.getByCode(Currency.Codes.USD)
      ctrl.fields.currency = currency.code
      ctrl.onCurrencyChange({ $currency: currency })
    }

    function validPromotion() {
      if (ctrl.promotion && ctrl.fields.currency) {
        /**
         * HACK
         * ----
         * The `enterprise` promotion is exempt from requiring at least one credit
         * or matched-spend amount
         */
        if (ctrl.promotion.code === 'enterprise') return true

        return (
          ctrl.promotion.credit.byCurrency(ctrl.fields.currency).amount ||
          ctrl.promotion.matchedSpend.byCurrency(ctrl.fields.currency).amount
        )
      }
    }

    function valid() {
      var base =
        ctrl.fields.firstName &&
        ctrl.fields.lastName &&
        ctrl.fields.country &&
        ctrl.fields.email &&
        ctrl.fields.password &&
        ctrl.fields.termsAgreed
      if (ctrl.promotion) {
        return base && ctrl.fields.businessType
      }
      return base
    }

    function signup() {
      if (!valid() || ctrl.loading) return
      ctrl.errors = null
      ctrl.loading = true
      ctrl.user = {
        fullname: ctrl.fields.firstName + ' ' + ctrl.fields.lastName,
        email: ctrl.fields.email,
        password: ctrl.fields.password,
        registrationContext: {
          type: 'PLAN',
          referrerCode: ctrl.options.referrerCode,
          analysisPackId: ctrl.options.analysisPackId,
          kitId: ctrl.options.kitId,
          termsAgreed: ctrl.fields.termsAgreed,
          utm_source: ctrl.options.utm_source,
          utm_medium: ctrl.options.utm_medium,
          utm_campaign: ctrl.options.utm_campaign,
          utm_term: ctrl.options.utm_term,
          utm_content: ctrl.options.utm_content,
        },
      }
      var subscriptionCode
      if (ctrl.options.isSteadfast) {
        subscriptionCode = 'steadfast'
      } else if (validPromotion()) {
        subscriptionCode = ctrl.promotion.code
      }
      ctrl.subscriber = {
        name: ctrl.fields.company || ctrl.user.fullname,
        country: ctrl.fields.country || ctrl.options.country,
        industry: ctrl.options.industry,
        billing: {
          currency: ctrl.fields.currency,
        },
        contentGroupIds: ctrl.options.contentGroupIds,
        businessType: ctrl.fields.businessType,
        subscriptionCode: subscriptionCode,
        data: {
          allowAddIntegratedPanel: ctrl.options.allowAddIntegratedPanel,
          allowAddExternalPanel: ctrl.options.allowAddExternalPanel,
          preferredExternalPanelId: ctrl.options.preferredExternalPanelId,
          flags: {
            EXTERNAL_PANEL: ctrl.options.enableExternalPanelFlag,
          },
        },
      }
      accountService
        .signup(ctrl.user, ctrl.subscriber)
        .then(function() {
          ctrl.onSignup({ $promoApplied: validPromotion() })
        })
        .catch(function(error) {
          ctrl.loading = false
          var message = 'There was an issue signing up. Please try again.'
          if (error.status === 409) {
            message = 'That email is already taken.'
            glAnalytics.track(
              'signup',
              'create-subscriber-failed',
              ctrl.fields.email
            )
          }
          ctrl.errors = [message]
        })
    }
  }
})()
