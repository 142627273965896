;(function() {
  'use strict'

  angular.module('app.core').controller('Portal', Controller)

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit

    function onInit() {
      // ...
    }
  }
})()
