;(function() {
  'use strict'

  Controller.$inject = ["$scope", "$timeout", "reportD3ChartService", "d3", "c3", "renderService"];
  angular
    .module('glow.reporting.charts')
    .component('reportGaugeChart', Component())

  /* @ngInject */
  function Component() {
    return {
      controller: Controller,
      templateUrl: 'report-gauge-chart.html',
      bindings: {
        height: '=',
        question: '<',
        hide: '<',
        allowChartClick: '=',
        onChartClick: '&',
        focus: '<',
      },
    }
  }

  /* @ngInject */
  function Controller(
    $scope,
    $timeout,
    reportD3ChartService,
    d3,
    c3,
    renderService
  ) {
    var ctrl = this

    ctrl.chartId = _.uniqueId('reportGaugeChart')
    ctrl.$onInit = onInit
    ctrl.$onChanges = onChanges

    function onInit() {
      // ...
    }

    function onChanges(changes) {
      if (!ctrl.chart) {
        $timeout(makeChart, 10)
      } else if (changes.question) {
        refreshChart()
      }
      if (ctrl.chart) {
        ctrl.chart.focus(ctrl.focus)
      }
    }

    // Change the chart data to be percentage based
    function getColumns() {
      var answered = ctrl.question.questionResults.answered
      var weighted = 0
      _.each(ctrl.question.choices, function(choice, index) {
        var count = ctrl.question.questionResults.values[choice.id].count
        var weight = index + 1
        weighted += count * weight
      })
      var score = weighted / answered

      return [['Score', score]]
    }

    function refreshChart() {
      var columns = getColumns()
      ctrl.chart.load({ unload: null, columns: columns })
    }

    function makeChart() {
      delete ctrl.chart

      var tickValues = _.map(ctrl.question.choices, function(choice, index) {
        return index + 1
      })

      var config = {
        bindto: '#' + ctrl.chartId,
        data: {
          columns: getColumns(),
          type: 'gauge',
        },
        gauge: {
          label: {
            format: function(value) {
              return value.toFixed(2)
            },
            // show: false, // to turn off the min/max labels.
          },
          min: 1, // 0 is default, //can handle negative min e.g. vacuum / voltage / current flow / rate of change
          max: ctrl.question.choices.length, // 100 is default
          // units: ' %',
          // width: 39, // for adjusting arc thickness
        },
        color: {
          pattern: _.map(ctrl.question.choices, function(choice) {
            return choice.color
          }),
          threshold: {
            // unit: 'value', // percentage is default
            // max: ctrl.question.choices.length, // 100 is default
            // values: [30, 60, 90, 100],
            values: _.map(ctrl.question.choices, function(choice, index) {
              return index + 0.5
            }).slice(1),
          },
        },
        size: {
          // height: 100,
        },
        legend: {
          show: false,
        },
        tooltip: {
          show: false,
        },
      }

      renderService.queue(function() {
        ctrl.chart = c3.generate(config)

        var mainTicks = tickValues.slice(0, -2)
        var halfTicks = []
        var microTicks = []
        var tick = mainTicks[0]

        _.each(mainTicks, function(val, index) {
          microTicks.push(val - tick / 2 - tick / 4)
          halfTicks.push(val - tick / 2)
          microTicks.push(val - tick / 4)
          if (index === mainTicks.length - 1) {
            microTicks.push(val + tick / 4)
            halfTicks.push(val + tick / 2)
            microTicks.push(val + tick - tick / 4)
          }
        })

        // c3 has no ticks, build our own
        d3.select('#' + ctrl.chartId + ' .c3-chart-arcs')
          .append('g')
          .classed('ticks', true)
          .selectAll('line')

          // main ticks
          .data(mainTicks)
          .enter()
          .append('line')
          .attr('x1', 0)
          .attr('x2', 0)
          .attr('y1', -ctrl.chart.internal.radius + 16)
          .attr('y2', -ctrl.chart.internal.radius)
          .attr('transform', function(d) {
            var min = ctrl.chart.internal.config.gauge_min,
              max = ctrl.chart.internal.config.gauge_max,
              ratio = d / (max - min),
              rotate = ratio * 180 - 90
            return 'rotate(' + rotate + ')'
          })
          .attr('data-reached', function(d) {
            var val = ctrl.chart.internal.data.targets[0].values[0].value
            return d < val ? 'yes' : 'no'
          })
          .exit()

          // half ticks
          .data(halfTicks)
          .enter()
          .append('line')
          .attr('x1', 0)
          .attr('x2', 0)
          .attr('y1', -ctrl.chart.internal.radius + 8)
          .attr('y2', -ctrl.chart.internal.radius)
          .attr('transform', function(d) {
            var min = ctrl.chart.internal.config.gauge_min,
              max = ctrl.chart.internal.config.gauge_max,
              ratio = d / (max - min),
              rotate = ratio * 180 - 90
            return 'rotate(' + rotate + ')'
          })
          .attr('data-reached', function(d) {
            var val = ctrl.chart.internal.data.targets[0].values[0].value
            return d < val ? 'yes' : 'no'
          })
          .exit()

          // micro ticks
          .data(microTicks)
          .enter()
          .append('line')
          .attr('x1', 0)
          .attr('x2', 0)
          .attr('y1', -ctrl.chart.internal.radius + 4)
          .attr('y2', -ctrl.chart.internal.radius)
          .attr('transform', function(d) {
            var min = ctrl.chart.internal.config.gauge_min,
              max = ctrl.chart.internal.config.gauge_max,
              ratio = d / (max - min),
              rotate = ratio * 180 - 90
            return 'rotate(' + rotate + ')'
          })
          .attr('data-reached', function(d) {
            var val = ctrl.chart.internal.data.targets[0].values[0].value
            return d < val ? 'yes' : 'no'
          })
      })
    }
  }
})()
