;(function() {
  'use strict'

  Controller.$inject = ["glUtils", "glDialog", "Question", "kitsService", "userService", "questionTemplateService"];
  angular
    .module('core.questionTemplatesEditor')
    .component('questionTemplatesEditor', Component())

  function Component() {
    return {
      controller: Controller,
      templateUrl: 'question-templates-editor.html',
      bindings: {
        template: '=',
      },
    }
  }

  /* @ngInject */
  function Controller(
    glUtils,
    glDialog,
    Question,
    kitsService,
    userService,
    questionTemplateService
  ) {
    var ctrl = this

    ctrl.tabs = [
      { label: 'Questions', value: 'questions' },
      { label: 'Kits', value: 'kits' },
    ]
    ctrl.selectedTab = ctrl.tabs[0]

    ctrl.addVariation = addVariation
    ctrl.$onInit = onInit
    ctrl.save = save
    ctrl.selectVariant = selectVariant
    ctrl.changeQuestionState = changeQuestionState
    ctrl.onKitSelect = onKitSelect

    function onInit() {
      // reference the title from the first question
      ctrl.template.title = ctrl.template.questions[0].title
      makeVariantTabs()
      ctrl.selectedVariant = ctrl.template.questions[0]
      ctrl.selectedVariantIndex = 0
      ctrl.templateId = ctrl.template.id ? ctrl.template.id : null
      ctrl.questionTypeInfo = Question.getInfo(ctrl.template.type)
      setTitle()
      loadKits()
    }

    function makeVariantTabs() {
      _.each(ctrl.template.questions, function(question, index) {
        question.label = index + 1 + ' - ' + question.state
        question.value = index
      })
    }

    function setTitle() {
      ctrl.editorTitle = ctrl.template.id
        ? 'Edit Question Template'
        : 'Create Question Template'
    }

    function addVariation() {
      ctrl.template.addVariation()
      makeVariantTabs()
      ctrl.selectedVariant = _.last(ctrl.template.questions)
      ctrl.selectedVariantIndex = ctrl.template.questions.length - 1
    }

    function save() {
      ctrl.isProcessing = true
      ctrl.showErrors = false
      return questionTemplateService
        .save(ctrl.template)
        .then(function() {
          makeVariantTabs()
          ctrl.selectedVariant =
            ctrl.template.questions[ctrl.selectedVariantIndex]
          ctrl.isProcessing = false
          setTitle()
          if (!ctrl.templateId) {
            glUtils.changeParams({
              questionId: ctrl.template.id,
              cloneTemplateId: undefined,
              type: undefined,
            })
          }
        })
        .catch(function(error) {
          ctrl.showErrors = true
          var questionWithError = _.find(ctrl.template.questions, function(
            question
          ) {
            return question.errors.length > 0
          })
          if (questionWithError) {
            ctrl.selectedVariant = questionWithError
          } else {
            glDialog.alert('Error Saving', error.data.message)
          }
          ctrl.isProcessing = false
        })
    }

    function selectVariant(index, question) {
      ctrl.selectedVariantIndex = index
      ctrl.selectedVariant = question
    }

    function changeQuestionState(state) {
      ctrl.changingState = true

      // Save the template before the state change
      save()
        .then(function() {
          var question = ctrl.template.questions[ctrl.selectedVariantIndex]
          var action
          switch (state) {
            case 'REDRAFT':
              action = 'redraftQuestion'
              break
            case 'ARCHIVE':
              action = 'archiveQuestion'
              break
            case 'PUBLISH':
              action = 'publishQuestion'
              break
          }
          questionTemplateService[action](ctrl.template, question)
            .then(function() {
              ctrl.changingState = false
            })
            .then(makeVariantTabs)
        })
        .catch(function() {
          ctrl.changingState = false
        })
    }

    /**
     * @name Load Kits
     * @desc If the survey is a template then load the kits that this subscriber OWNS
     */
    function loadKits() {
      kitsService
        .getByOwner(userService.getSubscriberId())
        .then(function(kits) {
          ctrl.kits = kits
          _.each(ctrl.kits, function(kit) {
            if (_.find(ctrl.template.kits, { id: kit.id })) {
              kit.selected = true
            }
          })
        })
    }

    /**
     * @name On Kit Select
     * @desc Adds or removes kit id from the template
     */
    function onKitSelect(kit) {
      if (kit.selected) {
        ctrl.template.kits.push(_.cloneDeep(kit))
      } else {
        _.remove(ctrl.template.kits, { id: kit.id })
      }
    }
  }
})()
