;(function() {
  'use strict'

  Service.$inject = ["glDialog"];
  angular.module('app.survey-edit').factory('surveyExporter', Service)

  /* @ngInject */
  function Service(glDialog) {
    return {
      showExporter: showExporter,
      showImporter: showImporter,
    }

    function showExporter(survey) {
      return glDialog.show({
        templateUrl: 'survey-exporter-export.html',
        controller: 'SurveyExporterExport',
        controllerAs: '$ctrl',
        locals: {
          survey: survey,
        },
        clickOutsideToClose: true,
        escapeToClose: true,
      })
    }

    function showImporter(survey) {
      return glDialog.show({
        templateUrl: 'survey-exporter-import.html',
        controller: 'SurveyExporterImport',
        controllerAs: '$ctrl',
        locals: {
          survey: survey,
        },
        clickOutsideToClose: true,
        escapeToClose: true,
      })
    }
  }
})()
