;(function() {
  'use strict'

  angular.module('app.core', [
    'core.services',
    'core.filters',
    'core.validators',

    'core.actionableListItem',
    'core.kitCard',
    'core.kitGrid',
    'core.kitSections',
    'core.surveyTemplateCard',
    'core.surveyTemplateGrid',
    'core.addPageElementsDialog',
    'core.addPageLinksDialog',
    'core.autofill',
    'core.bootLoader',
    'core.compileHtml',
    'core.glMasonry',
    'core.glView',
    'core.gSelect',
    'core.gResponseChart',
    'core.imagePrompt',
    'core.interaction',
    'core.interactionGraph',
    'core.interactionList',
    'core.kitListSelect',
    'core.kits',
    'core.linkEditorDialog',
    'core.linkList',
    'core.questionLibrary',
    'core.questionTemplates',
    'core.questionTemplatesEditor',
    'core.registerInterest',
    'core.sentimentChart',
    'core.sidenav',
    'core.spinner',
    'core.spFooter',
    'core.stripe',
    'core.surveyCard',
    'core.surveyListSelect',
    'core.surveyAnalyze',
    'core.subSelector',
    'core.orgSelector',
    'core.gSwitch',
    'core.tips',
    'core.wordcloud',
  ])
})()
