;(function() {
  'use strict'

  Controller.$inject = ["resourceService", "surveyService", "subscriberService"];
  angular.module('app.core').component('resourceEditor', {
    controller: Controller,
    templateUrl: 'resource-editor.html',
    bindings: {
      src: '<resource',
      onDiscard: '&',
      onCommit: '&',
    },
  })

  /* @ngInject */
  function Controller(resourceService, surveyService, subscriberService) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.commit = commit
    ctrl.setType = setType
    ctrl.setSurvey = setSurvey
    ctrl.setCategory = setCategory
    ctrl.isCategorySelected = isCategorySelected
    ctrl.isCategoryDisabled = isCategoryDisabled

    function onInit() {
      ctrl.TypeEnums = resourceService.Types
      // remove interactive analysis from the list of types
      ctrl.Types = _.reject(resourceService.TypeInfo, {
        type: resourceService.Types.INTERACTIVE_ANALYSIS,
      })
      ctrl.CategoryEnums = resourceService.Categories
      ctrl.Categories = resourceService.CategoryInfo
      ctrl.isNew = !ctrl.src
      loadSurveys().then(function() {
        if (ctrl.src) {
          ctrl.resource = ctrl.src.clone()
        } else {
          ctrl.resource = ctrl.src = resourceService.getBlank()
        }
      })
    }

    function loadSurveys() {
      var subscriberId = subscriberService.getSubscriber().id
      return surveyService
        .getSummariesBySubscriber(subscriberId)
        .then(function(surveys) {
          ctrl.surveys = _.map(surveys, function(survey) {
            var suffix = survey.reference ? ' (' + survey.reference + ')' : ''
            return {
              value: survey.id,
              label: survey.title + suffix,
            }
          })
        })
    }

    function setType(type) {
      ctrl.resource.type = type
      if (type.type === ctrl.TypeEnums.INTERACTIVE_ANALYSIS) {
        setCategory(
          _.find(ctrl.Categories, {
            type: ctrl.CategoryEnums.INTERACTIVE_ANALYSIS,
          })
        )
      }
    }

    function commit() {
      ctrl.src.become(ctrl.resource)
      ctrl.onCommit({ $resource: ctrl.src })
    }

    function setSurvey(surveyId) {
      ctrl.resource.surveyId = surveyId
      // changing survey should re-attach preview tokens
      if (ctrl.resource.surveyPreviewToken) {
        ctrl.resource.surveyPreviewToken = true
      }
    }

    function setCategory(category) {
      ctrl.resource.category = category
    }

    function isCategorySelected(category) {
      return (
        ctrl.resource.category && category.type === ctrl.resource.category.type
      )
    }

    function isCategoryDisabled(category) {
      return (
        ctrl.resource.type.type === ctrl.TypeEnums.INTERACTIVE_ANALYSIS &&
        category.type !== ctrl.CategoryEnums.INTERACTIVE_ANALYSIS
      )
    }
  }
})()
