;(function() {
  'use strict'

  Controller.$inject = ["$timeout", "responseService", "mediaView", "Question", "StateFactory", "LocationSet"];
  angular.module('app.core').component('responseDetailsDialog', {
    controller: Controller,
    templateUrl: 'response-details-dialog.html',
    bindings: {
      survey: '<',
      responseId: '<',
      onClose: '&',
    },
  })

  /* @ngInject */
  function Controller(
    $timeout,
    responseService,
    mediaView,
    Question,
    StateFactory,
    LocationSet
  ) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.$onDestroy = onDestroy
    ctrl.expandImage = mediaView.showImage

    function onInit() {
      ctrl.state = new StateFactory(['loading', 'ready', 'error'])
      ctrl.state.loading()
      responseService
        .get(ctrl.responseId)
        .then(function(response) {
          console.log('response', response)
          ctrl.response = response
          prepare()
          ctrl.state.ready()
        })
        .catch(function(err) {
          console.error(err)
          ctrl.state.error()
        })
      ctrl.numQuestions = ctrl.survey.getQuestionCount() + ''
      document.body.addEventListener('keydown', onKeyDown)
    }

    function onKeyDown(e) {
      // manually scrolling to find a question in a large survey can be tedious.
      // this allows you to just type a question number and have it scrolled to and highlighted
      // for you.
      if (e.keyCode >= 48 && e.keyCode <= 57) {
        e.preventDefault()
        // determine number pressed
        var numPressed = e.keyCode - 48
        // if previous number, check if this is additive or replacing
        if (ctrl.numSeeking) {
          var numChecking = ctrl.numSeeking + '' + numPressed
          var elem = document.body.querySelector(
            '[data-num="' + numChecking + '"]'
          )
          if (elem) {
            // if additive resolves to a match, use it!
            ctrl.numSeeking = +numChecking
          } else {
            // otherwise replace it and start again
            ctrl.numSeeking = numPressed
          }
        } else {
          ctrl.numSeeking = numPressed
        }
        seekTo(ctrl.numSeeking)
      }
      // Up arrow highlights and scrolls to previous question
      if (e.keyCode === 38) {
        var numSeeking = _.isNumber(ctrl.numCurrent)
          ? Math.max(ctrl.numCurrent - 1, 1)
          : 1
        seekTo(numSeeking)
      }
      // Down arrow highlights and scrolls to next question
      if (e.keyCode === 40) {
        var max = 0
        document.body.querySelectorAll('[data-num]').forEach(function(elem) {
          var num = +elem.getAttribute('data-num')
          if (num > max) max = num
        })
        var numSeeking = _.isNumber(ctrl.numCurrent)
          ? Math.min(ctrl.numCurrent + 1, max)
          : 1
        seekTo(numSeeking)
      }
    }

    function seekTo(num) {
      ctrl.numSeeking = num
      // remove all current highlights
      var elems = document.body.querySelectorAll('[data-response]')
      elems.forEach(function(elem) {
        elem.classList.remove('-highlight')
      })
      // cancel any existing timer
      $timeout.cancel(ctrl.highlighter)
      // find item to seek to
      var elem = document.body.querySelector(
        '[data-num="' + ctrl.numSeeking + '"]'
      )
      // seek or clear
      if (elem) {
        var container = document.body.querySelector('.gl-dialog-container')
        var elemBox = elem.getBoundingClientRect()
        var elemTop = elemBox.top
        var elemHeight = elemBox.height
        var winHeight = window.innerHeight
        var scrollY = container.scrollTop
        // highlight and scroll to it
        elem.classList.add('-highlight')
        container.scrollTop = elemTop - winHeight / 2 + elemHeight / 2 + scrollY
        // store as seeked
        ctrl.numCurrent = ctrl.numSeeking
        // set a timer to remove the highlight and restart
        ctrl.highlighter = $timeout(function() {
          elem.classList.remove('-highlight')
          ctrl.numSeeking = null
        }, 600)
      } else {
        ctrl.numSeeking = null
      }
    }

    function onDestroy() {
      ctrl.destroyed = true
      $timeout.cancel(ctrl.highlighter)
      document.body.removeEventListener('keydown', onKeyDown)
    }

    function prepare() {
      ctrl.questions = ctrl.survey.getQuestions().map(function(question) {
        return {
          id: question.id,
          num: question.getNumber(),
          label: question.getTitleLabel({ number: true }),
          typeLabel: question.label,
          items: getItems(question),
        }
      })
      ctrl.dimensions = ctrl.response.dimensions.filter(function(dimension) {
        // remove dimensions that shouldn't be here or don't need to be shown
        if (['link', 'channel'].includes(dimension.key)) {
          return false
        }
        if (dimension.key.startsWith('tag:')) {
          return false
        }
        return true
      })
    }

    function getItems(question) {
      var answer = ctrl.response.answers[question.id]
      var items = []
      function addImage(url) {
        items.push({
          type: 'image',
          url: url,
        })
      }
      function addHeading(text) {
        items.push({
          type: 'heading',
          text: text,
        })
      }
      function addSelection(label, info) {
        items.push({
          type: 'selection',
          label: label,
          info: info,
        })
      }
      function addText(text) {
        items.push({
          type: 'text',
          text: text,
        })
      }
      function addEmpty() {
        items.push({
          type: 'empty',
          text: answer.seen ? 'Skipped' : 'Not Asked',
        })
      }
      if (!answer) {
        items.push({ type: 'empty', text: 'Not Asked' })
        return items
      }
      if (question.type === Question.Types.CHOICE) {
        if (answer.selected.length) {
          answer.selected.forEach(function(item) {
            var label = getChoiceLabel(question, item.choiceId)
            addSelection(label)
          })
        } else {
          addEmpty()
        }
      }
      if (question.type === Question.Types.RANK) {
        if (answer.selected.length) {
          var ordered = _.sortBy(answer.selected, 'rank')
          ordered.forEach(function(item) {
            var label = getChoiceLabel(question, item.choiceId)
            var info = 'Rank ' + item.rank
            addSelection(label, info)
          })
        } else {
          addEmpty()
        }
      }
      if (question.type === Question.Types.SCALE) {
        if (answer.selected.length) {
          answer.selected.forEach(function(item) {
            var label = getChoiceLabel(question, item.choiceId)
            addSelection(label)
          })
        } else {
          addEmpty()
        }
      }
      if (question.type === Question.Types.SINGLE_TEXT) {
        if (answer.text) {
          addText(answer.text)
        } else {
          addEmpty()
        }
      }
      if (question.type === Question.Types.TEXT) {
        if (answer.text) {
          addText(answer.text)
        } else {
          addEmpty()
        }
      }
      if (question.type === Question.Types.SCAN) {
        if (answer.text) {
          addText(answer.text)
        } else {
          addEmpty()
        }
      }
      if (question.type === Question.Types.NPS) {
        if (answer.selected.length) {
          var label = getChoiceLabel(question, answer.selected[0].choiceId)
          addSelection(label)
        } else {
          addEmpty()
        }
      }
      if (question.type === Question.Types.MOOD) {
        if (answer.selected.length) {
          var label = getChoiceLabel(question, answer.selected[0].choiceId)
          addSelection(label)
        } else {
          addEmpty()
        }
      }
      if (question.type === Question.Types.PLACES_NEAR_ME) {
        if (answer.places.length) {
          answer.places.forEach(function(item) {
            addSelection(item.label)
          })
        } else {
          addEmpty()
        }
      }
      if (question.type === Question.Types.LOCATION) {
        if (answer.text) {
          var set = new LocationSet().fromTextAnswers([
            { textAnswer: answer.text },
          ])
          addSelection(set.locations[0].name)
        } else {
          addEmpty()
        }
      }
      if (question.type === Question.Types.RATING) {
        if (answer.selected.length) {
          var label = getChoiceLabel(question, answer.selected[0].choiceId)
          addSelection(label)
        } else {
          addEmpty()
        }
      }
      if (question.type === Question.Types.NUMERIC) {
        if (_.isNumber(answer.number)) {
          // todo: format based on numeric unit type etc
          addSelection(answer.number)
        } else {
          addEmpty()
        }
      }
      if (question.type === Question.Types.MATRIX) {
        if (answer.selected.length) {
          question.getVisibleStatements().forEach(function(statement) {
            addHeading(statement.label)
            var matches = answer.selected.filter(function(item) {
              return item.statementId === statement.id
            })
            if (matches.length) {
              matches.forEach(function(item) {
                var label = getChoiceLabel(question, item.choiceId)
                addSelection(label)
              })
            } else {
              addEmpty()
            }
          })
        } else {
          addEmpty()
        }
      }
      if (question.type === Question.Types.CONSTANT_SUM) {
        if (answer.selected.length) {
          if (question.isUsedAsMatrix()) {
            question.getVisibleStatements().forEach(function(statement) {
              addHeading(statement.label)
              var matches = answer.selected.filter(function(item) {
                return item.statementId === statement.id
              })
              if (matches.length) {
                matches.forEach(function(item) {
                  var label = getChoiceLabel(question, item.choiceId)
                  var info = item.number
                  addSelection(label, info)
                })
              } else {
                addEmpty()
              }
            })
          } else {
            answer.selected.forEach(function(item) {
              var label = getChoiceLabel(question, item.choiceId)
              var info = item.number
              addSelection(label, info)
            })
          }
        } else {
          addEmpty()
        }
      }
      if (question.type === Question.Types.SCORE) {
        if (answer.selected.length) {
          if (question.isUsedAsMatrix()) {
            question.getVisibleStatements().forEach(function(statement) {
              addHeading(statement.label)
              var matches = answer.selected.filter(function(item) {
                return item.statementId === statement.id
              })
              if (matches.length) {
                matches.forEach(function(item) {
                  var label = getChoiceLabel(question, item.choiceId)
                  addSelection(label)
                })
              } else {
                addEmpty()
              }
            })
          } else {
            answer.selected.forEach(function(item) {
              var label = getChoiceLabel(question, item.choiceId)
              addSelection(label)
            })
          }
        } else {
          addEmpty()
        }
      }
      if (question.type === Question.Types.IMAGE) {
        if (answer.text) {
          addImage(answer.text)
        } else {
          addEmpty()
        }
      }
      if (question.type === Question.Types.HIDDEN_VARIABLES) {
        if (answer.selected.length) {
          answer.selected.forEach(function(item) {
            var label = getChoiceLabel(question, item.choiceId)
            addSelection(label)
          })
        } else {
          addEmpty()
        }
      }
      return items
    }

    function getChoiceLabel(question, choiceId) {
      var choice = question.getVisibleChoices().find(function(choice) {
        return choice.id === choiceId
      })
      if (!choice) {
        console.log('question', question)
        console.log('choiceId', choiceId)
      }
      return choice.label
    }
  }
})()
