;(function() {
  'use strict'

  Controller.$inject = ["glToast", "StateFactory", "subscriberService", "paymentMethodsService"];
  angular.module('app.core').component('paymentMethodEditor', {
    controller: Controller,
    templateUrl: 'payment-method-editor.html',
    bindings: {
      paymentMethod: '<',
      onComplete: '&',
    },
  })

  /* @ngInject */
  function Controller(
    glToast,
    StateFactory,
    subscriberService,
    paymentMethodsService
  ) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.save = save

    function onInit() {
      ctrl.state = new StateFactory(['loading', 'ready'])
    }

    function save() {
      ctrl.state.loading()
      ctrl.card
        .submit()
        .then(savePaymentMethod)
        .then(onSaveSuccess)
        .catch(onSaveError)
        .finally(ctrl.state.ready)
    }

    function savePaymentMethod() {
      var subscriberId = subscriberService.getSubscriber().id
      var action = ctrl.paymentMethod ? 'replace' : 'create'
      return paymentMethodsService[action](subscriberId, ctrl.card.tokenId)
    }

    function onSaveSuccess() {
      glToast.show('Payment Method saved')
      ctrl.onComplete()
    }

    function onSaveError(error) {
      glToast.show(error.message || 'Could not save Payment Method')
    }
  }
})()
