;(function() {
  'use strict'

  Controller.$inject = ["Publication"];
  angular.module('app.core').component('publicationTypeIcon', {
    controller: Controller,
    templateUrl: 'publication-type-icon.html',
    bindings: {
      type: '<',
      greyscale: '<',
      large: '<',
    },
  })

  /* @ngInject */
  function Controller(Publication) {
    var ctrl = this

    ctrl.$onInit = onInit

    ctrl.info = {}
    ctrl.info[Publication.Types.FREE] = { icon: 'praise', class: '-free' }
    ctrl.info[Publication.Types.PAID] = { icon: 'dollar', class: '-paid' }
    ctrl.info[Publication.Types.PREMIUM] = { icon: 'star', class: '-premium' }

    function onInit() {
      // ...
    }
  }
})()
