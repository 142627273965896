;(function() {
  'use strict'

  interactionGraphDirective.$inject = ["$log", "$timeout", "c3", "d3", "glUtils", "interactionsService"];
  angular
    .module('core.interactionGraph')
    .directive('glSpInteractionGraph', interactionGraphDirective)

  /* @ngInject */
  function interactionGraphDirective(
    $log,
    $timeout,
    c3,
    d3,
    glUtils,
    interactionsService
  ) {
    InteractionGraph.$inject = ["$element"];
    $log = $log.create('interactionGraphDirective')

    return {
      restrict: 'EA',
      templateUrl: 'interaction-graph.template.html',
      controller: InteractionGraph,
      controllerAs: 'interactionGraph',
      scope: {
        interactions: '=',
      },
      bindToController: true,
    }

    function InteractionGraph($element) {
      var vm = this
      var chartId = 'intChart' + _.uniqueId()
      var series
      var yLimits
      var months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ]

      vm.$onInit = onInit

      /* Initialise */
      function onInit() {
        getInteractions()
      }

      /**
       * get interactions for this subscriber
       */
      function getInteractions() {
        vm.interactions.then(getSuccess).catch(getError)

        function getSuccess() {
          vm.interactionsData = interactionsService.data
          vm.state = 'ready'

          // wait for ng-switch to ensure template is in view before trying to chanrt
          $timeout(function() {
            addChart()
          })
        }

        function getError() {
          vm.state = 'error'
          $log.error('could not load interactions')
        }
      }

      /**
       * add graph to the DOM once data has been loaded and view is ready
       */
      function addChart() {
        $element.find('figure')[0].id = chartId

        generateData()

        drawChart()
      }

      /**
       * generate the chart and add to DOM
       */
      function drawChart() {
        c3.generate({
          bindto: '#' + chartId,
          data: {
            x: 'Date',
            xFormat: '%Y-%m-%d',
            columns: series,
            type: 'bar',
          },
          axis: {
            x: {
              type: 'timeseries',
              tick: {
                format: function(x) {
                  return x.getDate() + ' ' + months[x.getMonth()]
                },
              },
              padding: {
                left: 0,
              },
            },
            y: {
              show: false,
              min: yLimits.min,
              max: yLimits.max,
              tick: {
                count: yLimits.count,
                format: d3.format(',.0f'),
              },
              padding: {
                bottom: 0,
                top: 0,
              },
            },
          },
          grid: {
            y: {
              show: true,
            },
          },
          point: {
            r: 4,
          },
          legend: {
            show: false,
          },
          area: {
            zerobased: true,
          },
          tooltip: {
            format: {
              title: function(x) {
                return moment(x).format('ddd DD MMM')
              },
            },
          },
        })
      }

      /**
       * generate chart data
       */
      function generateData() {
        // generate graph series
        series = generateSeries()

        // parse data into series
        parseResponses(vm.interactionsData.interactions, series)

        // calculate y axis limit
        getYLimits()
      }

      /**
       * get the Y limits of the chart - used for drawing grid lines
       */
      function getYLimits() {
        var min = 0
        var max = _.max(series[1])

        max = (Math.floor(max / 4) + 1) * 4

        var count = max < 4 ? max + 1 : 5

        yLimits = {
          min: min,
          max: max,
          count: count,
        }
      }

      /**
       * generate chart series
       */
      function generateSeries() {
        var series = [['Date'], ['Activities']]

        var startDate = moment().subtract(4, 'weeks')
        var endDate = moment()

        var dates = glUtils.getDateRange(startDate, endDate)

        _.forEach(dates, function(date) {
          series[0].push(date)
          series[1].push(0)
        })

        return series
      }

      /**
       * parse responses into series
       */
      function parseResponses(interactions, series) {
        _.forEach(interactions, function(interaction) {
          var day = moment(interaction.createdAt).format('YYYY-MM-DD')
          var dayIndex = _.indexOf(series[0], day)

          if (dayIndex !== -1) {
            series[1][dayIndex]++
          }
        })
      }
    }
  }
})()
