;(function() {
  'use strict'

  Factory.$inject = ["FilterSet"];
  angular.module('glow.reporting').factory('FilterSetGroup', Factory)

  /* @ngInject */
  function Factory(FilterSet) {
    var VERSION_REGEX = /\s\(\d+\)$/

    var Operators = {
      AND: 'and',
      OR: 'or',
    }

    FilterSetGroup.prototype.Operators = FilterSetGroup.Operators = Operators

    function FilterSetGroup(options) {
      var self = this
      this.survey = options.survey
      this.accessType = options.accessType
      this.ownerId = options.ownerId

      var filterSets = _.map(options.filterSets, function(filterSet) {
        return new FilterSet(self).deserialize(filterSet)
      })
      this.default = new FilterSet(self, {
        label: 'All Responses',
        isDefault: true,
        isReadOnly: true,
      })
      filterSets.push(this.default)

      _.each(filterSets, function(filterSet) {
        filterSet.pin()
      })

      // order (default -> static -> editable) + by order field
      this.filterSets = _.orderBy(filterSets, [
        function(filterSet) {
          if (filterSet.isDefault) {
            return 1
          }
          if (filterSet.isReadOnly) {
            return 2
          }
          return 3
        },
        function(filterSet) {
          return filterSet.order
        },
      ])

      this.operator = Operators.AND
    }

    FilterSetGroup.prototype.getEditable = function() {
      return _.filter(this.filterSets, { isDefault: false, isReadOnly: false })
    }

    FilterSetGroup.prototype.getSelected = function() {
      return _.filter(this.filterSets, { isSelected: true })
    }

    FilterSetGroup.prototype.refreshSelected = function() {
      // this is to ensure when nothing is selected, then select default
      // if default is selected along with other filterSets, then deselect default
      if (this.getSelected().length === 0) {
        this.default.isSelected = true
      }
      if (this.getSelected().length > 1 && this.default.isSelected) {
        this.default.isSelected = false
      }
    }

    FilterSetGroup.prototype.selectSet = function(filterSet) {
      // this is to select 1 filterSet and de-select the others
      filterSet.isSelected = true
      _.each(this.filterSets, function(item) {
        if (filterSet === item) return // skip
        item.isSelected = false
      })
    }

    FilterSetGroup.prototype.toggleSet = function(filterSet) {
      // this is to toggle filter set
      filterSet.isSelected = !filterSet.isSelected
      // must always call refresh to ensure at least 1 filterSet is selected
      this.refreshSelected()
    }

    FilterSetGroup.prototype.snapshotSelected = function() {
      // this is to take snapshot of what's currently selected
      this.selectedSnapshot = _.chain(this.getSelected())
        .filter(function(filterSet) {
          return !filterSet.isDefault && filterSet.id
        })
        .map('id')
        .value()
    }

    FilterSetGroup.prototype.revertSelected = function() {
      // this is to revert back selection to snapshot
      if (this.selectedSnapshot.length) {
        this.selectByFilterSetIds(this.selectedSnapshot)
      }
      // must always call refresh to ensure at least 1 filterSet is selected
      this.refreshSelected()
    }

    FilterSetGroup.prototype.selectByFilterSetIds = function(filterSetIds) {
      if (!filterSetIds.length) return
      _.each(this.filterSets, function(filterSet) {
        filterSet.isSelected = _.includes(filterSetIds, filterSet.id)
      })
    }

    FilterSetGroup.prototype.getOperator = function() {
      return this.operator
    }

    FilterSetGroup.prototype.setOperator = function(operator) {
      operator = operator.toUpperCase()
      if (!Operators[operator]) return
      this.operator = Operators[operator]
    }

    FilterSetGroup.prototype.getHiddenCharts = function() {
      var hiddenCharts = []
      _.each(this.getSelected(), function(filterSet) {
        hiddenCharts = _.concat(hiddenCharts, filterSet.hiddenCharts)
      })
      return hiddenCharts.length ? _.uniq(hiddenCharts) : null
    }

    FilterSetGroup.prototype.remove = function(filterSet) {
      var idx = _.indexOf(this.filterSets, filterSet)
      _.remove(this.filterSets, filterSet)
      var next = this.filterSets[idx] || this.filterSets[idx - 1]
      return {
        idx: idx,
        next: next,
      }
    }

    FilterSetGroup.prototype.generateLabel = function(base) {
      base = base || 'My New Filter'

      // attempt versioning extract
      base = base.replace(VERSION_REGEX, '')

      var num = 1
      var label
      // try non-versioning
      var set = _.find(this.filterSets, { label: base })
      if (!set) {
        return base
      }
      // try versioning
      while (!label) {
        var potentialLabel = base + ' (' + ++num + ')'
        set = _.find(this.filterSets, { label: potentialLabel })
        if (!set) {
          label = potentialLabel
        }
      }
      return label
    }

    FilterSetGroup.prototype.add = function(filterSet, options) {
      filterSet =
        filterSet || new FilterSet(this, { ownerId: this.ownerId }).init()
      options = options || {}
      var idx
      if (options.at) {
        idx = options.at
      } else if (options.after) {
        idx = _.findIndex(this.filterSets, options.after) + 1
      } else {
        idx = this.filterSets.length
      }
      this.filterSets.splice(idx, 0, filterSet)
      return filterSet
    }

    FilterSetGroup.prototype.duplicate = function() {
      var filterSets = _.chain(this.filterSets)
        .filter({ isDefault: false })
        .map(function(filterSet) {
          return filterSet.serialize()
        })
        .value()
      var options = {
        survey: this.survey,
        accessType: this.accessType,
        ownerId: this.ownerId,
        filterSets: filterSets,
      }
      return new FilterSetGroup(options)
    }

    return FilterSetGroup
  }
})()
