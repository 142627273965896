;(function() {
  'use strict'

  angular.module('app.core').component('panelTargets', {
    controller: Controller,
    templateUrl: 'panel-targets.html',
    bindings: {
      selected: '<',
      onSelect: '&',
    },
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit

    function onInit() {
      ctrl.targets = getTargets()
    }

    function getTargets() {
      return [
        {
          country: 'AU',
          language: 'en',
          name: 'Australia',
          desc: 'Collect responses from a representative sample in Australia.',
          image: 'svg/noun_Australia_672413.svg',
        },
        {
          country: 'GB',
          language: 'en',
          name: 'United Kingdom',
          desc: 'Collect responses from a representative sample in the UK.',
          image: 'svg/noun_UK_672416.svg',
        },
        {
          country: 'US',
          language: 'en',
          name: 'United States',
          desc: 'Collect responses from a representative sample in the USA.',
          image: 'svg/noun_United States Map_619932.svg',
        },
        {
          country: 'CA',
          language: 'en',
          name: 'Canada',
          desc: 'Collect responses from a representative sample in Canada.',
          image: 'svg/noun_Canada_709996.svg',
        },
        {
          country: 'NZ',
          language: 'en',
          name: 'New Zealand',
          desc: 'Collect responses from a representative sample in NZ.',
          image: 'svg/noun_new zealand_1314619.svg',
        },
        {
          country: null,
          language: null,
          name: 'Custom',
          desc: 'Collect responses using granular or custom targeting.',
          image: 'svg/noun_world_543669.svg',
        },
      ]
    }
  }
})()
