;(function() {
  'use strict'

  Controller.$inject = ["$scope", "originService", "clipboardService"];
  angular
    .module('app.survey-edit')
    .controller('SurveyExporterExport', Controller)

  /* @ngInject */
  function Controller($scope, originService, clipboardService) {
    var ctrl = this

    ctrl.selectAndCopy = selectAndCopy
    ctrl.copy = copy
    ctrl.close = close

    init()

    function init() {
      var origin = originService.createForExport(ctrl.survey)
      var data = ctrl.survey.serialize()
      // disable unlimited questions function (this is per survey only)
      delete data.data.unlimitedQuestionsAllowed

      // Remove core-profiling entirely on export
      // Trello Card : https://trello.com/c/cgkQvVtX
      data.data.coreProfileAdded = false
      data.data.coreProfileEnabled = false
      data.data.coreProfileCompulsory = false
      var coreProfileQuestionIds = data.questions
        .filter(function(question) {
          return (
            question.profiling &&
            _.includes(
              ['age_year_of_birth', 'age_range', 'sex', 'location'],
              question.profiling.key
            )
          )
        })
        .map(function(question) {
          return question.id
        })
      _.remove(data.questions, function(question) {
        return coreProfileQuestionIds.includes(question.id)
      })
      _.remove(data.data.views, function(view) {
        return coreProfileQuestionIds.includes(view.value)
      })

      // track origin
      data.data.origins.unshift(origin.serialize())
      ctrl.data = JSON.stringify(data, null, 2)
    }

    function selectAndCopy(event) {
      if (ctrl.isSelected) {
        return
      }
      ctrl.isSelected = true
      event.target.select()
      copy()
    }

    function copy() {
      clipboardService.copy(ctrl.data)
    }

    function close() {
      $scope.dialog.close()
    }
  }
})()
