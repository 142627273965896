;(function() {
  'use strict'

  Controller.$inject = ["$state", "app", "datapackService"];
  angular
    .module('app.shared-dashboard')
    .controller('SharedDashboardController', Controller)

  /* @ngInject */
  function Controller($state, app, datapackService) {
    var ctrl = this

    ctrl.$onInit = onInit

    function onInit() {
      ctrl.dashboardId = $state.params.dashboardId

      datapackService
        .getDashboard(null, ctrl.dashboardId)
        .then(function(dashboard) {
          ctrl.dashboard = dashboard
          app.setTitle(dashboard.name)
          // app.setTags(tags)
          console.log(dashboard)
        })
    }
  }
})()
