;(function() {
  'use strict'

  config.$inject = ["$stateProvider"];
  angular.module('app.core').config(config)

  /* @ngInject */
  function config($stateProvider) {
    $stateProvider.state('store', {
      url: '/store',
      parent: 'portal',
      templateUrl: 'store.html',
      controller: 'StoreController',
      controllerAs: '$ctrl',
      title: 'Store',
      public: true,
    })

    $stateProvider.state('insights-store-deprecated', {
      url: '/insights/store',
      redirectTo: 'store',
    })

    $stateProvider.state('packs-store-redirect', {
      url: '/packs-store',
      redirectTo: 'store',
    })

    $stateProvider.state('packs-redirect', {
      url: '/packs',
      redirectTo: 'store',
    })

    $stateProvider.state('insights-packs-redirect', {
      url: '/insights/packs',
      redirectTo: 'store',
    })
  }
})()
