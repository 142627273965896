;(function() {
  'use strict'

  Service.$inject = ["glDialog", "glPrefs", "glAnalytics"];
  angular
    .module('app.core')
    .service('surveyTemplateInstructionsService', Service)

  /* @ngInject */
  function Service(glDialog, glPrefs, glAnalytics) {
    var self = this

    var prefs = glPrefs.create('survey-template-instructions')
    var SUPPRESS = 'suppress'

    self.show = show

    function show(options) {
      if (prefs.get(SUPPRESS)) {
        return
      }
      var templateName = options && options.templateName
      var template = [
        '<gl-dialog class="survey-template-instructions__dialog">',
        '<survey-template-instructions ',
        'template-name="{{ templateName }}"',
        'on-close="dialog.close($dontShowAgain)" ',
        '/>',
        '</gl-dialog>',
      ]
      return glDialog
        .show({
          template: template.join(''),
          locals: { templateName: templateName },
          clickOutsideToClose: true,
        })
        .then(function(dontShowAgain) {
          if (dontShowAgain) {
            prefs.set(SUPPRESS, dontShowAgain)
            glAnalytics.track(
              'surveys',
              'survey-template-instructions-suppressed'
            )
          }
        })
    }
  }
})()
