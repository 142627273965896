;(function() {
  'use strict'

  Service.$inject = ["Filter"];
  angular.module('glow.reporting').factory('filterService', Service)

  function Service(Filter) {
    var Types = Filter.Types
    var DateTypes = Filter.DateTypes
    var QuestionCategories = Filter.QuestionCategories

    return {
      getInfo: getInfo,
    }

    function getInfo(survey, filter) {
      switch (filter.type) {
        case Types.QUESTION:
        case Types.PROFILE:
          return getQuestionInfo(survey, filter)
        case Types.DATE:
          return getDateInfo(filter)
        case Types.DIMENSION:
          return getDimensionInfo(filter)
        case Types.CHANNEL:
          return getChannelInfo(survey, filter)
        case Types.PAGE:
          return getPageInfo(filter)
        case Types.SEGMENT:
          return getSegmentInfo(filter)
      }
    }

    function getQuestionInfo(survey, filter) {
      var question = _.find(survey.questions, { id: filter.data.questionId })
      var info = {}
      info.title = question.listTitle
      switch (filter.data.category) {
        case QuestionCategories.CHOICE:
        case QuestionCategories.NUMERIC:
          info.text = _.map(filter.data.values, function(value) {
            var choice = _.find(question.choices, value)
            return choice.displayLabel || choice.option
          }).join(', ')
          break
        case QuestionCategories.TEXT:
          info.text = _.map(filter.data.values, 'text').join(', ')
          break
        case QuestionCategories.NPS:
          info.text = _.map(filter.data.values, function(value) {
            // TODO: this isn't super clean
            return {
              promoter: 'Promoters',
              passive: 'Passives',
              detractor: 'Detractors',
            }[value.id]
          }).join(', ')
          break
        case QuestionCategories.MOOD:
          info.moods = _.map(filter.data.values, function(value) {
            var choice = _.find(question.choices, { id: value.id })
            return {
              icon: choice.icon,
              color: choice.color,
              // text: choice.displayLabel
            }
          })
          break
        case QuestionCategories.LOCATION:
          info.text = _.map(filter.data.values, 'text').join(', ')
          break
        case QuestionCategories.PLACES_NEAR_ME:
          var places =
            survey.filterOptions.questions[filter.data.questionId].places
          info.texts = _.map(filter.data.values, function(place) {
            place = _.find(places, { sourceId: place.sourceId })
            // TODO: generate this when we get the survey/chartData as it is a constant
            return place.name + ', ' + place.address + ', ' + place.city
          })
          break
        case QuestionCategories.RANK:
          var rank = _.find(question.ranks, { value: filter.data.aspect })
          info.text = rank ? rank.label : 'Any Rank'
          info.text += ' - '
          info.text += _.map(filter.data.values, function(value) {
            var choice = _.find(question.choices, value)
            return choice.displayLabel || choice.option
          }).join(', ')
          break
        case QuestionCategories.MATRIX:
          var statement = _.find(question.statements, {
            id: filter.data.aspect,
          })
          info.text = statement ? statement.statement : 'Any Statement'
          info.text += ' - '
          info.text += _.map(filter.data.values, function(value) {
            var choice = _.find(question.choices, value)
            return choice.displayLabel || choice.option
          }).join(', ')
          break
        case QuestionCategories.CONSTANT_SUM:
        case QuestionCategories.SCORE:
          // CONSTANT_SUM and SCORE has very similar filter
          // with only difference is that SCORE doesn't have choice.numbers
          if (question.isMatrix) {
            var statement = _.find(question.statements, {
              id: filter.data.aspect,
            })
            info.text = statement ? statement.statement : 'Any Statement'
            info.text += ' - '
            info.text += _.map(filter.data.values, function(choice) {
              var questionChoice = _.find(question.choices, { id: choice.id })
              var label = questionChoice.displayLabel || questionChoice.option
              if (choice.numbers) {
                return label + ': [' + choice.numbers.join(', ') + ']'
              }
              return label
            }).join(', ')
          } else {
            info.text = _.map(filter.data.values, function(choice) {
              var questionChoice = _.find(question.choices, { id: choice.id })
              var label = questionChoice.displayLabel || questionChoice.option
              if (choice.numbers) {
                return label + ': [' + choice.numbers.join(', ') + ']'
              }
              return label
            }).join(', ')
          }
          break
      }
      return info
    }

    function getDateInfo(filter) {
      var info = {}
      var start = filter.data.start
      var end = filter.data.end
      var days = end.diff(start, 'days') + 1
      info.title =
        filter.data.dateType === DateTypes.ALL_TIME
          ? 'All respondents since publication'
          : 'Responded between'
      info.text =
        start.format('D MMM YYYY') +
        ' to ' +
        end.format('D MMM YYYY') +
        ' (' +
        days +
        ' day' +
        (days === 1 ? '' : 's') +
        ')'
      return info
    }

    function getDimensionInfo(filter) {
      return {
        title: filter.data.category,
        text: _.map(filter.data.values, 'value').join(', '),
      }
    }

    function getChannelInfo(survey, filter) {
      return {
        title: 'Audience',
        text: _.map(filter.data.values, function(channelId) {
          return _.find(survey.channels, { id: channelId }).name
        }).join(', '),
      }
    }

    function getPageInfo(filter) {
      return {
        title: 'Pages',
        texts: _.map(filter.data.values, 'label'),
      }
    }

    function getSegmentInfo(filter) {
      return {
        title: 'Segment',
        text: filter.data.values.join(', '),
      }
    }
  }
})()
