;(function() {
  'use strict'

  Service.$inject = ["Origin", "configService", "userService", "subscriberService"];
  angular.module('app.survey-edit').service('originService', Service)

  /* @ngInject */
  function Service(Origin, configService, userService, subscriberService) {
    this.createForExport = createForExport
    this.createForDuplicate = createForDuplicate

    function createForExport(survey) {
      return create(survey, Origin.Types.EXPORT)
    }

    function createForDuplicate(survey) {
      return create(survey, Origin.Types.DUPLICATE)
    }

    function create(survey, type) {
      var origin = new Origin()
      var subscriber = subscriberService.getSubscriber()
      var user = userService.getUser()
      var isSSR = userService.isSSR()
      origin.type = type
      origin.surveyId = survey.id
      origin.projectId = survey.projectId
      origin.subscriberId = subscriber.id
      origin.subscriberName = subscriber.name
      origin.userId = user.id
      origin.userName = user.fullname
      origin.isSSR = isSSR
      origin.env = configService.isProduction()
        ? Origin.Envs.PRODUCTION
        : Origin.Envs.STAGING
      origin.createdAt = moment()
      return origin
    }
  }
})()
