;(function() {
  'use strict'

  Controller.$inject = ["$state", "accountService", "featureService", "projectService", "subscriberService", "userService", "publicationService", "suggestionsService", "incentivesService", "subscriberPromotionService", "accountSetupDialog", "steadfastDialog", "bundleService", "uacService", "hostingCreditService", "glPrefs", "glAnalytics"];
  angular.module('app.dashboard').controller('Dashboard', Controller)

  /* @ngInject */
  function Controller(
    $state,
    accountService,
    featureService,
    projectService,
    subscriberService,
    userService,
    publicationService,
    suggestionsService,
    incentivesService,
    subscriberPromotionService,
    accountSetupDialog,
    steadfastDialog,
    bundleService,
    uacService,
    hostingCreditService,
    glPrefs,
    glAnalytics
  ) {
    var ctrl = this
    var prefs = glPrefs.create('dashboard-page')
    var prefsOptions = { user: true }
    var WelcomHiddenPrefKey = 'welcomeHiddenV2'
    var WelcomeBackDialogPrefKey = 'welcomeBackDialogClosed'

    ctrl.$onInit = onInit
    ctrl.hideWelcome = hideWelcome
    ctrl.isLoading = isLoading
    ctrl.createProject = projectService.promptCreateAndView
    ctrl.viewProject = viewProject
    ctrl.executeSuggestion = suggestionsService.execute
    ctrl.viewBundles = viewBundles
    ctrl.executeIncentive = incentivesService.execute
    ctrl.refreshCreditBalance = refreshCreditBalance

    function onInit() {
      ctrl.welcomeHidden = prefs.get(WelcomHiddenPrefKey, prefsOptions)
      ctrl.user = userService.getUser()
      ctrl.subscriber = subscriberService.getSubscriber()
      ctrl.didSignup = $state.params.didSignup
      ctrl.promotion = subscriberPromotionService.getApplied()
      ctrl.isSteadfast = ctrl.subscriber.subscriptionCode === 'steadfast'
      ctrl.isSSR = userService.isSSR()
      ctrl.isAdmin = subscriberService.isAdmin(ctrl.user.id)
      ctrl.canTopup = ctrl.isSSR || ctrl.isAdmin
      refreshCreditBalance()
      loadProjects()
      loadReports()
      loadIncentives()

      // COMMENTING this out because the link to steadfast guide is broken
      // if (ctrl.didSignup && ctrl.isSteadfast) {
      //   steadfastDialog.show()
      // }
      if (ctrl.didSignup && ctrl.promotion) {
        subscriberPromotionService
          .showThanksDialog(ctrl.promotion)
          .finally(function() {
            accountSetupDialog.show().finally(function() {
              /**
               * HACK
               * ----
               * After users sign up to a promotion we want to show
               * them they are on the free plan and what other the plans
               * have to offer (also upsell).
               * But the `enterprise` promotion is special in that the
               * backend automatically puts the user on the enterprise plan.
               * In this case, we don't want to show the user the plans because
               * they're already on the best one.
               */
              if (ctrl.promotion.code !== 'enterprise') {
                accountService.promptUpgrade({ context: 'welcome' })
              }
            })
          })
      }

      // show a blocking Welcome Back dialog if account created before 27/11/2024 and is not SSR
      var isAccountCreatedBefore = moment(ctrl.subscriber.createdAt).isBefore(
        '2024-11-27'
      )
      var welcomeBackDialogClosed = prefs.get(
        WelcomeBackDialogPrefKey,
        prefsOptions
      )
      if (isAccountCreatedBefore && !welcomeBackDialogClosed && !ctrl.isSSR) {
        accountService.showWelcomeBackDialog().then(function() {
          glAnalytics.track('dashboard', 'welcome-back-dialog-closed', {
            userId: ctrl.user.id,
            subscriberId: ctrl.subscriber.id,
          })
          prefs.set(WelcomeBackDialogPrefKey, true, prefsOptions)
        })
      }
    }

    function hideWelcome() {
      ctrl.welcomeHidden = true
      prefs.set(WelcomHiddenPrefKey, true, prefsOptions)
    }

    function isLoading() {
      return (
        !ctrl.projects ||
        (ctrl.library.loading && !ctrl.library.docs) ||
        (ctrl.store.loading && !ctrl.store.docs)
      )
    }

    function loadProjects() {
      projectService.load().then(function(projects) {
        ctrl.projects = projects
        ctrl.zeroProjects = projectService.getZeroDetails()
        var incentiveMsg = incentivesService.getInfoMessage(
          incentivesService.Types.CREATE_PROJECT
        )
        if (incentiveMsg) {
          ctrl.zeroProjects.message += '<br>' + incentiveMsg
        }
      })
    }

    function loadReports() {
      ctrl.library = publicationService.getByView('library')
      ctrl.store = publicationService.getByView('store', 'inStore eq true', {
        top: 12,
        skip: 0,
        orderby: 'createdAt desc',
      })
    }

    function loadIncentives() {
      ctrl.incentives = incentivesService.get()
    }

    function viewProject(project) {
      $state.go('project', { id: project.id })
    }

    function viewBundles() {
      bundleService.showSelector().then(function(bundle) {
        accountService.showPurchaseDialog({
          type: 'bundle',
          bundle: bundle,
        })
      })
    }

    function refreshCreditBalance() {
      if (ctrl.isLoadingBalance) return
      ctrl.isLoadingBalance = true
      hostingCreditService.refreshBalance(ctrl.subscriber).finally(function() {
        ctrl.isLoadingBalance = false
      })
    }
  }
})()
