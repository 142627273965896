;(function() {
  'use strict'

  directive.$inject = ["$log", "c3", "glUtils", "sentimentService"];
  angular.module('core.sentimentChart').directive('glSentimentChart', directive)

  /* @ngInject */
  function directive($log, c3, glUtils, sentimentService) {
    $log = $log.create('sentimentChart')

    return {
      restrict: 'E',
      templateUrl: 'sentiment-chart.template.html',
      link: PostLink,
      controller: Controller,
      controllerAs: 'sentimentChart',
      scope: {
        pageId: '=?',
      },
      bindToController: true,
    }

    function PostLink(scope, elem) {
      elem.addClass('sentiment-chart')
      scope.sentimentChart.id = 'sentiment-' + _.uniqueId()
      elem.find('figure').attr('id', scope.sentimentChart.id)
      scope.sentimentChart.setViewType(scope.sentimentChart.ViewTypes.MONTH) // init with default viewType
    }

    function Controller() {
      var vm = this

      var options
      var States = (vm.States = {
        LOADING: 'loading',
        READY: 'ready',
        ERROR: 'error',
      })
      var ViewTypes = (vm.ViewTypes = {
        DAY: 'day',
        WEEK: 'week',
        MONTH: 'month',
      })
      var TypeOptions = [
        {
          view: ViewTypes.DAY,
          start: moment().startOf('day'),
          end: moment().endOf('day'),
          resolution: sentimentService.Resolutions.HOUR,
          xTickFormat: 'hA',
          xTipFormat: 'hA',
        },
        {
          view: ViewTypes.WEEK,
          start: moment()
            .subtract(6, 'days')
            .startOf('day'),
          end: moment().endOf('day'),
          resolution: sentimentService.Resolutions.DAY,
          xTickFormat: 'ddd DD',
          xTipFormat: 'ddd DD MMM',
        },
        {
          view: ViewTypes.MONTH,
          start: moment()
            .startOf('day')
            .subtract(4, 'weeks'),
          end: moment().endOf('day'),
          resolution: sentimentService.Resolutions.DAY,
          xTickFormat: 'DD MMM',
          xTipFormat: 'ddd DD MMM',
        },
      ]
      var RANGE_FORMAT = 'YYYY-MM-DD HH:mm:ss'

      vm.setViewType = setViewType
      vm.loadChart = loadChart
      vm.hint = [
        '<b>Average Mood</b> is calculated for every period in view. <br/></br/>',
        'It displays the average mood provided by users when sending and closing a message.',
      ].join('')

      function loadChart() {
        options = _.clone(_.find(TypeOptions, { view: vm.viewType }))
        options.pageId = vm.pageId

        setState(States.LOADING)
        sentimentService.get(options).then(
          function(sentiment) {
            vm.sentiment = sentiment

            var columns = makeColumns(sentiment, options)

            vm.config = makeConfig(columns, options)
            setState(States.READY)

            requestAnimationFrame(function() {
              vm.chart = c3.generate(vm.config)
            })
          },
          function() {
            setState(States.ERROR)
          }
        )
      }

      function setViewType(viewType) {
        if (viewType) {
          vm.viewType = viewType
          loadChart()
        }
      }

      function makeColumns(sentiment, options) {
        var dates = ['Date']
        var moods = ['Mood']
        var range = glUtils.getDateRange(
          options.start,
          options.end,
          options.resolution,
          null,
          RANGE_FORMAT
        )

        // re-map range to moment dates (sif!)
        range = _.map(range, function(value) {
          var date = moment(value)
          return {
            date: date,
            key: date.format(sentimentService.KEY_FORMAT),
            timeseriesValue: value,
          }
        })

        _.each(range, function(data) {
          var mood = moodForKey(data.key)
          dates.push(data.timeseriesValue)
          moods.push(mood)
        })

        return [dates, moods]
      }

      function makeConfig(columns, options) {
        return {
          bindto: '#' + vm.id,
          data: {
            x: 'Date',
            xFormat: '%Y-%m-%d %H:%M:%S',
            columns: columns,
            type: 'bar',
            color: function(x, data) {
              return colorForMood(data.value)
            },
          },
          axis: {
            x: {
              type: 'timeseries',
              tick: {
                format: function(x) {
                  return moment(x).format(options.xTickFormat)
                },
              },
            },
            y: {
              show: false,
              min: -3,
              max: 3,
              tick: {
                values: [3, 2, 1, 0, -1, -2, -3],
                format: emptyString,
              },
            },
          },
          grid: {
            y: {
              lines: [{ value: 0, class: 'sentiment-chart-line' }],
            },
          },
          tooltip: {
            contents: getTooltip,
          },
          legend: {
            show: false,
          },
        }
      }

      function setState(state) {
        vm.state = state
      }

      function moodForKey(key) {
        var doc = _.find(vm.sentiment, { key: key })
        return doc ? doc.averageMoodOffset : 0
      }

      function emptyString() {
        return ''
      }

      function iconClassForMood(mood) {
        switch (mood) {
          case -3:
            return 'gi gi-mood-6'
          case -2:
            return 'gi gi-mood-5'
          case -1:
            return 'gi gi-mood-4'
          case 1:
            return 'gi gi-mood-3'
          case 2:
            return 'gi gi-mood-2'
          case 3:
            return 'gi gi-mood-1'
        }
      }

      function colorForMood(mood) {
        return mood > 0 ? '#2ab27b' : '#eb4d5c'
      }

      function labelForMood(mood) {
        switch (mood) {
          case -3:
            return 'Very Angry'
          case -2:
            return 'Angry'
          case -1:
            return 'Meh'
          case 1:
            return 'Neutral'
          case 2:
            return 'Happy'
          case 3:
            return 'Very Happy'
          default:
            return 'Unknown'
        }
      }

      function getTooltip(d) {
        var value = d[0].value
        var date = moment(d[0].x)

        if (value === 0) {
          return [
            '<div class="sentiment-chart-tooltip">',
            '<div class="date">' + date.format(options.xTipFormat) + '</div>',
            '<span class="text -zero">Neutral</span>',
            '</div>',
          ].join('')
        }

        return [
          '<div class="sentiment-chart-tooltip">',
          '<div class="date">' + date.format(options.xTipFormat) + '</div>',
          '<span class="' + iconClassForMood(d[0].value) + '"></span>',
          '<span class="text">' + labelForMood(d[0].value) + '</span>',
          '</div>',
        ].join('')
      }
    }
  }
})()
