;(function() {
  'use strict'

  Service.$inject = ["$q", "$rootScope", "glAnalytics", "featureService", "suggestionsResource", "accountSetupDialog", "publicationService", "subscriberService", "userService"];
  angular.module('app.core').service('suggestionsService', Service)

  /* @ngInject */
  function Service(
    $q,
    $rootScope,
    glAnalytics,
    featureService,
    suggestionsResource,
    accountSetupDialog,
    publicationService,
    subscriberService,
    userService
  ) {
    var self = this
    var cache = {
      value: [],
      zero: false, // we dont know if there are zero until after a refresh
      loading: false,
    }

    var Suggestions = {
      COMPLETE_PROFILE: 'COMPLETE_PROFILE',
      RATE_PUBLICATION: 'RATE_PUBLICATION',
    }

    var SuggestionsMap = {
      COMPLETE_SUBSCRIBER_PROFILE: Suggestions.COMPLETE_PROFILE,
      COMPLETE_USER_PROFILE: Suggestions.COMPLETE_PROFILE,
      RATE_PUBLICATION: Suggestions.RATE_PUBLICATION,
    }

    $rootScope.$on(subscriberService.Events.CHANGE, refresh)
    $rootScope.$on(publicationService.Events.RATED, refresh)

    self.get = get
    self.refresh = refresh
    self.execute = execute

    function get() {
      refresh()
      return cache
    }

    function refresh() {
      if (!userService.isLoggedIn()) {
        _.remove(cache.value)
        cache.zero = true
        return $q.resolve()
      }
      cache.loading = true
      return $q(function(resolve, reject) {
        suggestionsResource
          .get({ userId: userService.getUser().id })
          .success(function(x, suggestions) {
            populate(suggestions)
            cache.loading = false
            cache.zero = cache.value.length === 0
            resolve()
          })
          .error(function() {
            cache.loading = false
            reject()
          })
      })
    }

    function populate(suggestions) {
      _.remove(cache.value)
      _.chain(suggestions)
        .map(function(suggestion) {
          return SuggestionsMap[suggestion]
        })
        .uniq() // why are these not unique?
        .map(function(suggestion) {
          return _.find(getSuggestionInfo(), { type: suggestion })
        })
        .orderBy('order')
        .each(function(suggestion) {
          cache.value.push(suggestion)
        })
        .value()
    }

    function getSuggestionInfo() {
      return [
        {
          type: Suggestions.COMPLETE_PROFILE,
          order: 0,
          icon: 'person',
          title: 'Tailored Content',
          message: 'Get tailored content based on your country and industry.',
          context: 'complete-profile',
          action: 'Complete Profile',
          onClick: accountSetupDialog.show,
        },
        {
          type: Suggestions.RATE_PUBLICATION,
          order: 1,
          icon: 'publication',
          title: 'Unrated Reports',
          message: "Don't forget to rate the reports in your library.",
          context: 'rate-publication',
          action: 'Go to Library',
          state: 'library',
        },
      ]
    }

    function execute(suggestion) {
      glAnalytics.track('suggestion', 'clicked', suggestion.context)
      suggestion.onClick && suggestion.onClick()
    }
  }
})()
