;(function() {
  'use strict'

  KitsEditController.$inject = ["$log", "$state", "glTips", "kitsService", "userService"];
  angular
    .module('app.kits-edit')
    .controller('KitsEditController', KitsEditController)

  /* @ngInject */
  function KitsEditController($log, $state, glTips, kitsService, userService) {
    $log = $log.create('KitsEditController')

    var ctrl = this

    // Constants
    ctrl.States = {
      LOADING: 'loading',
      ERROR: 'error',
      READY: 'ready',
      SUCCESS: 'success',
    }

    // Methods
    ctrl.setState = setState
    ctrl.isState = isState

    activate()

    /**
     * Initialise
     */
    function activate() {
      ctrl.setState(ctrl.States.LOADING)
      if ($state.params.cloneKitId) {
        ctrl.kitId = 'new'
        cloneKit($state.params.cloneKitId)
      } else {
        ctrl.kitId = $state.params.kitId === 'new' ? null : $state.params.kitId
        getKit()
      }
    }

    /**
     * @name getKit
     * @desc get the current kit by Id
     */
    function getKit() {
      kitsService
        .getById(ctrl.kitId)
        .then(getSuccess)
        .catch(getError)
    }

    /**
     * @name cloneKit
     * @desc clone the supplied kit Id
     */
    function cloneKit(kitId) {
      kitsService
        .getById(kitId)
        .then(function(kit) {
          sanitiseKit(kit)
          getSuccess(kit)
        })
        .catch(getError)
    }

    function getSuccess(data) {
      ctrl.kit = data
      if (!ctrl.kitId) {
        ctrl.kit.ownerId = userService.getSubscriberId()
      }
      ctrl.setState(ctrl.States.READY)
      glTips.ready()
    }

    function getError() {
      ctrl.setState(ctrl.States.ERROR)
      $log.error('could not load templates')
    }

    /**
     * @name Set State
     * @desc change state of this view
     * @param {String} state The State to change to
     */
    function setState(state) {
      ctrl.state = state
    }

    /**
     * @name Is State
     * @desc check if we are on a specified state
     * @param {String} state A State value
     */
    function isState(state) {
      return ctrl.state === state
    }

    /**
     * @name sanitiseKit
     * @desc sanitise the kit so there is no cross contamination between the original and cloned version
     * @param {Object} kit The kit to be sanitised
     */
    function sanitiseKit(kit) {
      kit.id = null
      kit.name = 'Clone of...' + kit.name
      kit.state = 'DRAFT'
      kit.status = 'Draft'
      kit.statusClass = 'draft'
    }
  }
})()
