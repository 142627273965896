;(function() {
  'use strict'

  config.$inject = ["$stateProvider"];
  angular.module('app.kits', []).config(config)

  /* @ngInject */
  function config($stateProvider) {
    $stateProvider.state('kits', {
      url: '/kits?promo',
      parent: 'portal',
      controller: 'Kits',
      controllerAs: '$ctrl',
      templateUrl: 'kits.html',
      title: 'Kits',
      public: true,
    })

    $stateProvider.state('studio-kits-deprecated', {
      url: '/studio/kits?promo',
      redirectTo: 'kits',
    })
  }
})()
