;(function() {
  'use strict'

  angular.module('core.gSelect').component('gSelect', {
    templateUrl: 'g-select.html',
    controller: Controller,
    bindings: {
      align: '@',
      placeholder: '@',
      optionsSrc: '<options',
      selectedSrc: '<selected',
      onChange: '&',
      multi: '<',
      multiFormat: '&',
      isSmall: '<small',
      isDisabled: '<disabled',
      isCustomField: '<custom',
      onOptionAdded: '&',
    },
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onChanges = onChanges

    ctrl.onOpen = onOpen
    ctrl.onClose = onClose
    ctrl.select = select
    ctrl.addCustom = addCustom
    ctrl.isSelected = isSelected

    function onChanges(bindings) {
      if (
        bindings.selectedSrc &&
        bindings.selectedSrc.currentValue !== bindings.selectedSrc.previousValue
      ) {
        ctrl.selected = []
        if (_.isArray(ctrl.selectedSrc)) {
          _.each(ctrl.selectedSrc, function(value) {
            ctrl.selected.push(value)
          })
        } else {
          ctrl.selected.push(ctrl.selectedSrc)
        }

        updateValue()
      }

      if (
        bindings.optionsSrc &&
        bindings.optionsSrc.currentValue !== bindings.optionsSrc.previousValue
      ) {
        // console.log('optionsSrc changed', bindings.optionsSrc);

        // the options array can contain strings or objects
        // strings treat value and label the same, eg ['Foo','Bar']
        // objects have more customisation using the signature { value, label, condition }
        // eg: [{ value: 'coke', label: 'Coke', condition: fn }]
        ctrl.options = _.cloneDeep(ctrl.optionsSrc)

        // all g-select logic adheres to the object structure, so if they are strings
        // we convert them into objects for consistency
        if (ctrl.options.length && _.isString(ctrl.options[0])) {
          ctrl.stringOptionSrc = true
          ctrl.options = _.map(ctrl.options, function(option) {
            return { value: option, label: option }
          })
        }

        updateValue()
      }
    }

    function updateValue() {
      var value
      var selectedOptions = _.filter(ctrl.options, function(option) {
        return isSelected(option.value)
      })
      if (ctrl.multi) {
        if (ctrl.multiFormat) {
          value = ctrl.multiFormat({ $options: selectedOptions })
        } else {
          value = _.map(selectedOptions, 'value').join(', ')
        }
      } else {
        value = selectedOptions[0] && selectedOptions[0].label
      }
      ctrl.value = value
    }

    function onOpen() {
      ctrl.isOpen = true
    }

    function onClose() {
      ctrl.isOpen = false
      if (ctrl.custom) {
        addCustom()
      }
    }

    function select(value) {
      var option = ctrl.options.find(function(option) {
        return option.value === value
      })
      if (option && option.disabled) return
      var output
      if (ctrl.multi) {
        if (!ctrl.selected) {
          ctrl.selected = []
        }
        if (isSelected(value)) {
          // must use function, _.remove(ctrl.selected, Number) fails
          _.remove(ctrl.selected, function(selected) {
            return selected === value
          })
        } else {
          ctrl.selected.push(value)
        }
        output = _.map(ctrl.selected) // re-reference
      } else {
        ctrl.selected = [value]
        output = value
      }
      updateValue()
      ctrl.onChange({ $value: output })
    }

    function addCustom() {
      var option
      if (ctrl.stringOptionSrc) {
        option = ctrl.custom
      } else {
        option = {
          value: ctrl.custom,
          label: ctrl.custom,
        }
      }
      ctrl.onOptionAdded({ $option: option })

      // select the new one
      select(ctrl.custom)

      // reset
      ctrl.custom = null
      ctrl.menu.close()
    }

    function isSelected(value) {
      return _.includes(ctrl.selected, value)
    }
  }
})()
