;(function() {
  'use strict'

  angular.module('app.core').component('inlineSteps', {
    controller: Controller,
    templateUrl: 'inline-steps.html',
    bindings: {
      steps: '<',
    },
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit

    // TODO: make step labels more flexible to varying text lengths
    function onInit() {
      // ...
    }
  }
})()
