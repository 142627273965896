;(function() {
  'use strict'

  Controller.$inject = ["glAnalytics", "glAuthService", "Currency", "StateFactory", "userService", "subscriberService", "websiteService", "glEmail", "accountService", "countryService", "currencyService"];
  angular.module('app.core').component('accountAuth', {
    controller: Controller,
    templateUrl: 'account-auth.html',
    bindings: {
      mode: '<',
      context: '<',
      onDone: '&',
      onCancel: '&',
    },
  })

  /* @ngInject */
  function Controller(
    glAnalytics,
    glAuthService,
    Currency,
    StateFactory,
    userService,
    subscriberService,
    websiteService,
    glEmail,
    accountService,
    countryService,
    currencyService
  ) {
    var ctrl = this

    var infoByContext = {
      publication: {
        signupTitle: 'Get this report',
        signupMessage: 'Create a free Glow account to access this report.',
      },
      generic: {
        signupTitle: 'Create an account',
        signupMessage:
          'Create a free Glow account to access tools and insights that build capability',
      },
    }

    ctrl.state = new StateFactory(['signup', 'login', 'reset', 'resetSuccess'])
    ctrl.$onInit = onInit
    ctrl.setCountryAndCurrency = setCountryAndCurrency
    ctrl.isValidSignup = isValidSignup
    ctrl.signup = signup
    ctrl.isValidLogin = isValidLogin
    ctrl.login = login
    ctrl.isValidReset = isValidReset
    ctrl.reset = reset

    function onInit() {
      _.defaults(ctrl, {
        context: 'generic',
      })
      ctrl.fields = {}
      ctrl.state[ctrl.mode || 'signup']()
      ctrl.termsUrl = websiteService.getTermsUrl()
      ctrl.info = infoByContext[ctrl.context]
      ctrl.countries = countryService.getSelectOptions()
    }

    function setCountryAndCurrency(countryId) {
      ctrl.fields.country = countryService.getById(countryId)
      var localCurrency = currencyService.getByCountry(ctrl.fields.country.cc)
      var currency =
        localCurrency || currencyService.getByCode(Currency.Codes.USD)
      ctrl.fields.currency = currency.code
    }

    function isValidSignup() {
      if (!ctrl.fields.firstName) {
        return false
      }
      if (!ctrl.fields.lastName) {
        return false
      }
      if (!ctrl.fields.country) {
        return false
      }
      if (!glEmail.isValid(ctrl.fields.email)) {
        return false
      }
      if (!ctrl.fields.password) {
        return false
      }
      if (!ctrl.fields.termsAgreed) {
        return false
      }
      return true
    }

    function signup() {
      if (ctrl.isLoading || !ctrl.isValidSignup()) {
        return
      }
      ctrl.user = {
        fullname: ctrl.fields.firstName + ' ' + ctrl.fields.lastName,
        email: ctrl.fields.email,
        password: ctrl.fields.password,
        registrationContext: {
          type: 'PLAN',
          termsAgreed: ctrl.fields.termsAgreed,
          // referrerCode: ctrl.options.referrerCode,
          // analysisPackId: ctrl.options.analysisPackId,
          // kitId: ctrl.options.kitId,
          // utm_source: ctrl.options.utm_source,
          // utm_medium: ctrl.options.utm_medium,
          // utm_campaign: ctrl.options.utm_campaign,
          // utm_term: ctrl.options.utm_term,
          // utm_content: ctrl.options.utm_content,
        },
      }
      ctrl.subscriber = {
        name: ctrl.fields.firstName + ' ' + ctrl.fields.lastName,
        country: ctrl.fields.country,
        // industry: ctrl.options.industry,
        billing: {
          currency: ctrl.fields.currency,
        },
        // contentGroupIds: ctrl.options.contentGroupIds,
        businessType: null,
        // subscriptionCode: ctrl.options.promo
      }
      ctrl.signupErrors = null
      ctrl.isLoading = true
      accountService
        .signup(ctrl.user, ctrl.subscriber)
        .then(function() {
          ctrl.onDone({ $outcome: 'signed-up' })
          glAnalytics.track('signup', 'signup-completed')
        })
        .catch(function(error) {
          var message = 'There was an issue signing up. Please try again.'
          if (error.status === 409) {
            message = 'That email is already taken.'
          }
          ctrl.signupErrors = [message]
        })
        .finally(function() {
          ctrl.isLoading = false
        })
    }

    function loginUser(data, permittedRoles) {
      return userService.login(data, permittedRoles)
    }

    function loadSubscriber() {
      var subscriberId = userService.getSubscriberId()
      return subscriberService.loadSubscriber(subscriberId)
    }

    function isValidLogin() {
      if (!glEmail.isValid(ctrl.fields.email)) {
        return false
      }
      if (!ctrl.fields.password) {
        return false
      }
      return true
    }

    function login() {
      if (ctrl.isLoading || !ctrl.isValidLogin()) {
        return
      }

      ctrl.loginErrors = null
      ctrl.isLoading = true
      var credentials = {
        email: ctrl.fields.email,
        password: ctrl.fields.password,
      }
      var permittedRoles = [
        glAuthService.Roles.SUBSCRIBER,
        glAuthService.Roles.SUBSCRIBER_SUPPORT,
      ]
      loginUser(credentials, permittedRoles)
        .then(loadSubscriber)
        .then(function() {
          ctrl.onDone({ $outcome: 'logged-in' })
          glAnalytics.track('signup', 'login-success')
        })
        .catch(function() {
          ctrl.loginErrors = [
            'There was an issue logging in. Please try again.',
          ]
        })
        .finally(function() {
          ctrl.isLoading = false
        })
    }

    function isValidReset() {
      if (!glEmail.isValid(ctrl.fields.email)) {
        return false
      }
      if (!ctrl.fields.newPassword) {
        return false
      }
      return true
    }

    function reset() {
      ctrl.isLoading = true
      userService
        .resetPassword(ctrl.fields.email, ctrl.fields.newPassword)
        .then(function() {
          ctrl.state.resetSuccess()
        })
        .catch(function() {
          ctrl.resetErrors = ['There was an issue resetting your password.']
        })
        .finally(function() {
          ctrl.isLoading = false
        })
    }
  }
})()
