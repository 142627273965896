;(function() {
  'use strict'

  Controller.$inject = ["glUtils", "glPrefs", "userService", "projectService", "surveyService", "surveyServiceNext"];
  angular.module('app.core').component('importFrom', {
    controller: Controller,
    templateUrl: 'import-from.html',
    bindings: {
      onSelect: '&',
    },
  })

  /* @ngInject */
  function Controller(
    glUtils,
    glPrefs,
    userService,
    projectService,
    surveyService,
    surveyServiceNext
  ) {
    var ctrl = this
    var prefs = glPrefs.create('import-from')

    ctrl.$onInit = onInit
    ctrl.selectProject = selectProject
    ctrl.selectSurvey = selectSurvey
    ctrl.getViewsPlaceholder = getViewsPlaceholder
    ctrl.add = add

    function onInit() {
      loadProjects().then(hydrate)
    }

    function persist() {
      // for convenience remember the project+survey selected
      // for this subscriber.
      // we also use a circular buffer based on usage to prevent
      // this growing too large. max 3 subscribers.
      var subscriberId = userService.getSubscriberId()
      var entries = prefs.get('entries')
      if (!entries) entries = []
      var entry = _.remove(
        entries,
        entry => entry.subscriberId === subscriberId
      )[0]
      if (!entry) entry = { subscriberId: subscriberId }
      entry.surveyId = ctrl.surveyId || null
      entry.projectId = ctrl.projectId || null
      entries.push(entry)
      if (entries.length > 3) entries.shift()
      console.log('entries', entries)
      prefs.set('entries', entries)
    }

    function hydrate() {
      var subscriberId = userService.getSubscriberId()
      var entries = prefs.get('entries')
      if (!entries) return
      var entry = entries.find(entry => entry.subscriberId === subscriberId)
      if (!entry) return
      if (entry.projectId) selectProject(entry.projectId)
      if (entry.surveyId) selectSurvey(entry.surveyId)
    }

    function loadProjects() {
      return projectService.load().then(function(projects) {
        projects = _.orderBy(
          projects,
          function(project) {
            return project.createdAt.unix()
          },
          'desc'
        )
        ctrl.projects = projects
        ctrl.projectOptions = projects.map(function(project) {
          return {
            label: project.name,
            value: project.id,
          }
        })
      })
    }

    function selectProject(projectId) {
      ctrl.projectId = projectId
      ctrl.surveyOptions = null
      ctrl.surveyId = null
      ctrl.survey = null
      ctrl.viewOptions = null
      ctrl.viewIds = null
      persist()
      return surveyService.getByProject(projectId).then(function(surveys) {
        _.remove(surveys, { isArchived: true })
        surveys = _.orderBy(
          surveys,
          function(survey) {
            return survey.createdAt
          },
          'desc'
        )
        ctrl.surveyOptions = surveys.map(function(survey) {
          return {
            label: survey.reference || survey.title,
            value: survey.id,
          }
        })
      })
    }

    function selectSurvey(surveyId) {
      ctrl.surveyId = surveyId
      ctrl.viewOptions = null
      ctrl.viewIds = null
      persist()
      surveyServiceNext.get(surveyId).then(function(survey) {
        ctrl.survey = survey
        ctrl.viewOptions = survey.views.map(function(view) {
          if (view.isQuestion()) {
            var question = view.value
            return {
              label: question.getTitleLabel({ number: true }),
              value: question.id,
              abbrev: 'Q' + question.getNumber(),
            }
          }
          if (view.isSection()) {
            var section = view.value
            return {
              label: section.getTitleLabel(),
              value: section.id,
              abbrev: 'S' + section.getNumber(),
            }
          }
        })
      })
    }

    function add() {
      // clone and refresh all ID's
      var survey = ctrl.survey.clone().refresh()

      // find the views we selected (note we use idOld)
      var views = survey.views.filter(function(view) {
        var id = view.value.idOld
        return ctrl.viewIds.includes(id)
      })

      // logic and groups might reference views outside of the set we are
      // taking. this function tells us if any are!
      function isOutside(viewIds) {
        return _.some(viewIds, function(viewId) {
          if (!viewId) return false
          return !views.find(function(view) {
            var id = view.value.id
            return id === viewId
          })
        })
      }

      // if any logic references something outside of our set, we
      // will just remove it because it's no longer relevant/useful.
      views.forEach(function(view) {
        var logic = view.value.logic
        _.remove(logic, function(logic) {
          let shouldRemove = false
          logic.conditions.forEach(function(condition) {
            var viewIds = [condition.questionId]
            if (isOutside(viewIds)) {
              shouldRemove = true
            }
          })
          logic.actions.forEach(function(action) {
            var viewIds = [action.questionId, action.sectionId]
            if (isOutside(viewIds)) {
              shouldRemove = true
            }
          })
          return shouldRemove
        })
      })

      // any view-groups that reference views we are copying will be
      // taken with us.
      // we also remove any view-group views that are outside the set (tree-shaking)
      var viewGroups = []
      survey.viewGroups.forEach(function(viewGroup) {
        // first remove views from this view-group that we aren't taking with us
        _.remove(viewGroup.viewIds, function(viewId) {
          return !views.find(function(view) {
            return view.value.id === viewId
          })
        })
        // do the same with shuffles
        _.remove(viewGroup.shuffleIds, function(viewId) {
          return !views.find(function(view) {
            return view.value.id === viewId
          })
        })
        // if we still have some views, we'll take it with us!
        if (viewGroup.viewIds.length) {
          viewGroups.push(viewGroup)
        }
        // note: for some fucked up reason the ViewGroup model stores
        // options/shuffleOptions ON THE FACTORY so who bloody knows
        // if this is going to break when it gets attached to the new survey
      })

      // any shuffle-groups that reference view-groups we are taking with us
      // will also be taken with us.
      var shuffleGroups = []
      survey.shuffleGroups.forEach(function(shuffleGroup) {
        // first remove view groups that we aren't taking with us
        _.remove(shuffleGroup.viewGroupIds, function(viewGroupId) {
          return !viewGroups.find(function(viewGroup) {
            return viewGroup.id === viewGroupId
          })
        })
        // if the shuffle group still references view groups we need, take it with us!
        // but only if the shuffle group is useful (>1)
        if (shuffleGroup.viewGroupIds.length > 1) {
          shuffleGroups.push(shuffleGroup)
        }
      })

      ctrl.onSelect({
        $views: views,
        $viewGroups: viewGroups,
        $shuffleGroups: shuffleGroups,
      })
    }

    function getViewsPlaceholder(options) {
      return _.map(options, 'abbrev').join(', ')
    }
  }
})()
