;(function() {
  'use strict'

  Controller.$inject = ["$attrs", "PaymentMethod"];
  angular.module('app.core').component('paymentMethod', {
    controller: Controller,
    templateUrl: 'payment-method.html',
    bindings: {
      paymentMethod: '<',
      onAdd: '&',
      onEdit: '&',
      onRemove: '&',
    },
  })

  /* @ngInject */
  function Controller($attrs, PaymentMethod) {
    var ctrl = this

    ctrl.$onChanges = onChanges

    function onChanges() {
      if (ctrl.paymentMethod) {
        ctrl.cardImage = getCardImage(ctrl.paymentMethod.brand)
        ctrl.label = ctrl.paymentMethod.maskedCardNumber
        ctrl.icon = 'edit'
        ctrl.onClick = ctrl.onEdit
        if (ctrl.paymentMethod.isExpired) {
          ctrl.message =
            'Expired ' + ctrl.paymentMethod.expDate.format('DD MMM YYYY')
        } else if (ctrl.paymentMethod.expiresSoon) {
          ctrl.message =
            'Expires soon ' + ctrl.paymentMethod.expDate.format('DD MMM YYYY')
        }
      } else {
        ctrl.cardImage = getCardImage('Unknown')
        ctrl.label = 'None added'
        ctrl.icon = 'add'
        ctrl.onClick = ctrl.onAdd
      }
      ctrl.showAddEdit = $attrs.onAdd || $attrs.onEdit
      ctrl.showRemove = ctrl.paymentMethod && $attrs.onRemove
    }

    function getCardImage(brand) {
      var Brands = PaymentMethod.Brands
      switch (brand) {
        case Brands.visa:
          return 'img/card-visa.png'
        case Brands.mastercard:
          return 'img/card-mastercard.png'
        case Brands.americanExpress:
          return 'img/card-american-express.png'
        default:
          return 'img/card-unknown.png'
      }
    }
  }
})()
