;(function() {
  'use strict'

  angular
    .module('core.interaction')
    .directive('interactionReply', interactionReplyDirective)

  /* @ngInject */
  function interactionReplyDirective() {
    controller.$inject = ["$scope", "interactionsService", "glToast", "userService"];
    return {
      restrict: 'E',
      templateUrl: 'interaction-reply.template.html',
      controller: controller,
    }

    /* @ngInject */
    function controller($scope, interactionsService, glToast, userService) {
      $scope.replyActive = false
      $scope.feedbackReply = {
        message: '',
      }

      $scope.user = userService.getUser()
      $scope.isSSR = userService.isSSR

      // methods
      $scope.replyFocus = replyFocus
      $scope.replyBlur = replyBlur
      $scope.sendReply = sendReply
      $scope.getClosedMsg = getClosedMsg

      /**
       * set reply box active on focus
       */
      function replyFocus() {
        $scope.replyActive = true
      }

      /**
       * set reply box inactive if textarea blurred and no valid message
       */
      function replyBlur() {
        if (
          !$scope.feedbackReply.message ||
          ($scope.feedbackReply.message &&
            $scope.feedbackReply.message.length < 1)
        ) {
          $scope.replyActive = false
        }
      }

      /*
             * Submit a reply to feedback
             */
      function sendReply(interaction) {
        var reply = {
          comment: $scope.feedbackReply.message,
          type: 's',
          creator: {
            id: $scope.user.id,
            name: $scope.user.fullname.split(' ')[0],
          },
          createdAt: moment().toISOString(),
        }

        $scope.$parent.processing = true

        if (interaction.type === 'followup') {
          sendFollowupReply()
        } else {
          sendCommentReply()
        }

        function sendFollowupReply() {
          if (!interaction.comments) {
            // need to submit followup reply to create thread
            interaction.comments = [reply]

            interactionsService
              .submitFollowupReply(interaction.id, $scope.feedbackReply.message)
              .then(followupSuccess, sendFailure)
          } else {
            // already received a reply so add comment
            interaction.comments.push(reply)

            interactionsService
              .submitReply(
                interaction.reply.replyPostId,
                $scope.feedbackReply.message
              )
              .then(replySuccess, sendFailure)
          }
        }

        function sendCommentReply() {
          interaction.comments.push(reply)
          interactionsService
            .submitReply(
              interaction.relatedObjectId,
              $scope.feedbackReply.message
            )
            .then(replySuccess, sendFailure)
        }

        function followupSuccess(data) {
          // add post ID to the interaction for any more replies before interaction is re-fetched
          interaction.reply = {
            replyPostId: data.postId,
          }
          replySuccess()
        }

        function replySuccess() {
          $scope.$parent.processing = false
          $scope.feedbackReply.message = ''
          glToast.show('Reply sent.')
        }

        function sendFailure() {
          $scope.$parent.processing = false
          $scope.interaction.comments.pop()
          glToast.show('Reply could not be sent.')
        }
      }

      /**
       * returns a user-friendly msg about this post being closed
       */
      function getClosedMsg() {
        var isClosed = $scope.interaction.status === 'CLOSED'
        // Older interaction don't have requestedBy. Anonymity is determined by username
        var fromAnonymous = $scope.interaction.requestedBy
          ? !$scope.interaction.requestedBy.email
          : !$scope.interaction.username

        if (isClosed && fromAnonymous) {
          return 'This feedback is anonymous.'
        }
        return 'This feedback has been closed.'
      }
    }
  }
})()
