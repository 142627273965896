;(function() {
  'use strict'

  errorConfig.$inject = ["$stateProvider"];
  angular.module('app.errors', []).config(errorConfig)

  /* @ngInject */
  function errorConfig($stateProvider) {
    $stateProvider
      .state('error-notfound', {
        url: '/404',
        templateUrl: 'error-404.template.html',
        controller: 'ErrorsController',
        title: 'Error',
        public: true,
      })

      .state('error-maintenance', {
        // TODO: implement maintenance page
        url: '/500?csid',
        templateUrl: 'error-500.template.html',
        controller: 'ErrorsController',
        title: 'Error',
        public: true,
      })

      .state('error-server', {
        url: '/500?csid',
        templateUrl: 'error-500.template.html',
        controller: 'ErrorsController',
        title: 'Error',
        public: true,
      })
  }
})()
