;(function() {
  'use strict'

  Controller.$inject = ["$state", "glDialog", "userService", "StateFactory"];
  angular.module('app.login').controller('PasswordResetController', Controller)

  /* @ngInject */
  function Controller($state, glDialog, userService, StateFactory) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.reset = reset

    function onInit() {
      ctrl.state = new StateFactory([
        'reset',
        'resetSuccess',
        'redeem',
        'redeemSuccess',
        'redeemError',
      ])
      ctrl.token = $state.params.token
      if (!ctrl.token) {
        ctrl.state.reset()
      } else {
        ctrl.state.redeem()
        userService
          .updatePasswordReset(ctrl.token)
          .then(ctrl.state.redeemSuccess)
          .catch(ctrl.state.redeemError)
      }
    }

    function reset() {
      if (ctrl.processing || !ctrl.form.$valid) {
        return
      }

      ctrl.processing = true
      userService
        .resetPassword(ctrl.email, ctrl.password)
        .then(ctrl.state.resetSuccess)
        .catch(function() {
          glDialog.alert(
            null,
            'There was an issue resetting your password. Please try again.'
          )
        })
        .finally(function() {
          ctrl.processing = false
        })
    }
  }
})()
