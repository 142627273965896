;(function() {
  'use strict'

  Service.$inject = ["$q", "$log", "api", "glAnalytics", "glUtils", "glToast", "glAuthService", "configService", "channelService", "surveyReportsResource", "surveyService", "profileService", "reportModService", "themeService", "reportLinkService", "glSurveyUtils", "filterSetMongoService", "numericService", "LocationSet", "Pipes", "colors", "ReportSettings", "ThemeContext", "responseTypeService", "reportMediaBtn", "Section", "Survey", "Video"];
  angular.module('glow.reporting').factory('surveyReport', Service)

  /* @ngInject */
  function Service(
    $q,
    $log,
    api,
    glAnalytics,
    glUtils,
    glToast,
    glAuthService,
    configService,
    channelService,
    surveyReportsResource,
    surveyService,
    profileService,
    reportModService,
    themeService,
    reportLinkService,
    glSurveyUtils,
    filterSetMongoService,
    numericService,
    LocationSet,
    Pipes,
    colors,
    ReportSettings,
    ThemeContext,
    responseTypeService,
    reportMediaBtn,
    Section,
    Survey,
    Video
  ) {
    $log = $log.create('surveyReport')

    var ageChoices = [
      {
        option: 'Under 14',
        order: 1,
        ageRange: { lower: 0, upper: 13 },
        idSuffix: '14',
      },
      {
        option: '14 - 19',
        order: 2,
        ageRange: { lower: 14, upper: 19 },
        idSuffix: '19',
      },
      {
        option: '20 - 24',
        order: 3,
        ageRange: { lower: 20, upper: 24 },
        idSuffix: '24',
      },
      {
        option: '25 - 34',
        order: 4,
        ageRange: { lower: 25, upper: 34 },
        idSuffix: '34',
      },
      {
        option: '35 - 44',
        order: 5,
        ageRange: { lower: 35, upper: 44 },
        idSuffix: '44',
      },
      {
        option: '45 - 54',
        order: 6,
        ageRange: { lower: 45, upper: 54 },
        idSuffix: '54',
      },
      {
        option: '55 - 64',
        order: 7,
        ageRange: { lower: 55, upper: 64 },
        idSuffix: '64',
      },
      {
        option: '65 - 74',
        order: 8,
        ageRange: { lower: 65, upper: 74 },
        idSuffix: '74',
      },
      {
        option: '75 - 84',
        order: 9,
        ageRange: { lower: 75, upper: 84 },
        idSuffix: '84',
      },
      {
        option: '85+',
        order: 10,
        ageRange: { lower: 85, upper: 200 },
        idSuffix: '85',
      },
    ]

    var chartConfigs = [
      {
        questionType: 'choice',
        isMulti: false,
        default: 'bar',
        types: ['bar', 'pie', 'radar'],
      },
      {
        questionType: 'choice',
        isMulti: true,
        default: 'bar',
        types: ['bar', 'radar'],
      },
      {
        questionType: 'sex',
        default: 'bar',
        types: ['bar', 'pie'],
      },
      {
        questionType: 'agerange',
        default: 'bar',
        types: ['bar', 'pie'],
      },
      {
        questionType: 'scale',
        default: 'bar',
        types: ['gauge', 'pie', 'bar', 'radar'],
      },
      { questionType: 'mood', default: 'doughnut', types: ['doughnut'] },
      {
        questionType: 'singletext',
        default: 'cloud',
        types: ['cloud'],
      },
      {
        questionType: 'text',
        default: 'cloud',
        types: ['cloud'],
      },
      {
        questionType: 'scan',
        default: 'cloud',
        types: ['cloud'],
      },
      {
        questionType: 'placesnearme',
        default: 'pointmap',
        types: ['pointmap'],
      },
      { questionType: 'nps', default: 'doughnut', types: ['doughnut'] },
      { questionType: 'image', default: 'gallery', types: ['gallery'] },
      {
        questionType: 'location',
        default: 'heatmap',
        types: ['heatmap'],
      },
      {
        questionType: 'rank',
        default: 'bar',
        types: ['bar', 'pie', 'radar'],
      },
      {
        questionType: 'rating',
        default: 'bar',
        types: ['bar', 'pie', 'radar'],
      },
      {
        questionType: 'numeric',
        default: 'bar',
        types: ['bar', 'pie', 'radar'],
      },
      {
        questionType: 'matrix',
        isMulti: false,
        default: 'bar',
        types: ['bar', 'pie', 'radar'],
      },
      {
        questionType: 'matrix',
        isMulti: true,
        default: 'bar',
        types: ['bar', 'radar'],
      },
      {
        questionType: 'constantsum',
        isMatrix: false,
        default: 'bar',
        types: ['bar', 'pie', 'radar'],
      },
      {
        questionType: 'constantsum',
        isMatrix: true,
        default: 'bar',
        types: ['bar', 'pie', 'radar'],
      },
      { questionType: 'score', default: 'doughnut', types: ['doughnut'] },
      {
        questionType: 'hiddenvariables',
        default: 'bar',
        types: ['bar', 'pie', 'radar'],
      },
    ]

    // Defaults
    var DefaultOptions = {
      canFilter: true,
      canFilterProfile: false,
      canEditFilters: false,
      showShareButton: false,
      canShareReport: false,
      showExportButton: false,
      canExportReport: false,
      showReportSettingsButton: false,
      canEditReportSettings: false,
      canEditResponses: false,
      canViewCrosstabs: false,
      canEditCrosstabs: false,
      showFullTextAnswers: true,
      showShareRestrictedAnalysis: false,
      visibilityMode: 'ALL',
      visibilityViewIds: [],
      onlyLoopVariableIds: null,
      showResponseTypeToggle: false,
      responseType: responseTypeService.Types.COMPLETE,
    }

    var originalSurveyCache = {}

    var service = {
      get: get,
      getResponses: getResponses,
      getTextAnswers: getTextAnswers,
      getOtherAnswers: getOtherAnswers,
      createExport: createExport,
      chartConfigs: chartConfigs,
      fetchSurvey: fetchSurvey,
      modifyCachedSurvey: modifyCachedSurvey,
    }
    return service

    function get(
      surveyId,
      opts,
      filterSets,
      filterSetsOperator,
      force,
      responseType,
      loopTracker,
      ignoreMods
    ) {
      return $q(function(resolve, reject) {
        var options = _.defaults(opts, DefaultOptions)
        var survey

        fetchSurvey(surveyId, force)
          .then(function(data) {
            // we need to apply any mods so charts and tables show the final modded values.
            // but we also need to keep the un-applied survey factory around for editing mods.
            console.log('data1', _.cloneDeep(data))
            var originalFactory = new Survey().deserialize(data)
            if (!ignoreMods) {
              reportModService.apply(data)
            }
            survey = data
            survey.originalFactory = originalFactory

            survey.options = options

            // lazy migrate
            glSurveyUtils.parseSandboxData(survey)

            fetchSubscriberTheme(survey).then(function() {
              // Process the the survey to add required attributes
              processSurvey(survey)

              // If there are no responses return the survey and dont bother requesting the results
              if (survey.responseCount === 0) {
                return resolve(survey)
              }

              return fetchReport(
                survey,
                filterSets,
                filterSetsOperator,
                responseType,
                loopTracker
              )
                .then(function(results) {
                  processResults(results, survey)
                  return fetchDependencies(
                    survey,
                    filterSets,
                    filterSetsOperator,
                    responseType,
                    loopTracker
                  )
                })
                .then(function() {
                  return channelService.getBySurvey(survey.id, null, {
                    token: survey.options.shareToken,
                  })
                })
                .then(function(channels) {
                  survey.channels = channels
                  resolve(survey)
                })
                .catch(reject)
            })
          })
          .catch(reject)
      })
    }

    function getResponses(
      survey,
      filterSets,
      filterSetsOperator,
      responseType
    ) {
      var params = { id: survey.id, token: survey.options.shareToken }
      params.filter = filterSetMongoService.parse(
        filterSets,
        filterSetsOperator
      )
      if (responseType) {
        params.filter = params.filter ? params.filter : {}
        params.filter.type = responseType
      }
      return $q(function(resolve, reject) {
        surveyReportsResource.getSurveyResponses(params).success(
          function(responses) {
            resolve(responses)
          },
          function(error) {
            reject(error)
          }
        )
      })
    }

    function getOtherAnswers(
      survey,
      questionId,
      filterSets,
      filterSetsOperator,
      responseType,
      loopKeyId
    ) {
      var params = { id: questionId, token: survey.options.shareToken }
      params.filter = filterSetMongoService.parse(
        filterSets,
        filterSetsOperator
      )
      if (responseType) {
        params.filter = params.filter ? params.filter : {}
        params.filter.type = responseType
      }
      if (loopKeyId) {
        params.loopKeyId = loopKeyId
      }
      return $q(function(resolve, reject) {
        surveyReportsResource.getOtherAnswers(params).success(
          function(x, otherAnswers) {
            resolve(otherAnswers)
          },
          function(error) {
            reject(error)
          }
        )
      })
    }

    function fetchSubscriberTheme(survey) {
      return profileService.get(survey.ownerId).then(function(profile) {
        survey.subscriberThemeContext = profile.themeContext
      })
    }

    function fetchSurvey(surveyId, force) {
      // TODO: surveyService only returns cached versions SOMETIMES
      // This isn't great so we add another layer here.
      var survey = _.cloneDeep(originalSurveyCache[surveyId])
      if (survey && !force) {
        return $q.resolve(survey)
      }
      return surveyService.get(surveyId).then(function(survey) {
        originalSurveyCache[surveyId] = survey
        // We must clone this so that we dont update the cached version
        return _.cloneDeep(survey)
      })
    }

    function modifyCachedSurvey(surveyId, cb) {
      const survey = originalSurveyCache[surveyId]
      if (survey) cb(survey)
    }

    function fetchReport(
      survey,
      filterSets,
      filterSetsOperator,
      responseType,
      loopTracker
    ) {
      return $q(function(resolve, reject) {
        // HACK: remove the attempts logic when mongoDB map-reduce bad-type issue is resolved

        var attempt = 0
        var maxAttempts = 5
        var loopKeys = loopTracker ? loopTracker.getKeysParam() : null
        var params = {
          id: survey.id,
          token: survey.options.shareToken,
          incidence: false,
          filter: filterSetMongoService.parse(filterSets, filterSetsOperator),
          loopKeys: loopKeys,
        }
        if (responseType) {
          params.filter = params.filter ? params.filter : {}
          params.filter.type = responseType
        }
        makeAttempt()

        function makeAttempt() {
          attempt++
          surveyReportsResource
            .get(params)
            .success(function(x, results) {
              resolve(results)
            })
            .error(function(err) {
              glAnalytics.track('SurveyReport', 'error-fetching-report', err)
              if (attempt === maxAttempts) {
                reject(err)
              } else {
                makeAttempt()
              }
            })
        }
      })
    }

    function fetchDependencies(
      survey,
      filterSets,
      filterSetsOperator,
      responseType,
      loopTracker
    ) {
      var loaders = []
      // location and image question types
      _(survey.questions)
        .filter(function(question) {
          return _.includes(['location', 'image'], question.type)
        })
        .each(function(question) {
          var loopKeyId =
            loopTracker && question.loop
              ? loopTracker.getSelected(question.loop.id)
              : null
          var loader = getTextAnswers(
            survey,
            question.id,
            filterSets,
            filterSetsOperator,
            responseType,
            loopKeyId
          ).then(function(textAnswers) {
            if (question.type === 'location') {
              question.questionResults.locations.fromTextAnswers(textAnswers)
            }
            if (question.type === 'image') {
              question.questionResults.imageUrls = _(textAnswers)
                .filter('textAnswer')
                .map('textAnswer')
                .value()
            }
          })
          loaders.push(loader)
        })
      return $q.all(loaders)
    }

    function getTextAnswers(
      survey,
      questionId,
      filterSets,
      filterSetsOperator,
      responseType,
      loopKeyId
    ) {
      return $q(function(resolve, reject) {
        var params = { id: questionId, token: survey.options.shareToken }
        params.filter = filterSetMongoService.parse(
          filterSets,
          filterSetsOperator
        )
        if (responseType) {
          params.filter = params.filter ? params.filter : {}
          params.filter.type = responseType
        }
        if (loopKeyId) {
          params.loopKeyId = loopKeyId
        }
        surveyReportsResource.getTextAnswers(params).success(
          function(x, textAnswers) {
            resolve(textAnswers)
          },
          function(error) {
            reject(error)
          }
        )
      })
    }

    function processSurvey(survey) {
      // undo backend behaviour changes (until available for all types)
      _.each(survey.questions, function(question) {
        glSurveyUtils.undoQuestionBehaviours(question)
      })

      // populate view questions
      survey.data.views.forEach(function(view) {
        if (view.type !== 'QUESTION') return
        view.value = survey.questions.find(function(q) {
          return q.id === view.value
        })
      })

      // store a reference to the canonical question numbers and index, even if
      // some of them are removed in a shared report
      var num = 0
      _.each(survey.data.views, function(view) {
        if (view.type !== 'QUESTION') return
        var question = view.value
        if (question.isProfiling) return // profiling have no numbers
        question.canonicalNumber = ++num
      })

      // remove any redacted loop variables
      var onlyLoopVariableIds = survey.options.onlyLoopVariableIds
      if (_.isArray(onlyLoopVariableIds) && onlyLoopVariableIds.length) {
        var redactedLoopVariableIds = []
        _.each(survey.loops, function(loop) {
          _.remove(loop.keys, function(key) {
            var remove = !_.includes(onlyLoopVariableIds, key.id)
            if (remove) {
              redactedLoopVariableIds.push(key.id)
              return true
            }
          })
        })
        _.each(survey.data.views, function(view) {
          if (view.type !== 'QUESTION') return
          var question = view.value
          _.remove(question.choices, function(choice) {
            return _.includes(redactedLoopVariableIds, choice.id)
          })
          _.remove(question.statements, function(statement) {
            return _.includes(redactedLoopVariableIds, statement.id)
          })
        })
      }

      // attach loops to questions for quick access
      _.each(survey.loops, function(loop) {
        loop.options = []
        loop.selected = []
        loop.keys.forEach(function(key) {
          loop.options.push({ label: key.label, value: key.id })
        })
        loop.questionIds.forEach(function(questionId) {
          var view = survey.data.views.find(function(v) {
            return v.value.id === questionId
          })
          if (view) view.value.loop = loop
        })
      })

      // hide, redact and number questions/sections
      function hideRedactAndNumber(survey) {
        // 1. default analysis (in order):
        //    - pre-number questions (canonically)
        //    - keep hidden questions (ui hides them)
        //    - there are no redacted questions + sections
        // 2. shared report (in order):
        //    - remove hidden questions
        //    - remove redacted questions + sections
        //    - post-number questions (sequentially)
        function isQuestionRedacted(question) {
          return !reportLinkService.isViewVisible(
            question.id,
            survey.options.visibilityMode,
            survey.options.visibilityViewIds
          )
        }
        function isSectionRedacted(section) {
          return !reportLinkService.isViewVisible(
            section.id,
            survey.options.visibilityMode,
            survey.options.visibilityViewIds
          )
        }
        function isQuestionHidden(question) {
          return question.isHidden
        }
        function numberQuestions() {
          var num = 0
          _.each(survey.questions, function(question) {
            if (question.isProfiling) return // profiling have no numbers
            question.number = ++num
          })
        }
        function numberSections() {
          var num = 0
          _.each(survey.data.views, function(view) {
            if (view.type === 'SECTION') {
              view.value.number = ++num
            }
          })
        }
        var isDefaultAnalysis = !survey.options.shareToken
        if (isDefaultAnalysis) {
          survey.hasHiddenQuestions = _.some(survey.questions, isQuestionHidden)
          numberQuestions()
          numberSections()
        } else {
          survey.hasHiddenQuestions = false
          var removed = _.remove(survey.data.views, function(view) {
            if (view.type === 'QUESTION') {
              return (
                isQuestionHidden(view.value) || isQuestionRedacted(view.value)
              )
            }
            if (view.type === 'SECTION') {
              return isSectionRedacted(view.value)
            }
          })
          removed.forEach(function(view) {
            if (view.type === 'QUESTION') {
              _.remove(survey.questions, view.value)
            }
          })
          numberQuestions()
          numberSections()
        }
      }
      hideRedactAndNumber(survey)

      // HACK: Types.LOCATION needs to be stored in the BE as Types.TEXT
      // until the BE supports the type first class
      _.each(survey.questions, function(question) {
        if (question.type === 'text' && question.data.isLocationType) {
          question.type = 'location'
        }
      })

      // Remove headings, they are only a visual for respondents
      _.each(survey.questions, function(question) {
        _.remove(question.choices, function(choice) {
          return choice.data && choice.data.isHeading
        })
        _.remove(question.statements, function(statement) {
          return statement.data && statement.data.isHeading
        })
      })

      // Remove choices and statements marked as hidden.
      // In the future we may need to add a button to toggle the visibility.
      _.each(survey.questions, function(question) {
        _.remove(question.choices, function(choice) {
          return choice.data && choice.data.isHidden
        })
        _.remove(question.statements, function(statement) {
          return statement.data && statement.data.isHidden
        })
      })

      // shared reports have redacted questions and sections, and are re-numbered
      // sequentially (see hideRedactAndNumber() above). we need to rewrite pipes
      // so that they point to the correct reference.
      if (survey.options.shareToken) {
        rewritePipes(survey)
      }

      // setup themes + attach color fetch helpers etc
      setupThemes(survey)

      // build nps choices and colors
      _.each(survey.questions, function(question) {
        if (question.type === 'nps') {
          question.nps = [
            {
              label: 'Promoter',
              key: 'promoter',
              color: question.getLinearColor(2, 3),
            },
            {
              label: 'Passive',
              key: 'passive',
              color: question.getLinearColor(1, 3),
            },
            {
              label: 'Detractor',
              key: 'detractor',
              color: question.getLinearColor(0, 3),
            },
          ]
        }
      })

      renameProfilingKeyTypes(survey.questions)
      setQuestionPlacements(survey)

      _.each(survey.data.views, function(view) {
        if (view.type !== 'SECTION') return
        var section = view.value

        section.pipes = new Pipes(section.title)

        section.mediaBtnType = reportMediaBtn.getTypeBySectionMediaType(
          section.mediaType
        )
        switch (section.mediaType) {
          case Section.MediaTypes.VIDEO:
            section.mediaBtnUrl = section.youTubeVideoUrl
            break
          case Section.MediaTypes.VIDEO_UPLOAD:
            section.video = new Video().deserialize(section.video)
            break
          case Section.MediaTypes.AUDIO:
            section.mediaBtnUrl = section.audioUrl
            break
          case Section.MediaTypes.IMAGE:
            section.mediaBtnUrl = section.imageUrl
            break
        }
      })

      _.each(survey.questions, function(question) {
        question.pipes = new Pipes(question.title)

        // Create friendly titles for reporting components
        if (question.isProfiling) {
          question.listTitle = question.title
          question.shortTitle = question.type
        } else {
          question.listTitle = 'Q' + question.number + ': ' + question.title
          question.shortTitle = 'Q' + question.number
        }

        question.isMulti = question.maxSelections !== 1
        question.isMatrix =
          question.type === 'matrix' || question.data.matrixEnabled
        question.isMaxDiff =
          question.isMatrix &&
          question.data.flipTableAxis &&
          question.matrixUniqueSelection
        question.isHiddenVariables = question.type === 'hiddenvariables'
        question.hasOtherAnswers = _.some(question.choices, function(choice) {
          return choice.data && choice.data.isCapture
        })

        // Add the addition attributes for choice based questions
        if (question.choices) {
          switch (question.type) {
            case 'mood':
              assignMoodAttributes(question)
              break
            case 'rating':
              assignRatingAttributes(question)
              break
            case 'numeric':
              assignNumericChoices(question)
              assignChoiceColors(question)
              break
            case 'score':
              assignScoreAttributes(question)
              assignChoiceColors(question)
              break
            default:
              assignChoiceColors(question)
              break
          }
        }
        // Add color to statements
        if (question.statements.length) {
          assignStatementColors(question)
        }
      })

      function assignChoiceColors(question) {
        _.each(question.choices, function(choice, idx) {
          if (question.isMatrix || question.type === 'scale') {
            choice.color = question.getLinearColor(idx, question.choices.length)
          } else {
            choice.color = question.getStandardColor(idx)
          }
        })
      }

      function assignStatementColors(question) {
        _.each(question.statements, function(statement, idx) {
          statement.color = question.getLinearColor(
            idx,
            question.statements.length,
            true
          )
        })
      }

      function assignMoodAttributes(question) {
        function getColor(idx) {
          return question.getLinearColor(idx, 6)
        }

        var moodAttributes = [
          { color: getColor(0), icon: 'gi-mood-6', displayLabel: 'Very Angry' },
          { color: getColor(1), icon: 'gi-mood-5', displayLabel: 'Angry' },
          { color: getColor(2), icon: 'gi-mood-4', displayLabel: 'Meh' },
          { color: getColor(3), icon: 'gi-mood-3', displayLabel: 'Neutral' },
          { color: getColor(4), icon: 'gi-mood-2', displayLabel: 'Happy' },
          { color: getColor(5), icon: 'gi-mood-1', displayLabel: 'Very Happy' },
        ]

        _.each(question.choices, function(choice, idx) {
          choice.color = moodAttributes[idx].color
          choice.displayLabel = moodAttributes[idx].displayLabel
          choice.icon = moodAttributes[idx].icon
        })
      }

      function assignRatingAttributes(question) {
        function getColor(idx) {
          return question.getLinearColor(idx, 5)
        }

        var ratingAttributes = [
          { color: getColor(0), displayLabel: '1 Star' },
          { color: getColor(1), displayLabel: '2 Stars' },
          { color: getColor(2), displayLabel: '3 Stars' },
          { color: getColor(3), displayLabel: '4 Stars' },
          { color: getColor(4), displayLabel: '5 Stars' },
        ]

        _.each(question.choices, function(choice) {
          choice.color = ratingAttributes[choice.order].color
          choice.displayLabel = ratingAttributes[choice.order].displayLabel
        })

        question.useDisplayLabels = true
      }

      function assignScoreAttributes(question) {
        question.getScore = function(statementId) {
          var value = statementId
            ? question.questionResults.score[statementId]
            : question.questionResults.score
          return _.isNumber(value) ? Math.round(value) + '' : ''
        }
      }

      function assignNumericChoices(question) {
        question.choices = makeNumericChoices(question)
        // convert to similar structure as choice
        _.each(question.choices, function(choice, i) {
          choice.id = i
          choice.option = choice.value = choice.label
        })
      }
    }

    function rewritePipes(survey) {
      function getCurrentQuestionNumber(canonicalNumber) {
        var question = _.find(survey.questions, {
          canonicalNumber: canonicalNumber,
        })
        if (!question) return null
        return question.number
      }
      function rewritePipe(obj, field) {
        var label = obj[field]
        var pipes = new Pipes(label)
        var newLabel = pipes.rewriteQuestionNumbers(getCurrentQuestionNumber)
        if (label !== newLabel) {
          obj[field] = newLabel
          survey.labelsToRewrite[obj.id] = newLabel
        }
      }
      survey.labelsToRewrite = {}
      survey.questions.forEach(function(question) {
        // note: only pipes shown in analysis are rewritten
        rewritePipe(question, 'title')
        question.choices.forEach(function(choice) {
          rewritePipe(choice, 'option')
        })
        question.statements.forEach(function(statement) {
          rewritePipe(statement, 'statement')
        })
      })
      survey.data.views.forEach(function(view) {
        if (view.type !== 'SECTION') return
        var section = view.value
        rewritePipe(section, 'title')
      })
    }

    function setupThemes(survey) {
      var subscriberThemeContext = survey.subscriberThemeContext

      survey.themeContext = new ThemeContext()
        .asSurveyDefault()
        .deserialize(survey.data.themeContext)

      _.each(survey.questions, function(question) {
        question.themeContext = new ThemeContext()
          .asQuestionDefault()
          .deserialize(question.data && question.data.themeContext)

        question.theme = themeService.resolveTheme(
          question.themeContext,
          subscriberThemeContext,
          survey.themeContext
        )

        question.getStandardColor = function(num, reverse) {
          var set = reverse
            ? question.theme.standard.slice().reverse()
            : question.theme.standard
          return set[num % set.length]
        }
        question.getLinearColor = function(num, steps, reverse) {
          var set = reverse
            ? question.theme.linear.slice().reverse()
            : question.theme.linear
          set = colors.getGradient(set, steps)
          return set[num % set.length]
        }
      })

      survey.theme = themeService.resolveTheme(
        survey.themeContext,
        subscriberThemeContext
      )

      survey.getStandardColor = function(num, reverse) {
        var set = reverse
          ? survey.theme.standard.slice().reverse()
          : survey.theme.standard
        return set[num % set.length]
      }

      survey.getLinearColor = function(num, steps, reverse) {
        var set = reverse
          ? survey.theme.linear.slice().reverse()
          : survey.theme.linear
        set = colors.getGradient(set, steps)
        return set[num % set.length]
      }
    }

    function makeNumericChoices(question) {
      var n = question.numericTypeConstraint
      var labels = question.data.numericLabels
      return numericService.calculateValues(
        n.minValue,
        n.maxValue,
        n.steps,
        n.unit,
        labels
      )
    }

    function makeChoiceOptionValues(choices) {
      return _(choices)
        .keyBy('id')
        .mapValues(function(choice) {
          return { optionValue: choice.option }
        })
        .value()
    }

    function makeDefaultNumericStatResult() {
      return {
        min: 0,
        max: 0,
        total: 0,
        average: 0,
      }
    }

    function makeDefaultChoiceResult(choice, maxSelections, isNumeric) {
      var result = {
        optionValue: choice.option,
        count: 0,
      }

      if (maxSelections) {
        result.rankings = _.chain(maxSelections)
          .times(function(i) {
            return i + 1
          })
          .keyBy()
          .mapValues(function() {
            return { count: 0 }
          })
          .value()
      }

      if (isNumeric) {
        _.defaults(result, makeDefaultNumericStatResult())
      }

      return result
    }

    function makeDefaultStatementResult(statement, choices, isNumeric) {
      var result = {
        statementValue: statement.statement,
        count: 0,
        skipped: 0,
        values: makeDefaultChoiceResults(choices, null, isNumeric),
      }

      if (isNumeric) {
        _.defaults(result, makeDefaultNumericStatResult())
      }

      return result
    }

    function makeDefaultNumericResults(question) {
      var choices = makeNumericChoices(question)
      return _(choices)
        .keyBy('number')
        .mapValues(function(choice) {
          return {
            value: choice.number,
            count: 0,
          }
        })
        .value()
    }

    function makeDefaultChoiceResults(choices, maxSelections, isNumeric) {
      return _(choices)
        .keyBy('id')
        .mapValues(function(choice) {
          return makeDefaultChoiceResult(choice, maxSelections, isNumeric)
        })
        .value()
    }

    function makeDefaultStatementResults(statements, choices, isNumeric) {
      return _(statements)
        .keyBy('id')
        .mapValues(function(statement) {
          return makeDefaultStatementResult(statement, choices, isNumeric)
        })
        .value()
    }

    function processResults(results, survey) {
      if (!_.isPlainObject(results.chartData.questionResults)) {
        results.chartData.questionResults = {}
      }
      _.each(survey.questions, function(question) {
        var questionId = question.id
        if (!results.chartData.questionResults[questionId]) {
          var result = {
            answered: 0,
            count: 0,
            notAsked: 0,
            questionBehaviour: null,
            questionType: question.type,
            seen: 0,
            skipped: 0,
            values: {},
          }
          glSurveyUtils.redoQuestionBehaviours(
            result,
            'questionType',
            'questionBehaviour'
          )
          results.chartData.questionResults[questionId] = result
        } else if (
          _.isEmpty(results.chartData.questionResults[questionId].values)
        ) {
          // BE returns empty array for `values` when there's no response
          // this could cause error produced by undefined elements after
          // assigning defaults value on a numeric question type.
          // example that would produce undefined array elements:
          // eg, _.defaultsDeep([], {0: {}, 100: {}}) -> [0: {}, ...undefined..., 100: {}]
          // to fix this, we ensure `values` is an object and not an array.
          results.chartData.questionResults[questionId].values = {}
        }
        var result = results.chartData.questionResults[questionId]

        // undo backend behaviour changes (until available for all types)
        glSurveyUtils.undoQuestionBehaviours(
          result,
          'questionType',
          'questionBehaviour'
        )

        // Ensure default (zero) values are always set. The backend straight up
        // leaves these fields out if there are no counts, instead of returning count=0.
        // We need these so downstream calculations don't need to do field checking
        var defaults
        switch (result.questionType) {
          case 'nps':
          case 'rating':
          case 'scale':
          case 'choice':
          case 'mood':
          case 'hiddenvariables':
            defaults = makeDefaultChoiceResults(question.choices)
            break
          case 'rank':
            defaults = makeDefaultChoiceResults(
              question.choices,
              _.isNumber(question.maxSelections)
                ? question.maxSelections
                : question.choices.length
            )
            break
          case 'numeric':
            defaults = makeDefaultNumericResults(question)
            break
          case 'matrix':
            defaults = makeDefaultStatementResults(
              question.statements,
              question.choices
            )
            break
          case 'constantsum':
            if (question.isMatrix) {
              defaults = makeDefaultStatementResults(
                question.statements,
                question.choices,
                true
              )
            } else {
              defaults = makeDefaultChoiceResults(question.choices, null, true)
            }
            break
          case 'score':
            if (question.isMatrix) {
              defaults = makeDefaultStatementResults(
                question.statements,
                question.choices
              )
            } else {
              defaults = makeDefaultChoiceResults(question.choices)
            }
            break
        }
        _.defaultsDeep(result.values, defaults)

        // There is an issue with result.values[choiceId].optionValue returned
        // from the backend. The optionValue should match the surveys' choice label
        // but under certain circumstances it is overwritten by the arbitrary
        // text from a single response. This is mostly an issue when choice labels
        // have been translated as the translated label makes it's way into something
        // that it has nothing to do with.
        var enforcements
        switch (result.questionType) {
          case 'rating':
          case 'scale':
          case 'choice':
          case 'hiddenvariables':
            enforcements = makeChoiceOptionValues(question.choices)
            break
          case 'rank':
            enforcements = makeChoiceOptionValues(question.choices)
            break
          case 'numeric':
            break
          case 'matrix':
            enforcements = _(question.statements)
              .keyBy('id')
              .mapValues(function() {
                return {
                  values: makeChoiceOptionValues(question.choices),
                }
              })
              .value()
            break
          case 'constantsum':
          case 'score':
            if (question.isMatrix) {
              enforcements = _(question.statements)
                .keyBy('id')
                .mapValues(function() {
                  return {
                    values: makeChoiceOptionValues(question.choices),
                  }
                })
                .value()
            } else {
              enforcements = makeChoiceOptionValues(question.choices)
            }
            break
        }
        _.merge(result.values, enforcements)
      })

      // Add the incidences to the survey
      if (results.chartData.incidence) {
        survey.incidence = results.chartData.incidence
      }

      // add date ranges
      survey.minDate = moment(results.filterOptions.dateFilterStartAt).startOf(
        'day'
      )
      survey.maxDate = moment(results.filterOptions.dateFilterEndAt).endOf(
        'day'
      )

      // Add the filter options to the survey - only add them once and throw in any text based question values
      if (!survey.filterOptions) {
        survey.filterOptions = results.filterOptions

        // HACK: backend returns empty [] when there are no values inside - turn it into an object
        if (
          !_.isPlainObject(
            survey.filterOptions.reportingDimensions.customReportingDimensions
          )
        ) {
          survey.filterOptions.reportingDimensions.customReportingDimensions = {}
        }
        if (
          !_.isPlainObject(
            survey.filterOptions.reportingDimensions.systemReportingDimensions
          )
        ) {
          survey.filterOptions.reportingDimensions.systemReportingDimensions = {}
        }

        // HACK: remove productList dimension
        if (
          survey.filterOptions.reportingDimensions.customReportingDimensions
            .productList
        ) {
          delete survey.filterOptions.reportingDimensions
            .customReportingDimensions.productList
        }

        _.each(results.chartData.questionResults, function(question, key) {
          if (
            question.questionType === 'text' ||
            question.questionType === 'singletext' ||
            question.questionType === 'scan'
          ) {
            survey.filterOptions.questions[key] = _.clone(question)
          }
        })
      }

      // Add the filter counts to the survey
      var counts = results.counts
      counts.filteredResponseCount = counts.filteredResponseCount || 0
      counts.overallResponseCount =
        counts.totalResponseCount - counts.totalDeletedResponseCount
      survey.counts = counts

      // Process the question responses
      _.forEach(survey.questions, function(question) {
        if (
          results.chartData.questionResults &&
          results.chartData.questionResults[question.id]
        ) {
          question.questionResults =
            results.chartData.questionResults[question.id]
          var max = 0
          if (question.isMatrix) {
            _.each(question.questionResults.values, function(statement) {
              var total = 0
              _.each(statement.values, function(choice) {
                total += choice.count
              })
              if (max < total) max = total
            })
          } else {
            _.each(question.questionResults.values, function(choice) {
              if (max < choice.count) max = choice.count
            })
          }
          question.questionResults.popularCount = max
        } else {
          question.questionResults = { count: 0, values: {} }
        }
      })

      // calculate hasResponses for each question
      _.each(survey.questions, function(question) {
        question.hasResults = question.questionResults.answered > 0
      })

      modifyPlacesNearMe(survey.questions)
      addRatingAverages(survey.questions)
      makeRanks(survey.questions)
      addAspects(survey.questions)
      fixNumerics(survey.questions)
      processProfilingResults(survey.questions)
      makeTiles(survey)
      makeLocations(survey)
      makeLegends(survey.questions)
      makeNumericStats(survey.questions)
      makeConstantSumAbsolutes(survey.questions)
      calculateMarginOfError(survey)
    }

    function makeNumericStats(questions) {
      _.each(questions, function(question) {
        if (!_.includes(['numeric', 'constantsum'], question.type)) {
          return
        }
        var results = question.questionResults
        var round = numericService.round
        if (question.type === 'numeric') {
          var unit = numericService.getUnit(question.numericTypeConstraint.unit)
          question.numericStats = {
            average: unit.parse(round(results.average)),
            popular: _.map(results.popular, function(value) {
              return unit.parse(round(value))
            }).join(', '),
            min: unit.parse(round(results.min)),
            max: unit.parse(round(results.max)),
          }
        }
        if (question.type === 'constantsum') {
          question.numericStats = {
            average: round(results.average),
            popular: _.map(results.popular, function(value) {
              return round(value)
            }).join(', '),
            min: round(results.min),
            max: round(results.max),
          }
        }
      })
    }

    function makeLegends(questions) {
      _.each(questions, function(question) {
        question.legend = []
        switch (question.type) {
          case 'choice':
          case 'scale':
          case 'mood':
          case 'numeric':
          case 'rating':
          case 'matrix':
          case 'agerange':
          case 'sex':
          case 'constantsum':
          case 'score':
          case 'hiddenvariables':
            question.legend = _.map(question.choices, function(choice) {
              return {
                color: choice.color,
                value: choice.displayLabel || choice.option,
              }
            })
            // we want to show statement legend instead of choice legend
            // when selected chart for max diff question is `matrixbar`
            question.statementLegend = _.map(question.statements, function(
              statement
            ) {
              return {
                color: statement.color,
                value: statement.statement,
              }
            })
            break
          case 'rank':
            question.legend = _.map(question.ranks, function(rank) {
              return {
                color: rank.color,
                value: rank.label,
              }
            })
            // we want to show choice legend instead of rank legend
            // when selected chart for rank question is `matrixbar`
            question.choiceLegend = _.map(question.choices, function(choice) {
              return {
                color: choice.color,
                value: choice.displayLabel || choice.option,
              }
            })
            break
          case 'nps':
            question.legend.push({
              color: question.getLinearColor(2, 3),
              value: 'Promoters',
            })
            question.legend.push({
              color: question.getLinearColor(1, 3),
              value: 'Passives',
            })
            question.legend.push({
              color: question.getLinearColor(0, 3),
              value: 'Detractors',
            })
            break
          case 'singletext':
          case 'text':
          case 'scan':
          case 'placesnearme':
          case 'image':
          case 'location':
          case 'followup':
            // ...
            break
          default:
            // ...
            break
        }
      })
    }

    function makeTiles(survey) {
      var id = 0
      var tiles = []
      _.each(survey.data.views, function(view) {
        if (view.type === 'SECTION') {
          var section = view.value
          tiles.push({
            id: ++id,
            type: 'section',
            section: section,
          })
        }
        if (view.type === 'QUESTION') {
          var question = view.value
          if (question.placement !== 'chart') return
          tiles.push({
            id: ++id,
            type: 'question',
            question: question,
          })
        }
      })
      survey.tiles = tiles
    }

    function renameProfilingKeyTypes(questions) {
      var KeyToType = {
        location: 'location',
        sex: 'sex',
        age_range: 'agerange',
        age_year_of_birth: 'agerange',
      }
      var profileQuestions = _.filter(questions, function(question) {
        return question.profiling && question.profiling.key
      })
      _.each(profileQuestions, function(question) {
        question.type = KeyToType[question.profiling.key] || question.type
      })
    }

    function setQuestionPlacements(survey) {
      survey.data = survey.data || {}

      // set profiling questions first
      if (survey.data.coreProfileAdded) {
        // if enabled, show them as a profiling
        if (survey.data.coreProfileEnabled) {
          var coreQuestions = _.filter(survey.questions, function(question) {
            return (
              question.profiling &&
              _.includes(
                ['age_range', 'age_year_of_birth', 'sex', 'location'],
                question.profiling.key
              )
            )
          })
          _.each(coreQuestions, function(question) {
            question.isProfiling = true

            // Add the artificial choices
            if (question.profiling.key === 'age_year_of_birth') {
              question.choices = _.cloneDeep(ageChoices)
              // assign dummy id's
              _.each(question.choices, function(choice) {
                choice.id = question.id + '-' + choice.idSuffix
              })
            }
          })

          // otherwise remove from report
        } else {
          _.remove(survey.questions, function(question) {
            return (
              question.profiling &&
              _.includes(
                ['age_range', 'age_year_of_birth', 'sex', 'location'],
                question.profiling.key
              )
            )
          })
        }
      }

      // decide the remainder
      _.each(survey.questions, function(question) {
        if (question.type !== 'followup') {
          question.placement = 'chart'
        }
      })
    }

    function modifyPlacesNearMe(questions) {
      _(questions)
        .filter({ type: 'placesnearme' })
        .each(function(question) {
          var num = 0
          _.each(question.questionResults.values, function(element) {
            num++
            element.number = num
            element.label = num.toString()
            element.color = question.getStandardColor(num)
          })
        })
    }

    function addRatingAverages(questions) {
      _.each(_.filter(questions, { type: 'rating' }), function(question) {
        // Calculate the average
        var total = 0
        _.each(question.questionResults.values, function(value) {
          total += value.count * value.optionValue
        })

        question.questionResults.averageRating =
          Math.round((total / question.questionResults.answered) * 10) / 10
      })
    }

    function fixNumerics(questions) {
      _(questions)
        .filter({ type: 'numeric' })
        .each(function(question) {
          var values = {}
          _.each(question.choices, function(choice) {
            var e = _.find(question.questionResults.values, {
              value: choice.number,
            })
            if (e) {
              e.optionValue = choice.label
              values[choice.id] = e
            }
          })
          question.questionResults.values = values
        })
    }

    function processProfilingResults(questions) {
      _.each(questions, function(question) {
        if (
          question.profiling &&
          question.profiling.key === 'age_year_of_birth'
        ) {
          // Munge the original results into the age ranges
          var originalResults = _.cloneDeep(question.questionResults.values)
          question.questionResults.values = {}
          _.each(question.choices, function(choice) {
            var count = _.reduce(
              _.filter(originalResults, function(result) {
                return (
                  result.value >= choice.ageRange.lower &&
                  result.value <= choice.ageRange.upper
                )
              }),
              function(count, value) {
                return (count += value.count)
              },
              0
            )

            question.questionResults.values[choice.id] = {
              optionValue: choice.option,
              count: count,
            }
          })
        }
      })
    }

    function makeRanks(questions) {
      _(questions)
        .filter({ type: 'rank' })
        .each(function(question) {
          question.totalRanks = _.isNumber(question.maxSelections)
            ? question.maxSelections
            : question.choices.length
          question.ranks = []

          _.times(question.totalRanks, function(idx) {
            var rankNum = idx + 1
            question.ranks.push({
              id: 'rank' + rankNum,
              value: rankNum,
              label: 'Rank ' + rankNum,
              color: question.getLinearColor(idx, question.totalRanks, true),
            })
          })
        })
    }

    function addAspects(questions) {
      _(questions)
        .filter({ type: 'rank' })
        .reject({ questionResults: { count: 0 } })
        .each(function(question) {
          question.aspects = []

          _.each(question.ranks, function(rank, i) {
            var aspectId = 'aspect-' + (i + 1)

            _.each(question.choices, function(choice) {
              var choiceResult =
                question.questionResults.values[choice.id] || {}
              var rankResult =
                choiceResult &&
                choiceResult.rankings &&
                choiceResult.rankings[rank.value]
              var aspect = {
                id: aspectId,
                rankId: rank.id,
                choiceId: choice.id,
              }

              if (rankResult) {
                aspect.count = rankResult.count
                // aspect.ratio = (rankResult.count / choiceResult.count);
                aspect.ratio =
                  rankResult.count / question.questionResults.answered
              } else {
                aspect.count = 0
                aspect.ratio = 0
              }
              aspect.percent = Math.round(aspect.ratio * 100)
              // aspect.percent = +(aspect.ratio * 100).toFixed(2);
              // aspect.percent = _.round(aspect.ratio * 100, 2);

              question.aspects.push(aspect)
            })
          })
        })

      _(questions)
        .filter(function(question) {
          return (
            question.type === 'matrix' ||
            (question.type === 'score' && question.isMatrix)
          )
        })
        .reject({ questionResults: { count: 0 } })
        .each(function(question) {
          question.aspects = []

          _.each(question.statements, function(statement, i) {
            var statementResult = question.questionResults.values[statement.id]
            var aspectId = 'aspect-' + (i + 1)

            if (!statementResult) {
              return
            }

            var statementCount = 0
            _.each(question.choices, function(choice) {
              var choiceResult = statementResult.values[choice.id]
              statementCount += choiceResult.count
            })

            _.each(question.choices, function(choice) {
              var choiceResult = statementResult.values[choice.id]
              var aspect = {
                id: aspectId,
                statementId: statement.id,
                choiceId: choice.id,
              }

              if (choiceResult && choiceResult.count > 0) {
                aspect.count = choiceResult.count
                // aspect.ratio = choiceResult.count / statementCount
                aspect.ratio =
                  choiceResult.count / question.questionResults.answered
              } else {
                aspect.count = 0
                aspect.ratio = 0
              }
              aspect.percent = Math.round(aspect.ratio * 100)
              // aspect.percent = +(aspect.ratio * 100).toFixed(2);
              // aspect.percent = _.round(aspect.ratio * 100, 2);

              question.aspects.push(aspect)
            })
          })
        })

      _(questions)
        .filter({ type: 'constantsum', isMatrix: true })
        .reject({ questionResults: { count: 0 } })
        .each(function(question) {
          question.aspects = []

          _.each(question.statements, function(statement, i) {
            var statementResult = question.questionResults.values[statement.id]
            var aspectId = 'aspect-' + (i + 1)

            if (!statementResult) {
              return
            }

            var statementCount = 0
            var statementTotal = 0
            _.each(question.choices, function(choice) {
              var choiceResult = statementResult.values[choice.id]
              statementCount += choiceResult.count
              statementTotal += choiceResult.total
            })

            _.each(question.choices, function(choice) {
              var choiceResult = statementResult.values[choice.id]
              var aspect = {
                id: aspectId,
                statementId: statement.id,
                choiceId: choice.id,
                count: 0,
                ratio: 0,
                total: 0,
                totalRatio: 0,
              }
              if (choiceResult) {
                aspect.count = choiceResult.count
                aspect.ratio = choiceResult.count / statementCount
                aspect.total = choiceResult.total
                aspect.totalRatio = choiceResult.total / statementTotal
              }
              aspect.percent = Math.round(aspect.ratio * 100)
              aspect.totalPercent = Math.round(aspect.totalRatio * 100)
              question.aspects.push(aspect)
            })
          })
        })
    }

    function makeLocations(survey) {
      // placesnearme question type
      _(survey.questions)
        .filter({ type: 'placesnearme' })
        .each(function(question) {
          var elements = question.questionResults.values
          question.questionResults.locations = new LocationSet().fromElements(
            elements
          )
        })

      // location question type
      _(survey.questions)
        .filter({ type: 'location' })
        .each(function(question) {
          question.questionResults.locations = new LocationSet()
        })
    }

    function makeConstantSumAbsolutes(questions) {
      _(questions)
        .filter({ type: 'constantsum' })
        .each(function(question) {
          question.questionResults.absolute = {}
          var max = 0
          if (question.isMatrix) {
            _.each(question.questionResults.values, function(statement) {
              var totalNumber = 0
              _.each(statement.values, function(choice) {
                totalNumber += choice.total
              })
              if (max < totalNumber) max = totalNumber
            })
          } else {
            _.each(question.questionResults.values, function(choice) {
              if (max < choice.total) max = choice.total
            })
          }
          question.questionResults.absolute.max = max
        })
    }

    function calculateMarginOfError(survey) {
      var reportSettings = new ReportSettings().deserialize(
        survey.data.reportSettings
      )
      var marginOfError = reportSettings.marginOfError
      var isShareLink = !!survey.options.shareToken

      if (
        !marginOfError.enabled ||
        (isShareLink && marginOfError.isHiddenFromShared)
      )
        return

      // MOE doesn't make sense for some question types, eg "text"
      var MOEQuestionTypes = [
        'choice',
        'rank',
        'scale',
        'nps',
        'mood',
        'rating',
        'numeric',
        'matrix',
        'constantsum',
        'score',
      ]

      _.each(survey.questions, function(question) {
        var isType = MOEQuestionTypes.includes(question.type)
        if (!isType) return
        var sampleSize = question.questionResults.answered
        question.marginOfError = marginOfError.get(sampleSize, 0)
      })
    }

    function createExport(options) {
      // this is our regular `qcsv` export, not the legacy `csv` one
      options = _.defaults(options, {
        surveyId: null,
        responseType: responseTypeService.Types.COMPLETE,
        open: true,
        shareToken: null,
        questionIds: [],
        deleted: null,
        labelsToRewrite: {},
        onlyLoopVariableIds: null,
        filterSets: [],
        filterSetsOperator: null,
        exportFormat: null,
        isNumeric: null,
        withDuration: null,
      })
      var params = { surveyId: options.surveyId }
      params.filter =
        filterSetMongoService.parse(
          options.filterSets,
          options.filterSetsOperator
        ) || {}
      if (options.responseType) {
        params.filter.type = options.responseType
      }
      if (options.shareToken) {
        params.token = options.shareToken
      }
      if (options.questionIds.length) {
        params.questionIds = options.questionIds
      }
      if (options.onlyLoopVariableIds) {
        params.loopKeyIds = options.onlyLoopVariableIds
      }
      if (_.isBoolean(options.deleted)) {
        params.filter.deletedAt = { $exists: options.deleted }
      }
      if (!_.isEmpty(options.labelsToRewrite)) {
        params.labels = options.labelsToRewrite
      }
      if (_.isString(options.exportFormat)) {
        params.exportFormat = options.exportFormat
      }
      if (_.isBoolean(options.isNumeric)) {
        params.isNumeric = options.isNumeric
      }
      if (options.withDuration === true) {
        params.withDuration = options.withDuration
      }
      return api.exports
        .create(params)
        .then(function(resp) {
          if (options.open) {
            var url = configService.getSubscriberPortalUrl('/export/' + resp.id)
            window.open(url, '_blank')
          }
          return resp
        })
        .catch(function(err) {
          console.error(err)
          throw err
        })
    }
  }
})()
