;(function() {
  'use strict'

  Controller.$inject = ["Question", "userService"];
  angular.module('app.core').component('newViewsGrid', {
    controller: Controller,
    templateUrl: 'new-views-grid.html',
    bindings: {
      isQuestionOnly: '<',
      onSelect: '&',
    },
  })

  /* @ngInject */
  function Controller(Question, userService) {
    var ctrl = this
    var Types = Question.Types
    var SSROnlyTypes = [Types.PLACES_NEAR_ME, Types.SCAN]
    var isSSR = userService.isSSR()
    var questions = Question.getInfo([
      Types.CHOICE,
      Types.MATRIX,
      Types.RANK,
      Types.SINGLE_TEXT,
      Types.TEXT,
      Types.SCALE,
      Types.NUMERIC,
      Types.NPS,
      Types.SCORE,
      Types.CONSTANT_SUM,
      Types.LOCATION,
      Types.IMAGE,
      Types.MOOD,
      Types.RATING,
      Types.PLACES_NEAR_ME,
      Types.SCAN,
    ])
    var section = {
      label: 'Section',
      desc: 'A section between questions',
      highlight: 'grey',
    }
    var hiddenVariables = _.assign(Question.getInfo(Types.HIDDEN_VARIABLES), {
      highlight: 'red',
    })

    ctrl.questions = _.filter(questions, canSelect)
    ctrl.others = [section, hiddenVariables]

    function canSelect(question) {
      if (isSSR) {
        return true
      }
      return !_.includes(SSROnlyTypes, question.type)
    }
  }
})()
