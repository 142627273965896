;(function() {
  'use strict'

  Controller.$inject = ["Mod", "surveyServiceNext"];
  angular.module('glow.reporting').component('reportMods', {
    controller: Controller,
    templateUrl: 'report-mods.html',
    bindings: {
      survey: '<',
      questionId: '<',
      onDone: '&',
      onCancel: '&',
    },
  })

  /* @ngInject */
  function Controller(Mod, surveyServiceNext) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.toggle = toggle
    ctrl.move = move
    ctrl.canMerge = canMerge
    ctrl.merge = merge
    ctrl.unmerge = unmerge
    ctrl.getInfo = getInfo
    ctrl.done = done
    ctrl.cancel = cancel

    function onInit() {
      var survey = ctrl.survey.originalFactory // factory
      ctrl.question = survey.getQuestion(ctrl.questionId)
      ctrl.question.initChoiceMods()
      ctrl.choices = ctrl.question.getVisibleChoices()
      ctrl.mods = _.cloneDeep(ctrl.question.choiceMods)
      ctrl.selected = []
      ctrl.saving = false
    }

    function toggle(mod) {
      var selected = ctrl.selected.includes(mod.id)
      if (selected) {
        ctrl.selected = ctrl.selected.filter(function(id) {
          return mod.id !== id
        })
      } else {
        ctrl.selected.push(mod.id)
      }
    }

    function move(mod, direction) {
      var idx = ctrl.mods.indexOf(mod)
      var newIdx = _.clamp(idx + direction, 0, ctrl.mods.length - 1)
      ctrl.mods.splice(newIdx, 0, ctrl.mods.splice(idx, 1)[0])
    }

    function canMerge() {
      return ctrl.selected.length >= 2
    }

    function merge() {
      if (!canMerge()) return
      var modIds = ctrl.selected
      var insertAt = _.findIndex(ctrl.mods, function(mod) {
        return modIds.includes(mod.id)
      })
      var mods = ctrl.mods.filter(function(mod) {
        return modIds.includes(mod.id)
      })
      var mod = new Mod()
      mod.label = mods
        .map(function(mod) {
          return mod.label
        })
        .join(' + ')
      mod.optionIds = []
      for (var m of mods) {
        for (var o of m.optionIds) {
          mod.optionIds.push(o)
        }
      }
      mod.hidden = _.every(mods, function(mod) {
        return mod.hidden
      })
        ? true
        : false
      for (var m of mods) {
        _.remove(ctrl.mods, m)
      }
      ctrl.mods.splice(insertAt, 0, mod)
      ctrl.selected = []
    }

    function unmerge(mod) {
      if (mod.optionIds.length < 2) return
      var insertAt = ctrl.mods.indexOf(mod)
      var newMods = []
      for (var optionId of mod.optionIds) {
        var newMod = mod.clone()
        newMod.id = optionId
        newMod.label = getOptionLabel(optionId)
        newMod.optionIds = [optionId]
        newMods.push(newMod)
      }
      ctrl.mods.splice(insertAt, 0, ...newMods)
      // Array.prototype.splice.apply(ctrl.mods, [insertAt, 0].concat(newMods))
      _.remove(ctrl.mods, mod)
      ctrl.selected = newMods.map(function(mod) {
        return mod.id
      })
    }

    function getOptionLabel(optionId) {
      var choice = ctrl.choices.find(function(choice) {
        return choice.id === optionId
      })
      return choice.label
    }

    function getInfo(mod) {
      return mod.optionIds.map(getOptionLabel).join(', ')
    }

    function done() {
      // if nothing is hidden, merged, renamed or reordered then there are no mods :)
      var hasHidden = _.some(ctrl.mods, function(mod) {
        return mod.hidden
      })
      var hasMerges = _.some(ctrl.mods, function(mod) {
        return mod.optionIds.length >= 2
      })
      var modIds = ctrl.mods.map(function(mod) {
        return mod.optionIds[0]
      })
      var choiceIds = ctrl.choices.map(function(choice) {
        return choice.id
      })
      var isReordered = !_.isEqual(modIds, choiceIds)
      var hasRenames = _.some(ctrl.mods, function(mod) {
        return mod.label !== getOptionLabel(mod.optionIds[0])
      })

      if (hasHidden || hasMerges || isReordered || hasRenames) {
        // console.log('has mods')
        ctrl.question.choiceMods = ctrl.mods
      } else {
        // console.log('has NO mods')
        ctrl.question.choiceMods = []
      }

      var data = ctrl.survey.originalFactory.serialize()
      var mods = data.questions.find(function(q) { return q.id === ctrl.questionId }).data.choiceMods // prettier-ignore
      // console.log('merges', data.merges)
      // console.log('mods', mods)

      // save survey
      ctrl.saving = true
      surveyServiceNext
        .save(ctrl.survey.originalFactory)
        .then(function() {
          ctrl.onDone()
        })
        .catch(function(err) {
          console.error(err)
        })
        .finally(function() {
          ctrl.saving = false
        })
    }

    function cancel() {
      ctrl.onCancel()
    }
  }
})()
