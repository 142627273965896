;(function() {
  'use strict'

  config.$inject = ["$stateProvider"];
  angular.module('app.core').config(config)

  /* @ngInject */
  function config($stateProvider) {
    $stateProvider.state('public-translation-editor', {
      url: '/translations/:translationId',
      templateUrl: 'public-translation-editor.html',
      controller: 'PublicTranslationEditor',
      controllerAs: '$ctrl',
      title: 'Edit Translation',
      public: true,
    })
  }
})()
