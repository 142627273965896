;(function() {
  'use strict'

  Controller.$inject = ["$q", "$timeout", "$element", "glDialog", "glToast", "glAnalytics", "userService", "subscriberService", "accountService", "currencyService", "hostingCreditService", "HostingCreditPurchase"];
  angular.module('app.core').component('hostingCreditPurchase', {
    controller: Controller,
    templateUrl: 'hosting-credit-purchase.html',
    bindings: {
      context: '<',
      onSelect: '&?',
    },
  })

  /* @ngInject */
  function Controller(
    $q,
    $timeout,
    $element,
    glDialog,
    glToast,
    glAnalytics,
    userService,
    subscriberService,
    accountService,
    currencyService,
    hostingCreditService,
    HostingCreditPurchase
  ) {
    var ctrl = this
    var sliderEl = null
    var sliderContainerEl = null

    ctrl.sliderMin = 100
    ctrl.sliderMax = 20000
    ctrl.sliderStep = 100
    ctrl.initial = 1000

    ctrl.$onInit = onInit
    ctrl.onSliderChange = onSliderChange
    ctrl.purchase = purchase
    ctrl.toggleAutoRenewal = toggleAutoRenewal

    function onInit() {
      ctrl.isMessage = ctrl.context === 'welcome'
      ctrl.isEnterprise = _.includes(['home', 'welcome'], ctrl.context)
      ctrl.usesSubscriberSettings = ctrl.context === 'account-billing'
      ctrl.isSSR = userService.isSSR()
      ctrl.subscriber = subscriberService.getSubscriber()
      ctrl.currencies = currencyService.getAll().map(function(currency) {
        return { label: currency.code, value: currency.code }
      })
      ctrl.currency = ctrl.subscriber
        ? ctrl.subscriber.billing.currency
        : _.find(ctrl.currencies, { value: 'AUD' })
        ? 'AUD'
        : ctrl.currencies[0].value

      ctrl.loading = true
      getPricing().then(function(pricing) {
        var units =
          (ctrl.usesSubscriberSettings &&
            ctrl.subscriber.hostingCredit.autoRenewal.units) ||
          ctrl.initial
        var autoRenewEnabled = ctrl.usesSubscriberSettings
          ? ctrl.subscriber.hostingCredit.isAutoRenew()
          : true
        ctrl.sliderModel = units
        ctrl.hostingCreditPurchase = new HostingCreditPurchase(pricing, units)
        ctrl.hostingCreditPurchase.autoRenewEnabled = autoRenewEnabled
        ctrl.loading = false
        $timeout(function() {
          sliderEl = $element[0].querySelector(
            '.hosting-credit-purchase__slider input[type="range"]'
          )
          sliderContainerEl = $element[0].querySelector(
            '.hosting-credit-purchase__slider'
          )
          updateSliderColor()
        }, 0)
      })
    }

    function getPricing() {
      return $q(function(resolve) {
        if (ctrl.subscriber) {
          return resolve(ctrl.subscriber.hostingCredit.pricing)
        }
        hostingCreditService.getPricing().then(resolve)
      })
    }

    function updateSliderColor() {
      if (!sliderEl || !sliderContainerEl) {
        console.error('slider element not found')
        return
      }
      var percentage =
        ((sliderEl.value - sliderEl.min) / (sliderEl.max - sliderEl.min)) * 100
      sliderContainerEl.style.setProperty(
        '--slider-percentage',
        percentage + '%'
      )
    }

    function onSliderChange() {
      ctrl.hostingCreditPurchase.setUnits(ctrl.sliderModel)
      updateSliderColor()
    }

    function track(action) {
      glAnalytics.track('hosting-credit-purchase', action, {
        context: ctrl.context,
        credits: ctrl.hostingCreditPurchase.units,
        price: ctrl.hostingCreditPurchase.price
          .byCurrency(ctrl.currency)
          .format({ currency: true }),
        pricePerUnit: ctrl.hostingCreditPurchase.pricePerUnit
          .byCurrency(ctrl.currency)
          .format({ currency: true }),
        autoRenew: ctrl.hostingCreditPurchase.autoRenewEnabled,
      })
    }

    function purchase() {
      if (ctrl.isSSR) {
        return glDialog.alert(
          '',
          'SSR users cannot purchase credits on behalf of subscriber. Instead, go to Admin portal to allocate credits to the subscriber and provide reason'
        )
      }

      if (ctrl.onSelect) {
        track('on-select')
        return ctrl.onSelect({
          $hostingCreditPurchase: ctrl.hostingCreditPurchase,
          $currency: ctrl.currency,
        })
      }

      if (ctrl.isPurchaseLoading) return

      ctrl.isPurchaseLoading = true
      accountService
        .showPurchaseDialog({
          type: 'hosting-credit',
          hostingCreditPurchase: ctrl.hostingCreditPurchase,
          context: ctrl.context,
        })
        .then(function() {
          track('purchase-credits')
        })
        .catch(function(err) {
          if (err !== 'cancelled') {
            glToast.show('Something went wrong. Please try again.')
            console.error(err)
          }
        })
        .finally(function() {
          ctrl.isPurchaseLoading = false
        })
    }

    function toggleAutoRenewal(enabled) {
      if (ctrl.isSSR) {
        return glDialog.alert('', 'SSR users cannot modify auto recharge')
      }

      if (!ctrl.usesSubscriberSettings) {
        ctrl.hostingCreditPurchase.autoRenewEnabled = enabled
        return
      }

      if (ctrl.isSavingAutoRenewal) return

      ctrl.isSavingAutoRenewal = true
      var deferred = $q.defer()

      if (!enabled) {
        glDialog
          .confirm(
            'Disable Auto Recharge',
            'Are you sure you want to disable auto recharge? This could interrupt your projects if your credits run out.'
          )
          .then(function() {
            return hostingCreditService.saveAutoRenewal(ctrl.subscriber, false)
          })
          .then(deferred.resolve, deferred.reject)
      } else {
        accountService
          .showPurchaseDialog({
            type: 'auto-renewal',
            hostingCreditPurchase: ctrl.hostingCreditPurchase,
            context: ctrl.context,
          })
          .then(deferred.resolve, deferred.reject)
      }

      deferred.promise
        .then(function() {
          ctrl.hostingCreditPurchase.autoRenewEnabled = enabled
          track('auto-renewal')
        })
        .catch(function(err) {
          if (err !== 'cancelled') {
            glToast.show('Something went wrong. Please try again.')
            console.error(err)
          }
        })
        .finally(function() {
          ctrl.isSavingAutoRenewal = false
        })
    }
  }
})()
