;(function() {
  'use strict'

  Controller.$inject = ["$state", "glDialog", "StateFactory", "glAnalytics", "glToast", "subscriberService", "userService", "paymentMethodsService", "paymentMethodEditorService"];
  angular.module('app.core').component('paymentMethods', {
    controller: Controller,
    templateUrl: 'payment-methods.html',
  })

  /* @ngInject */
  function Controller(
    $state,
    glDialog,
    StateFactory,
    glAnalytics,
    glToast,
    subscriberService,
    userService,
    paymentMethodsService,
    paymentMethodEditorService
  ) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.add = add
    ctrl.edit = edit
    ctrl.remove = remove

    function onInit() {
      ctrl.isSSR = userService.isSSR()
      ctrl.state = new StateFactory(['loading', 'error', 'ready'])
      loadPaymentMethod()
    }

    function track(action) {
      glAnalytics.track('payment-method', action, 'payment-methods')
    }

    function loadPaymentMethod() {
      var subscriberId = subscriberService.getSubscriber().id
      ctrl.state.loading()
      return paymentMethodsService
        .getBySubscriber(subscriberId)
        .then(function(paymentMethods) {
          // Only one payment method is supported
          ctrl.paymentMethod = paymentMethods[0]
          ctrl.state.ready()
        })
        .catch(ctrl.state.error)
    }

    function showEditor(options) {
      return paymentMethodEditorService.show(options).then(loadPaymentMethod)
    }

    function add() {
      if (ctrl.isSSR) {
        return glToast.show('SSR users cannot add Payment Methods')
      }
      track('intent-to-add')
      showEditor({ title: 'Add Payment Method' })
        .then(function() {
          track('intent-to-add-complete')
          return subscriberService.refresh()
        })
        .catch(function() {
          track('intent-to-add-cancelled')
        })
    }

    function edit() {
      if (ctrl.isSSR) {
        return glToast.show('SSR users cannot edit Payment Methods')
      }
      track('intent-to-edit')
      showEditor({
        title: 'Edit Payment Method',
        paymentMethod: ctrl.paymentMethod,
      })
        .then(function() {
          track('intent-to-edit-complete')
        })
        .catch(function() {
          track('intent-to-edit-cancelled')
        })
    }

    function remove() {
      glDialog
        .confirm(
          'Remove payment method',
          'Are you sure you want to remove your payment method?'
        )
        .then(function() {
          var subscriberId = subscriberService.getSubscriber().id
          paymentMethodsService
            .remove(subscriberId)
            .then(function() {
              ctrl.paymentMethod = null
              glToast.show('Payment method removed.')
            })
            .catch(function(error) {
              glToast.show(error.message || 'Error removing payment method.')
            })
        })
    }
  }
})()
