;(function() {
  'use strict'

  Service.$inject = ["$window"];
  angular.module('app.core').service('surveyExplorerExitService', Service)

  /* @ngInject */
  function Service($window) {
    var enabled = false
    var msg = null
    var defaultMsg = 'Unsaved changes will be lost, are you sure?'

    var service = {
      setEnabled: setEnabled,
      setMsg: setMsg,
      check: check,
    }

    init()

    return service

    function init() {
      $window.addEventListener('beforeunload', onBeforeUnload)
    }

    function setEnabled(_enabled) {
      if (enabled === _enabled) return
      enabled = _enabled
    }

    function setMsg(_msg) {
      msg = _msg
    }

    function check() {
      if (!enabled) return true
      return $window.confirm(msg || defaultMsg)
    }

    function onBeforeUnload(e) {
      if (enabled) {
        e.returnValue = msg
        return msg
      }
    }
  }
})()
