;(function() {
  'use strict'

  Factory.$inject = ["glUtils", "ResponseFilterRule"];
  angular.module('app.core').factory('ResponseFilterGroup', Factory)

  // This filter outputs mongo queries for the glow_report.query_optimised_responses collection

  /* @ngInject */
  function Factory(glUtils, ResponseFilterRule) {
    function ResponseFilterGroup(filter) {
      this.isGroup = true
      this.filter = filter
      this.applyDefaults()
    }

    ResponseFilterGroup.prototype.applyDefaults = function() {
      _.defaultsDeep(this, {
        id: glUtils.uuid(),
        rules: [],
      })
    }

    ResponseFilterGroup.prototype.deserialize = function(data) {
      var self = this
      this.id = data.id
      this.rules = data.rules.map(function(ruleData) {
        return new ResponseFilterRule(self.filter).deserialize(ruleData)
      })
      this.applyDefaults()
      return this
    }

    ResponseFilterGroup.prototype.serialize = function() {
      var data = {}
      data.id = this.id
      data.rules = this.rules.map(function(rule) {
        return rule.serialize()
      })
      data.isGroup = true
      return data
    }

    ResponseFilterGroup.prototype.addRule = function() {
      var rule = new ResponseFilterRule(this.filter)
      this.rules.push(rule)
      return rule
    }

    ResponseFilterGroup.prototype.duplicateRule = function(rule) {
      var index = this.rules.indexOf(rule)
      var clone = rule.clone().refresh()
      this.rules.splice(index + 1, 0, clone)
      return this
    }

    ResponseFilterGroup.prototype.removeRule = function(rule) {
      _.remove(this.rules, rule)
      return this
    }

    ResponseFilterGroup.prototype.isLastRule = function(rule) {
      return this.rules[this.rules.length - 1] === rule
    }

    ResponseFilterGroup.prototype.clone = function() {
      return new ResponseFilterGroup(this.filter).deserialize(this.serialize())
    }

    ResponseFilterGroup.prototype.refresh = function() {
      var self = this
      this.id = glUtils.uuid()
      this.rules.forEach(function(rule) {
        rule.refresh()
      })
      return this
    }

    ResponseFilterGroup.prototype.validate = function() {
      if (!this.rules.length) return false
      return _.every(this.rules, function(rule) {
        return rule.validate()
      })
    }

    ResponseFilterGroup.prototype.toMongo = function() {
      var set = this.rules.map(function(rule) {
        return rule.toMongo()
      })
      return { $and: set }
    }

    ResponseFilterGroup.prototype.toDatapackMatcher = function(datapack) {
      const matchers = this.rules.map(function(rule) {
        return rule.toDatapackMatcher(datapack)
      })
      return function(row) {
        return _.every(matchers, function(match) {
          return match(row)
        })
      }
    }

    return ResponseFilterGroup
  }
})()
