;(function() {
  'use strict'

  ErrorsController.$inject = ["$scope", "$state", "glErrorService", "helpService", "userService"];
  angular.module('app.errors').controller('ErrorsController', ErrorsController)

  /* @ngInject */
  function ErrorsController(
    $scope,
    $state,
    glErrorService,
    helpService,
    userService
  ) {
    $scope.goHome = goHome
    $scope.errorEventId = glErrorService.lastEventId()
    $scope.chat = helpService.chat

    function goHome() {
      if (userService.isLoggedIn()) {
        $state.go('dashboard')
      } else {
        $state.go('home')
      }
    }
  }
})()
