;(function() {
  'use strict'

  Controller.$inject = ["glUtils"];
  angular.module('glow.reporting').component('surveyExplorerModsDialog', {
    controller: Controller,
    templateUrl: 'survey-explorer-mods-dialog.html',
    bindings: {
      config: '<',
      options: '<',
      onDone: '&',
      onCancel: '&',
    },
  })

  /* @ngInject */
  function Controller(glUtils) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.toggle = toggle
    ctrl.move = move
    ctrl.canMerge = canMerge
    ctrl.merge = merge
    // ctrl.switchRedact = switchRedact
    ctrl.unmerge = unmerge
    ctrl.reset = reset
    ctrl.getInfo = getInfo
    ctrl.done = done
    ctrl.cancel = cancel

    function onInit() {
      ctrl.items = _.cloneDeep(ctrl.config.items)
      ctrl.combineWeighted = ctrl.config.combineWeighted
      ctrl.combineWeightedLabel = ctrl.config.combineWeightedLabel
      ctrl.selected = []
    }

    function toggle(item) {
      var selected = ctrl.selected.includes(item.id)
      if (selected) {
        ctrl.selected = ctrl.selected.filter(function(id) {
          return item.id !== id
        })
      } else {
        ctrl.selected.push(item.id)
      }
    }

    function move(item, direction) {
      var idx = ctrl.items.indexOf(item)
      var newIdx = _.clamp(idx + direction, 0, ctrl.items.length - 1)
      ctrl.items.splice(newIdx, 0, ctrl.items.splice(idx, 1)[0])
    }

    function canMerge() {
      return ctrl.selected.length >= 2
    }

    function merge() {
      if (!canMerge()) return
      var itemIds = ctrl.selected
      var insertAt = _.findIndex(ctrl.items, function(item) {
        return itemIds.includes(item.id)
      })
      var items = ctrl.items.filter(function(item) {
        return itemIds.includes(item.id)
      })
      var item = {}
      item.id = glUtils.uuid()
      item.label = items
        .map(function(item) {
          return item.label
        })
        .join(' + ')
      item.optionIds = []
      for (var m of items) {
        for (var o of m.optionIds) {
          item.optionIds.push(o)
        }
      }
      item.redact = null
      // item.hidden = _.every(items, function(item) {
      //   return item.hidden
      // })
      //   ? true
      //   : false
      for (var m of items) {
        _.remove(ctrl.items, m)
      }
      ctrl.items.splice(insertAt, 0, item)
      ctrl.selected = []
    }

    function unmerge(item) {
      if (item.optionIds.length < 2) return
      var insertAt = ctrl.items.indexOf(item)
      var newMods = []
      for (var optionId of item.optionIds) {
        var newMod = _.cloneDeep(item)
        newMod.id = optionId
        newMod.label = getOptionLabel(optionId)
        newMod.optionIds = [optionId]
        newMods.push(newMod)
      }
      ctrl.items.splice(insertAt, 0, ...newMods)
      // Array.prototype.splice.apply(ctrl.items, [insertAt, 0].concat(newMods))
      _.remove(ctrl.items, item)
      ctrl.selected = newMods.map(function(item) {
        return item.id
      })
    }

    function reset() {
      ctrl.items = ctrl.options.map(function(option) {
        return {
          id: option.id,
          label: option.label,
          optionIds: [option.id],
          redact: null,
        }
      })
    }

    // function switchRedact(item) {
    //   if (!item.redact) {
    //     item.redact = 'hide'
    //   } else if (item.redact === 'hide') {
    //     item.redact = 'exclude'
    //   } else if (item.redact === 'exclude') {
    //     item.redact = null
    //   }
    // }

    function getOptionLabel(optionId) {
      var option = ctrl.options.find(function(option) {
        return option.id === optionId
      })
      return option.label
    }

    function getInfo(item) {
      return item.optionIds.map(getOptionLabel).join(', ')
    }

    function done() {
      // if nothing is hidden, merged, renamed or reordered then there are no mods :)
      // var hasHidden = _.some(ctrl.items, function(item) {
      //   return item.hidden
      // })
      // var hasMerges = _.some(ctrl.items, function(item) {
      //   return item.optionIds.length >= 2
      // })
      // var itemIds = ctrl.items.map(function(item) {
      //   return item.optionIds[0]
      // })
      // var optionIds = ctrl.options.map(function(option) {
      //   return option.id
      // })
      // var isReordered = !_.isEqual(itemIds, optionIds)
      // var hasRenames = _.some(ctrl.items, function(item) {
      //   return item.label !== getOptionLabel(item.optionIds[0])
      // })

      ctrl.onDone({
        $config: {
          items: ctrl.items,
          combineWeighted: ctrl.combineWeighted,
          combineWeightedLabel: ctrl.combineWeightedLabel,
        },
      })

      // if (hasHidden || hasMerges || isReordered || hasRenames) {
      //   // console.log('has mods')
      //   ctrl.question.choiceMods = ctrl.items
      // } else {
      //   // console.log('has NO mods')
      //   ctrl.question.choiceMods = []
      // }

      // var data = ctrl.survey.originalFactory.serialize()
      // var mods = data.questions.find(function(q) { return q.id === ctrl.questionId }).data.choiceMods // prettier-ignore
      // // console.log('merges', data.merges)
      // // console.log('mods', mods)

      // // save survey
      // ctrl.saving = true
      // surveyServiceNext
      //   .save(ctrl.survey.originalFactory)
      //   .then(function() {
      //     ctrl.onDone()
      //   })
      //   .catch(function(err) {
      //     console.error(err)
      //   })
      //   .finally(function() {
      //     ctrl.saving = false
      //   })
    }

    function cancel() {
      ctrl.onCancel()
    }
  }
})()
