;(function() {
  'use strict'

  Controller.$inject = ["$timeout", "reportD3ChartService", "c3", "d3", "renderService"];
  angular
    .module('glow.reporting.charts')
    .component('glReportMoodChart', Component())

  /* @ngInject */
  function Component() {
    return {
      controller: Controller,
      templateUrl: 'report-mood-chart.template.html',
      bindings: {
        height: '=',
        question: '<',
        allowChartClick: '=',
        onChartClick: '&',
        focus: '<',
        hide: '<',
      },
    }
  }

  /* @ngInject */
  function Controller($timeout, reportD3ChartService, c3, d3, renderService) {
    var ctrl = this

    ctrl.chartId = _.uniqueId('glReportMoodChart')
    ctrl.$onChanges = onChanges

    function onChanges(changes) {
      if (!ctrl.chart) {
        $timeout(makeChart, 10)
      } else if (changes.question) {
        refreshChart()
      } else if (changes.hide) {
        ctrl.chart.show()
        if (ctrl.hide.length) {
          ctrl.chart.hide(ctrl.hide)
        }
      }
      if (ctrl.chart) {
        ctrl.chart.focus(ctrl.focus)
      }
    }

    function refreshChart() {
      ctrl.chart.unload()
      $timeout(function() {
        var data = reportD3ChartService.parseData(ctrl.question)
        ctrl.chart.load({ columns: data.columns })
      })
    }

    function makeChart() {
      var data = reportD3ChartService.parseData(ctrl.question)
      var config = reportD3ChartService.buildDonutConfig(
        ctrl.chartId,
        data.columns,
        data.colors,
        drawMoodScore,
        ctrl.allowChartClick,
        ctrl.onChartClick
      )
      renderService.queue(function() {
        ctrl.chart = c3.generate(config)
      })
    }

    function drawMoodScore() {
      var svg = d3.select('#' + ctrl.chartId).select('svg')
      var size = parseInt(svg.style('height'))

      svg.selectAll('text').remove()

      svg
        .append('text')
        .attr('x', '50%')
        .attr('y', '50%')
        .attr('dy', '.1em')
        .text(Math.round(calculateHappiness()))
        .attr('text-anchor', 'middle')
        .style('font-size', function() {
          return size / 3.3 + 'px'
        })

      svg
        .append('text')
        .attr('x', '50%')
        .attr('y', '50%')
        .text('% Happiness')
        .attr('dy', '2em')
        .attr('text-anchor', 'middle')
        .style('font-size', function() {
          return size / 18 + 'px'
        })
    }

    function calculateHappiness() {
      var happinessScore = {
        '1': 0,
        '2': 20,
        '3': 40,
        '4': 60,
        '5': 80,
        '6': 100,
      }

      var score = 0

      _.each(ctrl.question.questionResults.values, function(value) {
        score += happinessScore[value.optionValue] * value.count
      })

      if (score > 0) {
        score =
          (score /
            (ctrl.question.questionResults.answered * happinessScore['6'])) *
          100
      }

      return score
    }
  }
})()
