;(function() {
  'use strict'

  angular.module('app.core').factory('ResponseFilterList', Factory)

  /* @ngInject */
  function Factory() {
    function ResponseFilterList() {
      this.applyDefaults()
    }

    ResponseFilterList.prototype.applyDefaults = function() {
      _.defaultsDeep(this, {
        items: [],
        quantifier: null,
      })
    }

    ResponseFilterList.prototype.deserialize = function(data) {
      this.items = data.items
      this.quantifier = data.quantifier
      this.applyDefaults()
      return this
    }

    ResponseFilterList.prototype.serialize = function() {
      var data = {}
      data.items = this.items.slice()
      data.quantifier = this.quantifier
      return data
    }

    ResponseFilterList.prototype.setQuantifier = function(value) {
      this.quantifier = value
      return this
    }

    return ResponseFilterList
  }
})()
