;(function() {
  'use strict'

  Controller.$inject = ["subscriberService"];
  angular.module('app.core').component('accountSubscriberRenamer', {
    controller: Controller,
    templateUrl: 'account-subscriber-renamer.html',
    bindings: {
      subscriber: '<',
      onDone: '&',
      onCancel: '&',
    },
  })

  /* @ngInject */
  function Controller(subscriberService) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.done = done
    ctrl.cancel = cancel

    function onInit() {
      ctrl.nameModel = ctrl.subscriber.name
    }

    function done() {
      if (ctrl.isSaving) return

      if (!ctrl.nameModel) {
        ctrl.error = 'Organisation name cannot be empty!'
        return
      }

      if (ctrl.nameModel === ctrl.subscriber.name) {
        return ctrl.onDone()
      }

      ctrl.error = ''
      ctrl.isSaving = true
      ctrl.subscriber.name = ctrl.nameModel
      subscriberService
        .saveSubscriber(ctrl.subscriber)
        .then(function() {
          ctrl.onDone()
        })
        .catch(function(err) {
          ctrl.error = err.message
        })
        .finally(function() {
          ctrl.isSaving = false
        })
    }

    function cancel() {
      if (ctrl.isSaving) return
      ctrl.onCancel()
    }
  }
})()
