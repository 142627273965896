;(function() {
  'use strict'

  Service.$inject = ["$q", "productSurveysResource"];
  angular.module('core.services').factory('productSurveysService', Service)

  /* @ngInject */
  function Service($q, productSurveysResource) {
    return {
      associate: associate,
      disassociate: disassociate,
    }

    function associate(productId, surveyId) {
      var options = {
        id: productId,
        surveyId: surveyId,
      }

      return $q(function(resolve, reject) {
        productSurveysResource
          .associate(options)
          .success(onSuccess)
          .error(onError)

        function onSuccess() {
          resolve()
        }

        function onError(data, status) {
          reject({ data: data, status: status })
        }
      })
    }

    function disassociate(productId, surveyId) {
      var options = {
        id: productId,
        surveyId: surveyId,
      }

      return $q(function(resolve, reject) {
        productSurveysResource
          .disassociate(options)
          .success(onSuccess)
          .error(onError)

        function onSuccess() {
          resolve()
        }

        function onError(data, status) {
          reject({ data: data, status: status })
        }
      })
    }
  }
})()
