;(function() {
  'use strict'

  Controller.$inject = ["$scope", "$q", "$state", "StateFactory", "Survey", "translationService", "surveyService", "glToast"];
  angular.module('app.core').controller('PublicTranslationEditor', Controller)

  /* @ngInject */
  function Controller(
    $scope,
    $q,
    $state,
    StateFactory,
    Survey,
    translationService,
    surveyService,
    glToast
  ) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.save = save
    ctrl.updateVisibleCorpus = _.debounce(updateVisibleCorpus, 100, {
      trailing: true,
    })

    function onInit() {
      ctrl.translationId = $state.params.translationId
      ctrl.filters = {
        query: '',
        incomplete: false,
      }
      ctrl.state = new StateFactory(['loading', 'ready', 'error'])
      ctrl.state.loading()
      loadTranslation()
        .then(loadSurvey)
        .then(function() {
          ctrl.state.ready()
        })
        .catch(function(error) {
          console.error(error)
          ctrl.state.error()
        })
    }

    function loadTranslation() {
      return translationService
        .getById(ctrl.translationId)
        .promise.then(function(translation) {
          ctrl.translation = translation
          if (
            !translation.isPublicUpdateAllowed ||
            translation.type !== translation.Types.CUSTOM
          ) {
            return $q.reject()
          }
        })
    }

    function loadSurvey() {
      return surveyService.get(ctrl.translation.surveyId).then(function(data) {
        ctrl.survey = new Survey().deserialize(data)
        ctrl.corpus = translationService.corpus(ctrl.survey.serialize())
        updateVisibleCorpus()
      })
    }

    function save() {
      translationService
        .save(ctrl.translation)
        .then(function() {
          glToast.show('Translation saved')
        })
        .catch(function() {
          glToast.show('Could not save translation')
        })
    }

    function updateVisibleCorpus() {
      $scope.$applyAsync(function() {
        var query = ctrl.filters.query.toLowerCase()
        ctrl.visibleCorpus = _.filter(ctrl.corpus, function(item) {
          var mapping = ctrl.translation.mappings[item] || ''
          var queryMatch = query
            ? item.toLowerCase().includes(query) ||
              mapping.toLowerCase().includes(query)
            : true
          var incompleteMatch = ctrl.filters.incomplete ? !mapping : true
          return queryMatch && incompleteMatch
        })
      }, 100)
    }
  }
})()
