;(function() {
  'use strict'

  pageModulesConfig.$inject = ["$stateProvider"];
  angular.module('app.page-modules', []).config(pageModulesConfig)

  /* @ngInject */
  function pageModulesConfig($stateProvider) {
    $stateProvider.state('page.modules', {
      url: '/modules',
      templateUrl: 'page-modules.template.html',
      controller: 'PageModulesController',
      controllerAs: 'pageModules',
      title: 'Page Modules',
    })
  }
})()
