;(function() {
  'use strict'

  Controller.$inject = ["StateFactory", "ReportLink", "colors", "userService", "surveyServiceNext", "featureService", "reportLinkService", "responseTypeService"];
  angular.module('glow.reporting').component('reportLinkEditor', {
    controller: Controller,
    templateUrl: 'report-link-editor.html',
    bindings: {
      link: '<',
      onClose: '&',
      onSave: '&',
    },
  })

  /* @ngInject */
  function Controller(
    StateFactory,
    ReportLink,
    colors,
    userService,
    surveyServiceNext,
    featureService,
    reportLinkService,
    responseTypeService
  ) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.save = save
    ctrl.getViewsLabel = getViewsLabel

    ctrl.responseTypeOptions = responseTypeService.selectOptions

    function onInit() {
      ctrl.isSSR = userService.isSSR()
      ctrl.state = new StateFactory(['loading', 'ready', 'error'])
      ctrl.state.loading()
      surveyServiceNext
        .get(ctrl.link.surveyId)
        .then(function(survey) {
          ctrl.survey = survey
          ctrl.modeOptions = createModeOptions()
          ctrl.viewOptions = createViewOptions()
          ctrl.loopOptions = createLoopOptions()
          ctrl.expiryOptions = createExpiryOptions()
          ctrl.expiryLabel = ctrl.link.id
            ? ctrl.link.expiresAt
              ? ctrl.link.expiresAt.format('ddd MMM D YYYY hh:mma')
              : 'Never expires'
            : null
          ctrl.state.ready()
        })
        .catch(function(error) {
          console.error(error)
          ctrl.state.error()
        })
    }

    function save() {
      if (!ctrl.link.validate()) {
        ctrl.error = ctrl.link.errors[0]
        return
      }
      reportLinkService.save(ctrl.link).then(function() {
        ctrl.onSave()
      })
    }

    function createModeOptions() {
      return [
        {
          label: 'Show all questions and sections',
          value: ReportLink.Modes.ALL,
        },
        {
          label: 'Only show these questions and sections...',
          value: ReportLink.Modes.INCLUDE,
        },
        {
          label: 'Hide these questions and sections...',
          value: ReportLink.Modes.EXCLUDE,
        },
      ]
    }

    function createExpiryOptions() {
      var options = []
      if (featureService.canUseAdvancedAnalysisSharing()) {
        options.push({
          label: 'Never Expires',
          value: null,
        })
        options.push({
          label: '1 Day',
          value: moment()
            .add(1, 'day')
            .endOf('day'),
        })
        options.push({
          label: '1 Week',
          value: moment()
            .add(1, 'week')
            .endOf('day'),
        })
      }
      options.push({
        label: '1 Month',
        value: moment()
          .add(1, 'month')
          .endOf('day'),
      })
      options.push({
        label: '3 Months',
        value: moment()
          .add(3, 'months')
          .endOf('day'),
      })
      options.push({
        label: '6 Months',
        value: moment()
          .add(6, 'months')
          .endOf('day'),
      })
      options.push({
        label: '12 Months',
        value: moment()
          .add(12, 'months')
          .endOf('day'),
      })
      return options
    }

    function createLoopOptions() {
      ctrl.loops = []
      ctrl.loopVariables = {}
      ctrl.survey.viewGroups.forEach(function(viewGroup) {
        if (!viewGroup.loop) return
        ctrl.loops.push({
          id: viewGroup.id,
          label: viewGroup.name,
        })
        ctrl.loopVariables[viewGroup.id] = viewGroup.getLoopVariableOptions(
          ctrl.survey
        )
      })
    }

    function createViewOptions() {
      return _.map(ctrl.survey.views, function(view) {
        var option = {}
        if (view.isQuestion()) {
          var question = view.value
          option.label = question.getTitleLabel({ number: true })
          option.abbrev = 'Q' + question.getNumber()
          option.value = question.id
          if (ctrl.isSSR && question.omni) addOmniTag(option, question.omni)
        }
        if (view.isSection()) {
          var section = view.value
          option.label = section.getTitleLabel()
          option.abbrev = 'S' + section.getNumber()
          option.value = section.id
          if (ctrl.isSSR && section.omni) addOmniTag(option, section.omni)
        }
        return option
      })
    }

    function addOmniTag(option, omni) {
      option.tag = omni.subscriberName + ' / ' + omni.surveyId.slice(0, 4)
      option.tagColor = colors.getByKey(
        'omni',
        omni.subscriberId + omni.surveyId
      )
    }

    function getViewsLabel(options) {
      return _.map(options, 'abbrev').join(', ')
    }
  }
})()
