;(function() {
  'use strict'

  Controller.$inject = ["glAnalytics", "channelService", "subscriberService", "userService", "Channel", "StateFactory"];
  angular.module('app.core').component('channelPicker', {
    controller: Controller,
    templateUrl: 'channel-picker.html',
    bindings: {
      channel: '<',
      onDone: '&',
      onClose: '&',
    },
  })

  /* @ngInject */
  function Controller(
    glAnalytics,
    channelService,
    subscriberService,
    userService,
    Channel,
    StateFactory
  ) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.select = select
    ctrl.getTypeName = getTypeName

    function onInit() {
      var subscriber = subscriberService.getSubscriber()
      var isSSR = userService.isSSR()
      ctrl.types = channelService.getTypes().filter(function(type) {
        // hide integrated panel if disabled in admin tab
        if (
          type.value === Channel.Type.PANEL &&
          !subscriber.data.allowAddIntegratedPanel
        ) {
          return false
        }

        // hide third-party panel if subscriber doesn't have
        // feature - OR - its disabled in admin tab
        if (
          type.value === Channel.Type.EXTERNAL_PANEL &&
          (!subscriber.data.flags.EXTERNAL_PANEL ||
            !subscriber.data.allowAddExternalPanel)
        ) {
          return false
        }

        return true
      })
      ctrl.primaryTypes = ctrl.types.filter(function(type) {
        return type.category === Channel.Category.PRIMARY
      })
      ctrl.otherTypes = ctrl.types.filter(function(type) {
        // return other category type
        // and hide "Your Crowd" channel type
        return (
          type.category === Channel.Category.OTHER && type.value !== 'CROWD'
        )
      })
      ctrl.premiumTypes = ctrl.types.filter(function(type) {
        return type.category === Channel.Category.PREMIUM
      })
      ctrl.state = new StateFactory(['picker', 'places'])
      ctrl.state.picker()
      ctrl.isSSR = userService.isSSR()
      if (ctrl.channel.isNew) {
        glAnalytics.track('channel', 'create-new')
      } else {
        glAnalytics.track(
          'channel',
          'view-or-edit',
          ctrl.channel.id + ' - ' + ctrl.channel.name
        )
      }
    }

    function select(type) {
      if (type === 'CROWD') return // coming soon
      ctrl.channel.setType(type)
      glAnalytics.track('channel', 'select-type', type)
      switch (type) {
        case ctrl.channel.Type.CUSTOM:
        case ctrl.channel.Type.KIOSK:
        case ctrl.channel.Type.PRODUCTS:
        case ctrl.channel.Type.EXTERNAL_PANEL:
        case ctrl.channel.Type.PANEL:
          ctrl.onDone({ $channel: ctrl.channel })
          break
        case ctrl.channel.Type.PLACES:
          if (ctrl.isSSR) {
            ctrl.onDone({ $channel: ctrl.channel })
          } else {
            ctrl.state.places()
          }
          break
        default:
        // ...
      }
    }

    function getTypeName() {
      return _.find(ctrl.types, { value: ctrl.channel.type }).name
    }
  }
})()
