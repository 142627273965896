;(function() {
  'use strict'

  Controller.$inject = ["projectService"];
  angular.module('app.core').component('projectGrid', {
    controller: Controller,
    templateUrl: 'project-grid.html',
    bindings: {
      projects: '<',
      onSelect: '&',
      hasSidebar: '<',
      limit: '<',
    },
  })

  /* @ngInject */
  function Controller(projectService) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.orderBy = orderBy
    ctrl.archive = projectService.promptAndArchive
    ctrl.unarchive = projectService.promptAndUnarchive

    function onInit() {
      // ...
    }

    function orderBy(project) {
      return project.createdAt.unix()
    }
  }
})()
