;(function() {
  'use strict'

  Controller.$inject = ["StateFactory", "glDialog", "api", "surveyService", "subSelector", "Survey", "Project"];
  angular.module('app.core').component('omniImporter', {
    controller: Controller,
    templateUrl: 'omni-importer.html',
    bindings: {
      onSelect: '&',
    },
  })

  /* @ngInject */
  function Controller(
    StateFactory,
    glDialog,
    api,
    surveyService,
    subSelector,
    Survey,
    Project
  ) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.search = search
    ctrl.selectSubscriber = selectSubscriber
    ctrl.selectProject = selectProject
    ctrl.selectSurvey = selectSurvey

    function onInit() {
      ctrl.step = new StateFactory([
        'subscriber',
        'project',
        'survey',
        'confirm',
      ])
      ctrl.step.subscriber()

      ctrl.invalidBySurveyId = {}
      ctrl.query = ''
      ctrl.searchState = new StateFactory(['loading', 'error', 'ready'])
      ctrl.searchError = null
      ctrl.projectsState = new StateFactory(['loading', 'error', 'ready'])
      ctrl.projectsError = null
      ctrl.surveysState = new StateFactory(['loading', 'error', 'ready'])
      ctrl.surveysError = null
    }

    function search() {
      ctrl.searchState.loading()
      subSelector
        .search(ctrl.query)
        .then(function(subscribers) {
          ctrl.subscribers = subscribers
          ctrl.searchState.ready()
        })
        .catch(function() {
          ctrl.searchError = 'Error loading subscribers.'
          ctrl.searchState.error()
        })
    }

    function selectSubscriber(subscriber) {
      ctrl.subscriber = subscriber
      ctrl.step.project()
      ctrl.projectsState.loading()
      api.projects
        .get({ ownerId: subscriber.id })
        .then(function(projects) {
          _.remove(projects, { isArchived: true })
          ctrl.projects = projects.map(function(data) {
            return new Project().deserialize(data)
          })
          ctrl.projectsState.ready()
        })
        .catch(function() {
          ctrl.projectsError = 'Error loading projects.'
          ctrl.projectsState.error()
        })
    }

    function selectProject(project) {
      ctrl.project = project
      ctrl.step.survey()
      ctrl.surveysState.loading()
      surveyService
        .getByProject(project.id)
        .then(function(surveys) {
          _.remove(surveys, { isArchived: true })
          ctrl.surveys = surveys
          ctrl.surveysState.ready()
        })
        .catch(function() {
          ctrl.surveysError = 'Error loading surveys.'
          ctrl.surveysState.error()
        })
    }

    function selectSurvey(survey) {
      ctrl.surveysState.loading()
      surveyService
        .get(survey.id)
        .then(function(data) {
          ctrl.survey = new Survey().deserialize(data)
          checkValid()
          return loadInfo().then(function() {
            select()
          })
        })
        .catch(function(err) {
          glDialog.alert(
            'Invalid',
            err.userMessage || 'Survey cannot be imported'
          )
          ctrl.surveysState.ready()
        })
    }

    function checkValid() {
      ctrl.survey.views.forEach(function(view) {
        view.value.logic.forEach(function(logic) {
          logic.actions.forEach(function(action) {
            if (action.type === action.Types.EXIT) {
              throw { userMessage: 'Survey invalid (it uses exit logic)' }
            }
            if (action.type === action.Types.SUBMIT) {
              throw { userMessage: 'Survey invalid (it uses submit logic)' }
            }
            // DEPRECATED
            if (action.type === action.Types.JUMP_TO_FOLLOWUP) {
              throw {
                userMessage: 'Survey invalid (it uses jump-to-followup logic)',
              }
            }
            // DEPRECATED
            if (action.type === action.Types.JUMP_TO_PROFILING) {
              throw {
                userMessage: 'Survey invalid (it uses jump-to-profiling logic)',
              }
            }
          })
        })
      })
    }

    function loadInfo() {
      return api.subscribers
        .get({ id: ctrl.survey.ownerId })
        .then(function(subscriber) {
          ctrl.info = {
            subscriberId: subscriber.id,
            subscriberName: subscriber.name,
            // BUG: It's not currently possible to get the projectId for the survey
            // projectId: ctrl.survey.projectId,
            surveyId: ctrl.survey.id,
          }
        })
        .catch(function() {
          throw { userMessage: 'Could not load survey info' }
        })
    }

    function select() {
      // refresh ID's etc
      ctrl.survey.refresh()
      // add details about where the views came from
      ctrl.survey.views.forEach(function(view) {
        view.value.omni = ctrl.info
      })
      ctrl.onSelect({
        $views: ctrl.survey.views,
        $viewGroups: ctrl.survey.viewGroups,
        $shuffleGroups: ctrl.survey.shuffleGroups,
      })
    }
  }
})()
