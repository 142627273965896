;(function() {
  'use strict'

  Controller.$inject = ["$timeout", "reportD3ChartService", "c3", "renderService"];
  angular
    .module('glow.reporting.charts')
    .component('glReportPieChart', Component())

  /* @ngInject */
  function Component() {
    return {
      controller: Controller,
      templateUrl: 'report-pie-chart.template.html',
      bindings: {
        height: '=',
        question: '<',
        absolute: '<',
        allowChartClick: '=',
        onChartClick: '&',
        focus: '<',
        hide: '<',
        sortConfig: '<',
      },
    }
  }

  /* @ngInject */
  function Controller($timeout, reportD3ChartService, c3, renderService) {
    var ctrl = this

    ctrl.chartId = _.uniqueId('glReportPieChart')
    ctrl.$onChanges = onChanges

    function onChanges(changes) {
      if (!ctrl.chart) {
        $timeout(makeChart, 10)
      } else if (changes.absolute) {
        makeChart()
      } else if (changes.question) {
        refreshChart()
      } else if (changes.sortConfig) {
        makeChart()
      } else if (changes.hide) {
        ctrl.chart.show()
        if (ctrl.hide.length) {
          ctrl.chart.hide(ctrl.hide)
        }
      } else if (changes.focus) {
        ctrl.chart.focus(ctrl.focus)
      }
    }
    // Change the chart data to be percentage based
    function getData() {
      return reportD3ChartService.parseData(ctrl.question, ctrl.absolute)
    }

    function refreshChart() {
      var data = getData()
      ctrl.chart.load({
        unload: null,
        columns: reportD3ChartService.sortColumns(ctrl.sortConfig, data),
      })
    }

    function makeChart() {
      delete ctrl.chart

      var data = getData()
      var columns = reportD3ChartService.sortColumns(ctrl.sortConfig, data)

      var config = reportD3ChartService.buildChartConfig(
        ctrl.chartId,
        'pie',
        columns,
        data.colors,
        ctrl.allowChartClick,
        ctrl.onChartClick,
        ctrl.absolute
      )
      renderService.queue(function() {
        ctrl.chart = c3.generate(config)
      })
    }
  }
})()
