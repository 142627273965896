;(function() {
  'use strict'

  Controller.$inject = ["userService", "subscriberService"];
  angular.module('app.core').component('loginForm', {
    controller: Controller,
    templateUrl: 'login-form.html',
    bindings: {
      allowedRoles: '<',
      onLogin: '&',
      onError: '&?',
      onRequestPasswordReset: '&',
    },
  })

  /* @ngInject */
  function Controller(userService, subscriberService) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.login = login

    function onInit() {
      // ...
    }

    function login() {
      if (ctrl.loading || !ctrl.form.$valid) {
        return
      }

      ctrl.errors = null
      ctrl.loading = true
      userService
        .login(
          { email: ctrl.email, password: ctrl.password },
          ctrl.allowedRoles
        )
        .then(function() {
          var subscriberId = userService.getSubscriberId()
          return subscriberService.loadSubscriber(subscriberId)
        })
        .then(function() {
          ctrl.onLogin({ $user: ctrl.user })
        })
        .catch(function() {
          if (ctrl.onError) {
            ctrl.onError({
              $error: 'There was an issue logging in. Please try again.',
            })
          } else {
            ctrl.errors = ['There was an issue logging in. Please try again.']
          }
        })
        .finally(function() {
          ctrl.loading = false
        })
    }
  }
})()
