;(function() {
  'use strict'

  QuestionTemplateEditController.$inject = ["$log", "$state", "glTips", "questionTemplateService"];
  angular
    .module('app.question-templates-edit')
    .controller(
      'QuestionTemplateEditController',
      QuestionTemplateEditController
    )

  /* @ngInject */
  function QuestionTemplateEditController(
    $log,
    $state,
    glTips,
    questionTemplateService
  ) {
    $log = $log.create('QuestionTemplateEditController')

    var ctrl = this

    // Constants
    ctrl.States = {
      LOADING: 'loading',
      ERROR: 'error',
      READY: 'ready',
      SUCCESS: 'success',
    }

    // Methods
    ctrl.setState = setState
    ctrl.isState = isState

    activate()

    /**
     * Initialise
     */
    function activate() {
      ctrl.setState(ctrl.States.LOADING)
      if ($state.params.cloneTemplateId) {
        ctrl.templateId = 'new'
        cloneQuestionTemplate($state.params.cloneTemplateId)
      } else {
        if ($state.params.questionId === 'new') {
          createNewTemplate()
        } else {
          ctrl.templateId = $state.params.questionId
          getQuestionTemplate()
        }
      }
    }

    /**
     * @name getQuestionTemplate
     * @desc get all available survey templates
     */
    function getQuestionTemplate() {
      questionTemplateService
        .get(ctrl.templateId)
        .then(getSuccess)
        .catch(getError)
    }

    /**
     * @name cloneQuestionTemplate
     * @desc clone an existing survey template
     */
    function cloneQuestionTemplate(templateId) {
      questionTemplateService
        .get(templateId)
        .then(function(template) {
          sanitiseTemplate(template)
          getSuccess(template)
        })
        .catch(getError)
    }

    function getSuccess(data) {
      ctrl.questionTemplate = data
      ctrl.setState(ctrl.States.READY)
      glTips.ready()
    }

    function getError() {
      ctrl.setState(ctrl.States.ERROR)
      $log.error('could not load templates')
    }

    function createNewTemplate() {
      ctrl.templateId = null
      ctrl.questionTemplate = questionTemplateService.create($state.params.type)
      ctrl.questionTemplate.addVariation()
      ctrl.setState(ctrl.States.READY)
      glTips.ready()
    }

    /**
     * @name Set State
     * @desc change state of this view
     * @param {String} state The State to change to
     */
    function setState(state) {
      ctrl.state = state
    }

    /**
     * @name Is State
     * @desc check if we are on a specified state
     * @param {String} state A State value
     */
    function isState(state) {
      return ctrl.state === state
    }

    /**
     * @name sanitiseTemplate
     * @desc sanitise the template so there is no cross contamination between the original and cloned version
     * @param {Object} template The template to be sanitised
     */
    function sanitiseTemplate(template) {
      template.id = null
      template.title = 'Clone of...' + template.title
      template.name = _.clone(template.title)
      template.state = 'DRAFT'
      template.status = 'Draft'

      _.each(template.questions, function(question) {
        question.id = null
        question.title = template.title
        question.state = 'DRAFT'
        _.each(question.choices, function(choice) {
          choice.id = null
        })
      })
    }
  }
})()
