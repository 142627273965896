;(function() {
  'use strict'

  Service.$inject = ["$rootScope", "$q", "glAuthService", "glDialog", "glAnalytics", "Publisher", "publishersResource", "subscriberService", "edgeService"];
  angular.module('app.core').service('publisherService', Service)

  /* @ngInject */
  function Service(
    $rootScope,
    $q,
    glAuthService,
    glDialog,
    glAnalytics,
    Publisher,
    publishersResource,
    subscriberService,
    edgeService
  ) {
    var self = this

    self.publishers = []

    self.getBlank = getBlank
    self.getById = getById
    self.getMine = getMine
    self.save = save
    self.isPublisher = isPublisher
    self.subscribeTo = subscribeTo
    self.unsubscribeFrom = unsubscribeFrom
    self.showEditDialog = showEditDialog
    self.trackCtaClick = trackCtaClick

    init()

    function init() {
      $rootScope.$on(glAuthService.Events.USER_CHANGE, function() {
        _.remove(self.publishers)
      })
    }

    function getBlank() {
      var publisher = new Publisher()
      publisher.ownerId = subscriberService.getSubscriber().id
      return publisher
    }

    function getById(publisherId) {
      return $q(function(resolve, reject) {
        publishersResource
          .get({ id: publisherId })
          .success(function(x, resp) {
            resolve(new Publisher().deserialize(resp))
          })
          .error(function() {
            reject()
          })
      })
    }

    function getMine() {
      return $q(function(resolve, reject) {
        var ownerId = subscriberService.getSubscriber().id
        publishersResource
          .getByOwner({ ownerId: ownerId })
          .success(function(x, resp) {
            _.remove(self.publishers)
            _.each(resp, function(data) {
              self.publishers.push(new Publisher().deserialize(data))
            })
            resolve(self.publishers)
          })
          .error(function() {
            reject()
          })
      })
    }

    function save(publisher) {
      return $q(function(resolve, reject) {
        var action = publisher.id ? 'update' : 'create'
        publisher.isSaving = true
        publishersResource[action](publisher.serialize())
          .success(function(x, resp) {
            if (resp.id) {
              publisher.id = resp.id
              self.publishers.push(publisher)
            }
            publisher.isSaving = false
            resolve()
          })
          .error(function() {
            publisher.isSaving = false
            reject()
          })
      })
    }

    function isPublisher(publisherId) {
      return _.some(self.publishers, { id: publisherId })
    }

    function subscribeTo(publisher) {
      return edgeService
        .create('follow', 'publisher', publisher.id)
        .then(function(edgeId) {
          publisher.isSubscribed = edgeId
          glAnalytics.track('publisher', 'subscribed', {
            id: publisher.id,
            name: publisher.name,
          })
        })
    }

    function unsubscribeFrom(publisher) {
      return edgeService.remove(publisher.isSubscribed).then(function() {
        publisher.isSubscribed = null
        glAnalytics.track('publisher', 'unsubscribed', {
          id: publisher.id,
          name: publisher.name,
        })
      })
    }

    function showEditDialog(publisher) {
      // prettier-ignore
      var template = [
        '<gl-dialog class="publisher-editor__dialog">',
          '<publisher-editor ',
            'publisher="publisher" ',
            'on-close="dialog.cancel()" ',
            'on-done="dialog.close()" ',
          '/>',
        '</gl-dialog>',
      ]
      return glDialog.show({
        template: template.join(''),
        clickOutsideToClose: false,
        escapeToClose: false,
        locals: {
          publisher: publisher,
        },
      })
    }

    function trackCtaClick(publisher) {
      glAnalytics.track('publisher', 'click-cta', {
        label: publisher.ctaLabel,
        url: publisher.ctaURL,
      })
    }
  }
})()
