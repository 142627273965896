;(function() {
  'use strict'

  SurveyResponseBatchDeleteController.$inject = ["$rootScope", "$q", "glUtils", "surveyService"];
  angular
    .module('core.interaction')
    .controller(
      'SurveyResponseBatchDelete',
      SurveyResponseBatchDeleteController
    )

  /* @ngInject */
  function SurveyResponseBatchDeleteController(
    $rootScope,
    $q,
    glUtils,
    surveyService
  ) {
    var vm = this
    var SPACE_REGEX = /[\n\r\t\s]+/g
    var UUID_REGEX = /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i

    vm.reasonOptions = [
      { id: 'TEST', label: 'Test Response' },
      { id: 'SUSPICIOUS', label: 'Suspicious' },
      { id: 'INCONSISTENT', label: 'Inconsistent' },
      { id: 'OTHER', label: 'Other' },
    ]
    vm.reason = {}
    vm.responseDeletedText = ''
    vm.invalidIds = []

    vm.onPaste = onPaste
    vm.getResponsesIds = getResponsesIds
    vm.submit = submit
    vm.setReason = setReason

    activate()

    /**
     * Initialise
     */
    function activate() {
      var responsesIds = _.map(vm.interactions, 'id')
      vm.textarea = responsesIds.join('\n')
    }

    function onPaste(event) {
      var pasteText = glUtils.getClipboardData(event)
      var pasteValues = pasteText.split(SPACE_REGEX).filter(function(text) {
        return text && UUID_REGEX.test(text)
      })
      if (!vm.textarea) {
        vm.textarea = ''
      } else {
        vm.textarea += '\n'
      }
      vm.textarea += pasteValues.join('\n')
    }

    function getResponsesIds() {
      return vm.textarea ? vm.textarea.split(SPACE_REGEX) : []
    }

    /**
     * @name submit
     * @desc deletes the survey responses
     */
    function submit() {
      vm.invalidIds = null
      vm.isDeleting = true
      var allResponsesIds = getResponsesIds()
      // split the responses ids into chunks. maximum 5 ids per chunk
      var chunks = _.chunk(allResponsesIds, 5)

      doSerialRequests(chunks).then(function(results) {
        vm.invalidIds = []
        var deletedIds = []
        _.each(results, function(result) {
          if (result.invalidIds) {
            vm.invalidIds = _.concat(vm.invalidIds, result.invalidIds)
          } else {
            deletedIds = _.concat(deletedIds, result.responsesIds)
          }
        })

        if (deletedIds.length) {
          // remove deleted ids from textarea
          _.pullAll(allResponsesIds, deletedIds)
          vm.textarea = allResponsesIds.join('\n')
          // broadcast to refresh the survey in the background
          $rootScope.$broadcast('survey-interaction:response-deleted')
        }
        if (!vm.invalidIds.length) {
          // if all requests passed then close the dialog
          vm.$scope.dialog.close()
        }
        vm.isDeleting = false
      })
    }

    function doSerialRequests(chunks) {
      // Adapted from https://decembersoft.com/posts/promises-in-serial-with-array-reduce/
      return _.reduce(
        chunks,
        function(promise, responsesIds) {
          return promise.then(function(results) {
            return batchDelete(responsesIds, results)
          })
        },
        $q.when([])
      )
    }

    function batchDelete(responsesIds, results) {
      var deferred = $q.defer()
      surveyService
        .batchDeleteResponses(
          responsesIds,
          vm.reason.label,
          vm.responseDeletedText
        )
        .then(function() {
          results.push({
            responsesIds: responsesIds,
          })
          deferred.resolve(results)
        })
        .catch(function(invalidIds) {
          results.push({
            responsesIds: responsesIds,
            invalidIds: invalidIds,
          })
          deferred.resolve(results)
        })
      return deferred.promise
    }

    /**
     * @name setReason
     * @desc set the reason
     * @param {String} optionId the option id from the delete reasons dropdown
     */
    function setReason(optionId) {
      if (!optionId) {
        return
      }
      vm.optionId = optionId
      vm.reason = _.cloneDeep(_.find(vm.reasonOptions, { id: optionId }))
    }
  }
})()
