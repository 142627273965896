;(function() {
  'use strict'

  angular.module('glow.reporting').factory('reportSetsService', Service)

  function Service() {
    return {
      getForQuestion: getForQuestion,
    }

    function getForQuestion(question, subtype) {
      var subChoice = subtype && _.find(question.choices, { id: subtype })
      var subStatement = subtype && _.find(question.statements, { id: subtype })
      var multi = question.isMulti
      var stacked = true

      /**
       * Sets:
       * Choices, Rank Numbers
       *
       * Aspects:
       * Statements, Rank Choices
       */
      var sets = []

      // CHOICE

      if (
        [
          'choice',
          'numeric',
          'scale',
          'mood',
          'rating',
          'hiddenvariables',
        ].includes(question.type)
      ) {
        var set = {
          id: null,
          label: null,
          aspects: [],
          color: null,
          order: null,
        }
        // var total = 0
        // _.each(question.choices, function(choice) {
        //   total += question.questionResults.values[choice.id].count
        // })
        _.each(question.choices, function(choice) {
          var total = question.questionResults.answered
          var value = question.questionResults.values[choice.id]
          var aspect = {
            id: choice.id,
            label: choice.displayLabel || choice.option,
            count: value ? value.count : 0,
            percent: value ? round((value.count / total) * 100) || 0 : 0,
            color: choice.color,
            // colort: colors.rgba(choice.color, 0.1),
            order: choice.order,
          }
          var modHidden = choice.data && choice.data.isModHidden // invisible but still used for calculations
          if (!modHidden) {
            set.aspects.push(aspect)
          }
        })
        sets.push(set)
      }

      // NPS

      if (question.type === 'nps') {
        var set = {
          id: null,
          label: null,
          aspects: [],
          color: null,
          order: null,
        }
        question.nps.forEach(function(type, idx) {
          var groups = question.questionResults.npsGroups
          var count = groups[type.key].count
          var total = question.questionResults.answered
          var aspect = {
            id: type.key,
            label: type.label,
            count: count,
            percent: round((count / total) * 100) || 0,
            color: type.color,
            // colort: colors.rgba(type.color, 0.1),
            order: idx,
          }
          set.aspects.push(aspect)
        })
        sets.push(set)
      }

      // MATRIX (all statements OR sub-choice)

      if (question.type === 'matrix' && (!subtype || subChoice)) {
        stacked = !multi
        _.each(question.choices, function(choice) {
          if (subChoice && subChoice !== choice) return
          var set = {
            id: choice.id,
            label: choice.displayLabel || choice.option,
            aspects: [],
            color: choice.color,
            order: choice.order,
          }
          _.each(question.statements, function(statement) {
            var results = question.questionResults
            var value = results.values[statement.id].values[choice.id]
            var totalSelections = 0
            _.each(question.choices, function(choice) {
              totalSelections += results.values[statement.id].values[choice.id].count // prettier-ignore
            })
            var count = 0
            var percent = 0
            if (value) {
              count = value.count
              var val = multi
                ? value.count / results.values[statement.id].count
                : value.count / totalSelections
              percent = round(val * 100) || 0
            }
            var aspect = {
              id: statement.id,
              label: statement.statement,
              count: count,
              percent: percent,
              color: choice.color,
              // colort: colors.rgba(choice.color, 0.1),
              order: statement.order,
            }
            set.aspects.push(aspect)
          })
          var modHidden = choice.data && choice.data.isModHidden // invisible but still used for calculations
          if (!modHidden) {
            sets.push(set)
          }
        })
      }

      // MATRIX (sub-statement)

      if (question.type === 'matrix' && subStatement) {
        var statement = subStatement
        var set = {
          id: null,
          label: null,
          aspects: [],
          color: null,
          order: null,
        }
        question.choices.forEach(function(choice) {
          var results = question.questionResults
          var value = results.values[statement.id].values[choice.id]
          var totalSelections = 0
          _.each(question.choices, function(choice) {
            totalSelections += results.values[statement.id].values[choice.id].count // prettier-ignore
          })
          var count = 0
          var percent = 0
          if (value) {
            count = value.count
            var val = multi
              ? value.count / results.values[statement.id].count
              : value.count / totalSelections
            percent = round(val * 100) || 0
          }
          var aspect = {
            id: choice.id,
            label: choice.displayLabel || choice.option,
            count: count,
            percent: percent,
            color: choice.color,
            // colort: colors.rgba(choice.color, 0.1),
            order: choice.order,
          }
          var modHidden = choice.data && choice.data.isModHidden // invisible but still used for calculations
          if (!modHidden) {
            set.aspects.push(aspect)
          }
        })
        sets.push(set)
      }

      // RANK

      if (question.type === 'rank' && !subtype) {
        _.each(question.ranks, function(rank) {
          var set = {
            id: rank.id,
            label: rank.label,
            aspects: [],
            color: rank.color,
            order: rank.value,
          }
          _.each(question.choices, function(choice) {
            var total = question.questionResults.answered
            var values = question.questionResults.values[choice.id]
            var count = values.rankings[rank.value].count
            var aspect = {
              id: choice.id,
              label: choice.displayLabel || choice.option,
              count: count || 0,
              percent: count ? round((count / total) * 100) || 0 : 0,
              color: rank.color,
              // colort: colors.rgba(rank.color, 0.1),
              order: choice.order,
            }
            set.aspects.push(aspect)
          })
          sets.push(set)
        })
      }

      // RANK (subtype)

      if (question.type === 'rank' && subtype) {
        var rank = question.ranks.find(function(rank) {
          return rank.id === subtype
        })
        var set = {
          id: null,
          label: null,
          aspects: [],
          color: null,
          order: null,
        }
        question.choices.forEach(function(choice) {
          var total = question.questionResults.answered
          var values = question.questionResults.values[choice.id]
          var count = values.rankings[rank.value].count
          var aspect = {
            id: choice.id,
            label: choice.displayLabel || choice.option,
            count: count || 0,
            percent: count ? round((count / total) * 100) || 0 : 0,
            color: choice.color,
            // colort: colors.rgba(choice.color, 0.1),
            order: choice.order,
          }
          set.aspects.push(aspect)
        })
        sets.push(set)
      }

      // CONSTANT SUM (non-matrix)

      if (question.type === 'constantsum' && !question.isMatrix) {
        var set = {
          id: null,
          label: null,
          aspects: [],
          color: null,
          order: null,
        }
        question.choices.forEach(function(choice) {
          var total = question.questionResults.total
          var result = question.questionResults.values[choice.id]
          var count = result.total
          var aspect = {
            id: choice.id,
            label: choice.displayLabel || choice.option,
            count: count || 0,
            percent: count ? round((count / total) * 100) || 0 : 0,
            color: choice.color,
            // colort: colors.rgba(choice.color, 0.1),
            order: choice.order,
          }
          set.aspects.push(aspect)
        })
        sets.push(set)
      }

      // CONSTANT SUM (matrix)

      if (question.type === 'constantsum' && question.isMatrix && !subtype) {
        _.each(question.choices, function(choice) {
          var set = {
            id: choice.id,
            label: choice.displayLabel || choice.option,
            aspects: [],
            color: choice.color,
            order: choice.order,
          }
          _.each(question.statements, function(statement) {
            var total = question.questionResults.values[statement.id].total
            var count =
              question.questionResults.values[statement.id].values[choice.id]
                .total
            var aspect = {
              id: statement.id,
              label: statement.statement,
              count: count || 0,
              percent: round((count / total) * 100) || 0,
              color: choice.color,
              // colort: colors.rgba(choice.color, 0.1),
              order: statement.order,
            }
            set.aspects.push(aspect)
          })
          sets.push(set)
        })
      }

      // CONSTANT SUM (subtype)

      if (question.type === 'constantsum' && question.isMatrix && subtype) {
        var statement = question.statements.find(function(statement) {
          return statement.id === subtype
        })
        var set = {
          id: null,
          label: null,
          aspects: [],
          color: null,
          order: null,
        }
        question.choices.forEach(function(choice) {
          var total = question.questionResults.values[statement.id].total
          var result =
            question.questionResults.values[statement.id].values[choice.id]
          var count = result.total
          var aspect = {
            id: choice.id,
            label: choice.displayLabel || choice.option,
            count: count || 0,
            percent: count ? round((count / total) * 100) || 0 : 0,
            color: choice.color,
            // colort: colors.rgba(choice.color, 0.1),
            order: choice.order,
          }
          set.aspects.push(aspect)
        })
        sets.push(set)
      }

      // SCORE (non-matrix)

      if (question.type === 'score' && !question.isMatrix) {
        var set = {
          id: null,
          label: null,
          aspects: [],
          color: null,
          order: null,
        }
        question.choices.forEach(function(choice) {
          var total = question.questionResults.answered
          var value = question.questionResults.values[choice.id]
          var aspect = {
            id: choice.id,
            label: choice.displayLabel || choice.option,
            count: value ? value.count : 0,
            percent: value ? round((value.count / total) * 100) || 0 : 0,
            color: choice.color,
            // colort: colors.rgba(choice.color, 0.1),
            order: choice.order,
          }
          set.aspects.push(aspect)
        })
        sets.push(set)
      }

      // SCORE (matrix)

      if (question.type === 'score' && question.isMatrix && !subtype) {
        question.choices.forEach(function(choice) {
          var set = {
            id: choice.id,
            label: choice.displayLabel || choice.option,
            aspects: [],
            color: choice.color,
            order: choice.order,
          }
          _.each(question.statements, function(statement) {
            var results = question.questionResults
            var values = results.values[statement.id].values
            var value = results.values[statement.id].values[choice.id]
            var totalSelections = 0
            _.each(values, function(value, choiceId) {
              totalSelections += value.count
            })
            var count = 0
            var percent = 0
            if (value) {
              count = value.count
              var val = value.count / totalSelections
              percent = round(val * 100) || 0
            }
            var aspect = {
              id: statement.id,
              label: statement.statement,
              count: count,
              percent: percent,
              color: choice.color,
              // colort: colors.rgba(choice.color, 0.1),
              order: statement.order,
            }
            set.aspects.push(aspect)
          })
          sets.push(set)
        })
      }

      // SCORE (subtype)

      if (question.type === 'score' && question.isMatrix && subtype) {
        var statement = question.statements.find(function(statement) {
          return statement.id === subtype
        })
        var set = {
          id: null,
          label: null,
          aspects: [],
          color: null,
          order: null,
        }
        question.choices.forEach(function(choice) {
          var results = question.questionResults
          var values = results.values[statement.id].values
          var value = results.values[statement.id].values[choice.id]
          var totalSelections = 0
          _.each(values, function(value, choiceId) {
            totalSelections += value.count
          })
          var count = 0
          var percent = 0
          if (value) {
            count = value.count
            var val = value.count / totalSelections
            percent = round(val * 100) || 0
          }
          var aspect = {
            id: choice.id,
            label: choice.displayLabel || choice.option,
            count: count,
            percent: percent,
            color: choice.color,
            // colort: colors.rgba(choice.color, 0.1),
            order: choice.order,
          }
          set.aspects.push(aspect)
        })
        sets.push(set)
      }

      // cross counts
      if (sets.length) {
        var crossCounts = {}
        sets[0].aspects.forEach(function(tAspect) {
          var crossCount = 0
          sets.forEach(function(set) {
            var aspect = set.aspects.find(function(aspect) {
              return aspect.id === tAspect.id
            })
            crossCount += aspect.count
          })
          crossCounts[tAspect.id] = crossCount
        })
        sets.forEach(function(set) {
          set.aspects.forEach(function(aspect) {
            aspect.crossCount = crossCounts[aspect.id]
          })
        })
      }

      return {
        sets: sets,
        stacked: stacked,
      }
    }

    function round(num) {
      // utility to ensure we use a consistent rounding format
      return _.round(num, 1)
    }
  }
})()
