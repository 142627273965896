;(function() {
  'use strict'

  kitEditorConfig.$inject = ["$stateProvider"];
  angular.module('app.kits-edit', []).config(kitEditorConfig)

  /* @ngInject */
  function kitEditorConfig($stateProvider) {
    $stateProvider.state('kits-edit', {
      url: '/kits/:kitId?cloneKitId',
      params: {
        kitId: { dynamic: true },
        cloneKitId: { dynamic: true },
      },
      parent: 'portal',
      templateUrl: 'kits-edit.html',
      controller: 'KitsEditController',
      controllerAs: '$ctrl',
      title: 'Edit Kit',
    })
  }
})()
