;(function() {
  'use strict'

  Controller.$inject = ["$state", "accountService"];
  angular.module('app.core').controller('Home', Controller)

  /* @ngInject */
  function Controller($state, accountService) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.getStarted = getStarted

    ctrl.companies = [
      { name: 'Mondelez', imageUrl: '/img/trusted-mondelez.png' },
      { name: 'Bain', imageUrl: '/img/trusted-bain.png' },
      {
        name: 'Tourism Australia',
        imageUrl: '/img/trusted-tourism-australia.png',
      },
      { name: 'Ogilvy', imageUrl: '/img/trusted-ogilvy.png' },
      { name: 'Reckitt', imageUrl: '/img/trusted-reckitt.png' },
      { name: 'Havas', imageUrl: '/img/trusted-havas.png' },
      { name: 'Accenture', imageUrl: '/img/trusted-accenture.png' },
      { name: 'Employment Hero', imageUrl: '/img/trusted-employment-hero.png' },
      { name: 'Lego', imageUrl: '/img/trusted-lego.png' },
      { name: 'Nous', imageUrl: '/img/trusted-nous.png' },
      { name: 'EssenceMediacom', imageUrl: '/img/trusted-emc.png' },
      { name: 'Mars', imageUrl: '/img/trusted-mars.png' },
    ]

    function onInit() {
      // ...
    }

    function getStarted(hostingCreditPurchase, currency) {
      accountService.showAuthDialog('signup').then(function(outcome) {
        // logged in? go to dashboard
        if (outcome === 'logged-in') {
          $state.go('dashboard')
          if (hostingCreditPurchase) {
            purchaseHostingCredit(hostingCreditPurchase, currency)
          }
        }
        // signed up?
        if (outcome === 'signed-up') {
          // if user didn't select purchase credits, show the
          // credit purchase dialog
          if (!hostingCreditPurchase) {
            $state.go('dashboard')
            accountService.promptUpgrade({ context: 'welcome' })
            return
          }
          // if user selected purchase credits, show the
          // purchase dialog
          purchaseHostingCredit(hostingCreditPurchase, currency)
        }
      })
    }

    function purchaseHostingCredit(hostingCreditPurchase, currency) {
      accountService
        .showPurchaseDialog({
          type: 'hosting-credit',
          currency: currency,
          hostingCreditPurchase: hostingCreditPurchase,
          context: 'welcome',
        })
        .finally(function() {
          // go to dashboard even if they cancel payment
          $state.go('dashboard')
        })
    }
  }
})()
