/* globals docx */
;(function() {
  'use strict'

  Service.$inject = ["glUtils"];
  angular.module('core.services').factory('DOCXService', Service)

  /* @ngInject */
  function Service(glUtils) {
    var DocxObjectType = {
      DOCUMENT: 'DOCUMENT',
      TABLE: 'TABLE',
      TABLE_ROW: 'TABLE_ROW',
      TABLE_CELL: 'TABLE_CELL',
      PARAGRPAH: 'PARAGRPAH',
      TEXT: 'TEXT',
      IMAGE: 'IMAGE',
      EXTERNAL_HYPERLINK: 'EXTERNAL_HYPERLINK',
    }

    // /* --- DOCX API enums --- */
    // These ENUMS value should match with docx API enums

    // https://docx.js.org/api/enums/AlignmentType.html
    var AlignmentType = {
      BOTH: 'both',
      CENTER: 'center',
      DISTRIBUTE: 'distribute',
      END: 'end',
      JUSTIFIED: 'both',
      LEFT: 'left',
      RIGHT: 'right',
      START: 'start',
    }

    // https://docx.js.org/api/enums/VerticalAlign.html
    var VerticalAlign = {
      BOTH: 'both',
      BOTTOM: 'bottom',
      CENTER: 'center',
      TOP: 'top',
    }

    // https://docx.js.org/api/enums/WidthType.html
    var WidthType = {
      AUTO: 'auto',
      DXA: 'dxa',
      NIL: 'nil',
      PERCENTAGE: 'pct',
    }

    // https://docx.js.org/api/enums/HeadingLevel.html
    var HeadingLevel = {
      HEADING_1: 'Heading1',
      HEADING_2: 'Heading2',
      HEADING_3: 'Heading3',
      HEADING_4: 'Heading4',
      HEADING_5: 'Heading5',
      HEADING_6: 'Heading6',
      TITLE: 'Title',
    }

    return {
      DocxObjectType: DocxObjectType,
      AlignmentType: AlignmentType,
      VerticalAlign: VerticalAlign,
      WidthType: WidthType,
      HeadingLevel: HeadingLevel,
      createDocument: createDocument,
      createTable: createTable,
      createRow: createRow,
      createCell: createCell,
      createParagraph: createParagraph,
      createText: createText,
      createHyperLink: createHyperLink,
      createImage: createImage,
      convertPxtoPt: convertPxtoPt,
      generate: generate,
    }

    function createDocument(options) {
      // options: https://docx.js.org/api/classes/Document.html#constructor
      return { type: DocxObjectType.DOCUMENT, options: options }
    }

    function createTable(options) {
      // options: https://docx.js.org/api/interfaces/ITableOptions.html
      return { type: DocxObjectType.TABLE, options: options }
    }

    function createRow(options) {
      // options: https://docx.js.org/api/interfaces/ITableRowOptions.html
      return { type: DocxObjectType.TABLE_ROW, options: options }
    }

    function createCell(options) {
      // options: https://docx.js.org/api/interfaces/ITableCellOptions.html
      return { type: DocxObjectType.TABLE_CELL, options: options }
    }

    function createParagraph(options) {
      // options: https://docx.js.org/api/interfaces/IParagraphOptions.html
      return { type: DocxObjectType.PARAGRPAH, options: options }
    }

    function createText(options) {
      // options: https://docx.js.org/api/interfaces/IRunOptions.html
      return { type: DocxObjectType.TEXT, options: options }
    }

    function createHyperLink(options) {
      // options: https://docx.js.org/api/classes/ExternalHyperlink.html#constructor
      return { type: DocxObjectType.EXTERNAL_HYPERLINK, options: options }
    }

    function createImage(options) {
      // options: https://docx.js.org/api/interfaces/IImageOptions.html
      return { type: DocxObjectType.IMAGE, options: options }
    }

    function convertPxtoPt(numberInPx) {
      // 1px === 0.75pt
      return numberInPx * 0.75 + 'pt'
    }

    // this function can be later moved to node to parse the docx JSON schema
    // into actual DOCX objects and generate the doc
    function generate(data, fileName) {
      function parse(obj) {
        // if it's not an object then return back the value
        if (!_.isObject(obj)) {
          return obj
        }
        if (obj.type && DocxObjectType[obj.type]) {
          var options = parse(obj.options)
          switch (obj.type) {
            case DocxObjectType.DOCUMENT:
              return new docx.Document(options)
            case DocxObjectType.TABLE:
              return new docx.Table(options)
            case DocxObjectType.TABLE_ROW:
              return new docx.TableRow(options)
            case DocxObjectType.TABLE_CELL:
              return new docx.TableCell(options)
            case DocxObjectType.PARAGRPAH:
              return new docx.Paragraph(options)
            case DocxObjectType.TEXT:
              return new docx.TextRun(options)
            case DocxObjectType.IMAGE:
              return new docx.ImageRun(options)
            case DocxObjectType.EXTERNAL_HYPERLINK:
              return new docx.ExternalHyperlink(options)
          }
        } else {
          // loop through the object and parse the value
          _.each(obj, function(value, key) {
            obj[key] = parse(value)
          })
          return obj
        }
      }
      var doc = parse(data)
      return docx.Packer.toBlob(doc).then(function(blob) {
        glUtils.downloadFile(blob, null, fileName)
      })
    }
  }
})()
