;(function() {
  'use strict'

  Controller.$inject = ["$timeout", "glDialog", "userService"];
  angular.module('app.core').component('originButton', {
    controller: Controller,
    templateUrl: 'origin-button.html',
    transclude: true,
    bindings: {
      survey: '<',
    },
  })

  /* @ngInject */
  function Controller($timeout, glDialog, userService) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.onClick = onClick

    function onInit() {
      ctrl.clicks = 0
      ctrl.isSSR = userService.isSSR()
    }

    function onClick() {
      if (!ctrl.isSSR) return
      $timeout.cancel(ctrl.timer)
      ctrl.clicks++
      if (ctrl.clicks === 5) {
        ctrl.clicks = 0
        open()
        return
      }
      ctrl.timer = $timeout(function() {
        ctrl.clicks = 0
      }, 1000)
    }

    function open() {
      // prettier-ignore
      var template = [
        '<gl-dialog class="origin-viewer__dialog">',
          '<g-dialog-header ',
            'title="Origin" ',
            'on-close="dialog.cancel()" ',
          '></g-dialog-header>',
          '<origin-viewer origins="origins" pre="pre" />',
        '</gl-dialog>',
      ]
      glDialog.show({
        template: template.join(' '),
        clickOutsideToClose: true,
        locals: {
          origins: ctrl.survey.origins,
          pre: ctrl.survey.originsPre,
        },
      })
    }
  }
})()
