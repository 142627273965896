/* globals Masonry */
;(function() {
  'use strict'

  Controller.$inject = ["$timeout", "$interval", "$element", "$rootScope"];
  angular
    .module('core.glMasonry')
    .component('glMasonry', {
      templateUrl: 'gl-masonry.template.html',
      bindings: {
        space: '<',
      },
      transclude: true,
      controller: Controller,
    })
    .component('glMasonryItem', {
      template: '<div ng-transclude/>',
      transclude: true,
      require: {
        masonryCtrl: '^glMasonry',
      },
      /* @ngInject */
      controller: ["$element", function($element) {
        var ctrl = this

        ctrl.$onInit = onInit
        ctrl.$onDestroy = onDestroy

        function onInit() {
          $element.css({
            paddingRight: ctrl.masonryCtrl.spacePx,
            paddingBottom: ctrl.masonryCtrl.spacePx,
          })
          ctrl.masonryCtrl.update()
        }

        function onDestroy() {
          ctrl.masonryCtrl.update()
        }
      }],
    })

  /* @ngInject */
  function Controller($timeout, $interval, $element, $rootScope) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.$onDestroy = onDestroy

    $rootScope.$on('gl-masonry:update-hint', function() {
      $timeout(update)
    })

    function onInit() {
      ctrl.spacePx = (ctrl.space || 0) + 'px'
      ctrl.update = _.debounce(update, 100)
      ctrl.refresher = $interval(update, 1500)
    }

    function init() {
      // add a sizer if one wasnt added manually
      var size = $element.find('gl-masonry-size')
      if (!size.length) {
        $element.children().prepend(angular.element('<gl-masonry-size/>'))
      }

      $element.find('gl-masonry-size').css({
        paddingRight: ctrl.spacePx,
        paddingBottom: ctrl.spacePx,
      })

      $element.css({ marginRight: '-' + ctrl.spacePx })

      var elem = $element[0].querySelectorAll('.gl-masonry')[0]
      ctrl.masonry = new Masonry(elem, {
        itemSelector: 'gl-masonry-item',
        columnWidth: 'gl-masonry-size',
        percentPosition: true,
        // transitionDuration: 0
      })

      ctrl.hasInit = true
    }

    function update() {
      if (!ctrl.hasInit) {
        init()
      } else {
        // var start = performance.now();
        ctrl.masonry.reloadItems()
        ctrl.masonry.layout()
        // console.log(performance.now() - start);
        // ~1.5ms
      }
    }

    function onDestroy() {
      $interval.cancel(ctrl.refresher)
    }
  }
})()
