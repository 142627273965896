;(function() {
  'use strict'

  Service.$inject = ["$q", "$window", "api", "configService", "surveyServiceNext", "userService", "surveyTemplatesResource", "Survey"];
  angular.module('core.services').factory('surveyTemplateService', Service)

  /* @ngInject */
  function Service(
    $q,
    $window,
    api,
    configService,
    surveyServiceNext,
    userService,
    surveyTemplatesResource,
    Survey
  ) {
    return {
      get: get,
      getById: getById,
      getByTags: getByTags,
      getAvailable: getAvailable,
      getByKit: getByKit,
      install: install,
      preview: preview,
      fetchVersion: fetchVersion,
    }

    function get(subscriberId) {
      return $q(function(resolve, reject) {
        surveyTemplatesResource
          .get({ ownerId: subscriberId })
          .success(onSuccess)
          .error(onError)

        function onSuccess(templatesData) {
          var templates = _.map(templatesData, function(templateData) {
            return prepareTemplate(templateData)
          })
          resolve(templates)
        }

        function onError() {
          reject()
        }
      })
    }

    function getById(templateId) {
      return $q(function(resolve, reject) {
        // TODO: this should be in the route controller
        if (!templateId) {
          var survey = new Survey({ isTemplate: true })
          survey.kits = []
          return resolve(survey)
        }

        surveyTemplatesResource
          .get({ id: templateId })
          .success(onSuccess)
          .error(onError)

        function onSuccess(templateData) {
          resolve(prepareTemplate(templateData))
        }

        function onError() {
          reject()
        }
      })
    }

    function getByTags(tags, subscriberId) {
      return $q(function(resolve, reject) {
        if (_.isString(tags)) {
          tags = [tags]
        }
        if (!tags.length) {
          return reject()
        }
        var filters = _.map(tags, function(tag) {
          return 'tags eq ' + tag
        })
        var options = {
          ownerId: subscriberId,
          filter: filters.join(' or '),
        }
        surveyTemplatesResource
          .get(options)
          .success(onSuccess)
          .error(onError)

        function onSuccess(templatesData) {
          var templates = _.map(templatesData, function(templateData) {
            return prepareTemplate(templateData)
          })
          resolve(templates)
        }

        function onError() {
          reject()
        }
      })
    }

    function getAvailable(subscriberId) {
      return $q(function(resolve, reject) {
        if (!subscriberId) {
          return reject()
        }

        surveyTemplatesResource
          .getAvailable({ groupId: subscriberId })
          .success(onSuccess)
          .error(onError)

        function onSuccess(templatesData) {
          var templates = _.map(templatesData, function(templateData) {
            return prepareTemplate(templateData)
          })
          resolve(templates)
        }

        function onError() {
          reject()
        }
      })
    }

    function getByKit(kitId) {
      return $q(function(resolve, reject) {
        var options = {
          kitId: kitId,
          orderby: 'createdAt desc',
        }
        surveyTemplatesResource
          .getForKit(options)
          .success(onSuccess)
          .error(onError)

        function onSuccess(x, templatesData) {
          var templates = _.map(templatesData, function(templateData) {
            return prepareTemplate(templateData)
          })
          resolve(templates)
        }

        function onError() {
          reject()
        }
      })
    }

    function install(templates) {
      if (!userService.isLoggedIn()) {
        return $q.reject()
      }
      if (!templates) {
        return $q.reject()
      }
      if (!_.isArray(templates)) {
        templates = [templates]
      }

      // convert to survey format
      _.each(templates, function(template) {
        template.toNewSurvey()
      })

      var promises = _.map(templates, surveyServiceNext.save)

      // create them
      return $q.all(promises)
    }

    function prepareTemplate(templateData) {
      return new Survey({ isTemplate: true }).deserialize(templateData)
    }

    function preview(template) {
      var url = configService.getUserPortalUrl(
        '/survey/' + template.id + '?template=true&readOnly=true'
      )
      $window.open(url, '_blank')
    }

    function fetchVersion(template) {
      return api.surveyTemplates
        .getVersion({ id: template.id })
        .then(function(data) {
          template.serverVersion = data.version
          return data.version
        })
    }
  }
})()
