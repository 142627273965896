;(function() {
  'use strict'

  angular.module('app.products').controller('Products', Controller)

  /* @ngInject */
  function Controller() {
    var ctrl = this

    init()

    function init() {
      ctrl.subTitle =
        'Engage your audience at the best time possible - when they have your product in their hands.'
      ctrl.infoUrl = 'https://glo.link/products-info'
    }
  }
})()
