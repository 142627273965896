;(function() {
  'use strict'

  Controller.$inject = ["$scope", "$q", "$state", "$timeout", "glPrefs", "glToast", "glDialog", "glUtils", "glTips", "glAnalytics", "viewPicker", "viewEditor", "logicEditor", "scrollService", "userService", "Survey", "View", "surveyExporter", "surveyServiceNext", "accountService", "projectService", "questionTemplateService", "featureService", "translationService", "uacService", "findAndReplaceService", "surveyDOCXExporter", "reportMediaBtn"];
  angular.module('app.survey-edit').component('surveyEditor', {
    controller: Controller,
    templateUrl: 'survey-editor.template.html',
    bindings: {
      survey: '=',
      channels: '<',
      translations: '<',
      onDirtyChange: '&',
      onCreate: '&',
      onRefresh: '&',
      onSave: '&',
    },
  })

  /* @ngInject */
  function Controller(
    $scope,
    $q,
    $state,
    $timeout,
    glPrefs,
    glToast,
    glDialog,
    glUtils,
    glTips,
    glAnalytics,
    viewPicker,
    viewEditor,
    logicEditor,
    scrollService,
    userService,
    Survey,
    View,
    surveyExporter,
    surveyServiceNext,
    accountService,
    projectService,
    questionTemplateService,
    featureService,
    translationService,
    uacService,
    findAndReplaceService,
    surveyDOCXExporter,
    reportMediaBtn
  ) {
    var prefs = glPrefs.create('survey-editor')
    var ctrl = this
    var broadcast = glUtils.broadcaster('survey-editor')

    ctrl.ItemClassPrefix = 'view-'
    ctrl.isSSR = userService.isSSR()
    ctrl.isLoggedIn = userService.isLoggedIn()
    ctrl.isMacOS = navigator.userAgent.indexOf('Mac') !== -1

    // Methods

    ctrl.$onInit = onInit
    ctrl.$onDestroy = onDestroy
    ctrl.showViewPicker = showViewPicker
    ctrl.removeViews = removeViews
    ctrl.toggleSelectView = toggleSelectView
    ctrl.moveViews = moveViews
    ctrl.duplicateViews = duplicateViews
    ctrl.getSelectedViews = getSelectedViews
    ctrl.getSelectedInfo = getSelectedInfo
    ctrl.clearSelectedViews = clearSelectedViews
    ctrl.canMoveUp = canMoveUp
    ctrl.canMoveDown = canMoveDown
    ctrl.canRemove = canRemove
    ctrl.cloneAsTemplate = cloneAsTemplate
    ctrl.save = save
    ctrl.canPreview = canPreview
    ctrl.preview = preview
    ctrl.showExporter = surveyExporter.showExporter
    ctrl.showImporter = surveyExporter.showImporter
    ctrl.canExport = canExport
    ctrl.canImport = canImport
    ctrl.canClone = canClone
    ctrl.canCloneAsTemplate = canCloneAsTemplate
    ctrl.clone = clone
    ctrl.changeType = changeType
    ctrl.saveQuestionAsTemplate = saveQuestionAsTemplate
    ctrl.editIntro = editIntro
    ctrl.editView = editView
    ctrl.editLogic = editLogic
    ctrl.isUnloadConfirmation = isUnloadConfirmation
    ctrl.getLOIEstimate = getLOIEstimate
    ctrl.editSettings = editSettings
    ctrl.editTranslations = editTranslations
    ctrl.showLoopDialog = showLoopDialog
    ctrl.showFindAndReplaceDialog = findAndReplaceService.showDialog
    ctrl.exportDOCX = surveyDOCXExporter.export

    // reuse the same media icons from analysis chart media button
    ctrl.mediaIcons = reportMediaBtn.Icons
    ctrl.getSectionMediaType = reportMediaBtn.getTypeBySectionMediaType

    $scope.$watch('$ctrl.form.$dirty', function(value) {
      ctrl.onDirtyChange({ $dirty: value })
    })

    function onInit() {
      window.addEventListener('keydown', onKeyDown)
      $scope.$on(Survey.Events.VIEW_ADDED, onViewAdded)

      if (ctrl.survey.didMigrateLogic && !prefs.get('seenLogicMigrateMsg')) {
        console.log('DID MIGRATE LOGIC')
        glDialog
          .alert(
            'Feature Update: Redesigned Survey Logic',
            "Good news! The way logic is set up in Glow has improved tremendously and it is now available for you to explore. But don't worry, we've taken care of logic in your existing surveys and it's now set up for you in the new way automatically. If you have any questions, please get in touch."
          )
          .finally(function() {
            prefs.set('seenLogicMigrateMsg', true)
            ctrl.survey.didMigrateLogic = false
          })
      }

      ctrl.statusText = [
        ctrl.survey.hasActiveChannels()
          ? 'This survey is now recording responses.'
          : 'This survey has collected responses.',
        'Some options are disabled to maintain the consistency of your reporting data.',
      ].join(' ')
      ctrl.statusIconColor = ctrl.survey.hasActiveChannels()
        ? 'GREEN_SEAFOAM'
        : 'GREY_SILVER'

      buildTitle()
      glTips.next()

      ctrl.surveyLengthLabels = _.map(
        ctrl.survey.lengthOfInterview.types,
        'typeLabel'
      )

      ctrl.isForbidden = ctrl.isLoggedIn && !uacService.canManageSurveys()
      ctrl.logicHidden = true // hide logic summary by default
      ctrl.moveAmountQueue = [] // array of amount for moving views

      checkActiveTranslatedChannels()
    }

    function checkActiveTranslatedChannels() {
      ctrl.hasActiveTranslatedChannels = _.some(ctrl.channels, function(
        channel
      ) {
        return channel.translationId && channel.enabled
      })
      if (ctrl.hasActiveTranslatedChannels) {
        glDialog.alert(
          'Editing Disabled',
          'You cannot edit a live survey that uses a translation as it will result in an incomplete translation. If you need to modify the survey you will need to stop all live response channels that use translation.'
        )
      }
    }

    function buildTitle() {
      if (ctrl.survey.isTemplate) {
        ctrl.editorTitle = ctrl.survey.id ? 'Edit Template' : 'New Template'
      } else {
        ctrl.editorTitle = ctrl.survey.id ? 'Edit Survey' : 'New Survey'
      }
    }

    function track(action) {
      // Potential for title to be blank but low impact from this
      glAnalytics.track('survey', action, _.kebabCase(ctrl.survey.title))
    }

    function removeViews() {
      var selected = getSelectedViews()
      var viewIndexesById = {}
      // store selected view indexes for undo action
      _.each(selected, function(view) {
        viewIndexesById[view.value.id] = view.getIndex()
      })
      // remove selected views
      _.each(selected, function(view) {
        ctrl.survey.removeView(view)
      })
      ctrl.form.$setDirty()

      var options = {
        timeout: 10000,
        maxOpened: 10,
      }
      glToast.action(
        'Selected item(s) deleted',
        'Undo',
        function() {
          // on undo, restore the removed views
          _.each(selected, function(view) {
            var index = viewIndexesById[view.value.id]
            ctrl.survey.addView(view, index, true)
          })
        },
        options
      )
    }

    function showViewPicker(index) {
      viewPicker.show().then(function(response) {
        var view
        switch (response.type) {
          case 'question-type':
          case 'question':
            view = addQuestion(response.value, index)
            editView(null, view)
            break
          case 'section':
            view = addSection(index)
            editView(null, view)
            break
          case 'views':
            addViews(response.value, index)
            break
        }
      })
    }

    function addQuestion(questionOrType, index) {
      var view = ctrl.survey.addQuestion(questionOrType, index)
      ctrl.form.$setDirty()
      broadcast('question-added')
      return view
    }

    function addSection(index) {
      var view = ctrl.survey.addSection(null, index)
      ctrl.form.$setDirty()
      broadcast('section-added')
      return view
    }

    function addViews(value, index) {
      index = _.isNumber(index) ? index : ctrl.survey.views.length
      value.views.forEach(function(view) {
        if (view.isSection()) {
          ctrl.survey.addSection(view.value, index)
        }
        if (view.isQuestion()) {
          ctrl.survey.addQuestion(view.value, index)
        }
        index++
      })
      value.viewGroups.forEach(function(viewGroup) {
        ctrl.survey.viewGroups.push(viewGroup)
      })
      value.shuffleGroups.forEach(function(shuffleGroup) {
        ctrl.survey.shuffleGroups.push(shuffleGroup)
      })
    }

    function onViewAdded(e, view, index) {
      scrollService.animateTo('.' + ctrl.ItemClassPrefix + index)
    }

    function toggleSelectView(view) {
      if (ctrl.isMovingViews) return
      view.selected = !view.selected
    }

    function moveViews(event, amount) {
      if (event && event.shiftKey) {
        var direction = amount < 0 ? 'up' : 'down'
        return showMoveDialog(direction)
      }

      // add move amount to queue to be processed
      ctrl.moveAmountQueue.push(amount)

      // if queue is being processed, return
      if (ctrl.isMovingViews) return

      // start processing queue
      ctrl.isMovingViews = true
      processMoveQueue().then(function() {
        ctrl.form.$setDirty()
        ctrl.isMovingViews = false
      })
    }

    function processMoveQueue() {
      return $q(function(resolve) {
        if (!ctrl.moveAmountQueue.length) {
          // no more queue to process. resolve
          return resolve()
        }
        var amount = ctrl.moveAmountQueue.shift()
        // check if amount is actionable (can move up/down)
        if (
          (amount < 0 && !ctrl.canMoveUp()) ||
          (amount > 0 && !ctrl.canMoveDown())
        ) {
          // if amount is not actionable, move on to the next queue
          return processMoveQueue().then(resolve)
        }
        // action the amount and then move on to the next queue
        doMoveViews(amount)
          .then(processMoveQueue)
          .then(resolve)
      })
    }

    function doMoveViews(amount) {
      return $q(function(resolve) {
        var selected = getSelectedViews()
        var itemIndexes = selected.map(function(view) {
          return view.getIndex()
        })
        var itemsGapSize = 24 // combined size of add button icon plus paddings in between view blocks
        var options = {
          itemClassPrefix: ctrl.ItemClassPrefix,
          itemIndexes: itemIndexes,
          amount: amount,
          itemsGapSize: itemsGapSize,
        }
        glUtils.bulkAnimateMove(options).then(function() {
          ctrl.survey.bulkMoveViews(selected, amount)
          // set timeout to give the template some time to adjust
          // with the new index positions after move
          $timeout(function() {
            scrollService.animateTo(
              '.' + ctrl.ItemClassPrefix + _.head(selected).getIndex()
            )
            resolve()
          }, 100)
        })
      })
    }

    function showMoveDialog(direction) {
      // prettier-ignore
      var template = [
        '<gl-dialog class="move-dialog__dialog">',
          '<move-dialog ',
            'direction="{{ direction }}"',
            'survey="survey"',
            'selected-views="selectedViews"',
            'on-done="dialog.close($amount)"',
            'on-cancel="dialog.cancel()"',
          '/>',
        '</gl-dialog>',
      ]
      var options = {
        template: template.join(''),
        clickOutsideToClose: false,
        escapeToClose: true,
        locals: {
          direction: direction,
          survey: ctrl.survey,
          selectedViews: getSelectedViews(),
        },
      }
      return glDialog.show(options).then(function(amount) {
        if (_.isNumber(amount)) {
          moveViews(null, amount)
        }
      })
    }

    function duplicateViews() {
      _.each(getSelectedViews(), function(view) {
        var clonedView = ctrl.survey.cloneView(view)
        clonedView.selected = true
        view.selected = false
      })
      ctrl.form.$setDirty()
    }

    function getSelectedViews() {
      return ctrl.survey.views.filter(function(view) {
        return view.selected
      })
    }

    function getSelectedInfo() {
      return (
        getSelectedViews().length +
        ' item' +
        (getSelectedViews().length > 1 ? 's' : '') +
        ' selected.'
      )
    }

    function clearSelectedViews() {
      _.each(getSelectedViews(), function(view) {
        view.selected = false
      })
    }

    function canMoveUp() {
      var selected = getSelectedViews()
      if (!selected.length) return false
      return ctrl.survey.canMoveUp(_.head(selected))
    }

    function canMoveDown() {
      var selected = getSelectedViews()
      if (!selected.length) return false
      return ctrl.survey.canMoveDown(_.last(selected))
    }

    function canRemove() {
      var selected = getSelectedViews()
      if (!selected.length) return false
      return _.every(selected, function(view) {
        return ctrl.survey.canRemoveView(view)
      })
    }

    /**
     * @name cloneAsTempolate
     * @desc clone this survey as a template
     */
    function cloneAsTemplate() {
      $state.go('survey-template-edit', {
        surveyId: 'new',
        cloneSurveyId: ctrl.survey.id,
      })
    }

    function validateSurvey() {
      if (ctrl.survey.validate()) {
        return $q.resolve()
      } else {
        ctrl.showErrors = true
        scrollService.animateTo('.survey-editor__error')
        return $q.reject()
      }
    }

    function doSave(isExplicit) {
      if (!uacService.canManageSurveys(true)) {
        return $q.reject()
      }
      return $q(function(resolve, reject) {
        var selected = getSelectedViews()
        var isNew = ctrl.survey.isNew()
        ctrl.showErrors = false

        surveyServiceNext
          .save(ctrl.survey)
          .then(function() {
            glToast.show('Your survey has been saved')

            ctrl.form.$setPristine()

            if (isNew) {
              track(
                ctrl.survey.templateId
                  ? 'create-from-template'
                  : 'create-from-scratch'
              )
              buildTitle()
              ctrl.onCreate({ $surveyId: ctrl.survey.id })
            } else {
              ctrl.onSave()
            }

            resolve()

            if (isExplicit === true) {
              broadcast('save')
            }

            // persist views selection
            _.each(selected, function(selectedView) {
              var view = _.find(ctrl.survey.views, {
                value: { id: selectedView.value.id },
              })
              if (view) view.selected = true
            })

            glTips.next()
          })
          .catch(function(data) {
            if (data.status === 409) {
              onSaveConflict(data.data.message)
            } else if (data.errors) {
              ctrl.showErrors = true
              scrollService.animateTo('.survey-editor__error')
            } else {
              glDialog.alert(
                'Error',
                'There was an issue saving your survey. Please try again.'
              )
            }
            reject()
          })
      })
    }

    function onSaveConflict(message) {
      var VERSION_REGEX = /\[\d+\]/
      var serverVersion = message.match(VERSION_REGEX)[0].slice(1, -1)
      ctrl.survey.serverVersion = +serverVersion

      var options = {
        templateUrl: 'survey-conflict-dialog.template.html',
        clickOutsideToClose: false,
        escapeToClose: false,
      }
      glDialog.show(options).then(function(action) {
        switch (action) {
          case 'overwrite':
            ctrl.survey.version = ctrl.survey.serverVersion
            save(true)
            break
          case 'discard':
            ctrl.onRefresh()
            break
        }
      })
    }

    function save(isExplicit) {
      if (ctrl.isLoggedIn && !uacService.canManageSurveys(true)) return

      ctrl.survey.cleanUp()

      return validateSurvey()
        .then(ensureLogin)
        .then(ensureProject)
        .then(function() {
          return doSave(isExplicit)
        })
        .then(function() {
          if (ctrl.didSignup) {
            return accountService.promptUpgrade({ context: 'welcome' })
          }
          // So that future saves don't prompt
          ctrl.didSignup = false
        })
    }

    function ensureLogin() {
      if (ctrl.isLoggedIn) {
        return $q.resolve()
      }
      track('prompt-signup-on-save')
      return accountService
        .showAuthDialog('signup')
        .then(function(outcome) {
          ctrl.didSignup = outcome === 'signed-up'
        })
        .then(getUserInfo)
    }

    function getUserInfo() {
      ctrl.isLoggedIn = userService.isLoggedIn()
      ctrl.isSSR = userService.isSSR()
    }

    function ensureProject() {
      if (ctrl.survey.projectId || ctrl.survey.isTemplate) {
        return $q.resolve()
      }
      return projectService
        .load()
        .then(function(projects) {
          if (!projects.length) {
            if (!uacService.canManageProjects(true)) {
              return $q.reject()
            }
            var project = projectService.create()
            project.name = 'My First Glow Project'
            return projectService.save(project)
          } else if (projects.length === 1) {
            return projects[0]
          } else {
            return projectService
              .pick({ headerTitle: 'Add To Project' })
              .then(function(result) {
                return result.project
              })
          }
        })
        .then(function(project) {
          ctrl.survey.projectId = project.id
        })
    }

    function canPreview() {
      return !ctrl.survey.isNew() // must be saved at least once
    }

    function preview() {
      if (!canPreview()) return
      if (ctrl.form.$dirty) {
        glDialog
          .confirm(
            'Unsaved Changes',
            'Your survey has unsaved changes that will be lost. Do you want to continue?'
          )
          .then(function() {
            ctrl.isPreviewOverlay = true
          })
      } else {
        ctrl.isPreviewOverlay = true
      }
    }

    /**
     * @name canExport
     * @desc Determines whether or not a survey can be exported to JSON
     */
    function canExport() {
      return ctrl.survey.canExport() && ctrl.isSSR
    }

    /**
     * @name canImport
     * @desc Determines whether or not a survey can be imported from a JSON
     */
    function canImport() {
      return ctrl.survey.canImport() && ctrl.isSSR
    }

    function canClone() {
      return ctrl.survey.canClone()
    }

    function canCloneAsTemplate() {
      return ctrl.survey.id && ctrl.isSSR && !ctrl.survey.isTemplate
    }

    function clone() {
      accountService
        .promptUpgrade({
          bypassCondition: featureService.canCloneSurveys() || ctrl.isSSR,
          context: 'clone-survey',
        })
        .then(function() {
          if (!uacService.canManageSurveys(true)) return
          projectService
            .pick({
              headerTitle: 'Duplicate To Project',
            })
            .then(function(result) {
              glAnalytics.track('surveys', 'create-from-survey', ctrl.survey.id)
              $state.go('survey', {
                projectId: result.project.id,
                cloneSurveyId: ctrl.survey.id,
                surveyId: 'new',
              })
            })
        })
    }

    function changeType(view) {
      glDialog
        .confirm(
          'Change Type',
          [
            'Are you sure you want to change the type of this question? ',
            'Only the question title will be transferred to the new type.',
          ].join('')
        )
        .then(function() {
          var index = _.indexOf(ctrl.survey.views, view)
          var title = view.value.title
          viewPicker.showQuestionTypes('Change Type').then(function(response) {
            var newView = addQuestion(response.value, index)
            newView.value.title = title
            newView.selected = view.selected
            ctrl.survey.removeView(view)
          })
        })
    }

    function saveQuestionAsTemplate(question) {
      var options = {
        title: 'Question Template',
        questionTemplate: questionTemplateService.createFromQuestion(question),
      }
      var template = [
        '<gl-dialog>',
        '<g-dialog-header ',
        'title="{{ title }}" ',
        'on-close="dialog.cancel()" ',
        '></g-dialog-header>',
        '<question-templates-editor template="questionTemplate" />',
        '</gl-dialog>',
      ]
      return glDialog.show({ template: template.join(''), locals: options })
    }

    function editIntro() {
      var clickedSave = false
      viewEditor
        .show(ctrl.survey.introView, null, ctrl.survey, ctrl.isForbidden)
        .then(function(data) {
          clickedSave = data.clickedSave
        })
        .finally(function() {
          ctrl.survey.validateIntro()
          if (clickedSave) save(true)
        })
    }

    function editView(event, view) {
      var index = _.indexOf(ctrl.survey.views, view)
      if (event && event.defaultPrevented) {
        return
      }
      var clickedSave = false
      viewEditor
        .show(view, index, ctrl.survey, ctrl.isForbidden)
        .then(function(data) {
          clickedSave = data.clickedSave
          if (data.clickedSaveAsTemplate) {
            saveQuestionAsTemplate(view.value)
          }
          if (data.clickedChangeType) {
            changeType(view)
          }
          if (data.clickedDuplicate) {
            ctrl.survey.cloneView(view)
            ctrl.form.$setDirty()
          }
          if (data.clickedDelete) {
            var viewIndex = view.getIndex()
            var viewTitle = view.getTitleLabel({ number: true })
            ctrl.survey.removeView(view)
            ctrl.form.$setDirty()
            glToast.action(
              viewTitle + ' deleted',
              'Undo',
              function() {
                ctrl.survey.addView(view, viewIndex, true)
              },
              { timeout: 10000, maxOpened: 10 }
            )
          }
        })
        .finally(function() {
          view.value.validate()
          if (clickedSave) save(true)
        })
    }

    function editLogic(view) {
      if (ctrl.survey.queue.isProcessing()) return
      logicEditor
        .show({
          survey: ctrl.survey,
          view: view,
          isForbidden: ctrl.isForbidden,
          onChange: function() {
            ctrl.form.$setDirty()
          },
        })
        .then(function(clickedSave) {
          if (clickedSave) save(true)
        })
    }

    function isUnloadConfirmation() {
      if (ctrl.survey.templateId && ctrl.survey.isNew()) {
        return true
      }
      return ctrl.form && ctrl.form.$dirty
    }

    function getLOIEstimate() {
      return _.round(ctrl.survey.getLOIEstimate('loi widget') / 60, 1)
    }

    function editSettings() {
      var template = [
        '<gl-dialog class="survey-settings__dialog">',
        '<survey-settings ',
        'survey="survey"',
        'is-forbidden="isForbidden"',
        'on-done="dialog.close({settingsUpdated: $isDirty, clickedSave: $clickedSave});" ',
        '/>',
        '</gl-dialog>',
      ]
      var options = {
        template: template.join(''),
        clickOutsideToClose: true,
        escapeToClose: true,
        locals: {
          survey: ctrl.survey,
          isForbidden: ctrl.isForbidden,
        },
      }
      return glDialog.show(options).then(function(data) {
        if (data.clickedSave) save(true)
        return data.settingsUpdated && ctrl.form.$setDirty()
      })
    }

    function editTranslations() {
      translationService.showManager(ctrl.survey)
    }

    function showLoopDialog() {
      // prettier-ignore
      var template = [
        '<gl-dialog class="loop-dialog__dialog">',
          '<loop-dialog ',
            'survey="survey"',
            'on-done="dialog.close()" ',
            'on-cancel="dialog.cancel()" ',
          '/>',
        '</gl-dialog>',
      ]
      var options = {
        template: template.join(''),
        clickOutsideToClose: false,
        escapeToClose: true,
        locals: {
          survey: ctrl.survey,
        },
      }
      return glDialog.show(options)
    }

    function onKeyDown(e) {
      var hasActiveDialog = glDialog.isActive()
      var isInputEl = e.target.localName === 'input'
      var isTextareaEl = e.target.localName === 'textarea'
      if (hasActiveDialog || isInputEl || isTextareaEl) {
        return
      }
      var modifierKey = ctrl.isMacOS ? e.metaKey : e.ctrlKey
      if (modifierKey && e.keyCode === 70) {
        e.preventDefault()
        ctrl.showFindAndReplaceDialog(ctrl.survey)
      }
    }

    function onDestroy() {
      window.removeEventListener('keydown', onKeyDown)
    }
  }
})()
