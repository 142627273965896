;(function() {
  'use strict'

  angular
    .module('glow.reporting.charts')
    .component('reportSubtypeSwitcher', Component())

  /* @ngInject */
  function Component() {
    return {
      controller: Controller,
      templateUrl: 'report-subtype-switcher.html',
      bindings: {
        question: '<',
        value: '<',
        onChange: '&',
      },
    }
  }

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onChanges = onChanges
    ctrl.prev = prev
    ctrl.next = next

    function onChanges(changed) {
      if (!ctrl.hasInit) {
        init()
        ctrl.hasInit = true
      }
      if (changed.value) {
        ctrl.isPrev = getIsPrev()
        ctrl.isNext = getIsNext()
      }
    }

    function init() {
      if (ctrl.question.type === 'rank') {
        ctrl.options = [{ label: 'All Ranks', value: null }]
        ctrl.question.ranks.forEach(function(rank) {
          ctrl.options.push({
            label: rank.label,
            value: rank.id,
          })
        })
      }
      if (ctrl.question.type === 'matrix') {
        ctrl.options = [{ label: 'All Statements', value: null }]
        ctrl.question.statements.forEach(function(statement) {
          ctrl.options.push({
            label: statement.statement,
            value: statement.id,
          })
        })
        ctrl.question.choices.forEach(function(choice) {
          if (choice.data && choice.data.isModHidden) return
          ctrl.options.push({
            label: choice.option,
            value: choice.id,
          })
        })
      }
      if (ctrl.question.type === 'constantsum' && ctrl.question.isMatrix) {
        ctrl.options = [{ label: 'All Statements', value: null }]
        ctrl.question.statements.forEach(function(statement) {
          ctrl.options.push({
            label: statement.statement,
            value: statement.id,
          })
        })
      }
      if (ctrl.question.type === 'score' && ctrl.question.isMatrix) {
        ctrl.options = []
        ctrl.question.statements.forEach(function(statement) {
          ctrl.options.push({
            label: statement.statement,
            value: statement.id,
          })
        })
      }
    }

    function getIsPrev() {
      if (!ctrl.options) return false
      var idx = ctrl.options.findIndex(function(option) {
        return option.value === ctrl.value
      })
      return idx > 0
    }

    function getIsNext() {
      if (!ctrl.options) return false
      var idx = ctrl.options.findIndex(function(option) {
        return option.value === ctrl.value
      })
      return idx < ctrl.options.length - 1
    }

    function prev() {
      if (!ctrl.isPrev) return
      var idx = ctrl.options.findIndex(function(option) {
        return option.value === ctrl.value
      })
      var option = ctrl.options[idx - 1]
      ctrl.onChange({ $value: option.value })
    }

    function next() {
      if (!ctrl.isNext) return
      var idx = ctrl.options.findIndex(function(option) {
        return option.value === ctrl.value
      })
      var option = ctrl.options[idx + 1]
      ctrl.onChange({ $value: option.value })
    }
  }
})()
