;(function() {
  'use strict'

  angular.module('glow.reporting').component('glPagesFilter', Component())

  function Component() {
    return {
      controller: Controller,
      templateUrl: 'pages-filter.template.html',
      bindings: {
        survey: '=',
        filter: '=',
      },
    }
  }

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.toggle = toggle

    function onInit() {
      ctrl.pages = getPages()
      _.each(ctrl.filter.data.values, function(choice) {
        var page = _.find(ctrl.pages, { id: choice.id })
        if (page) {
          page.isSelected = true
        }
      })
    }

    function toggle(page) {
      if (page.isSelected) {
        ctrl.filter.data.values.push(_.cloneDeep(page))
      } else {
        _.remove(ctrl.filter.data.values, { id: page.id })
      }
    }

    function getPages() {
      return _.map(
        ctrl.survey.filterOptions.reportingDimensions.systemReportingDimensions
          .page,
        function(page) {
          return {
            id: page[0],
            label: page[1],
          }
        }
      )
    }
  }
})()
