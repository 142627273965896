;(function() {
  'use strict'

  Controller.$inject = ["$rootScope", "glToast", "surveyService", "userService"];
  angular
    .module('core.interaction')
    .component('surveyResponseDelete', Component())

  /* @ngInject */
  function Component() {
    return {
      controller: Controller,
      templateUrl: 'survey-response-delete.html',
      bindings: {
        interaction: '=',
        canDelete: '<',
      },
    }
  }

  /* @ngInject */
  function Controller($rootScope, glToast, surveyService, userService) {
    var ctrl = this

    // Constants
    ctrl.reasonOptions = [
      { id: 'TEST', label: 'Test Response' },
      { id: 'SUSPICIOUS', label: 'Suspicious' },
      { id: 'INCONSISTENT', label: 'Inconsistent' },
      { id: 'OTHER', label: 'Other' },
    ]

    // Variables
    ctrl.isSSR = userService.isSSR()

    // Methods
    ctrl.submit = submit
    ctrl.setReason = setReason

    ctrl.$onInit = onInit

    function onInit() {
      ctrl.isDeleted = !!ctrl.interaction.responseDeletedAt

      ctrl.reason = ctrl.isDeleted
        ? _.cloneDeep(
            _.find(ctrl.reasonOptions, {
              label: ctrl.interaction.responseDeletedReason,
            })
          )
        : {}
    }

    /**
     * @name submit
     * @desc deletes the survey response
     */
    function submit() {
      ctrl.isDeleting = true
      surveyService
        .deleteResponse(
          ctrl.interaction.surveyResponseId,
          ctrl.interaction.responseDeletedReason,
          ctrl.interaction.responseDeletedText
        )
        .then(function() {
          ctrl.isDeleted = true
          $rootScope.$broadcast('survey-interaction:response-deleted')
        })
        .catch(function() {
          ctrl.isDeleted = false
          glToast.action('Error deleting response', 'Retry', submit)
        })
        .finally(function() {
          ctrl.isDeleting = false
        })
    }

    /**
     * @name setReason
     * @desc sets the deletedReason label onto the interaction
     * @param {String} optionId the option id from the delete reasons dropdown
     */
    function setReason(optionId) {
      if (!optionId) {
        return
      }
      ctrl.optionId = optionId
      ctrl.reason = _.cloneDeep(_.find(ctrl.reasonOptions, { id: optionId }))
      ctrl.interaction.responseDeletedReason = ctrl.reason.label
    }
  }
})()
