;(function() {
  'use strict'

  Controller.$inject = ["channelService"];
  angular.module('glow.reporting').component('glChannelFilter', {
    controller: Controller,
    templateUrl: 'channel-filter.html',
    bindings: {
      survey: '<',
      filter: '<',
    },
  })

  /* @ngInject */
  function Controller(channelService) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.toggle = toggle
    ctrl.isSelected = isSelected

    function onInit() {
      channelService.getBySurvey(ctrl.survey.id, function(
        channels,
        loading,
        error
      ) {
        ctrl.channels = channels
        ctrl.loading = loading
        ctrl.error = error
      })
    }

    function toggle(channel) {
      if (isSelected(channel)) {
        _.remove(ctrl.filter.data.values, function(channelId) {
          return channelId === channel.id
        })
      } else {
        ctrl.filter.data.values.push(channel.id)
      }
    }

    function isSelected(channel) {
      return _.includes(ctrl.filter.data.values, channel.id)
    }
  }
})()
