;(function() {
  'use strict'

  Controller.$inject = ["userService", "glDialog", "glToast", "Link", "linkService", "clipboardService"];
  angular.module('core.linkList').component('glLinkList', Component())

  /* @ngInject */
  function Component() {
    return {
      controller: Controller,
      templateUrl: 'link-list.template.html',
      bindings: {
        links: '<',
        defaultLongUrl: '<',
        onCreateUpdateLink: '&',
        onDeleteLink: '&',
      },
    }
  }

  /* @ngInject */
  function Controller(
    userService,
    glDialog,
    glToast,
    Link,
    linkService,
    clipboardService
  ) {
    var ctrl = this

    ctrl.States = {
      LOADING: 'loading',
      ERROR: 'error',
      READY: 'ready',
      SUCCESS: 'success',
    }

    ctrl.copyLink = clipboardService.copy
    ctrl.isSSR = userService.isSSR
    ctrl.editLink = editLink
    ctrl.deleteLink = deleteLink

    ctrl.$onInit = onInit

    function onInit() {
      ctrl.state = ctrl.States.READY
    }

    function editLink(link) {
      var template =
        '<gl-dialog class="gl-dialog-medium">' +
        '<link-editor-dialog' +
        ' link="link"' +
        ' close="dialog.close(savedLink)"' +
        ' cancel="dialog.cancel()"' +
        '/>' +
        '</gl-dialog>'

      var options = {
        link: angular.copy(link) || new Link(ctrl.defaultLongUrl),
      }

      glDialog
        .show({
          template: template,
          controllerAs: '$ctrl',
          escapeToClose: true,
          clickOutsideToClose: true,
          locals: options,
        })
        .then(function(updatedLink) {
          // Send the updated link back to the parent
          ctrl.onCreateUpdateLink({ link: updatedLink })
        })
    }

    function deleteLink(link) {
      glDialog
        .confirm(
          'Delete Link',
          [
            'If you have shared this link with anyone they will no longer be able to access it.',
            'Are you sure you want to delete this link?',
          ].join(' '),
          {
            locals: {
              cancelText: 'No',
              closeText: 'Yes',
            },
          }
        )
        .then(doDeleteLink)

      function doDeleteLink() {
        linkService
          .deleteLink(link)
          .then(function() {
            ctrl.onDeleteLink({ link: link })
          })
          .catch(function() {
            glToast.action('Could not delete link.', 'retry', function() {
              doDeleteLink()
            })
          })
      }
    }
  }
})()
