;(function() {
  'use strict'

  Controller.$inject = ["userService", "subscriberService", "productService", "channelService", "helpService", "featureService"];
  angular.module('app.core').component('productManager', {
    controller: Controller,
    templateUrl: 'product-manager.html',
    bindings: {
      channel: '<',
    },
  })

  /* @ngInject */
  function Controller(
    userService,
    subscriberService,
    productService,
    channelService,
    helpService,
    featureService
  ) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.isLoading = isLoading
    ctrl.create = create
    ctrl.orderBy = orderBy
    ctrl.applyFilter = applyFilter
    ctrl.isSelected = isSelected
    ctrl.getIsLocked = getIsLocked
    ctrl.getChannelName = getChannelName
    ctrl.setSelected = setSelected
    ctrl.selectAll = selectAll
    ctrl.contact = helpService.chat

    function onInit() {
      ctrl.isSSR = userService.isSSR()
      ctrl.ownerId = subscriberService.getSubscriber().id
      channelService.getByOwner(ctrl.ownerId, function(
        channels,
        loading,
        error
      ) {
        ctrl.channels = channels
        ctrl.channelsLoading = loading
        ctrl.channelsError = error
        findProductChannels()
      })
      productService.list(ctrl.ownerId, function(products, loading, error) {
        ctrl.products = products
        ctrl.loading = loading
        ctrl.error = error
        applyFilter()
        findProductChannels()
      })
    }

    function findProductChannels() {
      ctrl.productChannels = {}
      _.each(ctrl.channels, function(channel) {
        _.each(channel.products, function(productId) {
          ctrl.productChannels[productId] = channel
        })
      })
    }

    function isLoading() {
      return (
        (ctrl.loading && !ctrl.products.length) ||
        (ctrl.channelsLoading && !ctrl.channels.length)
      )
    }

    function create() {
      clearFilter()
      var existing = _.find(ctrl.products, function(product) {
        return !product.id
      })
      if (existing) {
        return
      }
      if (!ctrl.isSSR && !featureService.canCreateProduct()) {
        ctrl.showQuota = true
        return
      }
      var product = productService.create({
        ownerId: ctrl.ownerId,
        mode: 'edit',
      })
      ctrl.products.unshift(product)
    }

    function orderBy(product) {
      return product.createdAt.unix()
    }

    function applyFilter() {
      ctrl.hiddenSelections = 0
      if (!ctrl.query) {
        ctrl.filteredProducts = ctrl.products
        return
      }
      ctrl.filteredProducts = _.filter(ctrl.products, function(product) {
        var id = ctrl.query.length === 36 ? product.id : ''
        var tokens = [
          id,
          product.brand,
          product.description,
          product.gtin,
          product.name,
        ]
          .join(' ')
          .toLowerCase()
        var visible = _.includes(tokens, ctrl.query.toLowerCase())
        if (
          !visible &&
          ctrl.channel &&
          _.includes(ctrl.channel.products, product.id)
        ) {
          ctrl.hiddenSelections++
        }
        return visible
      })
    }

    function clearFilter() {
      ctrl.query = null
      applyFilter()
    }

    function isSelected(product) {
      if (!ctrl.channel) {
        return
      }
      return _.includes(ctrl.channel.products, product.id)
    }

    function getIsLocked(product) {
      var productChannel = ctrl.productChannels[product.id]
      var sameChannel =
        ctrl.channel && productChannel && productChannel.id === ctrl.channel.id
      var otherChannelDisabled = productChannel && !productChannel.enabled
      if (!productChannel || sameChannel || otherChannelDisabled) {
        return false
      }
      return true
    }

    function getChannelName(product) {
      var productChannel = ctrl.productChannels[product.id]
      return productChannel && productChannel.name
    }

    function setSelected(product, selected) {
      if (!ctrl.channel) {
        return
      }
      _.remove(ctrl.channel.products, function(id) {
        return id === product.id
      })
      ctrl.channel.removeProductToTake(product.id)
      if (selected) {
        ctrl.channel.products.push(product.id)
        var otherChannel = ctrl.productChannels[product.id]
        if (otherChannel) {
          ctrl.channel.addProductToTake(product.id, otherChannel.id)
        }
      }
    }

    function selectAll(selected) {
      _(ctrl.filteredProducts)
        .orderBy(orderBy, ['desc'])
        .each(function(product) {
          if (getIsLocked(product)) return
          setSelected(product, selected)
        })
    }
  }
})()
