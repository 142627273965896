;(function() {
  'use strict'

  angular.module('app.core').component('ratingStars', {
    controller: Controller,
    templateUrl: 'rating-stars.html',
    bindings: {
      isLarge: '<',
      hideValue: '<',
      onSelect: '&?',
      rating: '<',
    },
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.getStarColor = getStarColor

    function onInit() {
      ctrl.intent = 0
    }

    function getStarColor(star) {
      if (ctrl.intent && ctrl.onSelect) {
        return ctrl.intent < star ? 'BLUE_ATHENS_GRAY' : 'ORANGE_GLOW'
      } else if (ctrl.rating) {
        return parseInt(ctrl.rating) < star ? 'BLUE_ATHENS_GRAY' : 'ORANGE_GLOW'
      } else {
        return 'BLUE_ATHENS_GRAY'
      }
    }
  }
})()
