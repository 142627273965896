;(function() {
  'use strict'

  Controller.$inject = ["$state", "publisherService", "publicationService", "userService", "glAnalytics", "helpService", "uacService"];
  angular.module('app.core').controller('MyPublicationsController', Controller)

  /* @ngInject */
  function Controller(
    $state,
    publisherService,
    publicationService,
    userService,
    glAnalytics,
    helpService,
    uacService
  ) {
    var ctrl = this

    ctrl.isVisible = isVisible
    ctrl.create = create
    ctrl.editPublisher = editPublisher
    ctrl.contact = helpService.chat

    init()

    function init() {
      ctrl.publishers = publisherService.publishers
      ctrl.all = publicationService.getByView('published')
      ctrl.isLoggedIn = userService.isLoggedIn()
      if (!ctrl.isLoggedIn) {
        glAnalytics.track('MyPublications', 'public-page-viewed')
      }
    }

    function isVisible(publication) {
      return publication.isFiltered(ctrl.filter)
    }

    function create() {
      if (!uacService.canManageReports(true)) return
      $state.go('publicationEditor', { id: 'new' })
    }

    function editPublisher() {
      publisherService.showEditDialog(ctrl.publishers[0])
    }
  }
})()
