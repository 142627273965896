;(function() {
  'use strict'

  angular.module('app.core').component('channelTranslation', {
    controller: Controller,
    templateUrl: 'channel-translation.html',
    bindings: {
      survey: '<',
      channel: '<',
      translations: '<',
      corpus: '<',
      onSave: '&',
    },
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.toggle = toggle

    function onInit() {
      ctrl.enabled = !!ctrl.channel.translationId
      ctrl.translationOptions = ctrl.translations.value.map(function(
        translation
      ) {
        var isIncomplete = translation.isIncomplete(ctrl.corpus)
        var isShared = translation.isPublicUpdateAllowed
        var suffix = ''
        if (isIncomplete) suffix += ' (incomplete)'
        if (isShared) suffix += ' (shareable link still active)'
        return {
          label: translation.label + suffix,
          value: translation.id,
          disabled: isIncomplete || isShared,
        }
      })
    }

    function toggle() {
      ctrl.enabled = !ctrl.enabled
      if (!ctrl.enabled) {
        ctrl.channel.translationId = null
      }
    }
  }
})()
