;(function() {
  'use strict'

  Controller.$inject = ["$q", "glDialog", "stateService", "panelService", "channelService", "featureService", "userService"];
  angular.module('app.core').component('channelFormPanel', {
    controller: Controller,
    templateUrl: 'channel-form-panel.html',
    bindings: {
      channel: '<',
      survey: '<',
      cost: '<',
      onCostShouldUpdate: '&',
      onSave: '&',
    },
  })

  /* @ngInject */
  function Controller(
    $q,
    glDialog,
    stateService,
    panelService,
    channelService,
    featureService,
    userService
  ) {
    var ctrl = this

    ctrl.daysInField = [
      { label: '1 Day', value: 1, condition: canUseQuickPanel },
      { label: '2 Days', value: 2, condition: canUseQuickPanel },
      { label: '3 Days', value: 3 },
      { label: '4 Days', value: 4 },
      { label: '5 Days', value: 5 },
      { label: '6 Days', value: 6 },
      { label: '7 Days', value: 7 },
      { label: '14 Days', value: 14 },
      { label: '28 Days', value: 28 },
    ]

    ctrl.$onInit = onInit
    ctrl.setCountry = setCountry
    ctrl.setLanguage = setLanguage
    ctrl.clampIncidenceRate = clampIncidenceRate
    ctrl.clampRequiredCompletes = clampRequiredCompletes

    function onInit() {
      ctrl.isSsr = userService.isSSR()
      ctrl.state = stateService.define(['loading', 'error', 'ready'])
      ctrl.state.loading()
      ctrl.isUpdateRestricted = ctrl.channel.panel.isUpdateRestricted()
      loadCountries()
        .then(function() {
          ctrl.onCostShouldUpdate()
          ctrl.state.ready()
        })
        .catch(function() {
          ctrl.state.error()
        })
      getExclusionOptions()
    }

    function canUseQuickPanel() {
      return featureService.canUseQuickPanel()
    }

    function loadCountries() {
      return panelService.getCountries().then(function(countries) {
        ctrl.languagesByCountry = {}
        ctrl.countries = []
        countries.forEach(function(country) {
          if (!country.supportedLanguages) return
          ctrl.languagesByCountry[
            country.isoCode
          ] = country.supportedLanguages.map(function(language) {
            return {
              label: language.languageName,
              value: language.isoCode,
              data: language,
            }
          })
          ctrl.countries.push({
            label: country.countryName,
            value: country.isoCode,
            data: country,
          })
        })
      })
    }

    function setCountry(value, force, cancel) {
      if (value === ctrl.channel.panel.country) return

      if (ctrl.channel.targeting.enabled && !force) {
        return glDialog
          .confirm(
            'Remove Targeting?',
            'Changing country will remove all targeting you have set up. ' +
              'Are you sure you want to remove all targeting from this channel?'
          )
          .then(function() {
            setCountry(value, true)
          })
          .catch(function() {
            // reset the selected country back to previous selected value
            cancel()
          })
      }

      ctrl.channel.targeting.enabled = false
      ctrl.channel.targeting.presetId = null
      _.remove(ctrl.channel.panel.targets)

      var firstLanguage = ctrl.languagesByCountry[value][0].value
      ctrl.channel.panel.country = value
      ctrl.channel.panel.language = firstLanguage
    }

    function setLanguage(value, force, cancel) {
      if (value === ctrl.channel.panel.language) return

      if (ctrl.channel.targeting.enabled && !force) {
        return glDialog
          .confirm(
            'Remove Targeting?',
            'Changing language will remove all targeting you have set up. ' +
              'Are you sure you want to remove all targeting from this channel?'
          )
          .then(function() {
            setLanguage(value, true)
          })
          .catch(function() {
            // reset the selected language back to previous selected value
            cancel()
          })
      }

      ctrl.channel.targeting.enabled = false
      ctrl.channel.targeting.presetId = null
      _.remove(ctrl.channel.panel.targets)

      ctrl.channel.panel.language = value
    }

    function clampIncidenceRate() {
      ctrl.channel.panel.incidenceRate = _.clamp(
        ctrl.channel.panel.incidenceRate,
        0,
        100
      )
    }

    function clampRequiredCompletes() {
      ctrl.channel.panel.requiredCompletes = _.clamp(
        ctrl.channel.panel.requiredCompletes,
        50,
        10000
      )
    }

    function getExclusionOptions() {
      channelService.getBySurvey(
        ctrl.survey.id,
        function(channels) {
          ctrl.exclusionOptions = _(channels)
            .filter(function(channel) {
              return (
                channel.id !== ctrl.channel.id &&
                channel.type === channel.Type.PANEL &&
                channel.panel.canUseAsExclusion()
              )
            })
            .map(function(channel) {
              return {
                label: channel.name,
                value: channel.panel.id,
              }
            })
            .value()
        },
        {
          policy: channelService.Policy.CACHE_ONLY,
        }
      )
    }
  }
})()
