;(function() {
  'use strict'

  Service.$inject = ["$document", "$timeout"];
  angular.module('core.services').factory('scrollService', Service)

  /* @ngInject */
  function Service($document, $timeout) {
    return {
      animateTo: animateTo,
    }

    function animateTo(elem) {
      $timeout(function() {
        if (_.isString(elem)) {
          elem = angular.element($document[0].querySelectorAll(elem))
        }
        if (!elem || !elem.length) {
          return
        }

        $document.scrollToElementAnimated(elem, 200, 500, function(t) {
          // easeInOutQuad - https://gist.github.com/gre/1650294
          return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t
        })
      })
    }
  }
})()
