;(function() {
  'use strict'

  Controller.$inject = ["$q", "$state", "app", "glUtils", "StateFactory", "reportLinkService"];
  angular
    .module('app.core')
    .controller('InteractiveAnalysisController', Controller)

  /* @ngInject */
  function Controller(
    $q,
    $state,
    app,
    glUtils,
    StateFactory,
    reportLinkService
  ) {
    var ctrl = this

    ctrl.errors = {
      Default: 'Error fetching report',
      403: 'Sorry, you are not permitted to view this Interactive Analysis.',
      404: 'Sorry, we are currently unable to generate this Interactive Analysis',
    }
    ctrl.setFiltersParam = setFiltersParam
    ctrl.setResponseTypeParam = setResponseTypeParam
    ctrl.onLoaded = onLoaded

    ctrl.$onInit = onInit

    function onInit() {
      ctrl.surveyId = $state.params.surveyId
      ctrl.filters = $state.params.filters
      ctrl.filtersOperator = $state.params.filtersOperator
      ctrl.token = $state.params.token || $state.params.t

      // HACK: ask ash/tim
      if (
        ctrl.token === 'a35e56e6160b2915c0a98502941e1348' &&
        ctrl.surveyId === 'd6f34045-4624-4c20-96c4-4d18fdf165d7'
      ) {
        $state.go(
          'publication',
          { id: '546e4ed7-7cd0-4c37-bf10-2cf445721a5f' },
          { location: 'replace' }
        )
      }

      ctrl.state = new StateFactory(['loading', 'ready', 'error'])
      ctrl.state.loading()

      fetchLink()
        .then(function() {
          initialize()
          ctrl.state.ready()
        })
        .catch(function(err) {
          console.error(err)
          ctrl.error = 403
          ctrl.state.error()
        })
    }

    function fetchLink() {
      if (!ctrl.token) return $q.resolve()
      return reportLinkService.getByToken(ctrl.token).then(function(link) {
        ctrl.link = link
      })
    }

    function initialize() {
      if (ctrl.link) {
        ctrl.options = {
          accessType: 'device',
          shareToken: link.token,
          showShareRestrictedAnalysis: link.isRestricted,
          canFilter: true,
          canFilterProfile: !link.isRestricted,
          canEditFilters: true,
          showShareButton: false,
          canShareReport: false,
          showExportButton: false,
          canExportReport: false,
          showReportSettingsButton: false,
          canEditReportSettings: false,
          canEditResponses: false,
          canViewCrosstabs: true,
          canEditCrosstabs: false,
          visibilityMode: link.mode,
          visibilityViewIds: link.viewIds,
          onlyLoopVariableIds: null,
          showResponseTypeToggle: false,
          responseType: link.responseType,
        }
      } else {
        var responseType = $state.params.responseType
        if (responseType) {
          responseType =
            responseType === 'all' ? null : responseType.toUpperCase()
        }
        ctrl.options = {
          accessType: 'user',
          canFilter: true,
          canFilterProfile: false,
          canEditFilters: true,
          showShareButton: false,
          canShareReport: false,
          showExportButton: false,
          canExportReport: false,
          showReportSettingsButton: false,
          canEditReportSettings: false,
          canEditResponses: false,
          canViewCrosstabs: true,
          canEditCrosstabs: false,
          showFullTextAnswers: false,
          visibilityMode: 'ALL',
          visibilityViewIds: [],
          onlyLoopVariableIds: null,
          showResponseTypeToggle: true,
          responseType: responseType,
        }
      }
    }

    function onLoaded(title, tags /*, subscriberId*/) {
      app.setTitle(title)
      app.setTags(tags)
    }

    function setFiltersParam(filterSetIds, operator) {
      glUtils.changeParams({ filters: filterSetIds, filtersOperator: operator })
    }

    function setResponseTypeParam(responseType) {
      glUtils.changeParams({ responseType: responseType })
    }
  }
})()
