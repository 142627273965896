;(function() {
  'use strict'

  Controller.$inject = ["$timeout", "reportD3ChartService", "c3", "renderService"];
  angular
    .module('glow.reporting.charts')
    .component('glReportMatrixBarChart', Component())

  /* @ngInject */
  function Component() {
    return {
      controller: Controller,
      templateUrl: 'report-matrix-bar-chart.template.html',
      bindings: {
        showAll: '<',
        height: '=',
        question: '<',
        absolute: '<',
        hideLabels: '<',
        hide: '<',
        focus: '<',
        rotated: '<',
        allowChartClick: '=',
        onChartClick: '&',
      },
    }
  }

  /* @ngInject */
  function Controller($timeout, reportD3ChartService, c3, renderService) {
    var ctrl = this

    ctrl.$onChanges = onChanges
    ctrl.selectAspect = selectAspect
    ctrl.next = next
    ctrl.prev = prev

    function onChanges(changes) {
      if (!ctrl.charts) {
        prepare()
        $timeout(makeChart, 10)
      } else if (changes.absolute || changes.rotated) {
        makeChart()
      } else if (changes.question) {
        refreshChart()
      } else if (changes.hide) {
        hideChart()
      }
      if (changes.focus) {
        focusChart()
      }
    }

    function prepare() {
      if (ctrl.question.isMaxDiff) {
        ctrl.aspects = _.map(ctrl.question.choices, function(choice) {
          return {
            value: choice.id,
            label: choice.option,
          }
        })
      } else if (ctrl.question.type === 'rank') {
        ctrl.aspects = _.map(ctrl.question.ranks, function(rank) {
          return {
            value: rank.id,
            label: rank.label,
            onChartClickAspect: rank.value,
          }
        })
      } else {
        ctrl.aspects = _.map(ctrl.question.statements, function(statement) {
          return {
            value: statement.id,
            label: statement.statement,
            onChartClickAspect: statement.id,
          }
        })
      }
      ctrl.charts = _.map(ctrl.aspects, function(aspect) {
        return {
          id: _.uniqueId('reportMatrixBarChart'),
          chart: null,
          hasNoResults: false,
          aspect: aspect,
          info: {},
        }
      })
      var defaultAspectId =
        ctrl.question.selectedMatrixBarChartAspectId || ctrl.aspects[0].value
      selectAspect(defaultAspectId)
    }

    function selectAspect(aspectId) {
      ctrl.selectedAspectId = aspectId
      var currentAspectIndex = _.findIndex(ctrl.aspects, { value: aspectId })
      ctrl.nextAspect = ctrl.aspects[currentAspectIndex + 1]
      ctrl.prevAspect = ctrl.aspects[currentAspectIndex - 1]
      // store in question to persist the selected aspect when switching charts
      ctrl.question.selectedMatrixBarChartAspectId = aspectId
    }

    function next() {
      if (!ctrl.nextAspect) return
      selectAspect(ctrl.nextAspect.value)
    }

    function prev() {
      if (!ctrl.prevAspect) return
      selectAspect(ctrl.prevAspect.value)
    }

    function getData(chart) {
      var columns = []
      var colors = {}
      var orders = {}
      var grouped = []
      if (ctrl.question.isMaxDiff) {
        grouped = _.groupBy(ctrl.question.aspects, 'choiceId')
      } else if (ctrl.question.type === 'rank') {
        grouped = _.groupBy(ctrl.question.aspects, 'rankId')
      } else {
        grouped = _.groupBy(ctrl.question.aspects, 'statementId')
      }
      var aspectId = chart.aspect.value
      var aspects = grouped[aspectId]

      if (ctrl.question.isMaxDiff) {
        _.each(ctrl.question.statements, function(statement) {
          var label = statement.statement
          var aspect = _.find(aspects, { statementId: statement.id })
          if (aspect) {
            var value = ctrl.absolute ? aspect.count : aspect.ratio
            columns.push([label, value])
          }
          colors[label] = statement.color
          orders[label] = statement.order
          chart.info[label] = {
            value: chart.aspect.label,
            aspect: statement.id,
          }
        })
      } else {
        _.each(ctrl.question.choices, function(choice) {
          var label = choice.displayLabel || choice.option
          var aspect = _.find(aspects, { choiceId: choice.id })
          if (aspect) {
            var value = 0
            if (ctrl.question.type === 'constantsum') {
              value = ctrl.absolute ? aspect.total : aspect.totalRatio
            } else {
              value = ctrl.absolute ? aspect.count : aspect.ratio
            }
            columns.push([label, value])
          }
          colors[label] = choice.color
          orders[label] = choice.order
          chart.info[label] = {
            value: label,
            aspect: chart.aspect.onChartClickAspect,
          }
        })
      }

      var data = {}
      data.columns = columns
      data.colors = colors
      data.orders = orders
      return data
    }

    function makeChart() {
      _.each(ctrl.charts, function(chart) {
        delete chart.chart

        var data = getData(chart)
        var config = reportD3ChartService.buildChartConfig(
          chart.id,
          'bar',
          data.columns,
          data.colors,
          undefined,
          undefined,
          ctrl.absolute,
          ctrl.hideLabels,
          ctrl.rotated
        )

        if (ctrl.allowChartClick) {
          config.data.onclick = function(d) {
            var info = chart.info[d.id]
            ctrl.onChartClick({
              $value: info.value,
              $aspect: info.aspect,
            })
          }
        }

        // BUG-FIX: https://github.com/c3js/c3/issues/975
        // bars were not rendering when data changed (eg chart.load())
        // gif: https://media.giphy.com/media/xT39DbJaJ6vxZCFwGs/giphy.gif
        config.transition = {
          duration: 0,
        }
        renderService.queue(function() {
          chart.chart = c3.generate(config)
        })

        var sum = _.sumBy(data.columns, function(column) {
          return column[1]
        })
        chart.hasNoResults = sum === 0
      })
    }

    function refreshChart() {
      _.each(ctrl.charts, function(chart) {
        var data = getData(chart)
        chart.chart.load({ unload: null, columns: data.columns })
      })
    }

    function hideChart() {
      _.each(ctrl.charts, function(chart) {
        chart.chart.show()
        if (ctrl.hide.length) {
          chart.chart.hide(ctrl.hide)
        }
      })
    }

    function focusChart() {
      _.each(ctrl.charts, function(chart) {
        if (chart.chart) {
          chart.chart.focus(ctrl.focus)
        }
      })
    }
  }
})()
