;(function() {
  'use strict'

  angular
    .module('glow.reporting.charts')
    .component('reportInfoRating', Component())

  /* @ngInject */
  function Component() {
    return {
      controller: Controller,
      templateUrl: 'report-info-rating.html',
      bindings: {
        question: '<',
      },
    }
  }

  /* @ngInject */
  function Controller() {
    var ctrl = this
    var MAX_STARS = 5

    ctrl.$onChanges = onChanges

    function onChanges() {
      var averageRating = ctrl.question.questionResults.averageRating
      var starQty = Math.floor(averageRating)
      var showPartial = averageRating !== starQty
      var stars = new Array(MAX_STARS)

      _.fill(stars, 'star', 0, starQty)
      if (showPartial) {
        _.fill(stars, 'star-half', starQty, starQty + 1)
        _.fill(stars, 'star-outline', starQty + 1, MAX_STARS)
      } else {
        _.fill(stars, 'star-outline', starQty, MAX_STARS)
      }

      ctrl.stars = stars
    }
  }
})()
