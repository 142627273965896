;(function() {
  'use strict'

  angular
    .module('glow.reporting')
    .component('glSurveyReportQuestions', Component())

  function Component() {
    return {
      controller: Controller,
      templateUrl: 'report-questions.template.html',
      bindings: {
        survey: '<',
        filterSetGroup: '<',
        hiddenCharts: '<',
        filteredResponseCount: '<',
        loopTracker: '<',
        onCompare: '&',
        onZoom: '&',
      },
    }
  }

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.isVisible = isVisible
    ctrl.toMarkdown = toMarkdown

    function isVisible(tile) {
      if (tile.type === 'section') {
        var isHidden = tile.section.isHidden
        return !isHidden
      }
      if (tile.type === 'question') {
        var isHiddenQuestion = tile.question.isHidden
        var isHiddenShown = ctrl.survey.showHiddenQuestions
        var isHiddenChart = _.includes(ctrl.hiddenCharts, tile.question.id)
        if (isHiddenChart) return false
        return !isHiddenQuestion || isHiddenShown
      }
    }

    function toMarkdown(string) {
      return window.marked(string)
    }
  }
})()
