;(function() {
  'use strict'

  Controller.$inject = ["$state", "$log", "glDialog", "glToast", "questionTemplateService", "Question"];
  angular
    .module('core.questionTemplates')
    .component('questionTemplateList', Component())

  /* @ngInject */
  function Component() {
    return {
      controller: Controller,
      templateUrl: 'question-template-list.html',
      bindings: {
        initialState: '=',
        onStateChange: '&',
        onSelect: '&',
        onTemplatesChange: '&',
      },
    }
  }

  /* @ngInject */
  function Controller(
    $state,
    $log,
    glDialog,
    glToast,
    questionTemplateService,
    Question
  ) {
    var pageState
    var ctrl = this
    var log = $log.create('questionTemplateList')

    var AMOUNT_PER_PAGE = (ctrl.AMOUNT_PER_PAGE = 20)

    ctrl.States = {
      LOADING: 'loading',
      ERROR: 'error',
      READY: 'ready',
      SUCCESS: 'success',
    }

    ctrl.$onInit = onInit
    ctrl.view = view
    ctrl.getDate = getDate
    ctrl.cloneTemplate = cloneTemplate
    ctrl.remove = remove
    ctrl.fetchTemplates = fetchTemplates
    ctrl.fetchMoreTemplates = fetchMoreTemplates
    ctrl.getVariationText = getVariationText
    ctrl.select = select
    ctrl.selectType = selectType
    ctrl.selectState = selectState

    function onInit() {
      // set state defaults including initial-state
      pageState = ctrl.pageState = _.defaultsDeep(
        ctrl.initialState,
        {},
        {
          pages: 1,
          filters: {
            query: { value: null, isDisabled: false },
            type: { value: null, isDisabled: false },
            state: { value: null, isDisabled: false },
          },
          filterString: null,
        }
      )

      setFilterOptions()
      fetchTemplates(pageState.pages)
    }

    /**
     * @name view
     * @desc view/edit a new Survey Templates
     */
    function view(template) {
      $state.go('question-templates-edit', { questionId: template.id })
    }

    function getDate(date) {
      return moment(date).format('DD MMM YYYY')
    }

    /**
     * @name Clone Template
     * @desc clone the selected template
     */
    function cloneTemplate(templateId) {
      $state.go('question-templates-edit', {
        questionId: 'new',
        cloneTemplateId: templateId,
      })
    }

    function remove(template) {
      glDialog
        .confirm('Delete', 'Are you sure you want to delete this template?')
        .then(function() {
          questionTemplateService
            .remove(template)
            .then(function() {
              _.remove(ctrl.templates, template)
              glToast.show('Template successfully deleted')
            })
            .catch(function() {
              glToast.show('Unable to delete template')
            })
        })
    }

    /**
     * @name setFilterOptions
     * @desc initialise filter interface options
     */
    function setFilterOptions() {
      // question-type options
      var Types = Question.Types
      ctrl.questionInfo = Question.getInfo([
        Types.CHOICE,
        Types.SINGLE_TEXT,
        Types.TEXT,
        Types.SCAN,
        Types.NPS,
        Types.MOOD,
        Types.IMAGE,
        Types.PLACES_NEAR_ME,
        Types.RATING,
        Types.RANK,
        Types.SCALE,
        Types.NUMERIC,
        Types.CONSTANT_SUM,
        Types.SCORE,
        Types.HIDDEN_VARIABLES,
      ])

      ctrl.questionTypes = _.map(ctrl.questionInfo, function(questionType) {
        questionType.id = questionType.type
        return questionType
      })

      ctrl.questionTypes = _.sortBy(ctrl.questionTypes, 'label')

      ctrl.questionTypes.unshift({ label: 'Any', id: 'ANY' })

      // state options
      ctrl.templateStates = [
        { label: 'Any', id: 'ANY' },
        { label: 'Draft', id: 'DRAFT' },
        { label: 'Published', id: 'PUBLISHED' },
        { label: 'Archived', id: 'ARCHIVED' },
      ]

      ctrl.selectedType = _.find(ctrl.questionTypes, {
        id: ctrl.pageState.filters.type.value,
      })
        ? _.cloneDeep(
            _.find(ctrl.questionTypes, {
              id: ctrl.pageState.filters.type.value,
            })
          )
        : _.cloneDeep(ctrl.questionTypes[0])

      ctrl.selectedState = _.find(ctrl.templateStates, {
        id: ctrl.pageState.filters.state.value,
      })
        ? _.cloneDeep(
            _.find(ctrl.templateStates, {
              id: ctrl.pageState.filters.state.value,
            })
          )
        : _.cloneDeep(ctrl.templateStates[0])
    }

    /**
     * @name fetchTemplates
     * @desc fetches a fresh set of templates based on the pages and filters set
     * @param {Number} pages how many pages of data to be fetched
     */
    function fetchTemplates(pages) {
      if (!_.isNumber(pages)) {
        return log.error('fetchTemplates no page number')
      }

      setState({ pages: pages })

      // request options
      var options = {
        top: pageState.pages * AMOUNT_PER_PAGE,
        orderby: 'updatedAt desc',
        filter: pageState.filterString,
      }

      // clear existing list
      ctrl.templates = null

      // show loading spinner
      ctrl.routeState = ctrl.States.LOADING

      // fetch
      questionTemplateService
        .find(options)
        .then(function(templates) {
          ctrl.hasMoreTemplates = templates.length === options.top
          ctrl.templates = templates
          ctrl.onTemplatesChange({ $templates: templates })
        })
        .catch(function() {
          ctrl.routeState = ctrl.States.ERROR
          log.error('error fetching initial templates', options)
        })
        .finally(function() {
          ctrl.routeState = ctrl.States.READY
        })
    }

    /**
     * @name fetchMoreTemplates
     * @desc fetches the next page of templates and appends to the existing set
     */
    function fetchMoreTemplates() {
      // increment pages by 1
      setState({ pages: pageState.pages + 1 })

      // request options
      var options = {
        top: AMOUNT_PER_PAGE,
        skip: (pageState.pages - 1) * AMOUNT_PER_PAGE,
        orderby: 'updatedAt desc',
        filter: pageState.filterString,
      }

      // show loading spinner
      ctrl.routeState = ctrl.States.LOADING

      // fetch
      questionTemplateService
        .find(options)
        .then(function(templates) {
          ctrl.hasMoreTemplates = templates.length === options.top
          ctrl.templates.push.apply(ctrl.templates, templates)
        })
        .catch(function() {
          ctrl.error = true
          log.error('error fetching more templates', options)
        })
        .finally(function() {
          ctrl.routeState = ctrl.States.READY
        })
    }

    /**
     * @name setState
     * @desc updates state and notifies listeners
     * @param {Object} newState the state changes to be made
     * @returns {Object} state the new state
     */
    function setState(newState) {
      _.assign(ctrl.pageState, newState)

      // parse filters into a string for requests
      ctrl.pageState.filterString = parseFilters(ctrl.pageState.filters)

      // notify listeners
      if (ctrl.onStateChange) {
        ctrl.onStateChange({ $state: ctrl.pageState })
      }

      return pageState
    }

    /**
     * @name parseFilters
     * @desc parses a filters object into the filter string used by the backend
     * @param {Object} filters the state filters
     * @returns {String} filters
     */
    function parseFilters(filters) {
      var arr = []
      if (filters.query.value) {
        arr.push(
          'name regex /' +
            filters.query.value +
            '/i or id eq ' +
            filters.query.value
        )
      }
      if (filters.type.value) {
        arr.push('questions.type eq ' + filters.type.value)
      }
      if (filters.state.value) {
        arr.push('questions.state eq ' + filters.state.value)
      }
      return arr.join(' and ')
    }

    function getVariationText(template) {
      // TODO we should use hasVariations that's in the BE object?
      if (template.questions.length > 1) {
        var states = {}
        _.each(template.questions, function(question) {
          if (states[question.state]) {
            states[question.state].count += 1
          } else {
            states[question.state] = { name: question.state, count: 1 }
          }
        })

        var stateText = []
        _.forEach(states, function(state) {
          stateText.push(state.count + ' ' + state.name)
        })
        return (
          template.questions.length +
          ' variations (' +
          stateText.join(', ') +
          ')'
        )
      }

      return template.questions[0].state
    }

    function select(event, template, action) {
      event.preventDefault()
      event.stopPropagation()
      ctrl.onSelect({ $template: template, $action: action })
    }

    function selectType(type) {
      if (type === 'ANY') {
        ctrl.pageState.filters.type.value = null
      } else {
        ctrl.pageState.filters.type.value = type
      }
    }

    function selectState(state) {
      if (state === 'ANY') {
        ctrl.pageState.filters.state.value = null
      } else {
        ctrl.pageState.filters.state.value = state
      }
    }
  }
})()
