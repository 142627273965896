;(function() {
  'use strict'

  Controller.$inject = ["$q", "glToast", "Currency", "currencyService", "subscriberService", "industryService", "countryService", "hubService", "userService", "usersResource"];
  angular.module('app.account').component('accountDetails', {
    controller: Controller,
    templateUrl: 'account-details.html',
  })

  /* @ngInject */
  function Controller(
    $q,
    glToast,
    Currency,
    currencyService,
    subscriberService,
    industryService,
    countryService,
    hubService,
    userService,
    usersResource
  ) {
    var ctrl = this

    ctrl.setIndustry = setIndustry
    ctrl.setCountry = setCountry
    ctrl.canSave = canSave
    ctrl.save = save

    ctrl.$onInit = onInit

    function onInit() {
      if (userService.isSSR()) {
        ctrl.isSSR = true
        loadAccountManagers()
      }

      ctrl.countries = countryService.getSelectOptions()
      ctrl.industries = _.map(industryService.getAll(), function(industry) {
        return {
          label: industry.name,
          value: industry.id,
        }
      })
      ctrl.subscriber = subscriberService.getSubscriber()
    }

    function setIndustry(industryId) {
      ctrl.subscriber.industry = industryService.getById(industryId)
    }

    function setCountry(countryId) {
      ctrl.subscriber.country = countryService.getById(countryId)
      if (!ctrl.subscriber.billing.currency) {
        var localCurrency = currencyService.getByCountry(
          ctrl.subscriber.country.cc
        )
        ctrl.currency =
          localCurrency || currencyService.getByCode(Currency.Codes.USD)
      }
    }

    function canSave() {
      if (!ctrl.subscriber.name) {
        return false
      }
      return true
    }

    function save() {
      ctrl.isSaving = true
      checkAndUpdateCurrency()
        .then(function() {
          return subscriberService.saveSubscriber(ctrl.subscriber)
        })
        .then(function() {
          ctrl.isSaving = false
        })
        .catch(function() {
          glToast.action('Your account could not be updated.', 'Retry', save, {
            timeout: 5000,
          })
        })
    }

    function checkAndUpdateCurrency() {
      if (!ctrl.currency) {
        return $q.resolve()
      }
      return subscriberService.setCurrency(ctrl.currency.code).then(function() {
        ctrl.currency = null
      })
    }

    function loadAccountManagers() {
      ctrl.hubOptions = hubService.getSelectOptions()
      ctrl.accountManagerOptions = []
      usersResource
        .get({ filter: 'userRole.roles eq ROLE_SUBSCRIBER_SUPPORT' })
        .success(function(users) {
          ctrl.accountManagerOptions = [{ label: 'None', value: '' }]
          users.forEach(function(user) {
            ctrl.accountManagerOptions.push({
              label: user.email,
              value: user.id,
            })
          })
        })
    }
  }
})()
