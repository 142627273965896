;(function() {
  'use strict'

  angular.module('app.core').component('detailGrid', {
    controller: Controller,
    templateUrl: 'detail-grid.html',
    bindings: {
      items: '<',
      onSelect: '&',
    },
  })

  /* @ngInject */
  function Controller() {
    // ...
  }
})()
