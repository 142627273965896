;(function() {
  'use strict'

  formatAnswer.$inject = ["cloudinaryService"];
  angular.module('core.filters').filter('formatAnswer', formatAnswer)

  /* @ngInject */
  function formatAnswer(cloudinaryService) {
    return function(input) {
      switch (input.question.type) {
        case 'choice':
        case 'scale':
          return formatChoices()
        // case 'singletext':
        //     output = input.textAnswer;
        //     break;
        // case 'text':
        //     output = input.textAnswer;
        //     break;
        case 'placesnearme':
          return formatPlaceAnswers()
        case 'numeric':
          return formatNumericAnswers()
        case 'image':
          return formatImageUrlElement()
        case 'rank':
          return formatRank()
        case 'rating':
          return formatRating()
        case 'matrix':
          return formatMatrixAnswers()
        case 'text':
          return input.textAnswer ? input.textAnswer.replace(/\|/g, ', ') : ''
        case 'constantsum':
          return formatConstantSumAnswers()
        case 'score':
          if (input.statementAnswers.length > 0) {
            return formatMatrixAnswers()
          } else {
            return formatChoices()
          }
        default:
          return formatText()
      }

      function formatChoices() {
        var choices = _.map(input.choiceAnswers, function(choice) {
          var label = choice.option
          if (choice.text) {
            // choice captured text
            label += ' (' + choice.text + ')'
          }
          return label
        }).join(', ')
        return choices
      }

      function formatText() {
        if (cloudinaryService.isCloudinaryUrl(input.textAnswer)) {
          return (
            '<img class="survey-response-image" ng-src="' +
            input.textAnswer +
            '" gl-image-viewer></img>'
          )
        }
        return input.textAnswer
      }

      function formatNumericAnswers() {
        return input.numericAnswer
      }

      function formatPlaceAnswers() {
        return _.map(input.placesAnswers, function(place) {
          return place.name + ', ' + place.address + ' ' + place.state
        }).join('\n')
      }

      function formatImageUrlElement() {
        if (input.textAnswer) {
          return (
            '<img class="survey-response-image" ng-src="' +
            input.textAnswer +
            '" gl-image-viewer></img>'
          )
        }
      }

      function formatRank() {
        var choices = _.chain(input.choiceAnswers)
          .sortBy('rank')
          .map(function(choice) {
            var label = 'Rank ' + choice.rank + ': ' + choice.option
            if (choice.text) {
              // choice captured text
              label += ' (' + choice.text + ')'
            }
            return label
          })
          .value()
          .join(', ')
        return choices
      }

      function formatRating() {
        var choice = input.choiceAnswers[0]
        if (!choice) {
          return
        }
        switch (choice.option) {
          case '1':
            return choice.option + ' Star'
          case '2':
          case '3':
          case '4':
          case '5':
            return choice.option + ' Stars'
        }
      }

      function formatMatrixAnswers() {
        return _.map(input.statementAnswers, function(answer) {
          return answer.statement + ': ' + answer.option + '\n'
        }).join('')
      }

      function formatConstantSumAnswers() {
        if (input.statementAnswers.length > 0) {
          var statementAnswers = {}
          _.each(input.statementAnswers, function(answer) {
            if (!statementAnswers[answer.statement]) {
              statementAnswers[answer.statement] = []
            }
            statementAnswers[answer.statement].push({
              option: answer.option,
              number: answer.number,
            })
          })
          return _.map(statementAnswers, function(choices, statementLabel) {
            var template = []
            template.push(
              '<div class="survey-response-constant-sum-statement">' +
                statementLabel +
                '</div>'
            )
            _.each(choices, function(choice) {
              template.push(
                '<div class="survey-response-constant-sum-choice">' +
                  choice.option +
                  ': ' +
                  choice.number +
                  '</div>'
              )
            })
            return template.join('')
          }).join('')
        }

        var choices = _.map(input.choiceAnswers, function(choice) {
          return choice.option + ': ' + choice.number
        }).join('\n')

        return choices
      }
    }
  }
})()
