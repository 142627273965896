;(function() {
  'use strict'

  angular
    .module('glow.reporting')
    .component('glQuestionPlaceFilter', Component())

  function Component() {
    return {
      controller: Controller,
      templateUrl: 'place-filter.template.html',
      bindings: {
        survey: '<',
        question: '=',
        filter: '=',
      },
    }
  }

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.toggle = toggle

    function onInit() {
      ctrl.places = _.cloneDeep(
        ctrl.survey.filterOptions.questions[ctrl.question.id].places
      )

      if (ctrl.filter.newValue) {
        var placeChoice = _.find(ctrl.places, {
          sourceId: ctrl.filter.newValue,
        })
        if (placeChoice) {
          ctrl.filter.data.values.push(placeChoice)
        }
        ctrl.filter.newValue = undefined
      }

      _.each(ctrl.filter.data.values, function(place) {
        var placeChoice = _.find(ctrl.places, { sourceId: place.sourceId })
        placeChoice.isSelected = true
      })
    }

    function toggle(choice) {
      if (choice.isSelected) {
        ctrl.filter.data.values.push({ sourceId: choice.sourceId })
      } else {
        _.remove(ctrl.filter.data.values, { sourceId: choice.sourceId })
      }
    }
  }
})()
