;(function() {
  'use strict'

  angular
    .module('glow.reporting')
    .component('glQuestionChoiceFilter', Component())

  function Component() {
    return {
      controller: Controller,
      templateUrl: 'choice-filter.template.html',
      bindings: {
        question: '=',
        filter: '=',
      },
    }
  }

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.toggle = toggle

    function onInit() {
      ctrl.question = _.cloneDeep(ctrl.question)

      if (ctrl.filter.newValue) {
        var choice = ctrl.question.useDisplayLabels
          ? _.find(ctrl.question.choices, {
              displayLabel: ctrl.filter.newValue,
            })
          : _.find(ctrl.question.choices, { option: ctrl.filter.newValue })

        // Only add the choice if not already selected/added
        if (choice && !_.find(ctrl.filter.data.values, { id: choice.id })) {
          var value = { id: choice.id }
          if (choice.ageRange) {
            value.ageRange = choice.ageRange
          }
          ctrl.filter.data.values.push(value)
        }
        ctrl.filter.newValue = undefined
      }

      _.each(ctrl.filter.data.values, function(choice) {
        var questionChoice = _.find(ctrl.question.choices, { id: choice.id })
        if (questionChoice) {
          questionChoice.isSelected = true
        }
      })
    }

    function toggle(choice) {
      if (choice.isSelected) {
        var filterValues = { id: choice.id }
        if (choice.ageRange) {
          filterValues.ageRange = choice.ageRange
        }
        ctrl.filter.data.values.push(filterValues)
      } else {
        _.remove(ctrl.filter.data.values, { id: choice.id })
      }
    }
  }
})()
