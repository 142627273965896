;(function() {
  'use strict'

  Controller.$inject = ["$element"];
  angular.module('app.core').component('publicationFilter', {
    controller: Controller,
    templateUrl: 'publication-filter.html',
    bindings: {
      showProjects: '<',
      onChange: '&',
    },
  })

  /* @ngInject */
  function Controller($element) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.setQuery = setQuery
    ctrl.setTypes = setTypes
    ctrl.setCategories = setCategories
    ctrl.setIndustries = setIndustries
    ctrl.setCountries = setCountries
    ctrl.setProjects = setProjects

    $element.addClass('publication-filter')

    function onInit() {
      ctrl.filter = {}
    }

    function setQuery(query) {
      ctrl.filter.query = query
      ctrl.onChange({ $filter: ctrl.filter })
    }

    function setTypes(types) {
      ctrl.filter.types = types
      ctrl.onChange({ $filter: ctrl.filter })
    }

    function setCategories(categories) {
      ctrl.filter.categories = categories
      ctrl.onChange({ $filter: ctrl.filter })
    }

    function setIndustries(industries) {
      ctrl.filter.industries = industries
      ctrl.onChange({ $filter: ctrl.filter })
    }

    function setCountries(countries) {
      ctrl.filter.countries = countries
      ctrl.onChange({ $filter: ctrl.filter })
    }

    function setProjects(projects) {
      ctrl.filter.projects = projects
      ctrl.onChange({ $filter: ctrl.filter })
    }
  }
})()
