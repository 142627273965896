;(function() {
  'use strict'

  PageModulesController.$inject = ["$rootScope", "$scope", "modulesService", "glDialog", "glTips"];
  angular
    .module('app.page-modules')
    .controller('PageModulesController', PageModulesController)

  /* @ngInject */
  function PageModulesController(
    $rootScope,
    $scope,
    modulesService,
    glDialog,
    glTips
  ) {
    var vm = this

    // locals
    vm.schemas = modulesService.getSchemas()

    // methods
    vm.showDialog = showDialog

    activate()

    /**
     * Initialise
     */
    function activate() {
      modulesService.getByPage($scope.page.page.id).then(function(modules) {
        vm.modules = modules
        glTips.ready()
      })
    }

    /**
     * @name showDialog
     * @description show module settings dialog
     * @param  {String} moduleType type of module to show settings for
     */
    function showDialog(moduleType) {
      var schema = _.find(vm.schemas, { type: moduleType })
      var module = vm.modules[moduleType]

      glDialog
        .show({
          templateUrl: schema.settingsTemplate,
          controller: schema.settingsController,
          controllerAs: schema.settingsControllerAs,
          locals: {
            pageId: $scope.page.page.id,
            module: module,
          },
        })
        .then(function(data) {
          if (data && data.modify) {
            vm.modules[moduleType] = data.module
          }
        })
        .finally(function() {
          $rootScope.$broadcast('page-modules:settings-closed')
        })
    }
  }
})()
