;(function() {
  'use strict'

  config.$inject = ["$stateProvider"];
  angular.module('app.core').config(config)

  /* @ngInject */
  function config($stateProvider) {
    $stateProvider.state('library', {
      url: '/library',
      parent: 'portal',
      templateUrl: 'library.html',
      controller: 'LibraryController',
      controllerAs: '$ctrl',
      title: 'My Library',
    })

    $stateProvider.state('insights-library-deprecated', {
      url: '/insights/library',
      redirectTo: 'library',
    })
  }
})()
