;(function() {
  'use strict'

  Service.$inject = ["$q", "$state", "$rootScope", "glAuthService", "glDialog", "glToast", "Project", "subscriberService", "projectsResource", "uacService", "Survey", "userService", "featureService", "accountService"];
  angular.module('app.core').service('projectService', Service)

  /* @ngInject */
  function Service(
    $q,
    $state,
    $rootScope,
    glAuthService,
    glDialog,
    glToast,
    Project,
    subscriberService,
    projectsResource,
    uacService,
    Survey,
    userService,
    featureService,
    accountService
  ) {
    var self = this

    self.projects = []
    self.archived = []

    self.Events = Project.Events
    self.load = load
    self.create = create
    self.get = get
    self.edit = edit
    self.save = save
    self.pick = pick
    self.getArchived = getArchived
    self.archive = archive
    self.unArchive = unArchive
    self.promptAndArchive = promptAndArchive
    self.promptAndUnarchive = promptAndUnarchive
    self.promptCreateAndView = promptCreateAndView
    self.getZeroDetails = getZeroDetails

    init()

    function init() {
      $rootScope.$on(glAuthService.Events.USER_CHANGE, onUserChange)
      $rootScope.$on(Survey.Events.CREATED, onSurveyCreated)
    }

    function load() {
      if (self.loader) {
        getAll()
        return self.loader
      }
      self.loader = getAll()
      return self.loader
    }

    function getAll() {
      return $q(function(resolve, reject) {
        var ownerId = subscriberService.getSubscriber().id
        var userId = userService.getUser().id
        var isSSR = userService.isSSR()
        var isAdmin = subscriberService.isAdmin(userId)

        projectsResource
          .get({ ownerId: ownerId, top: 200 })
          .success(function(x, projects) {
            _.remove(self.projects)
            _.remove(self.archived)
            projects = projects.map(function(project) {
              return new Project().deserialize(project)
            })
            // If you aren't an Admin or SSR we need to hide
            // projects you cannot see!
            if (!isSSR && !isAdmin) {
              _.remove(projects, function(project) {
                return (
                  project.isPrivate() &&
                  !_.includes(project.privateToGuestIds, userId)
                )
              })
            }
            _.each(projects, function(project) {
              if (!project.isArchived) {
                self.projects.push(project)
              } else {
                self.archived.push(project)
              }
            })
            resolve(self.projects)
          })
          .error(function(data, status) {
            reject({ data: data, status: status })
            self.loader = null
          })
      })
    }

    function create() {
      var ownerId = subscriberService.getSubscriber().id
      return new Project({ ownerId: ownerId })
    }

    function get(id) {
      return load().then(function() {
        return (
          _.find(self.projects, { id: id }) || _.find(self.archived, { id: id })
        )
      })
    }

    function edit(project) {
      project = project || create()
      var template = [
        '<gl-dialog class="project-editor__dialog">',
        '<project-editor project="project" on-saved="dialog.close($project)" on-cancel="dialog.cancel()">',
        '</gl-dialog>',
      ].join('')
      return glDialog
        .show({
          template: template,
          locals: {
            project: project,
          },
        })
        .then(function(project) {
          return project
        })
    }

    function save(project) {
      return $q(function(resolve, reject) {
        project.isSaving = true

        var isNew = project.isNew()
        var action = isNew ? 'create' : 'update'
        var data = project.serialize()

        projectsResource[action](data)
          .success(function(x, response) {
            if (isNew) {
              project.id = response.id
              self.projects.push(project)
            }
            project.updatedAt = moment()
            project.isSaving = false
            resolve(project)
            if (action === 'create') {
              $rootScope.$broadcast(Project.Events.CREATED, project)
            }
          })
          .error(function(data, status) {
            project.isSaving = false
            reject({ data: data, status: status })
          })
      })
    }

    function onUserChange() {
      _.remove(self.projects)
      self.loader = null
    }

    function onSurveyCreated(x, data) {
      var project = _.find(self.projects, { id: data.projectId })

      if (!project) {
        return
      }

      project.surveyCount++
    }

    function pick(options) {
      options = options || {}
      var template = [
        '<gl-dialog class="project-picker__dialog">',
        '<project-picker ',
        'header-title="headerTitle" ',
        'prompt-title="promptTitle" ',
        'hide-project-id="hideProjectId" ',
        'on-select="dialog.close({ project: $project, title: $title })" ',
        'on-close="dialog.cancel()" ',
        '/>',
        '</gl-dialog>',
      ]
      return glDialog.show({
        template: template.join(''),
        clickOutsideToClose: false,
        escapeToClose: true,
        locals: {
          headerTitle: options.headerTitle,
          promptTitle: options.promptTitle,
          hideProjectId: options.hideProjectId,
        },
      })
    }

    function getArchived() {
      return self.archived
    }

    function archive(project) {
      return $q(function(resolve, reject) {
        if (project.isArchived) {
          return resolve()
        }

        // optimistic updates
        project.previousUpdatedAt = project.updatedAt
        project.updatedAt = moment()
        project.isArchived = true
        _.remove(self.projects, project)
        self.archived.push(project)

        projectsResource
          .archive({ id: project.id })
          .success(resolve)
          .error(function() {
            // revert optimistic updates
            project.updatedAt = project.previousUpdatedAt
            project.isArchived = false
            _.remove(self.archived, project)
            self.projects.push(project)
            reject()
          })
      })
    }

    function unArchive(project) {
      return $q(function(resolve, reject) {
        if (!project.isArchived) {
          return resolve()
        }

        // optimistic updates
        project.previousUpdatedAt = project.updatedAt
        project.updatedAt = moment()
        project.isArchived = false
        _.remove(self.archived, project)
        self.projects.push(project)

        projectsResource
          .unarchive({ id: project.id })
          .success(resolve)
          .error(function() {
            project.updatedAt = project.previousUpdatedAt
            project.isArchived = true
            _.remove(self.projects, project)
            self.archived.push(project)
            reject()
          })
      })
    }

    function promptAndArchive(project) {
      if (!uacService.canManageProjects(true)) return
      return glDialog
        .confirm(
          'Archive Project',
          'All live channels will be turned off and respondents will no longer be able to respond to any surveys.'
        )
        .then(function() {
          return archive(project).catch(function(err) {
            glToast.show('Could not archive project')
            throw err
          })
        })
    }

    function promptAndUnarchive(project) {
      if (!uacService.canManageProjects(true)) return
      return glDialog
        .confirm('Unarchive Project', 'Do you want to unarchive this project?')
        .then(function() {
          return unArchive(project).catch(function(err) {
            glToast.show('Could not unarchive project')
            throw err
          })
        })
    }

    function promptCreateAndView() {
      // NOTE: projectLimit might be 1 but the user might have created 2 projects
      // before we introduced feature restrictions, so we show the correct amount in the alert.
      accountService
        .promptUpgrade({
          bypassCondition: featureService.canCreateProject(),
          context: 'more-projects',
        })
        .then(function() {
          if (!uacService.canManageProjects(true)) return
          edit().then(function(project) {
            $state.go('project', { id: project.id })
          })
        })
    }

    function getZeroDetails() {
      return {
        title: 'Ready to start your first project?',
        message:
          'Each project allows you to group surveys and content together, and makes it easier to collaborate and organise your data.',
      }
    }
  }
})()
