;(function() {
  'use strict'

  Service.$inject = ["glAnalytics", "glDialog"];
  angular.module('app.core').service('steadfastDialog', Service)

  /* @ngInject */
  function Service(glAnalytics, glDialog) {
    this.show = show

    function show() {
      var template = [
        '<gl-dialog class="steadfast-dialog">',
        '<steadfast-dialog on-close="dialog.cancel()"/>',
        '</gl-dialog>',
      ]
      return glDialog.show({
        template: template.join(''),
        clickOutsideToClose: false,
        escapeToClose: true,
      })
    }
  }
})()
