;(function() {
  'use strict'

  angular.module('glow.components').component('originViewer', {
    controller: Controller,
    templateUrl: 'origin-viewer.html',
    bindings: {
      origins: '<',
      pre: '<',
    },
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit

    function onInit() {
      // ...
    }
  }
})()
