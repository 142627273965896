;(function() {
  'use strict'

  Controller.$inject = ["$rootScope", "$state", "colors", "glAnalytics", "updateService", "userService", "subscriberService"];
  angular.module('app.core').component('statusBar', {
    controller: Controller,
    templateUrl: 'status-bar.html',
    bindings: {
      // ...
    },
  })

  /* @ngInject */
  function Controller(
    $rootScope,
    $state,
    colors,
    glAnalytics,
    updateService,
    userService,
    subscriberService
  ) {
    var ctrl = this
    var Colors = colors.Colors

    ctrl.$onInit = onInit

    function onInit() {
      ctrl.isAdmin = userService.isLoggedIn()
        ? subscriberService.isAdmin(userService.getUser().id)
        : false

      var frozen = subscriberService.isFrozen()
      if (updateService.isNewVersion()) {
        setNewVersionMessage()
      } else if (frozen) {
        setAccountFreezeMessage()
      }

      // watch for version changes
      $rootScope.$on(updateService.Events.NEW_VERSION_AVAILABLE, function() {
        setNewVersionMessage()
      })

      // watch for login/logout/updates
      $rootScope.$on(subscriberService.Events.CHANGE, function() {
        if (subscriberService.isFrozen()) {
          setAccountFreezeMessage()
        } else if (ctrl.message && ctrl.message.type === 'FROZEN') {
          ctrl.message = null
        }
      })
    }

    function setNewVersionMessage() {
      ctrl.message = {
        type: 'NEW_VERSION',
        text: 'A new version of Glow is available!',
        color: Colors.ORANGE_BLAZE,
        cta: 'Refresh',
        action: function() {
          window.location.reload()
          glAnalytics.track('status-bar', 'new-version-actioned')
        },
      }
      glAnalytics.track('status-bar', 'new-version-shown')
    }

    function setAccountFreezeMessage() {
      var subscriber = subscriberService.getSubscriber()
      var reason = subscriber.freeze.reason
      var messages = {
        CARD_FAILED: {
          text: 'Your account is frozen as your payment method has failed.',
          cta: ctrl.isAdmin
            ? 'Update payment method'
            : 'Please notify your administrator to update payment method',
        },
        CREDIT_EXHAUSTED: {
          text:
            'Your account is frozen as you have no payment method or credit remaining.',
          cta: ctrl.isAdmin
            ? 'Update payment method'
            : 'Please notify your administrator to update payment method',
        },
        HOSTING_CREDIT_EXHAUSTED: {
          text:
            'Your account is frozen as you have exhausted all hosting credit while using channels that consume it.',
          cta: ctrl.isAdmin
            ? 'Please top-up, to unfreeze your account'
            : 'Please notify your administrator to top-up hosting credit',
        },
      }
      var message = messages[reason]
      if (!message)
        throw new Error('setAccountFreezeMessage has invalid reason')
      ctrl.message = {
        type: 'FROZEN',
        text: message.text,
        color: Colors.RED_CANDY,
        cta: message.cta,
        action: function() {
          // if not admin, don't redirect user to account page
          if (!ctrl.isAdmin) {
            return
          }
          $state.go('account', { tab: 'billing' }) // TODO: wrong state!?
          glAnalytics.track('status-bar', 'freeze-actioned')
        },
      }
      glAnalytics.track('status-bar', 'freeze-shown')
    }
  }
})()
