;(function() {
  'use strict'

  config.$inject = ["$stateProvider"];
  angular.module('app.signup', []).config(config)

  function config($stateProvider) {
    $stateProvider.state('signup', {
      url:
        '/signup?' +
        [
          'contentGroupIds',
          'kitId',
          'referrerCode',
          'bannerImageUrl',
          'industry',
          'country',
          'hideOverview',
          'promo',
          'go',
          'ppp',
          'ip',
          'ep',
          'utm_source',
          'utm_medium',
          'utm_campaign',
          'utm_term',
          'utm_content',
          'organisationName',
        ].join('&'),
      templateUrl: 'signup.html',
      controller: 'Signup',
      controllerAs: '$ctrl',
      title: 'Signup',
      public: true,
    })
  }
})()
