;(function() {
  'use strict'

  Controller.$inject = ["$q", "$timeout", "glDialog", "glToast", "glUtils", "Panel", "PanelPreset", "PanelTarget", "panelService", "userService", "subscriberService", "colors"];
  angular.module('app.core').component('panelTargetEditor', {
    controller: Controller,
    templateUrl: 'panel-target-editor.html',
    bindings: {
      responses: '<',
      country: '<',
      language: '<',
      targets: '<',
      presetId: '<',
      setPresetId: '&',
      showCustomUpgradeWarning: '<',
      restricted: '<',
      selectDefault: '<',
      onChange: '&',
    },
  })

  /* @ngInject */
  function Controller(
    $q,
    $timeout,
    glDialog,
    glToast,
    glUtils,
    Panel,
    PanelPreset,
    PanelTarget,
    panelService,
    userService,
    subscriberService,
    colors
  ) {
    var Colors = colors.Colors
    var ctrl = this
    var hasInit = false
    var isIniting = false

    ctrl.$onChanges = onChanges
    ctrl.hasPrerequisites = hasPrerequisites
    ctrl.selectPreset = selectPreset
    ctrl.savePreset = savePreset
    ctrl.savePresetAs = savePresetAs
    ctrl.deletePreset = deletePreset
    ctrl.importPreset = importPreset
    ctrl.exportPreset = exportPreset
    ctrl.addTarget = addTarget
    ctrl.removeTarget = removeTarget
    ctrl.setType = setType
    ctrl.splitEvenly = splitEvenly
    ctrl.removeCell = removeCell
    ctrl.getOptionLabel = getOptionLabel
    ctrl.showAddCellDialog = showAddCellDialog
    ctrl.prepareInterlockOptions = prepareInterlockOptions
    ctrl.hasInterlockOptions = hasInterlockOptions
    ctrl.interlock = interlock
    ctrl.markModified = markModified

    function onChanges(changed) {
      if (isIniting) return
      if (!hasInit) {
        ctrl.isSSR = userService.isSSR()
        ctrl.isLoggedIn = userService.isLoggedIn()
        if (ctrl.isLoggedIn) {
          ctrl.subscriberId = subscriberService.getSubscriber().id
        }
        ctrl.presetFileExtension = PanelPreset.FileExtension
        ctrl.errors = []

        isIniting = true
        $q.all([loadAttributes(), loadPresets()])
          .then(setupPresets)
          .finally(function() {
            // update targets percentages
            _.each(ctrl.targets, function(target) {
              target.countToPercent()
            })

            ctrl.preset = ctrl.presetId ? ctrl.presetsById[ctrl.presetId] : null
            if (
              ctrl.selectDefault &&
              !ctrl.preset &&
              !ctrl.targets.length &&
              ctrl.presetOptions.length
            ) {
              // if preset does not exist and targets empty, then select the first one from options
              selectPreset(ctrl.presetOptions[0].value)
            }
            isIniting = false
          })
        hasInit = true
        return
      }
      if (changed.country || changed.language) {
        $q.all([loadAttributes(), loadPresets()]).then(setupPresets)
      }
      if (changed.responses) {
        // if using an unmodified preset, update counts to reflect preset percentages
        // if its modified, we cant update the counts because we don't know user intention
        if (ctrl.preset && !ctrl.modified) {
          _.each(ctrl.targets, function(target) {
            target.percentToCount(ctrl.responses)
          })
        }
      }
    }

    function hasPrerequisites() {
      return ctrl.country && ctrl.language
    }

    function selectPreset(presetId) {
      if (presetId === ctrl.presetId) return

      ctrl.preset = ctrl.presetsById[presetId]
      panelService.applyPreset(ctrl.targets, ctrl.preset, ctrl.responses)
      ctrl.setPresetId({ $value: ctrl.preset.id })
      markModified(false)
    }

    function savePreset() {
      if (!validateTargets()) return

      // save existing preset
      ctrl.preset.targets = _.map(ctrl.targets, function(target) {
        target.countToPercent()
        target = target.clone()
        return target
      })

      ctrl.savingPreset = true
      panelService
        .savePreset(ctrl.preset)
        .then(function() {
          glToast.show('Preset saved')
          markModified(false)
        })
        .catch(function(error) {
          glToast.show('Error saving preset')
          console.error(error)
        })
        .finally(function() {
          ctrl.savingPreset = false
        })
    }

    function savePresetAs() {
      if (!validateTargets()) return

      // save new preset
      var options = { subscriberId: ctrl.subscriberId }
      var preset = new PanelPreset(options)
      preset.country = ctrl.country
      preset.language = ctrl.language
      preset.targets = _.map(ctrl.targets, function(target) {
        target.countToPercent()
        target = target.clone()
        return target
      })

      // prettier-ignore
      var template = [
        '<gl-dialog class="panel-target-preset-editor__dialog">',
          '<panel-target-preset-editor',
            'on-save="dialog.close($name)"',
            'on-cancel="dialog.cancel()"',
          '/>',
        '</gl-dialog>',
      ]
      glDialog.show({ template: template.join(' ') }).then(function(name) {
        preset.name = name

        ctrl.savingPreset = true
        panelService
          .savePreset(preset)
          .then(function() {
            ctrl.preset = preset
            ctrl.setPresetId({ $value: preset.id })
            $timeout(function() {
              loadPresets().then(setupPresets)
            })
            markModified(false)
          })
          .catch(function(error) {
            glToast.show('Error saving preset')
            console.error(error)
          })
          .finally(function() {
            ctrl.savingPreset = false
          })
      })
    }

    function deletePreset() {
      glDialog
        .confirm(
          'Delete Preset',
          'Are you sure you want to delete this preset?'
        )
        .then(function() {
          ctrl.deletingPreset = true
          panelService
            .deletePreset(ctrl.preset)
            .then(function() {
              ctrl.preset = null
              ctrl.setPresetId({ $value: null })
              _.remove(ctrl.targets)
              $timeout(function() {
                loadPresets().then(setupPresets)
              })
              markModified(false)
            })
            .catch(function(error) {
              console.error(error)
              glToast.show('Error deleting preset')
            })
            .finally(function() {
              ctrl.deletingPreset = false
            })
        })
    }

    function importPreset(files) {
      if (!files.length) return

      var parsed
      try {
        parsed = JSON.parse(files[0].data)
      } catch (e) {
        console.error(e)
        return glToast.show('Cannot parse JSON, please check your preset file!')
      }

      var options = { subscriberId: ctrl.subscriberId }
      var preset = new PanelPreset(options).deserialize(parsed)
      if (
        ctrl.country !== preset.country ||
        ctrl.language !== preset.language
      ) {
        return glToast.show(
          'The preset should match with current selected Country and Language'
        )
      }

      try {
        panelService.applyPreset(ctrl.targets, preset, ctrl.responses)
      } catch (e) {
        console.error(e)
        return glToast.show('Invalid targeting preset')
      }

      ctrl.preset = null
      ctrl.setPresetId({ $value: null })
      markModified(true)
    }

    function exportPreset() {
      var preset
      if (ctrl.preset) {
        // clone it to ensure original targets are not replaced
        preset = ctrl.preset.clone()
      } else {
        var options = { subscriberId: ctrl.subscriberId }
        preset = new PanelPreset(options)
        preset.country = ctrl.country
        preset.language = ctrl.language
      }

      if (!validateTargets()) return

      preset.targets = _.map(ctrl.targets, function(target) {
        target.countToPercent()
        target = target.clone()
        return target
      })

      var blobData = JSON.stringify(preset.serialize(), null, 2)
      var fileName = preset.generateFileName()
      glUtils.downloadFile(blobData, 'application/json', fileName)
    }

    function addTarget(attributeId) {
      var target = new PanelTarget()
      var attribute = ctrl.attributesById[attributeId]
      target.type = attribute.isAllowedInFilters
        ? target.Types.FILTER
        : target.Types.ALLOCATION
      target.attributeIds.push(attributeId)
      ctrl.targets.unshift(target)
      markModified(true)
    }

    function removeTarget(target) {
      if (ctrl.restricted) return
      _.remove(ctrl.targets, target)
      markModified(true)
    }

    function setType(target, type) {
      if (ctrl.restricted) return
      target.type = type
      markModified(true)
    }

    function splitEvenly(target) {
      if (ctrl.restricted) return
      target.splitEvenly(ctrl.responses)
      markModified(true)
    }

    function removeCell(target, cell) {
      if (ctrl.restricted) return
      target.removeCell(cell)
      markModified(true)
    }

    function getOptionLabel(attributeId, option) {
      var attribute = ctrl.attributesById[attributeId]
      switch (attribute.type) {
        case 'INTEGER_RANGE':
        case 'INTEGER':
        case 'STRING':
          return option
        case 'LIST':
          return _.find(attribute.options, { value: option }).label
      }
    }

    function showAddCellDialog(target) {
      // prettier-ignore
      var template = [
        '<gl-dialog class="panel-target-cell-builder__dialog">',
          '<panel-target-cell-builder',
            'target="target"',
            'attributes-by-id="attributesById"',
            'on-add="dialog.close($cells)"',
            'on-cancel="dialog.cancel()"',
          '/>',
        '</gl-dialog>',
      ]
      glDialog
        .show({
          template: template.join(' '),
          closeOnOutsideClick: true,
          locals: {
            target: target,
            attributesById: ctrl.attributesById,
          },
        })
        .then(function(cells) {
          _.each(cells, function(cell) {
            target.cells.push(cell)
          })
          markModified(true)
        })
        .catch(function() {
          // do nothing on-cancel
        })
    }

    function loadAttributes() {
      ctrl.attributesLoading = false
      ctrl.attributes = null
      ctrl.attributesById = {}
      if (!hasPrerequisites()) return $q.reject()
      ctrl.attributesLoading = true
      return panelService
        .getAttributes(ctrl.country, ctrl.language)
        .then(function(attributes) {
          // console.log('attributes', _.cloneDeep(attributes))
          ctrl.attributes = _.uniqBy(attributes, 'id').map(function(attribute) {
            if (attribute.type === 'LIST') {
              attribute.options = attribute.options.map(function(option) {
                return {
                  label: option.text,
                  value: option.id,
                  data: option,
                }
              })
            }
            return {
              label: attribute.name,
              value: attribute.id,
              data: attribute,
              condition: function() {
                // hidden if INACTIVE (dynata no longer supports these)
                if (attribute.state === 'INACTIVE') {
                  return false
                }
                // hidden if not public and not an SSR user
                if (!attribute.public && !ctrl.isSSR) {
                  return false
                }
                // hidden if already used as a target
                var used = _.some(ctrl.targets, function(target) {
                  return _.includes(target.attributeIds, attribute.id)
                })
                if (used) {
                  return false
                }
                return true
              },
            }
          })
          _.each(ctrl.attributes, function(attribute) {
            ctrl.attributesById[attribute.data.id] = attribute.data
          })
          ctrl.attributesLoading = false
        })
    }

    function loadPresets() {
      ctrl.presets = null
      if (!hasPrerequisites()) return $q.reject()
      return panelService
        .getPresets(ctrl.country, ctrl.language)
        .then(function(presets) {
          ctrl.rawPresets = presets
        })
    }

    function setupPresets() {
      // filter out presets that has invalid attributes
      ctrl.presets = _.filter(ctrl.rawPresets, function(preset) {
        // skip if preset exist in cache
        if (ctrl.presetsById && ctrl.presetsById[preset.id]) return true

        var isValid = true
        _.each(preset.targets, function(target) {
          // check invalid attributes
          var validAttributeIds = _.keys(ctrl.attributesById)
          var diff = _.difference(target.attributeIds, validAttributeIds)
          if (diff.length) {
            isValid = false
            return false // break
          }
          // check invalid node options
          _.each(target.cells, function(cell) {
            _.each(cell.nodes, function(node) {
              _.each(node.options, function(option) {
                try {
                  getOptionLabel(node.attributeId, option)
                } catch (e) {
                  isValid = false
                  return false // break
                }
              })
              if (!isValid) return false // break
            })
            if (!isValid) return false // break
          })
          if (!isValid) return false // break
        })
        return isValid
      })
      buildPresetOptions()
    }

    function buildPresetOptions() {
      ctrl.presetOptions = []
      ctrl.presetsById = {}

      // order presets by tags Glow+Default -> Glow -> Dynata -> SubscriberID
      ctrl.presetOptions = _.orderBy(ctrl.presets, [
        function(preset) {
          if (_.includes(preset.tags, PanelPreset.DefaultSelectedTag)) {
            return 1
          }
          if (_.includes(preset.tags, PanelPreset.DefaultTags.GLOW)) {
            return 2
          }
          if (_.includes(preset.tags, PanelPreset.DefaultTags.DYNATA)) {
            return 3
          }
          if (preset.isCustom()) {
            return 4
          }
        },
        'name',
      ]).map(function(preset) {
        ctrl.presetsById[preset.id] = preset
        var option = { label: preset.name, value: preset.id }
        if (_.includes(preset.tags, PanelPreset.DefaultTags.GLOW)) {
          option.tag = PanelPreset.DefaultTags.GLOW
          option.tagColor = Colors.ORANGE_BLAZE
        }
        return option
      })
    }

    function prepareInterlockOptions(forTarget) {
      ctrl.interlockOptions = getInterlockTargets(forTarget).map(function(
        target
      ) {
        return {
          label: ctrl.attributesById[target.attributeIds[0]].name,
          value: target.gid,
        }
      })
    }

    function getInterlockTargets(forTarget) {
      return ctrl.targets.filter(function(target) {
        // we can only interlock with:
        // - other targets
        // - targets that allocate
        // - targets that aren't already interlocked
        return (
          target !== forTarget &&
          target.type === target.Types.ALLOCATION &&
          target.attributeIds.length === 1
        )
      })
    }

    function hasInterlockOptions(target) {
      return getInterlockTargets(target).length
    }

    function interlock(sourceTarget, targetGid) {
      var target = ctrl.targets.find(function(target) {
        return target.gid === targetGid
      })
      _.remove(ctrl.targets, target)
      sourceTarget.interlock(target)
      markModified(true)
    }

    function markModified(value) {
      var changed = ctrl.modified !== value

      ctrl.modified = value
      ctrl.onChange({ $modified: value })

      if (!changed) return
      if (ctrl.modified) {
        if (ctrl.preset && !ctrl.preset.isCustom()) {
          ctrl.preset = null
          ctrl.setPresetId({ $value: null })
        }
      } else {
        _.remove(ctrl.errors) // clear errors
      }
    }

    function validateTargets() {
      _.remove(ctrl.errors)
      if (!ctrl.targets.length) {
        ctrl.errors.push('Targets cannot be empty')
      }
      ctrl.errors = ctrl.errors.concat(
        Panel.validateTargets(ctrl.targets, ctrl.responses)
      )
      if (ctrl.errors.length) return false
      return true
    }
  }
})()
