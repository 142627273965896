;(function() {
  'use strict'

  angular.module('app.places').controller('Places', Controller)

  /* @ngInject */
  function Controller() {
    var ctrl = this

    init()

    function init() {
      ctrl.subTitle =
        "Get great feedback from your customers while they're at (or close to) your location."
      ctrl.infoUrl = 'https://glo.link/places-info'
    }
  }
})()
