;(function() {
  'use strict'

  Controller.$inject = ["$element", "$timeout", "glUtils", "StateFactory", "c3", "d3", "surveyService"];
  angular.module('core.gResponseChart').component('gResponseChart', {
    controller: Controller,
    templateUrl: 'g-response-chart.html',
    bindings: {
      type: '@',
      typeId: '<id',
    },
  })

  /* @ngInject */
  function Controller(
    $element,
    $timeout,
    glUtils,
    StateFactory,
    c3,
    d3,
    surveyService
  ) {
    var ctrl = this
    var RANGE_FORMAT = 'YYYY-MM-DD HH:mm:ss'
    var MAX_Y_GRIDLINES = 5

    ctrl.state = new StateFactory(['loading', 'ready', 'error'])

    ctrl.Types = surveyService.SeriesTypes
    ctrl.Resolutions = surveyService.SeriesResolutions
    ctrl.Views = {
      DAY: 'day',
      WEEK: 'week',
      MONTH: 'month',
    }
    ctrl.ViewOptions = [
      {
        view: ctrl.Views.DAY,
        start: moment().startOf('day'),
        end: moment().endOf('day'),
        resolution: ctrl.Resolutions.HOUR,
        xTickFormat: 'hA',
        xTipFormat: 'hA',
      },
      {
        view: ctrl.Views.WEEK,
        start: moment()
          .subtract(6, 'days')
          .startOf('day'),
        end: moment().endOf('day'),
        resolution: ctrl.Resolutions.DAY,
        xTickFormat: 'ddd DD',
        xTipFormat: 'ddd DD MMM',
      },
      {
        view: ctrl.Views.MONTH,
        start: moment()
          .startOf('day')
          .subtract(4, 'weeks'),
        end: moment().endOf('day'),
        resolution: ctrl.Resolutions.DAY,
        xTickFormat: 'DD MMM',
        xTipFormat: 'ddd DD MMM',
      },
    ]

    ctrl.$onInit = onInit
    ctrl.setView = setView
    ctrl.loadChart = loadChart

    function onInit() {
      $element.addClass('response-chart')

      ctrl.id = 'surveys-' + _.uniqueId()
      $element.find('figure').attr('id', ctrl.id)

      setView(ctrl.Views.MONTH) // default view
    }

    function setView(view) {
      ctrl.options = _.find(ctrl.ViewOptions, { view: view })
      if (view) {
        ctrl.view = view
        loadChart()
      }
    }

    function loadChart() {
      var options = {
        type: ctrl.type,
        typeId: ctrl.typeId,
        start: ctrl.options.start,
        end: ctrl.options.end,
        resolution: ctrl.options.resolution,
      }
      ctrl.state.loading()
      surveyService
        .getSeries(options)
        .then(function(series) {
          buildChart(series)
          ctrl.state.ready()
        })
        .catch(function() {
          ctrl.state.error()
        })
    }

    function buildChart(series) {
      var columns = makeColumns(series, ctrl.options)
      var yLimits = getYLimits(columns)
      var config = makeConfig(ctrl.id, columns, yLimits, ctrl.options)
      $timeout(function() {
        ctrl.chart = c3.generate(config)
      }, 10)
    }

    function makeColumns(series, options) {
      var dates = ['Date']
      var responses = ['Responses']
      var range = glUtils.getDateRange(
        options.start,
        options.end,
        options.resolution,
        null,
        RANGE_FORMAT
      )

      // re-map range to moment dates (sif!)
      range = _.map(range, function(value) {
        var date = moment(value)
        return {
          date: date,
          key: date.format(surveyService.KEY_FORMAT),
          timeseriesValue: value,
        }
      })

      _.each(range, function(item) {
        var seriesItem = _.find(series, { key: item.key })
        var value = seriesItem ? seriesItem.totalSamples : 0
        responses.push(value)
        dates.push(item.timeseriesValue)
      })

      return [dates, responses]
    }

    function getYLimits(columns) {
      var responses = _.clone(columns[1])
      responses.shift()

      var min = 0
      var max = _.max(responses)
      max = (Math.floor(max / 4) + 1) * 4

      var count = max < 4 ? max + 1 : MAX_Y_GRIDLINES

      return {
        min: min,
        max: max,
        count: count,
      }
    }

    function makeConfig(id, columns, yLimits, options) {
      return {
        bindto: '#' + id,
        data: {
          x: 'Date',
          xFormat: '%Y-%m-%d %H:%M:%S',
          columns: columns,
          type: 'area',
        },
        axis: {
          x: {
            type: 'timeseries',
            tick: {
              format: function(x) {
                return moment(x).format(options.xTickFormat)
              },
            },
          },
          y: {
            show: false,
            min: yLimits.min,
            max: yLimits.max,
            tick: {
              count: yLimits.count,
              format: d3.format(',.0f'),
            },
            padding: {
              bottom: 0,
              top: 0,
            },
          },
        },
        grid: {
          y: {
            show: true,
          },
        },
        point: {
          r: 2,
        },
        legend: {
          show: false,
        },
        area: {
          zerobased: true,
        },
        tooltip: {
          format: {
            title: function(x) {
              return moment(x).format('ddd DD MMM')
            },
          },
        },
      }
    }
  }
})()
