;(function() {
  'use strict'

  Controller.$inject = ["$element"];
  angular
    .module('glow.reporting.charts')
    .component('glReportTable', Component())

  /* @ngInject */
  function Component() {
    return {
      controller: Controller,
      templateUrl: 'report-table.template.html',
      bindings: {
        maxHeight: '<',
        height: '=',
        hideHeader: '<',
        data: '<',
        allowRowClick: '=',
        onRowClick: '&',
        onRowFocus: '&',
        onSort: '&',
      },
    }
  }

  /* @ngInject */
  function Controller($element) {
    var ctrl = this
    var containerEl = angular.element($element.children()[0])

    ctrl.sortBy = sortBy
    ctrl.sortIconClass = sortIconClass
    ctrl.rowClick = rowClick
    ctrl.arrayFromCount = arrayFromCount
    ctrl.rowsFilter = rowsFilter

    ctrl.$onInit = onInit
    ctrl.$onChanges = onChanges

    function onInit() {
      ctrl.isRowClickable = ctrl.allowRowClick
      ctrl.sort = (ctrl.data && ctrl.data.sort) || {}
    }

    function onChanges(changes) {
      // set defaults
      ctrl.data = ctrl.data || {}
      ctrl.columns = ctrl.data.columns || []
      ctrl.rows = ctrl.data.rows || []
      ctrl.footer = ctrl.data.footer

      if (changes.maxHeight) {
        containerEl.css({
          maxHeight: _.isNumber(ctrl.maxHeight) ? ctrl.maxHeight + 'px' : null,
          height: _.isNumber(ctrl.height) ? ctrl.height + 'px' : null,
        })
      }
    }

    function rowClick(clickValues) {
      if (ctrl.allowRowClick) {
        ctrl.onRowClick(clickValues)
      }
    }

    function sortBy(column) {
      var sortKey = column.key + '.' + (column.useSortValue ? 'sort' : 'text')
      var sortAscending = false

      if (!column.sortable) {
        return
      }

      // existing sort key, toggle ascending
      if (ctrl.sort.key === sortKey) {
        sortAscending = !ctrl.sort.ascending
      }

      // clone sort config
      ctrl.sort = {
        key: sortKey,
        ascending: sortAscending,
      }

      ctrl.onSort()(ctrl.sort)
    }

    function sortIconClass(column) {
      var sortKey = column.key + '.' + (column.useSortValue ? 'sort' : 'text')
      var classes = []

      // highlight if active
      if (ctrl.sort.key === sortKey) {
        classes.push('-active')
      }

      // not currently ordered? default to down arrow
      if (ctrl.sort.key !== sortKey) {
        classes.push('gi-expand-more')

        // otherwise show up/down arrow based on ascending
      } else {
        classes.push(ctrl.sort.ascending ? 'gi-expand-less' : 'gi-expand-more')
      }

      return classes.join(' ')
    }

    function arrayFromCount(count) {
      return new Array(Math.floor(count))
    }

    function rowsFilter(row) {
      return !row.isHidden
    }
  }
})()
