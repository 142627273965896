;(function() {
  'use strict'

  Controller.$inject = ["$element", "mediaView", "cloudinaryService"];
  angular.module('app.core').component('imageGallery', {
    controller: Controller,
    templateUrl: 'image-gallery.html',
    bindings: {
      numRows: '<',
      numColumns: '<',
      gap: '<',
      minHeight: '<',
      imageUrls: '<',
    },
  })

  /* @ngInject */
  function Controller($element, mediaView, cloudinaryService) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.$onChanges = onChanges

    ctrl.getColumnSize = getColumnSize
    ctrl.isPrev = isPrev
    ctrl.isNext = isNext
    ctrl.prev = prev
    ctrl.next = next
    ctrl.expandImage = expandImage

    function onInit() {
      $element.css({ display: 'flex', justifyContent: 'center' })
    }

    function onChanges(changes) {
      if (changes.imageUrls) {
        ctrl.allItems = _.map(ctrl.imageUrls, function(url) {
          return {
            gallery: cloudinaryService.resize(url, 250),
            fullscreen: cloudinaryService.optimize(url),
            original: url,
          }
        })
        ctrl.maxItems = ctrl.numRows * ctrl.numColumns
        ctrl.totalPages = Math.ceil(ctrl.allItems.length / ctrl.maxItems)
        ctrl.currentPage = 1
        populateItems()
      }
    }

    function getColumnSize() {
      // Example calculation for 3 columns and 10px gap :
      // calc(1/3*100% - (1 - 1/3)*10px);
      // ref: https://stackoverflow.com/a/39862756/5731688
      return (
        'calc(1 / ' +
        ctrl.numColumns +
        ' * 100% - ' +
        '(1 - 1 / ' +
        ctrl.numColumns +
        ') * ' +
        ctrl.gap +
        'px)'
      )
    }

    function populateItems() {
      var offset = (ctrl.currentPage - 1) * ctrl.maxItems
      ctrl.items = ctrl.allItems.slice(offset, offset + ctrl.maxItems)
    }

    function isPrev() {
      return ctrl.currentPage > 1
    }

    function isNext() {
      return ctrl.currentPage < ctrl.totalPages
    }

    function prev() {
      if (!isPrev()) return
      ctrl.currentPage -= 1
      populateItems()
    }

    function next() {
      if (!isNext()) return
      ctrl.currentPage += 1
      populateItems()
    }

    function expandImage(event, image) {
      event.preventDefault()
      event.stopPropagation()
      mediaView.showImage(image.fullscreen)
    }
  }
})()
