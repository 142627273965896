;(function() {
  'use strict'

  service.$inject = ["$log", "$q", "$timeout", "sentimentResource", "subscriberService"];
  angular.module('core.services').factory('sentimentService', service)

  function service($log, $q, $timeout, sentimentResource, subscriberService) {
    var log = $log.create('sentimentService')

    var Resolutions = {
      DAY: 'DAY',
      HOUR: 'HOUR',
    }
    var Types = {
      DAILY: 'daily',
      HOURLY: 'hourly',
    }
    var MOOD_OFFSET = -3
    var KEY_FORMAT = 'DD MMM YYYY hA'

    return {
      get: get,
      Resolutions: Resolutions,
      Types: Types,
      KEY_FORMAT: KEY_FORMAT,
    }

    function get(options) {
      return $q(function(resolve, reject) {
        options = options || {}
        options = _.clone(options)
        options = _.defaults(options, {
          resolution: Resolutions.DAY,
          subscriberId: subscriberService.getSubscriber().id,
          pageId: null,
        })

        if (!options.start || !options.end) {
          log.error('start and end date required')
          return reject()
        }

        options.start = options.start.toISOString()
        options.end = options.end.toISOString()

        sentimentResource
          .get(options)
          .success(function(x, response) {
            response = fromServer(response)
            resolve(response)
          })
          .error(function() {
            reject()
          })
      })
    }

    function fromServer(data) {
      _.each(data, function(doc) {
        doc.date = moment(doc.timestamp)
        doc.key = doc.date.format(KEY_FORMAT)

        doc.averageMood = _.round(doc.averageMood) // BE doesnt round (yet?)
        // if (doc.averageMood === 0) {
        //     doc.averageMood = 1;
        // } else {

        // }

        doc.averageMoodOffset = doc.averageMood + MOOD_OFFSET
      })
      return data
    }
  }
})()
