;(function() {
  'use strict'

  Controller.$inject = ["$q", "$state", "app", "reportLinkService", "profileService"];
  angular
    .module('app.shared-report')
    .controller('SharedReportController', Controller)

  /* @ngInject */
  function Controller($q, $state, app, reportLinkService, profileService) {
    var ctrl = this

    ctrl.errors = {
      Default: 'Error fetching report',
      403: 'Sorry, you are not permitted to view this report',
      404: 'Sorry, we are currently unable to generate this report',
    }

    ctrl.$onInit = onInit
    ctrl.surveyId = $state.params.surveyId
    ctrl.token = $state.params.token || $state.params.t
    ctrl.onLoaded = onLoaded
    ctrl.title = ''

    function onInit() {
      fetchLink()
        .then(initialize)
        .catch(function(err) {
          console.error(err)
          ctrl.error = 403
        })
    }

    function fetchLink() {
      if (!ctrl.token) return $q.reject()
      return reportLinkService.getByToken(ctrl.token).then(function(link) {
        ctrl.link = link
      })
    }

    function initialize() {
      ctrl.reportOptions = {
        // Set the options that will be used by all report components
        type: 'shared',
        accessType: 'device',
        shareToken: ctrl.link.token,
        showShareRestrictedAnalysis: ctrl.link.isRestricted,
        canFilter: true,
        canFilterProfile: !ctrl.link.isRestricted,
        canEditFilters: true,
        showShareButton: false,
        canShareReport: false,
        showExportButton: ctrl.link.canExport,
        canExportReport: ctrl.link.canExport,
        showReportSettingsButton: false,
        canEditReportSettings: false,
        canEditResponses: false,
        canViewCrosstabs: true,
        canEditCrosstabs: false,
        visibilityMode: ctrl.link.mode,
        visibilityViewIds: ctrl.link.viewIds,
        onlyLoopVariableIds: ctrl.link.getAllLoopVariableIds(),
        showResponseTypeToggle: false,
        responseType: ctrl.link.responseType,
      }
    }

    function fetchProfile(subscriberId) {
      profileService.get(subscriberId).then(function(profile) {
        ctrl.profile = profile
      })
    }

    function onLoaded(title, tags, subscriberId) {
      fetchProfile(subscriberId)
      app.setTitle(title)
      app.setTags(tags)
    }
  }
})()
