;(function() {
  'use strict'

  angular.module('app.core').component('gFormHeader', {
    templateUrl: 'g-form-header.html',
    bindings: {
      title: '@',
    },
  })
})()
