;(function() {
  'use strict'

  Controller.$inject = ["configService", "placeService", "subscriberService", "clipboardService"];
  angular.module('app.core').component('channelFormPlaces', {
    controller: Controller,
    templateUrl: 'channel-form-places.html',
    bindings: {
      channel: '<',
      survey: '<',
      onSave: '&',
      selectPlaces: '&',
    },
  })

  /* @ngInject */
  function Controller(
    configService,
    placeService,
    subscriberService,
    clipboardService
  ) {
    var ctrl = this

    ctrl.placesUrl = configService.getZoneUrl()

    ctrl.$onInit = onInit
    ctrl.removePlace = removePlace
    ctrl.getPlaceLabel = getPlaceLabel
    ctrl.copyToClipboard = clipboardService.copy

    function onInit() {
      ctrl.ownerId = subscriberService.getSubscriber().id
      placeService.list(ctrl.ownerId, function(places, loading) {
        ctrl.places = places
        ctrl.ready = places.length || !loading
      })
    }

    function removePlace(placeId) {
      _.remove(ctrl.channel.places, function(id) {
        return id === placeId
      })
    }

    function getPlaceLabel(placeId) {
      var place = _.find(ctrl.places, { id: placeId })
      return place.name
    }
  }
})()
