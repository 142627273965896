;(function() {
  'use strict'

  Controller.$inject = ["$state", "subscriberService", "orgSelector", "countryService", "currencyService", "Currency"];
  angular.module('app.core').component('accountSubscriberCreator', {
    controller: Controller,
    templateUrl: 'account-subscriber-creator.html',
    bindings: {
      onDone: '&',
      onCancel: '&',
    },
  })

  /* @ngInject */
  function Controller(
    $state,
    subscriberService,
    orgSelector,
    countryService,
    currencyService,
    Currency
  ) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.setCountryAndCurrency = setCountryAndCurrency
    ctrl.create = create
    ctrl.cancel = cancel
    ctrl.switchOrg = switchOrg

    function onInit() {
      ctrl.subscriber = {
        name: null,
        country: null,
        billing: { currency: null },
      }
      ctrl.countries = countryService.getSelectOptions()
    }

    function setCountryAndCurrency(countryId) {
      ctrl.subscriber.country = countryService.getById(countryId)
      var localCurrency = currencyService.getByCountry(
        ctrl.subscriber.country.cc
      )
      var currency =
        localCurrency || currencyService.getByCode(Currency.Codes.USD)
      ctrl.subscriber.billing.currency = currency.code
    }

    function create() {
      if (ctrl.isLoading) return

      ctrl.errors = []

      if (!ctrl.subscriber.name) {
        ctrl.errors.push('Please input your organisation name')
      }

      if (!ctrl.subscriber.country) {
        ctrl.errors.push('Please select your country')
      }

      if (ctrl.errors.length) return

      ctrl.isLoading = true
      subscriberService
        .create(ctrl.subscriber)
        .then(function(data) {
          ctrl.subscriber.id = data.id
        })
        .catch(function(err) {
          ctrl.errors.push(err.message)
        })
        .finally(function() {
          ctrl.isLoading = false
        })
    }

    function cancel() {
      if (ctrl.isLoading) return
      ctrl.onCancel()
    }

    function switchOrg() {
      orgSelector.setOrgId(ctrl.subscriber.id) // elect the organisation
      // redirect to dashboard
      var absolutePath = $state.href('dashboard', {}, { absolute: true })
      window.location.href = absolutePath
    }
  }
})()
