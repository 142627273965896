;(function() {
  'use strict'

  Controller.$inject = ["$scope", "reportSetsService"];
  angular
    .module('glow.reporting.charts')
    .component('questionChart', Component())

  /* @ngInject */
  function Component() {
    return {
      controller: Controller,
      templateUrl: 'question-chart.html',
      bindings: {
        display: '@',
        question: '<',
        subtype: '<',
        absolute: '<',
        rotate: '<',
        sort: '<',
        canClick: '<',
        onClick: '&',
        hidden: '<',
        settingsVisible: '<settings',
      },
      require: {
        resizer: '?^questionChartResizer',
      },
    }
  }

  var cachedSettings = {
    /* [key]: LabelOptions */
  }

  /* @ngInject */
  function Controller($scope, reportSetsService) {
    var ctrl = this

    ctrl.chartId = _.uniqueId('questionChart')
    ctrl.$onChanges = onChanges
    ctrl.update = update

    ctrl.isSettings = isSettings
    ctrl.isOptionSettings = isOptionSettings
    ctrl.isLegendSettings = isLegendSettings

    ctrl.legendPositionOptions = [
      { label: 'Top', value: 'top' },
      { label: 'Bottom', value: 'bottom' },
      { label: 'Left', value: 'left' },
      { label: 'Right', value: 'right' },
    ]

    function onChanges(changes) {
      update(changes.rotate || changes.display)
    }

    function getQuestionSets() {
      var question = ctrl.question
      var subtype = ctrl.subtype

      // We only need to generate this info once per report.
      // If the user changes the filters/subtype this question is rebuilt and hence
      // the info will also be rebuilt.
      if (question.chartSets && question.chartSubtype === subtype) {
        return {
          rebuild: false,
          sets: question.chartSets,
          stacked: question.chartStacked,
        }
      }

      var result = reportSetsService.getForQuestion(question, subtype)
      var sets = result.sets
      var stacked = result.stacked

      // if we have cached data, as long as we aren't switching between subtype and no-subtype
      // then we don't need to rebuild. The chart can transition.
      var rebuild = true
      if (question.chartSets) {
        if (question.chartSubtype && subtype) rebuild = false
        if (!question.chartSubtype && !subtype) rebuild = false
      }

      question.chartSets = sets
      question.chartSubtype = subtype
      question.chartStacked = stacked
      return {
        sets: sets,
        stacked: stacked,
        rebuild: rebuild,
      }
    }

    function update(rebuild) {
      // we need a frame to let angular put the ID on the canvas
      requestAnimationFrame(function() {
        var display = ctrl.display
        var radar = ctrl.display === 'radar'
        var pie = ctrl.display === 'pie'
        var doughnut = ctrl.display === 'doughnut'
        var bar = ctrl.display === 'bar'
        var subtype = ctrl.subtype
        var absolute = ctrl.absolute
        var rotate = ctrl.rotate
        var sort = ctrl.sort
        var canClick = ctrl.canClick
        var onClick = ctrl.onClick

        fetchSettings()
        var settings = ctrl.settings

        // get the canvas context
        var elem = document.getElementById(ctrl.chartId)
        var ctx = elem.getContext('2d')

        // get question data in chart format
        var result = getQuestionSets(ctrl.question)
        var sets = result.sets
        var stacked = result.stacked

        ctrl.sets = sets // used by isLegendSettings

        // override rebuild if question data determined a rebuild
        if (result.rebuild) rebuild = true

        if (!sets.length) {
          console.error(
            '[question-chart] unsupported question: ' + ctrl.question.id
          )
          return
        }

        // sort the sets/aspects based on sort prop
        applySort(sets, sort)

        // build Y axis config
        // when flipped this becomes X
        var y = {
          beginAtZero: true,
          min: absolute ? undefined : 0,
          max: undefined,
          ticks: {
            precision: 0,
            callback: function(value) {
              if (absolute) {
                return value
              }
              return value + '%'
            },
          },
          stacked: stacked,
          display: bar,
          grid: {
            display: true,
          },
        }
        // by default the max y-axis percentage is calculated from the data
        // but when displaying certain charts we want this to scale exactly 0% -> 100%
        if (
          ctrl.question.type === 'matrix' &&
          !ctrl.question.isMulti &&
          !absolute &&
          !subtype
        ) {
          y.max = 100
        }

        // build X axis config
        // when flipped this becomes Y
        var x = {
          ticks: {
            callback: function(value) {
              var label = this.getLabelForValue(value)
              var result = buildLabel(
                label,
                settings.options.maxChars,
                settings.options.wrap,
                settings.options.maxLines
              )
              return result
              // return _.truncate(label, { length: 65 })
            },
            autoSkip: settings.options.autoSkip,
          },
          stacked: stacked,
          display: bar,
          grid: {
            display: false,
          },
        }

        // if rotated, flip the X and Y axis
        if (rotate) {
          var z = x
          x = y
          y = z
        }

        var padding
        // pie and doughnut are huge compared to bar
        // so we add some padding
        if (pie || doughnut) {
          padding = {
            top: 60,
            bottom: 60,
          }
        }

        var barPercentage = stacked ? 0.7 : 0.9
        var categoryPercentage = stacked ? 1 : 0.8
        // var barPercentage = 1
        // var categoryPercentage = 1

        var cutout
        // score has text inside the doughnut chart so we reduce
        // the dougnut thickness by increasing the cutout
        if (ctrl.question.type === 'score' && doughnut) {
          cutout = '90%'
          $scope.$applyAsync(function() {
            ctrl.cutoutTitle = ctrl.question.getScore(ctrl.subtype) || '-'
            ctrl.cutoutSubtitle = ctrl.question.data.scoreLabel || 'Score'
          })
        }

        // Calculate the recommended chart size based on the number
        // of bars, orientation and whether it is stacked.
        // If the chart is inside a resizer, we notify it with this
        // recommended size.
        // See: https://www.chartjs.org/docs/latest/charts/bar.html#barpercentage-vs-categorypercentage
        if (ctrl.resizer && (pie || doughnut)) {
          // pies and doughnuts don't really change size so we can use a fixed recommended width
          ctrl.resizer.setRecommendedSize({ width: 730 })
        }
        if (ctrl.resizer && bar) {
          var idealBarSize = rotate ? 34 : 80
          var axisSize = rotate ? 34 : 45
          var barsPerCategory = stacked ? 1 : sets.length
          var numCategories = sets[0].aspects.length
          var recSize = 0
          // add axis size
          // note: this is an estimate of a percentage-based axis. Text labels are whole other beast.
          recSize += axisSize
          /**
           * barPercentage = 0.7
           * categoryPercentage: 0.8
           *
           * If we have 2 categories, 3 bars per category and a sample size of 100px:
           * - categorySize = (100 / 2) * 0.8 = 40px
           * - barSize = (40px / 3) * 0.7 = 9.333px
           *
           * If we have 2 categories, 3 bars and want bars to be 9.333px (the inverse of above):
           * - categorySize = (9.333 * 3) / 0.7 = 40px
           * - sampleSize = (40 * 2) / 0.8 = 100px
           */
          var categorySize = (idealBarSize * barsPerCategory) / barPercentage
          var sampleSize = (categorySize * numCategories) / categoryPercentage
          // add sample size
          recSize += sampleSize
          // determine if we're recommending width or height
          var rec = {}
          if (rotate) {
            rec.height = recSize
          } else {
            rec.width = recSize
          }
          // notify the resizer so he can use this size
          ctrl.resizer.setRecommendedSize(rec)
          // console.log({
          //   numCategories: numCategories,
          //   barPercentage: barPercentage,
          //   categoryPercentage: categoryPercentage,
          //   idealBarSize: idealBarSize,
          //   axisSize: axisSize,
          //   barsPerCategory: barsPerCategory,
          //   categorySize: categorySize,
          //   sampleSize: sampleSize,
          //   recSize: recSize,
          // })
        }

        // build chart config
        var config = {
          plugins: [ChartDataLabels],
          type: display,
          data: {
            labels: _.map(sets[0].aspects, 'label'),
            datasets: sets.map(function(set) {
              return {
                label: set.label,
                data: _.map(set.aspects, absolute ? 'count' : 'percent'),
                backgroundColor: radar ? 'transparent' : _.map(set.aspects, 'color') /* prettier-ignore */,
                borderColor: radar ? _.map(set.aspects, 'color') : undefined,
                borderWidth: radar ? 2 : undefined,
              }
            }),
          },
          options: {
            devicePixelRatio:
              settings.devicePixelRatio || window.devicePixelRatio, // fallback to device if not set
            responsive: true,
            maintainAspectRatio: false,
            barPercentage: barPercentage,
            categoryPercentage: categoryPercentage,
            cutout: cutout,
            borderRadius: 4,
            indexAxis: rotate ? 'y' : 'x',
            scales: {
              y: y,
              x: x,
            },
            plugins: {
              legend: {
                display: ctrl.settings.legend.visible,
                position: ctrl.settings.legend.position,
                align: 'center',
              },
              tooltip: {
                position: 'average',
                callbacks: {
                  title: function(ctx) {
                    var datasetIndex = ctx[0].datasetIndex
                    var dataIndex = ctx[0].dataIndex
                    return sets[datasetIndex].aspects[dataIndex].label
                  },
                  label: function(ctx) {
                    var value
                    if (bar) {
                      value = rotate ? ctx.parsed.x : ctx.parsed.y
                    } else {
                      value = ctx.parsed
                    }
                    var prefix = ''
                    var suffix = ''
                    if (sets.length > 1) {
                      prefix = ctx.dataset.label + ': '
                    }
                    if (absolute) {
                      var percent =
                        sets[ctx.datasetIndex].aspects[ctx.dataIndex].percent
                      suffix = ' (' + percent + '%)'
                    } else {
                      var total =
                        sets[ctx.datasetIndex].aspects[ctx.dataIndex].count
                      suffix = '% (' + total + ')'
                    }
                    return prefix + value + suffix
                  },
                },
              },
              datalabels: {
                anchor: 'end',
                align: 'start',
                offset: 10,
                color: 'white',
                font: {
                  family: 'Roboto, sans-serif',
                  weight: '500',
                  lineHeight: 0,
                },
                clip: true,
                formatter: function(value, ctx) {
                  if (!value) return ''
                  if (absolute) {
                    return value
                  }
                  return _.round(value, 0) + '%'
                },
                display: function(ctx) {
                  if (!settings.datalabels.visible) return false
                  if (radar) return false
                  // Labels are hidden if the bars are likely to be small.
                  // Bar size is an estimate, not 100% accurate.
                  // TODO: This currently assumes percentage values are shown
                  // but we may want to handle when `absolute` is enabled too.
                  if (pie || doughnut) {
                    // hide if the chart has cutout
                    if (cutout) return false
                    // hide if there are too many layers
                    if (sets.length > 3) return false
                    // hide if the set is in too deep
                    if (ctx.datasetIndex > 1) return false
                    // otherwise hide if the slice is too thin
                    return (
                      sets[ctx.datasetIndex].aspects[ctx.dataIndex].percent >= 5
                    )
                  }
                  /**
                   * barPercentage = 0.7
                   * categoryPercentage: 0.8
                   *
                   * If we have 2 categories, 3 bars per category and a sample size of 100px:
                   * - categorySize = (100 / 2) * 0.8 = 40px
                   * - barSize = (40px / 3) * 0.7 = 9.333px
                   */
                  var chartSize = rotate ? ctx.chart.height : ctx.chart.width
                  var axisSize = rotate ? 34 : 45
                  var sampleSize = chartSize - axisSize
                  var numCategories = sets[0].aspects.length
                  var barsPerCategory = stacked ? 1 : sets.length
                  var categorySize =
                    (sampleSize / numCategories) * categoryPercentage
                  var barSize = (categorySize / barsPerCategory) * barPercentage
                  // console.log('barSize (est)', barSize)
                  if (rotate) {
                    // horizontal bars
                    return barSize > 20
                  } else {
                    // vertical bars
                    return barSize > 50
                  }
                },
              },
            },
            layout: {
              padding: padding,
            },
            onClick: function(e) {
              if (!canClick) return
              var element = this.getElementsAtEventForMode(
                e,
                'nearest',
                { intersect: true },
                true
              )[0]
              if (!element) return
              var set = sets[element.datasetIndex]
              var aspect = set.aspects[element.index]
              onClick({
                $value: set.label,
                $aspect: aspect ? aspect.id : null,
              })
            },
          },
        }

        if (!ctrl.chart || rebuild) {
          if (ctrl.chart) ctrl.chart.destroy()
          ctrl.chart = new Chart(ctx, config)
        } else {
          /**
           * Important
           * ---------
           * Only modify dynamic fields that can change.
           * If we just swap out the whole config, Chart.js will render
           * the whole chart from scratch instead of animating between
           * the two states.
           */
          ctrl.chart.data.labels = config.data.labels
          _.each(ctrl.chart.data.datasets, function(dataset, idx) {
            dataset.label = config.data.datasets[idx].label
            dataset.data = config.data.datasets[idx].data
            dataset.backgroundColor = config.data.datasets[idx].backgroundColor
          })
          var dprChanged =
            ctrl.chart.options.devicePixelRatio !==
            config.options.devicePixelRatio
          ctrl.chart.options.devicePixelRatio = config.options.devicePixelRatio
          ctrl.chart.options.indexAxis = config.options.indexAxis
          ctrl.chart.options.scales.x.stacked = x.stacked
          ctrl.chart.options.scales.y.stacked = y.stacked
          var scales = ctrl.chart.options.scales
          _.each(['x', 'y'], function(axis) {
            var op = axis === 'y' ? y : x
            var scale = scales[axis]
            scale.beginAtZero = op.beginAtZero
            scale.min = op.min
            scale.max = op.max
            scale.ticks.precision = op.ticks.precision
            scale.ticks.callback = op.ticks.callback
            scale.ticks.autoSkip = op.ticks.autoSkip
            scale.stacked = op.stacked
            scale.display = op.display
            scale.grid.display = op.grid.display
          })
          // legend
          ctrl.chart.options.plugins.legend.display =
            config.options.plugins.legend.display
          ctrl.chart.options.plugins.legend.position =
            config.options.plugins.legend.position

          ctrl.chart.options.plugins.tooltip.callbacks.title =
            config.options.plugins.tooltip.callbacks.title
          ctrl.chart.options.plugins.tooltip.callbacks.label =
            config.options.plugins.tooltip.callbacks.label
          ctrl.chart.options.plugins.datalabels.formatter =
            config.options.plugins.datalabels.formatter
          ctrl.chart.options.plugins.datalabels.display =
            config.options.plugins.datalabels.display
          ctrl.chart.update()
          if (dprChanged) {
            ctrl.chart.resize()
          }
        }
      })
    }

    function fetchSettings() {
      var key = [
        ctrl.question.id,
        ctrl.rotate,
        ctrl.subtype,
        ctrl.display,
        !!ctrl.resizer, // used to separate settings from overview/fullscreen
      ].join('-')
      // console.log('[label-options] key', key)
      if (!cachedSettings[key]) {
        // console.log('[label-options] none found, creating')
        cachedSettings[key] = {
          datalabels: {
            visible: true,
          },
          options: {
            maxChars: 22,
            wrap: false,
            maxLines: 2,
            autoSkip: true,
          },
          legend: {
            visible: false,
            position: 'right',
          },
          devicePixelRatio: window.devicePixelRatio,
        }
      }
      ctrl.settings = cachedSettings[key]
      // console.log('[settings]', ctrl.settings)
    }

    /**
     * Sorts the sets/aspects
     *
     * This function is a mess because it translates from the data-table sort config
     * passed in, into a chart-based sort conifg
     */
    function applySort(sets, sort) {
      var sortSet = null
      var sortKey = 'order'
      var sortDir = 'asc'
      if (sort) {
        if (/^choice\d+\.sort$/.test(sort.key)) {
          // choice0.sort etc
          sortSet = parseInt(sort.key.split('choice')[1].split('.sort')[0]) // choice.order
          sortKey = 'count'
        } else if (/^rank\d+\.sort$/.test(sort.key)) {
          // rank0.sort etc
          sortSet = parseInt(sort.key.split('rank')[1].split('.sort')[0]) // rank.order
          sortKey = 'count'
        } else if (sort.key === 'total.sort') {
          sortSet = true
          sortKey = 'crossCount'
        } else {
          sortSet = null
          switch (sort.key) {
            case 'id':
              sortKey = 'id'
              break
            case 'choice.text':
            case 'statement.sort':
              sortKey = 'label'
              break
            case 'total.sort':
              sortKey = 'count'
              break
            case 'number.text':
              sortKey = 'order'
              break
            default:
              sortKey = 'order'
              break
          }
        }
        // BUG: the opposite is correct here, why?
        sortDir = sort.ascending ? 'desc' : 'asc'
      }
      // console.log({ sortSet, sortKey, sortDir })

      var sortBySet = _.isNumber(sortSet) || sortSet === true

      // sort aspects by aspect field
      if (!sortBySet) {
        _.each(sets, function(set) {
          set.aspects = _.orderBy(set.aspects, sortKey, sortDir)
        })
      }

      // sort aspects by target set
      if (sortBySet) {
        var targetSet
        if (sortSet === true) {
          targetSet = sets[0]
        } else {
          targetSet = sets.find(function(set) {
            return set.order === sortSet
          })
        }
        targetSet.aspects = _.orderBy(targetSet.aspects, sortKey, sortDir)
        sets.forEach(function(set) {
          if (set === targetSet) return
          set.aspects = _.orderBy(
            set.aspects,
            function(aspect) {
              return targetSet.aspects.findIndex(function(a) {
                return a.id === aspect.id
              })
            },
            'asc'
          )
        })
      }
    }

    function buildLabel(str, maxChars, wrap, maxLines) {
      // console.log(str, maxChars, wrap, maxLines)
      // no string? stop!
      if (!str) return str
      // string short? don't adjust!
      if (str.length <= maxChars) return str
      // string long but can't wrap? truncate it!
      if (str.length > maxChars && !wrap) {
        return _.truncate(str, { length: maxChars })
      }
      // otherwise, we're wrapping baby!
      var lines = []
      var words = str.split(' ')
      var temp = ''
      for (var idx = 0; idx < words.length; idx++) {
        var word = words[idx]
        if (temp.length > 0) {
          var concat = temp + ' ' + word
          if (concat.length > maxChars) {
            lines.push(temp)
            temp = ''
          } else {
            if (idx === words.length - 1) {
              lines.push(concat)
              continue
            } else {
              temp = concat
              continue
            }
          }
        }
        if (idx === words.length - 1) {
          lines.push(word)
          continue
        }
        if (word.length < maxChars) {
          temp = word
        } else {
          lines.push(word)
        }
      }
      if (maxLines && lines.length > maxLines) {
        lines.splice(maxLines)
        var text = lines[lines.length - 1]
        text = text.slice(0, -3) + '...'
        lines[lines.length - 1] = text
      }
      return lines
    }

    function isSettings() {
      return (
        !!ctrl.settingsVisible &&
        (ctrl.display === 'bar' || ctrl.display === 'pie')
      )
    }

    function isOptionSettings() {
      return ctrl.display === 'bar'
    }

    function isLegendSettings() {
      return ctrl.display === 'pie' || ctrl.sets.length > 1
    }
  }
})()
