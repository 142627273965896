;(function() {
  'use strict'

  Controller.$inject = ["$log", "$state", "glTips", "kitsService", "userService"];
  angular.module('app.kit-manager').controller('KitManager', Controller)

  /* @ngInject */
  function Controller($log, $state, glTips, kitsService, userService) {
    $log = $log.create('KitManager')

    var ctrl = this

    // Constants
    ctrl.States = {
      LOADING: 'loading',
      ERROR: 'error',
      READY: 'ready',
      SUCCESS: 'success',
    }

    // Methods
    ctrl.create = create

    activate()

    /**
     * Initialise
     */
    function activate() {
      ctrl.state = ctrl.States.LOADING
      getKits()
    }

    /**
     * @name getKits
     * @desc load the kits that this subscriber OWNS
     */
    function getKits() {
      kitsService
        .getByOwner(userService.getSubscriberId())
        .then(getSuccess)
        .catch(getError)

      function getSuccess(data) {
        ctrl.kits = data
        ctrl.state = ctrl.States.READY
        glTips.ready()
      }

      function getError() {
        ctrl.state = ctrl.States.ERROR
        $log.error('could not load templates')
      }
    }

    /**
     * @name Create
     * @desc create a new Kit
     */
    function create() {
      $state.go('kits-edit', { kitId: 'new' })
    }
  }
})()
