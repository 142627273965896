;(function() {
  'use strict'

  angular.module('core.interaction', [
    'core.services',
    'ui.router',
    'glow.lib',
    'glow.components',
  ])
})()
