;(function() {
  'use strict'

  config.$inject = ["$stateProvider"];
  angular.module('app.survey', []).config(config)

  /* @ngInject */
  function config($stateProvider) {
    $stateProvider.state('survey', {
      url:
        '/surveys/:surveyId?tab&surveyTemplateId&cloneSurveyId&projectId&title&filters&filtersOperator&view&responseType&zoom',
      params: {
        surveyId: { dynamic: true },
        tab: { dynamic: true },
        filters: { dynamic: true },
        filtersOperator: { dynamic: true },
        view: { dynamic: true },
        responseType: { dynamic: true },
        zoom: { dynamic: true },
      },
      parent: 'auth',
      templateUrl: 'survey.html',
      controller: 'Survey',
      controllerAs: '$ctrl',
      public: true,
    })
  }
})()
