;(function() {
  'use strict'

  angular.module('app.core').factory('Project', Factory)

  /* @ngInject */
  function Factory() {
    var Events = {
      CREATED: 'project:created',
    }

    var Visibility = {
      EVERYONE: 'EVERYONE',
      PRIVATE: 'PRIVATE',
    }

    function Project(options) {
      _.defaults(this, options, {
        id: null,
        name: null,
        ownerId: null,
        visibility: Visibility.EVERYONE,
        privateToGuestIds: [], // only used when visibility = PRIVATE
        canEditVisibilityGuestIds: [],
        surveyCount: 0,
        responseCount: 0,
        isArchived: false,
        createdAt: moment(),
        updatedAt: moment(),
      })
    }

    Project.prototype.Events = Project.Events = Events
    Project.prototype.Visibility = Project.Visibility = Visibility

    Project.prototype.deserialize = function(data) {
      this.id = data.id
      this.name = data.name
      this.ownerId = data.ownerId
      this.visibility = data.visibility
      this.privateToGuestIds = data.privateToGuestIds
      this.canEditVisibilityGuestIds = data.canEditVisibilityGuestIds
      this.surveyCount = data.surveyCount || 0
      this.responseCount = data.responseCount || 0
      this.isArchived = data.isArchived
      this.createdAt = moment(data.createdAt)
      this.updatedAt = moment(data.updatedAt)
      return this
    }

    Project.prototype.serialize = function() {
      var data = {}
      data.id = this.id
      data.name = this.name
      data.ownerId = this.ownerId
      data.visibility = this.visibility
      data.privateToGuestIds = this.privateToGuestIds.slice()
      data.canEditVisibilityGuestIds = this.canEditVisibilityGuestIds.slice()
      data.isArchived = this.isArchived
      data.createdAt = this.createdAt.toISOString()
      data.updatedAt = this.updatedAt.toISOString()
      return data
    }

    Project.prototype.isNew = function() {
      return !this.id
    }

    Project.prototype.isPrivate = function() {
      return this.visibility === Visibility.PRIVATE
    }

    Project.prototype.clone = function() {
      // exact copy only. if you need to modify make a 'duplicate' method
      return new Project().deserialize(this.serialize())
    }

    Project.prototype.replace = function(project) {
      this.deserialize(project.serialize())
    }

    Project.prototype.validate = function() {
      var self = this
      var isValid = true
      self.errors = []

      if (!self.name) {
        self.errors.push('Name is required')
        isValid = false
      }

      return isValid
    }

    return Project
  }
})()
