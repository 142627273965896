;(function() {
  'use strict'

  pageGeneralConfig.$inject = ["$stateProvider"];
  angular.module('app.page-general', []).config(pageGeneralConfig)

  /* @ngInject */
  function pageGeneralConfig($stateProvider) {
    $stateProvider.state('page.general', {
      url: '/general',
      templateUrl: 'page-general.template.html',
      controller: 'PageGeneralController',
      controllerAs: 'pageGeneral',
      title: 'Page Settings',
    })
  }
})()
