;(function() {
  'use strict'

  Controller.$inject = ["$window", "glToast", "projectResourceService", "assetService", "uacService"];
  angular.module('app.projects').component('projectResources', {
    controller: Controller,
    templateUrl: 'project-resources.html',
    bindings: {
      projectId: '<',
    },
  })

  /* @ngInject */
  function Controller(
    $window,
    glToast,
    projectResourceService,
    assetService,
    uacService
  ) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.isVisible = isVisible
    ctrl.open = open
    ctrl.edit = edit
    ctrl.remove = remove

    function onInit() {
      ctrl.loader = projectResourceService.getByProject(ctrl.projectId)
      ctrl.canManageResources = uacService.canManageProjects()
    }

    function isVisible(resource) {
      return !resource.isRemoved
    }

    function open(resource, e) {
      if (e && e.defaultPrevented) return
      if (resource.isLink()) {
        $window.open(resource.url, '_blank')
      }
      if (resource.isFile()) {
        assetService
          .download(
            resource.asset.id,
            'Download File',
            'You are downloading ' + resource.name + '.'
          )
          .catch(function(error) {
            console.error(error)
            glToast.show('There was an error downloading this file.')
          })
      }
    }

    function edit(resource) {
      projectResourceService.showEditor(resource.projectId, resource)
    }

    function remove(resource) {
      resource.isRemoved = true
      projectResourceService.save(resource).catch(function(error) {
        console.error(error)
        glToast.show('There was an error removing this resource.')
      })
    }
  }
})()
